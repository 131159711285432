import { useQuery } from '@apollo/client'
import { Divider, Paper, Skeleton, Stack } from '@mui/material'
import { ProductViewPanelState } from '../..'
import {
  GetProductQuery,
  GetProductVariantGroupsDocument,
} from '../../../../generated/graphql'
import { MultipleVariantSelector } from './multiple-variant-selector'
import {
  SingleVariantSelector,
  VariantSelectorButton,
} from './single-variant-selector'

type VariantSelectorProps = {
  product?: GetProductQuery['product']
  selectedProduct: ProductViewPanelState
  isLoading?: boolean

  onDetailProduct: (
    productId: number,
    siteId?: number,
    previous?: ProductViewPanelState,
  ) => void
}

export const VariantSelector = (props: VariantSelectorProps) => {
  const { product, onDetailProduct, selectedProduct } = props

  const { data: variantsData, loading: loadingProductVariantGroups } = useQuery(
    GetProductVariantGroupsDocument,
    {
      variables: {
        productid: product?.isMainVariant
          ? product?.id
          : product?.variantparentid,
        productSiteId: selectedProduct.siteId,
      },
      skip: !product?.id || !!product?.isSingleProduct,
    },
  )
  const isLoading = props.isLoading || loadingProductVariantGroups

  const variantGroups = variantsData?.productVariants.groups || []
  const variants = variantsData?.productVariants.variants || []

  if (!isLoading && variantGroups.length === 0) return null

  const isSingleVariantGroup = variantGroups.length === 1
  const firstVariantGroup = variantGroups[0]!

  return (
    <Paper elevation={0}>
      <Divider />
      {isLoading && (
        <Stack direction="row">
          <VariantSelectorButton sx={{ flexGrow: 1 }}>
            <Skeleton width="80%" />
          </VariantSelectorButton>
          <VariantSelectorButton sx={{ flexGrow: 1 }}>
            <Skeleton width="80%" />
          </VariantSelectorButton>
          <VariantSelectorButton sx={{ flexGrow: 1 }}>
            <Skeleton width="80%" />
          </VariantSelectorButton>
        </Stack>
      )}

      {!isLoading &&
        (isSingleVariantGroup ? (
          <>
            <Divider />
            <SingleVariantSelector
              variantGroup={firstVariantGroup}
              variants={variants}
              product={product}
              onDetailProduct={onDetailProduct}
              selectedProduct={selectedProduct}
            />
          </>
        ) : (
          <MultipleVariantSelector
            variantGroups={variantGroups}
            variants={variants}
            product={product}
            onDetailProduct={onDetailProduct}
            selectedProduct={selectedProduct}
          />
        ))}
    </Paper>
  )
}
