import { makeVar, useReactiveVar } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../../components/data-grid/context'
import { PageHeader } from '../../../components/page-header'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { PricesFilterViewPanel } from './prices-filter-view-panel'
import { PricesList } from './prices-list'

const pricesFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const PricesPage = () => {
  const { t } = useTranslation(['prices'])
  const filterViewPanelState = useReactiveVar(pricesFilterViewPanelVar)
  const { trackButtonClick } = useTracking()
  usePageTitle(t('prices:page_title'))

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'products-filter-view-panel-close' })
    pricesFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'products-filter-view-panel-open' })
    pricesFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader title={t('prices:page_title')} isFlexible />
          <Container maxWidth={false}>
            <PricesList onShowFilter={toggleFilterViewPanel} />
          </Container>
        </Grid>
        <Grid item>
          <PricesFilterViewPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
