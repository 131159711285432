import { ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'

type Props = {
  journal?: {
    staff: string
    staffuserid: string
  }
}

export const StaffDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const staff = props.journal?.staff
  const staffId = props.journal?.staffuserid

  return (
    <>
      <ListItemSkeleton
        hide={!staff}
        childrenSkeleton
        divider
        data-testid="journal-staff"
      >
        <ListItemText primary={t('journals:staff')} secondary={staff} />
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!staffId}
        childrenSkeleton
        divider
        data-testid="journal-staff-id"
      >
        <ListItemText primary={t('journals:staff_id')} secondary={staffId} />
      </ListItemSkeleton>
    </>
  )
}
