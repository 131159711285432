import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../../../hooks/tracking'
import {
  OrderDeliveryItemState,
  UpdateOrderDeliveryDocument,
} from '../../../../generated/graphql'
import { useSnackbar } from 'notistack'

type Props = {
  open: boolean
  orderId?: number
  orderDeliveryId?: number
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
  dataTestid?: string
}

export const DeliveryCancelDialog = (props: Props) => {
  const { t } = useTranslation(['orders', 'shared'])

  const dialogName = 'update-order-delivery'

  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormSuccess,
    trackFormError,
  } = useTracking()

  const prevOpen = usePrevious(props.open)
  const { enqueueSnackbar } = useSnackbar()

  const [updateOrderDelivery] = useMutation(UpdateOrderDeliveryDocument)

  const handleConfirm = async () => {
    const { orderId, orderDeliveryId } = props

    trackButtonClickEvent({ name: dialogName })

    if (!orderId || !orderDeliveryId) return

    try {
      await updateOrderDelivery({
        variables: {
          orderId,
          orderDeliveryId,
          data: {
            state: OrderDeliveryItemState.Cancelled,
          },
        },
      })

      trackFormSuccess({ name: dialogName })
      props.onClose()
    } catch (error) {
      enqueueSnackbar(t('orders:cancel_delivery_dialog.cannot_cancel'), {
        variant: 'error',
      })
      trackFormError({ name: dialogName })
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={handleConfirm}
      title={t('orders:cancel_delivery_dialog.title')}
      text={t('orders:cancel_delivery_dialog.description')}
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
      mainActionLabel={t('shared:action.confirm')}
      secondaryActionLabel={t('shared:action.close')}
      variant="destructive"
    />
  )
}
