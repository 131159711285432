import { Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Footer } from '../login/footer'
import { EmailInput } from './email-input'
import { EmailSent } from './email-sent'
import { PasswordInput } from './password-input'
import { PasswordSet } from './password-set'
import { FormProvider, useForm } from 'react-hook-form'
import { ACCOUNT_ID_REGEX } from '../login/util'
import { RootRoute } from '..'

enum Step {
  EmailInput,
  EmailSent,
  NewPassword,
  PasswordSet,
}

export type ResetPasswordForm = {
  email: string
  token: string | null
  accountId: number
}

type LocationState = {
  email?: string
}

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams()

  const state = useLocation().state as LocationState | undefined
  const email = state?.email

  const navigate = useNavigate()
  const token = searchParams.get('token')
  const accountId = Number(location.host.match(ACCOUNT_ID_REGEX)?.[1])

  const [step, setStep] = useState<Step>(
    token ? Step.NewPassword : Step.EmailInput,
  )

  const formContext = useForm<ResetPasswordForm>({
    defaultValues: { token, accountId, email },
  })

  useEffect(() => {
    if (!accountId) navigate(RootRoute.Login)
  }, [accountId, navigate])

  return (
    <>
      <Paper
        square
        elevation={0}
        sx={{
          px: 6,
          py: 5,
          minHeight: 340,
          alignContent: 'center',
        }}
      >
        <FormProvider {...formContext}>
          {step === Step.EmailInput && (
            <EmailInput onSuccess={() => setStep(Step.EmailSent)} />
          )}

          {step === Step.EmailSent && (
            <EmailSent onBack={() => setStep(Step.EmailInput)} />
          )}

          {step === Step.NewPassword && (
            <PasswordInput
              onSuccess={() => setStep(Step.PasswordSet)}
              onBack={() => setStep(Step.EmailInput)}
            />
          )}

          {step === Step.PasswordSet && <PasswordSet />}
        </FormProvider>
      </Paper>

      <Footer />
    </>
  )
}
