import {
  Box,
  Button,
  DialogProps,
  FormHelperText,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { DuplicateIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'
import { useMutation } from '@apollo/client'
import { AddCashRegisterActivationCodeDocument } from '../../../generated/graphql'
import { useSnackbar } from 'notistack'

type ActivationBlockProps = {
  loading: boolean
  value: string
}

const ActivationCodeBlock = (props: ActivationBlockProps) => {
  const { t } = useTranslation(['shared'])
  const { enqueueSnackbar } = useSnackbar()

  const copyToClipboard = () => {
    void navigator.clipboard
      .writeText(props.value)
      .then(() => enqueueSnackbar(t('shared:copied_to_clipboard')))
  }

  return (
    <Paper
      sx={{ p: 1, background: (theme) => theme.palette.background.default }}
    >
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: '100%', textAlign: 'center' }}>
          <Typography variant="displayLarge">
            {props.loading ? (
              <Skeleton
                variant="text"
                width="50%"
                sx={{ display: 'inline-block' }}
              />
            ) : (
              props.value
            )}
          </Typography>
        </Box>

        <Box>
          <IconButton onClick={copyToClipboard}>
            <DuplicateIcon />
          </IconButton>
        </Box>
      </Stack>
    </Paper>
  )
}

type Props = DialogProps & {
  registerId: string
  onClose(): void
}

export const ActivationCodeDialog = (props: Props) => {
  const { t } = useTranslation(['cash_registers', 'shared'])
  const { registerId, onClose, ...dialogProps } = props
  const prevOpen = usePrevious(props.open)

  const [requestActivationCode, { data, loading }] = useMutation(
    AddCashRegisterActivationCodeDocument,
    { variables: { registerId } },
  )

  useEffect(() => {
    if (props.open && !prevOpen) {
      void requestActivationCode()
    }
  }, [props.open, prevOpen, requestActivationCode])

  return (
    <Dialog onClose={onClose} maxWidth="xs" {...dialogProps}>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body02" sx={{ mb: 1, display: 'block' }}>
            {t('cash_registers:activation_code.account')}
          </Typography>

          <ActivationCodeBlock
            loading={loading}
            value={data?.addCashRegisterActivationCode.account || ''}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body02" sx={{ mb: 1, display: 'block' }}>
            {t('cash_registers:activation_code.activation_code')}
          </Typography>

          <ActivationCodeBlock
            loading={loading}
            value={data?.addCashRegisterActivationCode.activationCode || ''}
          />
        </Box>

        <FormHelperText>
          {t('cash_registers:activation_code.description')}
        </FormHelperText>
      </DialogContent>

      <DialogActions sx={{ pt: 0 }}>
        <Button color="primary" onClick={onClose}>
          {t('shared:action.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
