import {
  Box,
  Button,
  IconButton,
  TextField,
  Stack,
  SxProps,
} from '@mui/material'
import { Ref, useEffect } from 'react'
import {
  FieldValues,
  UseControllerProps,
  useController,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form'
import { nanoid } from 'nanoid'
import { DeleteIcon, PlusIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'

type Props = {
  sx?: SxProps
  innerRef?: Ref<HTMLElement>
}

type Field = {
  id: string
  key: string
  value: string
}

export const KeyValueInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, control, sx, innerRef } = props
  const { t } = useTranslation(['shared'])

  // Original Controller
  const { field } = useController({ name, control })

  const formatValues = (valueMap: Record<string, string>) => {
    return Object.entries(valueMap || {}).map(([key, value]) => ({
      key,
      value,
    })) as Field[]
  }

  // Local form
  const {
    register,
    control: localControl,
    reset,
    formState,
  } = useForm<{ root: Field[] }>()

  // Local array handler
  const { fields, append, remove } = useFieldArray({
    name: 'root',
    control: localControl,
  })

  // Watch changes for local controller
  const updatedFields = useWatch({ control: localControl })

  useEffect(() => {
    // Convert fields back to a map structure
    const valuesMap = updatedFields.root?.reduce(
      (acc, f) => {
        if (f?.key) acc[f.key] = f.value

        return acc
      },
      {} as Record<string, string | undefined>,
    )

    if (!valuesMap) return

    // Update Original Controller
    if (JSON.stringify(field.value) !== JSON.stringify(valuesMap)) {
      field.onChange(valuesMap)
    }
  }, [field, updatedFields])

  // Set defaultValues if field.value is not empty
  useEffect(() => {
    const hasKeys = field.value && Object.keys(field.value).length > 0

    if (!formState.defaultValues?.root && hasKeys) {
      reset({ root: formatValues(field.value) })
    }
  }, [field.value, formState, reset])

  return (
    <Box ref={innerRef} sx={sx}>
      {fields.map(({ id, key, value }, index) => (
        <Stack direction="row" spacing={1} key={id} sx={{ mb: 1 }}>
          <TextField
            defaultValue={key}
            placeholder={t('shared:label.key')}
            {...register(`root.${index}.key`)}
          />

          <TextField
            defaultValue={value}
            placeholder={t('shared:label.value')}
            {...register(`root.${index}.value`)}
          />

          <IconButton onClick={() => remove(index)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}

      <Button
        color="tertiary"
        fullWidth
        startIcon={<PlusIcon />}
        onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
          append({ id: nanoid(), key: '', value: '' })
          const target = event.target as HTMLButtonElement
          setTimeout(() => target.scrollIntoView())
        }}
      >
        {t('shared:action.add_new')}
      </Button>
    </Box>
  )
}
