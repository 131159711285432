import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  AllProductGroupsQuery,
  ProductGroupType,
} from '../../../../../generated/graphql'
import { useAuthorization } from '../../../../../hooks/authorization'
import { useTracking } from '../../../../../hooks/tracking'
import { ArrayElement } from '../../../../../utils/types'

type ProductGroup = ArrayElement<AllProductGroupsQuery['allProductGroups']>

type ProductGroupProps = {
  open: boolean
  productGroup?: ProductGroup

  onClose: () => void
  onSuccess: (productGroup: ProductGroup) => void
}

export const ProductGroupDialog = (props: ProductGroupProps) => {
  const { t } = useTranslation(['shared', 'settings'])
  const {
    modules: { writeSettingsProductGroups },
  } = useAuthorization()
  const { trackDialogOpen, trackDialogClose } = useTracking()
  const prevOpen = usePrevious(props.open)
  const dialogName = props.productGroup
    ? 'edit-product-group'
    : 'add-product-group'

  const isNewProductGroup = !props.productGroup

  const formContext = useForm<ProductGroup>({
    defaultValues: {
      name: '',
      type: ProductGroupType.NUMBER_10,
      value: 0,
    },
  })

  const submit = (
    data: ArrayElement<AllProductGroupsQuery['allProductGroups']>,
  ) => {
    trackDialogClose({ name: dialogName })
    props.onSuccess(data)
  }

  const vatTypes = (
    Object.keys(
      ProductGroupType,
    ) as unknown as (keyof typeof ProductGroupType)[]
  ).map((x) => ({
    id: x,
    text: t(`settings:product_group.vat_type.${x}`),
  }))

  useEffect(() => {
    formContext.reset(props.productGroup, { keepDefaultValues: true })
  }, [formContext, props.productGroup])

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, dialogName])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  return (
    <Dialog
      open={props.open}
      data-testid="add-product-group-dialog"
      maxWidth="xs"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        {!writeSettingsProductGroups
          ? t('settings:product_group.view_product_group')
          : isNewProductGroup
            ? t('settings:product_group.add_product_group')
            : t('settings:product_group.edit_product_group')}
      </DialogTitle>
      <DialogContent>
        <TextField
          error={!!formContext.formState.errors.name}
          fullWidth
          helperText={formContext.formState.errors.name?.message}
          label={t('settings:product_group.name')}
          {...formContext.register('name', {
            required: t('shared:validation.field_required', {
              field: t('settings:product_group.name'),
            }),
          })}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'product-group-name',
          }}
          disabled={!writeSettingsProductGroups}
        />
        <TextField
          error={!!formContext.formState.errors.comment}
          fullWidth
          label={t('settings:product_group.internal_comment')}
          {...formContext.register('comment')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'product-group-comment',
          }}
          disabled={!writeSettingsProductGroups}
        />
        <Controller
          control={formContext.control}
          name="type"
          render={({ field }) => (
            <>
              <InputLabel>{t('settings:product_group.type')}</InputLabel>
              <Select
                value={field.value || ProductGroupType.NUMBER_10}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                data-testid="product-group-type"
                inputProps={{
                  'data-testid': 'product-group-type-input',
                }}
                disabled={!writeSettingsProductGroups}
              >
                {vatTypes.map(({ id, text }) => (
                  <MenuItem
                    value={id}
                    key={id}
                    data-testid={`product-group-type-option-${id}`}
                  >
                    {text}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                {t('settings:product_group.type_description')}
              </FormHelperText>
            </>
          )}
        />
        <TextField
          error={!!formContext.formState.errors.value}
          fullWidth
          helperText={t('settings:product_group.vat_description')}
          label={t('settings:product_group.vat')}
          {...formContext.register('value', {
            required: t('shared:validation.field_required', {
              field: t('settings:product_group.name'),
            }),
            valueAsNumber: true,
          })}
          type="number"
          inputProps={{
            'data-testid': 'product-group-vat',
          }}
          disabled={!writeSettingsProductGroups}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-product-group"
        >
          {t(
            writeSettingsProductGroups
              ? 'shared:action.cancel'
              : 'shared:action.close',
          )}
        </Button>
        {writeSettingsProductGroups && (
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(submit)}
            data-testid="dialog-add-product-group"
          >
            {isNewProductGroup
              ? t('shared:action.add')
              : t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
