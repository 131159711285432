import { Box, List, ListItemText, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetOrderQuery } from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'

type Props = {
  isLoading?: boolean
  order?: GetOrderQuery['order']
  onlyShowTotal?: boolean
  sx?: SxProps
}

type TaxRow = {
  name: string
  total: number
  percentage: number
}

export const SalesTaxes = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const { formatCurrency: baseFormatCurrency } = useMoney()

  const formatCurrency = (cash: string | number) =>
    baseFormatCurrency(cash, { forceDecimals: true })

  const order = props.order

  const groupedTaxes = Object.values(
    order?.orderitems.reduce(
      (acc, orderItem) => {
        orderItem.salestaxes?.forEach((salesTax) => {
          if (salesTax.code) {
            const prevTotal = acc[salesTax.code]?.total || 0

            acc[salesTax.code] = {
              name: salesTax.name,
              total: prevTotal + Number(salesTax.moneytax),
              percentage: Number(salesTax.decimaltaxvalue),
            }
          }
        })

        return acc
      },
      {} as Record<string, TaxRow>,
    ) || [],
  )

  const taxTotal = groupedTaxes.reduce((acc, { total }) => acc + total, 0)

  return (
    <Box sx={{ ...props.sx }}>
      {props.onlyShowTotal ? (
        <ListItemSkeleton
          secondaryAction={
            <ListItemText>{formatCurrency(taxTotal)}</ListItemText>
          }
          isLoading={props.isLoading}
          data-testid={'order-sales-taxes'}
        >
          <ListItemText primary={t('orders:view_panel.sales_tax')} />
        </ListItemSkeleton>
      ) : (
        <ListItemAccordion
          aria-controls={'order-sales-taxes'}
          summary={
            <ListItemSkeleton
              secondaryAction={
                <ListItemText>{formatCurrency(taxTotal)}</ListItemText>
              }
              isLoading={props.isLoading}
              data-testid={'order-sales-taxes'}
              sx={{
                '.MuiListItemSecondaryAction-root': {
                  flexShrink: 0,
                },
              }}
            >
              <ListItemText primary={t('orders:view_panel.sales_tax')} />
            </ListItemSkeleton>
          }
        >
          <List>
            {groupedTaxes.map((salesTax, index) => (
              <ListItemSkeleton
                key={index}
                divider
                secondaryAction={formatCurrency(salesTax.total)}
              >
                <ListItemText>
                  {salesTax.name} ({salesTax.percentage}%)
                </ListItemText>
              </ListItemSkeleton>
            ))}
          </List>
        </ListItemAccordion>
      )}
    </Box>
  )
}
