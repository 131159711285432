import { Box, IconButton } from '@mui/material'
import { DuplicateIcon } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  code: string
}

export const ListItemCodeBlock = (props: Props) => {
  const { code } = props
  const { t } = useTranslation(['shared'])
  const { enqueueSnackbar } = useSnackbar()

  const copyCode = useCallback(() => {
    void navigator.clipboard
      .writeText(code)
      .then(() => enqueueSnackbar(t('shared:copied_to_clipboard')))
  }, [code, enqueueSnackbar, t])

  return (
    <Box
      className="MuiListItem-root"
      component="li"
      sx={{
        pb: 2,
      }}
    >
      <Box
        sx={{
          mx: 2,
          p: 2,
          backgroundColor: (theme) => theme.palette.background.page,
          fontSize: '10px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(1),
            top: (theme) => theme.spacing(1),
          }}
          onClick={copyCode}
        >
          <DuplicateIcon />
        </IconButton>
        <Box
          component="code"
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {code}
        </Box>
      </Box>
    </Box>
  )
}
