import { makeVar } from '@apollo/client'
import { ViewPanelState } from '.'

export const eventLogsViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  selectedId: undefined,
})

export const eventLogsFilterPanelVar = makeVar({
  isOpen: false,
})
