import { Box, Button, Paper, Typography } from '@mui/material'
import { ErrorIcon, SitooLogo } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { useRouteError } from 'react-router-dom'
import { SitooGlobalStyles } from '../../styles/global'

export const ErrorPage = () => {
  const { t } = useTranslation(['shared'])
  const routeError = useRouteError()
  const { trackButtonClickEvent } = useTracking()

  usePageTitle(t('shared:error.unexpected_application_error'))

  const error =
    typeof routeError === 'object' && routeError instanceof Error
      ? routeError
      : undefined

  return (
    <Box sx={{ display: 'grid', height: '100vh', gridAutoRows: 'auto 1fr' }}>
      <SitooGlobalStyles />

      <Box
        sx={{
          position: 'relative',
          height: '56px',
          backgroundColor: (theme) => theme.palette.background.paper,
          boxShadow: 'inset 0 -1px 0 0 #e0e0e1',
          display: 'flex',
          alignItems: 'center',
          padding: (theme) => theme.spacing(0, 3),
        }}
      >
        <Box component={SitooLogo} sx={{ maxHeight: 30, maxWidth: '100%' }} />
      </Box>

      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorIcon
          color="error"
          sx={{
            height: (theme) => theme.spacing(8),
            width: (theme) => theme.spacing(8),
          }}
        />

        <Typography variant="heading" sx={{ m: 3, mb: 3, textAlign: 'center' }}>
          {t('shared:error.unexpected_application_error')}
        </Typography>

        {error?.message && (
          <Typography variant="body01">{error?.message}</Typography>
        )}

        {error?.stack && (
          <Paper sx={{ p: 3, m: 4, fontSize: 16 }} elevation={0}>
            <Box component="pre" sx={{ whiteSpace: 'pre-wrap' }}>
              {error?.stack}
            </Box>
          </Paper>
        )}

        <Button
          onClick={trackButtonClickEvent({ name: 'error-reload-page' }, () =>
            location.reload(),
          )}
        >
          {t('shared:action.reload')}
        </Button>
      </Box>
    </Box>
  )
}
