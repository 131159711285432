import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-yaml'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/css/ace.css'
import { forwardRef } from 'react'
import ReactAce, { IAceEditorProps } from 'react-ace/lib/ace'
import { Box, FormHelperText, InputLabel, SxProps } from '@mui/material'

type YamlEditorProps = IAceEditorProps & {
  sx?: SxProps
  label?: string
  error?: boolean
  helperText?: string
}

export const YamlEditor = forwardRef<ReactAce, YamlEditorProps>(
  function YamlEditor(props, ref) {
    const { sx, label, helperText, error, readOnly, ...otherProps } = props

    const componentProps: YamlEditorProps = {
      minLines: 5,
      maxLines: Infinity,
      width: '100% !important',
      showPrintMargin: false,
      tabSize: 2,

      readOnly,
      ...otherProps,

      editorProps: { $blockScrolling: true, ...props.editorProps },
    }

    window.ace?.config.set('useStrictCSP', true)

    return (
      <Box sx={sx}>
        {label && <InputLabel>{label}</InputLabel>}
        <Box
          data-testid="blabla"
          component={AceEditor}
          mode="yaml"
          ref={ref}
          className={[
            'YamlEditorInput',
            readOnly ? 'YamlEditorInput-read-only' : '',
          ]
            .filter(Boolean)
            .join(' ')}
          sx={{
            borderBottom: '2px solid',
            borderColor: (theme) =>
              error ? theme.palette.redBase : theme.palette.divider,
            transition: 'border-color 250ms ease-in-out',

            '.ace_placeholder': {
              transform: 'none',
              padding: 0,
              margin: 0,
              opacity: 1,
            },

            '.ace_text-input': {
              height: 0,
            },

            '&:not(.YamlEditorInput-read-only)': {
              '.ace_gutter': {
                background: (theme) => theme.palette.gray20,
                color: (theme) => theme.palette.blue80,
              },
              '.ace_content': {
                background: (theme) => theme.palette.gray10,
              },
              '.ace_placeholder': {
                color: (theme) => theme.palette.text.disabled,
              },
            },

            '&:focus-within:not(.YamlEditorInput-read-only)': {
              borderColor: (theme) =>
                error ? theme.palette.redBase : theme.palette.blue80,
            },

            '&:not(:focus-within):not(.YamlEditorInput-read-only)': {
              '.ace_active-line': {
                background: (theme) => theme.palette.gray10,
              },

              '.ace_gutter-active-line': {
                background: (theme) => theme.palette.gray20,
              },

              '.ace_cursor': {
                opacity: 0,
              },
            },

            '&.YamlEditorInput-read-only': {
              border: 0,

              '.ace_active-line, .ace_gutter-layer': {
                color: 'inherit',
              },

              '.ace_content, .ace_gutter-active-line, .ace_active-line, .ace_gutter-layer':
                {
                  background: (theme) => theme.palette.gray20,
                },

              '.ace_content, .ace_gutter-active-line, .ace_gutter-layer, .ace_meta.ace_tag, .ace_keyword, .ace_string, .ace_boolean, .ace_constant.ace_numeric, .ace_string.ace_regexp, .ace_variable.ace_class, .ace_constant.ace_buildin, .ace_support.ace_function, .ace_comment, .ace_variable.ace_language, .ace_variable.ace_instance':
                {
                  color: (theme) => theme.palette.gray50,
                },

              '.ace_cursor': {
                color: 'transparent',
              },
            },
          }}
          setOptions={{
            useWorker: false,
          }}
          {...componentProps}
        />

        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </Box>
    )
  },
)
