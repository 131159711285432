/**
 * Converts a number to an integer with a fixed point
 *
 * @example
 *   formatDecimal(0) => 0
 *   formatDecimal(1) => 1
 *   formatDecimal(1.1) => 1.100
 *   formatDecimal(1.12345) => 1.123
 */
export const formatDecimal = (
  decimal: string | number | undefined | null,
): string | undefined => {
  const number = Number(decimal)

  if (Number.isNaN(number)) {
    return undefined
  }

  if (Number.isInteger(number)) {
    return String(number)
  }

  return number.toFixed(3)
}
