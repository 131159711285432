import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { useState, useEffect } from 'react'

export const useDrag = (apiRef: React.MutableRefObject<GridApiPro>) => {
  const [draggedRowId, setDraggedRowId] = useState<string | number | null>(null)

  useEffect(() => {
    if (
      apiRef.current?.subscribeEvent &&
      typeof apiRef.current?.subscribeEvent === 'function'
    ) {
      apiRef.current?.subscribeEvent('rowDragStart', (ev) => {
        const row = ev.row as Record<string, unknown>
        const rowId = row['id']
        if (rowId && (typeof rowId === 'string' || typeof rowId === 'number')) {
          setDraggedRowId(rowId)
        }
      })
      apiRef.current?.subscribeEvent('rowDragEnd', (ev) => {
        const row = ev.row as Record<string, unknown>
        const rowId = row['id']
        if (rowId && (typeof rowId === 'string' || typeof rowId === 'number')) {
          setDraggedRowId(null)
        }
      })
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    draggedRowId,
  }
}
