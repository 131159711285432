import { Button } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { ContextMenu } from './context-menu'
import { Row } from '.'

type Props = {
  warehouseToId: number | undefined
  warehouseFromId: number | undefined
}

export const BulkActions = (props: Props) => {
  const { t } = useTranslation(['shared', 'stock'])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }

  const apiRef = useGridApiContext()

  const selectedIds = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<Row>[]
  ).map((c) => c.sku)

  return (
    <>
      <Button
        id="in-store-replenishment-list-bulk-actionsmenu-button"
        aria-controls={
          isOpen ? 'in-store-replenishment-list-bulk-actionsmenu' : undefined
        }
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={<ChevronSmallDownIcon />}
        size="small"
      >
        {t('shared:label.actions')}
      </Button>

      <ContextMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        selectedIds={selectedIds}
        warehouseToId={props.warehouseToId}
        warehouseFromId={props.warehouseFromId}
      />
    </>
  )
}
