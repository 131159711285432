import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  SectionHeader,
  ShipmentInboundIcon,
  ShipmentOutgoingIcon,
  Link,
  LinkIcon,
} from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetShipmentQuery } from '../../../generated/graphql'
import { formatAdditionalData } from '../../../utils/format/additional-data'

type Props = {
  isLoading?: boolean
  shipment?: GetShipmentQuery['shipment']
}

export const Information = ({ shipment, isLoading }: Props) => {
  const { t } = useTranslation(['shared', 'shipments', 'countries'])

  const origin = [
    [
      shipment?.info?.origin?.contact?.title,
      shipment?.info?.origin?.contact?.first_name,
      shipment?.info?.origin?.contact?.last_name,
    ]
      .filter(Boolean)
      .join(' '),
    shipment?.info?.origin?.contact?.email,

    shipment?.info?.origin?.address?.name,
    shipment?.info?.origin?.address?.address,
    shipment?.info?.origin?.address?.address2,
    shipment?.info?.origin?.address?.city,
    shipment?.info?.origin?.address?.zip,
    shipment?.info?.origin?.address?.state,

    shipment?.info?.origin?.address?.country_id
      ? t(
          `countries:${shipment?.info?.origin?.address?.country_id.toUpperCase()}`,
        )
      : '',
  ]
    .filter(Boolean)
    .join('\n')

  const destination = [
    [
      shipment?.info?.destination?.contact?.title,
      shipment?.info?.destination?.contact?.first_name,
      shipment?.info?.destination?.contact?.last_name,
    ]
      .filter(Boolean)
      .join(' '),
    shipment?.info?.destination?.contact?.email,

    shipment?.info?.destination?.address?.name,
    shipment?.info?.destination?.address?.address,
    shipment?.info?.destination?.address?.address2,
    shipment?.info?.destination?.address?.city,
    shipment?.info?.destination?.address?.zip,
    shipment?.info?.destination?.address?.state,
    shipment?.info?.destination?.address?.country_id
      ? t(
          `countries:${shipment?.info?.destination?.address?.country_id.toUpperCase()}`,
        )
      : '',
  ]
    .filter(Boolean)
    .join('\n')

  const carrierReference = [
    shipment?.info?.carrier?.name,
    shipment?.info?.carrier?.reference,
  ]
    .filter(Boolean)
    .join('\n')

  const additionalData = formatAdditionalData(shipment?.info?.additional_data)

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>
          {t('shipments:view_panel.information')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={!isLoading && !origin}
            isLoading={isLoading}
            data-testid="shipment-info-origin"
            divider
            childrenSkeleton
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            <ListItemIcon>
              <ShipmentOutgoingIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('shipments:view_panel.origin')}
              secondary={origin}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !destination}
            isLoading={isLoading}
            data-testid="shipment-info-destination"
            divider
            childrenSkeleton
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            <ListItemIcon>
              <ShipmentInboundIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('shipments:view_panel.destination')}
              secondary={destination}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !shipment?.info?.note}
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="shipment-info-note"
          >
            <ListItemText
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                },
              }}
              secondary={shipment?.info?.note}
            >
              {t('shipments:view_panel.shipment_note')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !carrierReference}
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="shipment-info-carrier"
          >
            <ListItemText
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                },
              }}
              secondary={carrierReference}
            >
              {t('shipments:view_panel.carrier')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !shipment?.info?.carrier?.tracking_url}
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="shipment-info-track-shipment"
            secondaryAction={
              <IconButton
                href={shipment?.info?.carrier?.tracking_url || ''}
                target="_blank"
              >
                <LinkIcon />
              </IconButton>
            }
          >
            <ListItemText
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                },
              }}
              secondary={
                <>
                  {shipment?.info?.carrier?.tracking_url_title && (
                    <Link
                      href={shipment?.info?.carrier?.tracking_url || ''}
                      target="_blank"
                    >
                      {shipment?.info?.carrier?.tracking_url_title}
                    </Link>
                  )}
                </>
              }
            >
              {t('shipments:view_panel.track_shipment')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && additionalData.length === 0}
            secondaryAction={additionalData.length ? null : '-'}
            isLoading={isLoading}
            data-testid="shipment-info-additional-data"
            divider
            childrenSkeleton
          >
            <ListItemText
              primary={t('shipments:view_panel.additional_data')}
              secondary={additionalData.map(([key, value]) => (
                <Box key={key} component="span" sx={{ display: 'block' }}>
                  {key}: {value}
                </Box>
              ))}
            />
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
