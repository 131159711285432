import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useSessionStorage } from 'react-use'
import { RootRoute } from '../../pages'

export const SESSION_BACK_PATH = 'backPath'

export const useNavigationBackPath = () => {
  const location = useLocation()
  const [_, setBackPath] = useSessionStorage<string | null>(SESSION_BACK_PATH)

  useEffect(() => {
    const isSettingsPath = matchPath(
      { path: RootRoute.Settings, end: false },
      location.pathname,
    )

    if (!isSettingsPath) {
      setBackPath(location.pathname)
    }
  }, [location, setBackPath])
}
