import { ListItemIcon, ListItemText, SxProps, Theme } from '@mui/material'
import { UserDefaultIcon } from '@sitoo/mui-components'
import { ReactNode, forwardRef } from 'react'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GenericShipmentContactFields } from '../../shared'

type Props = {
  fallbackTitle?: string
  contact?: GenericShipmentContactFields | null
  sx?: SxProps<Theme>
  disabled?: boolean
  error?: boolean

  isLoading?: boolean
  hide?: boolean
  divider?: boolean
  secondaryAction?: ReactNode
}

export const ContactListItem = forwardRef<HTMLLIElement, Props>(
  function ContactListItem(
    {
      fallbackTitle,
      contact,
      sx,
      disabled,
      error,

      ...otherProps
    },
    ref,
  ) {
    const name = contact?.referencename

    return (
      <ListItemSkeleton
        ref={ref}
        childrenSkeleton
        sx={{
          whiteSpace: 'pre-line',
          minHeight: (theme) => theme.spacing(8),
          ...sx,
        }}
        {...otherProps}
      >
        <ListItemIcon
          sx={{
            ...(error
              ? {
                  color: 'error',
                }
              : disabled
                ? {
                    color: 'disabled',
                  }
                : {}),
          }}
        >
          <UserDefaultIcon />
        </ListItemIcon>
        <ListItemText
          primary={name || fallbackTitle}
          sx={{
            ...(error
              ? {
                  color: 'error',
                }
              : disabled
                ? {
                    color: 'disabled',
                  }
                : {}),
          }}
          secondaryTypographyProps={{
            sx: {
              '&.MuiListItemText-secondary': {
                ...(error
                  ? {
                      color: 'error',
                    }
                  : disabled
                    ? {
                        color: 'disabled',
                      }
                    : {}),
              },
            },
          }}
          secondary={[
            contact?.referenceemail,
            contact?.referencemobile,
            contact?.instructions,
          ]
            .filter(Boolean)
            .join('\n')}
        />
      </ListItemSkeleton>
    )
  },
)
