import { useReactiveVar } from '@apollo/client'
import { Box, useTheme } from '@mui/material'
import { forwardRef } from 'react'
import { navbarHeightReactiveVar } from '../../cache'
export { ViewPanelHeader } from './view-panel-header'
export { ViewPanelImage } from './view-panel-image'

type ViewPanelProps = {
  open: boolean
  children: React.ReactNode
}

export const ViewPanel = forwardRef<HTMLDivElement, ViewPanelProps>(
  function ViewPanel(props, ref) {
    const theme = useTheme()
    const navbarHeight = useReactiveVar(navbarHeightReactiveVar)
    const panelWidth = '377px'

    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          sx={{
            position: 'relative',
            width: props.open ? panelWidth : 0,
            // transform: props.open ? 'translateX(0)' : 'translateX(100%)',
            // transition: (theme) =>
            //   props.open
            //     ? `${theme.transitions.create(['width', 'transform'], {
            //         easing: theme.transitions.easing.sharp,
            //         duration: theme.transitions.duration.leavingScreen,
            //       })}, ${theme.transitions.create(['height'], {
            //         delay: 0,
            //         duration: 0,
            //       })}`
            //     : `${theme.transitions.create(['width', 'transform'], {
            //         easing: theme.transitions.easing.sharp,
            //         duration: theme.transitions.duration.enteringScreen,
            //       })}, ${theme.transitions.create(['height'], {
            //         delay: theme.transitions.duration.enteringScreen,
            //         duration: 0,
            //       })}`,

            [theme.breakpoints.down('sm')]: {
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              left: 0,
              zIndex: (theme) => theme.zIndex.pageHeader,
            },
          }}
        >
          <Box
            sx={{
              background: (theme) => theme.palette.background.default,
              overflow: 'hidden',
              position: 'absolute',
              right: 0,

              height: props.open ? `calc(100vh - ${navbarHeight || 0}px)` : 0,
              width: props.open ? panelWidth : 0,
              // transform: props.open ? 'translateX(0)' : 'translateX(100%)',
              // transition: (theme) =>
              //   props.open
              //     ? `${theme.transitions.create(['width', 'transform'], {
              //         easing: theme.transitions.easing.sharp,
              //         duration: theme.transitions.duration.leavingScreen,
              //       })}, ${theme.transitions.create(['height'], {
              //         delay: 0,
              //         duration: 0,
              //       })}`
              //     : `${theme.transitions.create(['width', 'transform'], {
              //         easing: theme.transitions.easing.sharp,
              //         duration: theme.transitions.duration.enteringScreen,
              //       })}, ${theme.transitions.create(['height'], {
              //         delay: theme.transitions.duration.enteringScreen,
              //         duration: 0,
              //       })}`,

              [theme.breakpoints.down('sm')]: {
                width: '100vw',
                maxWidth: '100vw',
              },
            }}
          >
            <Box
              ref={ref}
              sx={{
                background: (theme) => theme.palette.background.default,
                position: 'fixed',
                width: panelWidth,
                maxWidth: panelWidth,
                overflowY: 'scroll',
                height: `calc(100vh - ${navbarHeight || 0}px)`,
                borderLeft: (theme) => `1px solid ${theme.palette.divider}`,

                [theme.breakpoints.down('sm')]: {
                  width: '100vw',
                  maxWidth: '100vw',
                },
              }}
            >
              {props.children}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  },
)
