import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { ServiceOrderState } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

export enum ServiceOrderStateCategory {
  ALL = 'all',
  ACTIVE = 'active',
}

type QueryParamsState = {
  state?: ServiceOrderStateCategory | ''
}

export const StateFilter = () => {
  const { t } = useTranslation('service_orders')
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'state'
  const defaultValue = ServiceOrderStateCategory.ALL

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const state = queryParams[filterKey] ? queryParams[filterKey] : undefined

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    if (state === ServiceOrderStateCategory.ACTIVE) {
      setFilter(filterKey, {
        label: t('state_active'),
        value: state,
      })
    } else {
      setFilter(filterKey, {
        isDefault: true,
        label: t('state_all'),
        value: defaultValue,
      })
    }
  }, [state, setFilter, removeFilter, t, defaultValue])

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  return (
    <TextField
      data-testid="service-order-state-filter"
      onChange={(event) => {
        setQueryParams({
          [filterKey as ServiceOrderState]: event.target.value || undefined,
        })
      }}
      value={state ?? defaultValue}
      SelectProps={{
        displayEmpty: true,
      }}
      select
      label={t('status')}
    >
      <MenuItem
        divider
        value={ServiceOrderStateCategory.ALL}
        onClick={trackButtonClickEvent({
          name: 'service-order-state-filter-select-category',
          state: 'Show all',
        })}
      >
        <Typography variant="body02">{t('show_all')}</Typography>
      </MenuItem>
      <MenuItem
        divider
        value={ServiceOrderStateCategory.ACTIVE}
        onClick={trackButtonClickEvent({
          name: 'service-order-state-filter-select-category',
          state: 'Show active',
        })}
      >
        <Typography variant="body02">{t('show_active')}</Typography>
      </MenuItem>
    </TextField>
  )
}
