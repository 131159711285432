/**
 * Converts a typical `additionaldata` object to an array of tuples
 */
export const formatAdditionalData = (
  data: unknown,
): Array<[string, string]> => {
  if (data && typeof data === 'object') {
    return Object.entries(data)
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([key, value]) => {
        let formattedValue = ''

        if (typeof value === 'string' || typeof value === 'number') {
          formattedValue = String(value)
        }

        return [key, formattedValue]
      })
  }

  return []
}
