import { Grid, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { FormFieldset } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDayJs } from '../../../../hooks/day-js'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { BaseShipmentFormContext } from '../../shared'

export const CarrierField = () => {
  const { register, formState, control, setError, clearErrors } =
    useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments', 'shared'])
  const { getDateTimeFormat } = useLocalizedDate()
  const dayJs = useDayJs()

  return (
    <FormFieldset label={t('shipments:shipment_form.carrier_fieldset')}>
      <TextField
        data-testid="carrier-name"
        error={!!formState?.errors.info?.carrier?.name}
        fullWidth
        helperText={formState?.errors.info?.carrier?.name?.message}
        label={t('shipments:shipment_form.name')}
        {...register('info.carrier.name')}
        sx={{ mb: 2 }}
      />

      <TextField
        data-testid="carrier-service"
        error={!!formState?.errors.info?.carrier?.service}
        fullWidth
        helperText={formState?.errors.info?.carrier?.service?.message}
        label={t('shipments:shipment_form.service')}
        {...register('info.carrier.service')}
        sx={{ mb: 2 }}
      />

      <TextField
        data-testid="carrier-reference"
        error={!!formState?.errors.info?.carrier?.reference}
        fullWidth
        helperText={formState?.errors.info?.carrier?.reference?.message}
        label={t('shipments:shipment_form.reference')}
        {...register('info.carrier.reference')}
        sx={{ mb: 2 }}
      />

      <TextField
        data-testid="tracking-url"
        error={!!formState?.errors.info?.carrier?.tracking_url}
        fullWidth
        helperText={formState?.errors.info?.carrier?.tracking_url?.message}
        placeholder="https://wwww.site.com"
        label={t('shipments:shipment_form.tracking_url')}
        required
        {...register('info.carrier.tracking_url', {
          required: t('shared:validation.field_required', {
            field: t('shipments:shipment_form.tracking_url'),
          }),
        })}
        sx={{ mb: 2 }}
      />

      <TextField
        data-testid="tracking-url-title"
        error={!!formState?.errors.info?.carrier?.tracking_url_title}
        fullWidth
        helperText={
          formState?.errors.info?.carrier?.tracking_url_title?.message
        }
        label={t('shipments:shipment_form.tracking_url_title')}
        {...register('info.carrier.tracking_url_title')}
        sx={{ mb: 2 }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="info.carrier.estimated_pickup_at"
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                sx={{ mb: 2 }}
                slotProps={{
                  textField: {
                    label: t('shipments:shipment_form.estimated_pickup_at'),
                    error: !!error?.message,
                    helperText: error?.message,
                    inputProps: {
                      'data-testid': 'carrier-estimated-pickup-date',
                    },
                  },
                  openPickerButton: {
                    edge: 'start',
                  },
                }}
                value={field.value ? dayJs(field.value).tz() : null}
                format={getDateTimeFormat()}
                onChange={(newValue) => {
                  if (newValue && !newValue.isValid()) {
                    setError('info.carrier.estimated_pickup_at', {
                      message: t('shared:validation.field_invalid', {
                        field: t('shipments:shipment_form.estimated_pickup_at'),
                      }),
                      type: 'validate',
                    })
                  } else {
                    clearErrors('info.carrier.estimated_pickup_at')
                  }

                  if (newValue?.isValid) {
                    field.onChange(newValue.toJSON())
                  }
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="info.carrier.estimated_delivery_at"
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                sx={{ mb: 2 }}
                slotProps={{
                  textField: {
                    label: t('shipments:shipment_form.estimated_delivery_at'),
                    error: !!error?.message,
                    helperText: error?.message,
                    inputProps: {
                      'data-testid': 'carrier-estimated-delivery-date',
                    },
                  },
                  openPickerButton: {
                    edge: 'start',
                  },
                }}
                value={field.value ? dayJs(field.value).tz() : null}
                format={getDateTimeFormat()}
                onChange={(newValue) => {
                  if (newValue && !newValue.isValid()) {
                    setError('info.carrier.estimated_delivery_at', {
                      message: t('shared:validation.field_invalid', {
                        field: t(
                          'shipments:shipment_form.estimated_delivery_at',
                        ),
                      }),
                      type: 'validate',
                    })
                  } else {
                    clearErrors('info.carrier.estimated_delivery_at')
                  }

                  if (newValue?.isValid) {
                    field.onChange(newValue.toJSON())
                  }
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormFieldset>
  )
}
