import { Box, Paper, Skeleton, Stack } from '@mui/material'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { QuestionIcon, ZoomInIcon } from '@sitoo/mui-components'
import { useState } from 'react'
import { ImageViewerDialog } from '../../image-viewer-dialog'
import { SwiperOverrides } from '../../swiper'

type ViewPanelImageProps = {
  imageUrls: string[]
  isLoading?: boolean
}

export const ViewPanelImage = (props: ViewPanelImageProps) => {
  const { isLoading, imageUrls } = props
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const showImageDetails = (index: number) => {
    setActiveIndex(index)
    setOpen(true)
  }

  return (
    <>
      <ImageViewerDialog
        open={open}
        onClose={() => setOpen(false)}
        imageUrls={imageUrls}
        activeIndex={activeIndex}
      />

      <Paper elevation={0} sx={{ height: (theme) => theme.spacing(27) }}>
        {isLoading ? (
          <Stack direction="column" height="100%">
            <Skeleton variant="rectangular" height="100%" />
            <Skeleton
              variant="rectangular"
              width="20%"
              sx={{ margin: (theme) => theme.spacing(1, 'auto') }}
            />
          </Stack>
        ) : (
          <Box
            component={Swiper}
            modules={[Pagination]}
            slidesPerView={1}
            pagination={{
              clickable: true,
              dynamicBullets: true,
              dynamicMainBullets: 5,
            }}
            sx={{
              height: '100%',
              '--swiper-theme-color': (theme) => theme.palette.text.primary,
              ...SwiperOverrides,
            }}
          >
            {imageUrls.length === 0 && (
              <SwiperSlide>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    background: (theme) => theme.palette.background.default,
                  }}
                >
                  <QuestionIcon
                    sx={{
                      height: (theme) => theme.spacing(5),
                      width: (theme) => theme.spacing(5),
                      color: (theme) => theme.palette.gray50,
                    }}
                    fontSize="medium"
                  />
                </Box>
              </SwiperSlide>
            )}
            {imageUrls.length > 0 && (
              <ZoomInIcon
                sx={{
                  position: 'absolute',
                  top: (theme) => theme.spacing(2),
                  right: (theme) => theme.spacing(2),
                  cursor: 'zoom-in',
                  zIndex: 1,
                }}
                fontSize="medium"
              />
            )}
            {imageUrls.map((url, index) => (
              <SwiperSlide
                key={`${url}-${index}`}
                onClick={() => showImageDetails(index)}
              >
                <Box
                  component="img"
                  src={url}
                  sx={{
                    display: 'block',
                    margin: 'auto',
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                    cursor: 'zoom-in',
                  }}
                />
              </SwiperSlide>
            ))}
          </Box>
        )}
      </Paper>
    </>
  )
}
