import { Chip } from '@mui/material'
import { ShipmentStateV2 } from '../../../generated/graphql'

type ShipmentStateChipProps = {
  label: string
  state?: ShipmentStateV2 | null
}

const tagColorMap: { [key: string]: 'gray' | 'black' | 'green' | 'red' } = {
  [ShipmentStateV2.New]: 'gray',
  [ShipmentStateV2.InTransit]: 'black',
  [ShipmentStateV2.Received]: 'green',
  [ShipmentStateV2.Cancelled]: 'red',
}

export const ShipmentStateChip = ({ label, state }: ShipmentStateChipProps) => {
  return (
    <Chip
      label={label}
      size="small"
      color={state ? tagColorMap[state] : 'green'}
    />
  )
}
