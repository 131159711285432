import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  List,
  ListItemText,
} from '@mui/material'
import { EmailIcon, PhoneIcon, SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { ServiceOrderQuery } from '../../../generated/graphql'

type Props = {
  isLoading: boolean
  customer?: ServiceOrderQuery['serviceOrder']['customer']
}

export const Customer = ({ customer, isLoading }: Props) => {
  const { t } = useTranslation(['service_orders'])

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="customer">
        <SectionHeader sx={{ p: 0 }}>{t('customer')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List data-testid="service-order-customer-details">
          <ListItemSkeleton
            hide={!isLoading && !customer}
            isLoading={isLoading}
            divider
            childrenSkeleton
            secondaryAction={
              <>
                {customer?.email && (
                  <IconButton
                    data-testid="service-order-mailto-button"
                    href={`mailto:${customer.email}`}
                  >
                    <EmailIcon />
                  </IconButton>
                )}
                {customer?.mobile && (
                  <IconButton
                    data-testid="service-order-tel-button"
                    href={`tel:${customer.mobile}`}
                  >
                    <PhoneIcon />
                  </IconButton>
                )}
              </>
            }
          >
            <ListItemText
              primary={[customer?.first_name, customer?.last_name].join(' ')}
              secondary={
                <>
                  {customer?.mobile && (
                    <>
                      {customer.mobile}
                      <br />
                    </>
                  )}

                  {customer?.email}
                </>
              }
            />
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
