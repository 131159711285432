import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { SubmitErrorHandler, UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { NewShipmentFormContext } from '.'
import { RootRoute } from '../..'
import {
  ShipmentActionInput,
  ShipmentPackageDimensionsUnitEnum,
  ShipmentPackageInput,
  ShipmentPackageWeightUnitEnum,
  AddShipmentDocument,
  ShipmentState,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { stripEmptyProperties } from '../../../utils/strip-empty-properties'
import { shipmentViewPanelVar } from '../../shipments'
import { ShipmentPackage } from '../fields/packages'

type Props = {
  setError: UseFormSetError<NewShipmentFormContext>
}

export const formatPackage = (
  shipmentPackage: ShipmentPackage,
  shipmentState: ShipmentState,
): ShipmentActionInput['package'] => {
  const formattedPackage: ShipmentActionInput['package'] = {
    barcode: shipmentPackage.barcode || '',
  }

  if (shipmentPackage.items && shipmentState === ShipmentState.New) {
    formattedPackage.items =
      shipmentPackage.items?.map((item) => ({
        sku: item.sku,
        product_name: item.product_name,
        quantity: item.quantity,
        barcode: item.product?.barcode || '',
      })) || []
  }

  if (typeof shipmentPackage.weight?.weight === 'number') {
    formattedPackage.weight = {
      weight: Number(shipmentPackage.weight?.weight),
      unit: shipmentPackage.weight?.unit || ShipmentPackageWeightUnitEnum.Kg,
    }
  }

  const hasDimensions = [
    shipmentPackage.dimensions?.width,
    shipmentPackage.dimensions?.height,
    shipmentPackage.dimensions?.length,
  ].some(Number.isFinite)

  if (hasDimensions) {
    formattedPackage.dimensions = {
      unit:
        shipmentPackage.dimensions?.unit ||
        ShipmentPackageDimensionsUnitEnum.Cm,
      height: Number(shipmentPackage.dimensions?.height),
      width: Number(shipmentPackage.dimensions?.width),
      length: Number(shipmentPackage.dimensions?.length),
    }
  }

  return formattedPackage
}

export const useNewShipmentFormSubmit = (props: Props) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const { setError } = props
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const [addShipment, { loading: addLoading }] =
    useMutation(AddShipmentDocument)

  const { enqueueSnackbar } = useSnackbar()

  const setFormError = useServerValidation<NewShipmentFormContext>(
    'shipments',
    setError,
    {
      resolveFieldFromProperty: (property) => property,
    },
  )

  const isSubmitting = addLoading

  const formatShipment = (
    data: NewShipmentFormContext,
  ): NewShipmentFormContext => {
    return stripEmptyProperties(data)
  }

  const onSubmit = async (data: NewShipmentFormContext) => {
    try {
      trackButtonClick({ name: 'shipment-save' })
      const { data: newShipment } = await addShipment({
        variables: {
          data: {
            ...formatShipment(data),
            packages: data.packages
              .map((p) => formatPackage(p, ShipmentState.New))
              .filter(Boolean) as ShipmentPackageInput[],
          },
        },
      })
      trackFormSuccess({ name: 'shipment' })
      shipmentViewPanelVar({
        isOpen: true,
        shipmentId: newShipment?.addShipment.id || '',
      })
      enqueueSnackbar(t('shipments:shipment_message.success_add'))
      navigate(generatePath(RootRoute.Shipments))
    } catch (error) {
      setFormError(error)
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments:shipment_message.failure_add'),
      )

      trackFormError({
        name: 'shipment',

        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<NewShipmentFormContext> = (_errors) => {
    enqueueSnackbar(t('shipments:error.generic'), {
      variant: 'error',
      autoHideDuration: 2000,
    })
  }

  return { onSubmit, isSubmitting, onError }
}
