import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMe } from '../me'

export type FormatCurrencyOptions = {
  currency?: string
  forceDecimals?: boolean
  hideCurrency?: boolean
  useGrouping?: boolean
  isNegative?: boolean
}

export type FormatCurrency = (
  value: number | string,
  options?: FormatCurrencyOptions,
) => string

export const useMoney = () => {
  const { i18n } = useTranslation()
  const { me } = useMe()
  const currentCurrency = me?.currentSite.currencycode

  const formatCurrency: FormatCurrency = useCallback(
    (value: number | string, options?: FormatCurrencyOptions): string => {
      const currency = options?.currency || currentCurrency

      if (!currency) return String(value)

      let minimumFractionDigits = options?.forceDecimals ? 2 : undefined

      if (!options?.forceDecimals) {
        const isInteger = Number.isInteger(Number(value))
        minimumFractionDigits = isInteger ? 0 : 2
      }

      let numberValue = Number(value || 0)

      if (numberValue !== 0 && options?.isNegative) {
        numberValue = numberValue * -1
      }

      let parts = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency,
        minimumFractionDigits,
        useGrouping: options?.useGrouping,
      }).formatToParts(numberValue)

      if (options?.hideCurrency) {
        parts = parts.filter(
          (x) => x.type !== 'currency' && x.type !== 'literal',
        )
      }
      parts = parts.map((x) =>
        x.type === 'minusSign' ? { type: 'minusSign', value: '\u2011' } : x,
      )
      return parts.map((x) => x.value).join('')
    },
    [currentCurrency, i18n.language],
  )

  const formatEditableCurrency = useCallback(
    (value: number | string, opts?: Pick<FormatCurrencyOptions, 'currency'>) =>
      formatCurrency(value, {
        ...opts,
        hideCurrency: true,
        useGrouping: false,
        forceDecimals: false,
      }),
    [formatCurrency],
  )

  const formatCurrencyRange = useCallback(
    (
      initial: number | string,
      final: number | string,
      options?: Pick<FormatCurrencyOptions, 'forceDecimals' | 'currency'>,
    ): string => {
      const initialCurrency = formatCurrency(initial, options)
      const finalCurrency = formatCurrency(final, options)

      if (initialCurrency === finalCurrency) return initialCurrency

      return [initialCurrency, finalCurrency].join(' - ')
    },
    [formatCurrency],
  )

  const formatStringCurrencyToNumber = useCallback((money: string): number => {
    return parseFloat(money.trim().replaceAll(',', '.'))
  }, [])

  return {
    formatCurrency,
    formatEditableCurrency,
    formatCurrencyRange,
    formatStringCurrencyToNumber,
  }
}
