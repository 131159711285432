import { useQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import { SalesTaxIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ViewPanel, ViewPanelHeader } from '../../../../components/view-panel'
import { SalesTaxDocument } from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { Information } from './information'
import { Rules } from './rules'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { RootRoute } from '../../..'

type Props = {
  open: boolean
  onClose: () => void
  salesTaxId: number | undefined
}

export const SalesTaxViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'settings'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()

  const generatePath = useAbsolutePath()

  const { data, loading: fetchLoading } = useQuery(SalesTaxDocument, {
    variables: { salesTaxId: props.salesTaxId as number },
    skip: !props.salesTaxId,
  })

  const salesTax = data?.salesTax
  const isLoading = fetchLoading

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={isLoading}
        title={salesTax?.name || ''}
        section={t('settings:sales_tax.page_title')}
        onClose={props.onClose}
        actions={
          props.salesTaxId && (
            <Button
              component={Link}
              to={generatePath(RootRoute.SettingsSalesTax, {
                id: props.salesTaxId,
              })}
              color="secondary"
              size="small"
              data-testid="edit-sales-tax-button"
              onClick={() => {
                trackButtonClick({
                  name: 'sales-tax-panel-edit',
                  id: props.salesTaxId,
                })
              }}
            >
              {t('shared:action.edit')}
            </Button>
          )
        }
        onBack={() => {
          trackButtonClick({ name: 'sales-tax-panel-go-back' })
        }}
        sectionIcon={<SalesTaxIcon />}
      />
      <Box sx={{ background: (theme) => theme.palette.background.default }}>
        <Information salesTax={salesTax} />
        <Rules salesTaxRules={salesTax?.productgrouprules} />
      </Box>
    </ViewPanel>
  )
}
