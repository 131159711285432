import { Button } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrderContextMenu } from '../../order-context-menu'
import { useGridApiContext } from '@mui/x-data-grid-pro'

export const BulkActions = () => {
  const { t } = useTranslation(['shared', 'orders'])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }

  const apiRef = useGridApiContext()

  const selectedIds = Array.from(
    apiRef.current.getSelectedRows().keys(),
  ) as number[]

  return (
    <>
      <Button
        data-testid="orders-list-bulk-actionsmenu-button"
        id="orders-list-bulk-actionsmenu-button"
        aria-controls={isOpen ? 'orders-list-bulk-actionsmenu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={<ChevronSmallDownIcon />}
        size="small"
      >
        {t('shared:label.actions')}
      </Button>

      <OrderContextMenu
        anchorEl={anchorEl}
        isOpen={isOpen}
        onClose={() => setAnchorEl(null)}
        selectedIds={selectedIds}
      />
    </>
  )
}
