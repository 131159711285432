import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseServiceOrderFormContext } from '../shared'

export const NoteField = () => {
  const { register, formState } = useFormContext<BaseServiceOrderFormContext>()
  const { t } = useTranslation(['service_orders'])

  return (
    <FormFieldset label={t('note')}>
      <TextField
        error={!!formState?.errors.note}
        fullWidth
        multiline
        rows={2}
        helperText={formState?.errors.note?.message}
        label={t('note')}
        data-testid="note-input"
        {...register('note')}
        sx={{ mb: 2 }}
      />
    </FormFieldset>
  )
}
