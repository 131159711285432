import { Box, ButtonBase, IconButton, Chip } from '@mui/material'
import {
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { CloudUpIcon } from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Row } from '.'
import { ProductFormContext } from '..'
import { ArrayElement } from '../../../utils/types'
import { VariantImagesField } from './variant-images-field'
import { DataGridImage } from '../../../components/data-grid-image'

type VariantImagesProps = GridRenderEditCellParams<
  Row,
  Exclude<
    ArrayElement<
      ProductFormContext['product']['childVariants']
    >['productImages'],
    null | undefined
  >
>

export const VariantImagesRenderer = (props: VariantImagesProps) => {
  const apiRef = useGridApiContext()
  const { watch, setValue } = useFormContext<ProductFormContext>()
  const childVariants = watch('product.childVariants') || []
  const currentChildVariant = childVariants?.find((x) => x.id === props.id)
  const [imageDialogOpen, setImageDialogOpen] = useState(false)
  const fileUrl = props?.formattedValue?.[0]?.fileUrl
  const counter = props?.formattedValue?.length

  const setFiles = (product: Row, images: UploadedFile[]) => {
    const productImages = images.map((f) => ({
      fileUrl: f.fileUrl!,
      id: f.id!,
      file: f.file,
    }))

    if (currentChildVariant) {
      currentChildVariant.productImages = productImages

      setValue('product.childVariants', childVariants)

      if (currentChildVariant.isMainVariant) {
        setValue('product.productImages', productImages)
      }
    }

    void apiRef.current.setEditCellValue({
      id: product.id,
      field: 'productImages',
      value: productImages,
    })
  }

  const onClose = () => {
    setImageDialogOpen(false)

    try {
      apiRef.current.stopCellEditMode({
        id: props.id || 0,
        field: 'productImages',
      })
      // eslint-disable-next-line no-empty
    } catch {}
  }

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'cellEditStart',
      (params, event) => {
        if (
          currentChildVariant?.id === params.id &&
          params.field === 'productImages'
        ) {
          event.defaultMuiPrevented = true
        }
      },
      { isFirst: true },
    )
  }, [apiRef, currentChildVariant?.id, props.id])

  return (
    <>
      <VariantImagesField
        open={imageDialogOpen}
        onClose={onClose}
        id={Number(props.id)}
        setFiles={setFiles}
        editable={props.isEditable}
      />

      {fileUrl && (
        <ButtonBase
          onClick={() => setImageDialogOpen(true)}
          sx={{ height: '100%' }}
        >
          <DataGridImage src={fileUrl} />

          <Chip
            label={counter}
            color="black"
            size="small"
            sx={{
              position: 'absolute',
              pointerEvents: 'none',
              right: (theme) => theme.spacing(0.5),
              bottom: (theme) => theme.spacing(0.5),
            }}
          />
        </ButtonBase>
      )}

      {!fileUrl && (
        <Box
          className="MuiDataGrid-cellContent"
          sx={{
            '.MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editable &.MuiDataGrid-cellContent':
              {
                width: '100%',
                display: 'grid',
                justifyContent: 'center',
                padding: 0,
              },
          }}
        >
          <IconButton onClick={() => setImageDialogOpen(true)}>
            <CloudUpIcon fontSize="medium" />
          </IconButton>
        </Box>
      )}
    </>
  )
}
