import { Box, Stack } from '@mui/material'

type Props = React.PropsWithChildren & {
  active?: boolean
}

export const Indicator = (props: Props) => {
  return (
    <Stack direction="row" spacing={1.25} alignItems="center">
      <Box
        sx={{
          width: (theme) => theme.spacing(1.25),
          height: (theme) => theme.spacing(1.25),
          borderRadius: '100%',
          flexShrink: 0,
          backgroundColor: (theme) =>
            props.active ? theme.palette.greenBase : theme.palette.redBase,
        }}
      />
      <Box>{props.children}</Box>
    </Stack>
  )
}
