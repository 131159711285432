import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ProductFormContext } from '../..'
import { useTracking } from '../../../../hooks/tracking'

export type DeleteCampaignDialogProps = {
  open: boolean
  variantIds: { id: number; exists: boolean }[]
  dataTestid?: string
  onClose(): void
  onSuccess?(): void
  onError?(): void
}

export const DeleteVariantDialog = (props: DeleteCampaignDialogProps) => {
  const { t } = useTranslation('products')
  const dialogName = 'delete-variant'
  const { trackButtonClickEvent, trackDialogOpen, trackDialogClose } =
    useTracking()
  const prevOpen = usePrevious(props.open)
  const { getValues, setValue } = useFormContext<ProductFormContext>()

  const deleteCampaigns = () => {
    trackButtonClickEvent({ name: `${dialogName}-delete` })

    const childVariants = getValues('product.childVariants')
    const newChildVariants =
      childVariants?.filter(
        (x) => !props.variantIds.map((v) => v.id).includes(x.id),
      ) || []

    setValue('product.childVariants', newChildVariants, {
      shouldDirty: true,
    })

    props.onClose()
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose()
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={deleteCampaigns}
      title={t('delete_variants_dialog.title', {
        count: props.variantIds.length,
      })}
      text={t('delete_variants_dialog.description')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
      sx={{
        '.MuiDialogContent-root': {
          whiteSpace: 'break-spaces',
        },
      }}
    />
  )
}
