import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../../../hooks/tracking'
import { DeletePrice } from './delete-price'
import { SetPurchasePrice } from './set-purchase-price'

type Props = {
  refetch(): Promise<unknown>
}

export const BulkActions = (props: Props) => {
  const { t } = useTranslation(['shared', 'purchase_prices'])
  const { trackButtonClickEvent } = useTracking()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const [showSetPurchasePrice, setShowSetPurchasePrice] = useState(false)
  const [showDeletePrice, setShowDeletePrice] = useState(false)

  return (
    <>
      <Button
        id="purchase-price-list-bulk-action-menu-button"
        data-testid="purchase-price-list-bulk-action-menu-button"
        aria-controls={
          isOpen ? 'purchase-price-list-bulk-action-menu' : undefined
        }
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={<ChevronSmallDownIcon />}
        size="small"
      >
        {t('shared:label.actions')}
      </Button>
      <Menu
        id="purchase-price-list-bulk-action-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'purchase-price-list-bulk-action-menu-button',
        }}
      >
        <MenuItem
          onClick={trackButtonClickEvent(
            { name: 'purchase-price-list-bulk-action-menu-set-purchase-price' },
            () => {
              setShowSetPurchasePrice(true)
              closeMenu()
            },
          )}
          data-testid="grid-menu-set-purchase-price"
        >
          <Typography variant="body02">
            {t('purchase_prices:set_purchase_price')}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={trackButtonClickEvent(
            {
              name: 'purchase-price-list-bulk-action-menu-delete-purchase-price',
            },
            () => {
              setShowDeletePrice(true)
              closeMenu()
            },
          )}
          data-testid="grid-menu-delete-purchase-price"
        >
          <Typography variant="body02">
            {t('purchase_prices:delete_purchase_price')}
          </Typography>
        </MenuItem>
      </Menu>

      <SetPurchasePrice
        isOpen={showSetPurchasePrice}
        refetch={props.refetch}
        onClose={() => {
          setShowSetPurchasePrice(false)
        }}
      />

      <DeletePrice
        isOpen={showDeletePrice}
        refetch={props.refetch}
        onClose={() => {
          setShowDeletePrice(false)
        }}
      />
    </>
  )
}
