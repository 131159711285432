import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { DashboardDocument } from '../../../generated/graphql'
import { useNavigate, useParams } from 'react-router-dom'
import { DASHBOARD_URL } from '../../../utils/constants'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { RootRoute } from '../..'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

export const SettingsDashboardPage = () => {
  const { t } = useTranslation(['pos_settings'])
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { id } = useParams<{ id: string }>()
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  // TODO: Replace this with actual data from the backend
  const config = useMemo(
    () => ({
      separator_comma: ',',
      separator_thousands: ' ',
      format_date_time_full: 'yyyy-MM-dd HH:mm:ss',
      format_date_time: 'yyyy-MM-dd HH:mm',
      format_date: 'yyyy-MM-dd',
      format_time: 'HH:mm',
      currency_prepend: '',
      currency_append: ' kr',
      num_decimals: 0,
    }),
    [],
  )

  const { data, loading } = useQuery(DashboardDocument, {
    variables: { id: String(id) },
    skip: !id,
  })

  const isValidMessageData = (event: MessageEvent) => {
    const data = event.data as unknown

    return (
      data &&
      typeof data === 'object' &&
      'message' in data &&
      data.message === 'ready' &&
      event.origin === DASHBOARD_URL
    )
  }

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (!isValidMessageData(event)) return

      if (!data?.dashboard) return

      iframeRef.current?.contentWindow?.postMessage(
        {
          type: 'dashboard-data',
          config,
          dashboard: data.dashboard,
        },
        DASHBOARD_URL,
      )
    },
    [config, data?.dashboard],
  )

  useEffect(() => {
    if (loading) return

    if (!data?.dashboard) {
      enqueueSnackbar(t('pos_settings:dashboards.not_found'), {
        variant: 'error',
      })
      navigate(generatePath(RootRoute.SettingsPosDashboards))
    }
  }, [data?.dashboard, enqueueSnackbar, generatePath, loading, navigate, t])

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)

    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [receiveMessage, data])

  // Fetch dashboard data, then render iframe
  if (loading) return null

  return (
    <Box
      ref={iframeRef}
      component="iframe"
      src={DASHBOARD_URL}
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
        border: 'none',
      }}
    />
  )
}
