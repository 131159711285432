import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { ArchivedFilter } from '../shipments-list/archived-filter'

type ShipmentsFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const ShipmentsFilterViewPanel = (
  props: ShipmentsFilterViewPanelProps,
) => {
  const { t } = useTranslation(['shipments_v2'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>{t('page_title')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <ArchivedFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
