import {
  Box,
  ListItem,
  ListItemText,
  Switch,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { ChevronLeftIcon } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActiveVariantsForm } from '.'

export type ActivateVariantsSecondStepProps = {
  onNext(): void
  onCancel(): void
  onBack(): void
}

export const ActivateVariantsSecondStep = ({
  onNext,
  onBack,
  onCancel,
}: ActivateVariantsSecondStepProps) => {
  const { t } = useTranslation(['products', 'shared'])
  const activeVariantsForm = useFormContext<ActiveVariantsForm>()

  return (
    <>
      <DialogTitle>
        <>
          <ChevronLeftIcon
            sx={{ cursor: 'pointer' }}
            onClick={onBack}
            fontSize="medium"
          />
          {t('products:product_form.variant_group_generation')}
        </>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            m: (theme) => theme.spacing(2, -3),
          }}
        >
          <Controller
            control={activeVariantsForm.control}
            name="generateSku"
            render={({ field }) => (
              <ListItem
                secondaryAction={
                  <Switch
                    data-testid="generate-sku-switch"
                    checked={
                      typeof field.value === 'boolean' ? field.value : false
                    }
                    onChange={(event) => {
                      field.onChange(event.target.checked)
                    }}
                  />
                }
                sx={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <ListItemText
                  data-testid="generate-sku-text"
                  secondary={t(
                    'products:product_form.variant_group_generation_sku_description',
                  )}
                >
                  {t('products:product_form.variant_group_generation_sku')}
                </ListItemText>
              </ListItem>
            )}
          />
          <Controller
            control={activeVariantsForm.control}
            name="generateBarcode"
            render={({ field }) => (
              <ListItem
                secondaryAction={
                  <Switch
                    data-testid="generate-barcodes-switch"
                    checked={
                      typeof field.value === 'boolean' ? field.value : false
                    }
                    onChange={(event) => {
                      field.onChange(event.target.checked)
                    }}
                  />
                }
                sx={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <ListItemText
                  data-testid="generate-barcodes-text"
                  secondary={t(
                    'products:product_form.variant_group_generation_barcode_description',
                  )}
                >
                  {t('products:product_form.variant_group_generation_barcode')}
                </ListItemText>
              </ListItem>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="cancel-variant-activation-button"
          color="secondary"
          onClick={onCancel}
        >
          {t('shared:action.cancel')}
        </Button>
        <Button data-testid="generate-variants-buttton" onClick={onNext}>
          {t('shared:action.generate')}
        </Button>
      </DialogActions>
    </>
  )
}
