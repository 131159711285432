import { useReactiveVar } from '@apollo/client'
import { Box, Container, Stack, Typography, useTheme } from '@mui/material'
import { ChevronLeftIcon, Link } from '@sitoo/mui-components'
import { ReactNode, useEffect, useRef, useState } from 'react'
import {
  navbarHeightReactiveVar,
  pageHeaderHeightReactiveVar,
} from '../../cache'
import { Link as RouterLink } from 'react-router-dom'
import { useWindowScroll } from 'react-use'

export type PageHeaderProps = {
  title: string
  rightColumn?: ReactNode
  subTitle?: ReactNode
  isFlexible?: boolean
  isSticky?: boolean
  children?: ReactNode
} & (
  | { showBackButton?: false }
  | { showBackButton: boolean; backText?: string; backTo: string }
  | { showBackButton: boolean; backText?: string; backButtonClick: () => void }
)

export const PageHeader = (props: PageHeaderProps) => {
  const theme = useTheme()
  const navbarHeight = useReactiveVar(navbarHeightReactiveVar)
  const { y: scrollPositionY } = useWindowScroll()
  const [showShadow, setShowShadow] = useState(false)
  const pageHeaderRef = useRef<HTMLDivElement>()

  useEffect(() => {
    setShowShadow(scrollPositionY > 0)
  }, [scrollPositionY])

  useEffect(() => {
    if (pageHeaderRef.current) {
      const offsetHeight = pageHeaderRef.current.offsetHeight
      const marginBottom = 24

      pageHeaderHeightReactiveVar(offsetHeight + marginBottom)
    }
  }, [])

  return (
    <Box
      ref={pageHeaderRef}
      sx={{
        position: props.isSticky ? 'sticky' : 'relative',
        zIndex: (theme) => theme.zIndex.pageHeader,
        top: props.isSticky ? navbarHeight || 0 : 0,
        mb: 3,
        backgroundColor: (theme) => theme.palette.background.paper,

        transition: 'box-shadow 150ms ease-in-out',
        boxShadow: showShadow ? '0px 2px 4px -2px rgba(0, 0, 0, 0.1)' : 'none',

        [theme.breakpoints.down('sm')]: {
          mb: 2,
        },
      }}
    >
      <Container
        sx={{
          display: 'grid',
          pt: 0,
          pb: 0,
          gridTemplate: '"title action" "children action" / 1fr auto',
          [theme.breakpoints.down('sm')]: {
            gridTemplate: '"title action" "children children" / 1fr auto',
          },
        }}
        maxWidth={props.isFlexible ? false : undefined}
      >
        <Box
          sx={{
            gridArea: 'title',
            display: 'grid',
            justifyContent: 'space-between',
            alignItems: 'center',
            gridAutoFlow: 'column',
            mt: 2.25,
            mb: props.children ? 1 : 2.25,
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {props.showBackButton && (
              <Link
                component={'backTo' in props ? RouterLink : 'button'}
                data-testid="navigate-back-button"
                onClick={
                  'backButtonClick' in props ? props.backButtonClick : undefined
                }
                to={'backTo' in props ? props.backTo : undefined}
                underline="none"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  outline: 'unset',
                }}
              >
                <ChevronLeftIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                />
              </Link>
            )}
            <Typography
              data-testid="page-header-title"
              variant="heading"
              sx={{
                marginLeft: (theme) =>
                  props.showBackButton ? theme.spacing(2) : 0,
              }}
            >
              {props.title}
            </Typography>
            {props.subTitle && (
              <Stack
                direction="row"
                spacing={1}
                rowGap={1}
                flexWrap="wrap"
                sx={{
                  marginLeft: (theme) => theme.spacing(2),

                  [theme.breakpoints.down('sm')]: {
                    marginBottom: 0,
                  },
                }}
              >
                {props.subTitle}
              </Stack>
            )}
          </Box>
        </Box>
        {props.children && (
          <Box
            sx={{
              height: '100%',
              display: 'grid',
              gridArea: 'children',

              //  Override Tabs
              '.MuiTabs-flexContainer': {
                gap: (theme) => theme.spacing(3),

                '.MuiTab-root': {
                  padding: (theme) => theme.spacing(0.5, 0),
                  minWidth: 0,
                  letterSpacing: 0,
                },
              },
            }}
          >
            {props.children}
          </Box>
        )}

        {props.rightColumn && (
          <Box
            sx={{
              gridArea: 'action',
              position: 'relative',
              display: 'grid',
              gridAutoFlow: 'column',
              alignItems: 'center',
              gap: 2,

              [theme.breakpoints.down('sm')]: {
                mt: (theme) => theme.spacing(2),
              },
            }}
          >
            {props.rightColumn}
          </Box>
        )}
      </Container>
    </Box>
  )
}
