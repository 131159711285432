import { Container, Divider } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Fragment } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { LinkedProducts } from './linked-products'

type Props = { isLoading?: boolean }

export const Related = (props: Props) => {
  const { t } = useTranslation(['products', 'shared'])
  const productForm = useFormContext<GetProductQuery>()
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const currentAccessories = productForm.watch(`product.accessories`) || []
  const currentRelated = productForm.watch(`product.related`) || []
  const currentSimilar = productForm.watch(`product.similar`) || []

  const fields = []
  if (writeProducts || (!writeProducts && currentAccessories.length > 0)) {
    fields.push(
      <FormFieldset label={t('products:product_form.accessories_products')}>
        <LinkedProducts field="accessories" isLoading={props.isLoading} />
      </FormFieldset>,
    )
  }

  if (writeProducts || (!writeProducts && currentRelated.length > 0)) {
    fields.push(
      <FormFieldset label={t('products:product_form.related_products')}>
        <LinkedProducts field="related" isLoading={props.isLoading} />
      </FormFieldset>,
    )
  }

  if (writeProducts || (!writeProducts && currentSimilar.length > 0)) {
    fields.push(
      <FormFieldset label={t('products:product_form.similar_products')}>
        <LinkedProducts field="similar" isLoading={props.isLoading} />
      </FormFieldset>,
    )
  }

  return (
    <Container>
      {fields.map((x, i) => (
        <Fragment key={i}>
          {x}
          {i < fields.length - 1 && <Divider />}
        </Fragment>
      ))}
    </Container>
  )
}
