import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Tab,
  Tabs,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { GetOrderHistoryDocument } from '../../../../generated/graphql'
import { OrderHistory } from './order'
import { useTabs } from '../../../../hooks/tab'
import { useTracking } from '../../../../hooks/tracking'
import { PurchaseHistory } from './purchase'
import { useQuery } from '@apollo/client'

type Props = {
  orderId?: number
}

enum TabLabel {
  Order = 1,
  Purchase,
}

export const History = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const { trackButtonClick } = useTracking()
  const [activeTab, setActiveTab] = useTabs(
    TabLabel,
    TabLabel.Order,
    'historyTab',
  )

  const { data, loading: isLoading } = useQuery(GetOrderHistoryDocument, {
    variables: { orderId: Number(props.orderId) },
    skip: !props.orderId,
  })

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary
        aria-controls="history"
        sx={{ backgroundColor: (theme) => theme.palette.gray10 }}
      >
        <SectionHeader sx={{ p: 0 }}>{t('orders:history')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Tabs
          value={activeTab}
          onChange={(_e, value: TabLabel) => {
            trackButtonClick({
              name: 'order-view-panel-history',
              value,
            })
            setActiveTab(value)
          }}
        >
          <Tab
            label={
              isLoading ? (
                <Skeleton width="50%" />
              ) : (
                t('orders:order_history_tabs.order')
              )
            }
            value={TabLabel.Order}
            data-testid="tab-order"
          />
          <Tab
            label={
              isLoading ? (
                <Skeleton width="50%" />
              ) : (
                t('orders:order_history_tabs.purchase')
              )
            }
            value={TabLabel.Purchase}
            data-testid="tab-purchase"
            sx={{ width: '50%' }}
          />
        </Tabs>
        {activeTab === TabLabel.Order ? (
          <OrderHistory isLoading={isLoading} order={data?.order} />
        ) : (
          <PurchaseHistory isLoading={isLoading} order={data?.order} />
        )}
      </AccordionDetails>
    </Accordion>
  )
}
