import { List } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CampaignTypeGroup } from '.'
import { useTracking } from '../../../hooks/tracking'
import {
  CAMPAIGN_TYPE_CART,
  CAMPAIGN_TYPE_PACKAGE,
  CAMPAIGN_TYPE_PRICELIST,
  CAMPAIGN_TYPE_PRODUCT,
} from '../../../utils/campaigns'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type CampaignTypeSelectionProps = {
  onCampaignTypeGroupSelection: (campaignGroupType: CampaignTypeGroup) => void
  ['data-testid']?: string
}

export const CampaignTypeSelection = (props: CampaignTypeSelectionProps) => {
  const { t } = useTranslation(['campaigns'])
  const { trackButtonClickEvent } = useTracking()

  return (
    <List data-testid={props['data-testid']}>
      <CampaignTypeListItem
        primary={t(CAMPAIGN_TYPE_CART.title)}
        secondary={t(CAMPAIGN_TYPE_CART.caption)}
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-cart-campaign' },
          () => props.onCampaignTypeGroupSelection('cart'),
        )}
        listItemIcon={CAMPAIGN_TYPE_CART.icon}
        data-testid="cart-campaign-selection-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-campaign' },
          () => props.onCampaignTypeGroupSelection('product'),
        )}
        listItemIcon={CAMPAIGN_TYPE_PRODUCT.icon}
        primary={t(CAMPAIGN_TYPE_PRODUCT.title)}
        secondary={t(CAMPAIGN_TYPE_PRODUCT.caption)}
        data-testid="product-campaign-selection-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-package-campaign' },
          () => props.onCampaignTypeGroupSelection('package'),
        )}
        listItemIcon={CAMPAIGN_TYPE_PACKAGE.icon}
        primary={t(CAMPAIGN_TYPE_PACKAGE.title)}
        secondary={t(CAMPAIGN_TYPE_PACKAGE.caption)}
        data-testid="package-campaign-selection-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-price-list-campaign' },
          () => props.onCampaignTypeGroupSelection('pricelist'),
        )}
        listItemIcon={CAMPAIGN_TYPE_PRICELIST.icon}
        primary={t(CAMPAIGN_TYPE_PRICELIST.title)}
        secondary={t(CAMPAIGN_TYPE_PRICELIST.caption)}
        data-testid="pricelist-campaign-selection-listItem"
      />
    </List>
  )
}
