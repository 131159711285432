import { Button, IconButton } from '@mui/material'
import {
  GridColDef,
  GridEventListener,
  GridRowModel,
  useGridApiContext,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { DeleteIcon, DiscountIcon } from '@sitoo/mui-components'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { DataGrid } from '../../../components/data-grid'
import { getColumnValue } from '../../../components/data-grid/utils'
import {
  GetCampaignsQuery,
  GetCampaignsDocument,
} from '../../../generated/graphql'
import {
  CAMPAIGN_TYPE_CART,
  CAMPAIGN_TYPE_PACKAGE,
  CAMPAIGN_TYPE_PRICELIST,
  CAMPAIGN_TYPE_PRODUCT,
  getCampaignState,
} from '../../../utils/campaigns'
import { DeleteCampaignDialog } from '../delete-campaign-dialog'
import { AddCampaignDialog } from '../add-campaign-dialog'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import {
  offerCompositionRenderer,
  offerCompositionValueGetter,
} from './offer-composition'
import { tagsRenderer } from './tags'
import {
  typeAndPriorityRenderer,
  typeAndPriorityValueGetter,
} from './type-and-priority'
import { voucherStateRenderer, voucherStateValueGetter } from './voucher-state'
import { CampaignTypes } from './campaign-type-filter'
import { FilterContext } from '../../../components/data-grid/context'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import { ArrayElement } from '../../../utils/types'
import { CampaignStates } from './campaign-state-filter'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { translateCampaignType } from '../i18n'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useMoney } from '../../../hooks/money'
import { VoucherNameRenderer } from './voucher-name-renderer'
import { parseVoucherCode } from '../../campaign/utils'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'

type DeleteCampaignProps = {
  refetch(): Promise<ApolloQueryResult<GetCampaignsQuery>>
  onCloseDetailCampaign: () => void
  currentDetailedCampaignId?: number
}

export type Row = ArrayElement<GetCampaignsQuery['campaigns']['items']>

const DeleteCampaign = (props: DeleteCampaignProps) => {
  const { t } = useTranslation(['campaigns'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const selectedCampaignIds = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<{
      id: number
    }>[]
  ).map((c) => c.id)

  const onClose = () => {
    setIsDialogOpen(false)
  }

  const onSuccess = (
    successCampaignIds: number[],
    failedCampaignIds: number[],
  ) => {
    void props.refetch()

    if (
      props.currentDetailedCampaignId &&
      successCampaignIds.includes(props.currentDetailedCampaignId)
    ) {
      props.onCloseDetailCampaign()
    }

    if (successCampaignIds.length) {
      enqueueSnackbar(
        t('campaigns:success_delete_snackbar', {
          count: successCampaignIds.length,
        }),
      )
    }

    if (failedCampaignIds.length) {
      enqueueSnackbar(
        t('campaigns:failure_delete_snackbar', {
          count: failedCampaignIds.length,
        }),
        { variant: 'error' },
      )
    }

    onClose()
  }

  const onError = () => {
    void props.refetch()
    onClose()
  }

  return (
    <>
      <DeleteCampaignDialog
        open={isDialogOpen}
        onClose={onClose}
        campaignIds={selectedCampaignIds}
        onSuccess={onSuccess}
        onError={onError}
      />
      <IconButton
        onClick={() => {
          setIsDialogOpen(true)
        }}
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}

export type CampaignsListProps = {
  isAddCampaignDialogOpen: boolean
  onAddCampaignDialogClose: () => void
  showAddCampaign: () => void
  onDetailCampaign: (campaignId: number, siteId?: number) => void
  onCloseDetailCampaign: () => void
  currentDetailedCampaignId?: number
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const CampaignsList = (props: CampaignsListProps) => {
  const {
    isAddCampaignDialogOpen,
    onAddCampaignDialogClose,
    showAddCampaign,
    onDetailCampaign,
    onCloseDetailCampaign,
    currentDetailedCampaignId,
    onShowFilter,
  } = props
  const { t } = useTranslation(['campaigns', 'shared'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const { formatCurrency } = useMoney()

  const { filter, isFilterReady } = useContext(FilterContext)
  const apiRef = useGridApiRef()

  const variables = {
    num: MAX_NUM_REQUEST,
    start: 0,
  }
  const { data, loading, refetch } = useQuery(GetCampaignsDocument, {
    variables,
    fetchPolicy: 'cache-and-network',
    skip: !isFilterReady,
  })

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'vouchername',
        minWidth: 320,
        headerName: t('campaigns:list_campaign_header_name'),
        renderCell: VoucherNameRenderer,
        // renderCell: (params) =>
        //   VoucherNameRenderer(
        //     params.row.vouchername,
        //     !!params.row.vouchercode?.startsWith('LOCAL'),
        //   ),
      },
      {
        field: 'voucherpassword',
        minWidth: 200,
        headerName: t('campaigns:list_campaign_header_discount_code'),
      },
      {
        field: 'voucherState',
        minWidth: 100,
        headerName: t('campaigns:list_campaign_header_state'),
        valueGetter: voucherStateValueGetter,
        renderCell: voucherStateRenderer,
      },
      {
        field: 'typePriority',
        minWidth: 160,
        headerName: t('campaigns:list_campaign_header_type_priority'),
        valueGetter: typeAndPriorityValueGetter,
        renderCell: typeAndPriorityRenderer,
      },
      {
        field: 'datestart',
        ...ColumnProps.date,
        headerName: t('campaigns:list_campaign_header_start_date'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'dateend',
        ...ColumnProps.date,
        headerName: t('campaigns:list_campaign_header_end_date'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'tags',
        minWidth: 240,
        headerName: t('campaigns:list_campaign_header_tags'),
        renderCell: tagsRenderer,
      },
      {
        field: 'offer',
        minWidth: 280,
        headerName: t('campaigns:list_campaign_header_description'),
        valueGetter: (_value, row) =>
          offerCompositionValueGetter(row, formatCurrency),
        renderCell: offerCompositionRenderer,
      },
      {
        field: 'vouchercode',
        minWidth: 120,
        headerName: t('campaigns:list_campaign_header_campaign_group'),
        renderCell: (params) => parseVoucherCode(params.row.vouchercode),
      },
    ],
    [formatCurrency, formatDate, formatRelativeDate, t],
  )

  const onRowClick: GridEventListener<'rowClick'> = (params) => {
    onDetailCampaign(params.id as number)
  }

  const allCampaigns = useMemo(
    () =>
      data?.campaigns.items?.filter((x) => {
        //  Filter Campaign Type
        const campaignTypeFilter = Object.values(CampaignTypes).includes(
          filter['campaignType']?.value as CampaignTypes,
        )
          ? (filter['campaignType']?.value as CampaignTypes)
          : CampaignTypes.All
        if (campaignTypeFilter !== CampaignTypes.All) {
          const matchesCampaignTypeFilter =
            campaignTypeFilter === CampaignTypes.Cart
              ? CAMPAIGN_TYPE_CART.types.includes(x.vouchertype)
              : campaignTypeFilter === CampaignTypes.Package
                ? CAMPAIGN_TYPE_PACKAGE.types.includes(x.vouchertype)
                : campaignTypeFilter === CampaignTypes.Pricelist
                  ? CAMPAIGN_TYPE_PRICELIST.types.includes(x.vouchertype)
                  : campaignTypeFilter === CampaignTypes.Product
                    ? CAMPAIGN_TYPE_PRODUCT.types.includes(x.vouchertype)
                    : false

          if (!matchesCampaignTypeFilter) return false
        }

        //  Filter quick search
        const text = filter['text']?.value
        if (typeof text === 'string') {
          let matchesQuickTextFilter = false

          for (const filterableColumn of dataGridColumns.filter(
            (x) => x.filterable !== false,
          )) {
            const value: unknown = getColumnValue(
              // @ts-expect-error we should move filtering to the middleware
              text,
              x,
              filterableColumn,
              apiRef,
            )

            if (value) {
              switch (typeof value) {
                case 'string':
                  if (value.toLocaleLowerCase().includes(text.toLowerCase()))
                    matchesQuickTextFilter = true
                  break
                case 'number':
                  if (!Number.isNaN(Number(text)) && value === Number(text))
                    matchesQuickTextFilter = true
                  break
                default:
                  break
              }
            }

            if (matchesQuickTextFilter) break
          }

          if (!matchesQuickTextFilter) return false
        }

        //  Filter Campaign State
        const campaignStateFilter = Object.values(CampaignStates).includes(
          filter['state']?.value as CampaignStates,
        )
          ? (filter['state']?.value as CampaignStates)
          : CampaignStates.Active
        if (campaignStateFilter !== CampaignStates.All) {
          const campaignState = getCampaignState(x)

          const matchesCampaignStateFilter =
            campaignStateFilter === CampaignStates.Active
              ? campaignState === 'active'
              : campaignStateFilter === CampaignStates.Inactive
                ? campaignState === 'inactive'
                : campaignStateFilter === CampaignStates.Planned
                  ? campaignState === 'planned'
                  : false

          if (!matchesCampaignStateFilter) return false
        }

        return true
      }),
    [apiRef, data?.campaigns.items, dataGridColumns, filter],
  )

  const [paginatedCampaigns, setPaginatedCampaigns] =
    useState<typeof allCampaigns>()

  useEffect(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const sortingProp = (config.sorting?.[0]?.field || 'vouchername') as
      | keyof ArrayElement<typeof allCampaigns>
      | 'voucherState'
      | 'typePriority'
    const sortingSort = config.sorting?.[0]?.sort || 'asc'
    setPaginatedCampaigns(
      allCampaigns
        ?.sort((a, b) => {
          const itemA: ArrayElement<typeof allCampaigns> =
            sortingSort === 'asc' ? a : b
          const itemB: ArrayElement<typeof allCampaigns> =
            sortingSort === 'asc' ? b : a

          if (sortingProp === 'voucherState') {
            return voucherStateValueGetter(undefined, itemA).localeCompare(
              voucherStateValueGetter(undefined, itemB),
            )
          }

          if (sortingProp === 'typePriority') {
            const textA = `${translateCampaignType(itemA.vouchertype)} ${
              itemA.priority ? itemA.priority : 3
            }`
            const textB = `${translateCampaignType(itemB.vouchertype)} ${
              itemB.priority ? itemB.priority : 3
            }`
            return textA.localeCompare(textB)
          }

          const propA = itemA[sortingProp]
          const propB = itemB[sortingProp]

          if (typeof propA === 'string' && typeof propB === 'string') {
            return propA.localeCompare(propB)
          }

          // @ts-expect-error probably should be refactored
          return propA - propB
        })
        .slice(
          0,
          (config.pagination?.pageSize || 0) *
            ((config.pagination?.page || 0) + 1),
        ),
    )
  }, [allCampaigns, apiRef, filter])

  const sortItems = useMemo<SortItem[]>(
    () => [
      {
        field: 'vouchername',
        sort: 'asc',
        title: t('campaigns:list_campaign_header_name'),
        type: 'text',
        isDefault: true,
      },
      {
        field: 'vouchername',
        sort: 'desc',
        title: t('campaigns:list_campaign_header_name'),
        type: 'text',
      },
      {
        field: 'datestart',
        sort: 'asc',
        title: t('campaigns:list_campaign_header_start_date'),
        type: 'date',
      },
      {
        field: 'datestart',
        sort: 'desc',
        title: t('campaigns:list_campaign_header_start_date'),
        type: 'date',
      },
      {
        field: 'dateend',
        sort: 'asc',
        title: t('campaigns:list_campaign_header_end_date'),
        type: 'date',
      },
      {
        field: 'dateend',
        sort: 'desc',
        title: t('campaigns:list_campaign_header_end_date'),
        type: 'date',
      },
      {
        field: 'voucherState',
        sort: 'asc',
        title: t('campaigns:list_campaign_header_state'),
        type: 'text',
      },
      {
        field: 'voucherState',
        sort: 'desc',
        title: t('campaigns:list_campaign_header_state'),
        type: 'text',
      },
      {
        field: 'typePriority',
        sort: 'asc',
        title: t('campaigns:list_campaign_header_type_priority'),
        type: 'text',
      },
      {
        field: 'typePriority',
        sort: 'desc',
        title: t('campaigns:list_campaign_header_type_priority'),
        type: 'text',
      },
    ],
    [t],
  )

  return (
    <>
      <AddCampaignDialog
        open={isAddCampaignDialogOpen}
        onClose={onAddCampaignDialogClose}
      />

      <DataGrid
        apiRef={apiRef}
        name="campaign-list"
        columns={dataGridColumns}
        rows={paginatedCampaigns}
        rowCount={allCampaigns?.length || 0}
        loading={loading}
        noRowsOverlay={{
          icon: <DiscountIcon />,
          title: t('campaigns:grid.title'),
          description: t('campaigns:grid.description'),
          action: (
            <Button onClick={showAddCampaign}>
              {t('campaigns:add_campaign')}
            </Button>
          ),
        }}
        bulkAction={
          <DeleteCampaign
            refetch={refetch}
            onCloseDetailCampaign={onCloseDetailCampaign}
            currentDetailedCampaignId={currentDetailedCampaignId}
          />
        }
        hasTextFilter
        onShowFilter={onShowFilter}
        disableColumnFilter
        checkboxSelection
        onRowClick={onRowClick}
        hasPageHeader
        rowHeight={50}
        updateSearchParams
        slots={{ pagination: CursorPagination }}
        columnVisibilityModel={{
          voucherpassword: false,
          voucherState: false,
          offer: false,
          vouchercode: false,
        }}
        getRowClassName={(params) =>
          params.id === currentDetailedCampaignId ? 'Mui-selected' : ''
        }
        sorting={sortItems}
        showMore={false}
      />
    </>
  )
}
