import { Alert, AlertTitle, Container, Divider } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useAuthorization } from '../../hooks/authorization'
import { useMe } from '../../hooks/me'
import { usePageTitle } from '../../hooks/title'
import { Help } from './help'
import { ImportantToday } from './important-today'
import { Summary } from './summary'

export const HomePage = () => {
  const { t } = useTranslation(['home', 'shared'])
  usePageTitle(t('home:page_title'))
  const {
    modules: { homeWidgets },
  } = useAuthorization()

  const { me } = useMe()
  const role = me?.role || ''

  return (
    <>
      {homeWidgets ? (
        <>
          <Summary />
          <ImportantToday />
        </>
      ) : (
        <Container sx={{ pt: 4 }}>
          <Alert color="error" data-testid="no-permissions-alert">
            <AlertTitle>{t('home:no_permissions_title')}</AlertTitle>
            <Trans
              i18nKey="home:no_permissions_description"
              values={{ role: t(`shared:roles.${role}`) }}
            />
          </Alert>
        </Container>
      )}

      <Container sx={{ my: 4 }}>
        <Divider orientation="horizontal" />
      </Container>

      <Help />
    </>
  )
}
