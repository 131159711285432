import { Box, Typography } from '@mui/material'
import { useGridApiContext, useGridRootProps } from '@mui/x-data-grid-pro'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { COLUMN_HEADER_HEIGHT } from './utils'

type BulkGridHeaderActionsProps = {
  children?: ReactNode
}

export const BulkGridHeaderActions = (props: BulkGridHeaderActionsProps) => {
  const { t } = useTranslation('shared')
  const root = useGridRootProps()
  const apiRef = useGridApiContext()

  const selectedRowsSize = apiRef.current.getSelectedRows().size
  if (!selectedRowsSize) {
    return null
  }

  let checkboxWidth = '0'
  const hasCheckbox = root.checkboxSelection
  if (hasCheckbox) {
    const columns = apiRef.current.getAllColumns()

    const checkboxColumn = columns[0]
    if (!checkboxColumn) {
      return null
    }
    const checkboxElement = apiRef.current.getColumnHeaderElement(
      checkboxColumn.field,
    )
    checkboxWidth = checkboxElement?.style.width || '0'
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: COLUMN_HEADER_HEIGHT - 1,
        width: hasCheckbox ? `calc(100% - ${checkboxWidth})` : '100%',
        left: hasCheckbox ? checkboxWidth : 0,
        background: (theme) => theme.palette.background.paper,
        zIndex: 10,
        padding: (theme) => theme.spacing(0, 1.25),
      }}
    >
      <Box>
        <Typography variant="body01">
          {t('grid.selected', { count: selectedRowsSize })}
        </Typography>
      </Box>
      <Box>{props.children}</Box>
    </Box>
  )
}
