import { createContext, useState } from 'react'
import { DataGridFilterValue } from '../types'
import { useFilterContext } from '../hooks/use-filter-context'

export type ResetFilterFunction<T = Record<string, unknown>> = (
  key?: keyof T,
) => void | Promise<void>

export type FilterEventEmitter<TFunction> = {
  events: TFunction[]
  subscribe: (callback: TFunction) => () => void
  dispatch: TFunction
}

type FilterRecord<T> = {
  [K in keyof T]: DataGridFilterValue<T[K]>
}

export type FilterContext<T = Record<string, unknown>> = {
  filter: FilterRecord<T>
  hasApply: boolean
  isFilterReady: boolean
  registerFilter: (filters: Filter<T> | Filter<T>[]) => void
  resetFilter: ResetFilterFunction<T>
  setFilter: (key: keyof T, value: DataGridFilterValue<T[keyof T]>) => void
  applyFilter: () => void | Promise<void>
  subscribeOnResetFilter: FilterEventEmitter<
    ResetFilterFunction<T>
  >['subscribe']
  removeFilter: (key: keyof T) => void
  refetch: () => void
}

export const FilterContext = createContext<FilterContext>({
  filter: {},
  hasApply: false,
  isFilterReady: false,
  registerFilter: () => {},
  resetFilter: () => {},
  setFilter: () => {},
  removeFilter: () => {},
  applyFilter: () => {},
  subscribeOnResetFilter: () => () => {},
  refetch: () => {},
})

export type StateParamsContext<T = Record<string, unknown>> = [
  queryParams: T | undefined,
  setQueryParams: (state: T) => void,
]

export const StateParamsContext = createContext<StateParamsContext>([
  undefined,
  () => {},
])

type ProviderProps<T> = {
  children: React.ReactNode
  value?: T
  hasApply?: boolean
}

export type Filter<T> = {
  key: keyof T
  isReady?: boolean
}

export const FilterProvider = <T,>(props: ProviderProps<T>) => {
  const filterContext = useFilterContext<T>({
    hasApply: props.hasApply,
  })
  const [state, setState] = useState<Record<string, unknown> | undefined>({})

  return (
    <StateParamsContext.Provider value={[state, setState]}>
      <FilterContext.Provider
        value={(props.value as FilterContext) || filterContext}
      >
        {props.children}
      </FilterContext.Provider>
    </StateParamsContext.Provider>
  )
}
