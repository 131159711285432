import dayJs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isYesterday from 'dayjs/plugin/isYesterday'
import updateLocale from 'dayjs/plugin/updateLocale'
import relativeTime from 'dayjs/plugin/relativeTime'
import customParser from 'dayjs/plugin/customParseFormat'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/sv'
import { useMe } from '../me'

dayJs.extend(utc)
dayJs.extend(timezone)
dayJs.extend(isToday)
dayJs.extend(isYesterday)
dayJs.extend(updateLocale)
dayJs.extend(relativeTime)
dayJs.extend(customParser)

export const useDayJs = () => {
  const { i18n } = useTranslation()
  const { timeZone } = useMe()

  dayJs.tz.setDefault(timeZone)
  dayJs.locale(i18n.language)

  return dayJs
}
