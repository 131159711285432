import { Button } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../hooks/tracking'
import { FilterContext } from '../context'

type Props = {
  gridName: string
}

export const ResetFilterButton = (props: Props) => {
  const { t } = useTranslation(['shared'])
  const { trackButtonClickEvent } = useTracking()
  const { resetFilter } = useContext(FilterContext)

  return (
    <Button
      color="primary"
      data-testid="data-grid-reset-filter"
      onClick={trackButtonClickEvent(
        {
          name: 'grid-reset-filter',
          grid: props.gridName,
        },
        () => resetFilter(),
      )}
    >
      {t('shared:grid.reset_filter')}
    </Button>
  )
}
