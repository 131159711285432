import { useCallback } from 'react'
import { FieldPath, UseFormSetError } from 'react-hook-form'
import { getErrorMessage } from '../../utils/error-mapping'
import { extractGraphqlErrors } from '../../utils/extract-graphql-errors'

export const useServerValidation = <T extends Record<string, unknown>>(
  formDomain: string,
  setError: UseFormSetError<T>,
  opts?: {
    resolveFieldFromProperty?: (property: string) => string
    defaultErrorMessage?: string
  },
) => {
  const validateForm = useCallback(
    (error: unknown) => {
      const allErrors = extractGraphqlErrors(error)

      for (const graphqlError of allErrors) {
        const property = graphqlError?.extensions?.['property']
        const code = graphqlError?.extensions?.['code'] as string

        if (typeof property === 'string') {
          /**
           * propertyname -> propertyname
           * data.propertyname[0] -> propertyname
           * data.propertyname.subproperty -> propertyname
           */
          const message = getErrorMessage(
            error,
            formDomain,
            opts?.defaultErrorMessage || '',
            {
              resolveProperty: (property) =>
                property.match(/(data\.)?(\w+)/)?.[2] || property,
            },
          )

          const fieldName = property.match(/(data\.)?(\w+)/)?.[2] || ''
          const key = `${formDomain}.${fieldName}` as FieldPath<T>

          const fieldPath = opts?.resolveFieldFromProperty
            ? (opts?.resolveFieldFromProperty(property) as FieldPath<T>)
            : key

          setError(fieldPath, { type: code, message })
        }
      }
    },
    [formDomain, opts, setError],
  )

  return validateForm
}
