import { useQuery } from '@apollo/client'
import {
  GridColumnVisibilityModel,
  GridColDef,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import {
  GetInfluencerCodesDocument,
  GetInfluencerCodesQueryVariables,
  GetInfluencerCodesSort,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useMemo, useCallback, useContext } from 'react'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { FilterContext } from '../../../components/data-grid/context'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { Chip } from '@mui/material'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { Row } from '..'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { StarIcon } from '@sitoo/mui-components'
import { InfluencerCodesBulkActions } from '../influencer-codes-bulk-action'
import { useMoney } from '../../../hooks/money'
import { Status } from '../influencer-codes-filter-panel/status-filter'
import { DateFilterKey } from '../influencer-codes-filter-panel'

type Props = {
  onDetail(row: Row): void
  selectedId?: Row['code']
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const InfluencerCodesList = (props: Props) => {
  const { t } = useTranslation(['influencer_codes'])
  const { formatDate, formatRelativeDate } = useLocalizedDate()

  const { formatCurrency } = useMoney()

  const apiRef = useGridApiRef()

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'name',
        minWidth: 120,
        headerName: t('influencer_codes:name'),
      },
      {
        field: 'datestart',
        ...ColumnProps.date,
        headerName: t('influencer_codes:date_start'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'dateend',
        ...ColumnProps.date,
        headerName: t('influencer_codes:date_end'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'code',
        minWidth: 120,
        headerName: t('influencer_codes:code'),
        renderCell: (params) => (
          <Chip label={params.row.code} size="small" color="black" />
        ),
      },
      {
        field: 'uses',
        minWidth: 120,
        headerName: t('influencer_codes:uses'),
        renderCell: (params) =>
          params.row.maxuses
            ? `${params.row.numused || 0} / ${params.row.maxuses}`
            : params.row.numused || 0,
      },
      {
        field: 'moneyordertotal',
        ...ColumnProps.price,
        headerName: t('influencer_codes:total_sales'),
        valueGetter: (_value, row): string | undefined => {
          return row.moneyordertotal
            ? formatCurrency(row.moneyordertotal)
            : undefined
        },
      },
      {
        field: 'datelastused',
        ...ColumnProps.date,
        headerName: t('influencer_codes:date_last_used'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'vouchername',
        minWidth: 120,
        headerName: t('influencer_codes:campaign_name'),
      },
      {
        field: 'voucherpassword',
        minWidth: 120,
        headerName: t('influencer_codes:campaign_password'),
      },
    ],
    [formatCurrency, formatDate, formatRelativeDate, t],
  )

  const sortItems = useMemo<SortItem<GetInfluencerCodesSort>[]>(
    () => [
      {
        field: 'code',
        sort: 'asc',
        title: t('influencer_codes:code'),
        type: 'text',
        value: GetInfluencerCodesSort.code_asc,
        isDefault: true,
      },
      {
        field: 'code',
        sort: 'desc',
        title: t('influencer_codes:code'),
        type: 'text',
        value: GetInfluencerCodesSort.code_desc,
      },
      {
        field: 'name',
        sort: 'asc',
        title: t('influencer_codes:name'),
        type: 'text',
        value: GetInfluencerCodesSort.name_asc,
      },
      {
        field: 'name',
        sort: 'desc',
        title: t('influencer_codes:name'),
        type: 'text',
        value: GetInfluencerCodesSort.name_desc,
      },
      {
        field: 'vouchername',
        sort: 'asc',
        title: t('influencer_codes:campaign_name'),
        type: 'text',
        value: GetInfluencerCodesSort.vouchername_asc,
      },
      {
        field: 'vouchername',
        sort: 'desc',
        title: t('influencer_codes:campaign_name'),
        type: 'text',
        value: GetInfluencerCodesSort.vouchername_desc,
      },
      {
        field: 'voucherpassword',
        sort: 'asc',
        title: t('influencer_codes:campaign_password'),
        type: 'text',
        value: GetInfluencerCodesSort.voucherpassword_asc,
      },
      {
        field: 'voucherpassword',
        sort: 'desc',
        title: t('influencer_codes:campaign_password'),
        type: 'text',
        value: GetInfluencerCodesSort.voucherpassword_desc,
      },
      {
        field: 'datestart',
        sort: 'asc',
        title: t('influencer_codes:date_start'),
        type: 'date',
        value: GetInfluencerCodesSort.datestart_asc,
      },
      {
        field: 'datestart',
        sort: 'desc',
        title: t('influencer_codes:date_start'),
        type: 'date',
        value: GetInfluencerCodesSort.datestart_desc,
      },
      {
        field: 'dateend',
        sort: 'asc',
        title: t('influencer_codes:date_end'),
        type: 'date',
        value: GetInfluencerCodesSort.dateend_asc,
      },
      {
        field: 'dateend',
        sort: 'desc',
        title: t('influencer_codes:date_end'),
        type: 'date',
        value: GetInfluencerCodesSort.dateend_desc,
      },
    ],
    [t],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        pageSize: PAGE_SIZE,
        start: 0,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetInfluencerCodesQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
    }

    if (typeof config.filter?.['status']?.value === 'string') {
      const value = config.filter?.['status']?.value as Status

      if (value === Status.Reeddimable) {
        variables.redeemable = true
      }
      if (value === Status.NonRedeemable) {
        variables.redeemable = false
      }
    }

    if (typeof config.filter?.[DateFilterKey.Start]?.value === 'string') {
      variables.datestartFrom = config.filter[DateFilterKey.Start]?.value
    } else if (Array.isArray(config.filter?.[DateFilterKey.Start]?.value)) {
      const value = config.filter?.[DateFilterKey.Start]?.value

      if (typeof value?.[0] === 'string') {
        variables.datestartFrom = value[0]
      }

      if (typeof value?.[1] === 'string') {
        variables.datestartTo = value[1]
      }
    }

    if (typeof config.filter?.[DateFilterKey.End]?.value === 'string') {
      variables.dateendFrom = config.filter[DateFilterKey.End]?.value
    } else if (Array.isArray(config.filter?.[DateFilterKey.End]?.value)) {
      const value = config.filter?.[DateFilterKey.End]?.value

      if (typeof value?.[0] === 'string') {
        variables.dateendFrom = value[0]
      }
      if (typeof value?.[1] === 'string') {
        variables.dateendTo = value[1]
      }
    }

    if (typeof config.filter?.[DateFilterKey.LastUsed]?.value === 'string') {
      variables.datelastusedFrom = config.filter[DateFilterKey.LastUsed]?.value
    } else if (Array.isArray(config.filter?.[DateFilterKey.LastUsed]?.value)) {
      const value = config.filter?.[DateFilterKey.LastUsed]?.value

      if (typeof value?.[0] === 'string') {
        variables.datelastusedFrom = value[0]
      }
      if (typeof value?.[1] === 'string') {
        variables.datelastusedTo = value[1]
      }
    }

    if (typeof config.filter?.['influencer_code']?.value === 'string') {
      variables.code = config.filter?.['influencer_code']?.value
    }

    if (typeof config.filter?.['influencer_name']?.value === 'string') {
      variables.name = config.filter?.['influencer_name']?.value
    }

    if (typeof config.filter?.['campaign_name']?.value === 'string') {
      variables.vouchername = config.filter?.['campaign_name']?.value
    }

    if (typeof config.filter?.['campaign_code']?.value === 'string') {
      variables.voucherpassword = config.filter?.['campaign_code']?.value
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [apiRef, filter, sortItems])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetInfluencerCodesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || !isFilterReady

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.influencerCodes.totalcount) {
      return fetchMore({
        variables: {
          start: (data.influencerCodes?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(
    () => ({
      vouchername: false,
      voucherpassword: false,
    }),
    [],
  )

  const getRowId = (row: Row) => `${row.code}`

  return (
    <DataGrid
      name="influencer-codes-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.influencerCodes.items || []}
      rowCount={data?.influencerCodes?.items?.length || 0}
      onShowFilter={props.onShowFilter}
      loading={isLoading}
      onRowClick={(row: GridRowParams<Row>) => props.onDetail(row.row)}
      fetchMore={fetchMoreItems}
      noRowsOverlay={{
        icon: <StarIcon />,
        title: t('influencer_codes:grid.empty_title'),
        description: t('influencer_codes:grid.empty_description'),
      }}
      bulkAction={<InfluencerCodesBulkActions />}
      slots={{ pagination: CursorPagination }}
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={columnVisibilityModel}
      showMore={
        Number(data?.influencerCodes.items?.length) <
        Number(data?.influencerCodes.totalcount)
      }
      getRowId={getRowId}
      getRowClassName={(params) => {
        const classes = []
        if (props.selectedId && params.id === props.selectedId) {
          classes.push('Mui-selected')
        }

        const isExpired =
          params.row.dateend && new Date(params.row.dateend) < new Date()

        if (isExpired) {
          classes.push('disabled')
        }

        return classes.join(' ')
      }}
    />
  )
}
