import { ListItem, Button, ListItemText, Dialog, SxProps } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import { MfaConfigure } from '../../../components/mfa-configure'
import { useSnackbar } from 'notistack'
import { useMe } from '../../../hooks/me'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useMutation } from '@apollo/client'
import {
  RemoveMfaDocument,
  MeDocument,
  GetUsersDocument,
} from '../../../generated/graphql'

type Props = {
  email: string
  removeColor?: 'error' | 'secondary'
  sx?: SxProps
  hasMfa: boolean | undefined | null
  userId: string | undefined | null
}

export const MfaField = (props: Props) => {
  const { userId, email, sx, removeColor = 'secondary', hasMfa = false } = props

  const { me } = useMe()
  const isSelf = me?.user?.email == email

  const [removeMfaMutation] = useMutation(RemoveMfaDocument, {
    refetchQueries: [MeDocument, GetUsersDocument],
  })

  const [isMfaEnabled, setMfaEnabled] = useState(hasMfa)
  const [isMfaDialogOpen, setMfaDialogOpen] = useState(false)
  const [isRemoveMfaOpen, setRemoveMfaOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const onActivate = () => {
    setMfaDialogOpen(false)
    setMfaEnabled(true)
    enqueueSnackbar(t('users:profile.mfa_activate_success'))
  }

  const onRemove = () => {
    setRemoveMfaOpen(false)

    if (userId) {
      void removeMfaMutation({ variables: { userId } })
      setMfaEnabled(false)
      enqueueSnackbar(t('users:profile.mfa_remove_success'))
    }
  }

  return (
    <>
      <Dialog open={isMfaDialogOpen}>
        <MfaConfigure
          email={email}
          accountId={Number(me?.accountId)}
          backTitle={t('shared:action.close')}
          onBack={() => setMfaDialogOpen(false)}
          onSuccess={onActivate}
        />
      </Dialog>

      <ConfirmationDialog
        variant="destructive"
        confirmAction={onRemove}
        title={t('users:profile.mfa_confirmation_title')}
        text={t('users:profile.mfa_confirmation_description')}
        open={isRemoveMfaOpen}
        dataTestid="remove-mfa"
        onClose={() => setRemoveMfaOpen(false)}
        mainActionLabel={t('shared:action.remove')}
        secondaryActionLabel={t('shared:action.cancel')}
      />

      <ListItem
        sx={{ py: 1, px: 0, ...sx }}
        secondaryAction={
          <>
            {isMfaEnabled && (
              <Button
                size="small"
                onClick={() => setRemoveMfaOpen(true)}
                color={removeColor}
              >
                {t('shared:action.remove')}
              </Button>
            )}

            {!isMfaEnabled && isSelf && (
              <Button
                size="small"
                onClick={() => setMfaDialogOpen(true)}
                color="primary"
              >
                {t('shared:action.activate')}
              </Button>
            )}
          </>
        }
      >
        <ListItemText
          secondary={
            isMfaEnabled ? t('users:mfa_active') : t('users:mfa_not_active')
          }
        >
          {t('users:mfa')}
        </ListItemText>
      </ListItem>
    </>
  )
}
