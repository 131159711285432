import { List } from '@mui/material'
import { CampaignType } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import {
  translateCampaignTypeExample,
  translateCampaignTypeTitle,
} from '../i18n'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type CartCampaignSelectionProps = {
  onSelectCampaignType: (campaignType: CampaignType) => void
  ['data-testid']?: string
}

export const CartCampaignSelection = (props: CartCampaignSelectionProps) => {
  const { trackButtonClickEvent } = useTracking()

  return (
    <List data-testid={props['data-testid']}>
      <CampaignTypeListItem
        primary={translateCampaignTypeTitle(CampaignType.OrderDiscountM)}
        secondary={translateCampaignTypeExample(CampaignType.OrderDiscountM)}
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-cart-price-discount' },
          () => props.onSelectCampaignType(CampaignType.OrderDiscountM),
        )}
        data-testid="cart-price-discount-listItem"
        hasExample
      />

      <CampaignTypeListItem
        primary={translateCampaignTypeTitle(CampaignType.OrderDiscountX)}
        secondary={translateCampaignTypeExample(CampaignType.OrderDiscountX)}
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-cart-percentage-discount' },
          () => props.onSelectCampaignType(CampaignType.OrderDiscountX),
        )}
        data-testid="cart-percentage-discount-listItem"
        hasExample
      />

      <CampaignTypeListItem
        primary={translateCampaignTypeTitle(
          CampaignType.OrderBuyForMGetDiscountN,
        )}
        secondary={translateCampaignTypeExample(
          CampaignType.OrderBuyForMGetDiscountN,
        )}
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-cart-buy-amount-get-amount-discount' },
          () =>
            props.onSelectCampaignType(CampaignType.OrderBuyForMGetDiscountN),
        )}
        data-testid="cart-buy-for-amount-get-amount-discount-listItem"
        hasExample
      />
    </List>
  )
}
