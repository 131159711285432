import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { Typography } from '@mui/material'
import { GetCampaignQuery } from '../../../generated/graphql'
import { translateCampaignTypeOffer } from '../i18n'
import { FormatCurrency } from '../../../hooks/money'

type Campaign = GetCampaignQuery['campaign']

export const offerCompositionValueGetter = (
  row: Campaign,
  formatCurrency: FormatCurrency,
): string => {
  const {
    vouchertype,
    pricelisttags,
    value_x,
    value_y,
    value_z,
    money_m,
    money_n,
  } = row

  const tokens = {
    vouchertype,
    tags: (pricelisttags || []).join(', '),
    value_x: String(value_x || 0),
    value_y: String(value_y || 0),
    value_z: String(value_z || 0),
    money_m: formatCurrency(money_m || 0),
    money_n: formatCurrency(money_n || 0),
  }

  return translateCampaignTypeOffer(tokens)
}

export const offerCompositionRenderer = (
  params: GridRenderCellParams<Campaign, string>,
) => <OfferComposition offerDescription={params.formattedValue} />

type OfferCompositionProps = {
  offerDescription?: string
}

const OfferComposition = (props: OfferCompositionProps) => {
  return (
    <Typography
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      variant="caption"
    >
      {props.offerDescription}
    </Typography>
  )
}
