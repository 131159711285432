import { useQuery } from '@apollo/client'
import { Autocomplete, TextField } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { AllProductGroupsDocument } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { MAX_ITEMS_ORDER_API } from '.'
import { addTagOnInputChange } from '../../../utils/autocomplete'

type QueryParamsState = {
  productGroup?: string | string[]
}

export const ProductGroupFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)

  const { trackInputFocus, trackInputBlur } = useTracking()
  const filterKey = 'productGroup'
  const { data, loading: isLoading } = useQuery(AllProductGroupsDocument)

  const productGroups = useMemo(
    () =>
      (data?.allProductGroups || [])
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name)),
    [data?.allProductGroups],
  )

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const selectedProductGroups = useMemo(() => {
    return typeof queryParams[filterKey] === 'string'
      ? [queryParams[filterKey]]
      : (queryParams[filterKey] || []).slice(-MAX_ITEMS_ORDER_API)
  }, [queryParams])

  useEffect(() => {
    registerFilter({
      key: filterKey,
      isReady: !isLoading,
    })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (selectedProductGroups.length) {
      setFilter(filterKey, {
        label: t('filter:product_group.label'),
        labelValues: selectedProductGroups,
        value: selectedProductGroups,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [productGroups, selectedProductGroups, removeFilter, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <Autocomplete
      autoHighlight
      multiple
      limitTags={3}
      fullWidth
      freeSolo
      options={productGroups.map((x) => x.name)}
      disabled={isLoading}
      onChange={(_event, value) => {
        setQueryParams(
          {
            [filterKey]: value.slice(-MAX_ITEMS_ORDER_API),
          },
          hasApply !== true,
        )
      }}
      onInputChange={addTagOnInputChange}
      value={selectedProductGroups}
      data-testid="product-group-field"
      renderInput={(params) => (
        <TextField
          label={t('filter:product_group.label')}
          type="text"
          {...params}
          onFocus={trackInputFocus({ name: `product-group-${filterKey}` })}
          onBlur={trackInputBlur({ name: `product-group-${filterKey}` })}
          inputProps={{
            ...params.inputProps,
            ['data-testid']: 'product-group-input',
          }}
        />
      )}
    />
  )
}
