import { useQuery } from '@apollo/client'
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { SortItem } from '../../../components/data-grid/filters/sort'
import {
  GetRegistersSort,
  GetAllCashRegistersDocument,
  GetAllCashRegistersQuery,
  GetAllCashRegistersQueryVariables,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { RegisterCashIcon } from '@sitoo/mui-components'
import { useMemo, useEffect, useState } from 'react'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { Box, Chip } from '@mui/material'
import { Indicator } from '../../../components/indicator'
import { useDayJs } from '../../../hooks/day-js'

type Row = ArrayElement<GetAllCashRegistersQuery['allCashRegisters']>

type Props = {
  onDetail(selectedId: string): void
  selectedId?: string
}

export const CashRegisterList = (props: Props) => {
  const { t } = useTranslation(['cash_registers', 'shared', 'filter'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const dayjs = useDayJs()

  const sortItems = useMemo<SortItem<GetRegistersSort>[]>(
    () => [
      {
        field: 'orderid',
        sort: 'asc',
        title: t('cash_registers:id'),
        type: 'text',
        value: GetRegistersSort.RegisterIdAsc,
      },
      {
        field: 'orderid',
        sort: 'desc',
        title: t('cash_registers:id'),
        type: 'text',
        value: GetRegistersSort.RegisterIdDesc,
        isDefault: true,
      },
      {
        field: 'registerkey',
        sort: 'asc',
        title: t('cash_registers:register_key'),
        type: 'text',
        value: GetRegistersSort.RegisterKeyAsc,
      },
      {
        field: 'registerkey',
        sort: 'desc',
        title: t('cash_registers:register_key'),
        type: 'text',
        value: GetRegistersSort.RegisterKeyDesc,
      },
      {
        field: 'datelastping',
        sort: 'asc',
        title: t('cash_registers:last_updated'),
        type: 'date',
        value: GetRegistersSort.DateLastPingAsc,
      },
      {
        field: 'datelastping',
        sort: 'desc',
        title: t('cash_registers:last_updated'),
        type: 'date',
        value: GetRegistersSort.DateLastPingDesc,
      },
    ],
    [t],
  )

  const apiRef = useGridApiRef()

  const [queryVariables, setQueryVariables] =
    useState<GetAllCashRegistersQueryVariables>({
      includeNotRegistered: true,
    })

  const { data, loading: isLoading } = useQuery(GetAllCashRegistersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    const unsubscribe = apiRef.current.subscribeEvent(
      'sortModelChange',
      (sort) => {
        const sortItem = sort[0]

        if (sortItem) {
          const sort = sortItems.find(
            (s) => s.field === sortItem.field && s.sort === sortItem.sort,
          )?.value

          setQueryVariables((state) => ({ ...state, sort }))
        }
      },
    )

    return () => unsubscribe()
  }, [apiRef, sortItems])

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'registerid',
        minWidth: 200,
        headerName: t('cash_registers:id'),
        valueGetter: (_value, row): string => row.registerid,
      },
      {
        field: 'clientdescription',
        headerName: t('cash_registers:client_name'),
        minWidth: 200,
      },
      {
        field: 'datelastping',
        ...ColumnProps.date,
        headerName: t('cash_registers:last_updated'),
        renderCell: (params) => (
          <Indicator active={dayjs(params.row.datelastping).isToday()}>
            {params.row.datelastping ? (
              <RelativeDateRenderer
                {...params}
                formatDate={formatDate}
                formatRelativeDate={formatRelativeDate}
              />
            ) : (
              <Box color="gray60">
                {t('cash_registers:last_updated_unavailable')}
              </Box>
            )}
          </Indicator>
        ),
      },
      {
        field: 'warehouse',
        minWidth: 200,
        headerName: t('cash_registers:warehouse_name'),
        valueGetter: (_value, row): string => {
          return row.warehouse?.name || ''
        },
        renderCell: (params: GridRenderCellParams<Row, string>) => (
          <Chip label={params.formattedValue} size="small" />
        ),
      },
      {
        field: 'loglevel',
        minWidth: 120,
        headerName: t('cash_registers:log_level_label'),
        valueGetter: (_value, row): string => {
          return row.loglevel
            ? t(`cash_registers:log_level.${row.loglevel}`)
            : ''
        },
      },

      {
        field: 'registerkey',
        minWidth: 160,
        headerName: t('cash_registers:register_key'),
        valueGetter: (_value, row): string => {
          return row.registerkey || ''
        },

        renderCell: (params) => (
          <>
            {params.row.registerkey || (
              <Chip
                label={t('cash_registers:not_registered')}
                color="red"
                size="small"
              />
            )}
          </>
        ),
      },
      {
        field: 'manufacturerid',
        minWidth: 200,
        headerName: t('cash_registers:manufacturer_id'),
        valueGetter: (_value, row): string => {
          return row.manufacturerid || ''
        },
      },
    ],
    [dayjs, formatDate, formatRelativeDate, t],
  )

  const columnVisibilityModel = useMemo(
    () => ({
      ['registerid']: false,
    }),
    [],
  )

  return (
    <DataGrid
      name="cash-register-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.allCashRegisters}
      rowCount={data?.allCashRegisters?.length || 0}
      loading={isLoading}
      getRowId={(row) => row.registerid}
      onRowClick={(row) => props.onDetail(String(row.id))}
      noRowsOverlay={{
        icon: <RegisterCashIcon />,
        title: t('cash_registers:grid.empty_title'),
        description: t('cash_registers:grid.empty_description'),
      }}
      disableColumnFilter
      rowHeight={50}
      hasPageHeader
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={columnVisibilityModel}
      hideTotalCount
      getRowClassName={(params) => {
        return params.id === props.selectedId ? 'Mui-selected' : ''
      }}
    />
  )
}
