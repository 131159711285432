import { useQuery } from '@apollo/client'
import { Button, Chip } from '@mui/material'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CampaignViewPanelState } from '..'
import { RootRoute } from '../..'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import { GetCampaignDocument } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { useTracking } from '../../../hooks/tracking'
import { isLocalVoucherCode } from '../../campaign/utils'
import { CampaignAppliesTo } from './applies_to'
import { CampaignDetails } from './details'
import { CampaignRules } from './rules'
import { CampaignValidWhen } from './valid_when'

type CampaignViewPanelProps = {
  open: boolean
  selectedCampaign: CampaignViewPanelState

  onClose: () => void
}

export const CampaignViewPanel = (props: CampaignViewPanelProps) => {
  const { selectedCampaign, open, onClose } = props

  const generatePath = useAbsolutePath()
  const { t } = useTranslation(['shared', 'campaigns'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const {
    modules: { campaigns },
  } = useAuthorization()

  const { data: campaignData, loading } = useQuery(GetCampaignDocument, {
    variables: {
      id: selectedCampaign.campaignId,
    },
    skip: selectedCampaign.campaignId === 0 || !props.open,
  })

  const campaign = campaignData?.campaign
  const isLocalCampaign = isLocalVoucherCode(campaignData?.campaign.vouchercode)

  return (
    <ViewPanel open={open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={loading}
        title={campaign?.vouchername || ''}
        section={t('campaigns:title')}
        onClose={onClose}
        hideActionsSkeleton
        tags={isLocalCampaign ? <Chip size="small" label="LOCAL" /> : null}
        actions={
          <>
            {campaigns && campaign?.id && (
              <Button
                component={Link}
                to={generatePath(RootRoute.Campaign, {
                  id: String(campaign.id),
                })}
                color="secondary"
                size="small"
                data-testid="edit-campaign-button"
                onClick={() => {
                  trackButtonClick({
                    name: 'campaign-panel-edit-campaign',
                    campaignId: campaign.id,
                  })
                }}
              >
                {t('shared:action.edit')}
              </Button>
            )}
          </>
        }
        sx={{ mb: 1.25 }}
      />
      <CampaignDetails campaign={campaign} isLoading={loading} />
      <CampaignRules campaign={campaign} isLoading={loading} />
      <CampaignAppliesTo campaign={campaign} isLoading={loading} />
      <CampaignValidWhen campaign={campaign} isLoading={loading} />
    </ViewPanel>
  )
}
