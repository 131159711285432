import {
  Box,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { ChevronSmallDownIcon, ChevronSmallUpIcon } from '@sitoo/mui-components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTracking } from '../../../hooks/tracking'
import { useTranslation } from 'react-i18next'

type DescriptionProps = {
  shortDescription?: string | null
  description?: string | null
  isLoading?: boolean
}

export const Description = (props: DescriptionProps) => {
  const lineSize = useMemo(() => 18, [])
  const defaultCollapseSize = useMemo(() => lineSize * 2, [lineSize])
  const [collapseSize, setCollapseSize] = useState(defaultCollapseSize)
  const shortDescriptionRef = useRef<HTMLElement | null>(null)
  const descriptionRef = useRef<HTMLElement | null>(null)
  const [isShowingAll, setIsShowingAll] = useState(false)
  const [needsToCollapse, setNeedsToCollapse] = useState(false)
  const { trackButtonClickEvent } = useTracking()
  const { t } = useTranslation(['products'])
  const hasDescription = props.shortDescription || props.description

  useEffect(() => {
    if (!shortDescriptionRef.current && !descriptionRef.current) return

    let totalTextOffsetHeight =
      (shortDescriptionRef.current?.offsetHeight || 0) +
      (descriptionRef.current?.offsetHeight || 0)

    // Compensate for row gap and description title
    if (props.shortDescription && props.description) {
      totalTextOffsetHeight += lineSize * 2
    }

    const newCollapseSize = Math.min(
      defaultCollapseSize,
      lineSize * Math.ceil(totalTextOffsetHeight / lineSize),
    )

    setCollapseSize(newCollapseSize)
    setNeedsToCollapse(totalTextOffsetHeight > newCollapseSize)
  }, [defaultCollapseSize, lineSize, props.shortDescription, props.description])

  if (!props.isLoading && !hasDescription) return null

  return (
    <Stack
      sx={{
        padding: (theme) => theme.spacing(1, 2),
        background: (theme) => theme.palette.background.paper,
      }}
      spacing={1}
      direction="row"
    >
      {props.isLoading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="caption">
            <Skeleton />
          </Typography>
          <Typography variant="caption">
            <Skeleton />
          </Typography>
        </Box>
      ) : (
        <>
          <Collapse
            in={isShowingAll}
            collapsedSize={collapseSize}
            sx={{
              width: '100%',

              '.MuiCollapse-wrapperInner': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
          >
            <Stack direction="column" rowGap={2}>
              {props.shortDescription && (
                <Typography
                  data-testid="product-short-description"
                  variant="caption"
                  ref={shortDescriptionRef}
                  sx={{
                    whiteSpace: 'pre-wrap',
                  }}
                  variantMapping={{
                    caption: 'div',
                  }}
                >
                  {props.shortDescription}
                </Typography>
              )}

              {props.description && (
                <Box>
                  {/* Only show title if both short and regular descriptions are provided */}
                  {props.shortDescription && (
                    <Typography
                      data-testid="product-description-label"
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        whiteSpace: 'pre-wrap',
                      }}
                      variantMapping={{
                        caption: 'div',
                      }}
                    >
                      {t('products:view_panel.description')}
                    </Typography>
                  )}

                  <Typography
                    data-testid="product-description"
                    variant="caption"
                    ref={descriptionRef}
                    sx={{
                      whiteSpace: 'pre-wrap',
                    }}
                    variantMapping={{
                      caption: 'div',
                    }}
                  >
                    {props.description}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Collapse>
          {needsToCollapse && (
            <Box>
              <IconButton
                onClick={trackButtonClickEvent(
                  { name: 'product-panel-description-toggle' },
                  () => setIsShowingAll((p) => !p),
                )}
              >
                {isShowingAll ? (
                  <ChevronSmallUpIcon />
                ) : (
                  <ChevronSmallDownIcon />
                )}
              </IconButton>
            </Box>
          )}
        </>
      )}
    </Stack>
  )
}
