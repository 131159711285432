import { Select, MenuItem, Typography } from '@mui/material'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import {
  GetStoresDocument,
  GetWarehousesDocument,
} from '../../../generated/graphql'

type QueryParamsState = {
  storeId?: string
  warehouseId?: string
}

type Props = {
  storeId?: string
  warehouseId?: string
  setQueryParams: ({ storeId, warehouseId }: QueryParamsState) => void
}

export const StoreFilter = (props: Props) => {
  const { data: storesData } = useQuery(GetStoresDocument)

  const { data } = useQuery(GetWarehousesDocument)
  const allWarehouses = data?.allWarehouses

  const { storeId, warehouseId, setQueryParams } = props

  const stores = useMemo(
    () => storesData?.stores?.filter((s) => s.warehouses?.length),
    [storesData?.stores],
  )

  const warehouses = allWarehouses?.filter((warehouse) =>
    storeId ? warehouse.storeid === Number(storeId) : true,
  )

  const hasWarehouses = !!allWarehouses?.length

  return (
    <>
      {hasWarehouses && (
        <>
          <Select
            value={
              stores?.map((store) => store.id).includes(Number(storeId))
                ? storeId
                : ''
            }
            onChange={(event) => {
              setQueryParams({
                storeId: event.target.value ? event.target.value : undefined,
                warehouseId: undefined,
              })
            }}
            displayEmpty
            data-testid="stock-levels-store-select"
            inputProps={{
              ['data-testid']: 'stock-levels-store-input',
            }}
          >
            <MenuItem value={''} data-testid="stock-levels-store-button-0">
              <Typography variant="body02">
                {t('stock:stock_levels.all_stores')}
              </Typography>
            </MenuItem>
            {stores?.map(({ id, name }) => (
              <MenuItem
                value={String(id)}
                key={id}
                data-testid={`stock-levels-store-button-${id}`}
              >
                <Typography variant="body02">{name}</Typography>
              </MenuItem>
            ))}
          </Select>

          <Select
            value={
              warehouses
                ?.map((warehouse) => warehouse.id)
                .includes(Number(warehouseId))
                ? warehouseId
                : ''
            }
            onChange={(event) => {
              setQueryParams({
                storeId,
                warehouseId: event.target.value
                  ? event.target.value
                  : undefined,
              })
            }}
            displayEmpty
            disabled={Boolean(storeId) && warehouses?.length === 0}
            data-testid="stock-levels-warehouse-select"
            inputProps={{
              ['data-testid']: 'stock-levels-warehouse-input',
            }}
          >
            <MenuItem
              value={''}
              disabled
              data-testid="stock-levels-warehouse-button-0"
            >
              <Typography variant="body02">
                {t('stock:stock_levels.select_warehouse')}
              </Typography>
            </MenuItem>
            {warehouses?.map(({ id, name }) => (
              <MenuItem
                value={String(id)}
                key={id}
                data-testid={`stock-levels-warehouse-button-${id}`}
              >
                <Typography variant="body02">{name}</Typography>
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </>
  )
}
