import { ListItem, ListItemText, Switch } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'

export const StockField = () => {
  const { control } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()

  return (
    <FormFieldset
      label={t('products:product_form.stock_fieldset')}
      sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
    >
      <Controller
        control={control}
        name="product.stockcountenable"
        defaultValue={true}
        render={({ field }) => (
          <ListItem>
            <ListItemText
              data-testid="stock-count-field"
              secondary={t('products:product_form.stock_handling_description')}
            >
              {t('products:product_form.stock_handling_title')}
            </ListItemText>

            <Switch
              data-testid="stock-count-toggle"
              name="product.stockcountenable"
              checked={typeof field.value === 'boolean' ? field.value : true}
              onChange={(event) => {
                field.onChange(event.target.checked)
              }}
              disabled={!writeProducts}
            />
          </ListItem>
        )}
      />
    </FormFieldset>
  )
}
