import { useQuery } from '@apollo/client'
import { Button, Container } from '@mui/material'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { CustomOverlay } from '../../../components/data-grid/custom-overlay'
import { PageHeader } from '../../../components/page-header'
import {
  Transactiontype,
  AllOpenWarehouseBatchesDocument,
  WarehouseType,
  GetWarehousesDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { usePageTitle } from '../../../hooks/title'
import { DeliveryInList } from './delivery-in-list'
import { NewDeliveryInDialog } from './new-delivery-in-dialog'

export const DeliveriesInPage = () => {
  const { t } = useTranslation(['delivery_in', 'shared'])
  usePageTitle(t('delivery_in:page_title'))
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const {
    modules: { writeDeliveryIn },
  } = useAuthorization()

  const {
    data: allOpenWarehouseBatchesData,
    loading: loadingAllOpenWarehouseBatch,
  } = useQuery(AllOpenWarehouseBatchesDocument, {
    variables: {
      transactiontype: Transactiontype.NUMBER_10,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data, loading: fetchLoading } = useQuery(GetWarehousesDocument)
  const warehouses = data?.allWarehouses

  const fifoWarehouses = useMemo(
    () => warehouses?.filter((w) => w.warehousetype === WarehouseType.FIFO),
    [warehouses],
  )
  const canAddNew = useMemo(
    () =>
      fifoWarehouses?.some(
        (warehouse) =>
          !allOpenWarehouseBatchesData?.allOpenWarehouseBatches.find(
            (x) => x.warehouse?.id === warehouse.id,
          ),
      ),
    [allOpenWarehouseBatchesData?.allOpenWarehouseBatches, fifoWarehouses],
  )
  const isLoading = loadingAllOpenWarehouseBatch || fetchLoading

  return (
    <>
      <PageHeader
        title={t('delivery_in:page_title')}
        isFlexible
        rightColumn={
          <>
            {writeDeliveryIn && (
              <>
                <Button
                  variant="contained"
                  onClick={() => setOpen(true)}
                  data-testid="new-delivery-in"
                  disabled={isLoading || !canAddNew}
                >
                  {t('shared:action.new')}
                </Button>
                <NewDeliveryInDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  onSuccess={(warehouseId, warehouseBatchId) => {
                    setOpen(false)
                    navigate(
                      generatePath(RootRoute.DeliveryIn, {
                        warehouseId: String(warehouseId),
                        warehouseBatchId: String(warehouseBatchId),
                      }),
                    )
                  }}
                />
              </>
            )}
          </>
        }
      />
      <Container maxWidth={false}>
        {fifoWarehouses?.length === 0 ? (
          <CustomOverlay
            icon={<WarehouseIcon />}
            title={t('delivery_in:empty_warehouses.title')}
            description={t('delivery_in:empty_warehouses.description')}
            sx={{ position: 'relative' }}
          />
        ) : (
          <FilterProvider>
            <DeliveryInList />
          </FilterProvider>
        )}
      </Container>
    </>
  )
}
