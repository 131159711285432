import { t } from 'i18next'
import { CampaignType, CampaignGroupType } from '../../../generated/graphql'
import {
  CAMPAIGN_TYPE_CART,
  CAMPAIGN_TYPE_PACKAGE,
  CAMPAIGN_TYPE_PRICELIST,
  CAMPAIGN_TYPE_PRODUCT,
} from '../../../utils/campaigns'

export const translateCampaignType = (
  vouchertype: CampaignType | undefined | null,
): string => {
  if (!vouchertype) return ''

  if (CAMPAIGN_TYPE_CART.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_CART.name)

  if (CAMPAIGN_TYPE_PRODUCT.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_PRODUCT.name)

  if (CAMPAIGN_TYPE_PACKAGE.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_PACKAGE.name)

  if (CAMPAIGN_TYPE_PRICELIST.types.includes(vouchertype))
    return t(CAMPAIGN_TYPE_PRICELIST.name)

  return ''
}

type Params = {
  vouchertype: CampaignType
  tags: string
  value_x: string
  value_y: string
  value_z: string
  money_m: string
  money_n: string
}

export const translateCampaignTypeOffer = (params: Params): string => {
  const { vouchertype, ...tokens } = params

  switch (vouchertype) {
    case CampaignType.OrderDiscountM:
      return t('campaigns:campaign_types_tokens.cart_discount_m', tokens)
    case CampaignType.OrderDiscountX:
      return t('campaigns:campaign_types_tokens.cart_discount_x', tokens)
    case CampaignType.OrderBuyForMGetDiscountN:
      return t(
        'campaigns:campaign_types_tokens.shop_for_m_get_n_cart_discount',
        tokens,
      )
    case CampaignType.ProductDiscountM:
      return t('campaigns:campaign_types_tokens.product_discount_m', tokens)
    case CampaignType.ProductDiscountX:
      return t('campaigns:campaign_types_tokens.product_discount_x', tokens)
    case CampaignType.ProductBuyXForPriceM:
      return t('campaigns:campaign_types_tokens.buy_x_for_m', tokens)
    case CampaignType.ProductBuyXPayForY:
      return t('campaigns:campaign_types_tokens.buy_x_pay_for_y', tokens)
    case CampaignType.ProductBuyXPayForYDiscountZRest:
      return t(
        'campaigns:campaign_types_tokens.buy_x_pay_for_y_and_get_z_discount_on_rest',
        tokens,
      )
    case CampaignType.ProductBuyMinXDiscountY:
      return t(
        'campaigns:campaign_types_tokens.buy_minimum_quantity_x_get_y_discount',
        tokens,
      )
    case CampaignType.ProductBuyXGetDiscountList:
      return t(
        'campaigns:campaign_types_tokens.buy_x_get_discounts_from_list',
        tokens,
      )
    case CampaignType.ProductBuyForMGetDiscountList:
      return t(
        'campaigns:campaign_types_tokens.buy_for_m_get_discount_list',
        tokens,
      )
    case CampaignType.ProductBuy3For2Or2For1AndAHalf:
      return t(
        'campaigns:campaign_types_tokens.buy_3_pay_2_or_buy_2_pay_1_half',
      )
    case CampaignType.PackageBuyForPriceM:
      return t('campaigns:campaign_types_tokens.package_price_m', tokens)
    case CampaignType.PackageDiscountX:
      return t('campaigns:campaign_types_tokens.package_discount_x', tokens)
    case CampaignType.PackageDiscountM:
      return t('campaigns:campaign_types_tokens.package_discount_m', tokens)
    case CampaignType.PricelistActivate:
      return t(
        'campaigns:campaign_types_tokens.activate_pricelists_tags',
        tokens,
      )
    case CampaignType.PricelistActivateFinal:
      return t(
        'campaigns:campaign_types_tokens.activate_pricelists_block_other_tags',
        tokens,
      )

    default:
      return ''
  }
}

export const translateCampaignTypeTitle = (type?: CampaignType): string => {
  switch (type) {
    case CampaignType.OrderDiscountM:
      return t('campaigns:campaign_types_titles.cart_discount_m')
    case CampaignType.OrderDiscountX:
      return t('campaigns:campaign_types_titles.cart_discount_x')
    case CampaignType.OrderBuyForMGetDiscountN:
      return t('campaigns:campaign_types_titles.shop_for_m_get_n_cart_discount')
    case CampaignType.ProductDiscountM:
      return t('campaigns:campaign_types_titles.product_discount_m')
    case CampaignType.ProductDiscountX:
      return t('campaigns:campaign_types_titles.product_discount_x')
    case CampaignType.ProductBuyXForPriceM:
      return t('campaigns:campaign_types_titles.buy_x_for_m')
    case CampaignType.ProductBuyXPayForY:
      return t('campaigns:campaign_types_titles.buy_x_pay_for_y')
    case CampaignType.ProductBuyXPayForYDiscountZRest:
      return t(
        'campaigns:campaign_types_titles.buy_x_pay_for_y_and_get_z_discount_on_rest',
      )
    case CampaignType.ProductBuyMinXDiscountY:
      return t(
        'campaigns:campaign_types_titles.buy_minimum_quantity_x_get_y_discount',
      )
    case CampaignType.ProductBuyXGetDiscountList:
      return t('campaigns:campaign_types_titles.buy_x_get_discounts_from_list')
    case CampaignType.ProductBuyForMGetDiscountList:
      return t('campaigns:campaign_types_titles.buy_for_m_get_discount_list')
    case CampaignType.ProductBuy3For2Or2For1AndAHalf:
      return t(
        'campaigns:campaign_types_titles.buy_3_pay_2_or_buy_2_pay_1_half',
      )
    case CampaignType.PackageBuyForPriceM:
      return t('campaigns:campaign_types_titles.package_price_m')
    case CampaignType.PackageDiscountX:
      return t('campaigns:campaign_types_titles.package_discount_x')
    case CampaignType.PackageDiscountM:
      return t('campaigns:campaign_types_titles.package_discount_m')
    case CampaignType.PricelistActivate:
      return t('campaigns:campaign_types_titles.activate_pricelists_tags')
    case CampaignType.PricelistActivateFinal:
      return t(
        'campaigns:campaign_types_titles.activate_pricelists_block_other_tags',
      )

    default:
      return ''
  }
}

export const translateCampaignTypeExample = (type?: CampaignType): string => {
  switch (type) {
    case CampaignType.OrderDiscountM:
      return t('campaigns:campaign_types_examples.cart_discount_m')
    case CampaignType.OrderDiscountX:
      return t('campaigns:campaign_types_examples.cart_discount_x')
    case CampaignType.OrderBuyForMGetDiscountN:
      return t(
        'campaigns:campaign_types_examples.shop_for_m_get_n_cart_discount',
      )
    case CampaignType.ProductDiscountM:
      return t('campaigns:campaign_types_examples.product_discount_m')
    case CampaignType.ProductDiscountX:
      return t('campaigns:campaign_types_examples.product_discount_x')
    case CampaignType.ProductBuyXForPriceM:
      return t('campaigns:campaign_types_examples.buy_x_for_m')
    case CampaignType.ProductBuyXPayForY:
      return t('campaigns:campaign_types_examples.buy_x_pay_for_y')
    case CampaignType.ProductBuyXPayForYDiscountZRest:
      return t(
        'campaigns:campaign_types_examples.buy_x_pay_for_y_and_get_z_discount_on_rest',
      )
    case CampaignType.ProductBuyMinXDiscountY:
      return t(
        'campaigns:campaign_types_examples.buy_minimum_quantity_x_get_y_discount',
      )
    case CampaignType.ProductBuyXGetDiscountList:
      return t(
        'campaigns:campaign_types_examples.buy_x_get_discounts_from_list',
      )
    case CampaignType.ProductBuyForMGetDiscountList:
      return t('campaigns:campaign_types_examples.buy_for_m_get_discount_list')
    case CampaignType.ProductBuy3For2Or2For1AndAHalf:
      return t(
        'campaigns:campaign_types_examples.buy_3_pay_2_or_buy_2_pay_1_half',
      )
    case CampaignType.PackageBuyForPriceM:
      return t('campaigns:campaign_types_examples.package_price_m')
    case CampaignType.PackageDiscountX:
      return t('campaigns:campaign_types_examples.package_discount_x')
    case CampaignType.PackageDiscountM:
      return t('campaigns:campaign_types_examples.package_discount_m')
    case CampaignType.PricelistActivate:
      return t('campaigns:campaign_types_examples.activate_pricelists_tags')
    case CampaignType.PricelistActivateFinal:
      return t(
        'campaigns:campaign_types_examples.activate_pricelists_block_other_tags',
      )

    default:
      return ''
  }
}
