import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { CustomerFilter } from '../users-list/customer-filter'
import { UserTagsFilter } from '../users-list/user-tags-filter'

type UsersFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const UsersFilterViewPanel = (props: UsersFilterViewPanelProps) => {
  const { t } = useTranslation(['users', 'shared'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('shared:menu.general')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <CustomerFilter />
            <UserTagsFilter />
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
