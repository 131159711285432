import { useMutation, useQuery } from '@apollo/client'
import LoadingButton from '@mui/lab/LoadingButton'
import { Container, Paper } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../../components/page-header'
import { RouteLeavingGuard } from '../../../../components/route-leaving-guard'
import {
  Category,
  DeleteCategoriesDocument,
  GetCategoriesDocument,
  UpdateCategoriesDocument,
} from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { usePageTitle } from '../../../../hooks/title'
import { useTracking } from '../../../../hooks/tracking'
import { MAX_NUM_REQUEST } from '../../../../utils/constants'
import { ProductCategoryDialog } from './category-dialog'
import CategoryList from './category-list'
import { containsDirtyFields } from '../../../../utils/contains-dirty-fields'

export type ProductCategoriesForm = {
  categories: Category[]
  deleteCategories: number[]
}

export const ROOT_CATEGORY_ID = 0

export const SettingsProductsCategoriesPage = () => {
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.categories_navigation'))
  const {
    modules: { writeSettingsCategoriesNavigation },
  } = useAuthorization()

  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const formContext = useForm<ProductCategoriesForm>({
    defaultValues: { categories: [], deleteCategories: [] },
  })
  const {
    fields,
    remove: removeCategory,
    update: updateCategory,
    append: appendCategory,
    replace: replaceCategories,
  } = useFieldArray({
    control: formContext.control,
    name: 'categories',
    keyName: 'rowId',
  })

  const categories: Category[] = [
    { id: ROOT_CATEGORY_ID, title: 'Root' },
    ...fields,
  ]

  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  )

  const { enqueueSnackbar } = useSnackbar()

  const [showDialog, setShowDialog] = useState(false)

  const {
    loading: fetchLoading,
    data: categoriesData,
    refetch,
  } = useQuery(GetCategoriesDocument, {
    variables: { num: MAX_NUM_REQUEST, start: 0 },
  })

  const [updateCategories, { loading: updateLoading }] = useMutation(
    UpdateCategoriesDocument,
  )

  const [deleteCategories, { loading: deleteLoading }] = useMutation(
    DeleteCategoriesDocument,
  )

  const isLoading = fetchLoading || updateLoading || deleteLoading

  useEffect(() => {
    formContext.reset({
      categories: [...(categoriesData?.categories.items || [])].sort(
        (a, b) => a.sortorder! - b.sortorder!,
      ),
      deleteCategories: [],
    })
  }, [categoriesData, formContext])

  const onCategoryUpdate = (category: Category) => {
    const parentId = category.categoryparentid || null

    if (category.id) {
      updateCategory(
        fields.findIndex((x) => x.id === category.id),
        { ...category, categoryparentid: parentId },
      )
    } else {
      appendCategory({
        ...category,
        categoryparentid: parentId,
        id: Math.floor(Math.random() * 1000),
      })
    }

    setShowDialog(false)
  }

  const edit = (category: Category) => {
    trackButtonClick({ name: 'settings-product-category-edit' })

    setSelectedCategory(category)
    setShowDialog(true)
  }

  const add = () => {
    trackButtonClick({ name: 'settings-product-category-add' })

    setSelectedCategory(null)
    setShowDialog(true)
  }

  const remove = (category: Category) => {
    trackButtonClick({ name: 'settings-product-category-remove' })

    removeCategory(fields.findIndex(({ id }) => id === category.id))

    if (category.__typename) {
      formContext.setValue('deleteCategories', [
        ...formContext.getValues('deleteCategories'),
        category.id,
      ])
    }
  }

  const onSubmit = async (data: ProductCategoriesForm) => {
    try {
      trackButtonClick({
        name: 'settings-product-category-save',
      })

      await updateCategories({
        variables: {
          data: data.categories.map((c) => ({
            id: c.__typename ? c.id : null,
            title: c.title,
            sortorder: c.sortorder,
            visible: c.visible,
            categoryparentid: c.categoryparentid,
          })),
        },
      })

      if (data.deleteCategories.length > 0) {
        await deleteCategories({
          variables: {
            data: { ids: data.deleteCategories },
          },
        })
      }

      await refetch()

      trackFormSuccess({
        name: 'settings-product-category',
      })
      enqueueSnackbar(
        t('settings:categories.success_saved', {
          count: (data.deleteCategories.length || 0) + data.categories.length,
        }),
      )
    } catch {
      trackFormError({
        name: 'settings-product-category',
      })
    }
  }

  const onReOrderCategories = (newList: Category[]) => {
    replaceCategories(newList)
  }

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={() => writeSettingsCategoriesNavigation}
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shared:menu.categories_navigation')}
        // TODO: Temporarily disabled due to the multiple issues
        // rightColumn={
        //   <>
        //     {writeSettingsCategoriesNavigation && (
        //       <LoadingButton
        //         // disabled={!formContext.formState.isDirty}
        //         type="submit"
        //         // eslint-disable-next-line @typescript-eslint/no-misused-promises
        //         onClick={formContext.handleSubmit(onSubmit)}
        //         loading={isLoading}
        //         data-testid="save-changes"
        //       >
        //         {t('shared:action.save')}
        //       </LoadingButton>
        //     )}
        //   </>
        // }
      />
      <FormProvider {...formContext}>
        <Container>
          <Paper elevation={0}>
            <CategoryList
              categories={fields}
              onReorder={onReOrderCategories}
              onEdit={edit}
              onAdd={add}
              onDelete={remove}
              loading={isLoading}
              readOnly={!writeSettingsCategoriesNavigation}
            />
          </Paper>
        </Container>
      </FormProvider>

      <ProductCategoryDialog
        open={showDialog}
        category={selectedCategory}
        categories={categories}
        onClose={() => setShowDialog(false)}
        onSuccess={onCategoryUpdate}
      />
    </>
  )
}
