import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { AuthenticateSiteDocument } from '../../generated/graphql'
import { RootRoute } from '../../pages'
import { getErrorMessage } from '../../utils/error-mapping'
import { extractGraphqlErrors } from '../../utils/extract-graphql-errors'
import { useMe } from '../me'

export const useSiteSelector = () => {
  const { siteId } = useParams()
  const { me, loading } = useMe()
  const navigate = useNavigate()
  const [authenticateSite] = useMutation(AuthenticateSiteDocument, {
    refetchQueries: 'active',
  })
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!siteId || !me?.siteId || loading) return

    if (Number(siteId) !== me.siteId) {
      void authenticateSite({ variables: { siteId: Number(siteId) } }).catch(
        (error) => {
          const errorMessage = getErrorMessage(error, 'shared')
          enqueueSnackbar(errorMessage, { variant: 'error' })

          const allErrors = extractGraphqlErrors(error)

          const isInvalidSite = allErrors.some(
            (x) => x.extensions?.['code'] === 'INVALID_SITE_ID',
          )

          navigate(
            { pathname: RootRoute.Logout },
            { state: { clean: isInvalidSite } },
          )
        },
      )
    }
  }, [siteId, me, authenticateSite, navigate, loading, enqueueSnackbar])
}
