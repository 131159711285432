import { Box, Chip, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { SalesTax, SalesTaxGroup } from '../../../generated/graphql'
import { useSalesTaxFormatter } from '../sales-taxes/useSalesTaxFormatter'

export const SalesTaxGroupsTaxesRenderer = (
  params: GridRenderCellParams<SalesTaxGroup>,
): React.ReactElement => {
  return <SalesTaxGroupsTaxes salesTaxes={params.row.salestaxes} />
}

type Props = {
  salesTaxes?: SalesTax[] | null
}

const SalesTaxGroupsTaxes = ({ salesTaxes }: Props) => {
  const { formatTaxValue } = useSalesTaxFormatter()

  return !salesTaxes?.length ? (
    <>-</>
  ) : (
    <Box display="flex" alignItems="center" height="100%">
      <Typography variant="caption">{`${salesTaxes[0]?.name} (${formatTaxValue(salesTaxes[0]?.decimalvaluedefault)})`}</Typography>
      {salesTaxes.length > 1 && (
        <Chip sx={{ ml: 1 }} label={`+ ${salesTaxes.length - 1}`} />
      )}
    </Box>
  )
}
