import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ParseStoresCsvDocument,
  ImportStoresDocument,
} from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ImportDialog } from '../import-dialog'
import { RootRoute } from '../../..'
import { stripNullValues } from '../../../../utils/strip-null-values'

export type Props = {
  open: boolean
  onClose(): void
}

const CSV_EXAMPLE = `
  storeid;name;storetype
  0;Concept Store;10
`

export const ImportStoresDialog = (props: Props) => {
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [parseCsvMutation, { data: parsedData, loading: parseLoading }] =
    useMutation(ParseStoresCsvDocument)

  const [
    importMutation,
    { data: importData, loading: importLoading, error: importError },
  ] = useMutation(ImportStoresDocument)

  const isLoading = parseLoading || importLoading

  const onParse = useCallback(
    (csv: string) => {
      return parseCsvMutation({ variables: { csv } })
    },
    [parseCsvMutation],
  )

  const onImport = useCallback(() => {
    const parseItems = parsedData?.parseStoresCsv.items.map((o) =>
      stripNullValues(o),
    )

    if (parseItems) {
      return importMutation({
        variables: { data: parseItems },
      })
    }
  }, [importMutation, parsedData?.parseStoresCsv.items])

  const onSuccess = useCallback(() => {
    navigate(generatePath(RootRoute.SettingsStores))
  }, [generatePath, navigate])

  return (
    <ImportDialog
      {...props}
      isLoading={isLoading}
      ns="stores"
      onImport={onImport}
      onParse={onParse}
      onSuccess={onSuccess}
      importData={importData?.importStores}
      parsedItems={parsedData?.parseStoresCsv}
      importError={importError}
      example={CSV_EXAMPLE}
    />
  )
}
