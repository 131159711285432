import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  PaymentCashIcon,
  PaymentCreditCardIcon,
  PaymentCustomIcon,
  PaymentGiftcardIcon,
  PaymentUnknownIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetOrderQuery } from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'
import { ArrayElement } from '../../../utils/types'
import { getPaymentCardNumber } from '../../../utils/order'
import { formatAdditionalData } from '../../../utils/format/additional-data'
import { PaymentAdditionalData } from '../util'

type Props = {
  isLoading?: boolean
  order?: GetOrderQuery['order']
  defaultExpanded?: boolean
}

type Payment = ArrayElement<GetOrderQuery['order']['payments']>

type PaymentMethodProps = {
  paymentMethod: string | null | undefined
}

export const PaymentIcon = (props: PaymentMethodProps) => {
  switch (props.paymentMethod) {
    case 'cash':
    case 'cash-on-delivery':
      return <PaymentCashIcon />

    case 'giftcard':
    case 'giftcard-creditnote':
      return <PaymentGiftcardIcon />

    case 'external-card':
      return <PaymentUnknownIcon />

    case 'custom1':
    case 'custom2':
    case 'custom3':
    case 'custom4':
    case 'custom5':
    case 'custom-payment':
    case 'custom-invoice':
      return <PaymentCustomIcon />

    default:
      return <PaymentCreditCardIcon />
  }
}

export const Payments = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const { formatCurrency } = useMoney()
  const { defaultExpanded, order, isLoading } = props
  const payments = order?.payments || []

  const getPaymentIcon = (
    payment: Payment,
    additionalData: PaymentAdditionalData,
  ) => {
    const paymentMethod = additionalData['payment-method'] || payment.reftype

    return <PaymentIcon paymentMethod={paymentMethod} />
  }

  if (!isLoading && payments.length === 0) {
    return null
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded ?? true}
      className="MuiAccordionRoot"
    >
      <AccordionSummary aria-controls="payments">
        <SectionHeader sx={{ p: 0 }}>{t('orders:payments')}</SectionHeader>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        {isLoading && (
          <ListItemSkeleton
            isLoading={isLoading}
            secondaryAction="-"
            childrenSkeleton
          />
        )}

        {payments.map((payment, index) => {
          const additionalData: PaymentAdditionalData | null | undefined =
            payment.additionaldata || {}

          const additionalDataList = formatAdditionalData(additionalData)

          return (
            <Fragment key={index}>
              <ListItemAccordion
                aria-controls={`payment-${index}`}
                defaultExpanded={defaultExpanded}
                summary={
                  <ListItemSkeleton
                    secondaryAction={
                      <ListItemText>
                        {formatCurrency(payment.moneyamount)}
                      </ListItemText>
                    }
                    isLoading={isLoading}
                    data-testid={`order-payment-method-${index}`}
                    sx={{
                      '.MuiListItemSecondaryAction-root': {
                        flexShrink: 0,
                      },
                    }}
                  >
                    <ListItemIcon>
                      {getPaymentIcon(payment, additionalData)}
                    </ListItemIcon>
                    <ListItemText
                      data-testid="payment-details"
                      primary={payment.name}
                      secondary={getPaymentCardNumber(additionalData)}
                    />
                  </ListItemSkeleton>
                }
              >
                <List>
                  <ListItemSkeleton
                    hide={!isLoading && !payment.externalid}
                    secondaryAction={
                      <ListItemText>{payment.externalid}</ListItemText>
                    }
                    isLoading={isLoading}
                    data-testid={`order-payment-external-id-${index}`}
                    divider
                  >
                    <ListItemText
                      primary={t('orders:view_panel.external_id')}
                    />
                  </ListItemSkeleton>
                  <ListItemSkeleton
                    hide={!isLoading && !payment.refid}
                    isLoading={isLoading}
                    data-testid={`order-payment-reference-id-${index}`}
                    divider
                  >
                    <ListItemText
                      primary={t('orders:view_panel.reference_id')}
                      secondary={payment.refid}
                    />
                  </ListItemSkeleton>
                  <ListItemSkeleton
                    hide={!isLoading && !payment.reservedpaymentid}
                    secondaryAction={
                      <ListItemText>{payment.reservedpaymentid}</ListItemText>
                    }
                    isLoading={isLoading}
                    data-testid={`order-payment-reserved-payment-id-${index}`}
                    divider
                  >
                    <ListItemText
                      primary={t('orders:view_panel.reserved_payment_id')}
                    />
                  </ListItemSkeleton>
                  <ListItemSkeleton
                    hide={!isLoading && !payment.cardissuer}
                    secondaryAction={
                      <ListItemText>{payment.cardissuer}</ListItemText>
                    }
                    isLoading={isLoading}
                    data-testid={`order-payment-card-issuer-${index}`}
                    divider
                  >
                    <ListItemText
                      primary={t('orders:view_panel.card_issuer')}
                    />
                  </ListItemSkeleton>
                  <ListItemSkeleton
                    hide={!isLoading && additionalDataList.length === 0}
                    secondaryAction={additionalDataList ? null : '-'}
                    isLoading={isLoading}
                    data-testid={`order-payment-additional-data-${index}`}
                    divider
                  >
                    <ListItemText
                      primary={t('orders:view_panel.additional_data_label')}
                      secondary={additionalDataList.map(([key, value]) => (
                        <Box
                          key={key}
                          component="span"
                          sx={{ display: 'block' }}
                        >
                          {key}: {value}
                        </Box>
                      ))}
                    />
                  </ListItemSkeleton>
                </List>
              </ListItemAccordion>
              {index + 1 < payments.length && <Divider />}
            </Fragment>
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}
