import { Autocomplete, SxProps, TextField } from '@mui/material'
import { useMemo } from 'react'
import {
  FieldError,
  FieldValues,
  UseControllerProps,
  get,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

type Warehouse = {
  id: number | string
  name: string
}

type Props = {
  warehouses: Warehouse[]
  multiple?: boolean
  sx?: SxProps
  isLoading?: boolean
  required?: boolean
  label?: string
  helperText?: string
}

export const WarehousesInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const {
    name,
    control,
    sx,
    multiple,
    rules,
    required,
    warehouses,
    isLoading,
    label,
    helperText,
    disabled,
  } = props

  const { trackInputFocus, trackInputBlur } = useTracking()

  const { t } = useTranslation(['shared'])

  const fieldLabel = t('shared:label.warehouse')

  const { field, formState } = useController({
    name,
    control,
    rules: {
      ...rules,
      required: required
        ? t('shared:validation.field_required', { field: fieldLabel })
        : undefined,
    },
  })

  const formError = get(formState.errors, name) as FieldError

  const value = useMemo(() => {
    const fieldValue = field.value as Warehouse | Warehouse[] | null

    if (Array.isArray(fieldValue)) {
      return warehouses.filter((warehouse) =>
        fieldValue.map((v) => v.id).includes(warehouse.id),
      )
    }

    if (fieldValue?.id) {
      return (
        warehouses.find((warehouse) => warehouse.id === fieldValue.id) || null
      )
    }

    return multiple ? [] : null
  }, [field.value, multiple, warehouses])

  return (
    <Autocomplete
      sx={sx}
      multiple={multiple}
      fullWidth
      autoHighlight
      value={value}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name || ''}
      options={warehouses}
      disabled={isLoading || disabled}
      onChange={(_event, value) => field.onChange(value)}
      data-testid="warehouses-input-container"
      renderInput={(params) => (
        <TextField
          type="text"
          {...params}
          label={
            label || multiple
              ? t('shared:label.warehouses')
              : t('shared:label.warehouse')
          }
          onFocus={trackInputFocus({ name: `warehouses-input` })}
          onBlur={trackInputBlur({ name: `warehouses-input` })}
          placeholder={t('shared:label.add_warehouse')}
          error={!!formError}
          helperText={formError?.message ?? (helperText || '')}
          required={required}
          inputProps={{
            ...params.inputProps,
            ['data-testid']: 'warehouses-input',
          }}
        />
      )}
    />
  )
}
