import { ExtendableMetadata, TrackAction } from './types'

type MenuMetadata = ExtendableMetadata & {
  // menuName: string
}

type TrackMenu = TrackAction<{ (metadata: MenuMetadata): void }>

export const trackMenuOpen: TrackMenu = (track, baseMetadata) => (metadata) => {
  void track(`MenuOpen`, {
    ...baseMetadata,
    ...metadata,

    category: 'Menu',
    label: metadata.name,
  })
}

export const trackMenuClose: TrackMenu =
  (track, baseMetadata) => (metadata) => {
    void track(`MenuClose`, {
      ...baseMetadata,
      ...metadata,

      category: 'Menu',
      label: metadata.name,
    })
  }
