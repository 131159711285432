import { Box, Tab, Tabs, TextField } from '@mui/material'
import { Dropzone } from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

enum TabLabels {
  FileInput,
  TextInput,
}
type ImportInputProps = {
  setFiles: (files: UploadedFile[]) => void
  isLoading?: boolean
}

export const ImportField = ({ setFiles, isLoading }: ImportInputProps) => {
  const [activeTab, setActiveTab] = useState(TabLabels.FileInput)

  const { trackButtonClick } = useTracking()
  const [textInput, setTextInput] = useState('')
  const { t } = useTranslation(['settings', 'shared'])

  useEffect(() => {
    setTextInput('')
  }, [setTextInput, activeTab])

  useEffect(() => {
    const csvFile = new File([textInput], 'csvFile', { type: 'text/csv' })
    setFiles([{ file: csvFile }])
  }, [setFiles, textInput])

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_e, value: TabLabels) => {
          trackButtonClick({
            name: 'import-tab',
            value,
          })
          setActiveTab(value)
        }}
        variant="fullWidth"
        sx={{
          mb: 2,
          '.MuiButtonBase-root': {
            background: (theme) => theme.palette.background.paper,
          },
        }}
      >
        <Tab
          label={t('settings:import_data.tab_label_file_upload')}
          value={TabLabels.FileInput}
          data-testid="tab-file-input"
        />

        <Tab
          label={t('settings:import_data.tab_label_text')}
          value={TabLabels.TextInput}
          data-testid="tab-text-input"
        />
      </Tabs>

      {activeTab === TabLabels.FileInput ? (
        <Dropzone
          setFiles={setFiles}
          isLoading={isLoading}
          multiple={false}
          accept={{
            'text/csv': ['.csv'],
          }}
          label={t('settings:import_data.dropzone_label')}
          sx={{
            px: 2,
            mb: 2,
            '& .DropzoneContainer': {
              gridTemplateColumns: 'auto',
            },
          }}
        />
      ) : (
        <Box sx={{ m: 2 }}>
          <TextField
            multiline
            rows={10}
            placeholder={t('settings:import_data.text_import_placeholder')}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
          />
        </Box>
      )}
    </>
  )
}
