import { t } from 'i18next'
import { Maybe } from '../../../generated/graphql'

type Product = {
  sku?: Maybe<string>
  variant?: Maybe<{ value?: Maybe<string>; name?: Maybe<string> }[]>
}

export const getProductDetails = (product: Product): string => {
  const sku = t('shared:product_search.secondary_label', {
    property: product.sku,
  })

  const variant =
    product.variant?.map(({ value, name }) =>
      [name, value].filter(Boolean).join(': '),
    ) || []

  return [sku, ...variant].filter(Boolean).join(', ')
}
