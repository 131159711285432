import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RootRoute } from '..'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'

import { ShipmentViewPanel } from './shipment-view-panel'
import { ShipmentsFilterViewPanel } from './shipments-filter-view-panel'

import { ShipmentsList } from './shipments-list'

type ViewPanelState = {
  isOpen: boolean
  shipmentId: number | undefined
}

export const shipmentViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  shipmentId: undefined,
})

const shipmentFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const ShipmentsV2Page = () => {
  const { t } = useTranslation(['shipments_v2'])
  usePageTitle(t('page_title'))
  const viewPanelState = useReactiveVar(shipmentViewPanelVar)
  const filterViewPanelState = useReactiveVar(shipmentFilterViewPanelVar)
  const generatePath = useAbsolutePath()
  const { trackButtonClick, trackButtonClickEvent } = useTracking()

  const openViewPanel = (shipmentId: number) => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({ name: 'shipments-view-panel-open', shipmentId })
    shipmentViewPanelVar({ isOpen: true, shipmentId })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'shipments-view-panel-close' })
    shipmentViewPanelVar({ isOpen: false, shipmentId: undefined })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'shipments-filter-view-panel-close' })
    shipmentFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'shipments-filter-view-panel-open' })
    shipmentFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader
            title={t('page_title')}
            isFlexible
            rightColumn={
              <Button
                component={Link}
                to={generatePath(RootRoute.ShipmentNew)}
                onClick={trackButtonClickEvent({
                  name: 'shipments-add-shipment',
                })}
                data-testid="add-shipment-button"
              >
                {t('add_shipment_title')}
              </Button>
            }
          />
          <Container maxWidth={false}>
            <ShipmentsList
              onDetail={openViewPanel}
              selectedId={viewPanelState.shipmentId}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>
        <Grid item>
          <ShipmentViewPanel
            open={viewPanelState.isOpen}
            shipmentId={viewPanelState.shipmentId as number}
            onClose={closeViewPanel}
          />
          <ShipmentsFilterViewPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
