import { Chip, ChipOwnProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  result: string | undefined
}

export const EventResult = (props: Props) => {
  const { t } = useTranslation(['log_events'])
  const { result } = props

  const color: ChipOwnProps['color'] =
    result === 'success' ? 'green' : result === 'failure' ? 'red' : 'gray'

  if (!result) return null

  return (
    <Chip
      label={t(`log_events:event_result.${result}`, { defaultValue: result })}
      size="small"
      color={color}
    />
  )
}
