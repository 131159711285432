import { Dropzone } from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ImageUploadDialog } from '../../../../components/image-upload-dialog'
import { GetProductQuery } from '../../../../generated/graphql'
import { IMAGE_ACCEPT } from '../../fields/images'

type Props = {
  id: number
  open: boolean
  onClose(): void
  isLoading?: boolean
  setFiles(product: GetProductQuery['product'], images: UploadedFile[]): void
  editable?: boolean
}

export const VariantImagesField = (props: Props) => {
  const { getValues } = useFormContext<GetProductQuery>()
  const { isLoading, id, open, onClose, editable = true } = props
  const [dialogFiles, setDialogFiles] = useState<UploadedFile[]>([])

  const variants = getValues(`product.childVariants`)
  const product = variants?.find((variant) => variant.id === id)

  const setFiles = (files: UploadedFile[]) => {
    const images = files.map((f) => ({
      fileUrl: f.fileUrl!,
      id: f.id!,
      file: f.file,
    }))

    setDialogFiles(images)
  }

  const onSuccess = () => {
    if (product) {
      props.setFiles(product, dialogFiles)
    }
    props.onClose()
  }

  const onPreviewClick = (_urls: string[], _activeIndex: number) => {}

  return (
    <ImageUploadDialog
      open={open}
      onClose={onClose}
      onSuccess={onSuccess}
      editable={editable}
    >
      <Dropzone
        preloadFiles={product?.productImages || []}
        onPreviewClick={onPreviewClick}
        setFiles={setFiles}
        isLoading={isLoading}
        sx={{
          '& .DropzoneDragArea--empty': {
            minHeight: '300px',
          },
        }}
        accept={IMAGE_ACCEPT}
        disabled={!editable}
      />
    </ImageUploadDialog>
  )
}
