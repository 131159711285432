import {
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material'
import {
  BugIcon,
  QuestionIcon,
  SectionHeader,
  StarIcon,
} from '@sitoo/mui-components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'
import { useAuthorization } from '../../hooks/authorization'

export const Help = () => {
  const { t } = useTranslation(['home'])
  const { trackButtonClick } = useTracking()
  const {
    modules: { directFeedback },
  } = useAuthorization()
  const trackLink = useCallback(
    (name: string) => (_event: React.MouseEvent<HTMLAnchorElement>) => {
      trackButtonClick({ name: `${name}-link` })
    },
    [trackButtonClick],
  )

  return (
    <Container data-testid="help-section" sx={{ pb: 4 }}>
      <SectionHeader variant="transparent" sx={{ pl: 0, pt: 0 }}>
        {t('home:help.label')}
      </SectionHeader>

      <Paper elevation={0}>
        <List>
          <ListItemButton
            component="a"
            href={t('home:help.support_link')}
            onClick={trackLink('support-link')}
            divider
          >
            <ListItemIcon>
              <QuestionIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('home:help.support_title')}
              secondary={t('home:help.support_description')}
            />
          </ListItemButton>

          {!directFeedback && (
            <>
              <ListItemButton
                component="a"
                href={t('home:help.feedback_link')}
                onClick={trackLink('feedback-link')}
                divider
              >
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary={t('home:help.feedback_title')} />
              </ListItemButton>

              <ListItemButton
                component="a"
                href={t('home:help.report_link')}
                onClick={trackLink('report-link')}
              >
                <ListItemIcon>
                  <BugIcon />
                </ListItemIcon>
                <ListItemText primary={t('home:help.report_title')} />
              </ListItemButton>
            </>
          )}
        </List>
      </Paper>
    </Container>
  )
}
