import {
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Fragment, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ShipmentState } from '../../../../generated/graphql'
import { DataGridImage } from '../../../../components/data-grid-image'
import { EditShipmentFormContext } from '../../edit-shipment'
import { useSnackbar } from 'notistack'
import { SetUndeclaredItemsDataDialog } from '../../set-undeclared-items-data-dialog'

export const UndeclaredItems = () => {
  const { t } = useTranslation('shipments')
  const [isItemsDialogOpen, setItemsDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { getValues, watch } = useFormContext<EditShipmentFormContext>()
  const shipment = watch()

  const allItems = getValues('items_undeclared')

  if (!shipment.id || !shipment.version) {
    return null
  }

  const undeclaredItems = shipment?.items_undeclared

  const shouldShowUndeclaredItems =
    shipment.state &&
    [
      ShipmentState.Received,
      ShipmentState.Arrived,
      ShipmentState.Closed,
      ShipmentState.ClosedIncomplete,
      ShipmentState.Cancelled,
    ].includes(shipment.state)

  if (!shouldShowUndeclaredItems) {
    return null
  }

  const canAddUndeclaredItems = [
    ShipmentState.Received,
    ShipmentState.Arrived,
  ].includes(shipment.state)

  if (!canAddUndeclaredItems && !undeclaredItems?.length) return null

  return (
    <>
      {canAddUndeclaredItems && (
        <SetUndeclaredItemsDataDialog
          open={isItemsDialogOpen}
          shipmentId={shipment.id}
          shipmentVersion={shipment.version}
          onClose={() => setItemsDialogOpen(false)}
          onSuccess={() => {
            enqueueSnackbar(t('shipments:shipment_message.success_update'))
            setItemsDialogOpen(false)
          }}
          onError={(errorMessage) => {
            enqueueSnackbar(errorMessage, { variant: 'error' })
            setItemsDialogOpen(false)
          }}
        />
      )}

      <FormFieldset
        label={t('shipments:shipment_form.undeclared_items_fieldset')}
        sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
      >
        <List>
          {allItems.map((undeclaredItem, index) => (
            <Fragment key={undeclaredItem.id}>
              <ListItem
                secondaryAction={
                  undeclaredItem.__typename ? (
                    <Chip
                      color="green"
                      size="small"
                      label={t('shipments:undeclared_items.received', {
                        count: undeclaredItem.quantity,
                      })}
                    />
                  ) : (
                    <Chip
                      color="grayLight"
                      size="small"
                      label={t('shipments:undeclared_items.received', {
                        count: undeclaredItem.quantity,
                      })}
                    />
                  )
                }
              >
                <ListItemIcon>
                  <DataGridImage
                    src={undeclaredItem.product?.images?.at(0) || undefined}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={undeclaredItem.product_name}
                  secondary={undeclaredItem.sku}
                />
              </ListItem>

              {index + 1 < allItems?.length && <Divider />}
            </Fragment>
          ))}
          {canAddUndeclaredItems && (
            <ListItem sx={{ p: 0, minHeight: 'auto' }}>
              <Button
                fullWidth
                color="tertiary"
                onClick={() => setItemsDialogOpen(true)}
              >
                {t('shipments:undeclared_items.add_items')}
              </Button>
            </ListItem>
          )}
        </List>
      </FormFieldset>

      <Divider />
    </>
  )
}
