import { Box, IconButton } from '@mui/material'
import { GridColDef, GridPreProcessEditCellProps } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { DeleteIcon } from '@sitoo/mui-components'
import { MutableRefObject, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ShipmentPackageItem } from '.'
import { DataGrid } from '../../../components/data-grid'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import {
  StringEditRenderer,
  StringRenderer,
} from '../../../components/data-grid/utils/string-renderer'
import { ShipmentState } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ReceivedItemChip } from '../../shipments/shipment-view-panel/received-item-chip'
import { ShipmentPackage } from '../fields/packages'
import { getProductDetails } from '../../../utils/format/product'

type Props = {
  shipmentState?: ShipmentState
  items: ShipmentPackage['items']
  updateItem: (index: number, data: ShipmentPackageItem) => void
  removeItem: (index: number) => void
  apiRef: MutableRefObject<GridApiPro>

  readOnly?: boolean
}

type Row = ArrayElement<ShipmentPackage['items']>

export const PackageItems = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments'])
  const { items } = props
  const { trackButtonClick, trackInputBlur, trackInputFocus } = useTracking()

  const setCellValue = useCallback(
    (
      key: 'quantity',
      params: Pick<
        GridPreProcessEditCellProps<unknown, Row>,
        'id' | 'props' | 'row'
      >,
    ) => {
      if (['quantity'].includes(key)) {
        items?.forEach((item, index) => {
          if (item.id === params.id) {
            props.updateItem(index, {
              ...item,
              [key]: Number(params.props.value),
            })
          }
        })
      }
    },
    [items, props],
  )

  const dataGridColumns = useMemo<GridColDef<Row>[]>(() => {
    const columns: GridColDef<Row>[] = [
      {
        field: 'product_image',
        headerName: '',
        ...ColumnProps.image,
        valueGetter: (_value, row) => row.product?.images?.at(0),
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'product_name',
        ...ColumnProps.productTitle,
        headerName: t('shipments:packages.products_title'),
        flex: 3,
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.product_name,
          caption: getProductDetails(row),
        }),
        renderCell: CaptionRenderer,
      },
    ]

    if (
      props.shipmentState &&
      [
        ShipmentState.Cancelled,
        ShipmentState.Closed,
        ShipmentState.ClosedIncomplete,
      ].includes(props.shipmentState)
    ) {
      columns.push({
        field: 'quantity',
        headerName: t('shipments:packages.received_title'),
        editable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <ReceivedItemChip
            label={t('shipments:packages.received_of_total', {
              received: row.quantity_received,
              total: row.quantity,
            })}
            quantity={row.quantity}
            quantityReceived={row.quantity_received}
          />
        ),
      })
    } else {
      columns.push({
        field: 'quantity',
        headerName: t('shipments:packages.quantity_title'),
        editable: !props.readOnly,
        flex: 1,
        renderCell: StringRenderer,
        renderEditCell: (props) =>
          StringEditRenderer(
            props,
            trackInputFocus,
            trackInputBlur,
            'quantity',
          ),
        preProcessEditCellProps: (params) => {
          setCellValue('quantity', params)
          return params.props
        },
      })
    }

    if (!props.readOnly) {
      columns.push({
        field: 'remove',
        headerName: '',
        flex: 0.45,
        renderCell: (params) => (
          <IconButton
            onClick={() => {
              const index = items?.findIndex(({ id }) => id === params.id)
              if (typeof index === 'number') {
                props.removeItem(index)
              }
              trackButtonClick({ name: 'delete-package-item' })
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      })
    }

    return columns
  }, [
    items,
    props,
    setCellValue,
    t,
    trackButtonClick,
    trackInputBlur,
    trackInputFocus,
  ])

  return (
    <DataGrid
      apiRef={props.apiRef}
      name="set-package-items-list"
      columns={dataGridColumns}
      rows={items}
      rowCount={items?.length}
      rowHeight={50}
      ignoreRowHover
      sx={{ height: '300px' }}
      noRowsOverlay={{
        title: t('shipments:packages.no_rows_title'),
        description: t('shipments:packages.no_rows_description'),
      }}
      disableColumnVisibilityControl
      pagination={false}
      hideFooter
      disableAllFilters
    />
  )
}
