import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../../components/page-header'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '..'
import {
  AddInfluencerCodeDocument,
  GetInfluencerCodeDocument,
  GetInfluencerCodeQuery,
  GetInfluencerCodesDocument,
  InfluencerCodeInput,
  UpdateInfluencerCodeDocument,
} from '../../generated/graphql'
import { FormProvider, useForm } from 'react-hook-form'
import { Details } from './fields/details'
import { Container, Divider } from '@mui/material'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTracking } from '../../hooks/tracking'
import { useSnackbar } from 'notistack'
import { Rules } from './fields/rules'
import { CampaignDetails } from './fields/campaign-details'
import { ValidWhen } from './fields/valid-when'
import { Delete } from './fields/delete'
import { useEffect } from 'react'
import { FormRouteLeavingGuard } from '../../components/form-route-leaving-guard'

export type InfluencerCodeContext = GetInfluencerCodeQuery['influencerCode']

export const InfluencerCodePage = () => {
  const generatePath = useAbsolutePath()
  const { t } = useTranslation(['influencer_codes'])
  const navigate = useNavigate()
  const { id } = useParams()
  const { trackFormSuccess, trackFormError } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const client = useApolloClient()
  const isNewCode = id === undefined

  const [updateInfluencerCode, { loading: updateLoding }] = useMutation(
    UpdateInfluencerCodeDocument,
  )

  const [addInfluencerCode, { loading: addLoading }] = useMutation(
    AddInfluencerCodeDocument,
  )

  const {
    data,
    loading: fetchLoding,
    error: fetchError,
  } = useQuery(GetInfluencerCodeDocument, {
    variables: { code: String(id) },
    skip: !id,
  })

  const values = data?.influencerCode

  const defaultValues: InfluencerCodeContext = {
    active: true,
    code: '',
    maxuses: null,
    name: '',
    vouchercode: '',
    vouchercomment: '',
    vouchername: '',
    voucherpassword: '',
  }

  const formContext = useForm<InfluencerCodeContext>({
    values,
    defaultValues,
  })

  useEffect(() => {
    if (fetchError) {
      enqueueSnackbar(t('influencer_codes:influencer_code_fetch_fail'), {
        variant: 'error',
      })
      navigate(generatePath(RootRoute.InfluencerCodes))
    }
  }, [enqueueSnackbar, fetchError, generatePath, navigate, t])

  const onSubmit = async (formData: InfluencerCodeContext) => {
    const data: InfluencerCodeInput = {
      name: formData.name,
      active: formData.active,
      code: formData.code,
      voucherpassword: formData.voucherpassword,
      maxuses: formData.maxuses,
      vouchername: formData.vouchername,
      vouchercode: formData.vouchercode,
      vouchercomment: formData.vouchercomment,
      datestart: formData.datestart,
      dateend: formData.dateend,
    }
    try {
      if (isNewCode) {
        await addInfluencerCode({ variables: { data } })
      } else {
        await updateInfluencerCode({ variables: { data } })
      }

      trackFormSuccess({
        name: 'influencer_code',
        isNewCode,
      })

      navigate(generatePath(RootRoute.InfluencerCodes))

      await client.refetchQueries({
        include: [GetInfluencerCodesDocument],
      })

      enqueueSnackbar(
        isNewCode
          ? t('influencer_codes:influencer_create_success')
          : t('influencer_codes:influencer_update_success'),
      )
    } catch {
      trackFormError({
        name: 'influencer_code',
        isNewCode,
      })

      enqueueSnackbar(t('influencer_codes:influencer_update_fail'), {
        variant: 'error',
      })
    }
  }

  const isLoading = fetchLoding || addLoading || updateLoding

  return (
    <>
      <PageHeader
        title={
          isNewCode
            ? t('influencer_codes:add_influencer_code')
            : t('influencer_codes:edit_influencer_code')
        }
        backTo={generatePath(RootRoute.InfluencerCodes)}
        showBackButton={true}
        isSticky={true}
        rightColumn={
          <>
            {
              <LoadingButton
                loading={isLoading}
                data-testid="product-submit-button"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={formContext.handleSubmit(onSubmit)}
              >
                {t('shared:action.save')}
              </LoadingButton>
            }
          </>
        }
      />
      <FormProvider {...formContext}>
        <FormRouteLeavingGuard />
        <Container>
          <Details />
          <Divider />
          <Rules />
          <Divider />
          <CampaignDetails />
          <Divider />
          <ValidWhen />
          {!isNewCode && (
            <>
              <Divider />
              <Delete />
            </>
          )}
        </Container>
      </FormProvider>
    </>
  )
}
