import { Chip } from '@mui/material'

export type ReceivedItemChipProps = {
  label: string
  quantityReceived: number
  quantity: number
}

export const ReceivedItemChip = (props: ReceivedItemChipProps) => {
  return (
    <Chip
      data-testid="received-item-chip"
      label={props.label}
      size="small"
      color={
        props.quantityReceived === 0
          ? 'grayLight'
          : props.quantity === props.quantityReceived
            ? 'green'
            : 'orange'
      }
    />
  )
}
