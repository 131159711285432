import { LoadingButton } from '@mui/lab'
import { Box, Container, ListItemText } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  GetManufacturersDetailsQuery,
  SingleManufacturerInput,
  AddUpdateManufacturersDocument,
  DeleteManufacturersDocument,
  GetManufacturersDetailsDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { PageHeader } from '../../../../components/page-header'
import { ProductSettingsList } from '../product-settings-list'
import { ArrayElement } from '../../../../utils/types'
import { BrandDialog } from './brand-dialog'
import { MAX_NUM_REQUEST } from '../../../../utils/constants'
import { RouteLeavingGuard } from '../../../../components/route-leaving-guard'
import { usePageTitle } from '../../../../hooks/title'
import { useAuthorization } from '../../../../hooks/authorization'
import { useMutation, useQuery } from '@apollo/client'
import { containsDirtyFields } from '../../../../utils/contains-dirty-fields'

export type ProductBrand = ArrayElement<
  GetManufacturersDetailsQuery['manufacturers']['items']
>

type ProductBrandsForm = {
  productBrands: ProductBrand[]
  updateBrands: ProductBrand[]
  deleteBrands: number[]
}

export const SettingsProductsBrandsPage = () => {
  const { t } = useTranslation(['shared', 'settings', 'countries'])
  usePageTitle(t('shared:menu.brands'))

  const {
    modules: { writeSettingsBrands },
  } = useAuthorization()

  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const formContext = useForm<ProductBrandsForm>({
    defaultValues: {
      updateBrands: [],
      deleteBrands: [],
      productBrands: [],
    },
  })
  const {
    fields,
    remove: removeBrand,
    update: updateBrand,
    append: appendBrand,
  } = useFieldArray({
    control: formContext.control,
    name: 'productBrands',
    // We use this id because it'll be replaced by the real ID when we send to middleware
    keyName: 'externalcompanyid',
  })
  const { enqueueSnackbar } = useSnackbar()

  const [showDialog, setShowDialog] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState<ProductBrand | null>(null)
  const [allProductBrands, setAllProductBrands] = useState<ProductBrand[]>([])

  const {
    loading: fetchLoading,
    data: brandsData,
    refetch,
  } = useQuery(GetManufacturersDetailsDocument, {
    variables: { num: MAX_NUM_REQUEST },
  })

  useEffect(() => {
    setAllProductBrands(brandsData?.manufacturers.items || [])
    formContext.reset({
      productBrands: brandsData?.manufacturers.items || [],
      deleteBrands: [],
      updateBrands: [],
    })
  }, [brandsData, formContext])

  const edit = (productBrand: ProductBrand) => {
    trackButtonClick({
      name: 'settings-product-brand-edit',
    })

    setSelectedBrand(productBrand)
    setShowDialog(true)
  }

  const add = () => {
    trackButtonClick({
      name: 'settings-product-brand-add',
    })

    setSelectedBrand(null)
    setShowDialog(true)
  }

  const remove = (productBrand: ProductBrand, index: number) => {
    trackButtonClick({
      name: 'settings-product-brand-remove',

      productBrandId: productBrand.id,
    })

    removeBrand(index)

    if (productBrand.__typename) {
      formContext.setValue('deleteBrands', [
        ...formContext.getValues('deleteBrands'),
        productBrand.id,
      ])
    }
  }

  const [addUpdateBrandsMutation, { loading: addUpdateBrandsLoading }] =
    useMutation(AddUpdateManufacturersDocument)

  const [deleteBrandsMutation, { loading: deleteBrandsLoading }] = useMutation(
    DeleteManufacturersDocument,
  )

  const isLoading =
    fetchLoading || addUpdateBrandsLoading || deleteBrandsLoading

  const onSubmit = async (data: ProductBrandsForm) => {
    try {
      trackButtonClick({
        name: 'settings-product-brand-save',
      })
      let success = false

      if (data.updateBrands.length > 0) {
        const { data: updateResponse } = await addUpdateBrandsMutation({
          variables: {
            data: data.updateBrands.map(
              ({ id, __typename, ...x }): SingleManufacturerInput => ({
                ...x,
                countryid: x.countryid || (__typename ? null : undefined),
                externalcompanyid: __typename ? id : undefined,
              }),
            ),
          },
        })

        success = !!updateResponse?.addUpdateManufacturers.some(
          (x) => x.success === true,
        )

        const allFailedProductBrands =
          updateResponse?.addUpdateManufacturers.filter(
            (x) => x.success === false,
          ) || []
        if (allFailedProductBrands.length > 0) {
          enqueueSnackbar(
            t('settings:brand.error.brand_add_update_generic', {
              count: allFailedProductBrands.length,
              brand: allFailedProductBrands
                .map((x) => x.manufacturer.name)
                .join(', '),
            }),
            {
              variant: 'error',
            },
          )
        }
      }

      if (data.deleteBrands.length > 0) {
        const { data: deleteResponse } = await deleteBrandsMutation({
          variables: {
            data: {
              ids: data.deleteBrands,
            },
          },
        })

        if (!success) {
          success = !!deleteResponse?.deleteManufacturers.some(
            (x) => x.success === true,
          )
        }

        const allFailedProductBrands =
          deleteResponse?.deleteManufacturers.filter(
            (x) => x.success === false,
          ) || []
        if (allFailedProductBrands.length > 0) {
          enqueueSnackbar(
            t('settings:brand.error.brand_delete_generic', {
              count: allFailedProductBrands.length,
              brand: allFailedProductBrands
                .map(
                  (x) =>
                    allProductBrands.find((y) => y.id === x.manufacturerId)
                      ?.name,
                )
                .join(', '),
            }),
            {
              variant: 'error',
            },
          )
        }
      }

      if (success) {
        trackFormSuccess({
          name: 'settings-product-brand',
        })
        enqueueSnackbar(
          t('settings:brand.success_saved', {
            count: data.deleteBrands.length + data.updateBrands.length,
          }),
        )
      }

      await refetch()
    } catch {
      trackFormError({
        name: 'settings-product-brand',
      })
    }
  }

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={() => writeSettingsBrands}
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shared:menu.brands')}
        rightColumn={
          <>
            {writeSettingsBrands && (
              <LoadingButton
                disabled={!formContext.formState.isDirty}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={formContext.handleSubmit(onSubmit)}
                data-testid="save-changes"
                loading={isLoading}
              >
                {t('shared:action.save')}
              </LoadingButton>
            )}
          </>
        }
      />
      <Container>
        <Box
          sx={{
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <ProductSettingsList
            items={fields}
            baseDataTestId="brand"
            addText={t('settings:brand.add_brand')}
            loading={isLoading}
            canAdd
            onAdd={add}
            onDelete={(item, index) => remove(item, index)}
            onEdit={(item) => edit(item)}
            readOnly={!writeSettingsBrands}
            itemRender={(x) => (
              <ListItemText
                secondary={[
                  x.countryid ? t(`countries:${x.countryid}`) : '',
                  x.email || x.phone
                    ? `${x.email || '-'} • ${x.phone || '-'}`
                    : '',
                  x.description,
                ]
                  .filter((x) => !!x)
                  .join('\n')}
                secondaryTypographyProps={{
                  sx: {
                    whiteSpace: 'break-spaces',
                  },
                }}
              >
                {x.name}
              </ListItemText>
            )}
            emptyTitle={t('settings:brand.empty_title')}
            emptyDescription={t('settings:brand.empty_description')}
          />
        </Box>

        <BrandDialog
          open={showDialog}
          brand={selectedBrand !== null ? selectedBrand : undefined}
          onClose={() => setShowDialog(false)}
          onSuccess={(brand) => {
            if (selectedBrand !== null) {
              updateBrand(
                fields.findIndex((x) => x.id === selectedBrand.id),
                brand,
              )
              setSelectedBrand(null)
            } else {
              brand = {
                ...brand,
                id: Math.max(...fields.map(({ id }) => id)) + 1,
              }
              appendBrand(brand)
            }

            const updatedBrands = formContext.getValues('updateBrands')
            const brandIndex = updatedBrands.findIndex((x) => x.id === brand.id)
            if (brandIndex === -1) {
              formContext.setValue('updateBrands', [...updatedBrands, brand])
            } else {
              updatedBrands[brandIndex] = brand
              formContext.setValue('updateBrands', updatedBrands)
            }

            setShowDialog(false)
          }}
        />
      </Container>
    </>
  )
}
