import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { Button } from '@mui/material'
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro'
import { ErrorIcon } from '@sitoo/mui-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { useFilterContext } from '../../../components/data-grid/hooks/use-filter-context'
import { PageHeader } from '../../../components/page-header'
import { SettingsList } from '../../../components/settings-list'
import {
  AllReasonCodesDocument,
  AllReasonCodesQuery,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { ArrayElement } from '../../../utils/types'
import { ReasonCodeFilterPanel } from './reason-code-filter-panel'
import { ReasonCodeFilterContext } from './reason-code-filter'
import { useTracking } from '../../../hooks/tracking'

type Row = ArrayElement<AllReasonCodesQuery['allReasonCodes']>

const reasonCodeFilterPanelVar = makeVar({
  isOpen: false,
})

export const ReasonCodesPage = () => {
  const name = 'reason-codes'
  const { t } = useTranslation(['settings', 'shared'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { trackButtonClick } = useTracking()
  const filterViewPanelState = useReactiveVar(reasonCodeFilterPanelVar)
  const filterContext = useFilterContext<ReasonCodeFilterContext>()

  usePageTitle(t('shared:menu.reason_codes'))

  const { loading: isLoading, data } = useQuery(AllReasonCodesDocument, {
    variables: {
      type: filterContext.filter?.reasonCodeType?.value,
    },
    skip: !filterContext.isFilterReady,
  })

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'type',
        minWidth: 200,
        headerName: t('settings:reason_codes.type'),
        renderCell: (params) =>
          t(`settings:reason_codes.reason_code_types.${params.row.type}`),
      },
      {
        field: 'reasoncode',
        minWidth: 110,
        headerName: t('settings:reason_codes.reason_code'),
      },
      {
        field: 'name',
        minWidth: 240,
        headerName: t('settings:reason_codes.name'),
      },
    ],
    [t],
  )

  const onEdit = (row: GridRowParams<Row>) => {
    trackButtonClick({ name: `${name}-edit` })
    navigate(generatePath(RootRoute.SettingsReasonCode, { id: row.id }))
  }

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    navigate(generatePath(RootRoute.SettingsReasonCodeNew))
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: `${name}-filter-panel-close` })
    reasonCodeFilterPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: `${name}-filter-panel-open` })
    reasonCodeFilterPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider value={filterContext}>
      <SettingsList
        name={name}
        rows={data?.allReasonCodes}
        columns={columns}
        onRowClick={onEdit}
        loading={isLoading}
        getRowId={(row) => row.reasoncodeid}
        onShowFilter={toggleFilterViewPanel}
        noRowsOverlay={{
          icon: <ErrorIcon />,
          title: t('settings:reason_codes.no_rows_title'),
          description: t('settings:reason_codes.no_rows_description'),
        }}
        slots={{
          PageHeader,
          FilterPanel: ReasonCodeFilterPanel,
        }}
        slotProps={{
          pageHeader: {
            title: t('shared:menu.reason_codes'),
            isFlexible: true,
            rightColumn: (
              <Button onClick={onAdd}>
                {t('settings:reason_codes.add_reason_code')}
              </Button>
            ),
          },
          filterPanel: {
            isOpen: filterViewPanelState.isOpen,
            onClose: closeFilterViewPanel,
          },
        }}
      />
    </FilterProvider>
  )
}
