import { FieldValues, Path, useForm, UseFormProps } from 'react-hook-form'
import { useStateParams } from '../state-params'
import { useCallback, useEffect, useMemo } from 'react'
import { debounce } from '@mui/material'
import { ParseOptions, StringifyOptions } from 'query-string'

type Props<T extends FieldValues = FieldValues> = {
  formProps: UseFormProps<T>
  hasApply?: boolean
  parseOptions?: ParseOptions
  stringifyOptions?: StringifyOptions
  persistState?: boolean
}

export const useFormFilter = <T extends FieldValues = FieldValues>(
  props: Props<T>,
) => {
  const [queryParams, setQueryParams] = useStateParams<T>(undefined, {
    forceLocalState: true,
    persistState: props.persistState ?? true,
    parseOptions: { arrayFormat: 'none', ...props.parseOptions },
    stringifyOptions: { arrayFormat: 'comma', ...props.stringifyOptions },
  })

  const formContext = useForm<T>(props.formProps)

  const setFilter = useCallback(
    (values: Record<string, unknown>) => {
      Object.entries(values).map(([key, value]) => {
        setQueryParams({ [key]: value || undefined } as T, props.persistState)
      })
    },
    [props.persistState, setQueryParams],
  )

  const setParamsDebounced = useMemo(
    () => debounce(setFilter, 300),
    [setFilter],
  )

  const resetForm = useCallback(() => {
    const { defaultValues } = formContext.formState

    if (defaultValues) {
      setFilter(defaultValues)
      formContext.reset(defaultValues as T)
    }
  }, [formContext, setFilter])

  const applyFilter = useCallback(() => {
    setFilter(formContext.getValues())
  }, [formContext, setFilter])

  // Sync values from formContext to queryParams
  useEffect(() => {
    if (props.hasApply) return

    const subscribe = formContext.watch(setParamsDebounced)

    return () => subscribe.unsubscribe()
  }, [formContext, props.hasApply, setParamsDebounced, setQueryParams])

  // Sync values from queryParams to formContext
  useEffect(() => {
    const { defaultValues } = props.formProps

    if (!defaultValues) return

    const keys = Object.keys(defaultValues) as Path<T>[]

    for (const key of keys) {
      const formValue = formContext.getValues(key)
      const { isDirty } = formContext.getFieldState(key)
      const queryValue = queryParams[key]

      if (!queryValue || isDirty) continue

      if (JSON.stringify(formValue) !== JSON.stringify(queryValue)) {
        formContext.setValue(key, queryParams[key])
      }
    }
  }, [formContext, props.formProps, queryParams])

  return { formContext, queryParams, resetForm, applyFilter }
}
