/* eslint-disable @typescript-eslint/no-misused-promises */
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Button } from '@sitoo/mui-components'
import { useEffect, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../hooks/tracking'
import { TextFieldInput } from '../../inputs/text-field-input'
import { filterVar } from '../../cache'
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { FilterDocument } from '../../generated/graphql'
import { nanoid } from 'nanoid'
import { useMe } from '../../hooks/me'

type Form = { name: string }

export const filterPresetDialogVar = makeVar<boolean>(false)

export const FilterPresetSaveDialog = () => {
  const { t } = useTranslation(['shared', 'filter'])

  const dialogName = 'save-filter-preset'

  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const [loading, setLoading] = useState(false)

  const isOpen = useReactiveVar(filterPresetDialogVar)
  const prevOpen = usePrevious(isOpen)

  const { reset, handleSubmit, control, setFocus } = useForm<Form>({
    defaultValues: { name: '' },
  })

  const { me } = useMe()

  const filterKey = [me?.user?.id, location.pathname].filter(Boolean).join(':')

  const { data } = useQuery(FilterDocument)

  const onSave = useCallback(
    (filterData: { name: string }) => {
      filterVar([
        ...(data?.filter || []),
        {
          id: nanoid(),
          key: filterKey,
          name: filterData.name,
          value: location.search.replace(/^\?/, ''),
        },
      ])

      filterPresetDialogVar(false)
    },
    [data?.filter, filterKey],
  )

  const onSubmit = (formData: Form) => {
    try {
      setLoading(true)
      onSave(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    filterPresetDialogVar(false)
  }

  useEffect(() => {
    if (isOpen && !prevOpen) {
      reset()
      trackDialogOpen({ name: dialogName })
      setTimeout(() => setFocus('name'))
    }
  }, [isOpen, prevOpen, trackDialogOpen, reset, setFocus])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      data-testid="filter-preset-save-dialog"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle type="extended" sx={{ pb: 2 }}>
          {t('filter:filter_preset.dialog_save_label')}
        </DialogTitle>

        <DialogContent>
          <TextFieldInput
            name="name"
            control={control}
            fullWidth
            required
            label={t('filter:filter_preset.dialog_save_input_label')}
            sx={{ mb: 2 }}
            rules={{
              validate: (value) => {
                if (value.trim() === '') {
                  return t('shared:validation.field_required', {
                    field: t('filter:filter_preset.dialog_save_input_label'),
                  })
                }
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-cancel` },
              onClose,
            )}
            variant="secondary"
            size="compact"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-set` },
              handleSubmit(onSubmit),
            )}
            loading={loading}
          >
            {t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
