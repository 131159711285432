import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { ChevronRightIcon } from '@sitoo/mui-components'
import { MouseEventHandler, ReactNode } from 'react'

type Props = {
  onClick: MouseEventHandler
  primary?: ReactNode
  secondary?: ReactNode
  listItemIcon?: ReactNode
  ['data-testid']?: string
  hasExample?: boolean
}

export const CampaignTypeListItem = (props: Props) => {
  return (
    <ListItemButton
      divider
      sx={{ px: 3 }}
      onClick={props.onClick}
      data-testid={props['data-testid']}
    >
      {props.listItemIcon && <ListItemIcon>{props.listItemIcon}</ListItemIcon>}
      <ListItemText
        primary={props.primary}
        secondary={props.secondary}
        data-testid="campaign-option-text"
        sx={{
          '.MuiListItemText-secondary': {
            color: props.hasExample ? 'gray70' : 'inherit',
          },
        }}
      />
      <ListItemIcon>
        <ChevronRightIcon />
      </ListItemIcon>
    </ListItemButton>
  )
}
