import { List, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetPaymentTypeQuery } from '../../../../generated/graphql'

type Props = {
  paymentOption?: GetPaymentTypeQuery['getPaymentType']
  isLoading?: boolean
}

export const PaymentOptionDetails = ({ paymentOption, isLoading }: Props) => {
  const { t } = useTranslation(['pos_payment'])

  return (
    <List>
      <ListItemSkeleton
        data-testid="payment-option-external-id"
        secondaryAction={<ListItemText primary={paymentOption?.externalid} />}
        isLoading={isLoading}
        divider
      >
        <ListItemText primary={t('pos_payment:external_id')} />
      </ListItemSkeleton>
    </List>
  )
}
