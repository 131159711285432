import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  GetProductsQuery,
  DeletePricelistItemsDocument,
  UpdatePricelistItemsDocument,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { useTracking } from '../../../../hooks/tracking'
import { DEFAULT_PRICELIST_ID } from '../../../../utils/constants'
import { useMutation } from '@apollo/client'

export type DeletePriceDialogProps = {
  open: boolean
  products: Pick<
    ArrayElement<GetProductsQuery['products']['items']>,
    'id' | 'sku'
  >[]
  pricelistId: number
  productSiteId?: number
  dataTestid?: string
  onClose?(): void
  onSuccess?(successfulProductIds: number[], failedProductIds: number[]): void
  onError?(successfulProductIds: number[], failedProductIds: number[]): void
}

export const DeletePriceDialog = (props: DeletePriceDialogProps) => {
  const { t } = useTranslation(['prices'])
  const dialogName = 'delete-price'
  const isLoading = false
  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [deletePricelistItemsMutation] = useMutation(
    DeletePricelistItemsDocument,
  )
  const [updatePricelistItemsMutation] = useMutation(
    UpdatePricelistItemsDocument,
  )

  const deletePrices = async () => {
    const successfulProductIds: number[] = []
    const failedProductIds: number[] = []

    trackButtonClick({ name: `${dialogName}-dialog-delete` })

    try {
      if (props.pricelistId === DEFAULT_PRICELIST_ID) {
        await updatePricelistItemsMutation({
          variables: {
            pricelistId: props.pricelistId,
            items: props.products.map(({ id, sku }) => ({
              productid: id,
              sku,
              moneyprice: '0.00',
            })),
          },
        })
      } else {
        await deletePricelistItemsMutation({
          variables: {
            pricelistId: props.pricelistId,
            items: props.products.map(({ id, sku }) => ({
              productid: id,
              sku,
            })),
          },
        })
      }

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      props.onSuccess?.(successfulProductIds, failedProductIds)
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })

      props.onError?.(successfulProductIds, failedProductIds)
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={deletePrices}
      title={t('prices:delete_dialog.title', {
        count: props.products.length,
      })}
      text={t('prices:delete_dialog.description')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
