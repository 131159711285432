import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  DeleteShipmentsDocument,
  GetShipmentsDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'

type Props = {
  open: boolean
  selectedIds: string[]
  dataTestid?: string
  onClose?(): void
  onSuccess?(deletedIds: string[]): void
  onError?(deletedIds: string[]): void
}

export const DeleteShipmentDialog = (props: Props) => {
  const { t } = useTranslation('shipments')
  const dialogName = 'delete-shipment'
  const [deleteMutation, { loading }] = useMutation(DeleteShipmentsDocument, {
    refetchQueries: [GetShipmentsDocument],
  })
  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const onDelete = async () => {
    trackButtonClick({ name: `${dialogName}-delete` })

    try {
      const { data } = await deleteMutation({
        variables: {
          shipmentIds: props.selectedIds,
        },
      })
      const deletedIds = data?.deleteShipments || []

      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.(deletedIds)
    } catch {
      trackFormError({ name: `${dialogName}-dialog` })
      props.onError?.(props.selectedIds)
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={onDelete}
      title={t('delete_dialog.title', { count: props.selectedIds.length })}
      text={t('delete_dialog.description')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
