import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetCampaignQuery } from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'

type CampaignDetailsProps = {
  campaign?: GetCampaignQuery['campaign']
  isLoading: boolean
}

export const CampaignValidWhen = ({
  campaign,
  isLoading,
}: CampaignDetailsProps) => {
  const { t } = useTranslation(['shared', 'campaigns'])
  const { formatDate } = useLocalizedDate()

  if (
    !isLoading &&
    !campaign?.dateend &&
    !campaign?.datestart &&
    !campaign?.voucherpassword
  )
    return null

  return (
    <Accordion
      data-testid="valid-when-accordion"
      defaultExpanded
      className="MuiAccordionRoot"
    >
      <AccordionSummary aria-controls="details">
        <SectionHeader sx={{ p: 0 }}>
          {t('campaigns:campaign_form.valid_when')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={!campaign?.voucherpassword}
            isLoading={isLoading}
            divider
            childrenSkeleton
          >
            <ListItemText
              data-testid="voucher-password"
              primary={t('campaigns:campaign_form.discount_code_label')}
              secondary={campaign?.voucherpassword}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!campaign?.datestart && !isLoading}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="date-start"
                primary={
                  campaign?.datestart
                    ? formatDate(new Date(campaign.datestart))
                    : '-'
                }
              />
            }
          >
            <ListItemText
              data-testid="date-start-label"
              primary={t('campaigns:campaign_form.start_date')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!campaign?.dateend && !isLoading}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="date-end"
                primary={
                  campaign?.dateend
                    ? formatDate(new Date(campaign.dateend))
                    : '-'
                }
              />
            }
          >
            <ListItemText
              data-testid="date-end-label"
              primary={t('campaigns:campaign_form.end_date')}
            />
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
