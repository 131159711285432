import { Box, Chip, IconButton } from '@mui/material'
import { MouseEventHandler, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import { useTracking } from '../../../hooks/tracking'
import { MoreVerticalIcon, RegisterCashIcon } from '@sitoo/mui-components'
import { useQuery } from '@apollo/client'
import { GetCashRegisterDocument } from '../../../generated/graphql'
import { Details } from './details'
import { Company } from './company'
import { ContextMenu } from './context-menu'

type Props = {
  open: boolean
  onClose: () => void
  selectedId?: string
}

export const CashRegisterViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'cash_registers'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const { data, loading: isLoading } = useQuery(GetCashRegisterDocument, {
    variables: { id: String(props.selectedId) },
    skip: !props.selectedId,
    fetchPolicy: 'cache-only',
  })

  const cashRegister = data?.cashRegister

  const title = cashRegister?.clientdescription || ''

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={isLoading}
        title={title}
        section={t('cash_registers:page_title')}
        onClose={props.onClose}
        hideActionsSkeleton
        onBack={() => {
          trackButtonClick({ name: 'cash-register-panel-go-back' })
        }}
        sectionIcon={<RegisterCashIcon />}
        tags={
          !cashRegister?.registerkey && (
            <Chip
              label={t('cash_registers:not_registered')}
              color="red"
              size="small"
            />
          )
        }
        actions={
          <IconButton edge="end" onClick={handleClick}>
            <MoreVerticalIcon />
          </IconButton>
        }
      />

      <ContextMenu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        onClick={closeMenu}
        registerId={props.selectedId}
        isNewCashRegister={!cashRegister?.datelastping}
      />

      <Box sx={{ background: (theme) => theme.palette.background.paper }}>
        <Details cashRegister={cashRegister} isLoading={isLoading} />
        <Company cashRegister={cashRegister} isLoading={isLoading} />
      </Box>
    </ViewPanel>
  )
}
