import { Box } from '@mui/material'

type JournalDividerProps = {
  variant?: 'dashed' | 'double' | 'blank'
  hidden?: boolean
}

export const JournalDivider = (props: JournalDividerProps) => {
  const { variant: type = 'dashed', hidden } = props

  if (hidden) {
    return null
  }

  if (type == 'blank') {
    return <br />
  }

  return (
    <Box
      sx={{
        height: '2em',
        overflow: 'hidden',
        lineHeight: '2em',
      }}
    >
      {`${Array(60).join(type === 'dashed' ? '-' : '=')}`}
    </Box>
  )
}
