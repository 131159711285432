import { GridValueFormatter } from '@mui/x-data-grid-pro'

export const getColumnValue: GridValueFormatter = (
  _value,
  row,
  columnDef,
  apiRef,
): unknown => {
  let value = ''

  if (columnDef.valueFormatter) {
    value = columnDef.valueFormatter(_value, row, columnDef, apiRef) as string
  } else if (columnDef.valueGetter) {
    value = columnDef.valueGetter(_value, row, columnDef, apiRef) as string
  } else {
    value = row[columnDef.field] as string
  }

  return value
}

export const LOCAL_STORAGE_STATE_KEY = 'gridState'

export const RESET_COLUMN_WIDTH_EVENT = '__reset_column_size__'

export const COLUMN_HEADER_HEIGHT = 40
