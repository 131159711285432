import { useTranslation } from 'react-i18next'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { GetCashRegisterQuery } from '../../../generated/graphql'
import { JournalDivider } from './journal-divider'
import { PrintContainer } from './print-container'

type Props = {
  registerData?: GetCashRegisterQuery['cashRegister']
  reportDate: Date
}

export const JournalHeader = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { formatDate } = useLocalizedDate()
  const { registerData, reportDate } = props
  const date = reportDate ? formatDate(reportDate, { hour: undefined }) : ''

  return (
    <PrintContainer>
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>
              <JournalDivider variant="double" />
            </td>
          </tr>
          <tr>
            <td>
              {t('journals:journal_title')} {date}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <JournalDivider variant="double" />
            </td>
          </tr>
          <tr>
            <td>{t('journals:company_name')}:</td>
            <td>{registerData?.company}</td>
          </tr>
          <tr>
            <td>{t('journals:manufacturer_id')}:</td>
            <td> {registerData?.manufacturerid}</td>
          </tr>
          <tr>
            <td>{t('journals:register_key')}: </td>
            <td>{registerData?.registerkey}</td>
          </tr>
          <tr>
            <td>{t('journals:register_number')}: </td>
            <td>{registerData?.registernumber}</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <JournalDivider variant="double" />
            </td>
          </tr>
        </tbody>
      </table>
    </PrintContainer>
  )
}
