import { ListItemButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetShipmentV2Query } from '../../../generated/graphql'
import { ArchiveShipmentDialog } from '../archive-shipment-dialog'

type ArchiveShipmentActionProps = {
  shipment: GetShipmentV2Query['getShipmentV2']
}

export const ArchiveShipmentAction = ({
  shipment,
}: ArchiveShipmentActionProps) => {
  const { t } = useTranslation(['shipments_v2'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const isUpdatingToActive = shipment.archived

  const { enqueueSnackbar } = useSnackbar()
  const onClose = () => {
    setIsDialogOpen(false)
  }

  const onSuccess = () => {
    enqueueSnackbar(
      isUpdatingToActive
        ? t('success_activate_shipment_snackbar', { count: 1 })
        : t('success_archive_shipment_snackbar', { count: 1 }),
    )
    onClose()
  }

  const onError = () => {
    enqueueSnackbar(
      isUpdatingToActive
        ? t('success_activate_shipment_snackbar', { count: 1 })
        : t('failure_archive_shipment_snackbar', { count: 1 }),
      {
        variant: 'error',
      },
    )
    onClose()
  }
  if (!shipment.shipmentid) return

  return (
    <>
      <ArchiveShipmentDialog
        open={isDialogOpen}
        onClose={onClose}
        selectedIds={[shipment.shipmentid]}
        setToArchived={!shipment.archived}
        onSuccess={onSuccess}
        onError={onError}
      />
      <ListItemButton onClick={() => setIsDialogOpen(true)}>
        <Typography variant="body02">
          {isUpdatingToActive
            ? t('view_panel.unarchive_shipment', { count: 1 })
            : t('view_panel.archive_shipment', { count: 1 })}
        </Typography>
      </ListItemButton>
    </>
  )
}
