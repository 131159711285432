import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { Button, Container, Grid, Stack } from '@mui/material'
import { GridColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/page-header'
import {
  SalesTaxGroupsDocument,
  SalesTaxGroupsQuery,
  SalesTaxGroupsQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'

import { ErrorIcon, SalesTaxIcon } from '@sitoo/mui-components'
import { RootRoute } from '../..'
import { DataGrid } from '../../../components/data-grid'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { ArrayElement } from '../../../utils/types'
import { SalesTaxGroupViewPanel } from './sales-tax-group-view-panel'
import { SalesTaxGroupsTaxesRenderer } from './sales-tax-groups-taxes-renderer'
import { containsInvalidRule } from '../sales-tax/shared'

type Row = ArrayElement<SalesTaxGroupsQuery['salesTaxGroups']['items']>

type ViewPanelState = {
  isOpen: boolean
  salesTaxGroupId?: number
}

const PAGE_SIZE = 100

export const salesTaxGroupsViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  salesTaxGroupId: undefined,
})

export const SettingsSalesTaxGroupsPage = () => {
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.sales_tax_groups'))

  const apiRef = useGridApiRef()
  const { trackButtonClick } = useTracking()
  const viewPanelState = useReactiveVar(salesTaxGroupsViewPanelVar)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        page: 0,
        start: 0,
        pageSize: PAGE_SIZE,
      },
    }
    const variables: SalesTaxGroupsQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
    }

    return variables
  }, [])

  const {
    loading: isLoadingSalesTaxGroups,
    data,
    fetchMore,
  } = useQuery(SalesTaxGroupsDocument, {
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const onAdd = () => {
    trackButtonClick({ name: `sales-tax-groups-add-sales-tax-group` })
    navigate(generatePath(RootRoute.SettingsSalesTaxGroupNew))
  }
  const isLoading = isLoadingSalesTaxGroups

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'region',
        minWidth: 80,
        headerName: t('settings:sales_tax_group.region'),
        renderCell: (params) => {
          return params.row.salestaxes.some((salesTax) =>
            containsInvalidRule(salesTax),
          ) ? (
            <Stack direction="row" alignItems="center" height="100%">
              <ErrorIcon
                sx={{ mr: 0.5 }}
                data-testid="invalid-sales-tax-group-icon"
              />
              {params.row.region}
            </Stack>
          ) : (
            <>{params.row.region}</>
          )
        },
      },
      {
        field: 'name',
        minWidth: 140,
        headerName: t('settings:sales_tax.name'),
      },
      {
        field: 'salesTaxes',
        minWidth: 320,
        headerName: t('settings:sales_tax_group.sales_taxes'),
        renderCell: SalesTaxGroupsTaxesRenderer,
      },
    ],
    [t],
  )

  const openViewPanel = (salesTaxGroupId: number) => {
    trackButtonClick({
      name: 'sales-tax-groups-view-panel-open',
      salesTaxGroupId,
    })
    salesTaxGroupsViewPanelVar({ isOpen: true, salesTaxGroupId })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'sales-tax-groups-view-panel-close' })
    salesTaxGroupsViewPanelVar({ isOpen: false, salesTaxGroupId: undefined })
  }

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.salesTaxGroups.totalcount) {
      return fetchMore({
        variables: {
          start: (data.salesTaxGroups?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <Grid container flexWrap="nowrap">
      <Grid item flexGrow={1}>
        <PageHeader
          title={t('shared:menu.sales_tax_groups')}
          isFlexible
          rightColumn={
            <Button data-testid="add-sales-tax-group-button" onClick={onAdd}>
              {t('settings:sales_tax_group.add_sales_tax_group')}
            </Button>
          }
        />
        <Container maxWidth={false}>
          <DataGrid
            name="salesTaxGroups"
            apiRef={apiRef}
            rows={data?.salesTaxGroups.items}
            columns={columns}
            loading={isLoading}
            onRowClick={({ row }: GridRowParams<Row>) => openViewPanel(row.id)}
            paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
            fetchMore={fetchMoreItems}
            rowCount={data?.salesTaxGroups.totalcount || 0}
            slots={{
              pagination: CursorPagination,
            }}
            columnVisibilityModel={{
              region: true,
              name: true,
              salestaxes: true,
            }}
            showMore={
              Number(data?.salesTaxGroups.items?.length) <
              Number(data?.salesTaxGroups.totalcount)
            }
            noRowsOverlay={{
              icon: <SalesTaxIcon />,
              title: t('settings:sales_tax_group.empty_title'),
              description: t('settings:sales_tax_group.empty_description'),
            }}
            sx={{
              '.MuiDataGrid-row': {
                '&.Mui-error': { color: 'redBase' },
              },
            }}
            getRowClassName={(params) => {
              const classes = []
              const containsInvalidRules = params.row.salestaxes.some(
                (salesTax) => containsInvalidRule(salesTax),
              )
              if (params.id === viewPanelState.salesTaxGroupId) {
                classes.push('Mui-selected')
              }
              if (containsInvalidRules) {
                classes.push('Mui-error')
              }

              return classes.join(' ')
            }}
          />
        </Container>
      </Grid>
      <Grid item>
        <SalesTaxGroupViewPanel
          open={viewPanelState.isOpen}
          salesTaxGroupId={viewPanelState.salesTaxGroupId}
          onClose={closeViewPanel}
        />
      </Grid>
    </Grid>
  )
}
