import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../../hooks/tracking'
import { DeleteProduct } from './delete-product'
import { SetCategory } from './set-category'
import { SetProductGroup } from './set-product-group'

type BulkActionsProps = {
  currentDetailedProductId?: number
  onCloseDetailProduct: () => void
  refetch(): unknown
}

export const BulkActions = (props: BulkActionsProps) => {
  const { t } = useTranslation(['shared', 'products'])
  const { trackButtonClickEvent } = useTracking()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const [showSetProductGroup, setShowSetProductGroup] = useState(false)
  const [showSetCategory, setShowSetCategory] = useState(false)

  return (
    <>
      <Button
        id="product-list-bulk-actionsmenu-button"
        data-testid="bulk-actions-button"
        aria-controls={isOpen ? 'product-list-bulk-actionsmenu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={<ChevronSmallDownIcon />}
        size="small"
      >
        {t('shared:label.actions')}
      </Button>
      <Menu
        data-testid="product-bulk-actions-menu"
        id="product-list-bulk-actionsmenu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'product-list-bulk-actionsmenu-button',
        }}
      >
        <MenuItem
          data-testid="bulk-set-product-group"
          onClick={trackButtonClickEvent(
            { name: 'product-list-bulk-actions-menu-set-product-group' },
            () => {
              setShowSetProductGroup(true)
              closeMenu()
            },
          )}
        >
          <Typography variant="body02">
            {t('products:set_product_group')}
          </Typography>
        </MenuItem>
        <MenuItem
          data-testid="bulk-set-category"
          onClick={trackButtonClickEvent(
            { name: 'product-list-bulk-actions-menu-set-category' },
            () => {
              setShowSetCategory(true)
              closeMenu()
            },
          )}
        >
          <Typography variant="body02">{t('products:set_category')}</Typography>
        </MenuItem>
      </Menu>

      <DeleteProduct
        refetch={props.refetch}
        onSuccess={(productIds) => {
          if (productIds.includes(props.currentDetailedProductId || 0)) {
            props.onCloseDetailProduct()
          }
        }}
      />

      <SetProductGroup
        isOpen={showSetProductGroup}
        refetch={props.refetch}
        onClose={() => {
          setShowSetProductGroup(false)
        }}
      />

      <SetCategory
        isOpen={showSetCategory}
        refetch={props.refetch}
        onClose={() => {
          setShowSetCategory(false)
        }}
      />
    </>
  )
}
