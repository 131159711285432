import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  EmailIcon,
  PhoneIcon,
  ReceiptIcon,
  SectionHeader,
  ShipmentIcon,
  UserDefaultIcon,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetOrderQuery } from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { CustomerEditDialog, OrderCustomer } from './customer-edit-dialog'

type Props = {
  isLoading?: boolean
  order?: GetOrderQuery['order']
  readOnly?: boolean
}

export const Customer = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders', 'countries'])
  const { enqueueSnackbar } = useSnackbar()
  const [showEditDialog, setShowEditDialog] = useState(false)
  const {
    modules: { writeOrders },
  } = useAuthorization()

  const order = props.order

  const customerName = [order?.namefirst, order?.namelast]
    .filter(Boolean)
    .join(' ')

  const customerData = [
    order?.company,
    order?.personalid,
    order?.phone,
    order?.email,
    order?.customerref,
  ]
    .filter(Boolean)
    .join('\n')

  const deliveryAddressData = [
    order?.delivery_address,
    order?.delivery_address2,
    [order?.delivery_city, order?.delivery_state, order?.delivery_zip]
      .filter(Boolean)
      .join(', '),
    order?.delivery_countryid
      ? t(`countries:${order?.delivery_countryid.toUpperCase()}`)
      : '',
  ]
    .filter(Boolean)
    .join('\n')

  const invoiceAddressData = [
    order?.invoice_address,
    order?.invoice_address2,
    [order?.invoice_city, order?.invoice_state, order?.invoice_zip]
      .filter(Boolean)
      .join(', '),
    order?.invoice_countryid
      ? t(`countries:${order?.invoice_countryid.toUpperCase()}`)
      : '',
  ]
    .filter(Boolean)
    .join('\n')

  const getOrderInformation = (
    order?: GetOrderQuery['order'],
  ): OrderCustomer | undefined =>
    order
      ? {
          company: order.company,
          email: order.email,
          namefirst: order.namefirst,
          namelast: order.namelast,
          phone: order.phone,
          personalid: order.personalid,
          customerref: order.customerref,
          delivery_address: order.delivery_address,
          delivery_address2: order.delivery_address2,
          delivery_city: order.delivery_city,
          delivery_state: order.delivery_state,
          delivery_zip: order.delivery_zip,
          delivery_countryid: order.delivery_countryid,
          invoice_address: order.invoice_address,
          invoice_address2: order.invoice_address2,
          invoice_city: order.invoice_city,
          invoice_state: order.invoice_state,
          invoice_zip: order.invoice_zip,
          invoice_countryid: order.invoice_countryid,
        }
      : undefined

  const onSuccessInformationEditDialog = () => {
    setShowEditDialog(false)
    enqueueSnackbar(t('orders:edit_customer_dialog.success_update'))
  }

  const onErrorInformationEditDialog = () => {
    enqueueSnackbar(t('orders:edit_customer_dialog.failure_update'), {
      variant: 'error',
    })
  }

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="customer">
        <SectionHeader sx={{ p: 0 }}>{t('orders:customer')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={!props.isLoading && !customerName && !customerData}
            isLoading={props.isLoading}
            secondaryAction={
              <>
                {order?.email && (
                  <IconButton href={`mailto:${order?.email}`}>
                    <EmailIcon />
                  </IconButton>
                )}
                {order?.phone && (
                  <IconButton href={`tel:${order?.phone}`}>
                    <PhoneIcon />
                  </IconButton>
                )}
              </>
            }
            data-testid="order-customer"
            divider
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            <ListItemIcon>
              <UserDefaultIcon />
            </ListItemIcon>
            <ListItemText
              primary={props.isLoading ? t('orders:customer') : customerName}
              secondary={customerData}
            />
          </ListItemSkeleton>
          <ListItemSkeleton
            hide={!props.isLoading && !deliveryAddressData}
            isLoading={props.isLoading}
            secondaryAction={deliveryAddressData ? null : '-'}
            data-testid="order-delivery-address"
            divider
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            <ListItemIcon>
              <ShipmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('orders:view_panel.delivery_address_label')}
              secondary={deliveryAddressData}
            />
          </ListItemSkeleton>
          <ListItemSkeleton
            hide={!props.isLoading && !invoiceAddressData}
            isLoading={props.isLoading}
            secondaryAction={invoiceAddressData ? null : '-'}
            data-testid="order-invoice-address"
            divider
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('orders:view_panel.invoice_address_label')}
              secondary={invoiceAddressData}
            />
          </ListItemSkeleton>

          {writeOrders && !props.readOnly && (
            <ListItemSkeleton
              sx={{
                boxShadow: (theme) => `0 -1px 0 ${theme.palette.white}`,
              }}
              isLoading={props.isLoading}
            >
              <Button
                color="secondary"
                size="small"
                onClick={() => setShowEditDialog(true)}
                data-testid="edit-order-customer"
                fullWidth
              >
                {t('shared:action.edit')}
              </Button>
              <CustomerEditDialog
                open={showEditDialog}
                onClose={() => setShowEditDialog(false)}
                order={getOrderInformation(order)}
                orderId={order?.orderid}
                onSuccess={onSuccessInformationEditDialog}
                onError={onErrorInformationEditDialog}
              />
            </ListItemSkeleton>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
