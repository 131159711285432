import { Box, Tooltip } from '@mui/material'
import { VariantError } from '.'
import { VariantRendererProps } from './variants-renderer'

type Props = {
  errors: VariantError[]
  children: React.ReactElement
  rendererProps: VariantRendererProps
}

export const TooltipErrorRenderer = (props: Props) => {
  const { errors, rendererProps, children } = props

  const fieldErrors = errors.filter(
    (x) => x.field === rendererProps.field && x.rowId === rendererProps.id,
  )

  return (
    <Tooltip title={fieldErrors.map((x) => x.message).join('\n')}>
      <Box>{children}</Box>
    </Tooltip>
  )
}
