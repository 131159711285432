import { Autocomplete, SxProps, TextField } from '@mui/material'
import { Ref } from 'react'
import {
  FieldError,
  FieldValues,
  UseControllerProps,
  get,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserTag } from '../../generated/graphql'
import { addTagOnInputChange } from '../../utils/autocomplete'

type Props = {
  isRequired?: boolean
  sx?: SxProps
  placeholder?: string
  helperText?: string
  dataTestId?: string
  innerRef?: Ref<HTMLElement>
  userTags?: UserTag[]
}

export const UserTagsInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const {
    rules,
    control,
    name,
    sx,
    placeholder,
    helperText,
    disabled,
    userTags,
  } = props
  const { dataTestId = 'user-tags-field', innerRef } = props
  const { t } = useTranslation(['shared'])

  const { field, formState } = useController({ name, control, rules })

  const formError = get(formState.errors, name) as FieldError
  const options: UserTag[] = userTags || []

  return (
    <Autocomplete
      ref={innerRef}
      autoSelect
      multiple
      sx={sx}
      disabled={disabled}
      options={options}
      onChange={(_event, value) => field.onChange(value)}
      value={field.value || []}
      data-testid={dataTestId}
      onInputChange={addTagOnInputChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionKey={(option) => option.id}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!formError}
          helperText={helperText}
          placeholder={placeholder || t('shared:label.add_tag')}
        />
      )}
    />
  )
}
