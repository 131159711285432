/**
 * Converts a list of tags to a string with +n format.
 * @example ["foo", "bar", "baz", "ban"] => "foo, bar, baz, +1"
 *
 */
export const joinTags = (tags: string[], maxTags = 3): string => {
  if (tags.length <= maxTags) {
    return tags.join(', ')
  }

  return [...tags.slice(0, maxTags), `+${tags.length - maxTags}`].join(', ')
}
