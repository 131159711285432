import { useEffect, RefObject } from 'react'
import {
  FieldValues,
  UseControllerProps,
  get,
  useFormState,
  FieldError,
} from 'react-hook-form'

type Props = {
  ref: RefObject<HTMLElement>
  position?: ScrollLogicalPosition
}

export const useScrollToError = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, control, ref, position = 'center' } = props
  const formState = useFormState({ control, name })

  const formError = get(formState.errors, name) as
    | { [name: string]: FieldError }
    | FieldError

  useEffect(() => {
    if (formError) {
      ref.current?.scrollIntoView({ block: position })
    }
  }, [formError, name, position, ref])
}
