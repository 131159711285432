import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseServiceOrderFormContext } from '../shared'

export const CustomerField = () => {
  const { register, formState } = useFormContext<BaseServiceOrderFormContext>()
  const { t } = useTranslation(['service_orders'])

  return (
    <FormFieldset label={t('customer')}>
      <TextField
        error={!!formState?.errors.customer?.first_name}
        fullWidth
        helperText={formState?.errors.customer?.first_name?.message}
        label={t('service_order_form.first_name')}
        data-testid="customer-first-name-input"
        required
        {...register('customer.first_name', {
          required: t('shared:validation.field_required', {
            field: t('service_order_form.first_name'),
          }),
        })}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.customer?.last_name}
        fullWidth
        helperText={formState?.errors.customer?.last_name?.message}
        label={t('service_order_form.last_name')}
        data-testid="customer-last-name-input"
        {...register('customer.last_name')}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.customer?.email}
        fullWidth
        helperText={formState?.errors.customer?.email?.message}
        label={t('service_order_form.email')}
        data-testid="customer-email-input"
        {...register('customer.email')}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.customer?.mobile}
        fullWidth
        helperText={formState?.errors.customer?.mobile?.message}
        label={t('service_order_form.phone')}
        data-testid="customer-mobile-input"
        {...register('customer.mobile')}
        sx={{ mb: 2 }}
      />
    </FormFieldset>
  )
}
