import { useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { generatePath as originalGeneratePath } from 'react-router-dom'
import { MeDocument } from '../../generated/graphql'
import { DEFAULT_SITE_ID } from '../../utils/constants'

export const useAbsolutePath = () => {
  const { data } = useQuery(MeDocument)
  const siteId = data?.me.siteId || DEFAULT_SITE_ID

  const generatePath = useCallback<typeof originalGeneratePath>(
    (_path, params) => {
      const path = _path as '/:siteId'

      return originalGeneratePath(path, {
        siteId: String(siteId),
        ...params,
      })
    },
    [siteId],
  )

  return generatePath
}
