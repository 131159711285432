import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { SubmitErrorHandler, UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { RootRoute } from '../..'
import { AddShipmentV2Document } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { shipmentViewPanelVar } from '../../shipments-v2'
import { BaseShipmentFormContext } from '../shared'
import { formatFormDataToShipment } from '../utils'

type Props = {
  setError: UseFormSetError<BaseShipmentFormContext>
}

export const useNewShipmentFormSubmit = (props: Props) => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const { setError } = props
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const [addShipment, { loading: addLoading }] = useMutation(
    AddShipmentV2Document,
  )

  const { enqueueSnackbar } = useSnackbar()
  const setFormError = useServerValidation<BaseShipmentFormContext>(
    'shipments_v2',
    setError,
    {
      resolveFieldFromProperty: (property) => property,
    },
  )

  const isSubmitting = addLoading

  const onSubmit = async (data: BaseShipmentFormContext) => {
    const formattedPayload = formatFormDataToShipment(data)
    try {
      trackButtonClick({ name: 'shipment-v2-save' })
      const { data: newShipment } = await addShipment({
        variables: {
          shipment: formattedPayload,
        },
      })

      const newShipmentId = Number(newShipment?.addShipmentV2.shipmentid)

      trackFormSuccess({ name: 'shipment_v2' })
      shipmentViewPanelVar({
        isOpen: true,
        shipmentId: newShipmentId,
      })
      enqueueSnackbar(t('shipments_v2:shipment_message.success_add'))
      navigate(generatePath(RootRoute.Shipments))
    } catch (error) {
      setFormError(error)
      const errorMessage = getErrorMessage(
        error,
        'shipments_v2',
        t('shipments_v2:shipment_message.failure_add'),
      )

      trackFormError({
        name: 'shipment_v2',
        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<BaseShipmentFormContext> = (_errors) => {
    enqueueSnackbar(t('shipments_v2:error.generic'), {
      variant: 'error',
      autoHideDuration: 2000,
    })
  }

  return { onSubmit, isSubmitting, onError }
}
