import { useQuery } from '@apollo/client'
import { Container, Divider } from '@mui/material'
import { AllCustomAttributesDocument } from '../../generated/graphql'
import { useAuthorization } from '../../hooks/authorization'
import { AmountUnitField } from './fields/amount-unit'
import { AttributesField } from './fields/attributes'
import { BasicDetailsField } from './fields/basic-details'
import { BrandField } from './fields/brand'
import { CategoriesField } from './fields/categories'
import { CustomFields } from './fields/custom-fields'
import { DeleteField } from './fields/delete'
import { DescriptionField } from './fields/description'
import { ImagesField } from './fields/images'
import { MainCategoryField } from './fields/main-category'
import { ProductGroupField } from './fields/product-group'
import { StockField } from './fields/stock'
import { VariantsField } from './fields/variants'

type Props = { isLoading?: boolean; navigateToVariantsTab(): void }

export const Information = (props: Props) => {
  const { data: customAttributesData } = useQuery(AllCustomAttributesDocument)
  const {
    modules: { writeProducts },
  } = useAuthorization()

  return (
    <Container>
      <BasicDetailsField />

      <ImagesField isLoading={props.isLoading} />

      <VariantsField navigateToVariantsTab={props.navigateToVariantsTab} />

      <Divider />

      <DescriptionField />

      <Divider />

      <MainCategoryField />

      <CategoriesField />

      <Divider />

      <ProductGroupField />

      <Divider />

      <StockField />

      <Divider />

      <AmountUnitField />

      <Divider />

      {(customAttributesData?.allCustomAttributes || []).length > 0 && (
        <>
          <AttributesField />

          <Divider />
        </>
      )}

      <BrandField />

      <Divider />

      <CustomFields />
      {writeProducts && (
        <>
          <Divider />

          <DeleteField />
        </>
      )}
    </Container>
  )
}
