import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { DateFilter, OptionType } from '../../../components/date-filter'
import { StoreFilter } from '../orders-list/store-filter'
import { OrderIdFilter } from '../orders-list/order-id-filter'

type OrderFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const OrderFilterViewPanel = (props: OrderFilterViewPanelProps) => {
  const { t } = useTranslation(['orders'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>{t('orders:page_title')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <DateFilter
                data-testid="order-date-filter"
                hiddenOptions={[OptionType.AllTime]}
              />
            </ListItem>
            <ListItem>
              <StoreFilter />
            </ListItem>
            <OrderIdFilter />
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
