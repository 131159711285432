import { Box, Button, ListItem, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'

export type ImportListItem = {
  active: boolean
  title: string
  description?: string
  dataTestId: string
  onImport: () => void
}

type ImportListItemProps = {
  items: ImportListItem[]
}

export const ImportList = (props: ImportListItemProps) => {
  const { t } = useTranslation(['shared'])
  const activeItems = props.items.filter((item) => item.active)

  return (
    <Box>
      {activeItems.map((item, i) => (
        <ListItem
          key={i}
          divider={i + 1 < activeItems.length}
          data-testid={item.dataTestId}
          secondaryAction={
            <Button
              size="small"
              color="secondary"
              onClick={item.onImport}
              data-testid={`${item.dataTestId}-button`}
            >
              {t('shared:action.import')}
            </Button>
          }
        >
          <ListItemText secondary={item.description}>{item.title}</ListItemText>
        </ListItem>
      ))}
    </Box>
  )
}
