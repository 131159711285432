import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../hooks/tracking'
import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { filterVar } from '../../cache'
import { FilterDocument } from '../../generated/graphql'

export const filterPresetDeleteDialogVar = makeVar<string | undefined>(
  undefined,
)

export const FilterPresetDeleteDialog = () => {
  const { t } = useTranslation(['filter'])
  const dialogName = 'delete-filter-preset'
  const isLoading = false
  const { trackDialogOpen, trackDialogClose, trackButtonClick } = useTracking()
  const presetId = useReactiveVar<string | undefined>(
    filterPresetDeleteDialogVar,
  )
  const isOpen = !!presetId
  const prevOpen = usePrevious(isOpen)

  const { data } = useQuery(FilterDocument)

  const onDelete = () => {
    trackButtonClick({ name: `${dialogName}-dialog-delete` })

    if (presetId) {
      filterVar(data?.filter.filter(({ id }) => id !== presetId))

      filterPresetDeleteDialogVar(undefined)
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })
      filterPresetDeleteDialogVar(undefined)
    }
  }

  useEffect(() => {
    if (isOpen && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [isOpen, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={onDelete}
      title={t('filter:filter_preset.dialog_delete_label')}
      text={t('filter:filter_preset.dialog_delete_description')}
      variant="destructive"
      open={isOpen}
      dataTestid="filter-preset-delete-dialog"
      onClose={onClose}
    />
  )
}
