import { Fragment } from 'react'
import { GetOrderQuery } from '../../../../generated/graphql'
import { Stack, Typography } from '@mui/material'
import { PaymentAdditionalData } from '../../util'
import { useTranslation } from 'react-i18next'
import { useMoney } from '../../../../hooks/money'

type PaymentSectionProps = {
  order?: GetOrderQuery['order']
}

export const PaymentSection = ({ order }: PaymentSectionProps) => {
  const { t } = useTranslation(['orders'])
  const { formatCurrency } = useMoney()
  if (!order) return

  return (
    <Fragment>
      <Typography variant="subheader" sx={{ textTransform: 'uppercase' }}>
        {t('orders:print.payment_section.title')}
      </Typography>
      {order.payments.map((payment, index) => {
        const additionalData: PaymentAdditionalData | null | undefined =
          payment.additionaldata || {}

        const currencyCode =
          additionalData['foreign-currency-code'] || order.currencycode

        const paymentMethod = additionalData['payment-cardmaskedpan']
          ? `${payment.name} (${additionalData['payment-cardmaskedpan']})`
          : payment.name

        const giftCardNumber = additionalData['giftcard-cardnumber']

        return (
          <Stack
            key={`${payment.name}-${index}`}
            sx={{ mt: index !== 0 ? 2 : 0 }}
          >
            <Typography variant="caption">
              {formatCurrency(payment.moneyamount, {
                currency: currencyCode || undefined,
              })}
            </Typography>
            <Typography variant="caption">{paymentMethod}</Typography>
            {giftCardNumber && (
              <Typography variant="caption">
                {t('orders:print.payment_section.gift_card_number', {
                  giftCardNumber,
                })}
              </Typography>
            )}
            <Typography variant="caption">
              {t('orders:print.payment_section.reference', {
                refId: payment.refid || '-',
              })}
            </Typography>
          </Stack>
        )
      })}
    </Fragment>
  )
}
