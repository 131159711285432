import { makeVar } from '@apollo/client'
import { DEFAULT_ORDER_ID } from '../../utils/constants'

export type OrderViewPanelState = {
  orderId: number
  siteId?: number
  isOpen: boolean
  previous?: OrderViewPanelState
}

export const orderViewPanelVar = makeVar<OrderViewPanelState>({
  isOpen: false,
  orderId: DEFAULT_ORDER_ID,
})

export const orderFilterViewPanelVar = makeVar({
  isOpen: false,
})

export type OrderAdditionalData = {
  [key: string]: unknown
  'pos-full-return-only'?: string
  'tax-free-transaction-id'?: string
  'pos-receiptid'?: string
  'store-name'?: string
  'pos-staff-externalid'?: string
  'refundreasoncode-reasoncode'?: string
  'crm-membernumber'?: string
  'pos-refund-initiation-mode'?: string
}

export type OrderItemAdditionalData = {
  [key: string]: unknown
  'orderitem-moneyrowdiscount'?: string
  'reasoncode-manualdiscount'?: string
  'reasoncode-manualprice'?: string
  'reasoncode-return'?: string
}

export type PaymentAdditionalData = {
  [key: string]: unknown
  'payment-method'?: string
  'payment-cardmaskedpan'?: string
  'foreign-currency-code'?: string
  'giftcard-cardnumber'?: string
}
