import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseSalesTaxGroupContext } from '../shared'

export const InformationField = () => {
  const { t } = useTranslation(['settings', 'shared'])
  const { register, formState } = useFormContext<BaseSalesTaxGroupContext>()

  return (
    <FormFieldset label={t('sales_tax.information_fieldset')}>
      <TextField
        label={t('settings:sales_tax.name')}
        data-testid="tax-group-name-field"
        sx={{ mb: 2 }}
        error={!!formState?.errors.name}
        helperText={formState?.errors.name?.message}
        {...register('name', {
          required: t('shared:validation.field_required', {
            field: t('settings:sales_tax.name'),
          }),
        })}
      />

      <TextField
        label={t('settings:sales_tax_group.region')}
        sx={{ mb: 2 }}
        data-testid="tax-group-region-field"
        error={!!formState?.errors.region}
        helperText={formState?.errors.region?.message}
        {...register('region')}
      />
    </FormFieldset>
  )
}
