import { Chip } from '@mui/material'
import { ServiceOrderState } from '../../../generated/graphql'

type Props = {
  label: string
  state?: ServiceOrderState | null
}

const tagColorMap: { [key: string]: 'gray' | 'black' | 'green' | 'red' } = {
  [ServiceOrderState.New]: 'gray',
  [ServiceOrderState.InProgress]: 'black',
  [ServiceOrderState.ServiceDone]: 'green',
  [ServiceOrderState.Cancelled]: 'red',
  [ServiceOrderState.Closed]: 'gray',
}

export const ServiceOrderStateChip = ({ label, state }: Props) => {
  return (
    <Chip
      data-testid="service-order-state-chip"
      label={label}
      size="small"
      color={state ? tagColorMap[state] : 'green'}
    />
  )
}
