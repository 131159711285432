import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  DisableIcon,
  ProgressDoneIcon,
  WarningIcon,
} from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportField } from '../../../../components/import-field'
import {
  ConvertWarehouseBatchItemsCsvDocument,
  ImportWarehouseBatchItemsDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { getErrorMessage } from '../../../../utils/error-mapping'
import { extractGraphqlErrors } from '../../../../utils/extract-graphql-errors'

export type Props = {
  open: boolean
  warehouseId: number
  warehouseBatchId: number
  onClose: () => void
  onSuccess: () => void
  onError?(): void
}

export const ImportStocktakingDialog = (props: Props) => {
  const { t } = useTranslation(['stocktaking', 'shared'])
  const dialogName = 'import-stocktaking'
  const [files, setFiles] = useState<UploadedFile[]>()
  const [step, setStep] = useState<0 | 1 | 2>(0)

  const [
    convertWarehouseBatchItemsToCsv,
    { data: csvWarehouseBatchItemsData, loading: convertLoading },
  ] = useLazyQuery(ConvertWarehouseBatchItemsCsvDocument)
  const [
    importWarehouseMutation,
    { data: importPricesData, loading: importLoading, error: importError },
  ] = useMutation(ImportWarehouseBatchItemsDocument)
  const isLoading = convertLoading || importLoading
  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormSuccess,
    trackFormError,
  } = useTracking()

  const importErrors = extractGraphqlErrors(importError)

  useEffect(() => {
    setStep(0)
    setFiles([])
  }, [props.open])

  useEffect(() => {
    if (props.open) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, trackDialogOpen])

  const onClose = (
    _event: React.MouseEvent,
    reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick',
  ) => {
    if (reason === 'backdropClick') {
      return
    }

    trackDialogClose({ name: dialogName })
    props.onClose()
  }

  const onConvert = () => {
    const csvFile = files?.[0]
    const reader = new FileReader()

    if (csvFile?.file) {
      reader.readAsText(csvFile.file, 'UTF-8')

      reader.onload = async (event) => {
        const content = event.target?.result
        if (typeof content === 'string') {
          await convertWarehouseBatchItemsToCsv({ variables: { csv: content } })
          trackFormSuccess({ name: dialogName })
          setStep(1)
        }
      }
    }
  }

  const onImport = async () => {
    const items =
      csvWarehouseBatchItemsData?.convertWarehouseBatchItemsCsv.items
    if (items) {
      try {
        await importWarehouseMutation({
          variables: {
            warehouseBatchId: props.warehouseBatchId,
            warehouseId: props.warehouseId,
            data: items.map(({ note, decimalquantity, sku }) => ({
              sku: sku || '',
              decimalquantity: decimalquantity || 0,
              note: note || '',
            })),
          },
        })
        trackFormSuccess({ name: dialogName })
        props.onSuccess()
      } catch (error) {
        const errorMessage = getErrorMessage(error, 'stocktaking')
        trackFormError({ name: dialogName, errorMessage })
        props.onError?.()
      } finally {
        setStep(2)
      }
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={props.open}
      maxWidth="xs"
      fullWidth
      data-testid={`${dialogName}-dialog`}
    >
      <DialogTitle type="extended">
        {t('stocktaking:import_dialog.title')}
      </DialogTitle>

      <DialogContent sx={{ px: 0 }}>
        {step === 0 && (
          <>
            <ImportField setFiles={setFiles} />

            <Typography
              sx={{
                color: (t) => t.palette.text.disabled,
                whiteSpace: 'pre',
                p: 2,
              }}
            >
              {t('stocktaking:import_dialog.example_label')}
              {`\nsku;decimalquantity;comment\nproduct-sku-1;10;\nproduct-sku-2;0;none found\n`}
            </Typography>
          </>
        )}

        {step === 1 && (
          <>
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <WarningIcon fontSize="large" color="warning" />
              <Typography variant="heading">
                {t('stocktaking:import_dialog.confirm_import')}
              </Typography>
            </Stack>

            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subheader"
                      color="gray60"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {t('stocktaking:import_dialog.summary')}
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('stocktaking:import_dialog.number_import_label')}
                />
                <Typography fontWeight="medium">
                  {csvWarehouseBatchItemsData?.convertWarehouseBatchItemsCsv
                    .items.length || 0}
                </Typography>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText
                  primary={t('stocktaking:import_dialog.skipped_import_label')}
                  secondary={csvWarehouseBatchItemsData?.convertWarehouseBatchItemsCsv.errors.map(
                    (error) => (
                      <Box
                        component="span"
                        key={error.property}
                        sx={{
                          px: 1,
                          color: (t) => t.palette.error.main,
                          display: 'block',
                        }}
                      >
                        {t(`stocktaking:error.${error.code.toLowerCase()}`, {
                          property: error.property,
                        })}
                      </Box>
                    ),
                  )}
                />
                <Typography fontWeight="medium">
                  {csvWarehouseBatchItemsData?.convertWarehouseBatchItemsCsv
                    .errors.length || 0}
                </Typography>
              </ListItem>
            </List>
          </>
        )}

        {step === 2 && (
          <>
            {!importError && (
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
              >
                <ProgressDoneIcon fontSize="large" color="success" />
                <Typography variant="heading">
                  {t('stocktaking:import_dialog.finished')}
                </Typography>
              </Stack>
            )}

            {importError && (
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
              >
                <DisableIcon fontSize="large" color="error" />
                <Typography variant="heading">
                  {t('stocktaking:import_dialog.failed')}
                </Typography>
              </Stack>
            )}

            <List>
              <ListItem>
                <ListItemText
                  primary={t('stocktaking:import_dialog.imported_label')}
                />
                <Typography fontWeight="medium">
                  {importPricesData?.importWarehouseBatchItems.imported || 0}
                </Typography>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText
                  primary={t('stocktaking:import_dialog.deleted_label')}
                />
                <Typography fontWeight="medium">
                  {importPricesData?.importWarehouseBatchItems.deleted || 0}
                </Typography>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText
                  primary={t('stocktaking:import_dialog.failed_label', {})}
                  secondary={importErrors.map((error) => (
                    <Box
                      component="span"
                      key={
                        (error.extensions?.['property'] as string) ||
                        (error.extensions?.['code'] as string)
                      }
                      sx={{
                        px: 1,
                        color: (t) => t.palette.error.main,
                        display: 'block',
                      }}
                    >
                      {t(
                        `stocktaking:error.${String(
                          error.extensions?.['code'],
                        ).toLowerCase()}`,

                        { property: error.extensions?.['property'] },
                      )}
                    </Box>
                  ))}
                />
                <Typography fontWeight="medium">
                  {importErrors.length || 0}
                </Typography>
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!(step === 2 && importErrors.length === 0) && (
          <Button
            onClick={(event) => {
              trackButtonClick({ name: `${dialogName}-dialog-close` })
              onClose(event, 'buttonClick')
            }}
            color="secondary"
            size="small"
          >
            {t('shared:action.close')}
          </Button>
        )}
        {step === 0 && (
          <Button
            onClick={onConvert}
            size="small"
            data-testid="dialog-submit"
            disabled={!files?.length || isLoading}
          >
            {t('shared:action.validate')}
          </Button>
        )}

        {step === 1 && (
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onImport}
            data-testid="dialog-submit"
            size="small"
            disabled={
              !csvWarehouseBatchItemsData?.convertWarehouseBatchItemsCsv.items
                .length || isLoading
            }
          >
            {t('shared:action.import')}
          </Button>
        )}

        {step === 2 && importErrors.length === 0 && (
          <Button
            onClick={(event) => onClose(event, 'buttonClick')}
            data-testid="dialog-submit"
            size="small"
          >
            {t('shared:action.finish')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
