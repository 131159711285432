import { Box, IconButton, TextField } from '@mui/material'
import { MinusIcon, PlusIcon } from '@sitoo/mui-components'

type ProductCounterProps = {
  currentAmount: number
  maxAmount?: number
  minAmount?: number
  updateAmount(newAmount: number): void | Promise<void>
}

export const ProductCounter = (props: ProductCounterProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '34px 43px 34px',
        columnGap: 0.5,
        height: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <IconButton
        data-testid="minus-icon"
        disabled={props.currentAmount === (props.minAmount || 0)}
        onClick={() =>
          void props.updateAmount(
            props.currentAmount === 0 ? 0 : props.currentAmount - 1,
          )
        }
        sx={{
          ':disabled': {
            background: 'transparent',
          },
        }}
      >
        <MinusIcon />
      </IconButton>
      <TextField
        data-testid="product-count-input"
        value={props.currentAmount}
        onChange={(evt) => {
          const newQuantity = Number(evt.target.value) || 0
          const finalQuantity =
            props.maxAmount !== undefined && newQuantity > props.maxAmount
              ? props.maxAmount
              : newQuantity < 0
                ? 0
                : newQuantity

          if (finalQuantity !== props.currentAmount)
            void props.updateAmount(finalQuantity)
        }}
        onKeyDown={(event) => {
          event.stopPropagation()
        }}
        onFocus={(event) => event.currentTarget.select()}
        InputProps={{
          sx: {
            height: '34px',
            '.MuiInput-input': {
              px: 0,
              textAlign: 'center',
            },
          },
        }}
        placeholder="0"
      />
      <IconButton
        data-testid="plus-icon"
        disabled={
          props.maxAmount !== undefined &&
          props.currentAmount >= props.maxAmount
        }
        onClick={() =>
          void props.updateAmount(
            props.maxAmount !== undefined &&
              props.currentAmount > props.maxAmount
              ? props.maxAmount
              : props.currentAmount + 1,
          )
        }
        sx={{
          ':disabled': {
            background: 'transparent',
          },
        }}
      >
        <PlusIcon />
      </IconButton>
    </Box>
  )
}
