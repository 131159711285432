import { ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { useMoney } from '../../../../hooks/money'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalCart' }
  >
}

export const CartDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props

  const { formatCurrency } = useMoney()

  return (
    <>
      <ListItemSkeleton
        hide={!journal?.id}
        childrenSkeleton
        divider
        data-testid="journal-id"
      >
        <ListItemText secondary={journal?.id}>
          {t('journals:cart_id')}
        </ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={journal?.quantitytotal === undefined}
        secondaryAction={<ListItemText>{journal?.quantitytotal}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-quantitytotal"
      >
        <ListItemText>{t('journals:quantity_total')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.moneydiscount}
        secondaryAction={
          <ListItemText>
            {journal?.moneydiscount
              ? formatCurrency(journal?.moneydiscount)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-moneydiscount"
      >
        <ListItemText>{t('journals:money_discount')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.moneytotal}
        secondaryAction={
          <ListItemText>
            {journal?.moneytotal ? formatCurrency(journal?.moneytotal) : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-moneytotal"
      >
        <ListItemText>{t('journals:money_total')}</ListItemText>
      </ListItemSkeleton>
    </>
  )
}
