import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import {
  ConfirmationDialog,
  DeleteIcon,
  EditIcon,
  PlusIcon,
} from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import {
  AddUserTagDocument,
  DeleteUserTagDocument,
  GetUserTagsDocument,
  UpdateUserTagDocument,
  UserTag,
} from '../../../generated/graphql'
import { UserTagDialog } from './user-tag-dialog'
import { usePageTitle } from '../../../hooks/title'

export const UserTagsPage = () => {
  const { t } = useTranslation(['shared', 'users'])
  usePageTitle(t('shared:menu.user_tags'))
  const formContext = useForm<UserTag>({ defaultValues: { label: '', id: '' } })
  const [showUserTagDialog, setShowUserTagDialog] = useState(false)
  const [userTagToDelete, setUserTagToDelete] = useState<UserTag>()

  const { data, loading: isLoadingUserTags } = useQuery(GetUserTagsDocument)

  const [addUserTag, { loading: isAddingUserTag }] = useMutation(
    AddUserTagDocument,
    { refetchQueries: [GetUserTagsDocument] },
  )

  const [updateUserTag, { loading: isUpdatingUserTag }] = useMutation(
    UpdateUserTagDocument,
    { refetchQueries: [GetUserTagsDocument] },
  )

  const [deleteUserTag, { loading: isDeletingUserTag }] = useMutation(
    DeleteUserTagDocument,
    { refetchQueries: [GetUserTagsDocument] },
  )

  const onClose = () => {
    setShowUserTagDialog(false)
    formContext.reset()
  }
  const onEdit = (userTag: UserTag) => {
    formContext.reset(userTag, { keepDefaultValues: true })
    setShowUserTagDialog(true)
  }

  const onDelete = async () => {
    if (!userTagToDelete) {
      enqueueSnackbar(t('users:user_tags.failure_delete'), { variant: 'error' })
    } else {
      try {
        await deleteUserTag({ variables: { id: userTagToDelete.id } })
        enqueueSnackbar(t('users:user_tags.success_delete'))
      } catch {
        enqueueSnackbar(t('users:user_tags.failure_delete'), {
          variant: 'error',
        })
      }
    }
    setUserTagToDelete(undefined)
  }

  const onSubmit = async (submittedData: UserTag) => {
    const isEditing = !!submittedData.id
    try {
      if (isEditing) {
        await updateUserTag({ variables: { userTag: submittedData } })
      } else {
        await addUserTag({ variables: { label: submittedData.label } })
      }
      const successMessage = isEditing
        ? t('users:user_tags.success_edit')
        : t('users:user_tags.success_add')
      enqueueSnackbar(successMessage, { variant: 'success' })
    } catch {
      const errorMessage = isEditing
        ? t('users:user_tags.failure_edit')
        : t('users.user_tags.failure_add')
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
    onClose()
  }

  const isLoading =
    isLoadingUserTags ||
    isAddingUserTag ||
    isUpdatingUserTag ||
    isDeletingUserTag

  return (
    <>
      <FormProvider {...formContext}>
        <UserTagDialog
          open={showUserTagDialog}
          onClose={onClose}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={formContext.handleSubmit(onSubmit)}
        />

        <ConfirmationDialog
          confirmAction={onDelete}
          open={!!userTagToDelete}
          onClose={() => setUserTagToDelete(undefined)}
          text={t('users:user_tags.delete_tag_description', {
            userTag: userTagToDelete?.label,
          })}
          title={t('users:user_tags.delete_tag')}
          variant="destructive"
        />

        <PageHeader title={t('shared:menu.user_tags')} />
        <Container>
          <List
            sx={{
              background: (theme) => theme.palette.background.paper,
            }}
          >
            {data?.getUserTags?.map((userTag, i) => (
              <ListItem
                divider={i !== data?.getUserTags?.length - 1}
                key={userTag.id}
                secondaryAction={
                  <>
                    <IconButton
                      disabled={isLoading}
                      onClick={() => onEdit(userTag)}
                      data-testid={`edit-user-tag-${userTag.id}`}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      disabled={isLoading}
                      onClick={() => setUserTagToDelete(userTag)}
                      data-testid={`delete-user-tag-${userTag.id}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
                data-testid={`user-tag-${userTag.id}`}
              >
                <ListItemText>{userTag.label}</ListItemText>
              </ListItem>
            ))}

            <Button
              color="tertiary"
              fullWidth
              onClick={() => setShowUserTagDialog(true)}
              data-testid={`add-user-tag`}
              disabled={isLoading}
            >
              <PlusIcon sx={{ mr: 1 }} fontSize="medium" />
              {t('shared:action.add')}
            </Button>
          </List>
        </Container>
      </FormProvider>
    </>
  )
}
