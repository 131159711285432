import {
  GridApiPro,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react'
import { useStateParams } from '../../../hooks/state-params'

type UseSortingProps = {
  apiRef: MutableRefObject<GridApiPro>
  sorting?: GridSortModel
}

type QueryState = {
  sorting: string | undefined
}

const getSortingFromString = (
  sorting: string | undefined | null,
): GridSortModel => {
  if (!sorting) return []

  const sortParts = sorting.split('-')
  if (sortParts?.length !== 2) return []

  const sortField = sortParts[0]!.trim()
  const sortOrder = sortParts[1]!.toLowerCase().trim()

  if (!['asc', 'desc'].includes(sortOrder)) return []

  return [
    {
      field: sortField,
      sort: sortOrder as GridSortDirection,
    },
  ]
}

const getSortingFromGridSortModel = (sorting: GridSortModel): string => {
  if (!sorting || !sorting[0]) return ''

  return `${sorting[0].field}-${(sorting[0].sort || '')?.toLowerCase()}`
}

export const useSorting = (props: UseSortingProps) => {
  const { apiRef } = props
  const [queryState, setQueryState] = useStateParams<QueryState>({
    sorting: props.sorting
      ? getSortingFromGridSortModel(props.sorting)
      : undefined,
  })

  const sorting = useMemo(() => {
    return getSortingFromString(queryState.sorting)
  }, [queryState.sorting])

  const onSortChange = useCallback(
    (newSorting: GridSortModel) => {
      setQueryState({
        sorting: getSortingFromGridSortModel(newSorting),
      })
    },
    [setQueryState],
  )

  useEffect(() => {
    if (sorting.length) {
      apiRef.current.setSortModel(sorting)
    }
  }, [apiRef, sorting])

  return { sorting, onSortChange }
}
