import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { DateFilter, OptionType } from '../../../components/date-filter'
import { FilterViewPanelApplyFooter } from '../../../components/filter-view-panel/filter-view-panel-apply-footer'
import { useContext } from 'react'
import { FilterContext } from '../../../components/data-grid/context'
import { StaffUsersFilter } from '../order-search-list/staff-users-filter'
import { TransactionTypeFilter } from '../order-search-list/transaction-type-filter'
import { CardNumberFilter } from '../order-search-list/card-number-filter'
import { CustomerFilter } from '../order-search-list/customer-filter'
import { AmountFilter } from '../order-search-list/amount-filter'
import { PaymentMethodFilter } from '../order-search-list/payment-method-filter'
import { OrderTypeFilter } from '../order-search-list/order-type-filter'
import { useStateParams } from '../../../hooks/state-params'
import { SkuFilter } from '../order-search-list/sku-filter'
import { ProductGroupFilter } from '../order-search-list/product-group-filter'
import { MultipleStaffFilter } from '../order-search-list/multiple-staff-filter'
import { ManualDiscountFilter } from '../order-search-list/manual-discount-filter'
import { ManualPriceAdjustmentFilter } from '../order-search-list/manual-price-adjustment-filter'
import { CampaignFilter } from '../order-search-list/campaign-filter'
import { CardIssuerFilter } from '../order-search-list/card-issuer-filter'
import { StoreRegisterFilter } from '../order-search-list/store-register-filter'
import { OrderIdFilter } from '../../orders/orders-list/order-id-filter'

type OrderFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const OrderFilterViewPanel = (props: OrderFilterViewPanelProps) => {
  const { t } = useTranslation(['orders', 'shared'])
  const { applyFilter: applyFilter, resetFilter } = useContext(FilterContext)
  const [queryParams, setQueryParams] = useStateParams()

  const onFilterApply = () => {
    setQueryParams(queryParams, true)
    void applyFilter()
  }

  return (
    <FilterViewPanel
      open={props.isOpen}
      onClose={props.onClose}
      footer={
        <FilterViewPanelApplyFooter
          onApply={onFilterApply}
          onClearFilter={() => void resetFilter()}
        />
      }
    >
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="general">
          <SectionHeader sx={{ p: 0 }}>
            {t('orders:general_filter_label')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <DateFilter
                defaultOptionType={OptionType.Today}
                hiddenOptions={[OptionType.AllTime]}
              />
            </ListItem>

            <StoreRegisterFilter storeIdType="externalid" />

            <ListItem>
              <StaffUsersFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="order">
          <SectionHeader sx={{ p: 0 }}>
            {t('orders:order_filter_label')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <OrderIdFilter />

            <ListItem>
              <OrderTypeFilter />
            </ListItem>

            <TransactionTypeFilter />

            <ListItem>
              <AmountFilter filterKey="orderAmount" />
            </ListItem>
            <MultipleStaffFilter />
            <ManualDiscountFilter />
            <ManualPriceAdjustmentFilter />
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="payment">
          <SectionHeader sx={{ p: 0 }}>
            {t('orders:payments_filter_label')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <PaymentMethodFilter />
            </ListItem>

            <ListItem>
              <CardNumberFilter />
            </ListItem>
            <ListItem>
              <CardIssuerFilter />
            </ListItem>
            <ListItem>
              <AmountFilter filterKey="paymentAmount" />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="product">
          <SectionHeader sx={{ p: 0 }}>{t('orders:product')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <SkuFilter />
            </ListItem>
            <ListItem>
              <ProductGroupFilter />
            </ListItem>
            <ListItem>
              <CampaignFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="customer">
          <SectionHeader sx={{ p: 0 }}>{t('orders:customer')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <CustomerFilter />
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
