import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
  Tooltip,
} from '@mui/material'
import {
  ChevronSmallLeftIcon,
  CloseIcon,
  FolderIcon,
  Link,
  SectionHeader,
} from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { JSX } from 'react'

type ViewPanelHeaderProps = {
  title: string
  section: string
  sectionIcon?: JSX.Element
  onClose: () => void

  onBack?: () => void
  showBackButton?: boolean

  tags?: React.ReactNode
  actions?: React.ReactNode
  isLoading?: boolean

  hideActionsSkeleton?: boolean
  sx?: SxProps<Theme>
}

export const ViewPanelHeader = (props: ViewPanelHeaderProps) => {
  const { t } = useTranslation('shared')

  return (
    <Grid
      container
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        background: (theme) => theme.palette.background.paper,
        padding: (theme) => theme.spacing(2),
        minHeight: (theme) => theme.spacing(16.5),
        borderBottom: (theme) => `1px solid ${theme.palette.gray10}`,

        ...props.sx,
      }}
      rowGap={2.5}
    >
      <Grid container item sx={{ mt: -1 }}>
        <Grid
          item
          flexGrow={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {props.showBackButton ? (
            <Link
              onClick={props.onBack}
              sx={{
                display: 'flex',
                marginTop: (t) => t.spacing(1),
                cursor: 'pointer',
              }}
              variant="dark"
            >
              <ChevronSmallLeftIcon fontSize="medium" />
              <Typography variant="body02">{t('action.back')}</Typography>
            </Link>
          ) : (
            <SectionHeader sx={{ padding: 0, backgroundColor: 'transparent' }}>
              {props.isLoading ? (
                <Skeleton width="30%" />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    gap: (theme) => theme.spacing(0.5),
                  }}
                >
                  {props.sectionIcon || <FolderIcon />}{' '}
                  {props.section.toUpperCase()}
                </Box>
              )}
            </SectionHeader>
          )}
        </Grid>
        <Grid item>
          <IconButton onClick={props.onClose} sx={{ mr: -1 }}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container item flexWrap="nowrap" gap={1}>
        <Grid
          item
          flexGrow={1}
          alignItems="flex-end"
          display="flex"
          flexWrap="wrap"
          sx={{ overflow: 'hidden' }}
        >
          <Tooltip title={props.title} placement="bottom-start">
            <Typography
              variant="heading"
              flexGrow={1}
              width="100%"
              data-testid="view-panel-title"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {props.isLoading ? <Skeleton width="60%" /> : props.title}
            </Typography>
          </Tooltip>

          {props.tags && (
            <Stack
              direction="row"
              gap={0.5}
              flexWrap="wrap"
              data-testid="view-panel-tags"
            >
              {props.isLoading ? (
                <Skeleton width="55px" height="16px" />
              ) : (
                props.tags
              )}
            </Stack>
          )}
        </Grid>

        {props.actions && (
          <Grid item alignItems="flex-end" display="flex" gap={0.75}>
            {!props.hideActionsSkeleton && props.isLoading ? (
              <Skeleton width="55px" height="35px" />
            ) : (
              props.actions
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
