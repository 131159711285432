import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ParseUsersCsvDocument,
  ImportUsersDocument,
} from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ImportDialog } from '../import-dialog'
import { RootRoute } from '../../..'
import { stripNullValues } from '../../../../utils/strip-null-values'

export type Props = {
  open: boolean
  onClose(): void
}

const CSV_EXAMPLE = `
email;namefirst;namelast
john.doe@example.com;John;Doe
`

export const ImportUsersDialog = (props: Props) => {
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [parseCsvMutation, { data: parsedData, loading: parseLoading }] =
    useMutation(ParseUsersCsvDocument)

  const [
    importMutation,
    { data: importData, loading: importLoading, error: importError },
  ] = useMutation(ImportUsersDocument)

  const isLoading = parseLoading || importLoading

  const onParse = useCallback(
    (csv: string) => {
      return parseCsvMutation({ variables: { csv } })
    },
    [parseCsvMutation],
  )

  const onImport = useCallback(() => {
    const parseItems = parsedData?.parseUsersCsv.items.map((o) => ({
      ...stripNullValues(o),
      role: o.role || null,
    }))

    if (parseItems) {
      return importMutation({ variables: { data: parseItems } })
    }
  }, [importMutation, parsedData?.parseUsersCsv.items])

  const onSuccess = useCallback(() => {
    navigate(generatePath(RootRoute.Users))
  }, [generatePath, navigate])

  return (
    <ImportDialog
      {...props}
      isLoading={isLoading}
      ns="users"
      onImport={onImport}
      onParse={onParse}
      onSuccess={onSuccess}
      importData={importData?.importUsers}
      parsedItems={parsedData?.parseUsersCsv}
      importError={importError}
      example={CSV_EXAMPLE}
    />
  )
}
