import { useTranslation } from 'react-i18next'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers-pro'
import { PropsWithChildren } from 'react'

export const LocalizationProvider = (props: PropsWithChildren) => {
  const { i18n } = useTranslation()

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
    >
      {props.children}
    </MuiLocalizationProvider>
  )
}
