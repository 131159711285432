import { Box } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { MutableRefObject, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import { UndeclaredItem } from '.'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { ProductCounter } from '../../../components/product-counter'

type Row = UndeclaredItem

type Props = {
  items: UndeclaredItem[]
  updateItem: (data: {
    sku: string
    productName: string
    quantity: number
  }) => void
  apiRef: MutableRefObject<GridApiPro>
}

export const UndeclaredItems = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments'])
  const { items } = props

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'product_image',
        headerName: '',
        maxWidth: 46,
        minWidth: 46,
        valueGetter: (_value, row) => row.product?.images?.at(0),
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'product_name',
        headerName: t('shipments:packages.products_title'),
        flex: 3,
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.product_name,
          caption: row.sku,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'quantity',
        headerName: t('shipments:packages.quantity_title'),
        editable: false,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<Row, number | null | undefined>,
        ) => {
          return (
            <ProductCounter
              currentAmount={params.value || 0}
              updateAmount={(newQuantity) => {
                if (newQuantity !== params.value) {
                  props.updateItem({
                    sku: params.row.sku,
                    productName: params.row.product_name,
                    quantity: newQuantity,
                  })

                  params.row.quantity = newQuantity
                }
              }}
            />
          )
        },
      },
    ],
    [props, t],
  )

  return (
    <DataGrid
      apiRef={props.apiRef}
      name="undeclared-items-list"
      columns={dataGridColumns}
      rows={items}
      rowCount={items?.length}
      rowHeight={50}
      ignoreRowHover
      sx={{
        height: '300px',
      }}
      noRowsOverlay={{
        title: t('shipments:packages.no_rows_title'),
        description: t('shipments:packages.no_rows_description'),
      }}
      disableColumnVisibilityControl
      pagination={false}
      hideFooter
      disableAllFilters
    />
  )
}
