import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  ShipmentStateV2,
  UpdateShipmentV2Document,
} from '../../../../generated/graphql'
import { getErrorMessage } from '../../../../utils/error-mapping'
import { ArrayElement } from '../../../../utils/types'
import { SetPackageInfoDialog } from '../../set-package-info-dialog'
import { SetPackageItemsDialog } from '../../set-package-items-dialog'
import { useMutation } from '@apollo/client'
import { PackageItemIcon } from '../../../shipments-v2/shipment-view-panel/packages'
import { ReceivedItemChip } from '../../../shipments-v2/shipment-view-panel/received-item-chip'
import { SetReceivedPackageItemsDialog } from '../../set-received-package-items-dialog'
import { BaseShipmentFormContext } from '../../shared'

export type ShipmentPackage = ArrayElement<BaseShipmentFormContext['packages']>

export const Packages = () => {
  const { id: shipmentId } = useParams()
  const isNewShipment = shipmentId === undefined

  const { t } = useTranslation('shipments_v2')
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false)
  const [isItemsDialogOpen, setItemsDialogOpen] = useState(false)
  const [isReceivedPackageItemsDialogOpen, setReceivedPackageItemsDialogOpen] =
    useState(false)
  const [selectedPackage, setSelectedPackage] = useState<ShipmentPackage>()
  const { enqueueSnackbar } = useSnackbar()

  const { control, watch, getValues } =
    useFormContext<BaseShipmentFormContext>()

  const shipment = watch()

  const shipmentState = useMemo<ShipmentStateV2>(
    () => shipment.shipmentstate || ShipmentStateV2.New,
    [shipment.shipmentstate],
  )

  const [updateShipmentV2, { loading }] = useMutation(UpdateShipmentV2Document)

  const { fields, update, append, remove } = useFieldArray({
    control: control,
    name: 'packages',
    keyName: 'id',
  })

  const onEditInfo = (packageId: string) => () => {
    const selectedPackage = fields.find(({ id }) => id === packageId)

    setSelectedPackage(selectedPackage)
    setInfoDialogOpen(true)
  }

  const onAddPackageNewShipment = () => {
    append({
      shipmentpackageid: fields.length + 1,
    })
  }

  const onAddPackageExistingShipment = async () => {
    try {
      const shipmentId = getValues('shipmentid')

      if (!shipmentId) return

      const nextId = (fields.at(-1)?.shipmentpackageid || 0) + 1
      const newPackage = { shipmentpackageid: nextId }

      await updateShipmentV2({
        variables: {
          shipment: {
            shipmentid: shipmentId,
            packages: [...fields, newPackage],
          },
        },
      })

      enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments_v2:shipment_message.failure_update'),
      )
      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onDeletePackageNewShipment = useCallback(
    (shipmentPackage: ShipmentPackage) => {
      const packageIndex = fields.findIndex(
        ({ id }) => id === shipmentPackage.id,
      )
      remove(packageIndex)
      setInfoDialogOpen(false)
    },
    [fields, remove],
  )

  const onDeletePackageExistingShipment = useCallback(
    async (shipmentPackage: ShipmentPackage) => {
      const shipmentId = getValues('shipmentid')

      if (!shipmentId) return

      const updatedPackages = fields.filter(
        ({ id }) => id !== shipmentPackage.id,
      )

      await updateShipmentV2({
        variables: {
          shipment: {
            shipmentid: shipmentId,
            packages: updatedPackages,
          },
        },
      })

      enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    },
    [enqueueSnackbar, fields, getValues, t, updateShipmentV2],
  )

  const onServerError = useCallback(
    (errorMessage: string) => {
      if (!isNewShipment) {
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    },
    [enqueueSnackbar, isNewShipment],
  )

  const onPackageInfoUpdateNewShipment = useCallback(
    (data: ShipmentPackage) => {
      const index = fields.findIndex(({ id }) => id === data.id)
      update(index, data)
    },
    [fields, update],
  )

  const onPackageInfoUpdateExistingShipment = useCallback(
    async (shipmentPackage: ShipmentPackage) => {
      const shipmentId = getValues('shipmentid')

      if (!shipmentId) return

      const updatedPackages = fields.map((p) =>
        p.id === shipmentPackage.id ? shipmentPackage : p,
      )

      await updateShipmentV2({
        variables: {
          shipment: {
            shipmentid: shipmentId,
            packages: updatedPackages,
          },
        },
      })

      enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    },
    [enqueueSnackbar, fields, getValues, t, updateShipmentV2],
  )

  const onEditItems = useCallback(
    (packageId: string) => () => {
      const selectedPackage = fields.find(({ id }) => id === packageId)

      setSelectedPackage(selectedPackage)
      setItemsDialogOpen(true)
    },
    [fields],
  )

  const onPackageItemsUpdateNewShipment = useCallback(
    (packageItems: ShipmentPackage['items'], packageId: string) => {
      for (const [index, field] of fields.entries()) {
        if (field.id === packageId) {
          const totalQuantity =
            packageItems?.reduce((acc, item) => {
              acc += item.quantity || 0
              return acc
            }, 0) || 0

          update(index, {
            ...field,
            items: packageItems,
            totalQuantity: totalQuantity,
          })

          break
        }
      }
      setItemsDialogOpen(false)
    },
    [fields, update],
  )

  const onPackageItemsUpdateExistingShipment = useCallback(
    async (packageItems: ShipmentPackage['items'], packageId: string) => {
      const shipmentId = getValues('shipmentid')

      if (!packageId || !shipmentId) return

      const updatedPackages = fields.map((field) =>
        field.id === packageId ? { ...field, items: packageItems } : field,
      )

      await updateShipmentV2({
        variables: {
          shipment: {
            shipmentid: shipmentId,
            packages: updatedPackages,
          },
        },
      })

      enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    },
    [enqueueSnackbar, fields, getValues, t, updateShipmentV2],
  )

  return (
    <>
      <SetPackageInfoDialog
        open={isInfoDialogOpen}
        package={selectedPackage}
        shipmentState={shipmentState}
        onClose={() => setInfoDialogOpen(false)}
        onDelete={
          isNewShipment
            ? onDeletePackageNewShipment
            : onDeletePackageExistingShipment
        }
        onDeleteSuccess={() => setInfoDialogOpen(false)}
        onDeleteError={onServerError}
        onUpdate={
          isNewShipment
            ? onPackageInfoUpdateNewShipment
            : onPackageInfoUpdateExistingShipment
        }
        onUpdateSuccess={() => setInfoDialogOpen(false)}
        onUpdateError={onServerError}
        isNewShipment={isNewShipment}
      />

      <SetPackageItemsDialog
        readOnly={shipmentState !== ShipmentStateV2.New}
        open={isItemsDialogOpen}
        package={selectedPackage}
        packageId={String(selectedPackage?.id)}
        packageItems={selectedPackage?.items}
        shipmentState={shipmentState}
        onClose={() => setItemsDialogOpen(false)}
        onSubmit={
          isNewShipment
            ? onPackageItemsUpdateNewShipment
            : onPackageItemsUpdateExistingShipment
        }
        onSuccess={() => setItemsDialogOpen(false)}
        onError={onServerError}
        isNewShipment={isNewShipment}
      />

      <SetReceivedPackageItemsDialog
        open={isReceivedPackageItemsDialogOpen}
        shipment={shipment}
        packageId={selectedPackage?.shipmentpackageid}
        onClose={() => setReceivedPackageItemsDialogOpen(false)}
        onSuccess={() => {
          enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
          setReceivedPackageItemsDialogOpen(false)
        }}
        onError={(errorMessage) => {
          enqueueSnackbar(errorMessage, { variant: 'error' })
          setReceivedPackageItemsDialogOpen(false)
        }}
      />

      <FormFieldset
        label={t('shipments_v2:shipment_form.packages_fieldset')}
        sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
      >
        {shipmentState !== ShipmentStateV2.New && fields.length === 0 && (
          <Box sx={{ p: 2 }}>
            <Typography variant="body02">
              {t('shipments_v2:packages.no_packages_added')}
            </Typography>
          </Box>
        )}

        <List>
          {fields.map((shipmentPackage, index) => (
            <Fragment key={shipmentPackage.id}>
              <ListItem
                secondaryAction={
                  <Stack direction="row" spacing={1}>
                    {[ShipmentStateV2.New, ShipmentStateV2.Received].includes(
                      shipmentState,
                    ) && (
                      <Button
                        size="small"
                        color="secondary"
                        onClick={onEditInfo(shipmentPackage.id)}
                      >
                        {t('shipments_v2:packages.edit_info')}
                      </Button>
                    )}

                    {[ShipmentStateV2.New, ShipmentStateV2.InTransit].includes(
                      shipmentState,
                    ) && (
                      <Button
                        size="small"
                        color={
                          [ShipmentStateV2.New].includes(shipmentState)
                            ? shipmentPackage.items?.length
                              ? 'secondary'
                              : 'primary'
                            : 'secondary'
                        }
                        onClick={onEditItems(shipmentPackage.id)}
                      >
                        {[ShipmentStateV2.New].includes(shipmentState)
                          ? shipmentPackage.items?.length
                            ? t('shipments_v2:packages.edit_items')
                            : t('shipments_v2:packages.add_items')
                          : t('shipments_v2:packages.view_items')}
                      </Button>
                    )}

                    {[ShipmentStateV2.InTransit].includes(shipmentState) &&
                      shipmentPackage.totalReceivedQuantity !==
                        shipmentPackage.totalQuantity && (
                        <>
                          <Button
                            size="small"
                            color="secondary"
                            onClick={() => {
                              setSelectedPackage(shipmentPackage)
                              setReceivedPackageItemsDialogOpen(true)
                            }}
                          >
                            {t('shipments_v2:packages.receive_items')}
                          </Button>
                        </>
                      )}
                  </Stack>
                }
              >
                <ListItemIcon>
                  <PackageItemIcon
                    shipmentPackage={shipmentPackage}
                    shipmentState={shipmentState}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('shipments_v2:packages.package_title', {
                    property: shipmentPackage.shipmentpackageid,
                  })}
                  secondaryTypographyProps={{
                    sx: { whiteSpace: 'pre-line' },
                  }}
                  secondary={
                    shipmentPackage.totalReceivedQuantity ? (
                      <>
                        {[
                          shipmentPackage.totalReceivedQuantity > 0
                            ? t('shipments_v2:packages.items_received_total', {
                                received:
                                  shipmentPackage.totalReceivedQuantity || 0,
                                total: shipmentPackage.totalQuantity || 0,
                              })
                            : t('shipments_v2:packages.package_amount', {
                                count: shipmentPackage.totalQuantity || 0,
                              }),
                        ].join('\n')}
                        {shipmentPackage.totalReceivedQuantity > 0 && (
                          <>
                            {'\n'}
                            <ReceivedItemChip
                              label={t(
                                'shipments_v2:packages.package_received',
                              )}
                              quantity={shipmentPackage.totalQuantity || 0}
                              quantityReceived={
                                shipmentPackage.totalReceivedQuantity
                              }
                            />
                          </>
                        )}
                      </>
                    ) : (
                      t('shipments_v2:packages.package_amount', {
                        count: shipmentPackage.totalQuantity || 0,
                      })
                    )
                  }
                />
              </ListItem>

              {index + 1 < fields?.length && <Divider />}
            </Fragment>
          ))}

          {shipmentState === ShipmentStateV2.New && (
            <ListItem sx={{ p: 0, minHeight: 'auto' }}>
              <LoadingButton
                fullWidth
                color="tertiary"
                onClick={
                  isNewShipment
                    ? onAddPackageNewShipment
                    : onAddPackageExistingShipment
                }
                loading={loading}
              >
                {t('shipments_v2:packages.add_package')}
              </LoadingButton>
            </ListItem>
          )}
        </List>
      </FormFieldset>
    </>
  )
}
