import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  UpdateWarehouseBatchDocument,
  Warehousebatchstate,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { getErrorMessage } from '../../../../utils/error-mapping'

export type CancelStocktakingDialogProps = {
  open: boolean
  warehouseId: number
  warehouseBatchId: number
  dataTestid?: string
  onClose?(): void
  onSuccess?(): void
  onError?(): void
}

export const CancelStocktakingDialog = (
  props: CancelStocktakingDialogProps,
) => {
  const { t } = useTranslation(['stocktaking'])
  const dialogName = 'cancel-stocktaking'
  const [updateWarehouseBatch, { loading }] = useMutation(
    UpdateWarehouseBatchDocument,
  )
  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const { enqueueSnackbar } = useSnackbar()

  const cancelStocktaking = async () => {
    trackButtonClickEvent({ name: `${dialogName}-dialog-cancel` })

    try {
      await updateWarehouseBatch({
        variables: {
          warehouseBatchId: props.warehouseBatchId,
          warehouseId: props.warehouseId,
          data: {
            warehousebatchstate: Warehousebatchstate.NUMBER_30,
          },
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      enqueueSnackbar(t('stocktaking:cancel_dialog.success'))
      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'stocktaking',
        t('stocktaking:cancel_dialog.error'),
      )

      trackFormError({
        name: `${dialogName}-dialog`,

        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
      props.onError?.()
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={cancelStocktaking}
      title={t('stocktaking:cancel_dialog.title')}
      text={t('stocktaking:cancel_dialog.description')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
