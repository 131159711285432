import { List, ListItemText, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { stripTypename } from '@apollo/client/utilities'
import { ListItemAccordion } from '../../../../components/list-item-accordion'
import { ListItemCodeBlock } from '../../../../components/list-item-code-block'
import { AccessLogEvent } from '.'
import { EventResult } from '../event-result'
import { EventType } from '../event-type'

type Props = {
  event: AccessLogEvent
}

export const Details = (props: Props) => {
  const { event } = props
  const { t } = useTranslation(['shared', 'log_events'])
  const { formatDate } = useLocalizedDate()
  const { ip, result, session_type: type, email } = event.data

  return (
    <Paper elevation={0}>
      <List>
        <ListItemSkeleton
          hide={!event.id}
          childrenSkeleton
          divider
          data-testid="event-id"
        >
          <ListItemText primary={t('log_events:id')} secondary={event.id} />
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!email}
          childrenSkeleton
          divider
          data-testid="event-date"
        >
          <ListItemText primary={t('log_events:user')} secondary={email} />
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!event.timestamp}
          childrenSkeleton
          divider
          data-testid="event-date"
          secondaryAction={formatDate(event.timestamp)}
        >
          <ListItemText primary={t('log_events:date')} />
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!type}
          childrenSkeleton
          divider
          data-testid="event-type"
          secondaryAction={<EventType type={type} />}
        >
          <ListItemText primary={t('log_events:type')} />
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!result}
          childrenSkeleton
          divider
          data-testid="event-result"
          secondaryAction={<EventResult result={result} />}
        >
          <ListItemText primary={t('log_events:result')} />
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!ip}
          childrenSkeleton
          divider
          data-testid="event-ip"
          secondaryAction={ip}
        >
          <ListItemText primary={t('log_events:ip')} />
        </ListItemSkeleton>

        <ListItemAccordion
          aria-controls="event-data"
          summary={
            <ListItemSkeleton>
              <ListItemText>{t('log_events:data')}</ListItemText>
            </ListItemSkeleton>
          }
        >
          <List>
            <ListItemCodeBlock
              code={JSON.stringify(stripTypename(event || {}), null, 2)}
            />
          </List>
        </ListItemAccordion>
      </List>
    </Paper>
  )
}
