import { Box, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { useMoney } from '../../../../hooks/money'
import { formatAdditionalData } from '../../../../utils/format/additional-data'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalCash' }
  >
}

export const CashDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props

  const { formatCurrency } = useMoney()

  const additionalData = formatAdditionalData(journal?.additionaldata)

  return (
    <>
      <ListItemSkeleton
        hide={!journal?.currencycode}
        secondaryAction={<ListItemText>{journal?.currencycode}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-currencycode"
      >
        <ListItemText>{t('journals:currency_code')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.moneycash}
        secondaryAction={
          <ListItemText>
            {journal?.moneycash
              ? formatCurrency(journal?.moneycash, {
                  currency: journal?.currencycode,
                })
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-moneycash"
      >
        <ListItemText>{t('journals:money_cash')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={additionalData.length === 0}
        childrenSkeleton
        divider
        data-testid="journal-additionaldata"
      >
        <ListItemText
          secondary={additionalData?.map(([key, value]) => (
            <Box key={key} component="span" sx={{ display: 'block' }}>
              {key}: {value}
            </Box>
          ))}
        >
          {t('journals:additional_data')}
        </ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.comment}
        childrenSkeleton
        divider
        data-testid="journal-comment"
      >
        <ListItemText secondary={journal?.comment}>
          {t('journals:comment')}
        </ListItemText>
      </ListItemSkeleton>
    </>
  )
}
