import { Alert, SxProps, Typography } from '@mui/material'

type Props = {
  label: string
  dataTestId?: string
  sx?: SxProps
}

export const FieldHighlight = ({ label, dataTestId, sx }: Props) => {
  return (
    <Alert
      data-testid={dataTestId}
      severity="info"
      sx={{
        backgroundColor: (theme) => theme.palette.yellow,
        '.MuiAlert-message': { p: 0, lineHeight: 1 },
        '.MuiAlert-icon': { mr: 0.5, p: 0 },
        '.MuiAlert-icon svg': { fontSize: '1rem' },
        alignItems: 'center',
        ...sx,
      }}
    >
      <Typography variant="caption">{label}</Typography>
    </Alert>
  )
}
