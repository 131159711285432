import { Box, SxProps, Theme } from '@mui/material'
import { SitooLogo } from '@sitoo/mui-components'
import { useState } from 'react'
import { useMe } from '../../hooks/me'

type Props = {
  sx?: SxProps<Theme>
}

export const Logo = (props: Props) => {
  const { me, loading } = useMe()
  const [hasLogo, setHasLogo] = useState(true)

  if (loading) {
    return null
  }

  if (!hasLogo) {
    return <Box component={SitooLogo} sx={props.sx} />
  }

  return (
    <Box
      component="img"
      src={`${me?.resourceUrl || ''}/brand-logo.png`}
      onError={() => setHasLogo(false)}
      sx={props.sx}
    />
  )
}
