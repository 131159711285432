import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  DeleteInfluencerCodesDocument,
  GetInfluencerCodesDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'

type Props = {
  open: boolean
  ids: string[]
  dataTestid?: string
  onClose?(): void
  onSuccess?(result: boolean, codes: string[]): void
}

export const InfluencerCodesDeleteDialog = (props: Props) => {
  const { t } = useTranslation('influencer_codes')
  const dialogName = 'delete-influencer-code'
  const [deleteMutation, { loading }] = useMutation(
    DeleteInfluencerCodesDocument,
    { refetchQueries: [GetInfluencerCodesDocument] },
  )
  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const onDelete = async () => {
    trackButtonClickEvent({ name: `${dialogName}-dialog-delete` })

    const { data } = await deleteMutation({
      variables: { codes: props.ids },
    })
    const result = data?.deleteInfluencerCodes

    trackFormSuccess({
      name: `${dialogName}-dialog`,
    })

    props.onSuccess?.(Boolean(result), props.ids)
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={onDelete}
      title={t('delete_dialog.title', { count: props.ids.length })}
      text={t('delete_dialog.description')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
