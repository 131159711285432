import { Menu, MenuItem, MenuProps } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrintDatePickerDialog } from './print-date-picker-dialog'
import { ActivationCodeDialog } from './activation-code-dialog'
import { ActivationCodeConfirmationDialog } from './activation-code-confirmation-dialog'
import { useAuthorization } from '../../../hooks/authorization'

type Props = MenuProps & {
  registerId?: string
  isNewCashRegister: boolean
}

export const ContextMenu = (props: Props) => {
  const { t } = useTranslation(['cash_registers', 'shared'])
  const [isPrintDialogOpen, setPrintDialogOpen] = useState(false)
  const [isCodeDialogOpen, setCodeDialogOpen] = useState(false)
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const { registerId, isNewCashRegister, ...menuProps } = props
  const {
    modules: { activateCashRegisters },
  } = useAuthorization()

  const canPrint = !isNewCashRegister

  if (!registerId) return null

  return (
    <>
      <PrintDatePickerDialog
        open={isPrintDialogOpen}
        onClose={() => setPrintDialogOpen(false)}
        registerId={registerId}
      />

      <ActivationCodeConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onSuccess={() => {
          setConfirmationDialogOpen(false)
          setCodeDialogOpen(true)
        }}
      />

      <ActivationCodeDialog
        open={isCodeDialogOpen}
        onClose={() => setCodeDialogOpen(false)}
        registerId={registerId}
      />

      <Menu {...menuProps}>
        <MenuItem disabled={!canPrint} onClick={() => setPrintDialogOpen(true)}>
          {t('cash_registers:print_journal')}
        </MenuItem>

        {activateCashRegisters && (
          <MenuItem onClick={() => setConfirmationDialogOpen(true)}>
            {t('cash_registers:request_activation_code')}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
