import { useState } from 'react'
import { ContextMenu } from './in-store-replenishment-list/context-menu'
import { MoreButton } from '../../../components/more-button'

type Props = {
  warehouseToId: number | undefined
  warehouseFromId: number | undefined
}

export const ActionButton = (props: Props) => {
  const { warehouseToId, warehouseFromId } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <MoreButton onClick={handleClick} />

      <ContextMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        selectedIds={undefined}
        warehouseToId={warehouseToId}
        warehouseFromId={warehouseFromId}
      />
    </>
  )
}
