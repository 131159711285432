import { BaseMetadata, CustomTrack, ExtendableMetadata } from './types'

type DragMetadata = ExtendableMetadata & {
  // dragItemName: string
}

export const trackDragStart =
  (track: CustomTrack, baseMetadata: BaseMetadata) =>
  (metadata: DragMetadata) => {
    void track(`DragStart`, {
      ...baseMetadata,
      ...metadata,

      category: 'Drag',
      label: metadata.name,
    })
  }

export const trackDragEnd =
  (track: CustomTrack, baseMetadata: BaseMetadata) =>
  (metadata: DragMetadata) => {
    void track(`DragEnd`, {
      ...baseMetadata,
      ...metadata,

      category: 'Drag',
      label: metadata.name,
    })
  }
