import { MenuItem, Select, TextField } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

enum NumberType {
  BeginsWith,
  EndsWith,
}

type QueryParamsState = {
  cardNumber?: [type: NumberType, value?: string]
}

export const CardNumberFilter = () => {
  const { t } = useTranslation('filter')
  const filterKey = 'cardNumber'
  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)

  const { trackInputChange, trackInput } = useTracking()

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const cardNumber = queryParams.cardNumber?.[1] || ''
  const numberType = queryParams.cardNumber?.[0] || NumberType.BeginsWith

  const buildCardNumber = (cardNumber: string, numberType: NumberType) => {
    return numberType === NumberType.BeginsWith
      ? `${cardNumber}*`
      : `*${cardNumber}`
  }

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  const updateQueryParams = (cardNumber: string, numberType: NumberType) => {
    setQueryParams(
      {
        [filterKey]: [numberType, cardNumber],
      },
      hasApply !== true,
    )
  }

  useEffect(() => {
    if (cardNumber) {
      const value = buildCardNumber(cardNumber, numberType)

      setFilter(filterKey, {
        value,
        label: t('filter:card_number.label'),
        labelValues: [cardNumber],
      })
    } else {
      removeFilter(filterKey)
    }
  }, [cardNumber, numberType, removeFilter, setFilter, t])

  return (
    <Grid
      data-testid="card-number-field"
      container
      spacing={1}
      alignItems="end"
      width="100%"
    >
      <Grid size={{ xs: 4 }}>
        <TextField
          placeholder="####"
          value={cardNumber}
          {...trackInput({ name: 'card-number-input' })}
          onChange={(event) => {
            updateQueryParams(event.target.value, numberType)
          }}
          slotProps={{
            htmlInput: {
              ['data-testid']: 'card-number-filter-input',
            },
          }}
          label={t('filter:card_number.label')}
        />
      </Grid>

      <Grid size={{ xs: 8 }}>
        <Select
          value={numberType}
          onChange={(event) => {
            updateQueryParams(cardNumber, Number(event.target.value))
            trackInputChange({
              name: 'card-number-type',
              value: NumberType[Number(event.target.value)],
            })
          }}
          data-testid="card-number-type-filter"
        >
          <MenuItem
            data-testid="begins-with-option"
            value={NumberType.BeginsWith}
          >
            {t('filter:card_number.begins_with')}
          </MenuItem>
          <MenuItem data-testid="ends-with-option" value={NumberType.EndsWith}>
            {t('filter:card_number.ends_with')}
          </MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}
