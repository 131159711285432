import {
  Autocomplete,
  Box,
  Chip,
  FormHelperText,
  Icon,
  Stack,
  TextField,
} from '@mui/material'
import {
  ArrowRightIcon,
  FormFieldset,
  SectionHeader,
} from '@sitoo/mui-components'
import Grid from '@mui/material/Grid2'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InfluencerCodeContext } from '..'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import { useQuery } from '@apollo/client'
import { GetCampaignsDocument } from '../../../generated/graphql'
import { useParams } from 'react-router-dom'

export const Rules = () => {
  const { t } = useTranslation()
  const { register, formState, watch, control } =
    useFormContext<InfluencerCodeContext>()
  const { id } = useParams()
  const isNew = id === undefined

  const { data } = useQuery(GetCampaignsDocument, {
    variables: { num: MAX_NUM_REQUEST, start: 0 },
    fetchPolicy: 'cache-and-network',
  })

  const campaignPasswords = Array.from(
    new Set(
      data?.campaigns.items
        ?.map((campaign) => campaign.voucherpassword)
        .filter(Boolean) || [],
    ),
  )

  const selectedCampaigns =
    data?.campaigns.items?.filter(
      (campaign) =>
        campaign.voucherpassword !== '' &&
        campaign.voucherpassword === watch('voucherpassword'),
    ) || []

  return (
    <>
      <FormFieldset label={t('influencer_codes:rules_fieldset')}>
        <Grid container columns={15}>
          <Grid size={{ xs: 7 }}>
            <TextField
              error={!!formState?.errors?.code}
              label={t('influencer_codes:influencer_code_label')}
              disabled={!isNew}
              {...register('code', { required: true })}
            />
          </Grid>

          <Grid size={{ xs: 1 }} display="flex" justifyContent="center">
            <ArrowRightIcon fontSize="medium" sx={{ mt: '36px' }} />
          </Grid>

          <Grid size={{ xs: 7 }}>
            <Controller
              control={control}
              name="voucherpassword"
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  autoHighlight
                  freeSolo
                  options={campaignPasswords}
                  value={field.value || ''}
                  data-testid="pricelisttags-field"
                  onChange={(_, value) => field.onChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(
                        'influencer_codes:influencer_code_campaign_label',
                      )}
                      error={!!formState.errors.voucherpassword}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>

        <FormHelperText sx={{ mt: 1 }}>
          {t('influencer_codes:influencer_description')}
        </FormHelperText>

        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            p: 2,
            mt: 2,
            mx: -2,
            mb: -2,
          }}
        >
          <SectionHeader sx={{ p: 0, pb: 1 }}>
            {t('influencer_codes:influencer_campaign_activated')}
          </SectionHeader>

          {selectedCampaigns.length === 0 ? (
            <FormHelperText sx={{ color: (theme) => theme.palette.red.main }}>
              {t('influencer_codes:influencer_campaign_error')}
            </FormHelperText>
          ) : (
            <Stack direction="row" spacing={1}>
              {selectedCampaigns.map((campaign) => (
                <Chip
                  key={campaign.id}
                  label={campaign.vouchername}
                  size="small"
                  color="black"
                />
              ))}
            </Stack>
          )}
        </Box>
      </FormFieldset>

      <FormFieldset>
        <TextField
          error={!!formState?.errors?.maxuses}
          fullWidth
          helperText={
            formState?.errors?.maxuses?.message ||
            t('influencer_codes:influencer_max_uses_description')
          }
          label={t('influencer_codes:influencer_max_uses_label')}
          {...register('maxuses', { valueAsNumber: true })}
        />
      </FormFieldset>
    </>
  )
}
