import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { GetJournalEntriesQuery } from '../../../generated/graphql'
import { JournalItem } from '.'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { JournalDivider } from './journal-divider'
import { useMoney } from '../../../hooks/money'
import { ArrayElement } from '../../../utils/types'
import { PrintContainer } from './print-container'

type Props = {
  journal: JournalItem
  data: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalCartItem' }
  >
}

export const JournalCartItem = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal, data } = props
  const { formatDate } = useLocalizedDate()
  const getDate = (date: string) => formatDate(new Date(date))
  const { formatCurrency } = useMoney()

  return (
    <PrintContainer>
      <Box>{journal.datecreated ? getDate(journal.datecreated) : ''}</Box>

      <Box>
        <strong>
          {t('journals:journal_entry_id', {
            type: t(`journals:journal_entry_type_enum.${journal.type}`),
            id: journal.id,
          })}
        </strong>
      </Box>

      <Box>
        {data.staff} ({data.staffuserid})
      </Box>

      <Box>
        {t('journals:cart_id')}: {data.cart_id}
      </Box>

      {journal?.ispractice && (
        <Box>
          {t('journals:is_practice')}: {t('shared:label.yes')}
        </Box>
      )}

      <Box>
        {t('journals:item_index')}: {data.item_index}
      </Box>

      <Box>
        {t('journals:sku')}: {data.sku}
      </Box>

      <Box>
        {t('journals:money_discount')}:{' '}
        {data.JournalCartItemMoneyDiscount
          ? formatCurrency(data.JournalCartItemMoneyDiscount)
          : '-'}
      </Box>

      <Box>
        {t('journals:money_total')}:{' '}
        {data.JournalCartItemMoneyTotal
          ? formatCurrency(data.JournalCartItemMoneyTotal)
          : '-'}
      </Box>

      <Box>
        {t('journals:cart_money_discount')}:{' '}
        {data.cart_moneydiscount
          ? formatCurrency(data.cart_moneydiscount)
          : '-'}
      </Box>

      <Box>
        {t('journals:cart_money_total')}:{' '}
        {data.cart_moneytotal ? formatCurrency(data.cart_moneytotal) : '-'}
      </Box>

      <Box>
        {t('journals:quantity')}: {data.quantity}
      </Box>

      {data.decimalunitquantity && (
        <Box>
          {t('journals:decimal_unit_quantity')}: {data.decimalunitquantity}
        </Box>
      )}

      <Box>
        {t('journals:cart_quantity_total')}: {data.cart_quantitytotal}
      </Box>

      {data.note && (
        <Box>
          {t('journals:note')}: {data.note}
        </Box>
      )}

      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
