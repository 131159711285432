import { Chip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { UserRole } from '../../../generated/graphql'

export const RoleRenderer = (
  params: GridRenderCellParams<RoleProps, { role?: UserRole | null }>,
): React.ReactElement => <Role role={params.row.role} />

export type RoleProps = {
  role?: UserRole | null
}

const Role = (props: RoleProps) => {
  const { t } = useTranslation(['shared'])
  const { role } = props

  return (
    <Chip
      size="small"
      color={role ? 'black' : undefined}
      label={
        role
          ? t(`shared:roles.${role.toLocaleLowerCase()}`)
          : t('shared:label.none')
      }
    />
  )
}
