import {
  GetCashRegisterQuery,
  GetJournalEntriesQuery,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { JournalHeader } from './journal-header'
import { JournalStaff } from './journal-staff'
import { JournalCtuError } from './journal-ctu-error'
import { JournalPrintout } from './journal-printout'
import { JournalCash } from './journal-cash-bank'
import { JournalCartItem } from './journal-cart-item'
import { JournalCartPayment } from './journal-cart-payment'
import { JournalCart } from './journal-cart'
import { JournalReport } from './journal-report'
import { JournalReceipt } from './journal-receipt'
import { JournalReportItem } from '../types'
import { I18nextProvider } from '../../../providers/i18n'
import { useSessionStorage } from 'react-use'
import { useParams } from 'react-router-dom'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { RootRoute } from '../..'
import { Navigate } from 'react-router-dom'
import { useState } from 'react'
import { useWindowPrint } from '../../../hooks/window-print'

type PrintData = {
  journalsData: GetJournalEntriesQuery['journalEntries']
  registerData: GetCashRegisterQuery['cashRegister']
  reportDate: Date
}

export type JournalItem = ArrayElement<
  GetJournalEntriesQuery['journalEntries']['items']
>

export const JournalPrinter = () => {
  const { id } = useParams<{ id: string }>()
  const [printData] = useSessionStorage<PrintData | undefined>(String(id))
  const generatePath = useAbsolutePath()
  const [is18nReady, seti18nReady] = useState(false)

  useWindowPrint({ isReady: is18nReady })

  if (!printData) {
    return <Navigate to={generatePath(RootRoute.CashRegisters)} />
  }

  const { journalsData, registerData, reportDate } = printData
  const language = registerData?.receiptlanguagetag || undefined

  return (
    <I18nextProvider language={language} onReady={() => seti18nReady(true)}>
      <JournalHeader registerData={registerData} reportDate={reportDate} />

      {journalsData.items?.map((journal) => {
        if (journal?.data.__typename === 'JournalPrintout') {
          return (
            <JournalPrintout
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )
        }

        if (journal?.data.__typename === 'JournalCash') {
          return (
            <JournalCash
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )
        }

        if (journal?.data.__typename === 'JournalStaff')
          return (
            <JournalStaff
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )

        if (journal?.data.__typename === 'JournalCtuError') {
          return (
            <JournalCtuError
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )
        }

        if (journal?.data.__typename === 'JournalCart') {
          return (
            <JournalCart
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )
        }

        if (journal?.data.__typename === 'JournalCartItem') {
          return (
            <JournalCartItem
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )
        }

        if (journal?.data.__typename === 'JournalCartPayment') {
          return (
            <JournalCartPayment
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )
        }

        if (journal?.data.__typename === 'JournalReceipt') {
          return (
            <JournalReceipt
              key={journal.id}
              data={journal.data}
              journal={journal}
            />
          )
        }

        if (journal?.data.__typename === 'JournalReport') {
          return (
            <JournalReport
              key={journal.id}
              journal={journal as JournalReportItem}
              registerNumber={registerData.registernumber}
            />
          )
        }
      })}
    </I18nextProvider>
  )
}
