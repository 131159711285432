import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseShipmentFormContext } from '../../shared'

export const GeneralInfoField = () => {
  const { register, formState } = useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments', 'shared'])

  return (
    <FormFieldset label={t('shipments:shipment_form.general_fieldset')}>
      <TextField
        data-testid="shipment-reference-number"
        error={!!formState?.errors.info?.reference_number}
        fullWidth
        helperText={formState?.errors.info?.reference_number?.message}
        label={t('shipments:shipment_form.reference_number')}
        {...register('info.reference_number')}
        sx={{ mb: 2 }}
      />
      <TextField
        data-testid="shipment-barcode"
        error={!!formState?.errors.info?.barcode}
        fullWidth
        helperText={formState?.errors.info?.barcode?.message}
        label={t('shipments:shipment_form.barcode')}
        {...register('info.barcode')}
        sx={{ mb: 2 }}
      />
      <TextField
        data-testid="shipment-note"
        error={!!formState?.errors.info?.note}
        fullWidth
        helperText={formState?.errors.info?.note?.message}
        label={t('shipments:shipment_form.note')}
        {...register('info.note')}
        multiline
        rows={4}
      />
    </FormFieldset>
  )
}
