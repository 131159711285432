import { Typography, Box, SxProps } from '@mui/material'
import { ErrorIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'

type Props = {
  sx?: SxProps
}
export const SalesTaxInvalidRuleBanner = ({ sx }: Props) => {
  const { t } = useTranslation('settings')
  return (
    <Box
      data-testid="rule-error"
      sx={{
        backgroundColor: (theme) => theme.palette['redBase'],
        display: 'flex',
        px: 1,
        py: 0.5,
        ...sx,
      }}
    >
      <ErrorIcon sx={{ color: 'white', mx: 1 }} />
      <Typography variant="caption" color="white">
        {t('sales_tax.invalid_rule_missing_product_group')}
      </Typography>
    </Box>
  )
}
