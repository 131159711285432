import { ListItem, ListItemText, Switch } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  manualOrderItemPrice?: boolean
}

export const ManualPriceAdjustmentFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClick } = useTracking()

  const filterKey = 'manualOrderItemPrice'

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const manualPrice =
    (typeof queryParams[filterKey] === 'boolean'
      ? queryParams[filterKey]
      : queryParams[filterKey] === 'true') || false

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (manualPrice) {
      setFilter(filterKey, {
        label: t('filter:manual_price_adjustment.label'),
        labelValues: [t('shared:label.yes')],
        value: manualPrice,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, t, manualPrice])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <ListItem
      data-testid="manual-price-adjustment-field"
      sx={{ minHeight: (theme) => theme.spacing(4.5) }}
      secondaryAction={
        <Switch
          checked={manualPrice}
          size="small"
          onChange={(event) => {
            trackButtonClick({
              name: 'order-search-filter-manual-price-adjustment',
              value: event.target.checked,
            })
            setQueryParams(
              { [filterKey]: event.target.checked || undefined },
              hasApply !== true,
            )
          }}
        />
      }
    >
      <ListItemText>{t('filter:manual_price_adjustment.label')}</ListItemText>
    </ListItem>
  )
}
