import { useQuery } from '@apollo/client'
import { Box, MenuItem, Select, Checkbox, Chip } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  GetProductQuery,
  GetCategoriesDocument,
} from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { MAX_NUM_REQUEST } from '../../../../utils/constants'
import { formatCategoryName } from '../../../../utils/format/category'

export const CategoriesField = () => {
  const { control } = useFormContext<GetProductQuery>()
  const { data, loading } = useQuery(GetCategoriesDocument, {
    variables: { num: MAX_NUM_REQUEST },
  })
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const categories = data?.categories.items

  return (
    <FormFieldset
      label={t('products:product_form.categories_fieldset')}
      isLoading={loading}
    >
      <Controller
        control={control}
        name="product.categories"
        render={({ field }) => (
          <>
            <Select
              value={field.value || []}
              inputProps={{ name: 'product.categories' }}
              displayEmpty
              multiple
              placeholder={t('products:product_form.categories_placeholder')}
              disabled={!writeProducts}
              onChange={(event) => {
                field.onChange(event.target.value)
              }}
              renderValue={(selected) => {
                if (typeof selected === 'string') return selected

                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {selected?.map((value) => (
                      <Chip
                        key={value}
                        label={formatCategoryName(
                          value,
                          data?.categories.items,
                        )}
                        color="blue"
                      />
                    ))}
                  </Box>
                )
              }}
            >
              {categories?.map(({ id }) => (
                <MenuItem key={id} value={id}>
                  <Checkbox
                    checked={field.value?.includes(id) || false}
                    sx={{ p: (theme) => theme.spacing(0, 1, 0, 0) }}
                  />
                  {formatCategoryName(id, data?.categories.items, true)}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
    </FormFieldset>
  )
}
