import { createRoot } from 'react-dom/client'
import { App } from './app'
import { initI18next } from './i18n'
import { initServiceWorker } from './utils/service-worker'
import { setMuiLicense } from './utils/mui-license'

initI18next()
initServiceWorker()
setMuiLicense()

const root = document.getElementById('root')

if (root) {
  createRoot(root).render(<App />)
}
