import { Box, Divider, IconButton, ListItem, ListItemText } from '@mui/material'
import { Fragment, useMemo, useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { DeleteIcon } from '@sitoo/mui-components'
import { ProductFormContext } from '../..'
import { AllCustomAttributesQuery } from '../../../../generated/graphql'
import { AttributesMenu } from './attributes-menu'
import { CustomAttribute } from '.'
import { useAuthorization } from '../../../../hooks/authorization'

type MultipleVariantsAttributesProps = {
  customAttributes: AllCustomAttributesQuery['allCustomAttributes']
}

export const MultipleVariantsAttributes = (
  props: MultipleVariantsAttributesProps,
) => {
  const { watch, setValue, getValues } = useFormContext<ProductFormContext>()
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const allCustomAttributes = props.customAttributes
  const variantsCustomAttributes = useMemo(
    () =>
      watch('product.childVariants')?.flatMap((variant) =>
        Object.keys((variant.customattributes as CustomAttribute) || {}),
      ),
    [watch],
  )

  const [
    inMemoryVariantsCustomAttributes,
    setInMemoryVariantsCustomAttributes,
  ] = useState<string[] | null>(
    getValues('supportedCustomAttributes')?.map((x) => x.id) || null,
  )

  useEffect(() => {
    const currentCustomAttributes =
      inMemoryVariantsCustomAttributes !== null
        ? inMemoryVariantsCustomAttributes
        : variantsCustomAttributes

    setValue(
      'supportedCustomAttributes',
      allCustomAttributes.filter((c) =>
        currentCustomAttributes?.some((v) => c.id === v),
      ),

      {
        shouldDirty: inMemoryVariantsCustomAttributes !== null,
      },
    )

    const childVariants = getValues('product.childVariants')
    for (const childVariant of childVariants || []) {
      const childCustomAttribute =
        childVariant.customattributes as CustomAttribute
      if (!childCustomAttribute) continue

      const removedKeys = Object.keys(childCustomAttribute || {}).filter(
        (k) => !currentCustomAttributes?.includes(k),
      )

      for (const removedKey of removedKeys) {
        delete childCustomAttribute[removedKey]
      }

      if (childVariant.isMainVariant) {
        setValue('product.customattributes', childCustomAttribute)
      }

      childVariant.customattributes = childCustomAttribute
    }
    setValue('product.childVariants', childVariants)
  }, [
    allCustomAttributes,
    setValue,
    getValues,
    variantsCustomAttributes,
    inMemoryVariantsCustomAttributes,
  ])

  const supportedCustomAttributes = watch('supportedCustomAttributes') || []
  const missingCustomAttributes = allCustomAttributes.filter(
    (c) => !supportedCustomAttributes?.some((current) => current.id === c.id),
  )

  const setCustomAttribute = (attributeId: string) => {
    setInMemoryVariantsCustomAttributes((p) =>
      Array.from(
        new Set([
          ...(p === null ? variantsCustomAttributes || [] : p),
          attributeId,
        ]),
      ),
    )
  }

  const removeCustomAttribute = (attributeId: string) => {
    setInMemoryVariantsCustomAttributes((p) =>
      (p === null ? variantsCustomAttributes || [] : p).filter(
        (x) => x !== attributeId,
      ),
    )
  }

  return (
    <>
      {supportedCustomAttributes?.map((customAttribute, i) => (
        <Fragment key={customAttribute.id}>
          <ListItem>
            <ListItemText data-testid="attribute-row">
              {' '}
              {customAttribute.title}
            </ListItemText>
            {writeProducts && (
              <IconButton
                data-testid="remove-attribute-icon"
                onClick={() => removeCustomAttribute(customAttribute.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </ListItem>
          {i + 1 < supportedCustomAttributes.length && <Divider />}
        </Fragment>
      ))}

      {writeProducts && missingCustomAttributes.length > 0 && (
        <Box>
          <AttributesMenu
            customAttributes={missingCustomAttributes}
            onSelectCustomAttribute={setCustomAttribute}
          />
        </Box>
      )}
    </>
  )
}
