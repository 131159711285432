import { Radio, RadioGroup, Stack } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { FormFieldset, RadioGroupLabel } from '@sitoo/mui-components'
import { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { useDayJs } from '../../../../hooks/day-js'
import { useLocalizedDate } from '../../../../hooks/localized-date'

enum Selection {
  AllTime,
  CustomRange,
}

export const ValidWhenField = () => {
  const { unregister, getValues, control } =
    useFormContext<CampaignFormContext>()
  const dayJs = useDayJs()
  const { getDateTimeFormat } = useLocalizedDate()

  const { t } = useTranslation(['shared', 'campaigns'])

  const [selection, setSelection] = useState<Selection>(
    getValues(['campaign.datestart', 'campaign.dateend']).filter(Boolean).length
      ? Selection.CustomRange
      : Selection.AllTime,
  )

  const onSelectionChange = useCallback(
    (_event: React.SyntheticEvent<HTMLInputElement>, value: string) => {
      const selectionValue = Number(value) as Selection

      if (Selection.AllTime === selectionValue) {
        setSelection(Selection.AllTime)
        unregister(['campaign.datestart', 'campaign.dateend'])
      } else {
        setSelection(Selection.CustomRange)
      }
    },
    [unregister],
  )

  const prepareDate = (date: Dayjs | null) => {
    if (date?.isValid()) {
      return date.set('second', 0).set('millisecond', 0)
    }
  }

  return (
    <FormFieldset
      label={t('campaigns:campaign_form.valid_when')}
      sx={{
        '.MuiFormFieldset-Paper': {
          p: 0,
        },
      }}
    >
      <RadioGroup value={selection} onChange={onSelectionChange}>
        <RadioGroupLabel
          label={t('campaigns:campaign_form.all_time')}
          sx={{ px: 2 }}
          divider
          secondaryAction={
            <Radio
              value={Selection.AllTime}
              data-testid="campaign-valid-when-all-field"
            />
          }
        />

        <RadioGroupLabel
          label={t('campaigns:campaign_form.custom_range')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio
              value={Selection.CustomRange}
              data-testid="campaign-valid-when-custom-field"
            />
          }
        >
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Controller
              control={control}
              name="campaign.datestart"
              rules={{
                required: t('shared:validation.field_required', {
                  field: t('campaigns:campaign_form.start_date'),
                }),
                validate: (v) =>
                  !v || !dayJs(v)?.isValid()
                    ? t('shared:validation.field_invalid', {
                        field: t('campaigns:campaign_form.start_date'),
                      })
                    : true,
              }}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  slotProps={{
                    textField: {
                      label: t('campaigns:campaign_form.start_date'),
                      error: !!error?.message,
                      helperText: error?.message,
                      inputProps: {
                        'data-testid': 'campaign-datestart-field',
                      },
                    },
                    openPickerButton: {
                      edge: 'start',
                    },
                  }}
                  value={field.value ? dayJs(field.value).tz() : null}
                  format={getDateTimeFormat()}
                  onChange={(newValue) => field.onChange(prepareDate(newValue))}
                />
              )}
            />

            <Controller
              control={control}
              name="campaign.dateend"
              rules={{
                required: t('shared:validation.field_required', {
                  field: t('campaigns:campaign_form.end_date'),
                }),
                validate: (v) =>
                  !v || !dayJs(v)?.isValid()
                    ? t('shared:validation.field_invalid', {
                        field: t('campaigns:campaign_form.end_date'),
                      })
                    : true,
              }}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  slotProps={{
                    textField: {
                      label: t('campaigns:campaign_form.end_date'),
                      error: !!error?.message,
                      helperText: error?.message,
                      inputProps: {
                        'data-testid': 'campaign-dateend-field',
                      },
                    },
                    openPickerButton: {
                      edge: 'start',
                    },
                  }}
                  value={field.value ? dayJs(field.value).tz() : null}
                  format={getDateTimeFormat()}
                  onChange={(newValue) => field.onChange(prepareDate(newValue))}
                />
              )}
            />
          </Stack>
        </RadioGroupLabel>
      </RadioGroup>
    </FormFieldset>
  )
}
