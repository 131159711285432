import { Box, CssBaseline } from '@mui/material'
import {
  Components,
  ThemeProvider as MuiThemeProvider,
  Theme,
} from '@mui/material/styles'
import { createSitooTheme } from '@sitoo/mui-components'
import { PropsWithChildren } from 'react'

const components: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: {
      '@media print': {
        '@page': { margin: 0 },
        body: { margin: '0 20px', zoom: 0.8 },
      },
    },
  },
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiAccordionRoot': {
          border: `1px solid ${theme.palette.divider}`,
          ':last-of-type': {
            borderRadius: 0,
          },
        },
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: '#fff',
        '.MuiTypography-subheader': {
          color: theme.palette.text.primary,
          backgroundColor: '#fff',
        },
        '&& .MuiAccordionSummary-content.Mui-expanded': {
          boxShadow: 'none',
        },
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        '&& .MuiListItem-root': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.text.primary}`,
        height: '16px',
      }),
    },
  },
}

export const PrintThemeProvider = (props: PropsWithChildren) => {
  const theme = createSitooTheme()

  Object.assign(theme.components || {}, components)

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ m: 2 }}>{props.children}</Box>
    </MuiThemeProvider>
  )
}
