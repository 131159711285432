import { PaymentType } from '../../../../generated/graphql'
/**
 * Retrieves an array of payment types based on the provided keys and respects the order of the key array
 *
 * @param {number[]} keys - An array of payment type IDs to search for.
 * @param {PaymentType[]} [allPaymentTypes] - An array of all available payment types.
 * @returns {PaymentType[]} An array of payment types that match the provided keys.
 *
 */
export const getPaymentTypesFromIds = (
  keys: number[],
  allPaymentTypes?: PaymentType[],
): PaymentType[] => {
  if (!allPaymentTypes) return []

  return keys
    .map((key) =>
      allPaymentTypes.find((paymentType) => paymentType.paymenttypeid === key),
    )
    .filter(
      (paymentType): paymentType is PaymentType => paymentType !== undefined,
    )
}
