import { Typography, Box } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { Trans } from 'react-i18next'

type Props = {
  hideTotalCount?: boolean
}

export const CustomRowCount = (props: Props) => {
  const { current } = useGridApiContext()
  const rowsInTable = current.getRowModels().size
  const totalCount = current.getRowsCount()

  if (rowsInTable === 0) return null

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'gray10',
        borderTop: (theme) => `2px solid ${theme.palette.gray30}`,
        display: 'flex',
        alignItems: 'center',
        p: 0.5,
      }}
    >
      <Typography
        variant="caption"
        color="gray70"
        sx={{
          pointerEvents: 'none',
        }}
      >
        {props.hideTotalCount ? (
          <Trans
            i18nKey="shared:grid.showing_rows"
            values={{ rows: rowsInTable }}
            components={{ b: <b /> }}
          />
        ) : (
          <Trans
            i18nKey="shared:grid.showing_of_total"
            values={{ rows: rowsInTable, totalRows: totalCount }}
            components={{ b: <b /> }}
          />
        )}
      </Typography>
    </Box>
  )
}
