import { useFormContext } from 'react-hook-form'
import { RouteLeavingGuard } from '../route-leaving-guard'
import { containsDirtyFields } from '../../utils/contains-dirty-fields'
import { Location } from 'history'

/**
 * NOTE: Only usable inside a `<FormProvider>` component.
 *
 * `FormRouteLeavingGuard` is a component that integrates with `react-hook-form` to provide a guard
 * that prevents route changes if there are unsaved changes in the form.
 *
 * It uses the `useFormContext` hook to access the form context and checks if there are any dirty fields
 * using the `containsDirtyFields` utility function. The guard is active if there are dirty fields and
 * the form is neither submitted successfully nor submitting.
 *
 * @component
 * @example
 * ```tsx
 * import { FormRouteLeavingGuard } from './path-to-component';
 *
 * const MyFormComponent = () => (
 *   <FormProvider {...methods}>
 *       <FormRouteLeavingGuard />
 *       {form fields}
 *   </FormProvider>
 * );
 * ```
 */

type Props = {
  shouldBlockNavigation?: (location: Location) => boolean
}

export const FormRouteLeavingGuard = ({ shouldBlockNavigation }: Props) => {
  const { formState } = useFormContext()

  const { dirtyFields, isSubmitSuccessful, isSubmitting } = formState

  const hasDirtyFields = containsDirtyFields(dirtyFields)

  const when = hasDirtyFields && !isSubmitSuccessful && !isSubmitting

  return (
    <RouteLeavingGuard
      when={when}
      shouldBlockNavigation={shouldBlockNavigation}
    />
  )
}
