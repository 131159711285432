import { PrimitiveCircleSmallIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'

export type StatusProps = {
  inStock?: string | null
  newQuantity?: string | null
}

export const Status = (props: StatusProps): React.ReactElement => {
  const { t } = useTranslation('stocktaking')
  const inStock = Number(props.inStock) || 0
  const newQuantity = Number(props.newQuantity) || 0

  if (props.newQuantity === null || props.newQuantity === undefined)
    return <></>

  return (
    <>
      <PrimitiveCircleSmallIcon
        color={inStock === newQuantity ? 'success' : 'warning'}
      />{' '}
      {inStock === newQuantity ? t('ok') : t('diff')}
    </>
  )
}
