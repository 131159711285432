import { makeVar } from '@apollo/client'
import { Row } from '.'

type ViewPanelState = {
  isOpen: boolean
  selectedItem?: Row
}

export const influencerCodesViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  selectedItem: undefined,
})

export const influencerCodesFilterPanelVar = makeVar({
  isOpen: false,
})
