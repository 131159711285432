import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  CampaignType,
  GetCampaignQuery,
  GetConfigVarsDocument,
} from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'
import {
  CAMPAIGN_TYPE_PRODUCT,
  getCampaignType,
} from '../../../utils/campaigns'
import { parseVoucherCode } from '../../campaign/utils'
import { offerCompositionValueGetter } from '../campaigns-list/offer-composition'
import { formatPriority } from '../campaigns-list/type-and-priority'
import { voucherStateRenderer } from '../campaigns-list/voucher-state'
import { translateCampaignTypeTitle } from '../i18n'
import { useQuery } from '@apollo/client'

type CampaignDetailsProps = {
  campaign?: GetCampaignQuery['campaign']
  isLoading: boolean
}

export const CampaignDetails = ({
  campaign,
  isLoading,
}: CampaignDetailsProps) => {
  const { t } = useTranslation(['shared', 'campaigns'])
  const { formatCurrency } = useMoney()
  const campaignType = getCampaignType(campaign?.vouchertype)
  const { data } = useQuery(GetConfigVarsDocument)

  const showDiscountPriceInfo =
    !!data?.configVars.campaignPriceListDiscount &&
    CAMPAIGN_TYPE_PRODUCT.types.includes(campaign?.vouchertype as CampaignType)

  return (
    <Accordion
      defaultExpanded
      className="MuiAccordionRoot"
      data-testid="details-accordion"
    >
      <AccordionSummary aria-controls="details">
        <SectionHeader sx={{ p: 0 }}>{t('shared:label.details')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton isLoading={isLoading} divider childrenSkeleton>
            <ListItemIcon>{campaignType?.icon}</ListItemIcon>
            <ListItemText
              primary={t(campaignType?.title || '')}
              secondary={t(campaignType?.caption || '')}
            />
          </ListItemSkeleton>
          <ListItemSkeleton isLoading={isLoading} divider childrenSkeleton>
            <ListItemText
              primary={t('campaigns:type')}
              secondary={translateCampaignTypeTitle(campaign?.vouchertype)}
            />
          </ListItemSkeleton>
          <ListItemSkeleton isLoading={isLoading} divider>
            <ListItemText
              primary={t('campaigns:description')}
              secondary={
                campaign
                  ? offerCompositionValueGetter(campaign, formatCurrency)
                  : ''
              }
            />
          </ListItemSkeleton>
          <ListItemSkeleton
            hide={!isLoading && !campaign?.vouchercode}
            isLoading={isLoading}
            divider
          >
            <ListItemText
              data-testid="campaign-group"
              primary={t('campaigns:campaign_group')}
              secondary={parseVoucherCode(campaign?.vouchercode)}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            secondaryAction={
              <ListItemText
                data-testid="priority"
                primary={formatPriority(campaign?.priority, {
                  showNormal: true,
                  showPriorityValue: true,
                })}
              />
            }
            isLoading={isLoading}
            divider
          >
            <ListItemText
              data-testid="priority-label"
              primary={t('campaigns:priority')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            secondaryAction={
              campaign ? (
                <ListItemText
                  primary={voucherStateRenderer({ row: campaign })}
                />
              ) : (
                ''
              )
            }
            isLoading={isLoading}
            divider
          >
            <ListItemText
              data-testid="campaign-state"
              primary={t('campaigns:state')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            secondaryAction={
              <ListItemText
                primary={
                  <Chip
                    data-testid="campaign-active-in-pos-status"
                    size="small"
                    label={
                      campaign?.activepos
                        ? t('shared:label.active')
                        : t('shared:label.inactive')
                    }
                    color={campaign?.activepos ? 'green' : 'red'}
                  />
                }
              />
            }
            isLoading={isLoading}
            divider
          >
            <ListItemText
              data-testid="campaign-active-in-pos-label"
              primary={t('campaigns:active_in_pos')}
            />
          </ListItemSkeleton>

          {showDiscountPriceInfo && (
            <ListItemSkeleton
              secondaryAction={
                <ListItemText
                  primary={
                    <Chip
                      size="small"
                      data-testid="discounted-price-status"
                      label={
                        campaign?.use_discounted_price
                          ? t('shared:label.active')
                          : t('shared:label.inactive')
                      }
                      color={campaign?.use_discounted_price ? 'green' : 'red'}
                    />
                  }
                />
              }
              isLoading={isLoading}
              divider
            >
              <ListItemText
                data-testid="discounted-price-label"
                primary={t('campaigns:discounted_price_label')}
              />
            </ListItemSkeleton>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
