import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { Button } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { useFilterContext } from '../../../components/data-grid/hooks/use-filter-context'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { PageHeader } from '../../../components/page-header'
import { SettingsList } from '../../../components/settings-list'
import {
  GetWarehousesSort,
  SettingsWarehouseQuery,
  SettingsWarehousesDocument,
  SettingsWarehousesQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { StoreFilterContext } from '../../../filters/store-filter'
import { WarehouseFilterPanel } from './warehouse-filter-panel'
import { WarehousesContextMenu } from './warehouses-context-menu'

type Row = SettingsWarehouseQuery['warehouse']

const warehouseFilterPanelVar = makeVar({
  isOpen: false,
})

export const SettingsWarehousesPage = () => {
  const name = 'warehouses'
  const { t } = useTranslation([
    'shared',
    'settings',
    'countries',
    'warehouses',
  ])
  const { trackButtonClick } = useTracking()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const filterViewPanelState = useReactiveVar(warehouseFilterPanelVar)

  const filterContext = useFilterContext<StoreFilterContext>()

  const apiRef = useGridApiRef()
  usePageTitle(t('shared:menu.warehouses'))

  const sortItems = useMemo<SortItem<GetWarehousesSort>[]>(
    () => [
      {
        field: 'warehousetype',
        sort: 'asc',
        title: t('settings:warehouse.warehouse_type'),
        type: 'text',
        value: GetWarehousesSort.WarehouseTypeAsc,
      },
      {
        field: 'warehousetype',
        sort: 'desc',
        title: t('settings:warehouse.warehouse_type'),
        type: 'text',
        value: GetWarehousesSort.WarehouseTypeDesc,
        isDefault: true,
      },
      {
        field: 'name',
        sort: 'asc',
        title: t('settings:warehouse.name'),
        type: 'text',
        value: GetWarehousesSort.NameAsc,
      },
      {
        field: 'name',
        sort: 'desc',
        title: t('settings:warehouse.name'),
        type: 'text',
        value: GetWarehousesSort.NameDesc,
        isDefault: true,
      },
      {
        field: 'address',
        sort: 'asc',
        title: t('settings:warehouse.address'),
        type: 'text',
        value: GetWarehousesSort.AddressAsc,
      },
      {
        field: 'address',
        sort: 'desc',
        title: t('settings:warehouse.address'),
        type: 'text',
        value: GetWarehousesSort.AddressDesc,
      },
      {
        field: 'zip',
        sort: 'asc',
        title: t('settings:warehouse.zip'),
        type: 'text',
        value: GetWarehousesSort.ZipAsc,
      },
      {
        field: 'zip',
        sort: 'desc',
        title: t('settings:warehouse.zip'),
        type: 'text',
        value: GetWarehousesSort.ZipDesc,
      },
      {
        field: 'city',
        sort: 'asc',
        title: t('settings:warehouse.city'),
        type: 'text',
        value: GetWarehousesSort.CityAsc,
      },
      {
        field: 'city',
        sort: 'desc',
        title: t('settings:warehouse.city'),
        type: 'text',
        value: GetWarehousesSort.CityDesc,
      },
      {
        field: 'state',
        sort: 'asc',
        title: t('settings:warehouse.state'),
        type: 'text',
        value: GetWarehousesSort.StateAsc,
      },
      {
        field: 'state',
        sort: 'desc',
        title: t('settings:warehouse.state'),
        type: 'text',
        value: GetWarehousesSort.StateDesc,
      },
      {
        field: 'countryid',
        sort: 'asc',
        title: t('settings:warehouse.country_id'),
        type: 'text',
        value: GetWarehousesSort.ZipAsc,
      },
      {
        field: 'countryid',
        sort: 'desc',
        title: t('settings:warehouse.country_id'),
        type: 'text',
        value: GetWarehousesSort.ZipDesc,
      },
      {
        field: 'currencycode',
        sort: 'asc',
        title: t('settings:warehouse.currency'),
        type: 'text',
        value: GetWarehousesSort.CurrencyCodeAsc,
      },
      {
        field: 'currencycode',
        sort: 'desc',
        title: t('settings:warehouse.currency'),
        type: 'text',
        value: GetWarehousesSort.CurrencyCodeDesc,
      },
    ],
    [t],
  )

  const sortModel = apiRef.current.state?.sorting.sortModel
  const filteredStoreId = filterContext.filter.storeId?.value

  const queryVariables = useMemo(() => {
    const variables: SettingsWarehousesQueryVariables = {}
    if (filteredStoreId) {
      variables.storeid = Number(filteredStoreId)
    }

    if (sortModel) {
      const sortItem = sortModel[0]
      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [sortItems, sortModel, filteredStoreId])

  const { loading: isLoading, data } = useQuery(SettingsWarehousesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
  })

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'warehousetype',
        headerName: t('settings:warehouse.warehouse_type'),
        minWidth: 100,
      },
      {
        field: 'usetype',
        headerName: t('settings:warehouse.usetype'),
        minWidth: 100,
      },
      {
        field: 'sellable',
        headerName: t('settings:warehouse.sellable'),
        minWidth: 80,
      },
      {
        field: 'name',
        headerName: t('settings:warehouse.name'),
        minWidth: 240,
      },
      {
        field: 'address',
        headerName: t('settings:warehouse.address'),
        minWidth: 240,
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.address,
          caption: [
            row.zip,
            row.city,
            t(`countries:${row.countryid?.toUpperCase() ?? ''}`),
          ]
            .filter(Boolean)
            .join(', '),
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'currencycode',
        headerName: t('settings:warehouse.currency'),
        minWidth: 100,
      },
      {
        field: 'externalid',
        headerName: t('settings:warehouse.external_id'),
        minWidth: 120,
      },
      {
        field: 'store',
        headerName: t('settings:warehouse.store'),
        valueGetter: (_value, row) => row.store?.name,
        minWidth: 240,
      },
    ],
    [t],
  )

  const onEdit = (warehouseId: number) => {
    trackButtonClick({ name: `${name}-edit` })
    navigate(generatePath(RootRoute.SettingsWarehouse, { id: warehouseId }))
  }

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    navigate(generatePath(RootRoute.SettingsWarehouseNew))
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: `${name}-filter-panel-close` })
    warehouseFilterPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: `${name}-filter-panel-open` })
    warehouseFilterPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider value={filterContext}>
      <SettingsList
        name="warehouses-settings-list"
        apiRef={apiRef}
        rows={data?.allWarehouses}
        columns={columns}
        loading={isLoading}
        onRowClick={(row) => onEdit(Number(row.id))}
        onShowFilter={toggleFilterViewPanel}
        noRowsOverlay={{
          icon: <WarehouseIcon />,
          title: t('settings:warehouse.empty_title'),
          description: t('settings:warehouse.empty_description'),
          action: (
            <Button
              data-testid="blah"
              onClick={() =>
                navigate(generatePath(RootRoute.SettingsImportData))
              }
            >
              {t('settings:warehouse.import_warehouses')}
            </Button>
          ),
        }}
        sortItems={sortItems}
        slots={{ PageHeader, FilterPanel: WarehouseFilterPanel }}
        slotProps={{
          pageHeader: {
            title: t('shared:menu.warehouses'),
            isFlexible: true,
            rightColumn: (
              <>
                <WarehousesContextMenu />
                <Button data-testid="add-warehouse-button" onClick={onAdd}>
                  {t('settings:warehouse.add_warehouse')}
                </Button>
              </>
            ),
          },
          filterPanel: {
            isOpen: filterViewPanelState.isOpen,
            onClose: closeFilterViewPanel,
          },
        }}
      />
    </FilterProvider>
  )
}
