import {
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DeleteIcon } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ProductFormContext } from '../..'
import {
  AllCustomAttributesQuery,
  CustomAttributeTypeEnum,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { AttributesMenu } from './attributes-menu'
import { CustomAttribute, CustomAttributeValue } from '.'
import { useAuthorization } from '../../../../hooks/authorization'
import { t } from 'i18next'

type SingleProductAttributesProps = {
  customAttributes: AllCustomAttributesQuery['allCustomAttributes']
}

export const SingleProductAttributes = (
  props: SingleProductAttributesProps,
) => {
  const { control, watch, setValue } = useFormContext<ProductFormContext>()
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const productCustomAttributes = watch(
    'product.customattributes',
  ) as CustomAttribute

  const allCustomAttributes = props.customAttributes

  const currentCustomAttributes = Object.entries(productCustomAttributes || {})
    .map(([attributeId, value]) => ({
      ...allCustomAttributes.find((a) => a.id === attributeId),
      value,
    }))
    .filter(
      (
        c,
      ): c is ArrayElement<AllCustomAttributesQuery['allCustomAttributes']> & {
        value: CustomAttributeValue
      } => !!c.id,
    )

  useEffect(() => {
    setValue(
      'supportedCustomAttributes',
      allCustomAttributes.filter((c) =>
        currentCustomAttributes?.some((v) => v.id === c.id),
      ),
    )
  }, [allCustomAttributes, setValue, currentCustomAttributes])

  const missingCustomAttributes = allCustomAttributes.filter(
    (c) => !currentCustomAttributes.some((current) => current.id === c.id),
  )

  const setCustomAttribute = (
    attributeId: string,
    value: CustomAttributeValue,
  ) => {
    const current: CustomAttribute = productCustomAttributes
      ? productCustomAttributes
      : {}

    if (value === '') {
      const { [attributeId]: _, ...rest } = current
      setValue('product.customattributes', rest, {
        shouldDirty: true,
      })
    } else {
      setValue(
        'product.customattributes',
        { ...current, [attributeId]: value },
        {
          shouldDirty: true,
        },
      )
    }
  }

  const removeCustomAttribute = (attributeId: string) => {
    const current: CustomAttribute = productCustomAttributes
      ? productCustomAttributes
      : {}

    const { [attributeId]: _, ...rest } = current

    setValue('product.customattributes', rest, {
      shouldDirty: true,
    })
  }

  return (
    <>
      <Controller
        control={control}
        name="product.customattributes"
        render={({ field }) => (
          <>
            {currentCustomAttributes.map((c, index) => (
              <Box
                sx={{
                  display: 'flex',
                  gap: (theme) => theme.spacing(1),
                  marginBottom: (theme) =>
                    c.enums && index !== currentCustomAttributes.length - 1
                      ? theme.spacing(2)
                      : 0,
                  p: 2,
                  pb: 0,
                }}
                key={c.id}
              >
                {c.enums ? (
                  <Box sx={{ width: '100%' }}>
                    <InputLabel data-testid="attribute-label">
                      {c.title}
                    </InputLabel>
                    <Select<number | string>
                      data-testid="select-attribute-value"
                      value={(field.value as CustomAttribute)?.[c.id] || ''}
                      onChange={(event) =>
                        setCustomAttribute(c.id, event.target.value as string)
                      }
                      disabled={!writeProducts}
                    >
                      {c.enums.map((value) => (
                        <MenuItem value={value} key={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : (
                  <TextField
                    data-testid="attribute-label"
                    fullWidth
                    label={c.title}
                    name={`product.customattributes.${c.id}`}
                    sx={{
                      pb: index === currentCustomAttributes.length - 1 ? 2 : 0,
                    }}
                    value={(field.value as CustomAttribute)?.[c.id] || ''}
                    onChange={(event) =>
                      setCustomAttribute(
                        c.id,
                        c.type === CustomAttributeTypeEnum.Integer &&
                          event.target.value !== ''
                          ? Number(event.target.value)
                          : event.target.value,
                      )
                    }
                    type={
                      c.type === CustomAttributeTypeEnum.Integer
                        ? 'number'
                        : 'text'
                    }
                    disabled={!writeProducts}
                  />
                )}

                {writeProducts && (
                  <div>
                    <IconButton
                      data-testid="remove-attribute-icon"
                      onClick={() => removeCustomAttribute(c.id)}
                      sx={{
                        marginTop: (theme) => theme.spacing(3.5),
                      }}
                      aria-label={`${t('shared:action.remove')} ${c.title}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                )}
              </Box>
            ))}
          </>
        )}
      />

      {missingCustomAttributes.length > 0 && (
        <AttributesMenu
          customAttributes={missingCustomAttributes}
          onSelectCustomAttribute={setCustomAttribute}
          disabled={!writeProducts}
        />
      )}
    </>
  )
}
