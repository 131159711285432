import { LoadingButton } from '@mui/lab'
import { Button, Stack, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PasswordField } from '../../components/password-field'
import { UpdatePasswordDocument } from '../../generated/graphql'
import { useTracking } from '../../hooks/tracking'
import { ResetPasswordForm } from '.'
import { useServerValidation } from '../../hooks/server-validation'
import { useSnackbar } from 'notistack'
import { getErrorMessage } from '../../utils/error-mapping'
import { extractGraphqlErrors } from '../../utils/extract-graphql-errors'
import { Link as RouterLink } from 'react-router-dom'
import { RootRoute } from '..'

type Form = {
  password: string
  confirmPassword: string
}

type Props = {
  onSuccess(): void
  onBack(): void
}

export const PasswordInput = ({ onSuccess, onBack }: Props) => {
  const { getValues } = useFormContext<ResetPasswordForm>()

  const { register, formState, setError, handleSubmit, watch } = useForm<Form>()

  const { t } = useTranslation(['shared', 'login'])
  const { trackButtonClickEvent, trackInputBlur, trackInputFocus } =
    useTracking()

  const { enqueueSnackbar } = useSnackbar()

  const [updatePassword, { loading }] = useMutation(UpdatePasswordDocument)

  const setFormError = useServerValidation<Form>('', setError, {
    resolveFieldFromProperty: (property) => property,
  })

  const handleUpdatePassword = async ({ password, confirmPassword }: Form) => {
    // Disabling this rule since we are requesting a new password, not propmting the users existing password
    // eslint-disable-next-line security/detect-possible-timing-attacks
    if (password !== confirmPassword) {
      setError('confirmPassword', {
        message: t('login:error.passwords_not_matching'),
      })
      return
    }
    const token = getValues('token')

    if (!token) {
      return onBack()
    }

    try {
      const accountId = getValues('accountId')
      await updatePassword({ variables: { password, token, accountId } })

      onSuccess()
    } catch (error) {
      const errors = extractGraphqlErrors(error)

      errors.forEach(() => {
        const errorMessage = getErrorMessage(error, 'login')
        setFormError(error)
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
    }
  }

  return (
    <>
      <Typography variant="displayMedium" sx={{ mb: 3 }}>
        {t('login:new_password')}
      </Typography>
      <form>
        <PasswordField
          required
          error={!!formState.errors.password}
          helperText={
            formState.errors.password?.message ||
            t('login:password_helper_text')
          }
          {...register('password', {
            required: t('shared:validation.field_required', {
              field: t('shared:label.password'),
            }),
            onBlur: trackInputBlur({ name: 'reset-password' }),
          })}
          onFocus={trackInputFocus({ name: 'reset-password' })}
          inputProps={{ 'data-testid': 'reset-password-input' }}
          sx={{ mb: 2 }}
        />

        <PasswordField
          label={t('login:confirm_password')}
          required
          error={!!formState.errors.confirmPassword}
          helperText={formState.errors.confirmPassword?.message}
          {...register('confirmPassword', {
            required: t('shared:validation.field_required', {
              field: t('login:confirm_password'),
            }),
            validate: (value: string) => {
              if (watch('password') !== value) {
                return t('shared:validation.field_invalid', {
                  field: t('login:confirm_password'),
                })
              }
            },
            onBlur: trackInputBlur({ name: 'reset-pw-confirm-password' }),
          })}
          onFocus={trackInputFocus({ name: 'reset-pw-confirm-password' })}
          inputProps={{ 'data-testid': 'reset-confirm-password-input' }}
          sx={{ mb: 2 }}
        />

        <Stack gap={1}>
          <LoadingButton
            onClick={trackButtonClickEvent(
              { name: 'update-password-submit' },
              handleSubmit(handleUpdatePassword),
            )}
            type="submit"
            disabled={loading}
            loading={loading}
            data-testid="update-password-button"
            fullWidth
          >
            {t('shared:label.continue')}
          </LoadingButton>

          <Button variant="text" to={RootRoute.Login} component={RouterLink}>
            {t('login:return_to_sign_in')}
          </Button>
        </Stack>
      </form>
    </>
  )
}
