import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { DuplicateIcon, SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { RootRoute } from '../../..'
import {
  AddUpdateLoginOptionInput,
  LoginOptionTypeEnum,
  AddUpdateLoginOptionDocument,
  LoginOptionDocument,
  LoginOptionsDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { stripNullValues } from '../../../../utils/strip-null-values'
import { ACCOUNT_ID_REGEX } from '../../../login/util'

type AddUpdateLoginOptionProps = {
  open: boolean
  loginOptionId?: string
  availableLoginOptionTypes: LoginOptionTypeEnum[]
  hasOnlySitooLogin?: boolean

  onClose: () => void
  onSuccess: () => void
}

export const AddUpdateLoginOptionDialog = (
  props: AddUpdateLoginOptionProps,
) => {
  const { t } = useTranslation(['shared', 'settings'])
  const {
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const { enqueueSnackbar } = useSnackbar()

  const isNewLoginOption = !props.loginOptionId

  const dialogName = isNewLoginOption ? 'add-login-option' : 'edit-login-option'

  const getLabel = (optionType: LoginOptionTypeEnum) => {
    const type =
      optionType === LoginOptionTypeEnum.OidcPrivateAzureAd ? '_azure' : ''

    return {
      tenantId: t(`settings:authentication.tenant_id${type}`),
      clientId: t(`settings:authentication.client_id${type}`),
      clientSecret: t(`settings:authentication.client_secret${type}`),
    }
  }

  const { data, loading: fetchLoading } = useQuery(LoginOptionDocument, {
    variables: { optionId: String(props.loginOptionId) },
    skip: isNewLoginOption || !props.open,
    fetchPolicy: 'cache-and-network',
    onError: () => {
      enqueueSnackbar(t('shared:error.something_went_wrong'), {
        variant: 'error',
      })
    },
  })

  const [addUpdateLoginOptionsMutation, { loading: addLoading }] = useMutation(
    AddUpdateLoginOptionDocument,
    { refetchQueries: [LoginOptionsDocument] },
  )

  const isLoading = fetchLoading || addLoading

  const loginOptionTypes = Array.from(
    isNewLoginOption
      ? props.availableLoginOptionTypes
      : new Set([...props.availableLoginOptionTypes, data?.loginOption.type]),
  )
    .filter((x): x is LoginOptionTypeEnum => Boolean(x))
    .map((x) => ({
      id: x,
      text: t(`settings:authentication.login_option_type.${x}`),
    }))

  const defaultValue: AddUpdateLoginOptionInput = {
    type: loginOptionTypes[0]?.id || LoginOptionTypeEnum.SitooLogin,
  }

  const formContext = useForm<AddUpdateLoginOptionInput>()

  const loginOptionType = formContext.watch('type')
  const tenantId = formContext.watch('oidc_private.tenant_id')

  const submit = async (data: AddUpdateLoginOptionInput) => {
    try {
      const { id, oidc_private, type } = data
      await addUpdateLoginOptionsMutation({
        variables: {
          data: stripNullValues(
            { id: id || undefined, type, oidc_private },
            { deleteEmptyProperties: true },
          ),
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })
      enqueueSnackbar(
        isNewLoginOption
          ? t('settings:authentication.success_added')
          : t('settings:authentication.success_saved'),
      )
      props.onSuccess()

      if (props.hasOnlySitooLogin)
        enqueueSnackbar(t('settings:authentication.success_added_last'), {
          variant: 'info',
        })

      trackDialogClose({ name: dialogName })
    } catch {
      enqueueSnackbar(
        isNewLoginOption
          ? t('settings:authentication.error_added')
          : t('settings:authentication.error_saved'),
        {
          variant: 'error',
        },
      )
      trackFormError({
        name: `${dialogName}-dialog`,
      })
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, dialogName])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  const callbackUrl = useMemo(() => {
    const host = location.host.replace(ACCOUNT_ID_REGEX, '')
    return `https://${host}${RootRoute.Callback}`
  }, [])

  const copyCallbackToClipboard = useCallback(() => {
    void navigator.clipboard
      .writeText(callbackUrl)
      .then(() => enqueueSnackbar(t('shared:copied_to_clipboard')))
  }, [callbackUrl, enqueueSnackbar, t])

  useEffect(() => {
    let authUrl = ''
    let tokenUrl = ''

    if (loginOptionType === LoginOptionTypeEnum.OidcPrivateAzureAd) {
      authUrl = `https://login.microsoftonline.com/${
        tenantId || ''
      }/oauth2/v2.0/authorize`
      tokenUrl = `https://login.microsoftonline.com/${
        tenantId || ''
      }/oauth2/v2.0/token`
    } else if (loginOptionType === LoginOptionTypeEnum.OidcPrivateGoogle) {
      authUrl = 'https://accounts.google.com/o/oauth2/auth'
      tokenUrl = 'https://oauth2.googleapis.com/token'
    }

    formContext.setValue('oidc_private.auth_url', authUrl)
    formContext.setValue('oidc_private.token_url', tokenUrl)
  }, [formContext, loginOptionType, tenantId])

  useEffect(
    () => {
      if (!props.open) return

      if (!isNewLoginOption && data?.loginOption) {
        formContext.reset(data.loginOption)
      }

      if (isNewLoginOption) {
        formContext.reset(defaultValue)
      }
    },
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.open, data?.loginOption],
  )

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        {isNewLoginOption
          ? t('settings:authentication.add_login_option')
          : t('settings:authentication.edit_login_option')}
      </DialogTitle>
      <DialogContent>
        <Controller
          control={formContext.control}
          name="type"
          render={({ field }) => (
            <>
              <InputLabel>{t('settings:authentication.type')}</InputLabel>
              <Select
                value={props.open && field.value ? field.value : ''}
                onChange={(event) => field.onChange(event.target.value)}
                data-testid="login-option-type"
                inputProps={{
                  'data-testid': 'login-option-type-input',
                }}
                disabled={!isNewLoginOption}
                sx={{ mb: 2 }}
              >
                {loginOptionTypes.map(({ id, text }) => (
                  <MenuItem
                    value={id}
                    key={id}
                    data-testid={`login-option-type-option-${id}`}
                  >
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
        {loginOptionType === LoginOptionTypeEnum.OidcPrivateAzureAd && (
          <>
            <TextField
              error={!!formContext.formState.errors.oidc_private?.tenant_id}
              disabled={!isNewLoginOption}
              fullWidth
              helperText={
                formContext.formState.errors.oidc_private?.tenant_id?.message
              }
              label={getLabel(loginOptionType).tenantId}
              {...formContext.register('oidc_private.tenant_id', {
                required: t('shared:validation.field_required', {
                  field: getLabel(loginOptionType).tenantId,
                }),
              })}
              sx={{ mb: 2 }}
              inputProps={{
                autoComplete: 'off',
                'data-testid': 'login-option-tenant-id',
              }}
            />
          </>
        )}
        {(loginOptionType === LoginOptionTypeEnum.OidcPrivateGoogle ||
          loginOptionType === LoginOptionTypeEnum.OidcPrivateAzureAd) && (
          <>
            <TextField
              error={!!formContext.formState.errors.oidc_private?.client_id}
              disabled={!isNewLoginOption}
              fullWidth
              helperText={
                formContext.formState.errors.oidc_private?.client_id?.message
              }
              label={getLabel(loginOptionType).clientId}
              {...formContext.register('oidc_private.client_id', {
                required: t('shared:validation.field_required', {
                  field: getLabel(loginOptionType).clientId,
                }),
              })}
              sx={{ mb: 2 }}
              inputProps={{
                autoComplete: 'off',
                'data-testid': 'login-option-client-id',
              }}
            />
            <TextField
              error={!!formContext.formState.errors.oidc_private?.client_secret}
              fullWidth
              helperText={
                formContext.formState.errors.oidc_private?.client_secret
                  ?.message
              }
              placeholder={
                isNewLoginOption
                  ? ''
                  : t('settings:authentication.client_secret_placeholder')
              }
              label={getLabel(loginOptionType).clientSecret}
              {...formContext.register('oidc_private.client_secret', {
                required: t('shared:validation.field_required', {
                  field: getLabel(loginOptionType).clientSecret,
                }),
              })}
              sx={{ mb: 2 }}
              inputProps={{
                autoComplete: 'off',
                'data-testid': 'login-option-client-secret',
              }}
            />
          </>
        )}

        {loginOptionType !== LoginOptionTypeEnum.SitooLogin && (
          <>
            <SectionHeader variant="transparent" sx={{ px: 0 }}>
              {t('settings:authentication.configuration_reference')}
            </SectionHeader>
            <ListItem
              component="div"
              disablePadding
              sx={{
                pl: '0 !important',
                pr: '0 !important',
              }}
            >
              <ListItemText secondary={callbackUrl}>
                {t('settings:authentication.callback_url')}
              </ListItemText>
              <IconButton onClick={copyCallbackToClipboard}>
                <DuplicateIcon />
              </IconButton>
            </ListItem>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-login-option"
        >
          {t('shared:action.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={formContext.handleSubmit(submit)}
          data-testid="dialog-add-login-option"
          loading={isLoading}
        >
          {isNewLoginOption ? t('shared:action.add') : t('shared:action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
