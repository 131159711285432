import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  to?: string
  onClick?: () => void
}

export const SidebarLink = forwardRef<HTMLAnchorElement, Props>(
  (props, ref) => {
    const { to = '', ...rest } = props

    return <Link ref={ref} to={to} {...rest} />
  },
)

SidebarLink.displayName = 'SidebarLink'
