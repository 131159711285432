import { Container } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'

export const FormPagePlaceholder = () => {
  return (
    <Container>
      <FormFieldset isLoading={true} />

      <FormFieldset isLoading={true} />

      <FormFieldset isLoading={true} />

      <FormFieldset isLoading={true} />

      <FormFieldset isLoading={true} />
    </Container>
  )
}
