import { createSearchParams, matchPath } from 'react-router-dom'
import { RootRoute } from '../../pages'
import { REDIRECT_QUERY_PARAM } from '../constants'

export const getRedirectParam = (search: string): string => {
  const searchParams = new URLSearchParams(search)
  const redirectParam = searchParams.get(REDIRECT_QUERY_PARAM)

  return redirectParam || ''
}

export const createRedirectParam = (
  params?: Record<string, string>,
): string => {
  const redirectParam = getRedirectParam(location.search)

  if (redirectParam) {
    return `?${createSearchParams({
      [REDIRECT_QUERY_PARAM]: redirectParam,
    }).toString()}`
  }

  const isExcludedRoute = [
    RootRoute.Callback,
    RootRoute.Login,
    RootRoute.Logout,
  ].some((path) => matchPath(path, location.pathname))

  if (isExcludedRoute) {
    return ''
  }

  return `?${createSearchParams({
    [REDIRECT_QUERY_PARAM]: `${location.pathname}${location.search}`,
    ...params,
  }).toString()}`
}
