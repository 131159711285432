import { Box, ListItemIcon } from '@mui/material'

type Props = {
  first?: boolean
  last?: boolean
  icon?: React.ElementType
}

export const ListItemIconTimeline = ({ first, last, icon: LogIcon }: Props) => {
  return (
    <ListItemIcon
      sx={{
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: first ? '50%' : '0',
          bottom: 0,
          left: '25px',
          width: '2px',
          height: first && last ? '0' : first || last ? '50%' : '100%',
          backgroundColor: (theme) => theme.palette.gray30,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: (theme) => theme.palette.background.paper,
          zIndex: 1,
        }}
      >
        {LogIcon && <LogIcon />}
      </Box>
    </ListItemIcon>
  )
}
