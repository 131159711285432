import {
  Autocomplete,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { useDebounce } from 'react-use'
import { addTagOnInputChange } from '../../../utils/autocomplete'

export enum IdType {
  OrderId = 'orderId',
  ReceiptId = 'receiptId',
  ExternalId = 'externalId',
  CreditOrderId = 'creditOrderId',
}

type QueryParamsState = {
  id?: string[] | string
  idType?: IdType
}

export const OrderIdFilter = () => {
  const { t } = useTranslation(['orders', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)

  const { trackButtonClickEvent, trackInputFocus, trackInputBlur } =
    useTracking()

  const filterKey = 'id'

  const orderIdTypes = useMemo(
    () =>
      [
        { label: t('orders:order_id'), value: IdType.OrderId },
        { label: t('orders:receipt_id'), value: IdType.ReceiptId },
        { label: t('orders:external_id'), value: IdType.ExternalId },
        { label: t('orders:credit_order_id'), value: IdType.CreditOrderId },
      ].sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const toArray = (value: string | string[] | undefined) => {
    if (Array.isArray(value)) return value
    if (value) return [value]

    return []
  }

  const toString = (value: string | string[] | undefined) => {
    if (Array.isArray(value)) return value[0]
    if (value) return value

    return null
  }

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const typeValue =
    orderIdTypes.find(({ value }) => value === queryParams.idType)?.value ||
    IdType.OrderId

  const isArrayType = useMemo(() => {
    const arrayIdTypes = [IdType.OrderId, IdType.ExternalId]

    return !!typeValue && arrayIdTypes.includes(typeValue)
  }, [typeValue])

  const idValue = useMemo(
    () => (isArrayType ? toArray(queryParams.id) : toString(queryParams.id)),
    [isArrayType, queryParams.id],
  )

  const [debouncedValue, setDebouncedValue] = useState(idValue)

  useDebounce(
    () => {
      return setDebouncedValue(idValue)
    },
    300,
    [idValue],
  )

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined, idType: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  const filterText = hasApply ? idValue : debouncedValue

  useEffect(() => {
    const selectedType = orderIdTypes?.find(({ value }) => value === typeValue)

    if (selectedType && filterText?.length) {
      setFilter(filterKey, {
        label: selectedType.label,
        labelValues: Array.isArray(filterText) ? [...filterText] : [filterText],
        value: [selectedType.value, filterText],
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, typeValue, filterText, orderIdTypes])

  return (
    <ListItem>
      {isArrayType ? (
        <Autocomplete
          autoSelect
          multiple
          limitTags={3}
          fullWidth
          freeSolo
          options={[]}
          onChange={(_event, value) => {
            setQueryParams({ id: value, idType: typeValue }, hasApply !== true)
          }}
          onInputChange={addTagOnInputChange}
          value={idValue as string[]}
          data-testid="order-id-filter-field"
          renderInput={(params) => (
            <TextField
              label={t('orders:id')}
              type="text"
              {...params}
              onFocus={trackInputFocus({
                name: `order-id-filter-${filterKey}`,
              })}
              onBlur={trackInputBlur({
                name: `order-id-filter-${filterKey}`,
              })}
              inputProps={{
                ...params.inputProps,
                ['data-testid']: 'order-id-filter-input',
              }}
            />
          )}
        />
      ) : (
        <TextField
          label={t('orders:id')}
          data-testid="order-id-filter-input"
          value={idValue}
          onChange={(event) => {
            const value = event.currentTarget.value
            setQueryParams({ id: value, idType: typeValue }, hasApply !== true)
          }}
        />
      )}

      <TextField
        data-testid="filter-type-selector"
        onChange={(event) => {
          setQueryParams(
            { idType: event.target.value as IdType },
            hasApply !== true,
          )
        }}
        value={typeValue}
        SelectProps={{
          displayEmpty: true,
        }}
        select
        label="&#8203;"
      >
        {orderIdTypes?.map(({ label, value }) => (
          <MenuItem
            data-testid="order-id-type-option"
            key={value}
            value={value || ''}
            divider
            onClick={trackButtonClickEvent({
              name: 'order-search-filter-select-customer-detail-type',
              type: value,
            })}
          >
            <Typography variant="body02">{label}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </ListItem>
  )
}
