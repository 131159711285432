import { Box, Tooltip, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { Row } from '.'
import { getPaymentCardNumber } from '../../../utils/order'

type Params = GridRenderCellParams<Row, string | null | undefined>

export const PaymentsRenderer = (params: Params) => {
  const { payments } = params.row
  const MAX_LINES = 2

  const paymentRows = payments.map((payment) => {
    const cardNumber = getPaymentCardNumber(payment.additionaldata || {})

    return [payment.name, cardNumber].filter(Boolean).join(' - ')
  })

  const numHiddenItems = paymentRows.length - MAX_LINES

  return (
    <Tooltip
      title={paymentRows.map((row, index) => (
        <Box key={index}>{row}</Box>
      ))}
    >
      <Box className="MuiDataGrid-cellCaption">
        {paymentRows.slice(0, 2).map((paymentLabel, index) => {
          if (index === 1 && numHiddenItems > 0) {
            paymentLabel += `, +${numHiddenItems}`
          }

          return (
            <Box key={index}>
              <Typography>{paymentLabel}</Typography>
            </Box>
          )
        })}
      </Box>
    </Tooltip>
  )
}
