import { Autocomplete, FilterOptionsState, TextField } from '@mui/material'
import { Control, Controller, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SalesTax } from '../../../generated/graphql'
import { containsInvalidRule } from '../sales-tax/shared'
import { useSalesTaxFormatter } from '../sales-taxes/useSalesTaxFormatter'
import {
  BaseSalesTaxGroupContext,
  EMPTY_SALES_TAX,
  EMPTY_SALES_TAX_ID,
} from './shared'
import { useCallback, useMemo } from 'react'

type Props = {
  salesTaxes: SalesTax[]
  index: number
  onUpdate(index: number, salesTax: SalesTax): void
  control: Control<BaseSalesTaxGroupContext>
  selectedSalesTaxes: string[]
  isLoading: boolean
}

type OptionType = {
  id: number
  label: string
}

export const SalesTaxSelector = ({
  salesTaxes,
  index,
  onUpdate,
  control,
  selectedSalesTaxes,
  isLoading,
}: Props) => {
  const { t } = useTranslation(['shared', 'settings'])

  const { field } = useController({
    name: `salestaxes.${index}`,
    control,
  })

  const { formatNameAndTax } = useSalesTaxFormatter()

  const createFilterOptions = (
    options: OptionType[],
    state: FilterOptionsState<OptionType>,
  ) => {
    const nonSelectedOptions = options.filter(
      (option) => !selectedSalesTaxes.includes(option.label),
    )
    if (state.inputValue === '') return nonSelectedOptions

    const lowerCaseInput = state.inputValue.toLowerCase()
    return nonSelectedOptions.filter((opt) =>
      opt.label.toLowerCase().includes(lowerCaseInput),
    )
  }

  const allSalesTaxes = useMemo(() => {
    return [...salesTaxes, EMPTY_SALES_TAX]
  }, [salesTaxes])

  const options = useMemo(
    () =>
      allSalesTaxes.map((salesTax) => ({
        id: salesTax.id,
        label: salesTax.name,
      })),
    [allSalesTaxes],
  )

  const value = useMemo(
    () => options.find((salesTax) => salesTax.id === field.value.id) || null,
    [field.value.id, options],
  )

  const getOptionLabel = useCallback(
    (id: number) => {
      const option = allSalesTaxes.find(
        (salesTax) => salesTax.id === id,
      ) as SalesTax
      return formatNameAndTax(option.name, option.decimalvaluedefault)
    },
    [allSalesTaxes, formatNameAndTax],
  )

  const getSalesTax = (id?: number) => {
    if (!id) return EMPTY_SALES_TAX
    return allSalesTaxes.find((salesTax) => salesTax.id === id)
  }
  return (
    <Controller
      name={field.name}
      control={control}
      render={({ field, formState: { errors } }) => {
        const invalidRuleFound = containsInvalidRule(field.value)

        return (
          <Autocomplete
            value={value}
            options={options}
            fullWidth
            loading={isLoading}
            autoSelect
            filterOptions={createFilterOptions}
            getOptionDisabled={(option) =>
              option.id === EMPTY_SALES_TAX_ID ||
              containsInvalidRule(getSalesTax(option.id) as SalesTax)
            }
            noOptionsText={t('shared:no_options')}
            getOptionLabel={(option) =>
              option.id === EMPTY_SALES_TAX_ID
                ? option.label
                : getOptionLabel(option.id)
            }
            onChange={(_, data) =>
              onUpdate(index, getSalesTax(data?.id) as SalesTax)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                data-testid={`tax-group-sales-tax-input-${index}`}
                placeholder={t('settings:sales_tax_group.select_sales_tax')}
                error={invalidRuleFound || !!errors.salestaxes}
                helperText={
                  errors?.salestaxes?.root?.message ??
                  (invalidRuleFound &&
                    t('settings:sales_tax.invalid_rule_missing_product_group'))
                }
              />
            )}
          />
        )
      }}
    />
  )
}
