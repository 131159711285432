import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { JournalCart as JournalCartType } from '../../../generated/graphql'
import { JournalItem } from '.'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { JournalDivider } from './journal-divider'
import { useMoney } from '../../../hooks/money'
import { PrintContainer } from './print-container'

type Props = {
  journal: JournalItem
  data: JournalCartType
}

export const JournalCart = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal, data } = props
  const { formatDate } = useLocalizedDate()
  const getDate = (date: string) => formatDate(new Date(date))
  const { formatCurrency } = useMoney()

  return (
    <PrintContainer>
      <Box>{journal.datecreated ? getDate(journal.datecreated) : ''}</Box>

      <Box>
        <strong>
          {t('journals:journal_entry_id', {
            type: t(`journals:journal_entry_type_enum.${journal.type}`),
            id: journal.id,
          })}
        </strong>
      </Box>

      <Box>
        {data.staff} ({data.staffuserid})
      </Box>

      <Box>
        {t('journals:cart_id')}: {data.id}
      </Box>

      {journal?.ispractice && (
        <Box>
          {t('journals:is_practice')}: {t('shared:label.yes')}
        </Box>
      )}

      <Box>
        {t('journals:quantity_total')}: {data.quantitytotal}
      </Box>

      <Box>
        {t('journals:money_discount')}:{' '}
        {data.moneydiscount ? formatCurrency(data.moneydiscount) : '-'}
      </Box>

      <Box>
        {t('journals:money_total')}:{' '}
        {data.moneytotal ? formatCurrency(data.moneytotal) : '-'}
      </Box>

      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
