import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { EntryTypeFilter } from '../journal-list/entry-type-filter'
import { DateFilter, OptionType } from '../../../components/date-filter'
import { StoreIdFilter } from '../../../filters/store-filter'
import { CashRegisterIdFilter } from '../../../filters/cash-register-id-filter'

type JournalFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const JournalFilterViewPanel = (props: JournalFilterViewPanelProps) => {
  const { t } = useTranslation(['journals', 'shared'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('shared:menu.general')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <DateFilter
                defaultOptionType={OptionType.Today}
                hiddenOptions={[OptionType.AllTime]}
              />
            </ListItem>

            <ListItem>
              <StoreIdFilter />
            </ListItem>
            <ListItem>
              <CashRegisterIdFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('journals:page_title')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <EntryTypeFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
