import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  TextField,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { DeleteIcon, DragHandleIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ActiveVariantsForm } from '.'
import { useDrag } from '../../../../../hooks/drag'
import { addTagOnInputChange } from '../../../../../utils/autocomplete'

export type ActivateVariantsFirstStepProps = {
  onNext(): void
  onCancel(): void
  onSubmit(): void
}

type AddVariantGroup = {
  variantGroup: string
}

export const ActivateVariantsFirstStep = ({
  onNext,
  onCancel,
  onSubmit,
}: ActivateVariantsFirstStepProps) => {
  const { t } = useTranslation(['products', 'shared'])
  const addButtonRef = useRef<HTMLButtonElement>(null)
  const { register, handleSubmit, watch, reset, formState } =
    useForm<AddVariantGroup>({
      defaultValues: {
        variantGroup: '',
      },
    })

  const activeVariantsForm = useFormContext<ActiveVariantsForm>()
  const currentVariantGroups = activeVariantsForm.watch('variantGroups')
  const shouldGenerateVariants = !!activeVariantsForm.watch('generateVariants')

  const isAddVariantGroupFilled = !!watch('variantGroup')

  const possibleVariantsAmount = currentVariantGroups.reduce((p, c) => {
    const variants = c.options.length
    if (p === 0 && variants === 0) return p

    return (p === 0 ? 1 : p) * (variants === 0 ? 1 : variants)
  }, 0)

  const handleAddVariantGroup = (data: AddVariantGroup) => {
    if (!currentVariantGroups.find((x) => x.name === data.variantGroup)) {
      currentVariantGroups.push({
        name: data.variantGroup,
        options: [],
      })
      activeVariantsForm.setValue('variantGroups', currentVariantGroups)
    }

    reset({
      variantGroup: '',
    })
  }

  const deleteVariantGroup = (variantGroupName: string) => {
    activeVariantsForm.setValue(
      'variantGroups',
      currentVariantGroups.filter((x) => x.name !== variantGroupName),
    )
  }

  const { onDragEnd, onDragOver, onDragStart, dragId } = useDrag()

  return (
    <>
      <DialogTitle>
        {t('products:product_form.activate_variant_groups')}
      </DialogTitle>
      <DialogContent data-testid="activate-variants-dialog">
        <>
          <Grid
            container
            direction="row"
            sx={{
              mt: (theme) => theme.spacing(2),
              gap: (theme) => theme.spacing(1),
            }}
          >
            <Grid item flexGrow={1}>
              <TextField
                data-testid="add-variant-group"
                placeholder={t(
                  'products:product_form.variant_group_placeholder',
                )}
                {...register('variantGroup', {
                  required: t('shared:validation.field_required', {
                    field: t('products:product_form.variant_group'),
                  }),
                  validate: (v) =>
                    currentVariantGroups.map((x) => x.name).includes(v)
                      ? t('products:error.variant_group_already_exists')
                      : true,
                })}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    addButtonRef.current?.click()
                    event.preventDefault()
                  }
                }}
                error={!!formState.errors.variantGroup}
                helperText={formState.errors.variantGroup?.message}
              />
            </Grid>
            <Grid item>
              <Button
                ref={addButtonRef}
                disabled={!isAddVariantGroupFilled}
                color="primary"
                data-testid="add-variant-group-button"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleSubmit(handleAddVariantGroup)}
              >
                {t('shared:action.add')}
              </Button>
            </Grid>
          </Grid>

          {currentVariantGroups.length > 0 && (
            <Box
              sx={{
                m: (theme) => theme.spacing(2, -3, 0 - 3),
              }}
            >
              <Divider />
              {currentVariantGroups.map((variantGroup, index) => {
                const isLast = currentVariantGroups.length === index + 1
                return (
                  <Box
                    data-testid="variant-group-row"
                    key={variantGroup.name}
                    onDragStart={() => {
                      onDragStart(variantGroup.name)
                    }}
                    onDragEnd={() => {
                      onDragEnd()
                    }}
                    onDragOver={() => {
                      onDragOver(
                        index,
                        currentVariantGroups,
                        'name',
                        (newCurrentVariantGroups) =>
                          activeVariantsForm.setValue(
                            'variantGroups',
                            newCurrentVariantGroups,
                          ),
                      )
                    }}
                    className={variantGroup.name === dragId ? 'dragging' : ''}
                    sx={{
                      '&.dragging': {
                        backgroundColor: (theme) => theme.palette.blue20,
                        '.drag-helper': {
                          display: 'block',
                          mr: 2,
                        },

                        '.title': {
                          opacity: 0,
                        },
                      },

                      '.drag-helper': {
                        display: 'none',
                      },
                    }}
                  >
                    <ListItem
                      sx={{
                        backgroundColor: 'transparent',
                      }}
                    >
                      <Box
                        draggable
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          cursor: 'move',
                        }}
                      >
                        <DragHandleIcon
                          sx={{
                            color: (theme) => theme.palette.gray60,
                          }}
                        />

                        <Typography variant="body02" className="drag-helper">
                          {variantGroup.name}
                        </Typography>
                      </Box>
                      <Typography
                        data-testid="variant-group-name"
                        variant="body02"
                        sx={{ flexGrow: 1 }}
                        className="title"
                      >
                        {variantGroup.name}
                      </Typography>
                      <IconButton
                        data-testid="delete-variant-group-icon"
                        onClick={() => deleteVariantGroup(variantGroup.name)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                    <Autocomplete
                      autoHighlight
                      multiple
                      freeSolo
                      options={[]}
                      onChange={(_event, value) => {
                        const originalVariantGroup = currentVariantGroups.find(
                          (x) => x.name === variantGroup.name,
                        )
                        if (originalVariantGroup) {
                          originalVariantGroup.options = [...value]
                          activeVariantsForm.setValue(
                            'variantGroups',
                            currentVariantGroups,
                          )
                        }
                      }}
                      onInputChange={addTagOnInputChange}
                      value={variantGroup.options}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ px: 3, mb: 3 }} />
                      )}
                    />
                    {!isLast && <Divider />}
                  </Box>
                )
              })}
            </Box>
          )}
        </>
      </DialogContent>
      <DialogActions>
        {currentVariantGroups.length > 0 && possibleVariantsAmount > 0 && (
          <Box
            data-testid="generate-variants-checkbox"
            sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}
          >
            <Controller
              control={activeVariantsForm.control}
              name="generateVariants"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="generate-variants-checkbox"
                      checked={Boolean(field.value)}
                      onChange={(event) => {
                        field.onChange(event.target.checked)
                      }}
                    />
                  }
                  label={t('products:product_form.variant_group_checkbox', {
                    count: possibleVariantsAmount,
                  })}
                />
              )}
            />
          </Box>
        )}
        <Button
          color="secondary"
          data-testid="cancel-variant-activation-button"
          onClick={onCancel}
        >
          {t('shared:action.cancel')}
        </Button>
        <Button
          data-testid="activate-variant-groups-next-button"
          onClick={() =>
            possibleVariantsAmount > 0 && shouldGenerateVariants
              ? onNext()
              : onSubmit()
          }
          disabled={currentVariantGroups.length === 0}
        >
          {possibleVariantsAmount > 0 && shouldGenerateVariants
            ? t('shared:action.next')
            : t('shared:action.activate')}
        </Button>
      </DialogActions>
    </>
  )
}
