import { ListItem, ListItemProps, Skeleton } from '@mui/material'

type Props = ListItemProps & {
  isLoading?: boolean
  hide?: boolean
  childrenSkeleton?: boolean
}

export const ListItemTimeline = (props: Props) => {
  const { isLoading, hide, sx, childrenSkeleton, divider, ...listItemProps } =
    props
  return (
    <ListItem
      {...listItemProps}
      secondaryAction={
        isLoading && listItemProps.secondaryAction ? (
          <Skeleton width="min(10vw, 60px)" />
        ) : (
          listItemProps.secondaryAction
        )
      }
      sx={{
        display: hide ? 'none' : 'flex',
        justifyContent:
          childrenSkeleton && props.isLoading ? 'space-between' : 'flex-start',
        ...(divider
          ? {
              '&:after': {
                content: '""',
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                width: 'calc(100% - 50px)',
                bottom: '0',
                position: 'absolute',
                right: '0',
              },
            }
          : {}),
        ...sx,
      }}
    >
      {childrenSkeleton && props.isLoading ? (
        <Skeleton width="min(10vw, 100px)" />
      ) : (
        listItemProps.children
      )}
    </ListItem>
  )
}
