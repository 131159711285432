import { useQuery } from '@apollo/client'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import {
  GetShipmentsQuery,
  GetShipmentsQueryVariables,
  ShipmentState,
  GetShipmentsDocument,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { ShipmentIcon, Link } from '@sitoo/mui-components'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useMemo, useCallback, useContext } from 'react'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { DeleteShipment } from './bulk-actions/delete-shipment'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { FilterContext } from '../../../components/data-grid/context'

type Row = ArrayElement<GetShipmentsQuery['shipments']['items']>

type Props = {
  onDetail(shipmentId: string): void
  selectedId?: string
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const ORIGIN_FILTER_KEY = 'originWarehouseId'
export const DESTINATION_FILTER_KEY = 'destinationWarehouseId'

export const ShipmentsList = (props: Props) => {
  const { t } = useTranslation(['shipments', 'shared', 'filter', 'countries'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const apiRef = useGridApiRef()

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'id',
        minWidth: 120,
        headerName: t('shipments:id'),
      },
      {
        field: 'created_at',
        ...ColumnProps.date,
        headerName: t('shipments:created_at'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'modified_at',
        ...ColumnProps.date,
        headerName: t('shipments:modified_at'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'state',
        minWidth: 120,
        headerName: t('shipments:state'),
        valueGetter: (_value, row): string => {
          return t(`shipments:shipment_state.${row.state}`)
        },
      },
      {
        field: 'origin',
        minWidth: 200,
        headerName: t('shipments:origin'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.info?.origin?.address?.name,
          caption: row.info?.origin?.address?.address,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'destination',
        minWidth: 200,
        headerName: t('shipments:destination'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.info?.destination?.address?.name,
          caption: row.info?.destination?.address?.address,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'carrier',
        minWidth: 120,
        headerName: t('shipments:carrier'),
        valueGetter: (_value, row): CaptionRendererValue => {
          const carrier = row.info?.carrier
          const carrierName = carrier?.name ? `(${carrier?.name})` : ''

          return {
            title: [carrier?.reference, carrierName].join(' '),
            caption: carrier?.tracking_url ? (
              <Link
                href={carrier?.tracking_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {carrier?.tracking_url_title}
              </Link>
            ) : undefined,
          }
        },
        renderCell: CaptionRenderer,
      },
      {
        field: 'note',
        minWidth: 320,
        headerName: t('shipments:note'),
        valueGetter: (_value, row) => row.info?.note,
      },
    ],
    [formatDate, formatRelativeDate, t],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        pageSize: PAGE_SIZE,
      },
    }

    const originId = config.filter?.[ORIGIN_FILTER_KEY]?.value as number
    const originWarehouseId = originId ? [originId] : undefined
    const destinationId = config.filter?.[DESTINATION_FILTER_KEY]
      ?.value as number
    const destinationWarehouseId = destinationId ? [destinationId] : undefined

    const variables: GetShipmentsQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
      destinationWarehouseId,
      originWarehouseId,
    }

    const shipmentState = config.filter?.['shipmentState']
      ?.value as ShipmentState[]
    if (shipmentState) {
      variables.shipmentState = shipmentState
    }

    return variables
  }, [filter])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetShipmentsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || !isFilterReady

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel
    if (data?.shipments.next) {
      return fetchMore({
        variables: {
          start: (data?.shipments?.start || 0) + pageSize,
          next: data?.shipments.next,
        },
      })
    }
  }, [apiRef, data?.shipments.next, data?.shipments?.start, fetchMore])

  return (
    <DataGrid
      name="shipments-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.shipments.items || []}
      rowCount={
        data?.shipments.items?.length ? data.shipments.items.length + 1 : 0
      }
      onShowFilter={props.onShowFilter}
      loading={isLoading}
      onRowClick={(row) => props.onDetail(String(row.id))}
      fetchMore={fetchMoreItems}
      noRowsOverlay={{
        icon: <ShipmentIcon />,
        title: t('shipments:grid.empty_title'),
        description: t('shipments:grid.empty_description'),
      }}
      slots={{ pagination: CursorPagination }}
      bulkAction={<DeleteShipment />}
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      columnVisibilityModel={{ id: false }}
      showMore={!!data?.shipments.next}
      hideTotalCount
      getRowClassName={(params) => {
        const classes = []
        if (params.id === props.selectedId) {
          classes.push('Mui-selected')
        }

        if (
          [
            ShipmentState.Closed,
            ShipmentState.ClosedIncomplete,
            ShipmentState.Cancelled,
          ].includes(params.row.state)
        ) {
          classes.push('disabled')
        }

        return classes.join(' ')
      }}
    />
  )
}
