import { useQuery } from '@apollo/client'
import { InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  GetProductQuery,
  GetManufacturersDocument,
} from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { MAX_NUM_REQUEST } from '../../../../utils/constants'

export const BrandField = () => {
  const { register, formState, control } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const { data, loading } = useQuery(GetManufacturersDocument, {
    variables: { num: MAX_NUM_REQUEST },
  })
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const manufacturers = [
    { id: '', name: t('shared:label.none') },
    ...(data?.manufacturers.items || []),
  ]

  return (
    <FormFieldset
      label={t('products:product_form.manufacturer_fieldset')}
      isLoading={loading}
    >
      <Controller
        control={control}
        name="product.manufacturer.id"
        render={({ field }) => (
          <>
            <InputLabel>
              {t('products:product_form.manufacturer_id_label')}
            </InputLabel>
            <Select<number | string>
              data-testid="brand-selector"
              value={field.value || ''}
              onChange={(event) => {
                field.onChange(event.target.value)
              }}
              displayEmpty
              sx={{ mb: 2 }}
              disabled={!writeProducts}
            >
              {manufacturers.map(({ id, name }) => (
                <MenuItem value={id} data-testid="brand-option" key={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />

      <TextField
        data-testid="brand-sku-field"
        error={!!formState?.errors.product?.skumanufacturer}
        fullWidth
        label={t('products:product_form.manufacturer_sku_label')}
        helperText={t('products:product_form.manufacturer_sku_description')}
        {...register('product.skumanufacturer')}
        disabled={!writeProducts}
        sx={{ mb: 2 }}
      />

      <TextField
        data-testid="brand-url-field"
        error={!!formState?.errors.product?.manufacturerurl}
        fullWidth
        label={t('products:product_form.manufacturer_url_label')}
        helperText={t('products:product_form.manufacturer_url_description')}
        {...register('product.manufacturerurl')}
        disabled={!writeProducts}
      />
    </FormFieldset>
  )
}
