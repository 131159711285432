import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Divider, Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import {
  GetUserDocument,
  GetUserTagsDocument,
  MeDocument,
  UpdateUserDocument,
  UserPermissionsByIdDocument,
} from '../../../generated/graphql'
import { useMe } from '../../../hooks/me'
import { useServerValidation } from '../../../hooks/server-validation'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { ContactField } from '../../user/fields/contact-field'
import { DetailsField } from '../../user/fields/details-field'
import { LoginField } from '../../user/fields/login-field'
import { RolesField } from '../../user/fields/roles-field'
import { BaseUserContext } from '../../user/shared'
import { convertToUserRole } from '../../../utils/user-role-map'
import { TagsField } from '../../user/fields/tags-field'
import { TimeZoneField } from '../../user/fields/timezone-field'
import { formatUserPayload } from '../../user/format-user-payload'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'

export const SettingsProfile = () => {
  const { t } = useTranslation(['shared', 'users', 'login'])
  usePageTitle(t('shared:menu.profile'))
  const { me } = useMe()
  const { enqueueSnackbar } = useSnackbar()
  const email = String(me?.user?.email || '')
  const userId = me?.user?.id

  const formContext = useForm<BaseUserContext>()
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()

  const { data: permissionData, loading: permissionsLoading } = useQuery(
    UserPermissionsByIdDocument,
    {
      variables: { targetUserId: String(userId) },
      skip: !userId,
    },
  )

  const permissions = permissionData?.userPermissionsById

  const setFormError = useServerValidation<BaseUserContext>(
    'users',
    formContext.setError,
    {
      resolveFieldFromProperty: (property) =>
        property.split('.').pop() || property,
    },
  )

  const { data, loading: fetchLoading } = useQuery(GetUserDocument, {
    variables: { userId: String(userId) },
    skip: !userId,
  })

  const { data: userTags } = useQuery(GetUserTagsDocument, {
    variables: { tagIds: data?.user.tags },
    skip: !data?.user.tags,
  })

  const [updateUserMutation, { loading: isUpdatingUser }] = useMutation(
    UpdateUserDocument,
    { refetchQueries: [MeDocument] },
  )

  const isLoading = permissionsLoading || fetchLoading || isUpdatingUser

  useEffect(() => {
    if (data) {
      const { user } = data
      const roleFromMe = me?.role ? convertToUserRole(me.role) : undefined

      formContext.reset({
        ...user,
        role: user.role || roleFromMe,
        tags: userTags?.getUserTags,
      })
    }
  }, [data, formContext, me, userTags?.getUserTags])

  const onSubmit = async (formData: BaseUserContext) => {
    try {
      await updateUserMutation({
        variables: {
          data: { ...formatUserPayload(formData), userid: String(userId) },
        },
      })

      trackButtonClick({ name: 'profile-save' })
      trackFormSuccess({ name: 'profile' })
      enqueueSnackbar(t('users:user_message.success_update', { count: 1 }))
    } catch (error) {
      setFormError(error)
      const errorMessage = getErrorMessage(
        error,
        'users',
        t('users:user_message.failure_update', { count: 1 }),
      )

      trackFormError({
        name: 'users',
        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<BaseUserContext> = (_errors) => {
    enqueueSnackbar(t('users:error.generic'), {
      variant: 'error',
    })
  }

  return (
    <>
      <PageHeader
        title={t('shared:menu.profile')}
        rightColumn={
          <LoadingButton
            data-testid="save-changes"
            loading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(onSubmit, onError)}
          >
            {t('shared:action.save')}
          </LoadingButton>
        }
      />

      <Container>
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          <LoginField
            permissions={permissions}
            email={email}
            loading={isLoading}
            hasMfa={me?.user?.hasmfa}
            userId={me?.user?.id}
          />

          <Divider />
          <RolesField permissions={permissions} loading={isLoading} />

          <Divider />
          <ContactField permissions={permissions} />

          <Divider />
          <DetailsField permissions={permissions} />

          <Divider />
          <TagsField permissions={permissions} />

          <Divider />
          <TimeZoneField />
        </FormProvider>
      </Container>
    </>
  )
}
