import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { JournalStaff as JournalStaffType } from '../../../generated/graphql'
import { JournalItem } from '.'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { JournalDivider } from './journal-divider'
import { PrintContainer } from './print-container'

type Props = {
  journal: JournalItem
  data: JournalStaffType
}

export const JournalStaff = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal, data } = props
  const { formatDate } = useLocalizedDate()
  const getDate = (date: string) => formatDate(new Date(date))

  return (
    <PrintContainer>
      <Box>{journal.datecreated ? getDate(journal.datecreated) : ''}</Box>

      <Box>
        <strong>
          {t('journals:journal_entry_id', {
            type: t(`journals:journal_entry_type_enum.${journal.type}`),
            id: journal.id,
          })}
        </strong>
      </Box>

      <Box>
        {data.staff} ({data.staffuserid})
      </Box>

      {journal?.ispractice && (
        <Box>
          {t('journals:is_practice')}: {t('shared:label.yes')}
        </Box>
      )}

      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
