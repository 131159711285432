import { ApolloError, useLazyQuery } from '@apollo/client'
import { Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { FormPagePlaceholder } from '../../../components/form-page-placeholder'
import { PageHeader } from '../../../components/page-header'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import {
  GetShipmentV2Document,
  GetShipmentV2Query,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { extractGraphqlErrors } from '../../../utils/extract-graphql-errors'
import { Information } from '../information'
import { BaseShipmentFormContext } from '../shared'
import { containsDirtyFields } from '../../../utils/contains-dirty-fields'

export type EditShipmentFormContext = BaseShipmentFormContext &
  GetShipmentV2Query['getShipmentV2']

export const EditShipmentV2Page = () => {
  const { id } = useParams()
  const { t } = useTranslation(['shipments_v2', 'shared'])
  usePageTitle(t('shipments_v2:page_title_edit_shipment'))
  const navigate = useNavigate()
  const { track } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const [isInitialized, setInitialized] = useState(false)
  const shipmentId = Number(id)

  const onGetShipmentsError = (error: ApolloError) => {
    track('ShipmentV2FetchError', {
      category: 'ShipmentV2',
      name: 'ShipmentV2FetchError',
      ...extractGraphqlErrors(error)
        .map((error) => ({
          code: (error.extensions?.['code'] || error.extensions?.['code']) as
            | string
            | undefined,
          value: error.extensions?.['value'] as string | undefined,
          path: error.path?.join(' - '),
        }))
        .reduce(
          (p, c, i) => ({
            ...p,
            [`error.${i}.code`]: c.code,
            [`error.${i}.value`]: c.value,
            [`error.${i}.path`]: c.path,
          }),
          {},
        ),
    })

    const errorMessage = getErrorMessage(
      error,
      'shipments_v2',
      t('shipments_v2:shipment_message.failure_fetch'),
    )
    enqueueSnackbar(errorMessage, {
      variant: 'error',
    })
    navigate(generatePath(RootRoute.Shipments))
  }

  const [fetchShipment, { loading: isLoading, data: shipmentData }] =
    useLazyQuery(GetShipmentV2Document, {
      variables: { shipmentId: shipmentId },
      fetchPolicy: 'cache-and-network',
      onError: onGetShipmentsError,
    })

  const formContext = useForm<EditShipmentFormContext>()
  const { reset } = formContext

  const generatePath = useAbsolutePath()

  const formatShipmentData = (data: GetShipmentV2Query['getShipmentV2']) => {
    const {
      archived,
      comment,
      dateintransit,
      datenew,
      packages,
      shipmentid,
      shipmentstate,
      ...rest
    } = data
    return {
      archived,
      comment,
      dateintransit,
      datenew,
      packages,
      shipmentid,
      shipmentstate,
      senderAddress: {
        warehouseid: rest?.sender_warehouseid || undefined,
        address: rest?.sender_address || undefined,
        address2: rest?.sender_address2 || undefined,
        city: rest?.sender_city || undefined,
        countryid: rest?.sender_countryid || undefined,
        name: rest?.sender_name || undefined,
        state: rest?.sender_state || undefined,
        zip: rest?.sender_zip || undefined,
      },
      receiverAddress: {
        warehouseid: rest?.receiver_warehouseid || undefined,
        address: rest?.receiver_address || undefined,
        address2: rest?.receiver_address2 || undefined,
        city: rest?.receiver_city || undefined,
        countryid: rest?.receiver_countryid || undefined,
        name: rest?.receiver_name || undefined,
        state: rest?.receiver_state || undefined,
        zip: rest?.receiver_zip || undefined,
      },
      senderContact: {
        referenceemail: rest?.sender_referenceemail || undefined,
        referencename: rest?.sender_referencename || undefined,
        referencemobile: rest?.sender_referencemobile || undefined,
        instructions: rest?.sender_instructions || undefined,
      },
      receiverContact: {
        referenceemail: rest?.receiver_referenceemail || undefined,
        referencename: rest?.receiver_referencename || undefined,
        referencemobile: rest?.receiver_referencemobile || undefined,
        instructions: rest?.receiver_instructions || undefined,
      },
      carrier: {
        barcode: rest.barcode || undefined,
        carrier_name: rest.carrier_name || undefined,
        carrier_reference: rest.carrier_reference || undefined,
        carrier_trackingurl: rest.carrier_trackingurl || '',
        externalid: rest.externalid || undefined,
        dateestimateddelivery: rest.dateestimateddelivery || undefined,
        dateestimatedpickup: rest.dateestimatedpickup || undefined,
      },
    }
  }

  useEffect(() => {
    if (!shipmentId) {
      navigate(generatePath(RootRoute.Shipments))
      return
    }

    void fetchShipment({ variables: { shipmentId } }).then(({ data }) => {
      if (!data?.getShipmentV2) {
        navigate(generatePath(RootRoute.Shipments), {
          state: { ignoreLeavingGuard: true },
        })
        return
      }

      reset(
        {
          ...formatShipmentData(data?.getShipmentV2),
        },
        { keepDefaultValues: false },
      )
      setInitialized(true)
    })
  }, [fetchShipment, reset, generatePath, navigate, shipmentId])

  useEffect(() => {
    if (shipmentData) {
      reset(
        {
          ...formatShipmentData(shipmentData.getShipmentV2),
        },
        {
          keepDefaultValues: false,
        },
      )
    }
  }, [reset, shipmentData])

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={({ pathname, state }) =>
          pathname !== location.pathname &&
          !(state as Record<string, boolean> | undefined | null)?.[
            'ignoreLeavingGuard'
          ]
        }
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={
          String(shipmentId) || t('shipments_v2:page_title_edit_shipment_title')
        }
        backTo={generatePath(RootRoute.Shipments)}
        backText={t('shared:menu.shipments')}
        showBackButton={true}
        isSticky={true}
      />

      <Container data-testid="shipment-edit-page">
        <FormProvider {...formContext}>
          {!isInitialized ? <FormPagePlaceholder /> : <Information />}
        </FormProvider>
      </Container>
    </>
  )
}
