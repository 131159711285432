import {
  Box,
  MenuItem,
  Select,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import {
  generatePath,
  matchPath,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useMe } from '../../hooks/me'
import { useTracking } from '../../hooks/tracking'
import { RootRoute } from '../../pages'

type Props = {
  sx?: SxProps<Theme>
  isSidebar?: boolean
}

export const SiteSelector = (props: Props) => {
  const { me } = useMe()
  const { siteId: initialSiteId } = useParams()
  const [siteId, setSiteId] = useState<number | undefined>(
    initialSiteId ? Number(initialSiteId) : undefined,
  )
  const [open, setOpen] = useState(false)
  const { trackButtonClick } = useTracking()
  const location = useLocation()
  const navigate = useNavigate()

  const switchSite = (siteId: number) => {
    setSiteId(siteId)
    trackButtonClick({
      name: 'site-selector-switch-site',
      destinationSiteId: siteId,
    })

    let params: Params<string> = {}
    const currentRoute = Object.values(RootRoute).find((x) =>
      matchPath(x, location.pathname),
    )

    if (currentRoute) {
      const currentRouteMatch = matchPath(currentRoute, location.pathname)

      if (currentRouteMatch) params = currentRouteMatch.params
    }

    navigate(
      generatePath(currentRoute || RootRoute.Home, { ...params, siteId }),
    )
  }

  if (!me) {
    return null
  }

  return (
    <Box sx={props.sx}>
      {me.sites.length === 1 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            py: 0.5,
          }}
        >
          <Typography variant="body02">
            {me?.sites[0]?.eshopname || me?.sites[0]?.eshopid}
          </Typography>
        </Box>
      ) : (
        <Select
          value={siteId || me.siteId}
          onChange={(event) => {
            switchSite(Number(event.target.value))
          }}
          inputProps={{
            ['data-testid']: 'switch-site-selector-input',
          }}
          sx={{
            height: '100%',
            '.MuiInput-input:focus': {
              background: 'inherit',
            },
            '&::before, &:not(.Mui-disabled):hover::before': {
              borderBottomWidth: '1px',
            },
            '&::after': {
              border: 0,
            },
            ...(props.isSidebar
              ? {
                  background: 'inherit',
                  display: 'flex',
                  justifyContent: 'center',
                  color: (t) => t.palette.white,
                  '.MuiSelect-icon': {
                    color: (t) => t.palette.white,
                    position: 'relative',
                  },
                  '.MuiInput-input.MuiSelect-select': {
                    width: 'auto',
                    padding: (t) => t.spacing(1, 1, 1, 0),
                  },

                  '&::before, &:not(.Mui-disabled):hover::before': {
                    borderColor: (t) => t.palette.blue70,
                    borderBottomWidth: '2px',
                  },
                }
              : {}),
          }}
          data-testid="switch-site-selector"
          open={open}
          onOpen={() => setOpen(true)}
          onClick={(evt) => {
            if (evt.currentTarget.classList.contains('MuiBackdrop-root')) {
              setOpen(false)
            }
          }}
          onClose={() => setOpen(false)}
        >
          {me.sites.map((site) => (
            <MenuItem
              value={site.eshopid}
              key={site.eshopid}
              data-testid={`switch-site-button-${site.eshopid}`}
            >
              <Typography variant="body02">
                {site.eshopname || site.eshopid}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      )}
    </Box>
  )
}
