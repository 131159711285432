import { useQuery } from '@apollo/client'
import { Box, Button, Skeleton, Tab, Tabs, Chip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ProductViewPanelState } from '..'
import { RootRoute } from '../..'
import {
  ViewPanel,
  ViewPanelHeader,
  ViewPanelImage,
} from '../../../components/view-panel'
import {
  GetProductQuery,
  GetCampaignsWithProductAttributesDocument,
  GetProductDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { useMe } from '../../../hooks/me'
import { useTracking } from '../../../hooks/tracking'
import {
  CAMPAIGN_TYPE_PACKAGE,
  CAMPAIGN_TYPE_PRODUCT,
} from '../../../utils/campaigns'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import { Description } from './description'
import { InformationTab } from './information-tab'
import { RelatedTab } from './related-tab'
import { StockLevelTab } from './stock-level-tab'
import { VariantSelector } from './variant-selector'

enum ProductViewPanelTabs {
  Information,
  StockLevels,
  Related,
}

type ProductViewPanelProps = {
  open: boolean
  selectedProduct: ProductViewPanelState

  onClose: () => void
  onDetailProduct: (
    productId: number,
    siteId?: number,
    previous?: ProductViewPanelState,
  ) => void
}

export const ProductViewPanel = (props: ProductViewPanelProps) => {
  const generatePath = useAbsolutePath()
  const [product, setProduct] = useState<GetProductQuery['product']>()
  const { t } = useTranslation(['shared', 'products'])
  const panelRef = useRef<HTMLDivElement>(null)
  const [selectedTab, setSelectedTab] = useState(
    ProductViewPanelTabs.Information,
  )
  const { trackButtonClick } = useTracking()
  const {
    modules: { writeProducts },
  } = useAuthorization()
  const { me } = useMe()

  const previousSelectedProduct = props.selectedProduct.previous

  useEffect(() => {
    setSelectedTab(ProductViewPanelTabs.Information)

    panelRef.current?.scroll({ top: 0 })
  }, [props.selectedProduct.productId])

  const productResponse = useQuery(GetProductDocument, {
    variables: {
      id: props.selectedProduct.productId,
      productSiteId: props.selectedProduct.siteId,
      fallbackPriceToCommon: true,
    },
    skip: props.selectedProduct.productId === 0 || !props.open,
  })

  useEffect(() => {
    if (productResponse?.data?.product) {
      setProduct(productResponse.data?.product)
    }
  }, [productResponse])

  const campaignsResponse = useQuery(
    GetCampaignsWithProductAttributesDocument,
    {
      variables: {
        start: 0,
        num: MAX_NUM_REQUEST,
      },
      skip: !props.open,
    },
  )

  const campaigns = campaignsResponse.data?.campaigns
  const loading = productResponse?.loading
  const hasRelatedProducts =
    loading ||
    !!product?.related?.length ||
    !!product?.similar?.length ||
    !!product?.accessories?.length

  const matchesCampaign = campaigns?.items?.some((c) => {
    const isProductCampaign = CAMPAIGN_TYPE_PRODUCT.types.includes(
      c.vouchertype,
    )
    const isPackageCampaign = CAMPAIGN_TYPE_PACKAGE.types.includes(
      c.vouchertype,
    )
    if (!isProductCampaign && !isPackageCampaign) return false

    if (!product?.customattributes || !c.productattributes?.include)
      return false

    let hasCampaign = false
    const include = (c.productattributes?.include || {}) as Record<
      string,
      string[]
    >
    const includeProperties = Object.keys(include)

    //  First we check if the product custom attributes matches all include properties
    hasCampaign = includeProperties.every((v) =>
      include?.[v]?.includes(
        String((product.customattributes as Record<string, unknown>)?.[v]),
      ),
    )

    if (hasCampaign) {
      const exclude = (c.productattributes?.exclude || {}) as Record<
        string,
        string[]
      >
      const excludeProperties = Object.keys(exclude)

      //  If the product is included, we check if any property from exclude matches
      // In this case we remove the product from the campaign
      hasCampaign = !excludeProperties.some((v) =>
        exclude?.[v]?.includes(
          String((product.customattributes as Record<string, unknown>)?.[v]),
        ),
      )
    }

    // If even after the custom attributes validation it is still part of the package campaign
    // We check if the product ID is included in the package
    if (hasCampaign && isPackageCampaign) {
      hasCampaign =
        c.productoptions?.some((o) => o.products?.includes(product.sku)) ||
        false
    }

    return hasCampaign
  })

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={loading}
        title={product?.title || ''}
        section={t('products:title')}
        onClose={props.onClose}
        hideActionsSkeleton
        onBack={() => {
          trackButtonClick({ name: 'product-panel-go-back' })
          props.onDetailProduct(
            previousSelectedProduct?.productId || 0,
            previousSelectedProduct?.siteId,
            previousSelectedProduct?.previous,
          )
        }}
        showBackButton={!!previousSelectedProduct}
        actions={
          <>
            {writeProducts &&
              (props.selectedProduct.siteId === undefined ||
                me?.siteId === props.selectedProduct.siteId) && (
                <Button
                  component={Link}
                  to={generatePath(RootRoute.Product, {
                    id: String(props.selectedProduct.productId),
                  })}
                  color="secondary"
                  size="small"
                  data-testid="edit-product-button"
                  onClick={() => {
                    trackButtonClick({
                      name: 'product-panel-edit-product',
                      productId: props.selectedProduct.productId,
                    })
                  }}
                >
                  {t('shared:action.edit')}
                </Button>
              )}
          </>
        }
        tags={
          <>
            {product?.variant?.map((v, i) => (
              <Chip
                size="small"
                key={i}
                label={`${v.name || ''}: ${v.value || ''}`}
                color="blue"
              />
            ))}
            {matchesCampaign && (
              <Chip size="small" label={t('products:part_of_campaign')} />
            )}
          </>
        }
        sx={{
          mb: (theme) => theme.spacing(1.25),
        }}
      />
      <ViewPanelImage
        imageUrls={product?.productImages?.map((i) => i.fileUrl) || []}
        isLoading={loading}
      />
      <Description
        shortDescription={product?.descriptionshort}
        description={product?.description}
        isLoading={loading}
      />
      <VariantSelector
        data-testid="variant-selector"
        isLoading={loading && !product}
        onDetailProduct={props.onDetailProduct}
        selectedProduct={props.selectedProduct}
        product={product}
      />
      <Box
        sx={{
          marginTop: (theme) => theme.spacing(2),
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={(_event, value: number) => {
            trackButtonClick({
              name: 'product-panel-switch-tab',
              tab: value,
            })
            setSelectedTab(value)
          }}
        >
          <Tab
            data-testid="information-tab"
            label={
              loading ? (
                <Skeleton width="50%" />
              ) : (
                t('products:view_panel.tabs.information')
              )
            }
            value={ProductViewPanelTabs.Information}
            disabled={loading}
          />
          <Tab
            data-testid="stock-level-tab"
            label={
              loading ? (
                <Skeleton width="50%" />
              ) : (
                t('products:view_panel.tabs.stock_levels')
              )
            }
            value={ProductViewPanelTabs.StockLevels}
            disabled={loading}
          />
          {hasRelatedProducts && (
            <Tab
              data-testid="related-tab"
              label={
                loading ? (
                  <Skeleton width="50%" />
                ) : (
                  t('products:view_panel.tabs.related')
                )
              }
              value={ProductViewPanelTabs.Related}
              disabled={loading}
            />
          )}
        </Tabs>

        <Box
          role="tabpanel"
          hidden={selectedTab !== ProductViewPanelTabs.Information}
        >
          <InformationTab
            product={product}
            isLoading={loading}
            selectedProduct={props.selectedProduct}
          />
        </Box>
        <Box
          role="tabpanel"
          hidden={selectedTab !== ProductViewPanelTabs.StockLevels}
        >
          <StockLevelTab product={product} isLoading={loading} />
        </Box>
        {hasRelatedProducts && (
          <Box
            role="tabpanel"
            hidden={selectedTab !== ProductViewPanelTabs.Related}
          >
            <RelatedTab
              selectedProduct={props.selectedProduct}
              onDetailProduct={props.onDetailProduct}
            />
          </Box>
        )}
      </Box>
    </ViewPanel>
  )
}
