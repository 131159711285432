import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  DateRange,
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro'
import { CalendarIcon } from '@sitoo/mui-components'
import dayjs, { Dayjs } from 'dayjs'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../data-grid/context'
import { useDayJs } from '../../hooks/day-js'
import { useStateParams } from '../../hooks/state-params'
import { useTracking } from '../../hooks/tracking'
import { useLocalizedDate } from '../../hooks/localized-date'

type DateFilterProps = {
  defaultOptionType?: OptionType
  filterKey?: string
  hiddenOptions?: OptionType[]
  label?: string
}

type DateParam<TDefault = number> = TDefault | [start: string, end: string]

type CustomRangeForm = {
  start: Dayjs | null
  end: Dayjs | null
}

export enum OptionType {
  AllTime,
  Today,
  Last7Days,
  Last30Days,
  Last90Days,
  CustomRange,
}

export const DateFilter = (props: DateFilterProps) => {
  const { defaultOptionType = OptionType.Last30Days } = props

  const {
    hasApply,
    subscribeOnResetFilter,
    removeFilter,
    setFilter,
    registerFilter,
  } = useContext(FilterContext)
  const [isOpen, setIsOpen] = useState(false)
  const [customRangeSelected, setCustomRangeSelected] = useState(false)
  const { reset, handleSubmit, getValues, setValue } =
    useForm<CustomRangeForm>()
  const { trackButtonClickEvent, trackButtonClick } = useTracking()
  const dayJs = useDayJs()
  const { t } = useTranslation(['shared', 'filter'])
  const { getDateFormat, formatDate } = useLocalizedDate()

  const filterKey = props.filterKey || 'date'
  const label = props.label || t('filter:date_filter.label')
  const dateFormat = getDateFormat()

  const [queryParams, setQueryParams] = useStateParams()

  const options = useMemo(
    () =>
      [
        {
          text: t('filter:date_filter.all_time'),
          type: OptionType.AllTime,
          value: -1,
        },
        {
          text: t('filter:date_filter.today'),
          type: OptionType.Today,
          value: 0,
        },
        {
          text: t('filter:date_filter.last_x_days', { days: 7 }),
          type: OptionType.Last7Days,
          value: 7,
        },
        {
          text: t('filter:date_filter.last_x_days', { days: 30 }),
          type: OptionType.Last30Days,
          value: 30,
        },
        {
          text: t('filter:date_filter.last_x_days', { days: 90 }),
          type: OptionType.Last90Days,
          value: 90,
        },
        {
          text: t('filter:date_filter.custom_range'),
          type: OptionType.CustomRange,
          value: [],
        },
      ].filter((option) => !props.hiddenOptions?.includes(option.type)),
    [props.hiddenOptions, t],
  )

  const date = useMemo<DateParam>(() => {
    const queryParamDate = queryParams[filterKey] as DateParam<string>

    if (typeof queryParamDate === 'string') {
      return Number(queryParamDate)
    } else if (Array.isArray(queryParamDate)) {
      const startDate = queryParamDate[0]
      const endDate = queryParamDate[1]
      const isStartValid = startDate && dayJs(startDate).isValid()
      const isEndValid = endDate && dayJs(endDate).isValid()
      const isRangeValid = startDate <= endDate

      if (isStartValid && isEndValid && isRangeValid) {
        return [startDate, endDate]
      }
    }
    return options.find((o) => o.type === defaultOptionType)?.value as DateParam
  }, [dayJs, defaultOptionType, filterKey, options, queryParams])

  const selected = useMemo(
    () =>
      Array.isArray(date)
        ? OptionType.CustomRange
        : options.find((o) => o.value === date)?.type,
    [date, options],
  )

  const startDate = getValues('start') as Dayjs | string | null
  const endDate = getValues('end') as Dayjs | string | null

  const dateRangePickerValue: DateRange<Dayjs> = [
    startDate instanceof dayjs ? startDate?.utc() : null,
    endDate instanceof dayjs ? endDate.utc() : null,
  ]

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [filterKey, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
        setCustomRangeSelected(false)
        reset({ end: null, start: null })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [
    defaultOptionType,
    filterKey,
    reset,
    setQueryParams,
    subscribeOnResetFilter,
  ])

  useEffect(() => {
    if (Array.isArray(date)) {
      setCustomRangeSelected(true)
      reset({
        start: dayJs(date[0]),
        end: dayJs(date[1]),
      })
    }
  }, [date, dateFormat, dayJs, reset])

  useEffect(() => {
    const getRelativeDate = (date: number) => {
      return dayJs().startOf('day').subtract(date, 'day').utc().toJSON()
    }

    if (typeof date === 'number') {
      const option = options.find((o) => o.value === date)

      if (typeof option?.value === 'number') {
        const value =
          option.value === -1 ? undefined : getRelativeDate(option.value)

        setFilter(filterKey, {
          isDefault: option.type === defaultOptionType,
          label,
          labelValues: [option.text],
          value,
        })
      } else {
        const defaultOption = options.find((o) => o.type === defaultOptionType)
        setFilter(filterKey, {
          isDefault: true,
          label,
          labelValues: [defaultOption?.text || ''],
          value:
            typeof defaultOption?.value === 'number'
              ? getRelativeDate(defaultOption?.value)
              : [],
        })
      }
    } else if (Array.isArray(date)) {
      setFilter(filterKey, {
        label,
        labelValues: [
          [
            dayJs(date[0]).utc().format('ll'),
            dayJs(date[1]).utc().format('ll'),
          ].join(' - '),
        ],
        value: [date[0], date[1]],
      })
    }
  }, [
    removeFilter,
    setFilter,
    date,
    filterKey,
    dayJs,
    formatDate,
    options,
    t,
    defaultOptionType,
    label,
  ])

  const closeMenu = () => {
    setIsOpen(false)

    if (customRangeSelected && typeof date === 'number') {
      setCustomRangeSelected(false)
      reset({
        end: null,
        start: null,
      })
    }
  }

  const onSubmitCustomRange = (form: CustomRangeForm) => {
    setQueryParams(
      {
        [filterKey]: [dayJs(form.start).toJSON(), dayJs(form.end).toJSON()],
      },
      hasApply !== true,
    )

    setIsOpen(false)
  }

  return (
    <FormControl fullWidth>
      <InputLabel data-testid="date-filter-label" id="date-filter-label">
        {label}
      </InputLabel>
      <Select
        labelId="date-filter-label"
        value={selected}
        data-testid="date-filter-select-button"
        displayEmpty={true}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        sx={{
          'label + &': { mt: 0 },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          onClose: closeMenu,
        }}
      >
        {options.map(({ text, value, type }) => (
          <MenuItem
            key={type}
            value={type}
            data-testid="date-select-option"
            divider
            onClick={(event) => {
              event.stopPropagation()

              trackButtonClick({
                name: 'date-filter-select-date',
                text,
              })

              if (type === OptionType.CustomRange) {
                setCustomRangeSelected(true)
              } else if (typeof value === 'number') {
                setCustomRangeSelected(false)
                setQueryParams(
                  {
                    [filterKey]:
                      type === defaultOptionType ? undefined : String(value),
                  },
                  hasApply !== true,
                )
                closeMenu()
              }
            }}
          >
            <Typography variant="body02">{text}</Typography>
          </MenuItem>
        ))}

        {customRangeSelected && (
          <Box data-testid="date-picker-area" sx={{ p: 2 }}>
            <form>
              <DateRangePicker
                calendars={1}
                sx={{ pb: 2 }}
                defaultValue={dateRangePickerValue}
                timezone="UTC"
                label={[
                  t('filter:date_filter.start_date'),
                  t('filter:date_filter.end_date'),
                ].join(' – ')}
                onChange={(newValue) => {
                  setValue('start', dayJs(newValue[0]).startOf('day').utc())
                  setValue('end', dayJs(newValue[1]).endOf('day').utc())
                }}
                slots={{ field: SingleInputDateRangeField }}
                slotProps={{
                  textField: {
                    autoComplete: 'off',
                    InputProps: {
                      endAdornment: <CalendarIcon />,
                    },
                  },
                  fieldSeparator: {
                    hidden: true,
                  },
                  fieldRoot: {
                    direction: 'column',
                  },
                }}
                format={dateFormat}
              />

              <Button
                data-testid="apply-custom-date"
                type="button"
                fullWidth
                onClick={trackButtonClickEvent(
                  { name: `date-filter-custom-range-apply` },
                  handleSubmit(onSubmitCustomRange),
                )}
              >
                {t('shared:action.apply')}
              </Button>
            </form>
          </Box>
        )}
      </Select>
    </FormControl>
  )
}
