import { IconButton, Paper } from '@mui/material'
import { MouseEventHandler, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import { GetOrderDocument } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { Customer } from './customer'
import { Deliveries } from './delivery'
import { Details } from './details'
import { Information } from './information'
import { Payments } from './payments'
import { ReservedPayments } from './reserved-payments'
import { History } from './history'
import { MoreVerticalIcon, OrdersIcon } from '@sitoo/mui-components'
import { ReactiveVar, useQuery, useReactiveVar } from '@apollo/client'
import { DEFAULT_ORDER_ID } from '../../../utils/constants'
import { RelatedOrders } from './related-orders'
import { OrderViewPanelState } from '../util'
import { HeaderTags } from './header-tags'
import { OrderContextMenu } from '../order-context-menu'

type Props = {
  onClose: () => void
  viewPanelVar: ReactiveVar<OrderViewPanelState>
  readOnly?: boolean
}

export const OrderViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { isOpen, orderId, previous } = useReactiveVar(props.viewPanelVar)
  const { trackButtonClick } = useTracking()
  const { data, loading } = useQuery(GetOrderDocument, {
    variables: { orderId },
    skip: orderId === DEFAULT_ORDER_ID,
  })

  const order = data?.order

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isContextMenuOpen = !!anchorEl

  const onContextMenuClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isContextMenuOpen ? null : event.currentTarget)
  }

  return (
    <>
      <ViewPanel open={isOpen} ref={panelRef}>
        <ViewPanelHeader
          isLoading={loading}
          title={`#${order?.orderid || ''}`}
          section={t('orders:page_title')}
          onClose={props.onClose}
          hideActionsSkeleton
          onBack={() => {
            trackButtonClick({ name: 'order-panel-go-back' })
            props.viewPanelVar(previous)
          }}
          showBackButton={!!previous}
          sectionIcon={<OrdersIcon />}
          tags={<HeaderTags order={order} />}
          actions={
            <IconButton edge="end" onClick={onContextMenuClick}>
              <MoreVerticalIcon />
            </IconButton>
          }
        />

        <Paper data-testid="side-panel-container" elevation={0}>
          <Details
            order={order}
            isLoading={loading}
            readOnly={props.readOnly}
          />

          <ReservedPayments order={order} isLoading={loading} />

          <Payments order={order} isLoading={loading} />

          <Deliveries order={order} isLoading={loading} />

          <RelatedOrders
            order={order}
            isLoading={loading}
            viewPanelVar={props.viewPanelVar}
          />

          <Information
            order={order}
            isLoading={loading}
            readOnly={props.readOnly}
          />

          <Customer
            order={order}
            isLoading={loading}
            readOnly={props.readOnly}
          />

          <History orderId={order?.orderid} />
        </Paper>
      </ViewPanel>

      <OrderContextMenu
        anchorEl={anchorEl}
        isOpen={isContextMenuOpen}
        onClose={() => setAnchorEl(null)}
        selectedIds={[orderId]}
      />
    </>
  )
}
