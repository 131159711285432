import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../inputs/autocomplete-input'
import { FilterContext } from '../components/data-grid/context'
import { GetCampaignsDocument } from '../generated/graphql'
import { MAX_NUM_REQUEST } from '../utils/constants'
import { useFormFilter } from '../hooks/form-filter'
import { CAMPAIGN_TYPE_PRODUCT } from '../utils/campaigns'

export type CampaignIdFilter = {
  campaignIds?: number[]
}

export const CampaignIdFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'campaignIds'

  const { data, loading: isLoading } = useQuery(GetCampaignsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { num: MAX_NUM_REQUEST },
  })

  const options = useMemo(
    () =>
      [...(data?.campaigns.items || [])]
        .sort((a, b) => a.vouchername.localeCompare(b.vouchername))
        .filter((x) => CAMPAIGN_TYPE_PRODUCT.types.includes(x.vouchertype)),
    [data?.campaigns],
  )

  const { queryParams, formContext, resetForm } =
    useFormFilter<CampaignIdFilter>({
      formProps: {
        defaultValues: { campaignIds: [] },
      },
      parseOptions: {
        types: { campaignIds: (value) => value.split(',').map(Number) },
      },
    })

  const { campaignIds } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (isLoading) return

    if (campaignIds?.length) {
      setFilter(filterKey, {
        label: t('filter:campaign_id.label'),
        labelValues: campaignIds.map(
          (id) => options.find((x) => x.id === id)?.vouchername || String(id),
        ),
        value: campaignIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [options, campaignIds, removeFilter, setFilter, t, isLoading])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="campaignIds"
      control={formContext.control}
      options={options.map(({ id }) => id)}
      disabled={isLoading}
      label={t('filter:campaign_id.label')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'campaign-id-input' },
        },
      }}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) =>
          options.find((x) => x.id === option)?.vouchername || String(option),
      }}
    />
  )
}
