import {
  Button,
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ROOT_CATEGORY_ID } from '..'
import { Category } from '../../../../../generated/graphql'
import { useAuthorization } from '../../../../../hooks/authorization'
import { useTracking } from '../../../../../hooks/tracking'
import { formatCategoryName } from '../../../../../utils/format/category'

type Props = {
  open: boolean
  category?: Category | null
  categories: Category[]

  onClose: () => void
  onSuccess: (category: Category) => void
}

export const ProductCategoryDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'settings'])
  const { trackDialogOpen, trackDialogClose } = useTracking()
  const prevOpen = usePrevious(props.open)
  const dialogName = props.category ? 'edit-category' : 'add-category'
  const isNewCategory = !props.category
  const defaultValues: Omit<Category, 'id'> = {
    title: '',
    categoryparentid: ROOT_CATEGORY_ID,
  }
  const {
    modules: { writeSettingsCategoriesNavigation },
  } = useAuthorization()

  const categories = useMemo(() => {
    return props.categories
      .filter((c) => (props.category ? c.id !== props.category.id : true))
      .sort((a, b) => a.id - b.id)
  }, [props.categories, props.category])

  const formContext = useForm<Category>({ defaultValues })

  const submit = (data: Category) => {
    trackDialogClose({ name: dialogName })
    props.onSuccess(data)
  }

  useEffect(
    () => formContext.reset(props.category || {}),
    [formContext, props.category, props.open],
  )

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, dialogName])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        {!writeSettingsCategoriesNavigation
          ? t('settings:categories.view_category')
          : isNewCategory
            ? t('settings:categories.add_category')
            : t('settings:categories.edit_category')}
      </DialogTitle>

      <DialogContent>
        <TextField
          error={!!formContext.formState.errors.title}
          fullWidth
          helperText={formContext.formState.errors.title?.message}
          label={t('settings:categories.title')}
          {...formContext.register('title', {
            required: t('shared:validation.field_required', {
              field: t('settings:categories.title'),
            }),
          })}
          sx={{ mb: 2 }}
          inputProps={{ 'data-testid': 'category-title' }}
          disabled={!writeSettingsCategoriesNavigation}
        />
        <Controller
          control={formContext.control}
          name="categoryparentid"
          render={({ field }) => (
            <>
              <InputLabel>
                {t('settings:categories.parent_category')}
              </InputLabel>
              <Select
                value={field.value || ROOT_CATEGORY_ID}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                data-testid="category-parent-id"
                inputProps={{ 'data-testid': 'category-parent-id-input' }}
                disabled={!writeSettingsCategoriesNavigation}
              >
                {categories.map(({ id }) => (
                  <MenuItem
                    value={id}
                    key={id}
                    data-testid={`category-parent-option-${id}`}
                  >
                    {formatCategoryName(id, categories, true)}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}>
          <Controller
            control={formContext.control}
            name="visible"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      typeof field.value === 'boolean' ? field.value : true
                    }
                    onChange={(event) => {
                      field.onChange(event.target.checked)
                    }}
                    disabled={!writeSettingsCategoriesNavigation}
                  />
                }
                label={t('settings:categories.visible_label')}
              />
            )}
          />
        </Box>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-product-group"
        >
          {t(
            writeSettingsCategoriesNavigation
              ? 'shared:action.cancel'
              : 'shared:action.close',
          )}
        </Button>
        {writeSettingsCategoriesNavigation && (
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(submit)}
            data-testid="dialog-add-product-group"
          >
            {isNewCategory ? t('shared:action.add') : t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
