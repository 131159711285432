import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  List,
  ListItemText,
} from '@mui/material'
import { EmailIcon, PhoneIcon, SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetUserQuery } from '../../../generated/graphql'

type UserContactProps = {
  user?: GetUserQuery['user']
  isLoading: boolean
}

export const UserContact = ({ user, isLoading }: UserContactProps) => {
  const { t } = useTranslation(['shared', 'users'])

  const address = [
    user?.address,
    user?.address2,
    [user?.city, user?.state, user?.zip].filter(Boolean).join(', '),
    user?.countryid ? t(`countries:${user?.countryid.toUpperCase()}`) : '',
  ]
    .filter(Boolean)
    .join('\n')

  const isEmpty =
    !isLoading && !user?.email && !user?.phone && !user?.notes && !address

  if (isEmpty) return null

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="contact">
        <SectionHeader sx={{ p: 0 }}>{t('shared:label.contact')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={!isLoading && !user?.email}
            isLoading={isLoading}
            divider
            childrenSkeleton
            secondaryAction={
              <IconButton href={`mailto:${user?.email || ''}`}>
                <EmailIcon />
              </IconButton>
            }
          >
            <ListItemText
              data-testid="email-field"
              primary={t('users:email')}
              secondary={user?.email}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.mobile}
            isLoading={isLoading}
            divider
            childrenSkeleton
            secondaryAction={
              <IconButton href={`tel:${user?.mobile || ''}`}>
                <PhoneIcon />
              </IconButton>
            }
          >
            <ListItemText
              data-testid="mobile-field"
              primary={t('users:mobile')}
              secondary={user?.mobile}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.phone}
            isLoading={isLoading}
            divider
            childrenSkeleton
            secondaryAction={
              <IconButton href={`tel:${user?.phone || ''}`}>
                <PhoneIcon />
              </IconButton>
            }
          >
            <ListItemText
              data-testid="phone-field"
              primary={t('users:phone')}
              secondary={user?.phone}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !address}
            isLoading={isLoading}
            divider
            childrenSkeleton
          >
            <ListItemText
              data-testid="address-field"
              primary={t('users:address')}
              secondary={address}
            />
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
