import { Typography } from '@mui/material'
import { GridRenderCellParams, GridValueGetter } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { Row } from '.'
import { translateCampaignType } from '../i18n'

export const formatPriority = (
  priority?: number | null,
  opts?: { showNormal?: boolean; showPriorityValue?: boolean },
) => {
  if (priority === 1) {
    return t(
      opts?.showPriorityValue
        ? 'campaigns:campaign_priority.highest_value'
        : 'campaigns:campaign_priority.highest',
      { priority },
    )
  } else if (priority === 2) {
    return t(
      opts?.showPriorityValue
        ? 'campaigns:campaign_priority.high_value'
        : 'campaigns:campaign_priority.high',
      { priority },
    )
  } else if (priority === 3) {
    return opts?.showNormal
      ? t(
          opts?.showPriorityValue
            ? 'campaigns:campaign_priority.normal_value'
            : 'campaigns:campaign_priority.normal',
          { priority },
        )
      : ''
  } else if (priority === 4) {
    return t(
      opts?.showPriorityValue
        ? 'campaigns:campaign_priority.low_value'
        : 'campaigns:campaign_priority.low',
      { priority },
    )
  } else if (priority === 5) {
    return t(
      opts?.showPriorityValue
        ? 'campaigns:campaign_priority.lowest_value'
        : 'campaigns:campaign_priority.lowest',
      { priority },
    )
  }
}

export const typeAndPriorityValueGetter: GridValueGetter<Row> = (
  _value,
  row,
): string => {
  const { vouchertype, priority } = row
  const priorityText = formatPriority(priority)
  return `${translateCampaignType(vouchertype)}${
    priorityText ? ` (${priorityText})` : ''
  }`
}

export const typeAndPriorityRenderer = (
  params: GridRenderCellParams<TypeAndPriorityProps>,
): React.ReactElement => <TypeAndPriority {...params.row} />

export type TypeAndPriorityProps = Row

const TypeAndPriority = (props: TypeAndPriorityProps) => {
  const { vouchertype, priority } = props
  const translatedPriority = formatPriority(priority)
  return (
    <span>
      <span>{translateCampaignType(vouchertype)}</span>

      {translatedPriority && (
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="caption"
        >
          {' '}
          ({translatedPriority})
        </Typography>
      )}
    </span>
  )
}
