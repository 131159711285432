import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  isFullReturnOnly: boolean | undefined
}

export const FullReturnTag = (props: Props) => {
  const { t } = useTranslation(['orders'])
  const { isFullReturnOnly } = props

  if (!isFullReturnOnly) return null

  return (
    <Chip
      size="small"
      color="orange"
      label={t('orders:view_panel.full_return_only')}
    />
  )
}
