import { LoadingButton } from '@mui/lab'
import {
  Button,
  TextField,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../../../hooks/tracking'
import {
  UpdateOrderInput,
  UpdateOrderDocument,
} from '../../../../generated/graphql'
import { useMutation } from '@apollo/client'
import { CountriesInput } from '../../../../inputs/countries-input'

export type OrderCustomer = UpdateOrderInput

type Props = {
  open: boolean
  dataTestid?: string
  onClose(): void
  onSuccess?(): void
  onError?(): void
  orderId?: number
  order?: OrderCustomer
}

type Form = OrderCustomer

export const CustomerEditDialog = (props: Props) => {
  const { t } = useTranslation(['orders', 'shared'])
  const dialogName = 'order-customer-edit'
  const [updateOrderMutation, { loading }] = useMutation(UpdateOrderDocument)
  const isLoading = loading

  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const formContext = useForm<Form>({
    mode: 'onChange',
  })

  const onSubmit = async (order: Form) => {
    try {
      await updateOrderMutation({
        variables: {
          orderId: props.orderId || 0,
          data: order,
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })
      if (props.onSuccess) {
        props.onSuccess()
      }
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      if (props.onError) {
        props.onError()
      }
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      formContext.reset(props.order)
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      formContext.reset(props.order)
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, formContext, props.order])

  if (!props.order || !props.orderId) {
    return null
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formContext.handleSubmit(onSubmit)}
      >
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('orders:edit_customer_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <SectionHeader sx={{ pl: 0 }} variant="transparent">
            {t('orders:edit_customer_dialog.details')}
          </SectionHeader>
          <Stack spacing={2} mb={2}>
            <TextField
              label={t('orders:edit_customer_dialog.company')}
              error={!!formContext.formState.errors.company?.message}
              helperText={formContext.formState.errors.company?.message}
              slotProps={{ htmlInput: { ['data-testid']: 'customer-company' } }}
              {...formContext.register('company')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.first_name')}
              error={!!formContext.formState.errors.namefirst?.message}
              helperText={formContext.formState.errors.namefirst?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-first-name' },
              }}
              {...formContext.register('namefirst')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.last_name')}
              error={!!formContext.formState.errors.namelast?.message}
              helperText={formContext.formState.errors.namelast?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-last-name' },
              }}
              {...formContext.register('namelast')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.phone')}
              error={!!formContext.formState.errors.phone?.message}
              helperText={formContext.formState.errors.phone?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-phone' },
              }}
              {...formContext.register('phone')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.email')}
              error={!!formContext.formState.errors.email?.message}
              helperText={formContext.formState.errors.email?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-email' },
              }}
              {...formContext.register('email')}
            />
            <TextField
              label={t('orders:edit_customer_dialog.personal_id')}
              error={!!formContext.formState.errors.personalid?.message}
              helperText={
                formContext.formState.errors.personalid?.message ??
                t('orders:edit_customer_dialog.personal_id_helper')
              }
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-personalid' },
              }}
              {...formContext.register('personalid')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.customer_ref')}
              error={!!formContext.formState.errors.customerref?.message}
              helperText={formContext.formState.errors.customerref?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-customerref' },
              }}
              {...formContext.register('customerref')}
            />
          </Stack>
          <SectionHeader sx={{ pl: 0 }} variant="transparent">
            {t('orders:edit_customer_dialog.delivery_address')}
          </SectionHeader>
          <Stack spacing={2} mb={2}>
            <TextField
              label={t('orders:edit_customer_dialog.address')}
              error={!!formContext.formState.errors.delivery_address?.message}
              helperText={
                formContext.formState.errors.delivery_address?.message
              }
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-delivery-address' },
              }}
              {...formContext.register('delivery_address')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.address2')}
              error={!!formContext.formState.errors.delivery_address2?.message}
              helperText={
                formContext.formState.errors.delivery_address2?.message
              }
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-delivery-address-2' },
              }}
              {...formContext.register('delivery_address2')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.zip')}
              error={!!formContext.formState.errors.delivery_zip?.message}
              helperText={formContext.formState.errors.delivery_zip?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-delivery-zip' },
              }}
              {...formContext.register('delivery_zip')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.city')}
              error={!!formContext.formState.errors.delivery_city?.message}
              helperText={formContext.formState.errors.delivery_city?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-delivery-city' },
              }}
              {...formContext.register('delivery_city')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.state')}
              error={!!formContext.formState.errors.delivery_state?.message}
              helperText={formContext.formState.errors.delivery_state?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-delivery-state' },
              }}
              {...formContext.register('delivery_state')}
            />

            <CountriesInput
              control={formContext.control}
              name="delivery_countryid"
            />
          </Stack>
          <SectionHeader sx={{ pl: 0 }} variant="transparent">
            {t('orders:edit_customer_dialog.invoice_address')}
          </SectionHeader>
          <Stack spacing={2}>
            <TextField
              label={t('orders:edit_customer_dialog.address')}
              error={!!formContext.formState.errors.invoice_address?.message}
              helperText={formContext.formState.errors.invoice_address?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-invoice-address' },
              }}
              {...formContext.register('invoice_address')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.address2')}
              error={!!formContext.formState.errors.invoice_address2?.message}
              helperText={
                formContext.formState.errors.invoice_address2?.message
              }
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-invoice-address-2' },
              }}
              {...formContext.register('invoice_address2')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.zip')}
              error={!!formContext.formState.errors.invoice_zip?.message}
              helperText={formContext.formState.errors.invoice_zip?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-invoice-zip' },
              }}
              {...formContext.register('invoice_zip')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.city')}
              error={!!formContext.formState.errors.invoice_city?.message}
              helperText={formContext.formState.errors.invoice_city?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-invoice-city' },
              }}
              {...formContext.register('invoice_city')}
            />

            <TextField
              label={t('orders:edit_customer_dialog.state')}
              error={!!formContext.formState.errors.invoice_state?.message}
              helperText={formContext.formState.errors.invoice_state?.message}
              slotProps={{
                htmlInput: { ['data-testid']: 'customer-invoice-state' },
              }}
              {...formContext.register('invoice_state')}
            />

            <CountriesInput
              control={formContext.control}
              name="invoice_countryid"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            disabled={isLoading}
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            loading={isLoading}
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formContext.formState.isValid}
            data-testid="save-customer"
          >
            {t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
