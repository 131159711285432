import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  taxFreeId: string | undefined
}

export const TaxFreeTag = (props: Props) => {
  const { t } = useTranslation(['orders'])
  const { taxFreeId } = props

  if (!taxFreeId) return null

  return (
    <Chip
      size="small"
      color="grayLight"
      label={t('orders:view_panel.tax_free_label')}
    />
  )
}
