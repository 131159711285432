/* eslint-disable @typescript-eslint/no-misused-promises */
import LoadingButton from '@mui/lab/LoadingButton'
import { Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { Information } from '../information'
import { BaseShipmentFormContext } from '../shared'
import { useNewShipmentFormSubmit } from './submit'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'

export const NewShipmentV2Page = () => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  usePageTitle(t('shipments_v2:page_title_add_shipment'))

  const defaultValues: BaseShipmentFormContext = {
    packages: [],
    comment: '',
    carrier: {
      carrier_name: '',
      carrier_reference: '',
      carrier_trackingurl: '',
      barcode: '',
      externalid: '',
      dateestimatedpickup: '',
      dateestimateddelivery: '',
    },
    receiverAddress: {
      address: '',
      city: '',
      countryid: '',
      name: '',
      warehouseid: 0,
      zip: '',
    },
    receiverContact: {
      referenceemail: '',
      referencename: '',
      referencemobile: '',
      instructions: '',
    },
    senderAddress: {
      address: '',
      city: '',
      countryid: '',
      name: '',
      warehouseid: 0,
      zip: '',
    },
    senderContact: {
      referenceemail: '',
      referencename: '',
      referencemobile: '',
      instructions: '',
    },
  }

  const formContext = useForm<BaseShipmentFormContext>({
    defaultValues,
  })
  const { onSubmit, isSubmitting, onError } = useNewShipmentFormSubmit({
    setError: formContext.setError,
  })
  const generatePath = useAbsolutePath()

  return (
    <>
      <PageHeader
        title={t('shipments_v2:page_title_add_shipment')}
        backTo={generatePath(RootRoute.Shipments)}
        backText={t('shared:menu.shipments')}
        showBackButton={true}
        isSticky={true}
        rightColumn={
          <>
            <LoadingButton
              loading={isSubmitting}
              onClick={formContext.handleSubmit(onSubmit, onError)}
            >
              {t('shared:action.save')}
            </LoadingButton>
          </>
        }
      />

      <Container data-testid="shipment-add-page">
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          <Information />
        </FormProvider>
      </Container>
    </>
  )
}
