import { useEffect } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { useTracking } from '../../hooks/tracking'

export const TrackingProvider = () => {
  const { page: trackPage } = useTracking()

  const location = useLocation()
  const href = window.location.href
  const previousLocation = usePrevious(location)
  const previousHref = usePrevious(window.location.href)

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })

    void trackPage({
      path: location.pathname,
      search: location.search,
      hash: location.hash,
      url: href,
      previousPath: previousLocation?.pathname,
      previousSearch: previousLocation?.search,
      previousHash: previousLocation?.hash,
      previousUrl: previousHref,
    })
  }, [
    href,
    location.hash,
    location.pathname,
    location.search,
    previousHref,
    previousLocation?.hash,
    previousLocation?.pathname,
    previousLocation?.search,
    trackPage,
  ])

  return <Outlet />
}
