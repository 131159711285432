import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserTagsInput } from '../../../inputs/user-tags-input'
import { BaseUserContext } from '../shared'
import {
  GetUserTagsDocument,
  UserPermissionsByIdQuery,
} from '../../../generated/graphql'
import { useQuery } from '@apollo/client'
type Props = {
  permissions: UserPermissionsByIdQuery['userPermissionsById'] | undefined
}

export const TagsField = (props: Props) => {
  const { permissions } = props
  const { t } = useTranslation(['users'])
  const { control } = useFormContext<BaseUserContext>()
  const { data: userTagData } = useQuery(GetUserTagsDocument)

  return (
    <FormFieldset label={t('user_form.tags_fieldset')}>
      <UserTagsInput
        name="tags"
        control={control}
        disabled={!permissions?.editUser}
        userTags={userTagData?.getUserTags}
      />
    </FormFieldset>
  )
}
