import { Box, Typography, Divider, IconButton, Chip, Link } from '@mui/material'
import { FilterIcon } from '@sitoo/mui-components'
import { FilterPreset } from '../filter-preset'
import { Sort, SortItem } from './filters/sort'
import { TextFilter } from './filters/text-filter'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { FilterContext } from './context'
import { joinTags } from './utils/join-tags'
import { useSorting } from './hooks/sorting'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { DataGridMoreMenu } from '../data-grid-more-menu'

type Props = {
  showFilter: boolean
  hasTextFilter: boolean
  name: string
  textFilterPlaceHolder: string
  defaultSortingModel: SortItem[] | undefined
  onShowFilter?(): void
  sorting?: SortItem[]
}

export const Panel = (props: Props) => {
  const { t } = useTranslation()
  const {
    showFilter,
    hasTextFilter,
    name,
    textFilterPlaceHolder,
    defaultSortingModel,
    onShowFilter,
    sorting,
  } = props

  const apiRef = useGridApiContext()

  const { sorting: dataGridSorting, onSortChange } = useSorting({
    sorting: defaultSortingModel,
    apiRef,
  })

  const { filter: dataGridFilter, resetFilter } = useContext(FilterContext)

  const filterLabels = Object.entries(
    Object.entries(dataGridFilter).reduce(
      (acc, [filterKey, filter]) => {
        if (!filter?.label) return acc

        let label = filter.label

        if (filter.labelValues?.length) {
          label = `${filter.label}: ${joinTags(filter.labelValues)}`
        }

        acc[label] = {
          filterKeys: [...(acc[label]?.filterKeys || []), filterKey],
          isDefault: filter.isDefault,
        }

        return acc
      },
      {} as { [label: string]: { filterKeys: string[]; isDefault?: boolean } },
    ),
  )

  return (
    <Box>
      {showFilter && (
        <>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.background.page,
              display: 'flex',
              gap: '2px',
            }}
          >
            {hasTextFilter ? (
              <TextFilter name={name} placeholder={textFilterPlaceHolder} />
            ) : (
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.white,
                  flex: 1,
                  height: (theme) => theme.spacing(6),
                  display: 'flex',
                }}
              >
                <Divider
                  sx={{
                    flex: 1,
                    borderWidth: '1px',
                    alignSelf: 'flex-end',
                  }}
                />
              </Box>
            )}
            {onShowFilter && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <IconButton
                  sx={{
                    width: (theme) => theme.spacing(6.25),
                    backgroundColor: (theme) => theme.palette.white,
                    borderRadius: 0,
                    flexGrow: 1,
                  }}
                  onClick={onShowFilter}
                  data-testid="data-grid-filter-button"
                >
                  <FilterIcon />
                </IconButton>
                <Divider sx={{ borderWidth: '1px' }} />
              </Box>
            )}

            {(sorting || []).length > 0 && (
              <>
                <Sort
                  gridName={props.name}
                  initialSortItem={dataGridSorting[0]}
                  sortItems={sorting || []}
                  onSortChange={onSortChange}
                  sx={{
                    '&': (theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        margin: 0,
                      },
                    }),
                  }}
                />
              </>
            )}

            <DataGridMoreMenu showFilterPresets={!!onShowFilter} />
          </Box>
          {onShowFilter && (
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.background.default,
                p: (theme) => theme.spacing(1, 2),
              }}
            >
              {filterLabels.length > 0 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: (theme) => theme.spacing(0.5),
                    minHeight: (theme) => theme.spacing(3),
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: (theme) => theme.spacing(0.5),
                    }}
                  >
                    {filterLabels.map(([label, filter]) => (
                      <Chip
                        key={label}
                        size="small"
                        label={label}
                        color={filter.isDefault ? 'black' : 'gray'}
                        onDelete={
                          filter.isDefault
                            ? undefined
                            : () =>
                                filter.filterKeys.forEach(
                                  (key) => void resetFilter(key),
                                )
                        }
                      />
                    ))}
                  </Box>
                  {filterLabels.some(([, filter]) => !filter.isDefault) && (
                    <Link
                      onClick={() => void resetFilter()}
                      component="button"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: (theme) => theme.spacing(1.875),
                          lineHeight: (theme) => theme.spacing(2.5),
                          color: (theme) => theme.palette.primary.main,

                          '&:hover': {
                            color: (theme) => theme.palette.primary.dark,
                          },
                        }}
                      >
                        {t('shared:label.clear_all')}
                      </Typography>
                    </Link>
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: (theme) => theme.spacing(3),
                  }}
                >
                  <Typography variant="caption">
                    {t('shared:label.no_active_filter')}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  )
}
