import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetCashRegisterQuery } from '../../../generated/graphql'

type Props = {
  isLoading?: boolean
  cashRegister?: GetCashRegisterQuery['cashRegister']
}

export const Company = ({ cashRegister, isLoading }: Props) => {
  const { t } = useTranslation(['shared', 'cash_registers'])

  const address = [
    cashRegister?.address,
    cashRegister?.address2,
    [cashRegister?.city, cashRegister?.state, cashRegister?.zip]
      .filter(Boolean)
      .join(', '),
  ]

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="company">
        <SectionHeader sx={{ p: 0 }}>
          {t('cash_registers:view_panel.company')}
        </SectionHeader>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-last-active"
          >
            <ListItemText
              secondary={address.map((row, index) => (
                <Box component="span" key={index}>
                  {row}
                </Box>
              ))}
            >
              {cashRegister?.company || '-'}
            </ListItemText>
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
