import { useQuery } from '@apollo/client'
import { Autocomplete, SxProps, TextField } from '@mui/material'
import {
  FieldError,
  FieldValues,
  UseControllerProps,
  get,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AllStoreTagsDocument } from '../../generated/graphql'
import { addTagOnInputChange } from '../../utils/autocomplete'
import { Ref } from 'react'

type Props = {
  isRequired?: boolean
  sx?: SxProps
  placeholder?: string
  helperText?: string
  dataTestId?: string
  freeSolo?: true
  label?: string
  innerRef?: Ref<HTMLElement>
}

export const StoreTagsInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { rules, control, name, sx, placeholder, helperText, freeSolo } = props
  const { dataTestId = 'store-tags-field', innerRef } = props
  const { t } = useTranslation(['shared'])
  const { data } = useQuery(AllStoreTagsDocument)
  const { field, formState } = useController({ name, control, rules })

  const formError = get(formState.errors, name) as FieldError

  const formatValue = (tags: string[]) =>
    tags.filter((tag) => tag.length > 1).map((tag) => tag.toUpperCase())

  return (
    <Autocomplete
      ref={innerRef}
      autoSelect
      multiple
      freeSolo={freeSolo}
      sx={sx}
      options={data?.allStoreTags || []}
      onChange={(_event, value) => field.onChange(formatValue(value))}
      value={field.value || []}
      data-testid={dataTestId}
      onInputChange={addTagOnInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!formError}
          label={props.label}
          helperText={helperText}
          placeholder={placeholder || t('shared:label.add_tag')}
        />
      )}
    />
  )
}
