import { BaseMetadata, CustomTrack, ExtendableMetadata } from './types'

type FormMetadata = ExtendableMetadata & {
  // formName: string
}

type FormErrorMetadata = FormMetadata & {
  errorMessage?: string
}

const FORM_SUCCESS = 'FormSuccess'
const FORM_ERROR = 'FormError'
const FORM_CATEGORY = 'Form'

export const trackFormSuccess =
  (track: CustomTrack, baseMetadata: BaseMetadata) =>
  (metadata: FormMetadata) => {
    void track(FORM_SUCCESS, {
      ...baseMetadata,

      ...metadata,

      category: FORM_CATEGORY,
      label: metadata.name,
    })
  }

export const trackFormError =
  (track: CustomTrack, baseMetadata: BaseMetadata) =>
  (metadata: FormErrorMetadata) => {
    void track(FORM_ERROR, {
      ...baseMetadata,

      ...metadata,

      category: FORM_CATEGORY,
      label: metadata.name,
    })
  }
