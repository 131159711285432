import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { AllSitesDocument } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { useQuery } from '@apollo/client'

type QueryParamsState = {
  siteId?: string
}

export type SiteFilterContext = {
  siteId?: number
}

export const SitesFilter = () => {
  const { t } = useTranslation('settings')

  const ALL_SITES_ID = 0

  const { data: sitesData, loading: isLoading } = useQuery(AllSitesDocument)

  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext<FilterContext<SiteFilterContext>>(FilterContext)

  const { trackButtonClickEvent } = useTracking()

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const siteId = queryParams.siteId ? Number(queryParams.siteId) : undefined

  const options = sitesData?.allSites

  useEffect(() => {
    if (siteId) {
      setFilter('siteId', {
        label: t(`settings:store.site_id`),
        labelValues: [String(siteId)],
        value: siteId,
      })
    } else {
      setFilter('siteId', {
        isDefault: true,
        label: t(`settings:store.all_sites`),
        value: undefined,
      })
    }
  }, [siteId, removeFilter, setFilter, options, t])

  useEffect(() => {
    registerFilter({
      key: 'siteId',
      isReady: !isLoading,
    })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === 'siteId') {
        setQueryParams({ siteId: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  const selectedValue = useMemo(() => {
    return options?.find((o) => o.eshopid === siteId)?.eshopid || ALL_SITES_ID
  }, [options, siteId])

  return (
    <TextField
      onChange={(event) => {
        setQueryParams({ siteId: event.target.value || undefined })
      }}
      value={selectedValue}
      SelectProps={{
        displayEmpty: true,
      }}
      select
      label={t('settings:store.site_id')}
    >
      <MenuItem divider value={ALL_SITES_ID}>
        <Typography variant="body02">
          {t('settings:store.all_sites')}
        </Typography>
      </MenuItem>

      {options?.map(({ eshopname, eshopid }) => (
        <MenuItem
          key={eshopid}
          value={eshopid}
          divider
          onClick={trackButtonClickEvent({
            name: 'site-id-filter',
            type: eshopname,
          })}
        >
          <Typography variant="body02">{`#${eshopid} ${eshopname}`}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
