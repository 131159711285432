import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Box, ListItemText, Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../../components/page-header'
import { RouteLeavingGuard } from '../../../../components/route-leaving-guard'
import {
  AllProductGroupsQuery,
  SingleProductGroupInput,
  AddUpdateProductGroupsDocument,
  AllProductGroupsDocument,
  DeleteProductGroupsDocument,
} from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { usePageTitle } from '../../../../hooks/title'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'
import { ProductSettingsList } from '../product-settings-list'
import { ProductGroupDialog } from './product-group-dialog'
import { containsDirtyFields } from '../../../../utils/contains-dirty-fields'

type ProductGroupsForm = {
  productGroups: AllProductGroupsQuery['allProductGroups']
  deleteProductGroups: number[]
}

export const SettingsProductsProductGroupsPage = () => {
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.product_groups'))

  const {
    modules: { writeSettingsProductGroups },
  } = useAuthorization()

  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const formContext = useForm<ProductGroupsForm>({
    defaultValues: {
      deleteProductGroups: [],
      productGroups: [],
    },
  })
  const {
    fields,
    remove: removeProductGroup,
    update: updateProductGroup,
    append: appendProductGroup,
  } = useFieldArray({
    control: formContext.control,
    name: 'productGroups',
    keyName: 'vatid',
  })
  const { enqueueSnackbar } = useSnackbar()

  const [showDialog, setShowDialog] = useState(false)
  const [selectedProductGroup, setSelectedProductGroup] = useState<ArrayElement<
    AllProductGroupsQuery['allProductGroups']
  > | null>(null)
  const [allProductGroups, setAllProductGroups] = useState<
    AllProductGroupsQuery['allProductGroups']
  >([])

  const {
    loading: fetchLoading,
    data: productGroupsData,
    refetch,
  } = useQuery(AllProductGroupsDocument)

  useEffect(() => {
    setAllProductGroups(productGroupsData?.allProductGroups || [])
    formContext.reset({
      productGroups: productGroupsData?.allProductGroups,
      deleteProductGroups: [],
    })
  }, [formContext, productGroupsData])

  const edit = (
    productGroup: ArrayElement<AllProductGroupsQuery['allProductGroups']>,
  ) => {
    trackButtonClick({ name: 'settings-product-group-edit' })

    setSelectedProductGroup(productGroup)
    setShowDialog(true)
  }

  const add = () => {
    trackButtonClick({ name: 'settings-product-group-add' })

    setSelectedProductGroup(null)
    setShowDialog(true)
  }

  const remove = (
    productGroup: ArrayElement<AllProductGroupsQuery['allProductGroups']>,
    index: number,
  ) => {
    trackButtonClick({
      name: 'settings-product-group-remove',
      productGroupId: productGroup.id,
    })

    removeProductGroup(index)

    if (productGroup.__typename) {
      formContext.setValue('deleteProductGroups', [
        ...formContext.getValues('deleteProductGroups'),
        productGroup.id,
      ])
    }
  }

  const [
    addUpdateProductGroupsMutation,
    { loading: addUpdateProductGroupsLoading },
  ] = useMutation(AddUpdateProductGroupsDocument)

  const [deleteProductGroupsMutation, { loading: deleteProductGroupsLoading }] =
    useMutation(DeleteProductGroupsDocument)

  const isLoading =
    fetchLoading || addUpdateProductGroupsLoading || deleteProductGroupsLoading

  const onSubmit = async (data: ProductGroupsForm) => {
    try {
      trackButtonClick({
        name: 'settings-product-group-save',
      })
      let success = false

      if (data.productGroups.length > 0) {
        const { data: updateResponse } = await addUpdateProductGroupsMutation({
          variables: {
            data: data.productGroups.map(
              ({
                name,
                type,
                id,
                __typename,
                ...x
              }): SingleProductGroupInput => ({
                ...x,
                productgroupname: name,
                productgrouptype: type,
                vatid: __typename ? id : undefined,
              }),
            ),
          },
        })

        success = !!updateResponse?.addUpdateProductGroups.some(
          (x) => x.success === true,
        )

        const allFailedProductGroups =
          updateResponse?.addUpdateProductGroups.filter(
            (x) => x.success === false,
          ) || []

        if (allFailedProductGroups.length > 0) {
          enqueueSnackbar(
            t('settings:product_group.error.product_group_add_update_generic', {
              count: allFailedProductGroups.length,
              productGroup: allFailedProductGroups
                .map((x) => x.productGroup.name)
                .join(', '),
            }),
            { variant: 'error' },
          )
        }
      }

      if (data.deleteProductGroups.length > 0) {
        const { data: deleteResponse } = await deleteProductGroupsMutation({
          variables: {
            data: {
              ids: data.deleteProductGroups,
            },
          },
        })

        if (!success) {
          success = !!deleteResponse?.deleteProductGroups.some(
            (x) => x.success === true,
          )
        }

        const allFailedProductGroups =
          deleteResponse?.deleteProductGroups.filter(
            (x) => x.success === false,
          ) || []

        const inUseFailedProductGroups = allFailedProductGroups?.filter(
          (x) => x.error?.code === 'PRODUCT_GROUP_IN_USE',
        )
        if (inUseFailedProductGroups.length > 0) {
          enqueueSnackbar(
            t('settings:product_group.error.product_group_in_use', {
              count: inUseFailedProductGroups.length,
              productGroup: inUseFailedProductGroups
                .map(
                  (x) =>
                    allProductGroups.find((y) => y.id === x.productGroupId)
                      ?.name,
                )
                .join(', '),
            }),
            {
              variant: 'error',
            },
          )
        }

        const otherFailedProducts = allFailedProductGroups.filter(
          (x) =>
            !inUseFailedProductGroups.find(
              (y) => y.productGroupId === x.productGroupId,
            ),
        )
        if (otherFailedProducts.length > 0) {
          enqueueSnackbar(
            t('settings:product_group.error.product_group_delete_generic', {
              count: otherFailedProducts.length,
              productGroup: otherFailedProducts
                .map(
                  (x) =>
                    allProductGroups.find((y) => y.id === x.productGroupId)
                      ?.name,
                )
                .join(', '),
            }),
            { variant: 'error' },
          )
        }
      }

      if (success) {
        trackFormSuccess({
          name: 'settings-product-group',
        })
        enqueueSnackbar(
          t('settings:product_group.success_saved', {
            count: data.deleteProductGroups.length + data.productGroups.length,
          }),
        )
      }

      await refetch()
    } catch {
      trackFormError({
        name: 'settings-product-group',
      })
    }
  }

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={() => writeSettingsProductGroups}
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shared:menu.product_groups')}
        rightColumn={
          <>
            {writeSettingsProductGroups && (
              <LoadingButton
                disabled={!formContext.formState.isDirty}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={formContext.handleSubmit(onSubmit)}
                data-testid="save-changes"
                loading={isLoading}
              >
                {t('shared:action.save')}
              </LoadingButton>
            )}
          </>
        }
      />
      <Container>
        <Box
          sx={{
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <ProductSettingsList
            items={fields}
            baseDataTestId="product-group"
            addText={t('settings:product_group.add_product_group')}
            loading={isLoading}
            canAdd
            onAdd={add}
            onDelete={(item, index) => remove(item, index)}
            onEdit={(item) => edit(item)}
            readOnly={!writeSettingsProductGroups}
            itemRender={(x) => (
              <ListItemText
                secondary={`${x.value || 0}% • ${
                  x.type ? t(`settings:product_group.vat_type.${x.type}`) : ''
                }${x.comment ? `\n${x.comment}` : ''}`}
                secondaryTypographyProps={{
                  sx: {
                    whiteSpace: 'break-spaces',
                  },
                }}
              >
                {x.name}
              </ListItemText>
            )}
            emptyTitle={t('settings:product_group.empty_title')}
            emptyDescription={t('settings:product_group.empty_description')}
          />
        </Box>

        <ProductGroupDialog
          open={showDialog}
          productGroup={
            selectedProductGroup !== null ? selectedProductGroup : undefined
          }
          onClose={() => setShowDialog(false)}
          onSuccess={(productGroup) => {
            if (selectedProductGroup !== null) {
              updateProductGroup(
                fields.findIndex((x) => x.id === selectedProductGroup.id),
                productGroup,
              )
              setSelectedProductGroup(null)
            } else {
              appendProductGroup({
                ...productGroup,
                id: Math.max(...fields.map(({ id }) => id)) + 1,
              })
            }

            setShowDialog(false)
          }}
        />
      </Container>
    </>
  )
}
