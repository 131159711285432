import { PermissionRole, UserRole } from '../../generated/graphql'

/**
 * Converts a permission role to a user role.
 *
 * @param {PermissionRole} role - The permission role to convert.
 * @returns {UserRole} The corresponding user role.
 */
export const convertToUserRole = (
  role: PermissionRole | undefined,
): UserRole => {
  switch (role) {
    case PermissionRole.SaGlobal:
    case PermissionRole.SaLocal:
    case PermissionRole.Admin:
      return UserRole.Admin
    case PermissionRole.Staff:
      return UserRole.Staff
    case PermissionRole.StoreManager:
      return UserRole.StoreManager
    default:
      return UserRole.StoreStaff
  }
}
