import { ListItemButton, ListItemButtonProps, Skeleton } from '@mui/material'
import { forwardRef } from 'react'

type Props = ListItemButtonProps & {
  isLoading?: boolean
  hide?: boolean
}

export const ListItemButtonSkeleton = forwardRef<HTMLDivElement, Props>(
  function ListItemButtonSkeleton(props, ref) {
    const { isLoading, hide, sx, ...listItemProps } = props

    if (hide) return null

    return (
      <ListItemButton
        ref={ref}
        {...listItemProps}
        sx={{
          display: 'flex',
          justifyContent: props.isLoading ? 'space-between' : 'flex-start',
          ...sx,
        }}
      >
        {props.isLoading ? (
          <Skeleton width="min(10vw, 100px)" />
        ) : (
          listItemProps.children
        )}
      </ListItemButton>
    )
  },
)
