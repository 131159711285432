import { IconButton } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { DeleteIcon } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteShipmentDialog } from '../../delete-shipment-dialog'
import { shipmentViewPanelVar } from '../..'

export const DeleteShipment = () => {
  const { t } = useTranslation(['shipments'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const selectedIds = Array.from(
    apiRef.current.getSelectedRows().keys(),
  ) as string[]

  const onClose = () => {
    setIsDialogOpen(false)
  }

  const onSuccess = (deletedIds: string[]) => {
    if (deletedIds.length) {
      enqueueSnackbar(
        t('shipments:success_delete_shipment_snackbar', {
          count: deletedIds.length,
        }),
      )
    }
    shipmentViewPanelVar({ isOpen: false, shipmentId: undefined })
    onClose()
  }

  const onError = () => {
    onClose()
  }

  return (
    <>
      <DeleteShipmentDialog
        open={isDialogOpen}
        onClose={onClose}
        selectedIds={selectedIds}
        onSuccess={onSuccess}
        onError={onError}
      />
      <IconButton
        onClick={() => setIsDialogOpen(true)}
        data-testid="bulk-delete-shipments"
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}
