import { css, Theme } from '@mui/material/styles'

export const SnackbarStyles = (theme: Theme) => css`
  .notistack-SnackbarContainer .notistack-Snackbar {
    min-width: auto;
  }

  .notistack-MuiContent {
    font-size: ${theme.typography.body01.fontSize};
    font-family: ${theme.typography.body01.fontFamily};
    font-weight: ${theme.typography.body01.fontWeight};
    line-height: ${theme.typography.body01.lineHeight};
    border-radius: 2px;
    min-width: auto;
    text-align: center;
    max-width: 600px;
    padding: 3px 16px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  }

  .notistack-MuiContent .MuiSvgIcon-root {
    margin-right: 8px;
  }

  .notistack-MuiContent-error {
    background-color: ${theme.palette.error.main};
  }

  .notistack-MuiContent-success {
    background-color: ${theme.palette.primary.light};
  }

  .notistack-MuiContent-warning {
    background-color: ${theme.palette.warning.main};
  }
`
