import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
  SxProps,
  Theme,
} from '@mui/material'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'

type Props = SwitchProps & {
  label?: string
  labelPlacement?: FormControlLabelProps['labelPlacement']
  slotProps?: FormControlLabelProps['slotProps']
  sx?: SxProps<Theme>
}

export const SwitchInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const {
    control,
    name,
    disabled,
    label = '',
    labelPlacement = 'start',
    sx,
    slotProps,
    ...switchProps
  } = props

  const { field, formState } = useController({ control, name, disabled })

  const isDisabled = field.disabled || formState.isSubmitting

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      sx={{ mx: 0, ...sx }}
      slotProps={slotProps}
      disabled={isDisabled}
      control={
        <Switch
          {...switchProps}
          {...field}
          onChange={(event, value) => {
            props.onChange?.(event, value)
            field.onChange(event, value)
          }}
          checked={!!field.value}
          disabled={isDisabled}
        />
      }
    />
  )
}
