import { Box, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { useMoney } from '../../../../hooks/money'
import { formatAdditionalData } from '../../../../utils/format/additional-data'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalCartPayment' }
  >
}

export const CartPaymentDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props

  const { formatCurrency } = useMoney()
  const additionalData = formatAdditionalData(journal?.additionaldata)

  return (
    <>
      <ListItemSkeleton
        hide={!journal?.cart_id}
        childrenSkeleton
        divider
        data-testid="journal-id"
      >
        <ListItemText secondary={journal?.cart_id}>
          {t('journals:cart_id')}
        </ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={journal?.payment_index === undefined}
        secondaryAction={<ListItemText>{journal?.payment_index}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-payment_index"
      >
        <ListItemText>{t('journals:payment_index')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.paymentname}
        secondaryAction={<ListItemText>{journal?.paymentname}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-paymentname"
      >
        <ListItemText>{t('journals:payment_name')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={journal?.paymentmethod === undefined}
        secondaryAction={
          <ListItemText>
            {journal?.paymentmethod
              ? t(`journals:payment_method_enum.${journal?.paymentmethod}`)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-paymentmethod"
      >
        <ListItemText>{t('journals:payment_method')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.moneytotal}
        secondaryAction={
          <ListItemText>
            {journal?.moneytotal ? formatCurrency(journal?.moneytotal) : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-moneytotal"
      >
        <ListItemText>{t('journals:money_total')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.moneycaptured}
        secondaryAction={
          <ListItemText>
            {journal?.moneycaptured
              ? formatCurrency(journal?.moneycaptured)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-moneycaptured"
      >
        <ListItemText>{t('journals:money_captured')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={additionalData.length === 0}
        childrenSkeleton
        divider
        data-testid="journal-additionaldata"
      >
        <ListItemText
          secondary={additionalData?.map(([key, value]) => (
            <Box key={key} component="span" sx={{ display: 'block' }}>
              {key}: {value}
            </Box>
          ))}
        >
          {t('journals:additional_data')}
        </ListItemText>
      </ListItemSkeleton>
    </>
  )
}
