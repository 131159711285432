import { PropsWithChildren, useEffect, useMemo } from 'react'
import {
  I18nextProvider as OriginalI18nextProvider,
  I18nextProviderProps,
} from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import { FALLBACK_LANGUAGE, defaultNS, getI18nLoadPath } from '../../i18n'
import { createInstance, i18n as I18N } from 'i18next'

type Props = PropsWithChildren & {
  language: string | undefined
  onReady?: (i18n: I18nextProviderProps['i18n']) => void
}

/*
 * The provider is needed in order to override i18n instance for some specific
 * components
 */
export const I18nextProvider = (props: Props) => {
  const i18n = useMemo(() => {
    const _i18next = createInstance().use(HttpBackend)
    const language = props.language

    void _i18next.init({
      lng: language,
      defaultNS,
      fallbackLng: FALLBACK_LANGUAGE,
      debug: false,
      ns: ['shared'],
      react: {
        // if `true` then leads to unexpected rerenders
        useSuspense: false,
      },

      interpolation: {
        // React already does escaping
        escapeValue: false,
      },

      backend: {
        loadPath: getI18nLoadPath(),
      },
    })

    return _i18next
  }, [props.language])

  useEffect(() => {
    i18n.on('loaded', () => {
      // Delay for one more tick unil the translations are rendered
      setTimeout(() => {
        props.onReady?.(i18n as I18N)
      }, 0)
    })
  }, [i18n, props])

  return (
    <OriginalI18nextProvider i18n={i18n as I18N}>
      {props.children}
    </OriginalI18nextProvider>
  )
}
