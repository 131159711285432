import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { SalesTaxGroupQuery } from '../../../../generated/graphql'

type Props = {
  salesTaxGroup?: SalesTaxGroupQuery['salesTaxGroup']
}

export const Information = ({ salesTaxGroup }: Props) => {
  const { t } = useTranslation(['settings', 'shared'])

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>
          {t('shared:label.information')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={!salesTaxGroup?.name}
            divider
            secondaryAction={
              <ListItemText
                data-testid="sales-tax-group-region"
                primary={salesTaxGroup?.region}
              />
            }
          >
            <ListItemText
              data-testid="sales-tax-group-region-label"
              primary={t('settings:sales_tax_group.region')}
            />
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
