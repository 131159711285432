import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { ReasonCodeType } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  reasonCodeType?: string
}

export type ReasonCodeFilterContext = {
  reasonCodeType?: ReasonCodeType
}

export const ReasonCodeFilter = () => {
  const { t } = useTranslation('settings')
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'reasonCodeType'

  const options = useMemo(() => {
    return Object.values(ReasonCodeType).map((value) => ({
      value,
      name: t(`settings:reason_codes.reason_code_types.${value}`),
    }))
  }, [t])

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const reasonCodeType = queryParams[filterKey]
    ? queryParams[filterKey]
    : undefined

  useEffect(() => {
    if (reasonCodeType) {
      setFilter(filterKey, {
        label: t(`settings:reason_codes.reason_code_types.${reasonCodeType}`),
        value: reasonCodeType,
      })
    } else {
      setFilter(filterKey, {
        isDefault: true,
        label: t(`settings:reason_codes.all_types`),
        value: undefined,
      })
    }
  }, [reasonCodeType, removeFilter, setFilter, options, t])

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  const selectedValue = useMemo(() => {
    return reasonCodeType
      ? options.find((o) => String(o.value) === String(reasonCodeType))?.value
      : ''
  }, [options, reasonCodeType])

  return (
    <TextField
      onChange={(event) => {
        setQueryParams({ [filterKey]: event.target.value || undefined })
      }}
      value={selectedValue}
      SelectProps={{
        displayEmpty: true,
      }}
      select
      label={t('settings:reason_codes.type')}
    >
      <MenuItem divider value="">
        <Typography variant="body02">
          {t('settings:reason_codes.all_types')}
        </Typography>
      </MenuItem>
      {options.map(({ name, value }) => (
        <MenuItem
          key={String(value)}
          value={value}
          divider
          onClick={trackButtonClickEvent({
            name: 'reason-codes-state-filter',
            type: name,
          })}
        >
          <Typography variant="body02">{name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
