import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Grid2 as Grid, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FilterProvider } from '../../components/data-grid/context'
import { useFilterContext } from '../../components/data-grid/hooks/use-filter-context'
import { PageHeader } from '../../components/page-header'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { DEFAULT_SERVICE_ORDER_ID } from '../../utils/constants'
import {
  ServiceOrderFilterContext,
  ServiceOrderFilterPanel,
} from './service-order-filter-panel'
import { ServiceOrderViewPanel } from './service-order-view-panel'
import { ServiceOrdersList } from './service-orders-list'

import { RootRoute } from '..'
import { useAbsolutePath } from '../../hooks/absolute-path'

export type ViewPanelState = {
  isOpen: boolean
  serviceOrderId: string
  previous?: ViewPanelState
}

export const serviceOrderViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  serviceOrderId: DEFAULT_SERVICE_ORDER_ID,
})

const serviceOrderFilterPanelVar = makeVar({
  isOpen: false,
})

export const ServiceOrdersPage = () => {
  const { t } = useTranslation(['service_orders'])
  usePageTitle(t('service_orders:page_title'))
  const generatePath = useAbsolutePath()
  const filterViewPanelState = useReactiveVar(serviceOrderFilterPanelVar)

  const { trackButtonClick, trackButtonClickEvent } = useTracking()
  const filterContext = useFilterContext<ServiceOrderFilterContext>()

  const openViewPanel = (serviceOrderId: string) => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({ name: 'service-order-view-panel-open', serviceOrderId })
    serviceOrderViewPanelVar({ isOpen: true, serviceOrderId })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'service-order-view-panel-close' })
    serviceOrderViewPanelVar({
      isOpen: false,
      serviceOrderId: DEFAULT_SERVICE_ORDER_ID,
    })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'service-order-filter-view-panel-close' })
    serviceOrderFilterPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'service-order-filter-view-panel-open' })
    serviceOrderFilterPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider value={filterContext}>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader
            title={t('page_title')}
            isFlexible
            rightColumn={
              <Button
                component={Link}
                to={generatePath(RootRoute.ServiceOrderNew)}
                onClick={trackButtonClickEvent({
                  name: 'service-orders-add-service-order',
                })}
                data-testid="add-service-order-button"
              >
                {t('add_service_order_title')}
              </Button>
            }
          />

          <Container maxWidth={false}>
            <ServiceOrdersList
              onShowFilter={toggleFilterViewPanel}
              onServiceOrderClick={openViewPanel}
            />
          </Container>
        </Grid>
        <Grid>
          <ServiceOrderViewPanel onClose={closeViewPanel} />
          <ServiceOrderFilterPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
