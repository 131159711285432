import { MenuItem, TextField, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

export enum CampaignStates {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive',
  Planned = 'planned',
}

type QueryParamState = {
  state?: CampaignStates
}

export const CampaignStateFilter = () => {
  const { t } = useTranslation(['campaigns'])
  const { trackButtonClick } = useTracking()
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'state'
  const [queryParams, setQueryParams] = useStateParams<QueryParamState>()

  const state = useMemo(() => {
    const value = queryParams[filterKey]

    if (Object.values(CampaignStates).includes(value as CampaignStates)) {
      return value
    }

    return CampaignStates.Active
  }, [queryParams])

  const getCampaignStateLabel = useCallback(
    (type: CampaignStates) => {
      if (type === CampaignStates.All) return t('shared:label.all')
      if (type === CampaignStates.Active)
        return t('campaigns:campaign_state.active')
      if (type === CampaignStates.Inactive)
        return t('campaigns:campaign_state.inactive')
      if (type === CampaignStates.Planned)
        return t('campaigns:campaign_state.planned')

      return ''
    },
    [t],
  )

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    if (state === CampaignStates.Active) {
      setFilter(filterKey, {
        isDefault: true,
        label: t('campaigns:campaign_state_filter_tag_label'),
        labelValues: [state ? getCampaignStateLabel(state) : ''],
        value: '',
      })
    } else {
      setFilter(filterKey, {
        label: t('campaigns:campaign_state_filter_tag_label'),
        labelValues: [state ? getCampaignStateLabel(state) : ''],
        value: state || '',
      })
    }
  }, [state, removeFilter, setFilter, getCampaignStateLabel, t])

  return (
    <TextField
      onChange={(event) => {
        trackButtonClick({
          name: 'campaign-state-filter-select-state',
          type: event.target.value,
        })
        setQueryParams({ [filterKey]: event.target.value as CampaignStates })
      }}
      value={state}
      data-testid="campaign-state-filter"
      select
      label={t('campaigns:state')}
    >
      <MenuItem value={CampaignStates.All} divider>
        <Typography variant="body02">
          {getCampaignStateLabel(CampaignStates.All)}
        </Typography>
      </MenuItem>
      <MenuItem value={CampaignStates.Active}>
        <Typography variant="body02">
          {getCampaignStateLabel(CampaignStates.Active)}
        </Typography>
      </MenuItem>
      <MenuItem value={CampaignStates.Inactive}>
        <Typography variant="body02">
          {getCampaignStateLabel(CampaignStates.Inactive)}
        </Typography>
      </MenuItem>
      <MenuItem value={CampaignStates.Planned}>
        <Typography variant="body02">
          {getCampaignStateLabel(CampaignStates.Planned)}
        </Typography>
      </MenuItem>
    </TextField>
  )
}
