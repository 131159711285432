import { useCallback, useState } from 'react'

export const useDrag = <T>() => {
  const [rowId, setRowId] = useState<T | null>(null)
  const onDragStart = useCallback((id: T) => setRowId(id), [])

  const onDragOver = useCallback(
    <TArrayItem extends Record<string, unknown>, TKey extends keyof TArrayItem>(
      destinationIndex: number,
      list: TArrayItem[],
      key: TKey,
      onUpdate: (newList: TArrayItem[]) => void,
      shouldUpdate?: (current: TArrayItem, target?: TArrayItem) => boolean,
    ) => {
      if (rowId === null) return

      const startIndex = list.findIndex((x) => x[key] === rowId)
      if (startIndex === destinationIndex) return

      const newList = Array.from(list)
      const target = newList[destinationIndex]
      // remove item from the list
      const element = newList.splice(startIndex, 1)[0]

      if (element && (!shouldUpdate || shouldUpdate(element, target))) {
        newList.splice(destinationIndex, 0, element)
        onUpdate(newList)
      }
    },
    [rowId],
  )

  const onDragEnd = useCallback(() => {
    setRowId(null)
  }, [])

  return {
    dragId: rowId,
    onDragStart,
    onDragOver,
    onDragEnd,
  }
}
