import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SalesTaxProductGroupRule } from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'

export const useSalesTaxFormatter = () => {
  const { t } = useTranslation(['settings'])
  const { formatCurrency } = useMoney()

  // Tax ('decimalvalue') is required on the API salestax object and defaults to 0 if no tax is set
  const formatTaxValue = useCallback((tax?: string) => {
    return Number(tax || 0) + '%'
  }, [])

  const formatNameAndTax = useCallback(
    (name?: string | null, value?: string | null) => {
      if (!name || !value) return t('settings:sales_tax.missing_product_group')

      return `${name} (${formatTaxValue(value)})`
    },
    [formatTaxValue, t],
  )

  const formatRuleTypeAndAmounts = useCallback(
    (rule?: SalesTaxProductGroupRule) => {
      if (!rule) return ''

      const { type, moneymin, moneymax } = rule

      const translatedType = t(
        `settings:sales_tax_product_group_rule_type.${type}`,
      )

      if (!moneymin && !moneymax) {
        return translatedType
      }

      if (!moneymax) {
        return `${translatedType} (${formatCurrency(moneymin || 0)})`
      }

      if (!moneymin) {
        return `${translatedType} (${formatCurrency(0)} - ${formatCurrency(moneymax)})`
      }

      return `${translatedType} (${formatCurrency(moneymin || 0)} - ${formatCurrency(moneymax)})`
    },
    [formatCurrency, t],
  )

  return {
    formatRuleTypeAndAmounts,
    formatTaxValue,
    formatNameAndTax,
  }
}
