/**
 * Checks if there are any dirty fields in the provided object.
 *
 * This function is specifically used to receive `dirtyFields` from
 * `react-hook-form`'s `formContext.formState.dirtyFields`.
 *
 * @param dirtyFields - An object representing the dirty fields in a form.
 * @returns `true` if there are any dirty fields, otherwise `false`.
 */
export const containsDirtyFields = (dirtyFields: object) => {
  return Object.keys(dirtyFields).length > 0
}
