import { useLazyQuery } from '@apollo/client'
import {
  Autocomplete,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SxProps,
  TextField,
  Theme,
  UseAutocompleteProps,
} from '@mui/material'
import { debounce } from '@mui/material/utils'
import { CloseIcon, SearchIcon } from '@sitoo/mui-components'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GetProductsDocument,
  GetProductsQuery,
  GetProductsQueryVariables,
  Maybe,
} from '../../generated/graphql'
import { getProductDetails } from '../../utils/format/product'
import { ArrayElement } from '../../utils/types'
import { DataGridImage } from '../data-grid-image'

type ProductResult = ArrayElement<GetProductsQuery['products']['items']>

type Option = {
  id: number
  product: ProductResult
  label?: Maybe<string>
}

type AutocompleteProps = UseAutocompleteProps<Option, false, false, false>

type Props = {
  onChange: (product: ProductResult) => void
  sx?: SxProps<Theme>
  ['data-testid']?: string
  fetchOpts?: GetProductsQueryVariables
}

export const SearchProductField = (props: Props) => {
  const { t } = useTranslation(['shared'])
  const [value, setValue] = useState<Option | null>(null)
  const [inputValue, setInputValue] = useState('')
  const DEBOUNCE_MS = 250
  const MIN_SYMBOLS = 2
  const OPTIONS_NUM = 10

  const [fetchProducts, { data, loading }] = useLazyQuery(GetProductsDocument, {
    fetchPolicy: 'network-only',
  })

  const debouncedFetch = useMemo(
    () =>
      debounce(async (value: string) => {
        if (value.length < MIN_SYMBOLS) {
          return
        }

        await fetchProducts({
          variables: {
            search: value,
            num: OPTIONS_NUM,
            ...props.fetchOpts,
          },
        })
      }, DEBOUNCE_MS),
    [fetchProducts, props.fetchOpts],
  )

  const options =
    data?.products.items?.map((product) => ({
      id: product.id,
      label: product.title,
      product,
    })) || []

  const onChange = useCallback<
    Exclude<AutocompleteProps['onChange'], undefined>
  >(
    (_event, value, reason) => {
      if (reason !== 'selectOption') {
        return
      }

      if (value && typeof value === 'object' && 'id' in value) {
        setValue(null)
        props.onChange(value.product)
      }
    },
    [props],
  )

  const onInputChange = useCallback<
    Exclude<AutocompleteProps['onInputChange'], undefined>
  >(
    (_event, value, reason) => {
      if (reason === 'input') {
        setInputValue(value)
        void debouncedFetch(value)
      } else {
        setInputValue('')
      }
    },
    [debouncedFetch],
  )

  return (
    <Autocomplete
      sx={props?.sx}
      autoHighlight
      noOptionsText={t('shared:found_items_zero')}
      open={Number(inputValue.length) >= MIN_SYMBOLS}
      loadingText={t('shared:loading')}
      options={options}
      loading={loading}
      inputValue={inputValue}
      filterOptions={(option) => option}
      value={value}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={onChange}
      onInputChange={onInputChange}
      renderOption={(props, option) => (
        <ListItem
          {...props}
          key={option.id}
          data-testid="autocomplete-list-item"
          data-id={option.id}
        >
          <ListItemAvatar>
            <DataGridImage src={option.product.productImages?.at(0)?.fileUrl} />
          </ListItemAvatar>
          <ListItemText
            primary={option.label}
            secondary={getProductDetails(option.product)}
          />
        </ListItem>
      )}
      data-testid={props['data-testid']}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('shared:product_search.placeholder')}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="medium" />
              </InputAdornment>
            ),
            endAdornment: inputValue && (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => {
                    setInputValue('')
                  }}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
