import { useApolloClient, useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  ActionType,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  AddShipmentActionDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'

export type SetPackageArrivedDialogProps = {
  open: boolean
  packageIds: string[]
  shipmentVersion: number
  shipmentId: string
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
  dataTestid?: string
}

export const SetPackageArrivedDialog = (
  props: SetPackageArrivedDialogProps,
) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-package-arrived'
  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [addShipmentAction] = useMutation(AddShipmentActionDocument)
  const client = useApolloClient()

  const setPackageArrived = async () => {
    trackButtonClickEvent({ name: `${dialogName}-dialog-delete` })

    try {
      await addShipmentAction({
        variables: {
          shipmentId: props.shipmentId,
          data: {
            shipment_version: props.shipmentVersion,
            action: ActionType.AddArrivedPackages,
            package_id_list: props.packageIds,
          },
        },
      })

      trackFormSuccess({ name: `${dialogName}-dialog` })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })

      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments:shipment_message.failure_update'),
      )

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onError?.(errorMessage)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={setPackageArrived}
      title={t('shipments:set_package_arrived_dialog.title', {
        count: props.packageIds.length,
      })}
      text={t('shipments:set_package_arrived_dialog.description')}
      open={props.open}
      dataTestid="set-package-arrived"
      onClose={onClose}
      mainActionLabel={t('shipments:set_package_arrived_dialog.set_to_arrived')}
      secondaryActionLabel={t('shared:action.cancel')}
    />
  )
}
