import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemText,
  Stack,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { SalesTaxQuery } from '../../../../generated/graphql'
import { containsInvalidRule } from '../../sales-tax/shared'
import { SalesTaxInvalidRuleBanner } from '../../sales-taxes/sales-tax-invalid-rule-banner'
import { useSalesTaxFormatter } from '../../sales-taxes/useSalesTaxFormatter'

type Props = {
  salesTaxes?: SalesTaxQuery['salesTax'][] | null
}

export const SalesTaxes = ({ salesTaxes }: Props) => {
  const { t } = useTranslation(['settings'])
  const { formatNameAndTax, formatRuleTypeAndAmounts } = useSalesTaxFormatter()

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="sales_taxes">
        <SectionHeader sx={{ p: 0 }}>
          {t('settings:sales_tax_group.sales_taxes')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          {salesTaxes?.map((salesTax, index) => {
            const hasInvalidRule = containsInvalidRule(salesTax)
            const isLastSalesTax = index + 1 === salesTaxes.length
            return (
              <ListItemSkeleton
                key={index}
                data-testid={`sales-taxes-row-${index}`}
                hide={!salesTax.name}
                divider={!hasInvalidRule}
                sx={{ p: 0 }}
              >
                <Stack
                  direction="column"
                  width="100%"
                  sx={{ backgroundColor: 'background.default' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      py: 1,
                      px: 2,
                      backgroundColor: 'background.paper',
                    }}
                  >
                    <ListItemText
                      primary={formatNameAndTax(
                        salesTax.name,
                        salesTax.decimalvaluedefault,
                      )}
                      secondary={
                        <>
                          {salesTax.productgrouprules?.map(
                            (rule, ruleIndex) => (
                              <Box
                                key={`${index}-${ruleIndex}`}
                                component="span"
                                sx={{
                                  color: !rule.groupName ? 'redBase' : '',
                                }}
                              >
                                {`${formatNameAndTax(rule.groupName, rule.decimalvalue)}: ${formatRuleTypeAndAmounts(rule)}`}
                                <br />
                              </Box>
                            ),
                          )}
                        </>
                      }
                    />
                  </Box>
                  {hasInvalidRule && (
                    <SalesTaxInvalidRuleBanner
                      sx={{ mb: isLastSalesTax ? 0 : 1 }}
                    />
                  )}
                </Stack>
              </ListItemSkeleton>
            )
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
