import { ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalCtuError' }
  >
}

export const CtuErrorDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props

  return (
    <>
      <ListItemSkeleton
        hide={!journal?.errortext}
        childrenSkeleton
        divider
        data-testid="journal-errortext"
      >
        <ListItemText secondary={journal?.errortext}>
          {t('journals:ctu_error')}
        </ListItemText>
      </ListItemSkeleton>
    </>
  )
}
