import { useSuspenseQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { RootRoute } from '..'
import {
  AuthenticateOidcDocument,
  OidcAccountIdDocument,
} from '../../generated/graphql'
import { DEFAULT_SITE_ID, REDIRECT_QUERY_PARAM } from '../../utils/constants'
import { ACCOUNT_ID_REGEX } from '../login/util'
import { useLocalStorage } from 'react-use'

export const OidcCallbackPage = () => {
  const [searchParams] = useSearchParams()
  const [redirectPage, , removeRedirectPage] =
    useLocalStorage(REDIRECT_QUERY_PARAM)
  const { t } = useTranslation(['shared', 'login'])
  const navigate = useNavigate()
  const state = searchParams.get('state')
  const code = searchParams.get('code')
  const hasAccountId = location.host.match(ACCOUNT_ID_REGEX)?.[0]

  const { enqueueSnackbar } = useSnackbar()

  const { data: accountIdData, error: fetchError } = useSuspenseQuery(
    OidcAccountIdDocument,
    {
      variables: { state: String(state) },
      skip: !!hasAccountId || !state,
      errorPolicy: 'all',
    },
  )

  const { data: authenticateData, error: authError } = useSuspenseQuery(
    AuthenticateOidcDocument,
    {
      variables: { code: String(code), state: String(state) },
      skip: !hasAccountId || !state || !code,
      errorPolicy: 'all',
    },
  )

  const hasError = fetchError || authError

  const navigateToError = useCallback(() => {
    const errorMessage = authError
      ? t('login:error.oidc_authentication_internal')
      : t('login:error.oidc_authentication_external')

    enqueueSnackbar(errorMessage, { variant: 'error', preventDuplicate: true })
    navigate({ pathname: RootRoute.Logout })
  }, [authError, enqueueSnackbar, navigate, t])

  useEffect(() => {
    if (hasError) navigateToError()
  }, [hasError, navigateToError])

  useEffect(() => {
    const accountId = accountIdData?.oidcAccountId

    if (accountId) {
      return window.location.assign(
        window.location.href.replace('https://', `https://${accountId}.`),
      )
    }
  }, [accountIdData])

  useEffect(() => {
    if (authenticateData?.authenticateOidc) {
      const homePage = generatePath(RootRoute.Root, { siteId: DEFAULT_SITE_ID })

      removeRedirectPage()
      navigate(redirectPage || homePage)
    } else if (authenticateData?.authenticateOidc === false) {
      navigateToError()
    }
  }, [
    authenticateData?.authenticateOidc,
    navigate,
    navigateToError,
    redirectPage,
    removeRedirectPage,
  ])

  return null
}
