import { Box, Button } from '@mui/material'
import { FilterViewPanelFooter } from '../filter-view-panel-footer'
import { useTranslation } from 'react-i18next'

type FilterViewPanelApplyFooterProps = {
  onApply(): void
  onClearFilter(): void
}

export const FilterViewPanelApplyFooter = (
  props: FilterViewPanelApplyFooterProps,
) => {
  const { t } = useTranslation('shared')

  return (
    <FilterViewPanelFooter>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: (theme) => theme.spacing(2),
        }}
      >
        <Button
          color="secondary"
          data-testid="remove-filter-button"
          size="small"
          onClick={props.onClearFilter}
        >
          {t('shared:label.clear_filter')}
        </Button>
        <Button
          size="small"
          data-testid="apply-filter-button"
          onClick={props.onApply}
        >
          {t('shared:action.apply')}
        </Button>
      </Box>
    </FilterViewPanelFooter>
  )
}
