import { useParams, generatePath, Navigate, Outlet } from 'react-router-dom'
import { RootRoute } from '.'
import { Authorization, useAuthorization } from '../hooks/authorization'

type PermissionProps = {
  children?: React.ReactNode
  isAllowed:
    | keyof Authorization['modules']
    | ((modules: Authorization['modules']) => boolean)
  /**
   * Disable navigation to the root
   */
  silent?: true
}

export const Permission = (props: PermissionProps) => {
  const { modules, loading } = useAuthorization()
  const { siteId } = useParams()
  const rootUrl = generatePath(RootRoute.Root, { siteId })
  const { children = <Outlet />, silent = false, isAllowed } = props

  if (loading) return null

  if (
    typeof isAllowed === 'function' ? isAllowed(modules) : modules[isAllowed]
  ) {
    return <>{children}</>
  }

  if (!silent) {
    return <Navigate to={rootUrl} replace />
  }
}
