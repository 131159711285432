import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import {
  ImportInfluencerCodesDocument,
  ParseInfluencerCodesCsvDocument,
} from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ImportDialog } from '../import-dialog'
import { stripNullValues } from '../../../../utils/strip-null-values'

export type Props = {
  open: boolean
  onClose(): void
}

const CSV_EXAMPLE = `
code;name;voucherpassword
PREPARE4SUMMER;Taylor Smith;Lzs5MvMr
`

export const ImportInfluencerCodesDialog = (props: Props) => {
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [parseCsvMutation, { data: parsedData, loading: parseLoading }] =
    useMutation(ParseInfluencerCodesCsvDocument)

  const [
    importMutation,
    { data: importData, loading: importLoading, error: importError },
  ] = useMutation(ImportInfluencerCodesDocument)

  const isLoading = parseLoading || importLoading

  const onParse = useCallback(
    (csv: string) => {
      return parseCsvMutation({ variables: { csv } })
    },
    [parseCsvMutation],
  )

  const onImport = useCallback(() => {
    const parseItems = parsedData?.parseInfluencerCodesCsv.items.map((o) =>
      stripNullValues(o),
    )

    if (parseItems) {
      return importMutation({ variables: { data: parseItems } })
    }
  }, [importMutation, parsedData?.parseInfluencerCodesCsv.items])

  const onSuccess = useCallback(() => {
    navigate(generatePath(RootRoute.InfluencerCodes))
  }, [generatePath, navigate])

  return (
    <ImportDialog
      {...props}
      isLoading={isLoading}
      ns="influencer_codes"
      onImport={onImport}
      onParse={onParse}
      onSuccess={onSuccess}
      importData={importData?.importInfluencerCodes}
      parsedItems={parsedData?.parseInfluencerCodesCsv}
      importError={importError}
      example={CSV_EXAMPLE}
    />
  )
}
