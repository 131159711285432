import { Box } from '@mui/material'
import { PropsWithChildren } from 'react'

export const PrintContainer = (props: PropsWithChildren) => {
  return (
    <Box
      sx={{
        width: '310px',
        margin: 'auto',

        table: {
          borderSpacing: '0px',
          tableLayout: 'fixed',
          width: '100%',
          td: {
            wordWrap: 'break-word',
          },
        },
      }}
    >
      {props.children}
    </Box>
  )
}
