import { useQuery } from '@apollo/client'
import { Button, Container } from '@mui/material'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { CustomOverlay } from '../../../components/data-grid/custom-overlay'
import { PageHeader } from '../../../components/page-header'
import {
  Transactiontype,
  AllOpenWarehouseBatchesDocument,
  WarehouseType,
  GetWarehousesDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { usePageTitle } from '../../../hooks/title'
import { NewStocktakingSessionDialog } from './new-stocktaking-session-dialog'
import { StocktakingList } from './stocktaking-list'

export const StocktakingsPage = () => {
  const { t } = useTranslation(['stocktaking', 'shared'])
  usePageTitle(t('stocktaking:page_title'))
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const {
    modules: { writeStocktaking },
  } = useAuthorization()

  const {
    data: allOpenWarehouseBatchesData,
    loading: loadingAllOpenWarehouseBatch,
  } = useQuery(AllOpenWarehouseBatchesDocument, {
    variables: {
      transactiontype: Transactiontype.NUMBER_50,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data, loading: loadingWarehouses } = useQuery(GetWarehousesDocument)
  const allWarehouses = data?.allWarehouses

  const fifoWarehouses = useMemo(
    () => allWarehouses?.filter((w) => w.warehousetype === WarehouseType.FIFO),
    [allWarehouses],
  )

  const canAddNew = useMemo(
    () =>
      fifoWarehouses?.some(
        (warehouse) =>
          !allOpenWarehouseBatchesData?.allOpenWarehouseBatches.find(
            (x) => x.warehouse?.id === warehouse.id,
          ),
      ),
    [allOpenWarehouseBatchesData?.allOpenWarehouseBatches, fifoWarehouses],
  )
  const isLoading = loadingAllOpenWarehouseBatch || loadingWarehouses

  return (
    <>
      <PageHeader
        title={t('stocktaking:page_title')}
        isFlexible
        rightColumn={
          <>
            {writeStocktaking && (
              <>
                <Button
                  variant="contained"
                  onClick={() => setOpen(true)}
                  data-testid="new-stocktaking"
                  disabled={isLoading || !canAddNew}
                >
                  {t('shared:action.create')}
                </Button>
                <NewStocktakingSessionDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  onSuccess={(warehouseId, warehouseBatchId) => {
                    setOpen(false)
                    navigate(
                      generatePath(RootRoute.Stocktaking, {
                        warehouseId: String(warehouseId),
                        warehouseBatchId: String(warehouseBatchId),
                      }),
                    )
                  }}
                />
              </>
            )}
          </>
        }
      />
      <Container maxWidth={false}>
        {fifoWarehouses?.length === 0 ? (
          <CustomOverlay
            icon={<WarehouseIcon />}
            title={t('stocktaking:empty_warehouses.title')}
            description={t('stocktaking:empty_warehouses.description')}
            sx={{ position: 'relative' }}
          />
        ) : (
          <FilterProvider>
            <StocktakingList />
          </FilterProvider>
        )}
      </Container>
    </>
  )
}
