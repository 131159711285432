import { useEffect, useState } from 'react'

export const useWindowPrint = ({ isReady }: { isReady: boolean }) => {
  const [isFontsLoaded, setFontsLoaded] = useState(false)

  useEffect(() => {
    const onFontsLoaded = () => setFontsLoaded(true)

    document.fonts.addEventListener('loadingdone', onFontsLoaded)
    return () => {
      document.fonts.removeEventListener('loadingdone', onFontsLoaded)
    }
  }, [])

  useEffect(() => {
    if (isReady && isFontsLoaded) {
      window.print()
    }
  }, [isReady, isFontsLoaded])
}
