import { useQuery } from '@apollo/client'
import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  GetProductQuery,
  GetProductGroupsDocument,
} from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import {
  MAX_NUM_REQUEST,
  DEFAULT_PRODUCT_GROUP_ID,
} from '../../../../utils/constants'

export const ProductGroupField = () => {
  const { control } = useFormContext<GetProductQuery>()
  const { data, loading } = useQuery(GetProductGroupsDocument, {
    variables: { num: MAX_NUM_REQUEST },
    fetchPolicy: 'cache-and-network',
  })
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()

  return (
    <FormFieldset
      label={t('products:product_form.product_group_fieldset')}
      isLoading={loading}
    >
      <Controller
        control={control}
        name="product.productgroup.id"
        render={({ field }) => (
          <>
            <InputLabel>
              {t('products:product_form.product_group_title')}
            </InputLabel>
            <Select
              data-testid="product-group-selector"
              value={field.value || DEFAULT_PRODUCT_GROUP_ID}
              onChange={(event) => {
                field.onChange(event.target.value)
              }}
              disabled={!writeProducts}
            >
              {data?.productGroups.items?.map(({ id, name }) => (
                <MenuItem
                  data-testid="product-group-option"
                  value={id}
                  key={id}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {t('products:product_form.product_group_description')}
            </FormHelperText>
          </>
        )}
      />
    </FormFieldset>
  )
}
