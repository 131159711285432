import {
  Drawer,
  DrawerProps,
  IconButton,
  Theme,
  Typography,
} from '@mui/material'
import { css, styled } from '@mui/material/styles'

export const SIDEBAR_WIDTH = 30
export const SIDEBAR_WIDTH_MOBILE = 40

export const Category = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSettings',
})<{ isSettings?: boolean }>`
  user-select: none;
  text-transform: uppercase;
  padding: ${({ theme }) => theme.spacing(1.5, 2, 1.5, 3)};
  color: ${({ theme, isSettings }) =>
    isSettings ? theme.palette.gray60 : theme.palette.darkMode10};
`

const openedMixin = (theme: Theme, isSettings?: boolean) => css`
  & .MuiList-root {
    opacity: 1;
  }

  & .MuiDrawer-paper {
    background: ${isSettings
      ? theme.palette.darkMode80
      : theme.palette.darkMode60};
    border-right: none;
  }
`

const closedMixin = (theme: Theme) => css`
  & .MuiList-root {
    opacity: 0;
    pointer-events: none;
  }

  & .MuiDrawer-paper {
    overflow: visible;
    background: ${theme.palette.background.paper};
    transform: translateX(
      calc(-${theme.spacing(SIDEBAR_WIDTH)} + ${theme.spacing(3)} + 1px)
    );

    ${theme.breakpoints.down('sm')} {
      transform: translateX(calc(-${theme.spacing(SIDEBAR_WIDTH_MOBILE)}));
    }
  }
`

export const SidebarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'isSettings' && prop !== 'slotProps',
})<DrawerProps & { isSettings?: boolean }>(({ theme, open, isSettings }) => [
  css`
    flex-shrink: 0;

    & .MuiList-root {
      transition: ${theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      })};
    }

    & .MuiDrawer-paper {
      color: ${theme.palette.white};
      transition: ${theme.transitions.create(
        ['background-color', 'transform', 'color'],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        },
      )};

      width: ${theme.spacing(SIDEBAR_WIDTH)};
      ${theme.breakpoints.down('sm')} {
        width: ${theme.spacing(SIDEBAR_WIDTH_MOBILE)};
      }
    }

    .arrow-button {
      opacity: ${open ? 0 : 1};

      transition: ${theme.transitions.create(['opacity'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })};
    }
    &:hover {
      .arrow-button {
        opacity: 1;
      }
    }

    & .MuiListItemButton-root {
      border-left-width: 2px;
      border-style: solid;
      border-color: transparent;

      &:hover {
        background: ${theme.palette.blue80};
      }

      &.Mui-selected,
      &.Mui-selected:hover {
        background: ${theme.palette.blueBase};
      }

      &.Mui-selected,
      &:hover {
        border-color: ${theme.palette.blue40};
      }
    }
  `,

  open && openedMixin(theme, isSettings),
  !open && closedMixin(theme),
])

export const ArrowButton = styled(IconButton)<{ open?: boolean }>(
  ({ theme, open }) => `
  position: absolute;
  pointer-events: auto;
  padding: 2px;
  margin-top: 82px;
  right: -12px;
  background-color: ${theme.palette.darkMode60};
  color: ${theme.palette.white};
  border: 1.5px solid ${open ? theme.palette.white : theme.palette.white};

  &:hover {
    background-color: ${
      open ? theme.palette.darkMode60 : theme.palette.darkMode60
    };
    color: ${open ? theme.palette.white : theme.palette.white};

    border: 1.5px solid ${open ? theme.palette.white : theme.palette.white};
  }

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`,
)
