import { useQuery } from '@apollo/client'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import {
  GetUsersQuery,
  GetUsersQueryVariables,
  GetUsersDocument,
  UserTag,
  GetUsersSort,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { UserDefaultIcon } from '@sitoo/mui-components'
import { RoleRenderer } from './role'
import { useMemo, useCallback, useContext } from 'react'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { WarehouseRenderer } from './warehouse-chips'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { CustomerDetailTypeEnum } from './customer-filter'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { useMe } from '../../../hooks/me'
import { Chip } from '@mui/material'
import { UserTagsRenderer } from './user-tag-chips'
import { BulkActions } from './bulk-actions'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { MfaListRenderer } from './mfa-list-renderer'

export type Row = ArrayElement<GetUsersQuery['users']['items']>

type UsersListProps = {
  onDetailUser(id: string): void
  currentDetailedUserId?: string
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const UsersList = (props: UsersListProps) => {
  const { t } = useTranslation(['users', 'shared'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const { me } = useMe()
  const apiRef = useGridApiRef()

  const { filter, isFilterReady } = useContext(FilterContext)

  const sortItems = useMemo<SortItem<GetUsersSort>[]>(
    () => [
      {
        field: 'id',
        sort: 'asc',
        title: t('users:id'),
        type: 'text',
        value: GetUsersSort.UserIdAsc,
        isDefault: true,
      },
      {
        field: 'id',
        sort: 'desc',
        title: t('users:id'),
        type: 'text',
        value: GetUsersSort.UserIdDesc,
      },
      {
        field: 'email',
        sort: 'asc',
        title: t('users:email'),
        type: 'text',
        value: GetUsersSort.EmailAsc,
      },
      {
        field: 'email',
        sort: 'desc',
        title: t('users:email'),
        type: 'text',
        value: GetUsersSort.EmailDesc,
      },
      {
        field: 'first_name',
        sort: 'asc',
        title: t('users:first_name'),
        type: 'text',
        value: GetUsersSort.NameFirstAsc,
      },
      {
        field: 'first_name',
        sort: 'desc',
        title: t('users:first_name'),
        type: 'text',
        value: GetUsersSort.NameFirstDesc,
      },
      {
        field: 'last_name',
        sort: 'asc',
        title: t('users:last_name'),
        type: 'text',
        value: GetUsersSort.NameLastAsc,
      },
      {
        field: 'last_name',
        sort: 'desc',
        title: t('users:last_name'),
        type: 'text',
        value: GetUsersSort.NameLastDesc,
      },
    ],
    [t],
  )

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetUsersQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    if (typeof config.filter['text']?.value === 'string') {
      variables.search = config.filter['text']?.value
    }

    const userTagIds = config.filter?.['userTagIds']?.value as
      | UserTag[]
      | undefined

    variables.tags = userTagIds?.map((tag) => tag.id)

    if (
      Array.isArray(config.filter?.['customer']?.value) &&
      config.filter?.['customer']?.value.length === 2
    ) {
      const [customerDetailType, customerDetailValue] = config.filter?.[
        'customer'
      ]?.value as [CustomerDetailTypeEnum, string]

      switch (customerDetailType) {
        case CustomerDetailTypeEnum.Email:
          variables.email = customerDetailValue
          break

        case CustomerDetailTypeEnum.Mobile:
          variables.mobile = customerDetailValue
          break

        case CustomerDetailTypeEnum.PersonalId:
        default:
          variables.personalid = customerDetailValue
          break
      }
    }

    return variables
  }, [apiRef, filter, sortItems])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetUsersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || !isFilterReady

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'id',
        minWidth: 80,
        headerName: t('users:id'),
      },
      {
        field: 'custom-name',
        minWidth: 200,
        headerName: t('users:name'),
        renderCell: (params) => {
          return (
            <>
              {[params.row.namefirst, params.row.namelast]
                .filter(Boolean)
                .join(' ')}
              {params.id === me?.user?.id && (
                <Chip
                  size="small"
                  color="blue"
                  label={t('users:you')}
                  sx={{ ml: 1 }}
                />
              )}
            </>
          )
        },
      },

      {
        field: 'role',
        minWidth: 150,
        headerName: t('users:role'),
        renderCell: RoleRenderer,
      },

      {
        field: 'allWarehouses',
        minWidth: 250,
        headerName: t('users:warehouse'),
        renderCell: WarehouseRenderer,
      },
      {
        field: 'datecreated',
        ...ColumnProps.date,
        headerName: t('users:created'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'datemodified',
        ...ColumnProps.date,
        headerName: t('users:modified'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'email',
        minWidth: 300,
        headerName: t('users:email'),
      },
      {
        field: 'mobile',
        minWidth: 120,
        headerName: t('users:mobile'),
      },
      {
        field: 'personalid',
        minWidth: 120,
        headerName: t('users:personal_id'),
      },
      {
        field: 'tags',
        minWidth: 200,
        headerName: t('users:user_tags.tags'),
        renderCell: UserTagsRenderer,
      },
      {
        field: 'hasmfa',
        minWidth: 200,
        headerName: t('users:mfa'),
        renderCell: MfaListRenderer,
      },
      {
        field: 'customernumber',
        minWidth: 120,
        headerName: t('users:customer_number'),
      },
      {
        field: 'externalid',
        minWidth: 120,
        headerName: t('users:external_id'),
      },
    ],
    [formatDate, formatRelativeDate, me?.user?.id, t],
  )

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.users.totalcount) {
      return fetchMore({
        variables: {
          start: (data?.users.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data?.users.start, data?.users.totalcount, fetchMore])

  return (
    <DataGrid
      name="users-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.users.items}
      rowCount={data?.users.totalcount || 0}
      fetchMore={fetchMoreItems}
      loading={isLoading}
      onRowClick={(row) => props.onDetailUser(String(row.id))}
      noRowsOverlay={{
        icon: <UserDefaultIcon />,
        title: t('users:grid.title'),
        description: t('users:grid.description'),
      }}
      sx={{
        '.filter-row-container': {
          gridTemplateColumns: 'auto auto auto 1fr auto',
        },
      }}
      slots={{ pagination: CursorPagination }}
      onShowFilter={props.onShowFilter}
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      hasTextFilter
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      bulkAction={<BulkActions />}
      sorting={sortItems}
      columnVisibilityModel={{
        id: false,
        datecreated: false,
        externalid: false,
        mobile: false,
        personalid: false,
        customernumber: false,
      }}
      showMore={
        Number(data?.users.items?.length) < Number(data?.users.totalcount)
      }
      getRowClassName={(params) => {
        const classes = []
        if (params.id === props.currentDetailedUserId) {
          classes.push('Mui-selected')
        }

        if (params.row.active === false) {
          classes.push('disabled')
        }
        return classes.join(' ')
      }}
    />
  )
}
