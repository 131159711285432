import { useFormContext } from 'react-hook-form'
import { CampaignFormContext } from '../..'
import {
  CAMPAIGN_TYPE_CART,
  CAMPAIGN_TYPE_PRODUCT,
} from '../../../../utils/campaigns'
import { CartAppliesToField } from './cart-applies-to-field'
import { ProductAppliesToField } from './product-applies-to-field'

export const AppliesToField = () => {
  const { getValues } = useFormContext<CampaignFormContext>()
  const type = getValues('campaign.vouchertype')

  if (CAMPAIGN_TYPE_CART.types.includes(type)) {
    return <CartAppliesToField />
  }

  if (CAMPAIGN_TYPE_PRODUCT.types.includes(type)) {
    return <ProductAppliesToField />
  }

  return null
}
