import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'
import { Indicator } from '../../indicator'

export const StockRenderer = (
  params: GridRenderCellParams<GridValidRowModel, number | null | undefined>,
) => {
  return (
    <div
      className="MuiDataGrid-cellContent"
      data-testid={`string-field-${params.id}-${params.field}`}
    >
      <Indicator active={Number(params.value) > 0}>{params.value}</Indicator>
    </div>
  )
}
