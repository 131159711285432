import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import { ReactElement, useEffect, useState } from 'react'
import { ProductFormContext } from '..'
import { ArrayElement } from '../../../utils/types'
import { CustomAttributesDialog } from './custom-attributes-dialog'
import { useFormContext } from 'react-hook-form'
import { Box, Button, Chip } from '@mui/material'
import { CustomAttribute as CustomAttributeType } from '../fields/attributes'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../hooks/tracking'

export type CustomAttributeProps = Pick<
  ArrayElement<ProductFormContext['product']['childVariants']>,
  'customattributes'
> & {
  id?: number
  editable?: boolean
}

export const CustomAttributeRenderer = (
  params: GridRenderCellParams<
    CustomAttributeProps,
    ArrayElement<ProductFormContext['product']['childVariants']>
  >,
): ReactElement => (
  <CustomAttribute
    customattributes={params.row.customattributes}
    id={params.row.id}
    editable={params.isEditable}
  />
)

const CustomAttribute = (props: CustomAttributeProps): ReactElement => {
  const { t } = useTranslation('products')
  const { trackButtonClickEvent } = useTracking()
  const apiRef = useGridApiContext()
  const { watch, setValue } = useFormContext<ProductFormContext>()
  const childVariants = watch('product.childVariants') || []
  const currentChildVariant = childVariants?.find((x) => x.id === props.id)
  const supportedCustomAttributes = watch('supportedCustomAttributes') || []
  const [showEditDialog, setShowEditDialog] = useState(false)
  const currentCustomAttributes = props.customattributes as CustomAttributeType
  const customAttributesEntries: [string, unknown][] = supportedCustomAttributes
    .filter(
      (x) =>
        currentCustomAttributes?.[x.id] !== null &&
        currentCustomAttributes?.[x.id] !== undefined,
    )
    .map((x) => {
      return [x.id, currentCustomAttributes?.[x.id]]
    })

  const onClose = () => {
    setShowEditDialog(false)

    try {
      apiRef.current.stopCellEditMode({
        id: props.id || 0,
        field: 'customattributes',
      })
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const onSuccess = (newCustomAttributes: CustomAttributeType) => {
    const newChildVariants = childVariants

    if (currentChildVariant) {
      currentChildVariant.customattributes = newCustomAttributes
      setValue('product.childVariants', newChildVariants, {
        shouldDirty: true,
      })
    }
  }

  const formatLabel = (value: unknown): string | number => {
    if (typeof value === 'number' || typeof value === 'string') {
      return value
    }

    return ''
  }

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'cellEditStart',
      (params, event) => {
        if (
          currentChildVariant?.id === params.id &&
          params.field === 'customattributes'
        ) {
          event.defaultMuiPrevented = true
          setShowEditDialog(true)
        }
      },
      { isFirst: true },
    )
  }, [apiRef, currentChildVariant?.id, props.id])

  return (
    <>
      {customAttributesEntries.length === 0 ? (
        props.editable && (
          <Button
            color="secondary"
            onClick={trackButtonClickEvent(
              { name: 'product-variants-add-custom-attribute' },
              () => setShowEditDialog(true),
            )}
          >
            {t('product_form.add_attributes')}
          </Button>
        )
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: (theme) => theme.spacing(0.5),
          }}
        >
          {customAttributesEntries.map(([attribute, value]) => (
            <Chip
              key={attribute}
              size="small"
              color="blue"
              label={formatLabel(value)}
            />
          ))}
        </Box>
      )}
      <CustomAttributesDialog
        onClose={onClose}
        onSuccess={onSuccess}
        open={showEditDialog}
        supportedCustomAttributes={supportedCustomAttributes}
        values={currentCustomAttributes}
        variantId={props.id || 0}
      />
    </>
  )
}
