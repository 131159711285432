import { useFormContext } from 'react-hook-form'
import { CampaignFormContext } from '../..'
import { PriceListTagsInput } from '../../../../inputs/price-list-tags-input'

export const PricelistTagsField = () => {
  const { control } = useFormContext<CampaignFormContext>()

  return (
    <PriceListTagsInput
      name="campaign.pricelisttags"
      control={control}
      sx={{ px: 2, mb: 2 }}
    />
  )
}
