import { CampaignType } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import {
  translateCampaignTypeExample,
  translateCampaignTypeTitle,
} from '../i18n'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type ProductCampaignSelectionProps = {
  onSelectCampaignType: (campaignType: CampaignType) => void
  ['data-testid']?: string
}

export const ProductCampaignSelection = (
  props: ProductCampaignSelectionProps,
) => {
  const { trackButtonClickEvent } = useTracking()

  return (
    <div data-testid={props['data-testid']}>
      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-buy-x-fixed-price' },
          () => props.onSelectCampaignType(CampaignType.ProductBuyXForPriceM),
        )}
        primary={translateCampaignTypeTitle(CampaignType.ProductBuyXForPriceM)}
        secondary={translateCampaignTypeExample(
          CampaignType.ProductBuyXForPriceM,
        )}
        data-testid="buy-x-for-m-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-buy-x-pay-y' },
          () => props.onSelectCampaignType(CampaignType.ProductBuyXPayForY),
        )}
        primary={translateCampaignTypeTitle(CampaignType.ProductBuyXPayForY)}
        secondary={translateCampaignTypeExample(
          CampaignType.ProductBuyXPayForY,
        )}
        data-testid="buy-x-pay-for-y-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-price-discount' },
          () => props.onSelectCampaignType(CampaignType.ProductDiscountM),
        )}
        primary={translateCampaignTypeTitle(CampaignType.ProductDiscountM)}
        secondary={translateCampaignTypeExample(CampaignType.ProductDiscountM)}
        data-testid="product-discount-m-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-percentage-discount' },
          () => props.onSelectCampaignType(CampaignType.ProductDiscountX),
        )}
        primary={translateCampaignTypeTitle(CampaignType.ProductDiscountX)}
        secondary={translateCampaignTypeExample(CampaignType.ProductDiscountX)}
        data-testid="product-discount-x-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-x-related-products-discount',
          },
          () =>
            props.onSelectCampaignType(CampaignType.ProductBuyXGetDiscountList),
        )}
        primary={translateCampaignTypeTitle(
          CampaignType.ProductBuyXGetDiscountList,
        )}
        secondary={translateCampaignTypeExample(
          CampaignType.ProductBuyXGetDiscountList,
        )}
        data-testid="buy-x-get-discounts-from-list-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-for-m-get-discount-list',
          },
          () =>
            props.onSelectCampaignType(
              CampaignType.ProductBuyForMGetDiscountList,
            ),
        )}
        primary={translateCampaignTypeTitle(
          CampaignType.ProductBuyForMGetDiscountList,
        )}
        secondary={translateCampaignTypeExample(
          CampaignType.ProductBuyForMGetDiscountList,
        )}
        data-testid="buy-for-m-get-discount-list-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-x-pay-y-get-percentage-discount-rest',
          },
          () =>
            props.onSelectCampaignType(
              CampaignType.ProductBuyXPayForYDiscountZRest,
            ),
        )}
        primary={translateCampaignTypeTitle(
          CampaignType.ProductBuyXPayForYDiscountZRest,
        )}
        secondary={translateCampaignTypeExample(
          CampaignType.ProductBuyXPayForYDiscountZRest,
        )}
        data-testid="buy-x-pay-for-y-and-get-z-discount-on-rest-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-minimum-x-get-percentage-discount',
          },
          () =>
            props.onSelectCampaignType(CampaignType.ProductBuyMinXDiscountY),
        )}
        primary={translateCampaignTypeTitle(
          CampaignType.ProductBuyMinXDiscountY,
        )}
        secondary={translateCampaignTypeExample(
          CampaignType.ProductBuyMinXDiscountY,
        )}
        data-testid="buy-minimum-quantity-x-get-y-discount-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-3-for-2' },
          () =>
            props.onSelectCampaignType(
              CampaignType.ProductBuy3For2Or2For1AndAHalf,
            ),
        )}
        primary={translateCampaignTypeTitle(
          CampaignType.ProductBuy3For2Or2For1AndAHalf,
        )}
        secondary={translateCampaignTypeExample(
          CampaignType.ProductBuy3For2Or2For1AndAHalf,
        )}
        data-testid="buy-3-pay-2-or-buy-2-pay-1-half-listItem"
        hasExample
      />
    </div>
  )
}
