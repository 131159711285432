import { PaymentAdditionalData } from '../../pages/orders/util'

export const getPaymentCardNumber = (additionalData: PaymentAdditionalData) => {
  const cardProperties = [
    'payment-cardmaskedpan',
    'verifone-card-masked-pan',
    'giftcard-cardnumber-display',
    'giftcard-cardnumber',
  ]

  for (const property of cardProperties) {
    const value = additionalData[property]

    if (typeof value === 'string') return value
  }
}
