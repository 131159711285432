import { FilterIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewPanel } from '../../components/view-panel'
import { FilterViewPanelHeader } from './filter-view-panel-header'
import { Box } from '@mui/material'

type FilterViewPanelProps = {
  open: boolean
  loading?: boolean
  children?: React.ReactNode
  footer?: React.ReactNode
  onClose: () => void
}

export const FilterViewPanel = (props: FilterViewPanelProps) => {
  const { onClose, loading, children, footer, open } = props
  const { t } = useTranslation(['shared'])

  const panelRef = useRef<HTMLDivElement>(null)

  return (
    <ViewPanel open={open} ref={panelRef}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <FilterViewPanelHeader
          isLoading={loading}
          section={t('shared:label.filter')}
          sectionIcon={<FilterIcon />}
          onClose={onClose}
        />

        <Box sx={{ flexGrow: 1 }}>{children}</Box>

        {footer}
      </Box>
    </ViewPanel>
  )
}
