import { Container, Grid } from '@mui/material'
import { FilterProvider } from '../../../components/data-grid/context'
import { PageHeader } from '../../../components/page-header'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../hooks/tracking'
import { useReactiveVar } from '@apollo/client'
import { EventList } from './event-list'
import { eventLogsFilterPanelVar, eventLogsViewPanelVar } from './variables'
import { EventListViewPanel } from './event-list-view-panel'
import { EventListFilterPanel } from './event-list-filter-panel'
import { usePageTitle } from '../../../hooks/title'

export type ViewPanelState = {
  isOpen: boolean
  selectedId: string | undefined
}

export const SettingsAccessLogs = () => {
  const { t } = useTranslation(['shared', 'log_events'])
  usePageTitle(t('shared:menu.log_events'))
  const { trackButtonClick } = useTracking()

  const viewPanelState = useReactiveVar(eventLogsViewPanelVar)
  const filterViewPanelState = useReactiveVar(eventLogsFilterPanelVar)

  const openViewPanel = (selectedId: string) => {
    if (filterViewPanelState.isOpen) {
      closeFilterPanel()
    }

    trackButtonClick({ name: 'access-logs-on-row-click', selectedId })

    eventLogsViewPanelVar({ isOpen: true, selectedId })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'access-logs-view-panel-close' })
    eventLogsViewPanelVar({ isOpen: false, selectedId: '' })
  }

  const closeFilterPanel = () => {
    trackButtonClick({ name: 'access-logs-filter-panel-close' })
    eventLogsFilterPanelVar({ isOpen: false })
  }

  const openFilterPanel = () => {
    trackButtonClick({ name: 'access-logs-filter-panel-open' })
    eventLogsFilterPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterPanel()
    } else {
      openFilterPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader title={t('log_events:title')} isFlexible />
          <Container maxWidth={false}>
            <EventList
              onRowClick={openViewPanel}
              selectedId={viewPanelState.selectedId}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>
        <Grid item>
          <EventListViewPanel
            onClose={closeViewPanel}
            open={viewPanelState.isOpen}
            selectedId={viewPanelState.selectedId}
          />
          <EventListFilterPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
