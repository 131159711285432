import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { ServiceOrderQuery } from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { SetStateDialog } from '../set-state-dialog'

type Props = {
  isLoading?: boolean
  serviceOrder?: ServiceOrderQuery['serviceOrder']
}

export const Information = ({ serviceOrder, isLoading }: Props) => {
  const { t } = useTranslation(['service_orders'])
  const { formatDate } = useLocalizedDate()
  const [showSetStateDialog, setShowSetStateDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>{t('information')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List data-testid="service-order-information">
          <ListItemSkeleton
            hide={isLoading}
            secondaryAction={
              <ListItemText>{serviceOrder?.id || '-'}</ListItemText>
            }
            isLoading={isLoading}
            data-testid="service-order-id"
            divider
          >
            <ListItemText primary={t('id')} />
          </ListItemSkeleton>
          <ListItemSkeleton
            hide={isLoading}
            secondaryAction={
              <ListItemText>
                {serviceOrder?.created
                  ? formatDate(String(serviceOrder?.created))
                  : '-'}
              </ListItemText>
            }
            isLoading={isLoading}
            data-testid="service-order-created"
            divider
          >
            <ListItemText primary={t('date_created')} />
          </ListItemSkeleton>
          <ListItemSkeleton
            hide={isLoading}
            secondaryAction={
              <ListItemText>{serviceOrder?.store.name || '-'}</ListItemText>
            }
            isLoading={isLoading}
            data-testid="service-order-store"
            divider
          >
            <ListItemText primary={t('store')} />
          </ListItemSkeleton>

          <ListItemSkeleton
            data-testid="service-order-state-section"
            secondaryAction={
              <>
                <SetStateDialog
                  open={showSetStateDialog}
                  serviceOrder={serviceOrder}
                  onClose={() => setShowSetStateDialog(false)}
                  onSuccess={() => {
                    enqueueSnackbar(
                      t('service_orders:service_order_message.success_update'),
                    )
                    setShowSetStateDialog(false)
                  }}
                  onError={(errorMessage) => {
                    enqueueSnackbar(errorMessage, { variant: 'error' })
                  }}
                />
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => setShowSetStateDialog(true)}
                  data-testid="service-order-set-state-button"
                >
                  {t('shared:action.set')}
                </Button>
              </>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              secondary={t(`states.${serviceOrder?.state || ''}`)}
              data-testid="service-order-state"
            >
              {t('state')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={isLoading}
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="service-order-note"
          >
            <ListItemText
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                },
              }}
              secondary={serviceOrder?.note || '-'}
            >
              {t('note')}
            </ListItemText>
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
