import {
  useEffect,
  useCallback,
  useTransition,
  useMemo,
  useContext,
  Dispatch,
  SetStateAction,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import qs, { ParseOptions, StringifyOptions } from 'query-string'
import { useLocationSpecificKey, usePersistedValues } from '../persisted-values'
import { StateParamsContext } from '../../components/data-grid/context'

interface SetQueryString<TState> {
  (nextState: TState, reset?: boolean): void
}

interface SetQueryState<TState> {
  (nextState: TState, updateQueryString?: boolean, reset?: boolean): void
}

interface SetQueryStateFromString {
  // TODO: extract updateQueryString and reset to options object
  (stateString: string, updateQueryString?: boolean, reset?: boolean): void
}

interface Options {
  parseOptions?: ParseOptions
  stringifyOptions?: StringifyOptions
  persistState?: boolean
  forceLocalState?: boolean
}

export function useStateParams<TState extends Record<string, unknown>>(
  initialState?: TState,
  qsOptions?: Options,
): [
  TState,
  SetQueryState<TState>,
  SetQueryString<TState>,
  SetQueryStateFromString,
] {
  const [, setSearchParams] = useSearchParams()
  const [, startTransition] = useTransition()

  const options = useMemo(
    () =>
      ({
        persistState: true,
        parseOptions: { parseNumbers: false, arrayFormat: 'comma' },
        stringifyOptions: { arrayFormat: 'comma' },
        ...qsOptions,
      }) as Options,
    [qsOptions],
  )

  const persistKey = useLocationSpecificKey('queryStringFilter')

  const [persistedValues, setValues, appendValues] = usePersistedValues<
    Record<string, unknown>
  >(persistKey, undefined, {
    raw: false,
    serializer: (value) => qs.stringify(value, options.stringifyOptions),
    deserializer: (value) => qs.parse(value, options.parseOptions),
  })

  // Context State
  const [contextState, setContextState] = useContext(StateParamsContext) as [
    TState,
    Dispatch<SetStateAction<TState>>,
  ]

  // Local State
  const [localState, setLocalState] = useState(
    qs.parse(location.search, options?.parseOptions) as TState,
  )

  // If Context State does not exist, fallback to Local State
  const hasContextState = !options.forceLocalState && !!contextState

  const [state, setState] = [
    hasContextState ? contextState : localState,
    hasContextState ? setContextState : setLocalState,
  ]

  // First render set state from location.search or initialState
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialState,
      ...(options?.persistState ? persistedValues : {}),
      ...(qs.parse(location.search, options?.parseOptions) as TState),
    }))
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Restore persisted queryParameters on component mount
  useEffect(() => {
    const currentQueryString = qs.stringify(
      qs.parse(window.location.search, options.parseOptions),
      options.stringifyOptions,
    )

    const persistedQueryString = qs.stringify(
      persistedValues || {},
      options.stringifyOptions,
    )

    if (!currentQueryString && persistedQueryString) {
      setSearchParams(persistedQueryString, { replace: true })
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // On history back, sync state with location.search
  useEffect(() => {
    const onPopState = () => {
      const parsed = qs.parse(location.search, options?.parseOptions) as TState

      setState(parsed)
    }

    window.addEventListener('popstate', onPopState)

    return () => {
      window.removeEventListener('popstate', onPopState)
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setQueryString = useCallback<SetQueryString<TState>>(
    (nextState, reset) => {
      startTransition(() => {
        const currentSearchString = qs.stringify(
          qs.parse(window.location.search, options?.parseOptions),
        )

        const nextSearchString = qs.stringify(
          {
            ...(reset
              ? {}
              : qs.parse(window.location.search, options.parseOptions)),
            ...nextState,
          },
          options?.stringifyOptions,
        )

        if (reset || currentSearchString !== nextSearchString) {
          setSearchParams(nextSearchString)
        }
      })
    },
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const setQueryState = useCallback<SetQueryState<TState>>(
    (nextState, updateQueryString = true, reset = false) => {
      setState((prevState) => ({ ...(reset ? {} : prevState), ...nextState }))

      if (updateQueryString) {
        if (options?.persistState) {
          if (reset) setValues(nextState)
          else appendValues(nextState)
        }
        setQueryString(nextState, reset)
      }
    },
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const setQueryStateFromString = useCallback(
    (stateString: string, updateQueryString?: boolean, reset?: boolean) => {
      const nextState = qs.parse(stateString, options.parseOptions)

      setQueryState(nextState as TState, updateQueryString, reset)
    },
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  // TODO: return an object instead of an array
  return [state, setQueryState, setQueryString, setQueryStateFromString]
}
