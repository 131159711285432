import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import { usePageTitle } from '../../hooks/title'
import { useReactiveVar } from '@apollo/client'
import { navbarHeightReactiveVar } from '../../cache'
import { DEFAULT_SITE_ID, PAGE_BOTTOM_OFFSET } from '../../utils/constants'
import { ErrorIcon } from '@sitoo/mui-components'
import { useTracking } from '../../hooks/tracking'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '..'
import { useMe } from '../../hooks/me'

export const NotFoundPage = () => {
  const { t } = useTranslation(['shared'])
  const navbarHeight = useReactiveVar(navbarHeightReactiveVar)
  const { me } = useMe()
  const siteId = me?.siteId || DEFAULT_SITE_ID
  const { trackButtonClickEvent } = useTracking()
  const generatePath = useAbsolutePath()
  const navigate = useNavigate()

  usePageTitle(t('shared:error.resource_not_found'))

  return (
    <Box
      sx={{
        height: `calc(100vh - ${navbarHeight}px - ${PAGE_BOTTOM_OFFSET}px)`,
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <ErrorIcon
          color="error"
          sx={{
            height: (theme) => theme.spacing(8),
            width: (theme) => theme.spacing(8),
          }}
        />

        <Typography
          variant="displayLarge"
          sx={{ m: 3, mb: 3, textAlign: 'center' }}
        >
          {t('shared:error.resource_not_found')}
        </Typography>

        <Button
          onClick={trackButtonClickEvent({ name: '404-go-home' }, () =>
            navigate(generatePath(RootRoute.Root, { siteId })),
          )}
        >
          {t('shared:error.home_button_label')}
        </Button>
      </Box>
    </Box>
  )
}
