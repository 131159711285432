import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  JournalReceiptItem,
  JournalReceipt as JournalReceiptType,
} from '../../../generated/graphql'
import { JournalItem } from '.'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { JournalDivider } from './journal-divider'
import { useMoney } from '../../../hooks/money'
import { PrintContainer } from './print-container'

type Props = {
  journal: JournalItem
  data: JournalReceiptType
}

export const JournalReceipt = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals', 'orders'])
  const { journal, data } = props
  const { formatDate } = useLocalizedDate()
  const getDate = (date: string) => formatDate(new Date(date))
  const { formatCurrency } = useMoney()

  const campaigns = data.items
    .flatMap((item) =>
      item.discounts.map((d) => ({
        name: d.discountname,
        value: Math.abs(Number(d.moneytotal)),
      })),
    )
    .reduce(
      (p, c) => {
        const campaign = p.find((x) => x.name === c.name)

        if (campaign) campaign.value += c.value
        else p.push(c)

        return p
      },
      [] as {
        name: string
        value: number
      }[],
    )

  const getManualDiscount = (item: JournalReceiptItem) => {
    const additionalData = item.additionaldata as Record<string, string> | null

    if (
      Number(item.moneydiscount) === 0 ||
      !additionalData?.['reasoncode-manualdiscount']
    )
      return null

    return {
      reasonCode: additionalData?.['reasoncode-manualdiscount'],
      discount: Number(item.moneydiscount),
    }
  }

  const getManualPriceChange = (item: JournalReceiptItem) => {
    const additionalData = item.additionaldata as Record<string, string> | null

    return additionalData?.['reasoncode-manualprice'] || null
  }

  return (
    <PrintContainer>
      <Box>{journal.datecreated ? getDate(journal.datecreated) : ''}</Box>

      <Box>
        <strong>
          {t('journals:journal_entry_id', {
            type: t(`journals:journal_entry_type_enum.${journal.type}`),
            id: journal.id,
          })}
        </strong>
      </Box>

      <Box>
        {data.staff} ({data.staffuserid})
      </Box>

      <Box>
        {t('journals:receipt_number')}: {data.receiptnumber}
      </Box>

      {data.creditorderid && (
        <Box>
          {t('journals:credit_order_id')}: {data.creditorderid}
        </Box>
      )}

      <Box>
        {t('journals:currency_code')}: {data.currencycode}
      </Box>

      {data.isrefund && (
        <Box>
          {t('journals:is_refund')}: {t('shared:label.yes')}
        </Box>
      )}

      {journal?.ispractice && (
        <Box>
          {t('journals:is_practice')}: {t('shared:label.yes')}
        </Box>
      )}

      <JournalDivider variant="dashed" />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>{t('journals:item')}</Box>
        <Box>{t('journals:amount')}</Box>
      </Box>
      <JournalDivider variant="dashed" />

      {data.items.map((item, i) => {
        const manualDiscount = getManualDiscount(item)
        const manualPriceChange = getManualPriceChange(item)
        return (
          <Box key={i}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>{item.productname}</Box>
              <Box>{formatCurrency(item.moneytotal)}</Box>
            </Box>

            <Box sx={{ ml: 1 }}>
              {item.variant && <Box>{item.variant}</Box>}
              <Box>{item.sku}</Box>
              {(manualDiscount || item.quantity !== 1) && (
                <Box>{`${Math.abs(item.quantity)} x ${
                  manualDiscount
                    ? `${formatCurrency(
                        Number(item.moneyunitprice) -
                          Number(manualDiscount.discount) /
                            Math.abs(item.quantity),
                      )} (${formatCurrency(item.moneyunitprice)})`
                    : formatCurrency(item.moneyunitprice)
                }`}</Box>
              )}
              {manualDiscount && (
                <Box>
                  -{' '}
                  {t('orders:view_panel.reason_code_manual_discount', {
                    code: manualDiscount.reasonCode,
                  })}
                </Box>
              )}
              {manualPriceChange && (
                <Box>
                  {t('orders:view_panel.reason_code_manual_price', {
                    code: manualPriceChange,
                  })}
                </Box>
              )}
              <Box>{item.productgroupname}</Box>

              {item.discounts.length > 0 &&
                item.discounts.map((discount) => (
                  <Box
                    key={discount.id}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Box>{discount.discountname}</Box>
                    <Box>
                      {formatCurrency(-Math.abs(Number(discount.moneytotal)))}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        )
      })}

      <JournalDivider variant="dashed" />

      {Number(data.moneyroundoff) !== 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>{t('journals:cash_rounding')}:</Box>
          <Box>{formatCurrency(data.moneyroundoff)}</Box>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>{t('journals:total')}:</Box>
        <Box>{formatCurrency(data.moneytotal)}</Box>
      </Box>

      {data.payments.map((payment, i) => (
        <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>{payment.paymentname}</Box>
          <Box>{formatCurrency(payment.moneycaptured)}</Box>
        </Box>
      ))}

      <Box>&#8203;</Box>

      {data.vatsummary.map((vat, i) => (
        <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {t('journals:vat_title', {
              value: `${vat.vatvalue}%`,
              total: formatCurrency(vat.moneynet),
            })}
            :
          </Box>
          <Box>{formatCurrency(vat.moneyvat)}</Box>
        </Box>
      ))}

      {campaigns.length > 0 && (
        <>
          <JournalDivider variant="dashed" />
          {campaigns.map((campaign, i) => (
            <Box
              key={i}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box>{campaign.name}</Box>
              <Box>{formatCurrency(campaign.value * -1)}</Box>
            </Box>
          ))}
        </>
      )}

      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
