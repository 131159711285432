import { QueryHookOptions, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { Exact, MeDocument, MeQuery } from '../../generated/graphql'
import { formatUserDisplayName } from '../../utils/format/display-name'

type Props = QueryHookOptions<MeQuery, Exact<{ [key: string]: never }>>

export const useMe = (props?: Props) => {
  const { data, loading } = useQuery(MeDocument, props)

  const me = data?.me
  const timeZone = me?.timeZone
  const siteId = me?.siteId
  const user = me?.user

  const displayName = useMemo(() => formatUserDisplayName(user), [user])

  return { loading, me, displayName, timeZone, siteId }
}
