import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { ActivateVariantsField } from './activate-variants-field'
import { VariantsSummary } from './variants-summary'

type VariantsFieldProps = {
  navigateToVariantsTab(): void
}

export const VariantsField = (props: VariantsFieldProps) => {
  const { watch } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const isSingleProduct =
    watch('product.isSingleProduct') ||
    watch('product.isSingleProduct') === undefined

  if (isSingleProduct && !writeProducts) return null

  return (
    <FormFieldset label={t('products:product_form.variants_fieldset')}>
      {(isSingleProduct && (
        <ActivateVariantsField
          navigateToVariantsTab={props.navigateToVariantsTab}
        />
      )) || (
        <VariantsSummary navigateToVariantsTab={props.navigateToVariantsTab} />
      )}
    </FormFieldset>
  )
}
