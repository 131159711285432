import { Category, Maybe } from '../../../generated/graphql'

/**
 * It concatenates all the parent category names to a string like:
 * Root Category / Parent Category / Target Category
 * @param categoryId - target category id
 * @param categories - a list of all the categories
 * @param fullPath - build a full category path e.e "Parent / Child"
 */
export const formatCategoryName = (
  categoryId: Maybe<number> | undefined,
  categories: Maybe<Category[]> | undefined,
  fullPath?: boolean,
): string | undefined => {
  if (!categories?.length) {
    return
  }

  const rowCategories: string[] = []
  let parentId: number | null | undefined = categoryId

  do {
    const category = categories?.find(({ id }) => id === parentId)

    if (!category) break

    rowCategories.unshift(category.title)

    if (!fullPath) break

    parentId = category.categoryparentid
  } while (parentId)

  if (rowCategories.length) {
    return rowCategories.join(' / ')
  }
}
