import { useFragment } from '@apollo/client'
import { Box, List } from '@mui/material'
import { DocumentationIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewPanel, ViewPanelHeader } from '../../../../components/view-panel'
import {
  LogEventFragment,
  LogEventFragmentDoc,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { Details } from './details'
import { EventResult } from '../event-result'
import { EventType } from '../event-type'
import { AccessEventData } from '../event-list'

type Props = {
  open: boolean
  onClose: () => void
  selectedId?: string
}

export type AccessLogEvent = LogEventFragment & { data: AccessEventData }

export const EventListViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'log_events'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const { formatDate } = useLocalizedDate()

  const { data, complete } = useFragment({
    fragment: LogEventFragmentDoc,
    from: {
      __typename: 'LogEvent',
      id: props.selectedId || '',
    },
  })

  const eventData = data as AccessLogEvent

  const type = complete ? eventData.data.session_type : undefined
  const result = complete ? eventData.data.result : undefined

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={!complete}
        title={formatDate(eventData.timestamp || new Date())}
        section={t('log_events:title')}
        onClose={props.onClose}
        hideActionsSkeleton
        onBack={() => {
          trackButtonClick({ name: 'access-logs-panel-go-back' })
        }}
        sectionIcon={<DocumentationIcon />}
        tags={
          <>
            <EventType type={type} />
            <EventResult result={result} />
          </>
        }
      />

      <Box sx={{ background: (theme) => theme.palette.background.paper }}>
        <List>{complete && <Details event={eventData} />}</List>
      </Box>
    </ViewPanel>
  )
}
