import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../components/page-header'
import { usePageTitle } from '../../hooks/title'

export const StatisticsPage = () => {
  const { t } = useTranslation(['shared', 'statistics'])
  usePageTitle(t('statistics:page_title'))

  return (
    <>
      <PageHeader title="Statistics" />
    </>
  )
}
