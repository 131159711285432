import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { DeleteProductsDocument } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'

export type DeleteProductDialogProps = {
  open: boolean
  productIds: number[]
  dataTestid?: string
  onClose?(): void
  onSuccess?(successfulProductIds: number[], failedProductIds: number[]): void
  onError?(successfulProductIds: number[], failedProductIds: number[]): void
}

export const DeleteProductDialog = (props: DeleteProductDialogProps) => {
  const { t } = useTranslation('products')
  const dialogName = 'delete-product'
  const [deleteMutation, { loading }] = useMutation(DeleteProductsDocument)
  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const deleteProducts = async () => {
    const successfulProductIds: number[] = []
    const failedProductIds: number[] = []

    trackButtonClick({ name: `${dialogName}-delete` })

    try {
      const mutationResponse = await deleteMutation({
        variables: {
          data: {
            productids: props.productIds,
          },
        },
      })

      for (const [index, wasProductDeleted] of Array.from(
        mutationResponse.data?.deleteProducts.entries() || [],
      )) {
        const productId = props.productIds?.[index] || 0

        if (wasProductDeleted) {
          successfulProductIds.push(productId)
        } else {
          failedProductIds.push(productId)
        }
      }

      trackFormSuccess({ name: `${dialogName}-dialog` })

      props.onSuccess?.(successfulProductIds, failedProductIds)
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      props.onError?.(successfulProductIds, failedProductIds)
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={deleteProducts}
      title={t('delete_dialog.title', { count: props.productIds.length })}
      text={t('delete_dialog.description')}
      variant="destructive"
      open={props.open}
      dataTestid="confirm-product-deletion"
      onClose={onClose}
    />
  )
}
