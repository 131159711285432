import {
  FieldFunctionOptions,
  FieldMergeFunction,
  FieldReadFunction,
  InMemoryCache,
  makeVar,
  Reference,
} from '@apollo/client'
import { PaletteMode } from '@mui/material'
import { PAGE_HEADER_HEIGHT, NAVBAR_HEIGHT } from '../utils/constants'
import { makeVarPersisted } from './make-var-persisted'
import { FilterValue } from '../generated/graphql'
import { StrictTypedTypePolicies } from '../generated/apollo-helpers'
import { VariantType } from 'notistack'

type Envelope = {
  items: Reference[]
  start: number
}

const mergeItems: FieldMergeFunction<Envelope> = (
  existing,
  incoming,
  options,
) => {
  const { args } = options as FieldFunctionOptions<{ start: number }>
  const start = args?.start || 0
  const merged = [...(existing?.items || [])]
  incoming.items.forEach((item, index) => {
    merged[start + index] = item
  })

  return {
    ...incoming,
    items: merged.slice(0, start + incoming.items.length),
  }
}

const getCursorArgs = (args: Record<string, unknown> | null) => {
  if (args) {
    const { next, start, ...filter } = args
    return JSON.stringify(filter)
  }
}

const readItems: FieldReadFunction<Envelope> = (existing, options) => {
  if (existing) {
    const { args } = options as FieldFunctionOptions<{
      start: number
      num: number
    }>

    const start = existing?.start || 0
    const num = args?.num || 10
    const items = Object.values(existing.items || [])
    return { ...existing, items: items.slice(0, start + num) }
  }
}

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      filter: {
        read() {
          return filterVar()
        },
      },
      shipments: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      getShipmentsV2: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      journalEntries: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      salesTaxes: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      salesTaxGroups: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      searchOrders: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      serviceOrders: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      orders: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      cashRegisters: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      customAttributes: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      users: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      influencerCodes: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      products: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      purchasePriceLists: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      accessLogs: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },
      warehouseBatchItems: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },

      replenishmentItems: {
        keyArgs: getCursorArgs,
        merge: mergeItems,
        read: readItems,
      },

      cashRegister(_, { args, toReference }) {
        return toReference({
          __typename: 'CashRegister',
          registerid: args?.['id'] as string,
        })
      },
    },
  },
  Product: {
    keyFields: ['id'],
  },
  PricelistItem: {
    keyFields: ['sku', 'pricelistid'],
  },

  PurchasePriceListItem: {
    keyFields: ['sku', 'priceListId'],
  },
  ReasonCode: {
    keyFields: ['reasoncodeid'],
  },
  ShipmentV2: {
    keyFields: ['shipmentid'],
  },
  ShipmentV2Package: {
    keyFields: ['shipmentpackageid'],
  },
  ShipmentV2Item: {
    keyFields: ['shipmentitemid', 'shipmentpackageid'],
  },
  Order: {
    keyFields: ['orderid'],
  },
  OrderDelivery: {
    keyFields: ['orderdeliveryid'],
  },
  OrderDeliveryItem: {
    keyFields: ['orderdeliveryitemid'],
  },
  WarehouseBatch: {
    keyFields: ['warehousebatchid'],
  },
  WarehouseBatchItem: {
    keyFields: ['warehousebatchitemid'],
  },

  CashRegister: {
    keyFields: ['registerid'],
  },

  Journal: {
    keyFields: ['id', 'registerid', 'type'],
  },

  LogEvent: {
    keyFields: ['id'],
  },

  JournalReceiptItemDiscount: {
    keyFields: ['id', 'moneytotal', 'moneyvat'],
  },

  InfluencerCode: {
    keyFields: ['code'],
  },
  Settings: {
    keyFields: [],
  },
  StoreReplenishmentItem: {
    keyFields: ['sku'],
  },
  PaymentType: {
    keyFields: ['paymenttypeid'],
  },
  UserTag: {
    keyFields: ['id'],
  },
}

export const cache: InMemoryCache = new InMemoryCache({ typePolicies })

export const navbarHeightReactiveVar = makeVar<number>(NAVBAR_HEIGHT)

export const pageHeaderHeightReactiveVar = makeVar<number>(PAGE_HEADER_HEIGHT)

export const sidebarOpenState = makeVarPersisted<boolean | undefined>(
  undefined,
  'apollo:sidebarState',
)

export const themeState = makeVarPersisted<PaletteMode>(
  'light',
  'apollo:themeState',
)

type NotificationType = {
  variant: VariantType
  message: string
}

export const globalNotificationState = makeVar<NotificationType | undefined>(
  undefined,
)

export const filterVar = makeVarPersisted<FilterValue[]>([], 'filterPreset')
