import { MenuItem, TextField, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

export enum CampaignTypes {
  All = 'all',
  Cart = 'cart',
  Product = 'product',
  Package = 'package',
  Pricelist = 'pricelist',
}

type QueryParamState = {
  campaignType?: CampaignTypes
}

export const CampaignTypeFilter = () => {
  const { t } = useTranslation(['campaigns', 'shared'])
  const { trackButtonClick } = useTracking()
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'campaignType'
  const [queryParams, setQueryParams] = useStateParams<QueryParamState>()

  const campaignType = useMemo(() => {
    const value = queryParams[filterKey]

    if (Object.values(CampaignTypes).includes(value as CampaignTypes)) {
      return value
    }

    return CampaignTypes.All
  }, [queryParams])

  const getCampaignTypeLabel = useCallback(
    (type: CampaignTypes) => {
      if (type === CampaignTypes.All) return t('shared:label.all')
      if (type === CampaignTypes.Cart)
        return t('campaigns:campaign_type_groups.cart')
      if (type === CampaignTypes.Product)
        return t('campaigns:campaign_type_groups.product')
      if (type === CampaignTypes.Package)
        return t('campaigns:campaign_type_groups.package')
      if (type === CampaignTypes.Pricelist)
        return t('campaigns:campaign_type_groups.pricelist')

      return ''
    },
    [t],
  )

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    if (campaignType === CampaignTypes.All) {
      setFilter(filterKey, {
        isDefault: true,
        label: t('campaigns:campaign_type_filter_tag_label', {
          type: getCampaignTypeLabel(CampaignTypes.All),
        }),
        value: '',
      })
    } else {
      setFilter(filterKey, {
        label: campaignType
          ? t('campaigns:campaign_type_filter_tag_label', {
              type: getCampaignTypeLabel(campaignType),
            })
          : '',
        value: String(campaignType),
      })
    }
  }, [campaignType, getCampaignTypeLabel, removeFilter, setFilter, t])

  return (
    <TextField
      onChange={(event) => {
        trackButtonClick({
          name: 'campaign-type-filter-select-type',
          type: event.target.value,
        })
        setQueryParams({ [filterKey]: event.target.value as CampaignTypes })
      }}
      value={campaignType}
      select
      label={t('campaigns:type')}
      data-testid="campaign-type-filter"
    >
      <MenuItem value={CampaignTypes.All} divider>
        <Typography variant="body02">
          {getCampaignTypeLabel(CampaignTypes.All)}
        </Typography>
      </MenuItem>
      <MenuItem value={CampaignTypes.Cart}>
        <Typography variant="body02">
          {getCampaignTypeLabel(CampaignTypes.Cart)}
        </Typography>
      </MenuItem>
      <MenuItem value={CampaignTypes.Product}>
        <Typography variant="body02">
          {getCampaignTypeLabel(CampaignTypes.Product)}
        </Typography>
      </MenuItem>
      <MenuItem value={CampaignTypes.Package}>
        <Typography variant="body02">
          {getCampaignTypeLabel(CampaignTypes.Package)}
        </Typography>
      </MenuItem>
      <MenuItem value={CampaignTypes.Pricelist}>
        <Typography variant="body02">
          {getCampaignTypeLabel(CampaignTypes.Pricelist)}
        </Typography>
      </MenuItem>
    </TextField>
  )
}
