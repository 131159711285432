import { Divider, List, ListItem, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  GetStoresQuery,
  GetProductQuery,
  GetStoresDocument,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { SectionHeader } from '@sitoo/mui-components'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { Fragment } from 'react'
import { useQuery } from '@apollo/client'

type StockLevelTabProps = {
  product?: GetProductQuery['product']
  isLoading?: boolean
}

type StoreStock = {
  store: ArrayElement<GetStoresQuery['stores']>
  totalSellableStock: number
}

export const StockLevelTab = ({
  product,
  isLoading: isLoadingProduct,
}: StockLevelTabProps) => {
  const { t } = useTranslation(['shared', 'products'])

  const { data: allStoresData, loading: isLoadingStores } =
    useQuery(GetStoresDocument)
  const allStores = allStoresData?.stores || []
  const warehouseItems = product?.warehouseItems || []

  const isLoading = isLoadingProduct || isLoadingStores

  const stores = allStores.reduce(
    (p, store) => {
      p[store.id] = {
        store: store,
        totalSellableStock: 0,
      }

      return p
    },
    {} as { [storeId: string]: StoreStock },
  )

  for (const warehouseItem of warehouseItems) {
    if (!warehouseItem.warehouse?.sellable) continue

    const storeId = warehouseItem.warehouse?.store?.id
    if (!storeId) continue

    const store = stores[storeId]
    if (store) {
      store.totalSellableStock += Number(warehouseItem.decimaltotal)
    }
  }

  const storeEntries = Object.entries(stores).sort((a, b) =>
    a[1].store.name.localeCompare(b[1].store.name),
  )

  return (
    <List>
      {isLoading && (
        <>
          <ListItemSkeleton
            sx={{
              background: (theme) => theme.palette.background.default,
              padding: (theme) => theme.spacing(1.5, 2),
              minHeight: 0,
            }}
            isLoading={isLoading}
          >
            <SectionHeader />
          </ListItemSkeleton>
          <ListItemSkeleton secondaryAction="" isLoading={isLoading} />
          <Divider />
          <ListItemSkeleton secondaryAction="" isLoading={isLoading} />
        </>
      )}
      {storeEntries.length > 0 && (
        <ListItem disablePadding sx={{ minHeight: 'auto' }}>
          <SectionHeader>{t('products:view_panel.all_stores')}</SectionHeader>
        </ListItem>
      )}
      {storeEntries.map(([storeId, data], i) => (
        <Fragment key={storeId}>
          <ListItemSkeleton
            secondaryAction={<ListItemText primary={data.totalSellableStock} />}
            isLoading={isLoading}
          >
            <ListItemText primary={data.store.name || '-'} />
          </ListItemSkeleton>
          {i + 1 < storeEntries.length && <Divider />}
        </Fragment>
      ))}
    </List>
  )
}
