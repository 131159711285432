import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../../components/filter-view-panel'
import { DateFilter, OptionType } from '../../../../components/date-filter'

type Props = {
  isOpen: boolean
  onClose(): void
}

export const EventListFilterPanel = (props: Props) => {
  const { isOpen, onClose } = props
  const { t } = useTranslation(['log_events'])

  return (
    <FilterViewPanel open={isOpen} onClose={onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>{t('log_events:title')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <DateFilter
                defaultOptionType={OptionType.Today}
                hiddenOptions={[
                  OptionType.AllTime,
                  OptionType.CustomRange,
                  OptionType.Last90Days,
                ]}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
