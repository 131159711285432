import { t } from 'i18next'
import { CampaignType } from '../../../../generated/graphql'

export type FieldName =
  | 'value_x'
  | 'value_y'
  | 'value_z'
  | 'money_m'
  | 'money_n'
  | 'vouchername1'
  | 'vouchercode1'
  | 'vouchername2'
  | 'vouchercode2'
  | 'maxuses'
  | 'productoptions'
  | 'discountoptions'
  | 'pricelisttags'

export const getFormRules = (
  type?: CampaignType,
): Partial<Record<FieldName, string>> | null => {
  switch (type) {
    case CampaignType.OrderDiscountM:
      return {
        money_m: t('campaigns:campaign_types_labels.100_money_m'),
      }
    case CampaignType.OrderDiscountX:
      return {
        value_x: t('campaigns:campaign_types_labels.110_value_x'),
      }
    case CampaignType.OrderBuyForMGetDiscountN:
      return {
        money_m: t('campaigns:campaign_types_labels.120_money_m'),
        money_n: t('campaigns:campaign_types_labels.120_money_n'),
      }
    case CampaignType.ProductDiscountM:
      return {
        money_m: t('campaigns:campaign_types_labels.200_money_m'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
      }
    case CampaignType.ProductDiscountX:
      return {
        value_x: t('campaigns:campaign_types_labels.210_value_x'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
      }
    case CampaignType.ProductBuyXForPriceM:
      return {
        value_x: t('campaigns:campaign_types_labels.220_value_x'),
        money_m: t('campaigns:campaign_types_labels.220_money_m'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
      }
    case CampaignType.ProductBuyXPayForY:
      return {
        value_x: t('campaigns:campaign_types_labels.230_value_x'),
        value_y: t('campaigns:campaign_types_labels.230_value_y'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
      }
    case CampaignType.ProductBuyXPayForYDiscountZRest:
      return {
        value_x: t('campaigns:campaign_types_labels.240_value_x'),
        value_y: t('campaigns:campaign_types_labels.240_value_y'),
        value_z: t('campaigns:campaign_types_labels.240_value_z'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
      }
    case CampaignType.ProductBuyMinXDiscountY:
      return {
        value_x: t('campaigns:campaign_types_labels.250_value_x'),
        value_y: t('campaigns:campaign_types_labels.250_value_y'),
      }
    case CampaignType.ProductBuyXGetDiscountList:
      return {
        value_x: t('campaigns:campaign_types_labels.260_value_x'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
        discountoptions: t(
          'campaigns:campaign_types_labels.260_discountoptions',
        ),
      }
    case CampaignType.ProductBuyForMGetDiscountList:
      return {
        money_m: t('campaigns:campaign_types_labels.265_money_m'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
        discountoptions: t(
          'campaigns:campaign_types_labels.265_discountoptions',
        ),
      }
    case CampaignType.ProductBuy3For2Or2For1AndAHalf:
      return {
        vouchername1: t('campaigns:campaign_types_labels.270_vouchername1'),
        vouchercode1: t('campaigns:campaign_types_labels.270_vouchercode1'),
        vouchername2: t('campaigns:campaign_types_labels.270_vouchername2'),
        vouchercode2: t('campaigns:campaign_types_labels.270_vouchercode2'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
      }
    case CampaignType.PackageBuyForPriceM:
      return {
        money_m: t('campaigns:campaign_types_labels.300_money_m'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
        productoptions: t('campaigns:campaign_types_labels.300_productoptions'),
      }
    case CampaignType.PackageDiscountX:
      return {
        value_x: t('campaigns:campaign_types_labels.310_value_x'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
        productoptions: t('campaigns:campaign_types_labels.300_productoptions'),
      }
    case CampaignType.PackageDiscountM:
      return {
        money_m: t('campaigns:campaign_types_labels.320_money_m'),
        maxuses: t('campaigns:campaign_types_labels.maxuses'),
        productoptions: t('campaigns:campaign_types_labels.300_productoptions'),
      }
    case CampaignType.PricelistActivate:
      return {
        pricelisttags: t('campaigns:campaign_types_labels.pricelisttags'),
      }
    case CampaignType.PricelistActivateFinal:
      return {
        pricelisttags: t('campaigns:campaign_types_labels.pricelisttags'),
      }

    default:
      return null
  }
}
