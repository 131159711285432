import { Tooltip } from '@mui/material'
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'
import { useLocalizedDate } from '../../../hooks/localized-date'

type Params = GridRenderCellParams<
  GridValidRowModel,
  string | null | undefined
> & {
  formatDate: ReturnType<typeof useLocalizedDate>['formatDate']
}

export const DateTimeRenderer = (params: Params) => {
  const { formatDate } = params

  const fullDate = params.value ? formatDate(new Date(params.value)) : null

  return (
    <Tooltip title={fullDate}>
      <div
        className="MuiDataGrid-cellContent"
        data-testid={`date-field-${params.id}-${params.field}`}
      >
        {fullDate}
      </div>
    </Tooltip>
  )
}
