import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../inputs/autocomplete-input'
import { FilterContext } from '../components/data-grid/context'
import { GetManufacturersDocument } from '../generated/graphql'
import { MAX_NUM_REQUEST } from '../utils/constants'
import { useFormFilter } from '../hooks/form-filter'

export type ManufacturerIdFilter = {
  manufacturerIds?: number[]
}

export const ManufacturerIdFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])

  const filterKey = 'manufacturerIds'

  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext<FilterContext<ManufacturerIdFilter>>(FilterContext)

  const { data, loading: isLoading } = useQuery(GetManufacturersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { num: MAX_NUM_REQUEST },
  })

  const options = useMemo(
    () =>
      [...(data?.manufacturers.items || [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    [data?.manufacturers],
  )

  const { queryParams, formContext, resetForm } =
    useFormFilter<ManufacturerIdFilter>({
      formProps: {
        defaultValues: { manufacturerIds: [] },
      },
      parseOptions: {
        types: { manufacturerIds: (value) => value.split(',').map(Number) },
      },
    })

  const { manufacturerIds } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (isLoading) return

    if (manufacturerIds?.length) {
      setFilter(filterKey, {
        label: t('filter:manufacturer_id.label'),
        labelValues: manufacturerIds.map(
          (id) => options.find((x) => x.id === id)?.name || String(id),
        ),
        value: manufacturerIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [options, manufacturerIds, removeFilter, setFilter, t, isLoading])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="manufacturerIds"
      control={formContext.control}
      options={options.map(({ id }) => id)}
      disabled={isLoading}
      label={t('filter:manufacturer_id.label')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'manufacurer-id-input' },
        },
      }}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) =>
          options.find((x) => x.id === option)?.name || String(option),
      }}
    />
  )
}
