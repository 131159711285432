import { Box, Button } from '@mui/material'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import {
  AllShipmentActionsDocument,
  GetShipmentDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { ShipmentIcon } from '@sitoo/mui-components'
import { Overview } from './overview'
import { Information } from './information'
import { History } from './history'
import { Packages } from './packages'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { RootRoute } from '../..'
import { Link } from 'react-router-dom'
import { UndeclaredItems } from './undeclared-items'
import { useQuery } from '@apollo/client'

type Props = {
  open: boolean
  onClose: () => void
  shipmentId?: string
}

export const ShipmentViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const generatePath = useAbsolutePath()
  const { data, loading: loadingShipment } = useQuery(GetShipmentDocument, {
    variables: { shipmentId: props.shipmentId as string },
    skip: !props.shipmentId,
    fetchPolicy: 'cache-and-network',
  })
  const shipment = data?.shipment

  const { data: shipmentActionsData, loading: loadingShipmentActions } =
    useQuery(AllShipmentActionsDocument, {
      variables: { shipmentId: props.shipmentId as string },
      skip: !props.shipmentId,
    })
  const shipmentActions = shipmentActionsData?.allShipmentActions

  const isLoading = loadingShipment || loadingShipmentActions

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={isLoading}
        title={`#${shipment?.id || ''}`}
        section={t('shipments:page_title')}
        onClose={props.onClose}
        hideActionsSkeleton
        onBack={() => {
          trackButtonClick({ name: 'shipment-panel-go-back' })
        }}
        sectionIcon={<ShipmentIcon />}
        actions={
          <>
            {props.shipmentId && (
              <Button
                component={Link}
                to={generatePath(RootRoute.Shipment, {
                  id: props.shipmentId,
                })}
                color="secondary"
                size="small"
                data-testid="edit-shipment-button"
                onClick={() => {
                  trackButtonClick({
                    name: 'shipment-panel-edit-shipment',
                    shipmentId: props.shipmentId,
                  })
                }}
              >
                {t('shared:action.edit')}
              </Button>
            )}
          </>
        }
      />
      <Box sx={{ background: (theme) => theme.palette.background.paper }}>
        <Overview shipment={shipment} isLoading={isLoading} />
        <Information shipment={shipment} isLoading={isLoading} />
        <Packages shipment={shipment} isLoading={isLoading} />
        <UndeclaredItems shipment={shipment} isLoading={isLoading} />
        <History
          shipmentActions={shipmentActions}
          shipment={shipment}
          isLoading={isLoading}
        />
      </Box>
    </ViewPanel>
  )
}
