import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'

export const InternalNotesField = () => {
  const { t } = useTranslation('campaigns')
  const { register } = useFormContext<CampaignFormContext>()
  return (
    <FormFieldset
      label={t('campaigns:campaign_form.general_information_fieldset')}
    >
      <TextField
        {...register('campaign.vouchercomment')}
        helperText={t('campaigns:campaign_form.internal_notes_description')}
        multiline
        rows={2}
        label={t('campaigns:campaign_form.internal_notes_label')}
      />
    </FormFieldset>
  )
}
