import { useQuery } from '@apollo/client'
import { FormFieldset } from '@sitoo/mui-components'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProductFormContext } from '../..'
import {
  AllCustomAttributesQuery,
  AllCustomAttributesDocument,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { MultipleVariantsAttributes } from './multiple-variants-attributes'
import { SingleProductAttributes } from './single-product-attributes'

export type CustomAttributeValue = string | number | null | undefined

export type CustomAttribute =
  | Record<string, CustomAttributeValue>
  | null
  | undefined

export const useInitializeSupportedCustomAttributes = (
  isProductInitialized: boolean,
  watch: UseFormWatch<ProductFormContext>,
  setValue: UseFormSetValue<ProductFormContext>,
) => {
  const { data, loading } = useQuery(AllCustomAttributesDocument)
  const allCustomAttributes = useMemo(
    () =>
      Array.from(data?.allCustomAttributes || []).sort((a, b) =>
        a.title.localeCompare(b.title),
      ),
    [data?.allCustomAttributes],
  )

  const [isInitiated, setIsInitiated] = useState(false)
  useEffect(() => {
    if (!isProductInitialized || isInitiated || loading) return

    const isSingleProduct =
      watch('product.isSingleProduct') ||
      watch('product.isSingleProduct') === undefined
    let currentCustomAttributes: { id: string }[] | undefined = undefined

    if (isSingleProduct) {
      const productCustomAttributes = watch(
        'product.customattributes',
      ) as CustomAttribute
      currentCustomAttributes = Object.entries(productCustomAttributes || {})
        .map(([attributeId, value]) => ({
          ...allCustomAttributes.find((a) => a.id === attributeId),
          value,
        }))
        .filter(
          (
            c,
          ): c is ArrayElement<
            AllCustomAttributesQuery['allCustomAttributes']
          > & {
            value: CustomAttributeValue
          } => !!c.id,
        )
    } else {
      currentCustomAttributes = watch('product.childVariants')
        ?.flatMap((variant) =>
          Object.keys((variant.customattributes as CustomAttribute) || {}),
        )
        .map((id) => ({ id }))
    }

    setValue(
      'supportedCustomAttributes',
      allCustomAttributes.filter((c) =>
        currentCustomAttributes?.some((v) => v.id === c.id),
      ),
    )
    setIsInitiated(true)
  }, [
    allCustomAttributes,
    isInitiated,
    isProductInitialized,
    loading,
    setValue,
    watch,
  ])
}

export const AttributesField = () => {
  const { watch } = useFormContext<ProductFormContext>()
  const { t } = useTranslation(['products'])

  const { data, loading } = useQuery(AllCustomAttributesDocument)

  const allCustomAttributes = useMemo(
    () =>
      Array.from(data?.allCustomAttributes || []).sort((a, b) =>
        a.title.localeCompare(b.title),
      ),
    [data?.allCustomAttributes],
  )

  const isSingleProduct =
    watch('product.isSingleProduct') ||
    watch('product.isSingleProduct') === undefined

  return (
    <>
      <FormFieldset
        label={t('products:product_form.attributes_fieldset')}
        isLoading={loading}
        sx={{ '.MuiFormFieldset-Paper': { padding: 0 } }}
      >
        {isSingleProduct ? (
          <SingleProductAttributes customAttributes={allCustomAttributes} />
        ) : (
          <MultipleVariantsAttributes customAttributes={allCustomAttributes} />
        )}
      </FormFieldset>
    </>
  )
}
