import { Autocomplete, TextField } from '@mui/material'
import { SyntheticEvent, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { OrderLookupFilterReturnLookupMethodEnum } from '../../../generated/graphql'
import { MAX_ITEMS_ORDER_API } from '.'
import { addTagOnInputChange } from '../../../utils/autocomplete'

type QueryParamsState = {
  returnMethod?: string | string[]
}

export const ReturnLookupMethodFilter = () => {
  const { t } = useTranslation(['filter', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackInputFocus, trackInputBlur } = useTracking()
  const filterKey = 'returnMethod'
  const returnLookupMethods = useMemo(
    () =>
      Object.values(OrderLookupFilterReturnLookupMethodEnum)
        .map((x) => ({
          value: x,
          label: t(`filter:return_lookup_method.${x}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedReturnLookupMethods = useMemo(
    () =>
      queryParams.returnMethod &&
      returnLookupMethods.find(({ value }) =>
        queryParams.returnMethod?.includes(value),
      )
        ? Array.isArray(queryParams.returnMethod)
          ? queryParams.returnMethod.slice(-MAX_ITEMS_ORDER_API)
          : [queryParams.returnMethod]
        : [],
    [queryParams.returnMethod, returnLookupMethods],
  )

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const lookupMethods = returnLookupMethods?.filter(({ value }) =>
      selectedReturnLookupMethods?.includes(value),
    )

    if (lookupMethods.length) {
      setFilter(filterKey, {
        label: t('filter:return_lookup_method.label'),
        labelValues: lookupMethods.map((x) => x.label),
        value: lookupMethods.map((x) => x.value),
      })
    } else {
      removeFilter(filterKey)
    }
  }, [
    removeFilter,
    setFilter,
    selectedReturnLookupMethods,
    returnLookupMethods,
    t,
  ])

  return (
    <Autocomplete
      autoHighlight
      multiple
      limitTags={3}
      fullWidth
      options={returnLookupMethods.map((x) => x.label)}
      onChange={(_event, value) => {
        setQueryParams(
          {
            [filterKey]:
              value
                .map(
                  (label) =>
                    returnLookupMethods.find((x) => x.label === label)?.value,
                )
                .filter(
                  (x): x is OrderLookupFilterReturnLookupMethodEnum => !!x,
                )
                .slice(-MAX_ITEMS_ORDER_API) || undefined,
          },
          hasApply !== true,
        )
      }}
      onInputChange={addTagOnInputChange}
      value={selectedReturnLookupMethods.map(
        (value) =>
          returnLookupMethods.find(
            (x) =>
              x.value === (value as OrderLookupFilterReturnLookupMethodEnum),
          )?.label,
      )}
      data-testid="return-lookup-method-field"
      renderInput={(params) => (
        <TextField
          label={t('filter:return_lookup_method.label')}
          type="text"
          {...params}
          onFocus={trackInputFocus({
            name: `return-lookup-method-${filterKey}`,
          })}
          onBlur={trackInputBlur({ name: `return-lookup-method-${filterKey}` })}
          inputProps={{
            ...params.inputProps,
            ['data-testid']: 'return-lookup-method-input',
          }}
        />
      )}
    />
  )
}
