import { ListItem, ListItemText, Switch, TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'

export const AmountUnitField = () => {
  const { control, register, formState } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()

  return (
    <>
      <FormFieldset
        label={t('products:product_form.amount_unit_fieldset')}
        sx={{
          '.MuiFormFieldset-Paper': { padding: 0 },
        }}
      >
        <Controller
          control={control}
          name="product.allowdecimals"
          render={({ field }) => (
            <ListItem>
              <ListItemText
                data-testid="allow-decimals-field"
                secondary={t(
                  'products:product_form.allow_decimals_description',
                )}
                secondaryTypographyProps={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {t('products:product_form.allow_decimals_title')}
              </ListItemText>

              <Switch
                name="product.allowdecimals"
                date-testid="allow-decimals-toggle"
                checked={typeof field.value === 'boolean' ? field.value : false}
                onChange={(event) => {
                  field.onChange(event.target.checked)
                }}
                disabled={!writeProducts}
              />
            </ListItem>
          )}
        />
      </FormFieldset>

      <FormFieldset>
        <TextField
          data-testid="ordering-unit-field"
          error={!!formState?.errors.product?.unitlabel}
          fullWidth
          helperText={t('products:product_form.unit_description')}
          label={t('products:product_form.unit_title')}
          {...register('product.unitlabel')}
          disabled={!writeProducts}
        />
      </FormFieldset>
    </>
  )
}
