import { Button } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'

type Props = {
  isLoading?: boolean
  onClick(): void
  label?: string
  children?: string
  testId?: string
  disabled?: boolean
}

export const FormDeleteField = (props: Props) => {
  const { isLoading, label, children, onClick, disabled } = props
  return (
    <FormFieldset isLoading={isLoading} label={label}>
      <Button
        color="error"
        onClick={onClick}
        sx={{ textTransform: 'uppercase', width: '100%' }}
        data-testid={props.testId}
        disabled={disabled}
      >
        {children}
      </Button>
    </FormFieldset>
  )
}
