import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StoresInput } from '../../../inputs/stores-input'
import { BaseServiceOrderFormContext } from '../shared'
import { useQuery } from '@apollo/client'
import { GetStoresDocument } from '../../../generated/graphql'
import { useMemo } from 'react'

export const StoreField = () => {
  const { control } = useFormContext<BaseServiceOrderFormContext>()
  const { t } = useTranslation(['service_orders'])

  const { data, loading: isLoading } = useQuery(GetStoresDocument)

  const stores = useMemo(() => {
    return (
      data?.stores
        .filter((store) => store.externalid && store.warehouses?.length)
        .map((store) => ({ id: String(store.externalid), name: store.name })) ||
      []
    )
  }, [data?.stores])

  return (
    <FormFieldset label={t('store')}>
      <StoresInput
        stores={stores}
        isLoading={isLoading}
        control={control}
        name="store"
        required
      />
    </FormFieldset>
  )
}
