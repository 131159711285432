import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { JournalCash as JournalCashType } from '../../../generated/graphql'
import { JournalItem } from '.'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { JournalDivider } from './journal-divider'
import { useMoney } from '../../../hooks/money'
import { PrintContainer } from './print-container'
import { formatAdditionalData } from '../../../utils/format/additional-data'

type Props = {
  journal: JournalItem
  data: JournalCashType
}

export const JournalCash = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal, data } = props
  const { formatDate } = useLocalizedDate()
  const { formatCurrency } = useMoney()
  const getDate = (date: string) => formatDate(new Date(date))
  const additionalData = formatAdditionalData(data.additionaldata)

  return (
    <PrintContainer>
      <Box>{journal.datecreated ? getDate(journal.datecreated) : ''}</Box>

      <Box>
        <strong>
          {t('journals:journal_entry_id', {
            type: t(`journals:journal_entry_type_enum.${journal.type}`),
            id: journal.id,
          })}
        </strong>
      </Box>

      <Box>
        {data.staff} ({data.staffuserid})
      </Box>

      {journal?.ispractice && (
        <Box>
          {t('journals:is_practice')}: {t('shared:label.yes')}
        </Box>
      )}

      {data?.currencycode && (
        <Box>
          {t('journals:currency_code')}: {data.currencycode}
        </Box>
      )}

      {data?.moneycash && (
        <Box>
          {t('journals:money_cash')}: {formatCurrency(data.moneycash)}
        </Box>
      )}

      {data?.comment && (
        <Box>
          {t('journals:comment')}: {formatCurrency(data.comment)}
        </Box>
      )}

      {additionalData?.length && (
        <>
          <Box>{t('journals:additional_data')}</Box>
          <Box sx={{ pl: 2 }}>
            {additionalData?.map(([key, value]) => (
              <Box key={key} sx={{ display: 'block' }}>
                {key}: {value}
              </Box>
            ))}
          </Box>
        </>
      )}

      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
