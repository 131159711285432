import { useParams } from 'react-router-dom'
import { CarrierField } from './fields/carrier'
import { SenderField } from './fields/sender'
import { ReceiverField } from './fields/receiver'
import { Divider } from '@mui/material'
import { GeneralInfoField } from './fields/general-info'
import { Packages } from './fields/packages'
import { EditInformation } from './fields/edit-information'
import { ArchiveShipment } from './fields/archive-shipment'

export const Information = () => {
  const { id: shipmentId } = useParams()
  const isNewShipment = shipmentId === undefined

  return (
    <>
      <SenderField />
      <ReceiverField />
      <Divider />

      <Packages />
      <Divider />

      {isNewShipment ? (
        <>
          <CarrierField />
          <Divider />
          <GeneralInfoField />
        </>
      ) : (
        <>
          <EditInformation />
          <ArchiveShipment shipmentId={Number(shipmentId)} />
        </>
      )}
    </>
  )
}
