import { useQuery } from '@apollo/client'
import { ListItemText, Container, Paper } from '@mui/material'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import {
  LoginOptionsQuery,
  LoginOptionTypeEnum,
  LoginOptionsDocument,
} from '../../../generated/graphql'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ProductSettingsList } from '../products/product-settings-list'
import { AddUpdateLoginOptionDialog } from './add-update-login-option-dialog'
import { DeleteLoginOptionDialog } from './delete-login-option-dialog'

export const SettingsAuthenticationPage = () => {
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.authentication'))
  const { trackButtonClick } = useTracking()

  const { data: loginOptionsQuery, loading: loginOptionsLoading } = useQuery(
    LoginOptionsDocument,
    { fetchPolicy: 'cache-and-network' },
  )

  const allLoginOptions = useMemo(() => {
    const loginOptions = loginOptionsQuery?.loginOptions
      ? Array.from(loginOptionsQuery?.loginOptions)
      : []

    if (loginOptions.length === 0) {
      loginOptions.push({
        id: 'DEFAULT',
        type: LoginOptionTypeEnum.SitooLogin,
      })
    }

    return loginOptions
  }, [loginOptionsQuery?.loginOptions])

  const hasOnlySitooLogin =
    allLoginOptions.length === 1 &&
    allLoginOptions[0]?.type === LoginOptionTypeEnum.SitooLogin

  const allLoginOptionTypes = Object.values(LoginOptionTypeEnum)

  const availableLoginOptionTypes = allLoginOptionTypes.filter(
    (loginOptionType) =>
      !allLoginOptions.some((f) => f.type === loginOptionType),
  )

  const [showAddUpdateDialog, setShowAddUpdateDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [selectedLoginOption, setSelectedLoginOption] = useState<ArrayElement<
    LoginOptionsQuery['loginOptions']
  > | null>(null)

  const onEdit = ({
    id,
    type,
  }: ArrayElement<LoginOptionsQuery['loginOptions']>) => {
    trackButtonClick({ name: 'settings-login-option-edit' })

    setSelectedLoginOption({ id, type })
    setShowAddUpdateDialog(true)
  }

  const onAdd = () => {
    trackButtonClick({ name: 'settings-login-option-add' })

    setSelectedLoginOption(null)
    setShowAddUpdateDialog(true)
  }

  const onDelete = ({
    id,
    type,
  }: ArrayElement<LoginOptionsQuery['loginOptions']>) => {
    trackButtonClick({ name: 'settings-login-option-delete' })

    setSelectedLoginOption({ id, type })
    setShowDeleteDialog(true)
  }

  const canEdit = (
    element: ArrayElement<LoginOptionsQuery['loginOptions']>,
  ) => {
    return !hasOnlySitooLogin && element.type !== LoginOptionTypeEnum.SitooLogin
  }

  const isLoading = loginOptionsLoading

  return (
    <>
      <PageHeader title={t('shared:menu.authentication')} />
      <Container>
        <Paper elevation={0}>
          <ProductSettingsList
            items={isLoading ? undefined : allLoginOptions}
            baseDataTestId="login-option"
            addText={t('settings:authentication.add_login_option')}
            loading={isLoading}
            canAdd={!isLoading && availableLoginOptionTypes.length > 0}
            onAdd={onAdd}
            editable={canEdit}
            onEdit={onEdit}
            onDelete={onDelete}
            itemRender={(x) => (
              <ListItemText>
                {t(`settings:authentication.login_option_type.${x.type}`)}
              </ListItemText>
            )}
            emptyTitle={t('settings:authentication.empty_title')}
            emptyDescription={t('settings:authentication.empty_description')}
          />
        </Paper>

        <AddUpdateLoginOptionDialog
          open={showAddUpdateDialog}
          hasOnlySitooLogin={hasOnlySitooLogin}
          availableLoginOptionTypes={availableLoginOptionTypes}
          loginOptionId={selectedLoginOption?.id}
          onClose={() => setShowAddUpdateDialog(false)}
          onSuccess={() => {
            setShowAddUpdateDialog(false)
          }}
        />

        <DeleteLoginOptionDialog
          open={showDeleteDialog}
          hasSingleLoginOption={allLoginOptions.length === 1}
          loginOptionId={selectedLoginOption?.id}
          loginOptionType={selectedLoginOption?.type}
          onClose={() => setShowDeleteDialog(false)}
          onSuccess={() => {
            setShowDeleteDialog(false)
          }}
        />
      </Container>
    </>
  )
}
