import { Button, TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useState } from 'react'
import { Controller, FieldPath, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'

export const CustomFields = () => {
  const { control, formState, getValues } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const [activateCustomFields, setActivateCustomFields] = useState(false)
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const customFields: {
    customField: FieldPath<GetProductQuery>
    productField: FieldPath<GetProductQuery['product']>
  }[] = [
    { customField: 'product.custom1', productField: 'custom1' },
    { customField: 'product.custom2', productField: 'custom2' },
    { customField: 'product.custom3', productField: 'custom3' },
    { customField: 'product.custom4', productField: 'custom4' },
    { customField: 'product.custom5', productField: 'custom5' },
  ]

  const hasCustomField =
    customFields.some((field) => !!getValues(field.customField)) ||
    activateCustomFields

  return (
    <>
      <FormFieldset
        label={t('products:product_form.custom_field_fieldset')}
        sx={{
          ...(!hasCustomField && { '.MuiFormFieldset-Paper': { padding: 0 } }),
        }}
      >
        {!hasCustomField && (
          <Button
            onClick={() => setActivateCustomFields(true)}
            fullWidth
            color="tertiary"
            disabled={!writeProducts}
            data-testid="activate-custom-fields"
          >
            {t('products:product_form.activate_custom_attributes')}
          </Button>
        )}

        {hasCustomField &&
          customFields.map((c, i) => (
            <Controller
              key={c.customField}
              control={control}
              name={c.customField}
              render={({ field }) => (
                <>
                  <TextField
                    error={
                      formState?.errors && formState?.errors.product
                        ? !!(
                            formState?.errors.product as Record<string, unknown>
                          )[c.productField]
                        : false
                    }
                    name={c.customField}
                    fullWidth
                    label={t('products:custom', { number: i + 1 })}
                    sx={{ mb: i !== customFields.length - 1 ? 2 : 0 }}
                    value={field.value}
                    onChange={(event) => field.onChange(event.target.value)}
                    disabled={!writeProducts}
                  />
                </>
              )}
            />
          ))}
      </FormFieldset>
    </>
  )
}
