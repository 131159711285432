import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Box,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { DeleteIcon, DragHandleIcon } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AllPaymentTypesDocument,
  PaymentType,
  PosPaymentSettingsDocument,
  UpdatePaymentSettingsDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { PaymentSettingsKeys } from './payment-settings-tab'
import { getPaymentTypesFromIds } from './utils'
import { DraggableItem } from '../../../../components/draggable-item'
import { DndProvider } from 'react-dnd/dist/core/DndProvider'
import { HTML5Backend } from 'react-dnd-html5-backend'

type Props = {
  open: boolean
  onClose(): void
  fieldName: PaymentSettingsKeys
}
type Form = {
  payments_refund_manual?: PaymentType[]
  payments_refund_validated?: PaymentType[]
  payments_refund_validated_omni?: PaymentType[]
  payments_sales_gift_card?: PaymentType[]
  payments_sales_non_delivered?: PaymentType[]
}

export const PaymentSettingsTabDialog = ({
  open,
  onClose,
  fieldName,
}: Props) => {
  const { t } = useTranslation(['pos_payment', 'shared'])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const { control, formState, setValue } = useForm<Form>()
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: fieldName,
  })

  const { data: paymentTypesData, loading: isLoadingPaymentTypes } = useQuery(
    AllPaymentTypesDocument,
  )

  const { data: paymentSettingsData, loading: isLoadingPaymentSettings } =
    useQuery(PosPaymentSettingsDocument, {
      onCompleted: (data) => {
        const currentIds = data.settings[fieldName] ?? []
        const currentTypes = getPaymentTypesFromIds(
          currentIds,
          paymentTypesData?.allPaymentTypes,
        )
        setValue(fieldName, currentTypes, { shouldDirty: false })
      },
    })

  const [updateSettings, { loading: isUpdatingSettings }] = useMutation(
    UpdatePaymentSettingsDocument,
  )

  const { trackButtonClick } = useTracking()

  const options = useMemo(() => {
    return (
      paymentTypesData?.allPaymentTypes.filter(
        (x) => !fields.map((y) => y.paymenttypeid).includes(x.paymenttypeid),
      ) || []
    )
  }, [fields, paymentTypesData?.allPaymentTypes])

  const handleSave = async () => {
    trackButtonClick({ name: `limit-payment-settings-${fieldName}-save` })

    try {
      await updateSettings({
        variables: {
          settings: {
            [fieldName]: fields.map((x) => x.paymenttypeid),
          },
        },
      })

      enqueueSnackbar(t('pos_payment:settings_saved_success'))
    } catch {
      enqueueSnackbar(t('pos_payment:settings_saved_failure'), {
        variant: 'error',
      })
    }
    onClose()
  }

  const handleOnCloseDialog = () => {
    const currentIds = paymentSettingsData?.settings[fieldName] ?? []
    setValue(
      fieldName,
      getPaymentTypesFromIds(currentIds, paymentTypesData?.allPaymentTypes),
      { shouldDirty: false },
    )
    onClose()
  }

  const handleAddFieldClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseAddFieldMenu = () => {
    setAnchorEl(null)
  }

  const isLoading =
    isLoadingPaymentTypes || isLoadingPaymentSettings || isUpdatingSettings

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        {t(`pos_payment:limit_payment_options.${fieldName}`)}
      </DialogTitle>
      <DialogContent>
        <DndProvider backend={HTML5Backend}>
          <List sx={{ '.MuiListItem-root': { p: 0 } }}>
            {fields.map((paymentOption, index) => (
              <DraggableItem
                id={paymentOption.id}
                key={paymentOption.id}
                index={index}
                moveField={move}
                component={ListItem}
                divider={index + 1 !== fields.length}
                data-index={index}
                secondaryAction={
                  <>
                    <Stack direction="row" gap={1}>
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                      <Box
                        sx={{
                          color: 'theme.palette.gray60',
                          cursor: 'move',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DragHandleIcon />
                      </Box>
                    </Stack>
                  </>
                }
              >
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    },
                  }}
                  secondaryTypographyProps={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {
                    <Tooltip title={paymentOption?.name} placement="top-start">
                      <span>{paymentOption?.name}</span>
                    </Tooltip>
                  }
                </ListItemText>
              </DraggableItem>
            ))}
            {fields.length === 0 && (
              <ListItem sx={{ mb: 2 }}>
                <ListItemText
                  primaryTypographyProps={{
                    color: 'gray60',
                    variant: 'body01',
                  }}
                >
                  {t('pos_payment:no_fields', {
                    paymentOption: t(
                      `pos_payment:limit_payment_options.${fieldName}`,
                    ),
                  })}
                </ListItemText>
              </ListItem>
            )}
          </List>
        </DndProvider>

        <Menu
          open={menuOpen}
          onClose={handleCloseAddFieldMenu}
          id="payment-type-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          slotProps={{ paper: { sx: { width: '395px' } } }}
        >
          {options.map((paymentType) => (
            <MenuItem
              key={paymentType.paymenttypeid}
              onClick={() => {
                if (paymentType) {
                  append(paymentType)
                }
                setAnchorEl(null)
              }}
            >
              {paymentType.name}
            </MenuItem>
          ))}
        </Menu>

        <Button
          fullWidth
          color="tertiary"
          onClick={handleAddFieldClick}
          aria-controls={open ? 'payment-type-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={options.length === 0}
        >
          {t('pos_payment:add_field')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleOnCloseDialog}
          data-testid="dialog-cancel-payment-option"
        >
          {t('shared:action.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isLoading || !formState.isDirty}
          color="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSave}
        >
          {t('shared:action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
