import { Fragment } from 'react'
import { GetOrderQuery } from '../../../../generated/graphql'
import { Stack, Typography } from '@mui/material'
import { OrderAdditionalData } from '../../util'
import { useTranslation } from 'react-i18next'

type DetailsSectionProps = {
  order?: GetOrderQuery['order']
  isReturn: boolean
}

export const DetailsSection = ({ order, isReturn }: DetailsSectionProps) => {
  const { t } = useTranslation(['orders'])
  if (!order) return

  const additionalData: OrderAdditionalData = order.additionaldata

  const dataPoints = {
    orderId: order.orderid ?? '-',
    receiptId: additionalData['pos-receiptid'] ?? '-',
    store: additionalData['store-name'] ?? '-',
    staffRef: additionalData['pos-staff-externalid'] ?? '-',
    cashRegister: order.cashRegister?.registerkey ?? '-',
    originalOrderId: order.originalOrder?.orderid ?? '-',
    exchangeOrderId: order.exchangeOrder?.orderid ?? '-',
    orderReturnReason: additionalData['refundreasoncode-reasoncode'] ?? '-',
  }

  return (
    <Fragment>
      <Typography variant="subheader" sx={{ textTransform: 'uppercase' }}>
        {t('orders:print.details_section.title')}
      </Typography>
      <Stack>
        <Typography variant="caption">
          {t('orders:print.details_section.order_id', {
            orderId: dataPoints.orderId,
          })}
        </Typography>
        <Typography variant="caption">
          {t('orders:print.details_section.receipt_id', {
            receiptId: dataPoints.receiptId,
          })}
        </Typography>
        <Typography variant="caption">
          {t('orders:print.details_section.store', {
            store: dataPoints.store,
          })}
        </Typography>
        <Typography variant="caption">
          {t('orders:print.details_section.staff_ref', {
            staffRef: dataPoints.staffRef,
          })}
        </Typography>
        <Typography variant="caption">
          {t('orders:print.details_section.cash_register', {
            cashRegister: dataPoints.cashRegister,
          })}
        </Typography>
        {isReturn && (
          <Fragment>
            <Typography variant="caption">
              {t('orders:print.details_section.original_order_id', {
                originalOrderId: dataPoints.originalOrderId,
              })}
            </Typography>
            <Typography variant="caption">
              {t('orders:print.details_section.exchange_order_id', {
                exchangeOrderId: dataPoints.exchangeOrderId,
              })}
            </Typography>
            <Typography variant="caption">
              {t('orders:print.details_section.order_return_reason', {
                orderReturnReason: dataPoints.orderReturnReason,
              })}
            </Typography>
          </Fragment>
        )}
      </Stack>
    </Fragment>
  )
}
