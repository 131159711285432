import { Fragment } from 'react'
import { GetOrderQuery } from '../../../../generated/graphql'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

type CompanyInformationProps = {
  order?: GetOrderQuery['order']
}

export const CompanyInformationSection = ({
  order,
}: CompanyInformationProps) => {
  const { t } = useTranslation(['countries', 'orders'])
  if (!order) return

  const dataPoints = {
    company: order.company ?? '',
    deliveryAddress: order.delivery_address ?? '',
    deliveryZip: order.delivery_zip ?? '',
    deliveryCity: order.delivery_city ?? '',
    deliveryCountryId: order.delivery_countryid ?? '',
  }

  const isEmpty = !Object.values(dataPoints).some(Boolean)
  return (
    <Fragment>
      <Typography variant="subheader" sx={{ textTransform: 'uppercase' }}>
        {t('orders:print.company_information_section.title')}
      </Typography>
      {isEmpty ? (
        '-'
      ) : (
        <Stack>
          <Typography variant="caption">{dataPoints.company}</Typography>
          <Typography variant="caption">
            {dataPoints.deliveryAddress}
          </Typography>
          <Typography variant="caption">{`${dataPoints.deliveryCity}${
            dataPoints.deliveryZip && `, ${dataPoints.deliveryZip}`
          }`}</Typography>
          <Typography variant="caption">
            {dataPoints.deliveryCountryId &&
              t(`countries:${dataPoints.deliveryCountryId.toUpperCase()}`)}
          </Typography>
        </Stack>
      )}
    </Fragment>
  )
}
