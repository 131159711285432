import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseShipmentFormContext } from '../../shared'

export const CarrierField = () => {
  const { register, formState } = useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments_v2', 'shared'])

  return (
    <FormFieldset label={t('shipments_v2:shipment_form.shipment_fieldset')}>
      <TextField
        error={!!formState?.errors.carrier?.carrier_name}
        fullWidth
        helperText={formState?.errors.carrier?.carrier_name?.message}
        label={t('shipments_v2:shipment_form.carrier')}
        {...register('carrier.carrier_name')}
        sx={{ mb: 2 }}
      />
      <TextField
        error={!!formState?.errors.carrier?.carrier_reference}
        fullWidth
        helperText={formState?.errors.carrier?.carrier_reference?.message}
        label={t('shipments_v2:shipment_form.carrier_reference')}
        {...register('carrier.carrier_reference')}
        sx={{ mb: 2 }}
      />
      <TextField
        error={!!formState?.errors.carrier?.barcode}
        fullWidth
        helperText={formState?.errors.carrier?.barcode?.message}
        label={t('shipments_v2:shipment_form.barcode')}
        {...register('carrier.barcode')}
        sx={{ mb: 2 }}
      />
      <TextField
        error={!!formState?.errors.carrier?.carrier_trackingurl}
        fullWidth
        helperText={formState?.errors.carrier?.carrier_trackingurl?.message}
        placeholder="https://wwww.site.com"
        label={t('shipments_v2:shipment_form.tracking_url')}
        {...register('carrier.carrier_trackingurl')}
        sx={{ mb: 2 }}
      />
      <TextField
        error={!!formState?.errors.carrier?.externalid}
        fullWidth
        helperText={formState?.errors.carrier?.externalid?.message}
        label={t('shipments_v2:shipment_form.externalid')}
        {...register('carrier.externalid')}
        sx={{ mb: 2 }}
      />
    </FormFieldset>
  )
}
