import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import {
  DisableIcon,
  ProgressDoneIcon,
  WarningIcon,
} from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportField } from '../../../components/import-field'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessages } from '../../../utils/error-mapping'
import { Maybe } from '../../../generated/graphql'
import { ApolloError } from '@apollo/client'

type ParseError = {
  code: string
  property?: string | null
}

type ImportData = {
  imported: number
  failed: number
  deleted: number
}

type ImportNameSpaces =
  | 'stores'
  | 'prices'
  | 'purchase_prices'
  | 'warehouses'
  | 'users'
  | 'influencer_codes'

export type Props = {
  ns: ImportNameSpaces
  open: boolean
  onClose(): void
  isLoading?: boolean
  onImport(): Promise<unknown> | undefined
  onParse(csv: string): Promise<unknown>
  onSuccess(): void
  parsedItems: Maybe<{ items: unknown[]; errors: ParseError[] }> | undefined
  importData: Maybe<ImportData> | undefined
  importError: ApolloError | undefined
  example?: string
}

enum Step {
  Upload,
  Validate,
  Result,
}

export const ImportDialog = (props: Props) => {
  const { isLoading, parsedItems, importData, ns, example, importError } = props
  const dialogName = `${ns}-import`
  const { t } = useTranslation(['settings', 'shared'])
  const [files, setFiles] = useState<UploadedFile[]>()
  const [step, setStep] = useState<Step>(Step.Upload)

  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormSuccess,
    trackFormError,
  } = useTracking()

  useEffect(() => {
    if (props.open) {
      setStep(Step.Upload)
      trackDialogOpen({ name: dialogName })
    }
  }, [dialogName, props.open, trackDialogOpen])

  const onClose = (
    _event: React.MouseEvent,
    reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick',
  ) => {
    if (reason === 'backdropClick') {
      return
    }

    trackDialogClose({ name: dialogName })
    props?.onClose()
  }

  const onConvert = () => {
    const csvFile = files?.[0]
    const reader = new FileReader()
    if (csvFile?.file) {
      reader.readAsText(csvFile?.file, 'UTF-8')

      reader.onload = async (event) => {
        const content = event.target?.result
        if (typeof content === 'string') {
          await props.onParse(content)
          trackFormSuccess({ name: `${dialogName}-convert` })
          setStep(Step.Validate)
        }
      }
    }
  }

  const onImport = async () => {
    const items = parsedItems

    if (items) {
      try {
        await props.onImport()
        trackFormSuccess({ name: `${dialogName}-import` })
      } catch (error) {
        const errorMessage = getErrorMessages(error, {
          resolve: (errorCode) => `settings:import_data.error.${errorCode}`,
        }).join(', ')

        trackFormError({ name: `${dialogName}-import`, errorMessage })
      } finally {
        setStep(Step.Result)
      }
    }
  }

  return (
    <Dialog
      onClose={onClose}
      open={props.open}
      maxWidth="xs"
      fullWidth
      data-testid={`${dialogName}-dialog`}
    >
      <DialogTitle type="extended">
        {t(`settings:import_data.${ns}.title`)}
      </DialogTitle>

      <DialogContent sx={{ px: 0 }}>
        {step === Step.Upload && (
          <>
            <ImportField setFiles={setFiles} />
            <Typography
              sx={{
                color: (t) => t.palette.text.disabled,
                whiteSpace: 'pre',
                p: 2,
              }}
            >
              {t('settings:import_data.example_label')}
              {example?.replaceAll(' ', '')}
            </Typography>
          </>
        )}

        {step === Step.Validate && (
          <>
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <WarningIcon
                data-testid="warning-icon"
                fontSize="large"
                color="warning"
              />
              <Typography
                variant="heading"
                data-testid="confirm-import-heading"
              >
                {t('settings:import_data.confirm_import')}
              </Typography>
            </Stack>

            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subheader"
                      color="gray60"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {t('settings:import_data.summary')}
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  data-testid="import-number-label"
                  primary={t(`settings:import_data.${ns}.number_import_label`)}
                />
                <Typography
                  fontWeight="medium"
                  data-testid="import-number-value"
                >
                  {parsedItems?.items?.length || 0}
                </Typography>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText
                  data-testid="skipped-number-label"
                  primary={t(`settings:import_data.${ns}.skipped_import_label`)}
                  secondary={parsedItems?.errors.map((error) => (
                    <Box
                      component="span"
                      data-testid="error-message"
                      key={error.property}
                      sx={{
                        px: 1,
                        color: (t) => t.palette.error.main,
                        display: 'block',
                      }}
                    >
                      {t(
                        `settings:import_data.error.${error.code.toLowerCase()}`,
                        { property: error.property },
                      )}
                    </Box>
                  ))}
                />
                <Typography
                  fontWeight="medium"
                  data-testid="skipped-number-value"
                >
                  {parsedItems?.errors?.length || 0}
                </Typography>
              </ListItem>
            </List>
          </>
        )}

        {step === Step.Result && (
          <>
            {!importError && (
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
              >
                <ProgressDoneIcon
                  fontSize="large"
                  color="success"
                  data-testid="success-icon"
                />
                <Typography variant="heading">
                  {t('settings:import_data.finished')}
                </Typography>
              </Stack>
            )}

            {!!importError && (
              <Stack
                direction="column"
                alignItems="center"
                spacing={2}
                sx={{ mb: 2 }}
              >
                <DisableIcon
                  fontSize="large"
                  color="error"
                  data-testid="error-icon"
                />
                <Typography variant="heading">
                  {t('settings:import_data.failed')}
                </Typography>
              </Stack>
            )}

            <List>
              <ListItem>
                <ListItemText
                  data-testid="imported-label"
                  primary={t('settings:import_data.imported_label')}
                />
                <Typography fontWeight="medium" data-testid="imported-value">
                  {importData?.imported || 0}
                </Typography>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText
                  data-testid="deleted-label"
                  primary={t('settings:import_data.deleted_label')}
                />
                <Typography fontWeight="medium" data-testid="deleted-value">
                  {importData?.deleted || 0}
                </Typography>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText
                  data-testid="failed-label"
                  primary={t('settings:import_data.failed_label')}
                  secondary={
                    !!importError && (
                      <Box
                        data-testid="failed-error-message"
                        component="span"
                        sx={{
                          px: 1,
                          color: (t) => t.palette.error.main,
                          display: 'block',
                        }}
                      >
                        {getErrorMessages(importError, {
                          resolve: (errorCode) =>
                            `settings:import_data.error.${errorCode}`,
                        }).join(', ')}
                      </Box>
                    )
                  }
                />
                <Typography fontWeight="medium" data-testid="failed-value">
                  {importError ? '1' : '0'}
                </Typography>
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={(event) => {
            trackButtonClick({ name: `${dialogName}-dialog-close` })
            onClose(event, 'buttonClick')
          }}
          data-testid="dialog-close"
          color="secondary"
          size="small"
        >
          {t('shared:action.close')}
        </Button>

        {step === Step.Upload && (
          <Button
            onClick={onConvert}
            size="small"
            data-testid="dialog-submit"
            disabled={!files?.length || isLoading}
          >
            {t('shared:action.validate')}
          </Button>
        )}

        {step === Step.Validate && (
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onImport}
            data-testid="dialog-submit"
            size="small"
            disabled={!parsedItems?.items.length || isLoading}
          >
            {t('shared:action.import')}
          </Button>
        )}

        {step === Step.Result && (
          <Button
            onClick={props.onSuccess}
            data-testid="dialog-submit"
            size="small"
          >
            {t(`settings:import_data.${ns}.view_label`)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
