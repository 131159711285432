import { ListItem, MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

export enum CustomerDetailTypeEnum {
  CRMMemberNumber = 'CRMMemberNumber',
  CRMCustomerId = 'CRMCustomerId',
  Email = 'Email',
  Phone = 'Phone',
}

type QueryParamsState = {
  customerDetail?: [type: CustomerDetailTypeEnum, value?: string]
}

export const CustomerFilter = () => {
  const { t } = useTranslation(['orders', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'customerDetail'
  const customerDetailTypes = useMemo(
    () =>
      Object.values(CustomerDetailTypeEnum)
        .map((x) => ({
          value: x,
          label: t(`orders:customer_detail_type_enum.${x}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const selectedCustomerDetailType =
    queryParams.customerDetail?.[0] &&
    customerDetailTypes.find(
      ({ value }) => value === queryParams.customerDetail?.[0],
    )
      ? queryParams.customerDetail?.[0]
      : CustomerDetailTypeEnum.CRMMemberNumber

  const selectedCustomerDetailValue = queryParams.customerDetail?.[1] || ''
  const [customerDetailValue, setCustomerDetailValue] = useState(
    selectedCustomerDetailValue,
  )

  useEffect(() => {
    setCustomerDetailValue(selectedCustomerDetailValue)
  }, [selectedCustomerDetailValue])

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setCustomerDetailValue('')
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const customerDetailType = customerDetailTypes?.find(
      ({ value }) => value === selectedCustomerDetailType,
    )

    if (customerDetailType && selectedCustomerDetailValue) {
      setFilter(filterKey, {
        label: `${customerDetailType.label}: ${selectedCustomerDetailValue}`,
        value: [customerDetailType.value, selectedCustomerDetailValue],
      })
    } else {
      removeFilter(filterKey)
    }
  }, [
    removeFilter,
    setFilter,
    selectedCustomerDetailType,
    selectedCustomerDetailValue,
    customerDetailTypes,
    t,
  ])

  return (
    <ListItem>
      <TextField
        data-testid="customer-field"
        onChange={(event) => {
          setCustomerDetailValue(event.target.value)
          setQueryParams(
            {
              [filterKey]: [
                selectedCustomerDetailType,
                event.target.value || undefined,
              ],
            },
            hasApply !== true,
          )
        }}
        value={customerDetailValue}
        placeholder={
          customerDetailTypes.find(
            (x) => x.value === selectedCustomerDetailType,
          )?.label || CustomerDetailTypeEnum.CRMMemberNumber
        }
        label={t('orders:customer_detail')}
      />
      <TextField
        data-testid="customer-field-input"
        onChange={(event) =>
          setQueryParams(
            {
              [filterKey]: [
                event.target.value as CustomerDetailTypeEnum,
                selectedCustomerDetailValue,
              ],
            },
            hasApply !== true,
          )
        }
        value={selectedCustomerDetailType}
        SelectProps={{
          displayEmpty: true,
        }}
        select
        label="&#8203;"
      >
        {customerDetailTypes?.map((customerDetailType) => (
          <MenuItem
            data-testid="customer-data-option"
            key={customerDetailType.value}
            value={customerDetailType.value || ''}
            divider
            onClick={trackButtonClickEvent({
              name: 'order-search-filter-select-customer-detail-type',
              customerDetailType: customerDetailType.value,
            })}
          >
            <Typography variant="body02">{customerDetailType.label}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </ListItem>
  )
}
