import { Fragment } from 'react'
import { GetOrderQuery } from '../../../../generated/graphql'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { OrderAdditionalData } from '../../util'

type CustomerSectionProps = {
  order?: GetOrderQuery['order']
}

export const CustomerSection = ({ order }: CustomerSectionProps) => {
  const { t } = useTranslation(['orders'])
  if (!order) return
  const additionalData: OrderAdditionalData = order.additionaldata

  const dataPoints = {
    name: `${order.namefirst || ''} ${order.namelast || ''}`.trim(),
    company: order.company ?? '',
    crmMemberNumber: additionalData['crm-membernumber'] ?? '',
    phone: order.phone ?? '',
    email: order.email ?? '',
  }

  const isEmpty = !Object.values(dataPoints).some(Boolean)
  return (
    <Fragment>
      <Typography variant="subheader" sx={{ textTransform: 'uppercase' }}>
        {t('orders:print.customer_section.title')}
      </Typography>
      {isEmpty ? (
        '-'
      ) : (
        <Stack>
          <Typography variant="caption">{dataPoints.name}</Typography>
          <Typography variant="caption">{dataPoints.company}</Typography>
          <Typography variant="caption">
            {dataPoints.crmMemberNumber}
          </Typography>
          <Typography variant="caption">{dataPoints.phone}</Typography>
          <Typography variant="caption">{dataPoints.email}</Typography>
        </Stack>
      )}
    </Fragment>
  )
}
