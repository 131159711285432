import { Stack, Typography } from '@mui/material'
import { DoneIcon, Link } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { RootRoute } from '..'

type Props = {
  onBack?(): void
}

export const EmailSent = (props: Props) => {
  const { t } = useTranslation(['login'])

  return (
    <Stack direction="column" alignItems="center">
      <DoneIcon fontSize="large" sx={{ mb: 2 }} />
      <Typography variant="displayMedium" sx={{ mb: 1 }}>
        {t('email_sent')}
      </Typography>

      <Typography>{t('email_sent_info')}</Typography>

      <Typography sx={{ mb: 3 }}>
        <Link
          to={RootRoute.ResetPassword}
          onClick={props.onBack}
          component={RouterLink}
        >
          {t('resend_email')}
        </Link>

        {` ${t('or_go_back_to')} `}
        <Link to={RootRoute.Login} component={RouterLink}>
          {t('shared:label.sign_in')}
        </Link>
      </Typography>
    </Stack>
  )
}
