import { useLazyQuery } from '@apollo/client'
import { useEffect, useRef } from 'react'
import { MeDocument } from '../../generated/graphql'

type Props = {
  skip: boolean
}

/**
 * Fetch the user session when app is in focus.
 * When session is expired, a user is redirected to the logout page.
 */
export const useRefreshSession = (props: Props) => {
  const lastFetchDate = useRef(new Date())
  const [fetchMe] = useLazyQuery(MeDocument, { fetchPolicy: 'network-only' })

  useEffect(() => {
    if (props.skip) return

    const onVisibilityChange = () => {
      const isVisible = document.visibilityState === 'visible'
      const FETCH_TIMEOUT = 5 * 1000 // 5 seconds
      const canFetch =
        !lastFetchDate.current ||
        Date.now() - lastFetchDate.current.getTime() > FETCH_TIMEOUT

      if (isVisible && canFetch) {
        lastFetchDate.current = new Date()
        void fetchMe()
      }
    }

    // Add event listeners to handle both visibility change and window focus
    document.addEventListener('visibilitychange', onVisibilityChange)
    window.addEventListener('focus', onVisibilityChange)

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
      window.removeEventListener('focus', onVisibilityChange)
    }
  }, [fetchMe, props.skip])
}
