import { Box, Typography } from '@mui/material'
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'

export type CaptionRendererValue = {
  title: React.ReactNode
  caption: React.ReactNode
}

export const CaptionRenderer = (
  params: GridRenderCellParams<GridValidRowModel, CaptionRendererValue>,
) => {
  const title = params.formattedValue?.title
  const caption = params.formattedValue?.caption

  return (
    <Box
      data-testid={`caption-${params.id}-${params.field}`}
      sx={{ display: 'grid', height: '100%', alignItems: 'center' }}
    >
      {title && <Typography>{title}</Typography>}

      {caption && (
        <Typography variant="caption" color="textSecondary">
          {caption}
        </Typography>
      )}
    </Box>
  )
}
