import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { createSitooTheme } from '@sitoo/mui-components'

const typography = {
  body1: {
    fontFamily: "'Roboto Mono', monospace",
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 400,
    fontStyle: 'normal',
  },

  heading: {
    fontFamily: "'Roboto Mono', monospace",
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '33px',
  },
}

export const ReceiptThemeProvider = (props: PropsWithChildren) => {
  const theme = createSitooTheme()

  Object.assign(theme.typography, typography)

  return (
    <MuiThemeProvider theme={theme}>
      <Box sx={{ ...typography.body1, m: 2 }}>{props.children}</Box>
    </MuiThemeProvider>
  )
}
