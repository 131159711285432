import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetUserQuery } from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'

type UserAdditionalDataProps = {
  user?: GetUserQuery['user']
  isLoading: boolean
}

export const UserAdditionalData = ({
  user,
  isLoading,
}: UserAdditionalDataProps) => {
  const { t } = useTranslation(['shared', 'users'])
  const { formatDate } = useLocalizedDate()

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="additional-data">
        <SectionHeader sx={{ p: 0 }}>
          {t('users:additional_data')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                primary={
                  user?.datecreated
                    ? formatDate(new Date(user?.datecreated))
                    : '-'
                }
              />
            }
          >
            <ListItemText primary={t('users:created')} />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.datemodified}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                primary={
                  user?.datemodified
                    ? formatDate(new Date(user?.datemodified))
                    : '-'
                }
              />
            }
          >
            <ListItemText primary={t('users:modified')} />
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            divider
            childrenSkeleton
            hide={!isLoading && !user?.notes}
          >
            <ListItemText
              data-testid="user-note-field"
              primary={t('shared:label.notes')}
              secondary={user?.notes}
            />
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
