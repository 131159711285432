import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { useQuery } from '@apollo/client'
import { GetWarehousesDocument } from '../../../generated/graphql'

type Props = {
  filterKey: string
  label: string
  title: string
}

export const WarehouseFilter = (props: Props) => {
  const { removeFilter, setFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const { filterKey, label, title } = props

  const { data, loading: isLoading } = useQuery(GetWarehousesDocument)
  const warehouses = data?.allWarehouses

  const [queryParams, setQueryParams] = useStateParams()

  const warehouseIdParam = queryParams[filterKey]
    ? Number(queryParams[filterKey])
    : undefined

  const warehouse = warehouses?.find(({ id }) => id === warehouseIdParam)
    ? warehouseIdParam
    : ''

  useEffect(() => {
    registerFilter({
      key: filterKey,
      isReady: !isLoading,
    })
  }, [filterKey, isLoading, registerFilter, warehouseIdParam])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })

    return () => {
      unsubscribe()
    }
  }, [filterKey, setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    if (warehouse) {
      const warehouseName = warehouses?.find(
        ({ id }) => id === warehouseIdParam,
      )?.name

      setFilter(filterKey, {
        label: warehouseName ? `${title}: ${warehouseName}` : undefined,
        value: warehouse,
      })
    } else {
      setFilter(filterKey, {
        isDefault: true,
        label: `${title}: ${props.label}`,
        value: '',
      })
    }
  }, [
    warehouse,
    removeFilter,
    setFilter,
    filterKey,
    warehouses,
    title,
    warehouseIdParam,
    props.label,
  ])

  return (
    <TextField
      disabled={isLoading}
      onChange={(event) => {
        setQueryParams({ [filterKey]: event.target.value || undefined })
      }}
      value={warehouse}
      SelectProps={{
        displayEmpty: true,
      }}
      data-testid={`warehouse-filter-${filterKey}`}
      select
      label={title}
    >
      <MenuItem
        value=""
        divider
        onClick={trackButtonClickEvent({
          name: `warehouse-filter-${filterKey}`,
          warehouse: label,
        })}
      >
        <Typography variant="body02">{label}</Typography>
      </MenuItem>

      {warehouses?.map(({ id, name }) => (
        <MenuItem
          key={id}
          value={id}
          divider
          onClick={trackButtonClickEvent({
            name: `warehouse-filter-${filterKey}`,
            warehouse: name,
          })}
        >
          <Typography variant="body02">{name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
