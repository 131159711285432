import { Box, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { ProductViewPanelState } from '../..'
import { GetProductQuery } from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'

type MultipleVariantSelectorProps = {
  variantGroups: { name: string; options: string[] }[]
  variants: { id: number; attributes: string[] }[]

  selectedProduct: ProductViewPanelState
  product?: GetProductQuery['product']
  onDetailProduct: (
    productId: number,
    siteId?: number,
    previous?: ProductViewPanelState,
  ) => void
}
export const MultipleVariantSelector = (
  props: MultipleVariantSelectorProps,
) => {
  const { trackButtonClick } = useTracking()
  const productVariants = props.product?.variant || []
  const variantGroups = props.variantGroups
  const currentProductVariant = variantGroups.reduce(
    (p, group) => {
      p[group.name] =
        productVariants.find((v) => v.name === group.name)?.value || ''

      return p
    },
    {} as Record<string, string>,
  )

  const allCombinationVariants: {
    productId: number
    [key: string]: string | number
  }[] = props.variants.map((variant) => ({
    productId: variant.id,
    ...variantGroups.reduce(
      (p, c) => {
        p[c.name] =
          c.options.find((option) => variant.attributes.includes(option)) || ''
        return p
      },
      {} as Record<string, string>,
    ),
  }))

  const isVariantOptionValid = (groupName: string, variantOption: string) => {
    const variantGroupNames = variantGroups.map((x) => x.name)
    const variantGroupNameIndex = variantGroupNames.findIndex(
      (x) => x === groupName,
    )

    //  The first variant group name is always valid
    if (variantGroupNameIndex === 0) return true

    const variantGroupsToBeChecked = variantGroupNames.slice(
      0,
      variantGroupNameIndex + 1,
    )

    return allCombinationVariants.some((x) => {
      if (x[groupName] !== variantOption) return false

      for (const groupKey of variantGroupsToBeChecked) {
        if (groupKey === groupName) continue

        if (x[groupKey] !== currentProductVariant[groupKey]) {
          return false
        }
      }

      return true
    })
  }

  return (
    <Stack sx={{ padding: (theme) => theme.spacing(1, 2, 2, 2) }} spacing={2}>
      {variantGroups.map((group) => (
        <Box key={group.name}>
          <InputLabel data-testid="product-variant-label">
            {group.name}
          </InputLabel>
          <Select
            data-testid="product-variant-selector"
            value={currentProductVariant[group.name]}
            onChange={(event) => {
              currentProductVariant[group.name] = event.target.value

              trackButtonClick({
                name: 'product-panel-multiple-variant-selector-select',

                groupName: group.name,
                groupValue: event.target.value,
              })

              //    We pick up the variant with the highest score
              //    The closer to being the first variantGroup the higher the score will be.
              const variantGroupNames = variantGroups.map((x) => x.name)
              const matchedVariant = allCombinationVariants
                .map((v) => {
                  return {
                    ...v,
                    score: variantGroupNames.reduce(
                      (p, c, i) =>
                        p +
                        (v[c] === currentProductVariant[c]
                          ? variantGroupNames.length - i
                          : 0),
                      0,
                    ),
                  }
                })
                .sort((a, b) => b.score - a.score)[0]

              if (matchedVariant?.productId)
                props.onDetailProduct(
                  matchedVariant.productId,
                  props.selectedProduct.siteId,
                )
            }}
          >
            {group.options.map((variantOption) => (
              <MenuItem
                data-testid="variant-option"
                value={variantOption}
                key={variantOption}
                disabled={!isVariantOptionValid(group.name, variantOption)}
              >
                {variantOption}
              </MenuItem>
            ))}
          </Select>
        </Box>
      ))}
    </Stack>
  )
}
