import { ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { useMoney } from '../../../../hooks/money'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalCartItem' }
  >
}

export const CartItemDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props

  const { formatCurrency } = useMoney()

  return (
    <>
      <ListItemSkeleton
        hide={!journal?.cart_id}
        childrenSkeleton
        divider
        data-testid="journal-id"
      >
        <ListItemText secondary={journal?.cart_id}>
          {t('journals:cart_id')}
        </ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.sku}
        childrenSkeleton
        divider
        data-testid="journal-sku"
      >
        <ListItemText primary={t('journals:sku')} secondary={journal?.sku} />
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.JournalCartItemMoneyDiscount}
        secondaryAction={
          <ListItemText>
            {journal?.JournalCartItemMoneyDiscount
              ? formatCurrency(journal?.JournalCartItemMoneyDiscount)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-cart_moneydiscount"
      >
        <ListItemText>{t('journals:money_discount')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.JournalCartItemMoneyTotal}
        secondaryAction={
          <ListItemText>
            {journal?.JournalCartItemMoneyTotal
              ? formatCurrency(journal?.JournalCartItemMoneyTotal)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-JournalCartItemMoneyTotal"
      >
        <ListItemText>{t('journals:money_total')}</ListItemText>
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!journal?.cart_moneydiscount}
        secondaryAction={
          <ListItemText>
            {journal?.cart_moneydiscount
              ? formatCurrency(journal?.cart_moneydiscount)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-cart_moneydiscount"
      >
        <ListItemText>{t('journals:cart_money_discount')}</ListItemText>
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!journal?.cart_moneytotal}
        secondaryAction={
          <ListItemText>
            {journal?.cart_moneytotal
              ? formatCurrency(journal?.cart_moneytotal)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-cart_moneytotal"
      >
        <ListItemText>{t('journals:cart_money_total')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={journal?.quantity === undefined}
        secondaryAction={<ListItemText>{journal?.quantity}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-quantity"
      >
        <ListItemText>{t('journals:quantity')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.decimalunitquantity}
        secondaryAction={
          <ListItemText>{journal?.decimalunitquantity}</ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-decimalunitquantity"
      >
        <ListItemText>{t('journals:decimal_unit_quantity')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={journal?.cart_quantitytotal === undefined}
        secondaryAction={
          <ListItemText>{journal?.cart_quantitytotal}</ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-cart_quantitytotal"
      >
        <ListItemText>{t('journals:cart_quantity_total')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.note}
        childrenSkeleton
        divider
        data-testid="journal-note"
      >
        <ListItemText secondary={journal?.note}>
          {t('journals:note')}
        </ListItemText>
      </ListItemSkeleton>
    </>
  )
}
