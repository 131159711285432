import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InfluencerCodeContext } from '..'

export const CampaignDetails = () => {
  const { t } = useTranslation()
  const { register, formState } = useFormContext<InfluencerCodeContext>()

  return (
    <>
      <FormFieldset label={t('influencer_codes:campaign_overrides_fieldset')}>
        <TextField
          error={!!formState?.errors?.vouchername}
          fullWidth
          helperText={
            formState?.errors?.vouchername?.message ||
            t('influencer_codes:influencer_campaign_name_description')
          }
          label={t('influencer_codes:influencer_campaign_name_label')}
          sx={{ mb: 2 }}
          {...register('vouchername')}
        />

        <TextField
          error={!!formState?.errors?.vouchercode}
          fullWidth
          helperText={
            formState?.errors?.vouchercode?.message ||
            t('influencer_codes:influencer_campaign_group_description')
          }
          label={t('influencer_codes:influencer_campaign_group_label')}
          sx={{ mb: 2 }}
          {...register('vouchercode')}
        />

        <TextField
          error={!!formState?.errors?.vouchercomment}
          fullWidth
          helperText={
            formState?.errors?.vouchercomment?.message ||
            t('influencer_codes:influencer_campaign_note_description')
          }
          multiline
          rows={3}
          label={t('influencer_codes:influencer_campaign_note_label')}
          {...register('vouchercomment')}
        />
      </FormFieldset>
    </>
  )
}
