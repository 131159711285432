import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { useTranslation } from 'react-i18next'
import { GetOrderQuery } from '../../../../generated/graphql'
import { useMoney } from '../../../../hooks/money'

type OrderTableProps = {
  order?: GetOrderQuery['order']
}

export const OrderTable = ({ order }: OrderTableProps) => {
  const { t } = useTranslation(['orders'])
  const { formatCurrency } = useMoney()
  if (!order) return

  const getReasonsOnSku = (sku?: string | null) => {
    if (!sku) return null

    const orderItemReasonFields = [
      'reasoncode-manualprice',
      'reasoncode-return',
      'reasoncode-manualdiscount',
    ]

    const foundReasons: string[] = []
    order.orderitems
      .filter((x) => x.sku === sku)
      .forEach((item) => {
        if (!item.additionaldata) return

        Object.entries(item.additionaldata).forEach(([key, value]) => {
          if (orderItemReasonFields.includes(key)) {
            foundReasons.push(value as string)
          }
        })
      })

    return foundReasons.length > 0 ? [...new Set(foundReasons)] : null
  }

  const mainItems = order.orderitems.filter((x) => x.quantity !== 0)

  return (
    <TableContainer>
      <Table
        sx={{
          [`.${tableCellClasses.root}`]: {
            borderBottom: '1px solid',
            padding: '8px 0',
            borderColor: (theme) => theme.palette.divider,
          },
        }}
      >
        <TableHead
          sx={{
            borderBottom: '2px solid',
            borderColor: (theme) => theme.palette.divider,
            textTransform: 'uppercase',
          }}
          data-testid="order-print-table-header"
        >
          <TableRow>
            <TableCell sx={{ width: '30%' }}>
              <Typography variant="subheader">
                {t('orders:print.order_table.item')}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subheader">
                {t('orders:print.order_table.description')}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subheader">
                {t('orders:print.order_table.quantity')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subheader">
                {t('orders:print.order_table.price')}
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="subheader">
                {t('orders:print.order_table.total')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody data-testid="order-print-table-body">
          {mainItems.map((item, index) => {
            const itemQuantity = Math.abs(item.quantity)
            const reasons = getReasonsOnSku(item.sku)
            const manualDiscount = item.additionaldata?.[
              'orderitem-moneyrowdiscount'
            ]
              ? Number(item.additionaldata?.['orderitem-moneyrowdiscount'])
              : 0

            // In case of manual discount or price change, the amount had already been subracted from the 'moneyitemtotal_net' and 'moneyitemtotal_vat' so we need to add it again
            // When no manual discount or price change is detected manualDiscount is 0
            const totalPrice =
              Number(item.moneyitemtotal_net) +
              Number(item.moneyitemtotal_vat) +
              manualDiscount

            const unitPrice = totalPrice / itemQuantity

            const unitPriceIncDiscount =
              unitPrice - manualDiscount / itemQuantity

            const unitMoneySummary = `${formatCurrency(
              unitPriceIncDiscount,
            )} (${formatCurrency(unitPrice)})`

            const productUnitDiscount = unitPrice - unitPriceIncDiscount

            return (
              <TableRow
                key={item.orderitemid}
                data-testid={`order-print-table-row-${index}`}
              >
                <TableCell
                  data-testid={`order-print-table-row-${index}-item-cell`}
                >
                  <Typography variant="body02">{item.productname}</Typography>
                </TableCell>
                <TableCell
                  data-testid={`order-print-table-row-${index}-description-cell`}
                >
                  <Stack>
                    <Typography variant="caption">{item.sku}</Typography>
                    <Typography variant="caption">
                      {item.productattributes}
                    </Typography>

                    {itemQuantity !== 1 && (
                      <Typography variant="caption">
                        {t('orders:print.order_table.unit_price', {
                          unitPrice: unitMoneySummary,
                        })}
                      </Typography>
                    )}
                    {productUnitDiscount !== 0 && (
                      <Typography variant="caption">
                        {t('orders:print.order_table.unit_discount', {
                          unitDiscount: formatCurrency(productUnitDiscount, {
                            isNegative: true,
                          }),
                        })}
                      </Typography>
                    )}
                    {reasons && (
                      <Typography variant="caption">
                        {t('orders:print.order_table.reason', {
                          reasons: reasons.join(', '),
                        })}
                      </Typography>
                    )}
                  </Stack>
                </TableCell>
                <TableCell
                  align="center"
                  data-testid={`order-print-table-row-${index}-quantity-cell`}
                >
                  <Typography variant="body01">{item.quantity}</Typography>
                </TableCell>
                <TableCell
                  align="right"
                  data-testid={`order-print-table-row-${index}-price-cell`}
                >
                  <Typography variant="body01">{`${formatCurrency(totalPrice, {
                    forceDecimals: true,
                  })}`}</Typography>
                </TableCell>
                <TableCell
                  align="right"
                  data-testid={`order-print-table-row-${index}-total-cell`}
                >
                  <Typography variant="body01">{`${formatCurrency(
                    totalPrice - manualDiscount,
                    { forceDecimals: true },
                  )}`}</Typography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
