import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridImage } from '../../../components/data-grid-image'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetShipmentQuery, ShipmentState } from '../../../generated/graphql'
import { SetUndeclaredItemsDataDialog } from '../../shipment/set-undeclared-items-data-dialog'

type Props = {
  isLoading?: boolean
  shipment?: GetShipmentQuery['shipment']
}

export const UndeclaredItems = (props: Props) => {
  const { t } = useTranslation(['shipments'])
  const { shipment } = props
  const [isItemsDialogOpen, setItemsDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const undeclaredItems = shipment?.items_undeclared
  const shipmentState = shipment?.state
  const shouldShowUndeclaredItems =
    shipmentState &&
    [
      ShipmentState.Received,
      ShipmentState.Arrived,
      ShipmentState.Closed,
      ShipmentState.ClosedIncomplete,
      ShipmentState.Cancelled,
    ].includes(shipmentState)

  if (!shouldShowUndeclaredItems) return null

  const canAddUndeclaredItems = [
    ShipmentState.Received,
    ShipmentState.Arrived,
  ].includes(shipment.state)

  if (!canAddUndeclaredItems && !undeclaredItems?.length) {
    return null
  }

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>
          {t('shipments:undeclared_items.title')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          {undeclaredItems?.map((undeclaredItem, index) => (
            <Fragment key={undeclaredItem.id}>
              <ListItem
                data-testid="undeclared-item-row"
                secondaryAction={
                  undeclaredItem.__typename ? (
                    <Chip
                      data-testid="chip-received-undeclared"
                      color="green"
                      size="small"
                      label={t('shipments:undeclared_items.received', {
                        count: undeclaredItem.quantity,
                      })}
                    />
                  ) : (
                    <Chip
                      color="grayLight"
                      size="small"
                      label={t('shipments:undeclared_items.received', {
                        count: undeclaredItem.quantity,
                      })}
                    />
                  )
                }
              >
                <ListItemIcon>
                  <DataGridImage
                    src={undeclaredItem.product?.images?.at(0) || undefined}
                  />
                </ListItemIcon>
                <ListItemText
                  data-testid="undeclared-item-label"
                  primary={undeclaredItem.product_name}
                  secondary={undeclaredItem.sku}
                />
              </ListItem>

              {index + 1 < undeclaredItems?.length && <Divider />}
            </Fragment>
          ))}

          {canAddUndeclaredItems && (
            <>
              <ListItemSkeleton sx={{ justifyContent: 'end', py: 2 }}>
                <Button
                  data-testid="receive-undeclared-button"
                  color="secondary"
                  size="small"
                  onClick={() => setItemsDialogOpen(true)}
                  fullWidth
                >
                  {t('shipments:undeclared_items.receive_undeclared_items')}
                </Button>
              </ListItemSkeleton>

              <SetUndeclaredItemsDataDialog
                open={isItemsDialogOpen}
                shipmentId={shipment?.id || ''}
                shipmentVersion={shipment?.version || 0}
                onClose={() => setItemsDialogOpen(false)}
                onSuccess={() => {
                  enqueueSnackbar(
                    t('shipments:shipment_message.success_update'),
                  )
                  setItemsDialogOpen(false)
                }}
                onError={(errorMessage) => {
                  enqueueSnackbar(errorMessage, { variant: 'error' })
                  setItemsDialogOpen(false)
                }}
              />
            </>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
