import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from '@mui/material'
import { ReactNode } from 'react'

type Props = AccordionProps & {
  children?: ReactNode
  summary?: ReactNode
  'aria-controls': string
}

export const ListItemAccordion = (props: Props) => {
  const { children, summary, 'aria-controls': ariaControls, ...rest } = props

  return (
    <Accordion {...rest}>
      <AccordionSummary
        aria-controls={ariaControls}
        sx={{
          pl: 0,
          '.MuiAccordionSummary-content': {
            my: 0,
            boxShadow: (theme) => `inset 0px 0px 0px ${theme.palette.blueBase}`,
            transition: (theme) =>
              `box-shadow 100ms ${theme.transitions.easing.easeInOut}`,

            '&.Mui-expanded': {
              boxShadow: (theme) =>
                `inset 3px 0px 0px ${theme.palette.blueBase}`,
              transition: (theme) =>
                `box-shadow 150ms ${theme.transitions.easing.easeInOut}`,
            },
          },

          '.MuiAccordionSummary-content .MuiListItem-root': {
            background: 'inherit',
          },
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
          '.MuiListItem-root': {
            boxShadow: (theme) => `inset 3px 0px 0px ${theme.palette.gray20}`,
          },
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  )
}
