import { ListItem, ListItemProps, Skeleton } from '@mui/material'
import { forwardRef } from 'react'

type Props = ListItemProps & {
  isLoading?: boolean
  hide?: boolean
  childrenSkeleton?: boolean
}

export const ListItemSkeleton = forwardRef<HTMLLIElement, Props>(
  function ListItemSkeleton(props, ref) {
    const { isLoading, hide, sx, childrenSkeleton, ...listItemProps } = props

    if (hide) return null

    return (
      <ListItem
        ref={ref}
        {...listItemProps}
        secondaryAction={
          isLoading && listItemProps.secondaryAction ? (
            <Skeleton width="min(10vw, 60px)" />
          ) : (
            listItemProps.secondaryAction
          )
        }
        sx={{
          // display: hide ? 'none' : 'flex',
          display: 'flex',
          justifyContent:
            childrenSkeleton && props.isLoading
              ? 'space-between'
              : 'flex-start',
          ...sx,
        }}
      >
        {childrenSkeleton && props.isLoading ? (
          <Skeleton width="min(10vw, 100px)" />
        ) : (
          listItemProps.children
        )}
      </ListItem>
    )
  },
)
