import { Autocomplete, SxProps, TextField } from '@mui/material'
import { Ref, useMemo } from 'react'
import { useTracking } from '../../hooks/tracking'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  sx?: SxProps
  isRequired?: boolean
  innerRef?: Ref<HTMLElement>
  label?: string
  helperText?: string
}

export const CurrenciesInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, control, rules, isRequired, sx, innerRef, disabled } = props
  const { trackInputFocus, trackInputBlur } = useTracking()
  const { t, i18n } = useTranslation(['shared'])

  const { language } = i18n

  const fieldLabel = t('shared:label.currency')

  const { field, fieldState, formState } = useController({
    name,
    control,
    rules: {
      ...rules,
      required: isRequired
        ? t('shared:validation.field_required', { field: fieldLabel })
        : undefined,
    },
  })

  const { isSubmitting } = formState

  const currencyNames = new Intl.DisplayNames([language], { type: 'currency' })

  const currencies = Intl.supportedValuesOf('currency').map((currency) => ({
    id: currency,
    label: `${currency}, ${currencyNames.of(currency)}`,
  }))

  const value = useMemo(() => {
    return currencies.find((currency) => currency.id === field.value) || null
  }, [field.value, currencies])

  const formError = fieldState.error

  return (
    <Autocomplete
      ref={innerRef}
      sx={sx}
      fullWidth
      autoHighlight
      value={value}
      disabled={isSubmitting || disabled}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.label || ''}
      options={currencies}
      onChange={(_event, value) => field.onChange(value?.id || null)}
      data-testid="currencies-input-container"
      renderInput={(params) => (
        <TextField
          type="text"
          {...params}
          helperText={formError?.message ?? props.helperText}
          error={!!formError}
          label={props.label || fieldLabel}
          onFocus={trackInputFocus({ name: `currency-input` })}
          onBlur={trackInputBlur({ name: `currency-input` })}
          required={isRequired}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              ['data-testid']: 'currency-input',
            },
          }}
        />
      )}
    />
  )
}
