import { PageData } from 'analytics'
import { useCallback, useEffect, useMemo } from 'react'
import { useAnalytics } from 'use-analytics'
import { useMe } from '../me'
import { trackButtonClick, trackButtonClickEvent } from './button'
import { trackDialogClose, trackDialogOpen } from './dialog'
import { trackDragEnd, trackDragStart } from './drag'
import { trackFormError, trackFormSuccess } from './form'
import {
  trackInput,
  trackInputBlur,
  trackInputChange,
  trackInputFocus,
} from './input'
import { trackMenuClose, trackMenuOpen } from './menu'
import { BaseMetadata, CustomTrack, ExtendableMetadata } from './types'

export const useTracking = () => {
  const { track: originalTrack, page, identify, reset, user } = useAnalytics()
  const { me } = useMe()
  const track: CustomTrack = originalTrack

  const baseMetadata = useMemo<BaseMetadata>(
    () => ({
      url: window.location.href,
      accountId: me?.accountId,
      siteId: me?.siteId,
      userRole: me?.role,
    }),
    [me?.accountId, me?.role, me?.siteId],
  )

  const userId = useMemo(() => user('userId') as string | null, [user])

  useEffect(() => {
    if (userId !== me?.user?.id && me?.user?.id) {
      void identify(me?.user?.id, {
        accountId: me?.accountId,
        siteId: me?.siteId,
        userRole: me?.role,
      })
    }
  }, [identify, me?.accountId, me?.user?.id, me?.role, me?.siteId, userId])

  /**
   * NOTE: There is no good reason to keep function like `trackButtonClick`
   * in a separate file. Feel free to refactor this.
   */

  const _trackButtonClick = useCallback(
    (metadata: ExtendableMetadata) =>
      trackButtonClick(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackButtonClickEvent = useCallback(
    (
      metadata: ExtendableMetadata,
      onClick?: React.MouseEventHandler | ((event?: unknown) => Promise<void>),
    ) => trackButtonClickEvent(track, baseMetadata)(metadata, onClick),
    [baseMetadata, track],
  )

  const _trackInput = useCallback(
    (metadata: ExtendableMetadata) => trackInput(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackInputFocus = useCallback(
    (metadata: ExtendableMetadata) =>
      trackInputFocus(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackInputBlur = useCallback(
    (metadata: ExtendableMetadata) =>
      trackInputBlur(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackInputChange = useCallback(
    (metadata: ExtendableMetadata) =>
      trackInputChange(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackDialogOpen = useCallback(
    (metadata: ExtendableMetadata) =>
      trackDialogOpen(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackDialogClose = useCallback(
    (metadata: ExtendableMetadata) =>
      trackDialogClose(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackFormSuccess = useCallback(
    (metadata: ExtendableMetadata) =>
      trackFormSuccess(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackFormError = useCallback(
    (metadata: ExtendableMetadata) =>
      trackFormError(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackDragStart = useCallback(
    (metadata: ExtendableMetadata) =>
      trackDragStart(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackDragEnd = useCallback(
    (metadata: ExtendableMetadata) =>
      trackDragEnd(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackMenuOpen = useCallback(
    (metadata: ExtendableMetadata) =>
      trackMenuOpen(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _trackMenuClose = useCallback(
    (metadata: ExtendableMetadata) =>
      trackMenuClose(track, baseMetadata)(metadata),
    [baseMetadata, track],
  )

  const _track = useCallback(
    (name: string, metadata: ExtendableMetadata & { category: string }) => {
      void track(name, {
        ...baseMetadata,
        ...metadata,

        label: metadata.name,
      })
    },
    [baseMetadata, track],
  )

  const _page = useCallback(
    (pageData: PageData) => page({ ...baseMetadata, ...pageData }),
    [baseMetadata, page],
  )

  return {
    identify,
    reset,
    page: _page,
    track: _track,

    trackButtonClick: _trackButtonClick,
    trackButtonClickEvent: _trackButtonClickEvent,

    trackInput: _trackInput,
    trackInputFocus: _trackInputFocus,
    trackInputBlur: _trackInputBlur,
    trackInputChange: _trackInputChange,

    trackDialogOpen: _trackDialogOpen,
    trackDialogClose: _trackDialogClose,

    trackFormSuccess: _trackFormSuccess,
    trackFormError: _trackFormError,

    trackDragStart: _trackDragStart,
    trackDragEnd: _trackDragEnd,

    trackMenuOpen: _trackMenuOpen,
    trackMenuClose: _trackMenuClose,
  }
}
