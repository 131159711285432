import { Box, ListItemIcon } from '@mui/material'
import { QuestionIcon } from '@sitoo/mui-components'

type Props = {
  url?: string | undefined
}

export const ListItemProductImage = ({ url }: Props) => {
  return (
    <ListItemIcon>
      {url ? (
        <Box
          component="img"
          sx={{
            maxWidth: 40,
            maxHeight: 40,
            width: 40,
            objectFit: 'contain',
          }}
          src={url}
          alt=""
        />
      ) : (
        <Box
          sx={{
            background: (t) => t.palette.gray20,
            height: '40px',
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <QuestionIcon
            sx={{
              color: (t) => t.palette.gray50,
            }}
          />
        </Box>
      )}
    </ListItemIcon>
  )
}
