import { useApolloClient } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  SectionHeader,
  ShipmentInboundIcon,
  ShipmentOutgoingIcon,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetShipmentV2Query,
  GetShipmentsV2Document,
  ShipmentStateV2,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { SetStateDialog } from '../../shipment-v2/set-state-dialog'

type Props = {
  isLoading?: boolean
  shipment?: GetShipmentV2Query['getShipmentV2']
  allItemsReceived: boolean
}

export const Information = ({
  shipment,
  isLoading,
  allItemsReceived,
}: Props) => {
  const { t } = useTranslation(['shared', 'countries', 'shipments_v2'])
  const [showSetStateDialog, setShowSetStateDialog] = useState(false)
  const client = useApolloClient()
  const { enqueueSnackbar } = useSnackbar()
  const { formatDate } = useLocalizedDate()

  if (!shipment) return

  const sender = [
    shipment?.sender_name,
    shipment?.sender_referenceemail,
    shipment?.sender_address,
    shipment?.sender_address2,
    `${shipment?.sender_city}${shipment?.sender_state && `, ${shipment?.sender_state}`}${shipment?.sender_zip && `, ${shipment?.sender_zip}`}`,
    shipment?.sender_countryid
      ? t(`countries:${shipment?.sender_countryid.toUpperCase()}`)
      : '',
  ]
    .filter(Boolean)
    .join('\n')

  const receiver = [
    shipment?.receiver_name,
    shipment?.receiver_referenceemail,
    shipment?.receiver_address,
    shipment?.receiver_address2,
    [shipment?.receiver_city, shipment?.receiver_state, shipment?.receiver_zip]
      .filter(Boolean)
      .join(', '),
    shipment?.receiver_countryid
      ? t(`countries:${shipment?.receiver_countryid.toUpperCase()}`)
      : '',
  ]
    .filter(Boolean)
    .join('\n')

  return (
    <Accordion
      defaultExpanded
      className="MuiAccordionRoot"
      data-testid="view-panel-information-section"
    >
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>
          {t('shipments_v2:view_panel.information')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={!isLoading && !sender}
            isLoading={isLoading}
            divider
            childrenSkeleton
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            <ListItemIcon>
              <ShipmentOutgoingIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('shipments_v2:sender')}
              secondary={sender}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !receiver}
            isLoading={isLoading}
            divider
            childrenSkeleton
            sx={{
              whiteSpace: 'pre-line',
            }}
          >
            <ListItemIcon>
              <ShipmentInboundIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('shipments_v2:receiver')}
              secondary={receiver}
            />
          </ListItemSkeleton>
          <ListItemSkeleton
            secondaryAction={
              <>
                {shipment?.shipmentstate &&
                shipment?.shipmentstate === ShipmentStateV2.Cancelled ? (
                  <ListItemText>
                    {t(
                      `shipments_v2:shipment_state.${
                        shipment?.shipmentstate || ''
                      }`,
                    )}
                  </ListItemText>
                ) : (
                  <>
                    <SetStateDialog
                      open={showSetStateDialog}
                      state={shipment.shipmentstate as ShipmentStateV2}
                      shipmentId={shipment.shipmentid as number}
                      onClose={() => setShowSetStateDialog(false)}
                      onSuccess={() => {
                        void client.refetchQueries({
                          include: [GetShipmentsV2Document],
                        })
                        enqueueSnackbar(
                          t('shipments_v2:shipment_message.success_update'),
                        )
                        setShowSetStateDialog(false)
                      }}
                      onError={(errorMessage) => {
                        enqueueSnackbar(errorMessage, { variant: 'error' })
                      }}
                    />
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => setShowSetStateDialog(true)}
                    >
                      {t('shared:action.set')}
                    </Button>
                  </>
                )}
              </>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              secondary={
                shipment?.shipmentstate &&
                shipment.shipmentstate !== ShipmentStateV2.Cancelled
                  ? t(
                      `shipments_v2:shipment_state.${
                        shipment?.shipmentstate || ''
                      }`,
                    )
                  : ''
              }
            >
              {t('shipments_v2:state')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            secondaryAction={
              <ListItemText
                sx={{
                  textAlign: 'end',
                }}
                secondaryTypographyProps={{
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                }}
              >
                {shipment?.datenew ? formatDate(shipment?.datenew) : '-'}
              </ListItemText>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                },
              }}
            >
              {t('shipments_v2:view_panel.created_at')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            secondaryAction={
              <ListItemText
                sx={{
                  textAlign: 'end',
                }}
                secondaryTypographyProps={{
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                }}
              >
                {shipment?.dateintransit
                  ? formatDate(shipment?.dateintransit)
                  : '-'}
              </ListItemText>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                },
              }}
            >
              {t('shipments_v2:view_panel.sent_at')}
            </ListItemText>
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
