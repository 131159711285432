import { MouseEventHandler } from 'react'
import { ExtendableMetadata, TrackAction } from './types'

type ButtonMetadata = ExtendableMetadata & {
  // buttonName: string
}

const BUTTON_CLICK = 'ButtonClick'
const BUTTON_CATEGORY = 'Button'

type TrackButton = TrackAction<{ (metadata: ButtonMetadata): void }>

export const trackButtonClick: TrackButton =
  (track, baseMetadata) => (metadata) => {
    void track(BUTTON_CLICK, {
      ...baseMetadata,

      ...metadata,

      category: BUTTON_CATEGORY,
      label: metadata.name,
    })
  }

type TrackButtonClickEvent = TrackAction<
  (
    metadata: ButtonMetadata,
    onClick?: MouseEventHandler | ((event?: unknown) => Promise<void>),
  ) => MouseEventHandler<Element>
>

export const trackButtonClickEvent: TrackButtonClickEvent =
  (track, baseMetadata) => (metadata, onClick) => (event) => {
    trackButtonClick(track, baseMetadata)(metadata)
    void onClick?.(event)
  }
