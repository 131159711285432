import { Dropzone } from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { SendFeedbackDocument } from '../../generated/graphql'
import { makeVar, useMutation, useReactiveVar } from '@apollo/client'
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { Form, useForm } from 'react-hook-form'
import { TextFieldInput } from '../../inputs/text-field-input'

type Form = {
  message: string
  files: UploadedFile[]
}

export const feedbackDialogOpenVar = makeVar<boolean>(false)

export const FeedbackDialog = () => {
  const { t } = useTranslation(['shared'])
  const isOpen = useReactiveVar(feedbackDialogOpenVar)

  const { enqueueSnackbar } = useSnackbar()

  const [sendFilesMutation, { loading: isLoading }] = useMutation(
    SendFeedbackDocument,
    { context: { headers: { 'apollo-require-preflight': true } } },
  )

  const { control, handleSubmit, setValue, reset } = useForm<Form>({
    defaultValues: { message: '', files: [] },
  })

  const onClose = () => {
    feedbackDialogOpenVar(false)
    reset()
  }

  const sendFiles = async ({ files, message }: Form) => {
    try {
      await sendFilesMutation({
        variables: {
          message,
          path: window.location.pathname,
          files: files.map(({ file }) => file),
        },
      })
      enqueueSnackbar(t('shared:feedback_dialog.success_message'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('shared:feedback_dialog.error_message'), {
        variant: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Dialog
      data-testid="export-dialog"
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle data-testid="export-dialog-header" type="extended">
        {t('shared:feedback_dialog.dialog_title')}
      </DialogTitle>

      <DialogContent>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form id="form" onSubmit={handleSubmit(sendFiles)}>
          <TextFieldInput
            name="message"
            control={control}
            rules={{ required: true }}
            multiline
            rows={5}
            sx={{ mb: 2 }}
            placeholder={t('shared:feedback_dialog.input_placeholder')}
          />

          <Dropzone
            label={t('shared:feedback_dialog.dropzone_label')}
            setFiles={(files) => setValue('files', files)}
            isLoading={isLoading}
            accept={{ 'image/*': [], 'video/*': [] }}
            hideFileName
            helperText={t('shared:feedback_dialog.dropzone_helper_text')}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button data-testid="cancel-button" color="secondary" onClick={onClose}>
          {t('shared:action.cancel')}
        </Button>

        <LoadingButton
          data-testid="export-button"
          loading={isLoading}
          form="form"
          type="submit"
        >
          {t('shared:action.send')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
