import { ListItemIcon, ListItemText, SxProps, Theme } from '@mui/material'
import { UserDefaultIcon } from '@sitoo/mui-components'
import { forwardRef, ReactNode } from 'react'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { ShipmentContact } from '../../../../generated/graphql'

type Props = {
  fallbackTitle?: string
  contact?: ShipmentContact | null
  sx?: SxProps<Theme>
  disabled?: boolean
  error?: boolean

  isLoading?: boolean
  hide?: boolean
  divider?: boolean
  secondaryAction?: ReactNode
}

export const ContactListItem = forwardRef<HTMLLIElement, Props>(
  function ContactListItem(
    {
      fallbackTitle,
      contact,
      sx,
      disabled,
      error,

      ...otherProps
    },
    ref,
  ) {
    const name = [contact?.title, contact?.first_name, contact?.last_name]
      .filter(Boolean)
      .join(' ')

    return (
      <ListItemSkeleton
        ref={ref}
        childrenSkeleton
        sx={{
          whiteSpace: 'pre-line',
          minHeight: (theme) => theme.spacing(8),
          ...sx,
        }}
        {...otherProps}
      >
        <ListItemIcon
          sx={{
            ...(error
              ? {
                  color: (theme) => theme.palette.error.main,
                }
              : disabled
                ? {
                    color: (theme) => theme.palette.gray50,
                  }
                : {}),
          }}
        >
          <UserDefaultIcon />
        </ListItemIcon>
        <ListItemText
          primary={name || fallbackTitle}
          sx={{
            ...(error
              ? {
                  color: (theme) => theme.palette.error.main,
                }
              : disabled
                ? {
                    color: (theme) => theme.palette.gray50,
                  }
                : {}),
          }}
          secondaryTypographyProps={{
            sx: {
              '&.MuiListItemText-secondary': {
                ...(error
                  ? {
                      color: (theme) => theme.palette.error.main,
                    }
                  : disabled
                    ? {
                        color: (theme) => theme.palette.gray50,
                      }
                    : {}),
              },
            },
          }}
          secondary={[contact?.email, contact?.mobile, contact?.note]
            .filter(Boolean)
            .join('\n')}
        />
      </ListItemSkeleton>
    )
  },
)
