import { List, ListItemText, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import {
  GetJournalEntriesQuery,
  JournalStaff,
} from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { ArrayElement } from '../../../../utils/types'
import { ReportDetails } from './report-details'
import { PrintoutDetails } from './printout-details'
import { CartDetails } from './cart-details'
import { CartPaymentDetails } from './cart-payment-details'
import { ReceiptDetails } from './receipt-details'
import { CartItemDetails } from './cart-item-details'
import { CashDetails } from './cash-details'
import { CtuErrorDetails } from './ctu-error-details'
import { StaffDetails } from './staff-details'

type Props = {
  journal?: ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>
}

export const Details = ({ journal }: Props) => {
  const { t } = useTranslation(['shared', 'journals'])

  const { formatDate } = useLocalizedDate()

  return (
    <Paper elevation={0}>
      <List>
        <ListItemSkeleton
          hide={!journal?.id}
          secondaryAction={<ListItemText>{journal?.id}</ListItemText>}
          childrenSkeleton
          divider
          data-testid="journal-id"
        >
          <ListItemText>{t('journals:id')}</ListItemText>
        </ListItemSkeleton>
        <ListItemSkeleton
          hide={!journal?.dateadded}
          secondaryAction={
            <ListItemText>
              {journal?.dateadded ? formatDate(journal?.dateadded) : '-'}
            </ListItemText>
          }
          childrenSkeleton
          divider
          data-testid="journal-date-added"
        >
          <ListItemText>{t('journals:added_at')}</ListItemText>
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!journal?.datecreated}
          secondaryAction={
            <ListItemText>
              {journal?.datecreated ? formatDate(journal?.datecreated) : '-'}
            </ListItemText>
          }
          childrenSkeleton
          divider
          data-testid="journal-date-created"
        >
          <ListItemText>{t('journals:created_at')}</ListItemText>
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!journal?.registerkey}
          childrenSkeleton
          divider
          data-testid="journal-register-key"
        >
          <ListItemText
            primary={t('journals:register_key')}
            secondary={journal?.registerkey}
          />
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!journal?.registerid}
          childrenSkeleton
          divider
          data-testid="journal-register-id"
        >
          <ListItemText secondary={journal?.registerid}>
            {t('journals:register_id')}
          </ListItemText>
        </ListItemSkeleton>

        <ListItemSkeleton
          hide={!journal?.ispractice}
          secondaryAction={<ListItemText>{t('shared:label.yes')}</ListItemText>}
          childrenSkeleton
          divider
          data-testid="journal-is-practice"
        >
          <ListItemText>{t('journals:is_practice')}</ListItemText>
        </ListItemSkeleton>

        {journal?.data.__typename !== 'JournalCtuError' && (
          <StaffDetails journal={journal?.data as JournalStaff} />
        )}

        {journal?.data.__typename === 'JournalReport' && (
          <ReportDetails journal={journal.data} />
        )}

        {journal?.data.__typename === 'JournalReceipt' && (
          <ReceiptDetails journal={journal.data} />
        )}

        {journal?.data.__typename === 'JournalPrintout' && (
          <PrintoutDetails journal={journal.data} />
        )}

        {journal?.data.__typename === 'JournalCart' && (
          <CartDetails journal={journal.data} />
        )}

        {journal?.data.__typename === 'JournalCartPayment' && (
          <CartPaymentDetails journal={journal.data} />
        )}

        {journal?.data.__typename === 'JournalCartItem' && (
          <CartItemDetails journal={journal.data} />
        )}

        {journal?.data.__typename === 'JournalCash' && (
          <CashDetails journal={journal.data} />
        )}

        {journal?.data.__typename === 'JournalCtuError' && (
          <CtuErrorDetails journal={journal.data} />
        )}
      </List>
    </Paper>
  )
}
