import { GridColDef } from '@mui/x-data-grid-pro'

type ColumnProps = Record<string, Partial<GridColDef>>

export const ColumnProps = {
  sku: { minWidth: 160 },
  barcode: { minWidth: 160 },
  image: { maxWidth: 40, minWidth: 40, resizable: false, hideable: false },
  date: { minWidth: 160 },
  price: { minWidth: 120 },
  editablePrice: { minWidth: 120 },

  productTitle: { minWidth: 320 },
  productId: { minWidth: 80 },
  productActive: { minWidth: 80 },
  productGroup: { minWidth: 160 },
  productCategory: { minWidth: 160 },
  productPriceRange: { minWidth: 240 },
} satisfies ColumnProps
