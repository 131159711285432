import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  Chip,
  Stack,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { Indicator } from '../../../components/indicator'
import { InfluencerCode } from '.'

type Props = {
  influencerCode?: InfluencerCode
  isLoading: boolean
}

export const Details = (props: Props) => {
  const { influencerCode, isLoading } = props
  const { t } = useTranslation(['shared', 'influencer_codes'])

  const { formatDate } = useLocalizedDate()

  return (
    <>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="details">
          <SectionHeader sx={{ p: 0 }}>
            {t('shared:label.details')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List>
            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                <ListItemText>{influencerCode?.name}</ListItemText>
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-name"
            >
              <ListItemText>{t('influencer_codes:name')}</ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="influencer-code-code"
            >
              <ListItemText
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                  <Chip
                    size="small"
                    color="black"
                    label={influencerCode?.code}
                  />
                }
              >
                {t('influencer_codes:code')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                influencerCode?.datestart ? (
                  <Indicator
                    active={new Date(influencerCode.datestart) > new Date()}
                  >
                    {formatDate(influencerCode?.datestart)}
                  </Indicator>
                ) : (
                  '-'
                )
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-datestart"
            >
              <ListItemText>{t('influencer_codes:activation')}</ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                influencerCode?.dateend ? (
                  <Indicator
                    active={new Date(influencerCode.dateend) > new Date()}
                  >
                    {formatDate(influencerCode?.dateend)}
                  </Indicator>
                ) : (
                  '-'
                )
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-dateend"
            >
              <ListItemText>{t('influencer_codes:expire')}</ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                influencerCode?.maxuses
                  ? `${influencerCode.numused || 0} / ${influencerCode.maxuses}`
                  : influencerCode?.numused || 0
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-uses"
            >
              <ListItemText>{t('influencer_codes:uses')}</ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={influencerCode?.campaigns?.length ? '' : '-'}
              childrenSkeleton
              divider
              data-testid="influencer-code-activates-campaigns"
            >
              <ListItemText
                secondaryTypographyProps={{ component: 'div' }}
                secondary={
                  <Stack direction="row" spacing={0.5} mt={0.5}>
                    {influencerCode?.campaigns?.map((campaign) => (
                      <Chip
                        key={campaign.id}
                        size="small"
                        color="grayLight"
                        label={campaign.vouchername}
                      />
                    ))}
                  </Stack>
                }
              >
                {t('influencer_codes:activates_campaigns')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              childrenSkeleton
              secondaryAction={influencerCode?.vouchercode || '-'}
              divider
              data-testid="influencer-code-campaign-group"
            >
              <ListItemText>
                {t('influencer_codes:campaign_group_override')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              childrenSkeleton
              secondaryAction={influencerCode?.vouchername || '-'}
              divider
              data-testid="influencer-code-campaign-name"
            >
              <ListItemText>
                {t('influencer_codes:campaign_name_override')}
              </ListItemText>
            </ListItemSkeleton>
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
