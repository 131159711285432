import {
  ApolloProvider as Provider,
  ApolloClient,
  from,
  ApolloLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLFormattedError } from 'graphql'
import { t } from 'i18next'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { globalNotificationState, cache } from '../../cache'
import { RootRoute } from '../../pages'
import { createRedirectParam } from '../../utils/redirect'
import { router } from '../router/router'
import {
  isAuthenticationError,
  isAuthorizationError,
} from '../../utils/extract-graphql-errors'

const createApolloLink = (): ApolloLink => {
  return createUploadLink({ uri: '/graphql', credentials: 'include' })
}

const isNotFoundError = (error: GraphQLFormattedError) => {
  return error.extensions?.['code'] === 'RESOURCE_NOT_FOUND'
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const excludedRoutes = [
      RootRoute.Login,
      RootRoute.Logout,
      RootRoute.Callback,
      RootRoute.ResetPassword,
    ]

    const isExcludedRoute = router.state.matches.find(({ pathname }) =>
      excludedRoutes.includes(pathname),
    )

    for (const error of graphQLErrors) {
      if (isExcludedRoute) continue

      // eslint-disable-next-line no-console
      console.warn(`[GraphQL Error]:`, error)

      if (isAuthenticationError(error)) {
        globalNotificationState({
          variant: 'info',
          message: t('shared:error.session_expired', { ns: 'shared' }),
        })

        void router.navigate({
          pathname: RootRoute.Logout,
          search: createRedirectParam(),
        })
      }

      if (isAuthorizationError(error)) {
        globalNotificationState({
          variant: 'error',
          message: t('shared:error.forbidden', { ns: 'shared' }),
        })
      }

      if (isNotFoundError(error)) {
        globalNotificationState({
          variant: 'error',
          message: t('shared:error.resource_not_found', { ns: 'shared' }),
        })
      }
    }
  }

  if (!graphQLErrors && networkError) {
    globalNotificationState({
      variant: 'error',
      message: t('shared:error.network_error', { ns: 'shared' }),
    })
    // eslint-disable-next-line no-console
    console.warn(`[Network error]:`, networkError)
  }
})

const client = new ApolloClient({
  link: from([errorLink, removeTypenameFromVariables(), createApolloLink()]),
  cache,
})

type Props = { children: React.ReactNode }

export const ApolloProvider = ({ children }: Props) => (
  <Provider client={client}>{children}</Provider>
)
