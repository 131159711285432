import { useTranslation } from 'react-i18next'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { Box } from '@mui/material'
import { JournalCtuError as JournalCtuErrorType } from '../../../generated/graphql'
import { JournalDivider } from './journal-divider'
import { JournalItem } from '.'
import { PrintContainer } from './print-container'

type Props = {
  journal: JournalItem
  data: JournalCtuErrorType
}

export const JournalCtuError = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal, data } = props
  const { formatDate } = useLocalizedDate()
  const getDate = (date: string) => formatDate(new Date(date))

  return (
    <PrintContainer>
      <Box>{journal?.datecreated ? getDate(journal?.datecreated) : ''}</Box>

      <Box>
        <strong>
          {t('journals:journal_entry_id', {
            type: t(`journals:journal_entry_type_enum.${journal.type}`),
            id: journal.id,
          })}
        </strong>
      </Box>

      <Box>{data.errortext}</Box>

      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
