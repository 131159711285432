import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { SetProductGroupDialog } from '../../set-product-group-dialog'

type SetProductGroupProps = {
  isOpen: boolean
  onClose(): void
  refetch(): unknown
}

export const SetProductGroup = (props: SetProductGroupProps) => {
  const { t } = useTranslation(['products'])

  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const selectedProductIds = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<{
      id: number
    }>[]
  ).map((c) => c.id)

  const onClose = () => {
    props.onClose()
  }

  const onSuccess = (
    successProductIds: number[],
    failedProductIds: number[],
  ) => {
    props.refetch()

    if (successProductIds.length) {
      enqueueSnackbar(
        t('products:success_set_product_group_snackbar', {
          count: successProductIds.length,
        }),
      )
    }

    if (failedProductIds.length) {
      enqueueSnackbar(
        t('products:failure_set_product_group_snackbar', {
          count: failedProductIds.length,
        }),
        { variant: 'error' },
      )
    }

    onClose()
  }

  const onError = () => {
    props.refetch()
    onClose()
  }

  return (
    <SetProductGroupDialog
      open={props.isOpen}
      onClose={onClose}
      productIds={selectedProductIds}
      onSuccess={onSuccess}
      onError={onError}
    />
  )
}
