import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { MeDocument } from '../../generated/graphql'
import { useLocalStorage } from 'react-use'
import { useQuery } from '@apollo/client'

export const useLocationSpecificKey = (key: string) => {
  const { data } = useQuery(MeDocument)
  const location = useLocation()
  const userId = data?.me.user?.id || ''

  return `${key}:${userId}:${location.pathname}`
}

export const useScopeSpecificKey = (key: string) => {
  const { data } = useQuery(MeDocument)
  const userId = data?.me.user?.id || ''

  return `${key}:${userId}`
}

export const usePersistedValues = <T,>(
  ...params: Parameters<typeof useLocalStorage<T>>
) => {
  const [key, defaultValue, options] = params
  const [values, setValues] = useLocalStorage(key, defaultValue, options)

  const appendValues = useCallback(
    (nextValues: T) => {
      const rawValue = localStorage.getItem(key) || ''
      let parsedValue

      if (options?.raw === false) {
        parsedValue = options.deserializer(rawValue)
      } else {
        parsedValue = JSON.parse(rawValue) as T
      }

      setValues({ ...parsedValue, ...nextValues })
    },
    [key, options, setValues],
  )

  return [values, setValues, appendValues] as [
    ReturnType<typeof useLocalStorage<T>>[0],
    ReturnType<typeof useLocalStorage<T>>[1],
    (nextValues: T) => void,
  ]
}
