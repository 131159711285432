import { CampaignType } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import {
  translateCampaignTypeExample,
  translateCampaignTypeTitle,
} from '../i18n'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type PricelistCampaignSelectionProps = {
  onSelectCampaignType: (campaignType: CampaignType) => void
  ['data-testid']?: string
}

export const PricelistCampaignSelection = (
  props: PricelistCampaignSelectionProps,
) => {
  const { trackButtonClickEvent } = useTracking()

  return (
    <div data-testid={props['data-testid']}>
      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-price-list-activate-price-lists' },
          () => props.onSelectCampaignType(CampaignType.PricelistActivate),
        )}
        primary={translateCampaignTypeTitle(CampaignType.PricelistActivate)}
        secondary={translateCampaignTypeExample(CampaignType.PricelistActivate)}
        data-testid="activate-pricelists-tags-listItem"
        hasExample
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-price-list-activate-price-lists-block-other-tags',
          },
          () => props.onSelectCampaignType(CampaignType.PricelistActivateFinal),
        )}
        primary={translateCampaignTypeTitle(
          CampaignType.PricelistActivateFinal,
        )}
        secondary={translateCampaignTypeExample(
          CampaignType.PricelistActivateFinal,
        )}
        data-testid="activate-pricelists-block-other-tags-listItem"
        hasExample
      />
    </div>
  )
}
