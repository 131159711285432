import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  LoginOptionTypeEnum,
  DeleteLoginOptionDocument,
  LoginOptionsDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'

export type DeleteLoginOptionDialogProps = {
  open: boolean
  loginOptionId?: string
  loginOptionType?: LoginOptionTypeEnum
  hasSingleLoginOption?: boolean
  dataTestid?: string
  onClose?(): void
  onSuccess?(): void
  onError?(): void
}

export const DeleteLoginOptionDialog = (
  props: DeleteLoginOptionDialogProps,
) => {
  const { t } = useTranslation(['settings'])
  const dialogName = 'delete-login-option'
  const isLoading = false
  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const { enqueueSnackbar } = useSnackbar()
  const [deleteLoginOptionMutation] = useMutation(DeleteLoginOptionDocument, {
    refetchQueries: [LoginOptionsDocument],
  })

  const onConfirm = async () => {
    if (!props.loginOptionId) return
    trackButtonClick({ name: `${dialogName}-dialog-delete` })

    try {
      await deleteLoginOptionMutation({
        variables: {
          loginOptionId: props.loginOptionId,
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })
      enqueueSnackbar(t('settings:authentication.success_deleted'))
      props.onSuccess?.()

      if (props.hasSingleLoginOption)
        enqueueSnackbar(t('settings:authentication.success_deleted_last'), {
          variant: 'info',
        })
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      enqueueSnackbar(t('settings:authentication.error_deleted'), {
        variant: 'error',
      })

      props.onError?.()
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={onConfirm}
      title={t('settings:authentication.delete_dialog.title', {
        loginOptionType: t(
          `settings:authentication.login_option_type.${
            props.loginOptionType || ''
          }`,
        ),
      })}
      text={t('settings:authentication.delete_dialog.description', {
        loginOptionType: t(
          `settings:authentication.login_option_type.${
            props.loginOptionType || ''
          }`,
        ),
      })}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
