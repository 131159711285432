import { Stack, Chip } from '@mui/material'
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'

export const tagsRenderer = (
  params: GridRenderCellParams<GridValidRowModel, string[]>,
) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{ height: '100%', alignItems: 'center' }}
  >
    {params.formattedValue?.map((tag) => (
      <Chip key={tag} size="small" color="grayLight" label={tag} />
    ))}
  </Stack>
)
