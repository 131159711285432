import { Autocomplete, SxProps, TextField } from '@mui/material'
import { useMemo } from 'react'
import {
  FieldError,
  FieldValues,
  UseControllerProps,
  get,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

type Store = {
  id: number | string
  name: string
}

type Props = {
  stores: Store[]
  multiple?: boolean
  placeholder?: string
  sx?: SxProps
  isLoading?: boolean
  required?: boolean
}

export const StoresInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, control, sx, multiple, rules, required } = props
  const { stores, isLoading, placeholder } = props
  const { trackInputFocus, trackInputBlur } = useTracking()

  const { t } = useTranslation(['shared'])

  const fieldLabel = t('shared:label.store')

  const { field, formState } = useController({
    name,
    control,
    rules: {
      ...rules,
      required: required
        ? t('shared:validation.field_required', { field: fieldLabel })
        : undefined,
    },
  })

  const formError = get(formState.errors, name) as FieldError

  const value = useMemo(() => {
    const fieldValue = field.value as Store | Store[] | null

    if (Array.isArray(fieldValue)) {
      return stores.filter((store) =>
        fieldValue.map((v) => v.id).includes(store.id),
      )
    }

    if (fieldValue?.id) {
      return stores.find((store) => store.id === fieldValue.id) || null
    }

    return multiple ? [] : null
  }, [field.value, multiple, stores])

  return (
    <Autocomplete
      sx={sx}
      multiple={multiple}
      fullWidth
      autoHighlight
      value={value}
      getOptionKey={(option) => option.id}
      getOptionLabel={(option) => option.name || ''}
      options={stores}
      disabled={isLoading}
      onChange={(_event, value) => field.onChange(value)}
      data-testid="stores-input-container"
      renderInput={(params) => (
        <TextField
          type="text"
          {...params}
          label={multiple ? t('shared:label.stores') : t('shared:label.store')}
          onFocus={trackInputFocus({ name: `stores-input` })}
          onBlur={trackInputBlur({ name: `stores-input` })}
          placeholder={placeholder}
          error={!!formError}
          helperText={formError?.message ?? ''}
          required={required}
          slotProps={{
            htmlInput: {
              ['data-testid']: 'stores-input',
              ...params.inputProps,
            },
          }}
        />
      )}
    />
  )
}
