import { SalesTax, SalesTaxQuery } from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'

export type BaseSalesTaxContext = SalesTaxQuery['salesTax']

export type BaseSalesTaxRuleContext = ArrayElement<
  SalesTaxQuery['salesTax']['productgrouprules']
>

export const containsInvalidRule = (salesTax: SalesTax) => {
  if (!salesTax?.productgrouprules) return false
  return salesTax.productgrouprules?.some((rule) => !rule.groupName) || false
}
