import { css } from '@emotion/react'
import { CssBaseline, GlobalStyles, Theme } from '@mui/material'

const customStyles = (theme: Theme) => css`
  html {
    -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: ${theme.palette.background.page};
  }

  :focus {
    outline: thin dotted black;
  }

  input[type='password']::-ms-reveal {
    display: none;
  }
`

export const SitooGlobalStyles = () => (
  <>
    <CssBaseline />
    <GlobalStyles styles={customStyles} />
  </>
)
