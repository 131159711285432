import { Avatar, Box, Stack, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import {
  AdyenIcon,
  CoineyIcon,
  CurrencySwitchIcon,
  IZettleIcon,
  KlarnaIcon,
  KlarnaOldIcon,
  MobilePayIcon,
  NetsIcon,
  OrderIcon,
  PaymentCashIcon,
  PaymentCreditCardIcon,
  PaymentCustomIcon,
  PaymentGiftcardIcon,
  PaymentUnknownIcon,
  ReceiptIcon,
  SwishIcon,
  VerifoneIcon,
  VippsIcon,
  VippsMobilePayIcon,
} from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import {
  AllPaymentTypesQuery,
  PosPaymentMethod,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { ElementType, useMemo } from 'react'

export const PaymentMethodRenderer = (
  params: GridRenderCellParams<
    ArrayElement<AllPaymentTypesQuery['allPaymentTypes']>,
    string
  >,
) => <PaymentMethod {...params.row} />

type PaymentMethodProps = {
  src?: string
  paymentmethodid: PosPaymentMethod
}

const PaymentMethod = (props: PaymentMethodProps) => {
  const { t } = useTranslation(['pos_payment'])

  const paymentMethodIconMap = useMemo<Record<PosPaymentMethod, ElementType>>(
    () => ({
      [PosPaymentMethod.CARD_ADYEN_SDK]: AdyenIcon,
      [PosPaymentMethod.CARD_ADYEN_TAP_TO_PAY]: AdyenIcon,
      [PosPaymentMethod.CARD_ADYEN_TERMINAL_API]: AdyenIcon,
      [PosPaymentMethod.CARD_COINEY]: CoineyIcon,
      [PosPaymentMethod.CARD_EXTERNAL]: PaymentCreditCardIcon,
      [PosPaymentMethod.CARD_HOST2T]: PaymentCreditCardIcon,
      [PosPaymentMethod.CARD_IZETTLE]: IZettleIcon,
      [PosPaymentMethod.CARD_NETS]: NetsIcon,
      [PosPaymentMethod.CARD_VERIFONE_AIRPAY]: VerifoneIcon,
      [PosPaymentMethod.CARD_VERIFONE_LPP]: VerifoneIcon,
      [PosPaymentMethod.CASH]: PaymentCashIcon,
      [PosPaymentMethod.CASH_ON_DELIVERY]: PaymentCashIcon,
      [PosPaymentMethod.CREDIT_NOTE]: ReceiptIcon,
      [PosPaymentMethod.CREDIT_NOTE_AS_GIFT_CARD]: PaymentGiftcardIcon,
      [PosPaymentMethod.CUSTOM_INVOICE]: PaymentCustomIcon,
      [PosPaymentMethod.CUSTOM_METHOD_1]: PaymentCustomIcon,
      [PosPaymentMethod.CUSTOM_METHOD_2]: PaymentCustomIcon,
      [PosPaymentMethod.CUSTOM_METHOD_3]: PaymentCustomIcon,
      [PosPaymentMethod.CUSTOM_METHOD_4]: PaymentCustomIcon,
      [PosPaymentMethod.CUSTOM_METHOD_5]: PaymentCustomIcon,
      [PosPaymentMethod.CUSTOM_PAYMENT_API]: PaymentUnknownIcon,
      [PosPaymentMethod.FOREIGN_CURRENCY_CASH]: CurrencySwitchIcon,
      [PosPaymentMethod.GIFT_CARD]: PaymentGiftcardIcon,
      [PosPaymentMethod.INVOICE]: OrderIcon,
      [PosPaymentMethod.KLARNA]: KlarnaIcon,
      [PosPaymentMethod.KLARNA_LEGACY]: KlarnaOldIcon,
      [PosPaymentMethod.MOBILEPAY]: MobilePayIcon,
      [PosPaymentMethod.SWISH]: SwishIcon,
      [PosPaymentMethod.SWISH_WITH_QR]: SwishIcon,
      [PosPaymentMethod.VIPPS]: VippsIcon,
      [PosPaymentMethod.VIPPS_MOBILEPAY]: VippsMobilePayIcon,
    }),
    [],
  )

  const PaymentIcon =
    paymentMethodIconMap[props.paymentmethodid] ?? PaymentUnknownIcon

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Box sx={{ display: 'flex' }}>
        <Avatar
          variant="square"
          src={props.src}
          sx={{
            width: 30,
            height: 30,
            background: 'white',
          }}
        >
          <PaymentIcon fontSize="medium" />
        </Avatar>
      </Box>
      <Stack direction="column" spacing={0} ml={1}>
        <Typography>
          {t(`pos_payment:payment_method.${props.paymentmethodid}`)}
        </Typography>
        {/* TODO: Implement payment type status */}
        {/* <Typography variant="caption">{'Status: Unknown'}</Typography> */}
      </Stack>
    </Box>
  )
}
