import { useQuery } from '@apollo/client'
import { Divider, IconButton, MenuItem, ListItemText } from '@mui/material'
import { DeleteIcon, PlusIcon, SectionHeader } from '@sitoo/mui-components'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterDocument, FilterValue } from '../../generated/graphql'
import { useMe } from '../../hooks/me'
import { useStateParams } from '../../hooks/state-params'
import { FilterContext } from '../data-grid/context'
import { filterPresetDeleteDialogVar } from './filter-preset-delete-dialog'
import { filterPresetDialogVar } from './filter-preset-save-dialog'

type Props = {
  setMenuOpen: (value: boolean) => void
}

export const FilterPreset = (props: Props) => {
  const { setMenuOpen } = props
  const { t } = useTranslation(['filter', 'shared'])
  const { me } = useMe()

  const [, , , setQueryStateFromString] = useStateParams()

  const filterKey = [me?.user?.id, location.pathname].filter(Boolean).join(':')

  const { hasApply, applyFilter } = useContext(FilterContext)

  const { data } = useQuery(FilterDocument)

  const presets = data?.filter.filter(({ key }) => key === filterKey) || []

  const onItemSelect = useCallback(
    (preset: FilterValue) => {
      setMenuOpen(false)

      setQueryStateFromString(preset.value, true, true)

      // NOTE: This is a workaround to trigger the filter the same way as it
      // would have done via browser back/forward navigation.
      // Ideally, this should be avoided
      window.dispatchEvent(new Event('popstate'))

      setTimeout(() => {
        if (hasApply) {
          void applyFilter()
        }
      })
    },
    [applyFilter, hasApply, setMenuOpen, setQueryStateFromString],
  )

  return (
    <>
      <SectionHeader
        variant="transparent"
        sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
      >
        {t('filter:filter_preset.menu_label')}
      </SectionHeader>

      <Divider />

      <MenuItem
        autoFocus
        role="menuitem"
        onClick={() => filterPresetDialogVar(true)}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <ListItemText>{t('shared:grid.save_filter')}</ListItemText>

        <IconButton edge="end" size="small">
          <PlusIcon />
        </IconButton>
      </MenuItem>

      {presets.map((preset) => (
        <MenuItem
          key={preset.id}
          role="menuitem"
          onClick={() => onItemSelect(preset)}
        >
          <ListItemText>{preset.name}</ListItemText>

          <IconButton
            edge="end"
            size="small"
            onClick={(event) => {
              event.stopPropagation()
              filterPresetDeleteDialogVar(preset.id)
            }}
          >
            <DeleteIcon />
          </IconButton>
        </MenuItem>
      ))}
    </>
  )
}
