import { ThemeProvider as MuiProvider } from '@mui/material/styles'
import { createSitooTheme } from '@sitoo/mui-components'
import { useReactiveVar } from '@apollo/client'
import { themeState } from '../../cache'
import { PropsWithChildren } from 'react'

export const ThemeProvider = (props: PropsWithChildren) => {
  const mode = useReactiveVar(themeState)
  const theme = createSitooTheme(mode)

  return <MuiProvider theme={theme}>{props.children}</MuiProvider>
}
