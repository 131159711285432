import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { StoreIdFilter } from '../../../filters/store-filter'
import { ServiceOrderState } from '../../../generated/graphql'
import { StateFilter } from '../service-orders-list/state-filter'

type Props = {
  isOpen: boolean
  onClose(): void
}

export type ServiceOrderFilterContext = {
  storeId?: string
  state?: ServiceOrderState
}

export const ServiceOrderFilterPanel = (props: Props) => {
  const { t } = useTranslation(['service_orders'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>{t('page_title')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <StateFilter />
            </ListItem>
            <ListItem>
              <StoreIdFilter useExternalId />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
