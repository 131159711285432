/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  ServiceOrderQuery,
  ServiceOrderState,
  UpdateServiceOrderDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'

export type StateDialogProps = {
  open: boolean
  serviceOrder?: ServiceOrderQuery['serviceOrder']
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
}

type Form = {
  state: ServiceOrderState
}

export const SetStateDialog = (props: StateDialogProps) => {
  const { t } = useTranslation(['service_orders', 'shared'])
  const dialogName = 'service-order-set-state'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const { formState, reset, handleSubmit, control } = useForm<Form>({
    mode: 'onChange',
  })

  const [updateServiceOrder, { loading: updateLoading }] = useMutation(
    UpdateServiceOrderDocument,
  )

  const serviceOrderStates: ServiceOrderState[] = useMemo(() => {
    return [
      ServiceOrderState.New,
      ServiceOrderState.InProgress,
      ServiceOrderState.ServiceDone,
      ServiceOrderState.Closed,
      ServiceOrderState.Cancelled,
    ]
  }, [])

  const isLoading = updateLoading
  const onSubmit = async (formData: Form) => {
    if (!props.serviceOrder || !props.serviceOrder.id) {
      props.onError?.(t('service_orders.service_order_message.failure_update'))
      return
    }
    const {
      created,
      modified,
      customer: { id: customerId, ...customerFields },
      product: {
        id,
        images,
        totalAfterShare,
        totalBeforeShare,
        ...productFields
      },
      ...restOfServiceOrder
    } = props.serviceOrder

    try {
      await updateServiceOrder({
        variables: {
          serviceOrder: {
            ...restOfServiceOrder,
            product: productFields,
            id: props.serviceOrder.id,
            state: formData.state,
            customer: customerFields,
          },
        },
      })

      trackFormSuccess({ name: `${dialogName}-dialog` })

      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'service_orders',
        t('service_orders:service_order_message.failure_update'),
      )

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onError?.(errorMessage)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset(
        { state: props.serviceOrder?.state || serviceOrderStates[0] },
        { keepDefaultValues: false },
      )
    }
  }, [
    props.open,
    prevOpen,
    trackDialogOpen,
    props.serviceOrder?.state,
    reset,
    serviceOrderStates,
  ])

  return (
    <Dialog open={props.open} onClose={onClose} maxWidth="xs" fullWidth>
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="service-order-set-state-dialog"
      >
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('service_orders:set_state_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="state"
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('service_orders:set_state_dialog.state')}
                </InputLabel>
                <Select
                  value={field.value || ''}
                  data-testid="set-state-dialog-dropdown-menu"
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                >
                  {serviceOrderStates.map((state) => (
                    <MenuItem value={state} key={state}>
                      {t(`service_orders:states.${state}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!error?.message}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            type="button"
            data-testid="set-state-dialog-cancel-button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            loading={isLoading}
            data-testid="set-state-dialog-set-button"
          >
            {t('shared:action.set')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
