import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemText,
} from '@mui/material'
import {
  OrderIcon,
  PaymentCashIcon,
  PrimitiveCircleSmallIcon,
  ProgressDoneIcon,
  ShipmentIcon,
} from '@sitoo/mui-components'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemIconTimeline } from '../../../../components/list-item-icon-timeline'
import { ListItemTimeline } from '../../../../components/list-item-timeline'
import {
  GetOrderHistoryQuery,
  OrderLogType,
} from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { ArrayElement } from '../../../../utils/types'

type Order = Exclude<GetOrderHistoryQuery['order'], undefined>

type Props = {
  isLoading?: boolean
  order?: Order
}

export const OrderHistory = (props: Props) => {
  const { t, i18n } = useTranslation(['shared', 'orders'])
  const { formatRelativeDate } = useLocalizedDate()

  const logs = (props.order?.orderlogitems || []).slice().reverse()

  const getLogTitle = (logType: OrderLogType) => {
    return i18n.exists(`orders:order_history.${logType}`)
      ? t(`orders:order_history.${logType}`)
      : '-'
  }

  const getLogIcon = (logType: OrderLogType) => {
    switch (logType) {
      case OrderLogType.Created:
      case OrderLogType.OrderStateOpen:
      case OrderLogType.OrderStateClosed:
      case OrderLogType.OrderStateCancelled:
      case OrderLogType.OrderStateAbandoned:
        return OrderIcon

      case OrderLogType.PaymentStateNone:
      case OrderLogType.PaymentStatePending:
      case OrderLogType.PaymentStateSuccessful:
      case OrderLogType.PaymentStateCancelled:
      case OrderLogType.PaymentStateFailed:
      case OrderLogType.PaymentStateReserved:
      case OrderLogType.PaymentReceived:
        return PaymentCashIcon

      case OrderLogType.DeliveryStateNone:
      case OrderLogType.DeliveryToTASystem:
      case OrderLogType.DeliveryAdded:
      case OrderLogType.DeliveryCancelled:
        return ShipmentIcon

      case OrderLogType.ConfirmationSent:
      case OrderLogType.DeliveryConfirmationSent:
        return ProgressDoneIcon

      default:
        return PrimitiveCircleSmallIcon
    }
  }

  const getOrderDeliveryContent = (orderDeliveryId: number) => {
    if (Number.isNaN(orderDeliveryId)) return

    const orderDelivery = props.order?.orderDelivery.find(
      (x) => x.orderdeliveryid === orderDeliveryId,
    )

    if (!orderDelivery) return

    const details = []

    if (orderDelivery.pluginname) details.push(orderDelivery.pluginname)

    if (orderDelivery.orderdeliveryid)
      details.push(
        `${t('orders:view_panel.delivery_id')}: ${
          orderDelivery.orderdeliveryid
        }`,
      )

    if (orderDelivery.warehouseid)
      details.push(
        `${t('orders:view_panel.warehouse_id')}: ${orderDelivery.warehouseid}`,
      )

    if (orderDelivery.warehouse?.name)
      details.push(
        `${t('orders:view_panel.warehouse_label')}: ${
          orderDelivery.warehouse?.name
        }`,
      )

    return details.join('\n') || undefined
  }

  const getDetailsContent = (log: ArrayElement<Order['orderlogitems']>) => {
    switch (log.orderlogtypeid) {
      case OrderLogType.DeliveryConfirmationSent: {
        const details = []

        if (log.strvalue && Number.isNaN(Number(log.strvalue))) {
          details.push(
            t('orders:confirmation_sent_to', { email: log.strvalue }),
          )
        }

        details.push(
          getOrderDeliveryContent(log.fvalue || Number(log.strvalue)),
        )

        return details.join('\n')
      }

      case OrderLogType.DeliveryStateNone:
      case OrderLogType.DeliveryToTASystem:
      case OrderLogType.DeliveryAdded:
      case OrderLogType.DeliveryCancelled:
        return getOrderDeliveryContent(log.fvalue || Number(log.strvalue))
    }

    return log.strvalue
  }

  const getTitleContent = (log: ArrayElement<Order['orderlogitems']>) => {
    return (
      <ListItemText
        secondary={
          log.datelog
            ? formatRelativeDate(new Date(log.datelog), {
                showSeconds: true,
                showTime: true,
              })
            : ''
        }
      >
        {getLogTitle(log.orderlogtypeid)}
      </ListItemText>
    )
  }

  return (
    <List>
      {logs.map((log, i) => {
        const titleContent = getTitleContent(log)
        const detailsContent = getDetailsContent(log)

        return (
          <Fragment key={i}>
            <ListItemTimeline
              key={i}
              divider={i !== logs.length - 1}
              data-testid={`history-item-${i}`}
            >
              <ListItemIconTimeline
                icon={getLogIcon(log.orderlogtypeid)}
                first={i === 0}
                last={i === logs.length - 1}
              />
              {!detailsContent ? (
                titleContent
              ) : (
                <Accordion
                  sx={{
                    width: '100%',
                    my: 0,
                  }}
                >
                  <AccordionSummary
                    sx={{
                      px: 0,
                      width: '100%',
                      '.MuiAccordionSummary-content': {
                        my: 0,
                      },
                    }}
                    aria-controls={`history-${i}`}
                  >
                    {titleContent}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      secondary={detailsContent}
                      secondaryTypographyProps={{
                        sx: { whiteSpace: 'pre-line' },
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </ListItemTimeline>
          </Fragment>
        )
      })}
    </List>
  )
}
