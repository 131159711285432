import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemProductImage } from '../../../components/list-item-product-image'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { ServiceOrder, ServiceOrderQuery } from '../../../generated/graphql'

type Props = {
  isLoading?: boolean
  product?: ServiceOrderQuery['serviceOrder']['product']
  serviceOrder?: ServiceOrder
}

export const Product = ({ serviceOrder, isLoading }: Props) => {
  const { t } = useTranslation(['service_orders'])
  if (!serviceOrder) return

  const { product, id } = serviceOrder

  return (
    <Accordion className="MuiAccordionRoot" defaultExpanded>
      <AccordionSummary aria-controls="product">
        <SectionHeader sx={{ p: 0 }}>{t('product')}</SectionHeader>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        {isLoading && (
          <ListItemSkeleton
            isLoading={isLoading}
            secondaryAction="-"
            childrenSkeleton
          />
        )}
        <ListItem data-testid={`product-${id}`}>
          <ListItemProductImage url={product?.images?.[0]} />

          <ListItemText
            primary={
              <Tooltip title={product?.name} placement="top-start">
                <span>{product?.name}</span>
              </Tooltip>
            }
            primaryTypographyProps={{
              sx: {
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              },
            }}
            data-testid={`productcaption-${id}`}
            secondaryTypographyProps={{
              whiteSpace: 'nowrap',
            }}
            secondary={
              <Tooltip
                placement="bottom-start"
                title={
                  <>
                    {product?.sku}
                    <br />
                    {product?.serial}
                  </>
                }
              >
                <span>
                  {product?.sku}
                  <br />
                  {product?.serial}
                </span>
              </Tooltip>
            }
          />
        </ListItem>
      </AccordionDetails>
    </Accordion>
  )
}
