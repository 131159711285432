import { Button, Chip, Paper } from '@mui/material'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import { useTracking } from '../../../hooks/tracking'
import { Details } from './details'
import { Sales } from './sales'
import {
  GetInfluencerCodeDocument,
  GetInfluencerCodeQuery,
} from '../../../generated/graphql'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { RootRoute } from '../..'
import { useAbsolutePath } from '../../../hooks/absolute-path'

type Props = {
  open: boolean
  onClose: () => void
  selectedId: string | undefined
}

export type InfluencerCode = GetInfluencerCodeQuery['influencerCode']

export const InfluencerCodesViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'influencer_codes'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const generatePath = useAbsolutePath()

  const { data, loading: fetchLoading } = useQuery(GetInfluencerCodeDocument, {
    variables: { code: String(props.selectedId) },
    skip: !props.selectedId,
    fetchPolicy: 'cache-and-network',
  })

  const isLoading = fetchLoading
  const influencerCode = data?.influencerCode

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        title={influencerCode?.name || ''}
        isLoading={isLoading}
        tags={
          <>
            {influencerCode?.active ? (
              <Chip
                size="small"
                label={t('shared:label.active')}
                color="green"
              />
            ) : (
              <Chip
                size="small"
                label={t('shared:label.inactive')}
                color="red"
              />
            )}
            {influencerCode?.code && (
              <Chip size="small" label={influencerCode.code} color="black" />
            )}
          </>
        }
        section={t('influencer_codes:page_title')}
        onClose={props.onClose}
        hideActionsSkeleton
        onBack={() => {
          trackButtonClick({ name: 'influencer-code-panel-go-back' })
        }}
        actions={
          <>
            {props.selectedId && (
              <Button
                component={Link}
                to={generatePath(RootRoute.InfluencerCode, {
                  id: props.selectedId,
                })}
                color="secondary"
                size="small"
                data-testid="edit-product-button"
                onClick={() => {
                  trackButtonClick({
                    name: 'product-panel-edit-product',
                    influencerCodeId: props.selectedId,
                  })
                }}
              >
                {t('shared:action.edit')}
              </Button>
            )}
          </>
        }
      />
      <Paper elevation={0}>
        <Details influencerCode={influencerCode} isLoading={isLoading} />
        <Sales influencerCode={influencerCode} isLoading={isLoading} />
      </Paper>
    </ViewPanel>
  )
}
