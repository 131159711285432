import { Maybe } from 'graphql/jsutils/Maybe'

type User = {
  namefirst?: Maybe<string>
  namelast?: Maybe<string>
  email?: Maybe<string>
}

export const formatUserDisplayName = (
  user: Maybe<User> | undefined,
): string => {
  const firstName = user?.namefirst
  const lastName = user?.namelast
  const email = user?.email || ''
  const userName = [firstName, lastName].filter(Boolean)

  return userName?.length ? userName.join(' ') : email
}
