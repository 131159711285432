import { DashboardIcon } from '@sitoo/mui-components'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { SettingsList } from '../../../components/settings-list'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useQuery } from '@apollo/client'
import { useTracking } from '../../../hooks/tracking'
import { useMemo } from 'react'
import { DashboardsDocument, DashboardsQuery } from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { useTranslation } from 'react-i18next'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { Tab, Tabs } from '@mui/material'
import { useTabs } from '../../../hooks/tab'
import { usePageTitle } from '../../../hooks/title'

type Row = ArrayElement<DashboardsQuery['dashboards']>

enum TabLabel {
  List,
  CustomMetrics,
  Settings,
}

export const SettingsDashboardsPage = () => {
  const name = 'dashboards'
  const apiRef = useGridApiRef()
  const generatePath = useAbsolutePath()
  const { trackButtonClick } = useTracking()
  const [activeTab, setActiveTab] = useTabs(TabLabel, TabLabel.List)
  const { t } = useTranslation(['shared', 'pos_settings'])
  usePageTitle(t('shared:menu.dashboards'))
  const { loading: fetchLoading, data } = useQuery(DashboardsDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'name',
        minWidth: 240,
        headerName: t('pos_settings:dashboards.name'),
      },
    ],
    [t],
  )

  // const onAdd = () => {
  //   trackButtonClick({ name: `${name}-add` })
  // }

  const onEdit = (id: string) => {
    trackButtonClick({ name: `${name}-edit` })
    window.open(generatePath(RootRoute.SettingsPosDashboard, { id }), '_blank')
  }

  const isLoading = fetchLoading

  return (
    <>
      <PageHeader
        isFlexible
        title={t('shared:menu.dashboards')}
        // rightColumn={
        //   <Button data-testid="add-dashboard-button" onClick={onAdd}>
        //     {t('shared:action.add')}
        //   </Button>
        // }
      >
        <Tabs
          value={activeTab}
          onChange={(_e, value: TabLabel) => {
            trackButtonClick({ name, value })
            setActiveTab(value)
          }}
          variant="standard"
          sx={{
            '.MuiButtonBase-root': {
              borderColor: 'transparent',
            },
          }}
        >
          <Tab
            label={t('pos_settings:dashboards.list_tab')}
            value={TabLabel.List}
            data-testid="tab-list"
          />
          {/* <Tab */}
          {/*   label={t('pos_settings:dashboards.custom_metrics_tab')} */}
          {/*   value={TabLabel.CustomMetrics} */}
          {/*   data-testid="tab-custom-metrics" */}
          {/* /> */}
          {/* <Tab */}
          {/*   label={t('pos_settings:dashboards.settings_tab')} */}
          {/*   value={TabLabel.Settings} */}
          {/*   data-testid="tab-settings" */}
          {/* /> */}
        </Tabs>
      </PageHeader>

      {activeTab === TabLabel.List && (
        <SettingsList
          name={name}
          apiRef={apiRef}
          rows={data?.dashboards}
          columns={columns}
          loading={isLoading}
          onRowClick={({ id }) => onEdit(String(id))}
          noRowsOverlay={{
            icon: <DashboardIcon />,
            title: t('pos_settings:dashboards.empty_title'),
            description: t('pos_settings:dashboards.empty_description'),
          }}
        />
      )}
    </>
  )
}
