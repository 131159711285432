import { SalesTax, SalesTaxGroupQuery } from '../../../generated/graphql'

export type BaseSalesTaxGroupContext = SalesTaxGroupQuery['salesTaxGroup']

export const EMPTY_SALES_TAX_ID = -1
export const EMPTY_SALES_TAX: SalesTax = {
  name: '',
  id: EMPTY_SALES_TAX_ID,
  decimalvaluedefault: '0',
}
