import { generatePath, Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAuthorization } from '../../hooks/authorization'
import { RootRoute } from '../../pages'
import { DEFAULT_SITE_ID } from '../../utils/constants'
import { createRedirectParam, getRedirectParam } from '../../utils/redirect'
import { useMe } from '../../hooks/me'
import { useRefreshSession } from '../../hooks/refresh-session'

type Props = {
  allowAnonymous?: boolean
}

export const AuthProvider = ({ allowAnonymous = false }: Props) => {
  const { me } = useMe()
  const { loading: loadingAuthorization, isLoggedIn } = useAuthorization()
  const { search, pathname } = useLocation()

  useRefreshSession({ skip: allowAnonymous })

  if (loadingAuthorization) {
    return null
  }

  if (!allowAnonymous && !isLoggedIn) {
    return (
      <Navigate
        to={{
          pathname: RootRoute.Logout,
          search: createRedirectParam(),
        }}
      />
    )
  }

  const anonymousOnly = [RootRoute.Login, RootRoute.ResetPassword]

  if (allowAnonymous && isLoggedIn && anonymousOnly.includes(pathname)) {
    const currentSiteId = me?.siteId || DEFAULT_SITE_ID

    const redirectPage =
      getRedirectParam(search) ||
      generatePath(RootRoute.Home, { siteId: currentSiteId })

    const url = new URL(location.origin + redirectPage)

    return <Navigate to={{ pathname: url.pathname, search: url.search }} />
  }

  return <Outlet />
}
