import { OrderDetailsFieldsFragment } from '../../../generated/graphql'
import { DeliveryStateTag } from '../orders-list/delivery-state'
import { PaymentStateTag } from '../orders-list/payment-state'
import { OrderAdditionalData } from '../util'
import { FullReturnTag } from './full-return-tag'
import { ReturnTag } from './return-tag'
import { TaxFreeTag } from './tax-free-tag'

type Props = {
  order: OrderDetailsFieldsFragment | undefined
}

export const HeaderTags = (props: Props) => {
  const { order } = props
  const additionalData = order?.additionaldata as OrderAdditionalData
  const isReturn = order?.orderitems.some((x) => x.quantity < 0) || false
  return (
    <>
      <ReturnTag data-testid="return-tag" isReturn={isReturn} />
      <PaymentStateTag
        data-testid="payment-state-tag"
        stateId={order?.paymentstateid}
      />
      <DeliveryStateTag
        data-testid="delivery-state-tag"
        state={order?.orderDeliveryState}
      />
      <FullReturnTag
        isFullReturnOnly={Boolean(additionalData?.['pos-full-return-only'])}
      />
      <TaxFreeTag taxFreeId={additionalData?.['tax-free-transaction-id']} />
    </>
  )
}
