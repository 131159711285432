import { styled } from '@mui/material'
import { NAVBAR_HEIGHT } from '../../utils/constants'

export const NavContainer = styled('nav')`
  background-color: ${(props) => props.theme.palette.background.paper};
  box-shadow: inset 0 -1px 0 0 #e0e0e1;
  z-index: ${(props) => props.theme.zIndex.appBar};
  padding: ${(props) => props.theme.spacing(0, 3)};
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  min-height: ${(props) => props.theme.spacing(7)};
  display: flex;
  position: fixed;
  align-items: center;
  display: grid;
  height: ${NAVBAR_HEIGHT}px;
  width: 100vw;
`
