import { useQuery } from '@apollo/client'
import { Autocomplete, ListItem, TextField } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import {
  GetAllCashRegistersDocument,
  GetStoresDocument,
} from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { MAX_ITEMS_ORDER_API } from '.'

type QueryParamsState = {
  registerId?: string[] | string
  storeId?: string[] | string
}

type Props = {
  storeIdType: 'id' | 'externalid'
}

export const StoreRegisterFilter = (props: Props) => {
  const { t } = useTranslation(['filter', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackInputFocus, trackInputBlur } = useTracking()
  const filterKey = 'registerId'
  const storeFilterKey = 'storeId'
  const { data: cashRegisterData, loading: isLoadingCashRegister } = useQuery(
    GetAllCashRegistersDocument,
  )
  const { data: storesData, loading: isLoadingStores } =
    useQuery(GetStoresDocument)

  const isLoading = useMemo(
    () => isLoadingCashRegister || isLoadingStores,
    [isLoadingCashRegister, isLoadingStores],
  )

  const allRegisters = useMemo(
    () =>
      [...(cashRegisterData?.allCashRegisters || [])].sort(
        (a, b) => a.registerkey?.localeCompare(b.registerkey || '') || 0,
      ),
    [cashRegisterData?.allCashRegisters],
  )

  const stores = useMemo(
    () =>
      (storesData?.stores ? [...storesData.stores] : [])
        .filter((x) => x[props.storeIdType])
        .sort((a, b) => a.name.localeCompare(b.name)),
    [props.storeIdType, storesData?.stores],
  )

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const selectedRegisterIds = useMemo(
    () =>
      typeof queryParams.registerId === 'string'
        ? [queryParams.registerId]
        : queryParams.registerId || [],
    [queryParams.registerId],
  )

  const selectedStoreIds = useMemo(
    () =>
      typeof queryParams.storeId === 'string'
        ? [queryParams.storeId]
        : queryParams.storeId || [],
    [queryParams.storeId],
  )

  useEffect(() => {
    registerFilter([
      {
        key: filterKey,
        isReady: !isLoading,
      },
      {
        key: storeFilterKey,
        isReady: !isLoading,
      },
    ])
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key) {
        setQueryParams({ [filterKey]: undefined, [storeFilterKey]: undefined })
      } else if (key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      } else if (key === storeFilterKey) {
        setQueryParams({ [storeFilterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const selectedRegisters = allRegisters.filter((r) =>
      selectedRegisterIds.includes(r.registerid),
    )

    if (selectedRegisters.length) {
      setFilter(filterKey, {
        label: t('filter:register_filter.label'),
        labelValues: selectedRegisters
          .map((register) => register.registerkey || register.registerid)
          .filter(Boolean),
        value: selectedRegisterIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [
    removeFilter,
    setFilter,
    selectedRegisterIds,
    allRegisters,
    t,
    selectedStoreIds,
  ])

  useEffect(() => {
    const selectedStores = stores.filter((store) =>
      selectedStoreIds.includes(String(store.id)),
    )

    if (selectedStores.length) {
      setFilter(storeFilterKey, {
        label: t('filter:store_filter.label'),
        labelValues: selectedStores.map((store) => store.name),
        value: selectedStoreIds,
      })
    } else {
      removeFilter(storeFilterKey)
    }
  }, [props.storeIdType, removeFilter, setFilter, selectedStoreIds, stores, t])

  return (
    <>
      <ListItem>
        <Autocomplete
          multiple
          limitTags={3}
          fullWidth
          autoHighlight
          options={stores}
          getOptionLabel={(store) => store.name}
          getOptionDisabled={(option) =>
            selectedStoreIds.length >= MAX_ITEMS_ORDER_API &&
            !selectedStoreIds.includes(String(option.id))
          }
          disabled={isLoading}
          onChange={(_event, value) => {
            setQueryParams(
              {
                [storeFilterKey]: value
                  .map((store) => String(store.id))
                  .slice(0, MAX_ITEMS_ORDER_API),
                [filterKey]: selectedRegisterIds,
              },
              hasApply !== true,
            )
          }}
          value={stores.filter((store) =>
            selectedStoreIds.includes(String(store.id)),
          )}
          data-testid="store-field"
          renderInput={(params) => (
            <TextField
              label={t('filter:store_filter.label')}
              type="text"
              {...params}
              onFocus={trackInputFocus({ name: `store-${filterKey}` })}
              onBlur={trackInputBlur({ name: `store-${filterKey}` })}
              inputProps={{
                ...params.inputProps,
                ['data-testid']: 'store-input',
              }}
            />
          )}
        />
      </ListItem>

      <ListItem>
        <Autocomplete
          multiple
          limitTags={3}
          fullWidth
          autoHighlight
          options={allRegisters}
          getOptionLabel={(register) => register.registerkey || ''}
          getOptionDisabled={(option) =>
            selectedRegisterIds.length >= MAX_ITEMS_ORDER_API &&
            !selectedRegisterIds.includes(option.registerid)
          }
          disabled={isLoading}
          onChange={(_event, value) => {
            setQueryParams(
              {
                [storeFilterKey]: selectedStoreIds,
                [filterKey]: value
                  .map((register) => register.registerid)
                  .slice(0, MAX_ITEMS_ORDER_API),
              },
              hasApply !== true,
            )
          }}
          value={allRegisters.filter((register) =>
            selectedRegisterIds.includes(register.registerid),
          )}
          data-testid="register-field"
          renderInput={(params) => (
            <TextField
              label={t('filter:register_filter.label')}
              type="text"
              {...params}
              onFocus={trackInputFocus({ name: `register-${filterKey}` })}
              onBlur={trackInputBlur({ name: `register-${filterKey}` })}
              inputProps={{
                ...params.inputProps,
                ['data-testid']: 'register-input',
              }}
            />
          )}
        />
      </ListItem>
    </>
  )
}
