import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { DESTINATION_FILTER_KEY, ORIGIN_FILTER_KEY } from '../shipments-list'
import { StateFilter } from '../shipments-list/state-filter'
import { WarehouseFilter } from '../shipments-list/warehouse-filter'

type ShipmentsFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const ShipmentsFilterViewPanel = (
  props: ShipmentsFilterViewPanelProps,
) => {
  const { t } = useTranslation(['shipments', 'shared'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('shipments:page_title')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <StateFilter />
            </ListItem>
            <ListItem>
              <WarehouseFilter
                filterKey={ORIGIN_FILTER_KEY}
                label={t('shared:label.all')}
                title={t('shipments:origin')}
              />
            </ListItem>
            <ListItem>
              <WarehouseFilter
                filterKey={DESTINATION_FILTER_KEY}
                label={t('shared:label.all')}
                title={t('shipments:destination')}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
