import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { DeletePurchasePriceListItemsDocument } from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'

export type DeletePurchasePriceDialogProps = {
  open: boolean
  priceListId: string
  productSkus: string[]
  dataTestid?: string
  onClose?(): void
  onSuccess?(priceListId: string, skus: string[]): void
  onError?(priceListId: string, skus: string[]): void
}

export const DeletePurchasePriceDialog = (
  props: DeletePurchasePriceDialogProps,
) => {
  const { t } = useTranslation(['purchase_prices'])
  const dialogName = 'delete-purchase-price'
  const [deletePurchasePriceListItems, { loading: isLoading }] = useMutation(
    DeletePurchasePriceListItemsDocument,
  )
  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const deletePrices = async () => {
    trackButtonClick({ name: `${dialogName}-dialog-delete` })

    try {
      await deletePurchasePriceListItems({
        variables: {
          priceListId: props.priceListId,
          skus: props.productSkus,
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })
      props.onSuccess?.(props.priceListId, props.productSkus)
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      props.onError?.(props.priceListId, props.productSkus)
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={deletePrices}
      title={t('purchase_prices:delete_purchase_pricelist_item_dialog.title', {
        count: props.productSkus.length,
      })}
      text={t(
        'purchase_prices:delete_purchase_pricelist_item_dialog.description',
      )}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
