import { Menu, MenuItem } from '@mui/material'
import { ArchiveShipment } from '../shipments-list/bulk-actions/archive-shipment'

type Props = {
  anchorEl: Element | null
  isOpen: boolean
  onClose?(): void
  selectedIds: number[]
}

export const ShipmentContextMenu = (props: Props) => {
  return (
    <Menu
      id="shipment-list-bulk-actionsmenu"
      anchorEl={props.anchorEl}
      open={props.isOpen}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      MenuListProps={{
        'aria-labelledby': 'shipment-list-bulk-actionsmenu-button',
      }}
    >
      <MenuItem sx={{ p: 0 }}>
        <ArchiveShipment />
      </MenuItem>
    </Menu>
  )
}
