import { Menu, Button, MenuItem } from '@mui/material'
import { PlusIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AllCustomAttributesQuery } from '../../../../generated/graphql'
import { CustomAttributeValue } from '.'
import useResizeObserver from 'use-resize-observer'

type AttributesMenuProps = {
  customAttributes: AllCustomAttributesQuery['allCustomAttributes']
  onSelectCustomAttribute: (
    attributeId: string,
    value: CustomAttributeValue,
  ) => void
  disabled?: boolean
  ['data-testid']?: string
}

export const AttributesMenu = (props: AttributesMenuProps) => {
  const { t } = useTranslation(['products', 'shared'])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const { ref: buttonRef, width } = useResizeObserver<HTMLButtonElement>({
    box: 'border-box',
  })

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        startIcon={<PlusIcon fontSize="medium" />}
        color="tertiary"
        fullWidth
        data-testid="attribute-menu-button"
        aria-controls={isOpen ? 'attribute-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        ref={buttonRef}
        disabled={props.disabled}
      >
        {t('products:product_form.add_attributes')}
      </Button>
      <Menu
        id="attribute-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'attribute-menu-button',
        }}
        sx={{
          '.MuiPaper-root': {
            maxWidth: 'initial',
            minWidth: width,
          },
        }}
      >
        {props.customAttributes.map((c) => (
          <MenuItem
            key={c.id}
            data-value={c.id}
            onClick={() => {
              props.onSelectCustomAttribute(
                c.id,
                c.enums && c.enums.length > 0 ? (c.enums[0] as string) : null,
              )
              closeMenu()
            }}
          >
            {c.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
