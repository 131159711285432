import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

export enum ExchangeFilterEnum {
  All = 'All',
  Include = 'Include',
  Exclude = 'Exclude',
}

type QueryParamsState = {
  exchange?: string
}

export const ExchangeFilter = () => {
  const { t } = useTranslation(['orders', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'exchange'
  const exchanges = useMemo(
    () =>
      Object.values(ExchangeFilterEnum)
        .map((x) => ({
          value: x,
          label: t(`orders:exchange_filter_enum.${x}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedExchange = exchanges.find(
    ({ value }) => value === queryParams.exchange,
  )
    ? queryParams.exchange
    : 'All'

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const exchange = exchanges?.find(({ value }) => value === selectedExchange)

    if (exchange && exchange.value !== ExchangeFilterEnum.All) {
      setFilter(filterKey, {
        label: t('orders:exchange_filter_tag', { exchange: exchange.label }),
        value: exchange.value,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, selectedExchange, exchanges, t])

  return (
    <>
      <TextField
        data-testid="exchange-field"
        onChange={(event) =>
          setQueryParams(
            {
              [filterKey]: event.target.value || undefined,
            },
            hasApply !== true,
          )
        }
        value={selectedExchange || 'All'}
        SelectProps={{
          displayEmpty: true,
        }}
        select // tell TextField to render select
        label={t('orders:exchange')}
      >
        {exchanges?.map((exchange) => (
          <MenuItem
            data-testid="exchange-option"
            key={exchange.value}
            value={exchange.value || ''}
            divider
            onClick={trackButtonClickEvent({
              name: 'order-search-filter-select-exchange',
              exchange: exchange.value,
            })}
          >
            <Typography variant="body02">{exchange.label}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}
