import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseUserContext } from '../shared'
import { TimeZonesInput } from '../../../inputs/time-zones-input'

export const TimeZoneField = () => {
  const { t } = useTranslation(['users'])
  const { control } = useFormContext<BaseUserContext>()

  return (
    <FormFieldset label={t('user_form.settings_fieldset')}>
      <TimeZonesInput control={control} name="timezone" />
    </FormFieldset>
  )
}
