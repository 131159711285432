import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { AvatarName, Link, LogOutIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMe } from '../../hooks/me'
import { useTracking } from '../../hooks/tracking'
import { RootRoute } from '../../pages'
import { useAbsolutePath } from '../../hooks/absolute-path'

export const NavbarUserMenu = () => {
  const { t } = useTranslation('shared')
  const { trackButtonClickEvent } = useTracking()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { displayName, me } = useMe()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    navigate(RootRoute.Logout)
  }

  return (
    <Box>
      <IconButton
        id="user-menu-button"
        aria-controls={isOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          padding: 0,
          margin: 0,
          fontFamily: 'inherit',
        }}
      >
        <AvatarName
          variant="circular"
          sx={{ cursor: 'pointer', width: '32px', height: '32px' }}
        >
          {displayName}
        </AvatarName>
      </IconButton>

      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        onClick={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
      >
        <ListItem sx={{ outline: 0 }} divider>
          <ListItemAvatar>
            <AvatarName variant="circular">{displayName}</AvatarName>
          </ListItemAvatar>

          <ListItemText
            secondary={
              <Stack component={'span'}>
                {t(`shared:roles.${me?.role?.toLocaleLowerCase() || ''}`)}
                <Link
                  to={generatePath(RootRoute.SettingsProfile)}
                  component={RouterLink}
                  sx={{ ':active': { outline: 'none' } }}
                >
                  {t('shared:action.edit_profile')}
                </Link>
              </Stack>
            }
          >
            {displayName}
          </ListItemText>
        </ListItem>

        <MenuItem
          onClick={trackButtonClickEvent({ name: 'user-menu-logout' }, logout)}
        >
          <ListItemIcon>
            <LogOutIcon />
          </ListItemIcon>
          <ListItemText>{t('label.sign_out')}</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  )
}
