import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ListItem,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { ServiceOrderQuery } from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'

type Props = {
  isLoading?: boolean
  services?: ServiceOrderQuery['serviceOrder']['product']['services']
  totalBeforeShare?: number | null
  totalAfterShare?: number | null
  currency?: string
}

export const Services = ({
  services,
  isLoading,
  totalAfterShare,
  totalBeforeShare,
  currency,
}: Props) => {
  const { t } = useTranslation(['service_orders'])
  const { formatCurrency } = useMoney()
  return (
    <Accordion className="MuiAccordionRoot" defaultExpanded>
      <AccordionSummary aria-controls="services">
        <SectionHeader sx={{ p: 0 }}>{t('services')}</SectionHeader>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        {isLoading && (
          <ListItemSkeleton
            isLoading={isLoading}
            secondaryAction="-"
            childrenSkeleton
          />
        )}
        {services?.map((service, index) => (
          <ListItem
            data-testid={`service-${index}`}
            key={`${service.sku}-${index}`}
            divider
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              <Box sx={{ width: '100%', display: 'flex' }}>
                <ListItemText
                  primary={service?.name}
                  secondaryTypographyProps={{
                    component: 'div',
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  }}
                  secondary={
                    <>
                      {t('sku', { sku: service?.sku })}
                      <br />
                      {t('view_panel.customer_share', {
                        cost_share: service?.cost_share,
                      })}
                      {service?.note && (
                        <>
                          <br />
                          {t('view_panel.service_note', {
                            note: service?.note,
                          })}
                        </>
                      )}
                    </>
                  }
                />
                <ListItemText
                  sx={{ flex: 'none' }}
                  primary={formatCurrency(Number(service.unit_price), {
                    currency,
                  })}
                  secondary={t('view_panel.quantity', {
                    qty: service.quantity,
                  })}
                />
              </Box>
            </Box>
          </ListItem>
        ))}

        <ListItemSkeleton
          secondaryAction={
            <ListItemText
              sx={{
                textAlign: 'end',
              }}
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                },
              }}
              secondary={formatCurrency(Number(totalBeforeShare), { currency })}
            >
              {formatCurrency(Number(totalAfterShare), { currency })}
            </ListItemText>
          }
          isLoading={isLoading}
          childrenSkeleton
          data-testid="service-order-total"
        >
          <ListItemText
            sx={{
              '.MuiListItemText-secondary': {
                color: (theme) => theme.palette.gray70,
              },
            }}
            secondaryTypographyProps={{
              sx: {
                whiteSpace: 'pre-line',
              },
            }}
            secondary={t('service_orders:view_panel.original_price')}
          >
            {t('total')}
          </ListItemText>
        </ListItemSkeleton>
      </AccordionDetails>
    </Accordion>
  )
}
