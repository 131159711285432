import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { Button, Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RootRoute } from '..'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { UserPermissionsByRoleDocument } from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { UserViewPanel } from './user-view-panel'
import { UsersContextMenu } from './users-context-menu'
import { UsersFilterViewPanel } from './users-filter-view-panel'
import { UsersList } from './users-list'

type ViewPanelState = {
  isOpen: boolean
  userId: string | undefined
}

export const userViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  userId: undefined,
})

const userFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const UsersPage = () => {
  const { t } = useTranslation(['shared', 'users'])

  const { data: permissionData } = useQuery(UserPermissionsByRoleDocument, {
    variables: { targetUserRole: null },
  })

  const addUsers = !!permissionData?.userPermissionsByRole.addUser

  usePageTitle(t('users:page_title'))
  const generatePath = useAbsolutePath()

  const viewPanelState = useReactiveVar(userViewPanelVar)
  const filterViewPanelState = useReactiveVar(userFilterViewPanelVar)
  const { trackButtonClick, trackButtonClickEvent } = useTracking()

  const openViewPanel = (userId: string) => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({ name: 'users-view-panel-open', userId })
    userViewPanelVar({ isOpen: true, userId })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'users-view-panel-close' })
    userViewPanelVar({ isOpen: false, userId: undefined })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'users-filter-view-panel-close' })
    userFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'users-filter-view-panel-open' })
    userFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader
            title={t('users:page_title')}
            isFlexible
            rightColumn={
              <>
                <UsersContextMenu />

                {addUsers && (
                  <Button
                    component={Link}
                    to={generatePath(RootRoute.UserNew)}
                    onClick={trackButtonClickEvent({
                      name: 'users-add-user',
                    })}
                    data-testid="add-user-button"
                  >
                    {t('users:add_user')}
                  </Button>
                )}
              </>
            }
          />
          <Container maxWidth={false}>
            <UsersList
              onDetailUser={openViewPanel}
              currentDetailedUserId={viewPanelState.userId}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>
        <Grid item>
          <UserViewPanel
            open={viewPanelState.isOpen}
            userId={viewPanelState.userId}
            onClose={closeViewPanel}
          />
          <UsersFilterViewPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
