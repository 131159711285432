import { ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalPrintout' }
  >
}

export const PrintoutDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props

  return (
    <>
      <ListItemSkeleton
        hide={!journal?.type}
        secondaryAction={
          <ListItemText>
            {journal?.type
              ? t(`journals:printout_type_enum.${journal?.type}`)
              : '-'}
          </ListItemText>
        }
        childrenSkeleton
        divider
        data-testid="journal-type"
      >
        <ListItemText>{t('journals:type')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.receiptid}
        childrenSkeleton
        divider
        data-testid="journal-receiptid"
      >
        <ListItemText secondary={journal?.receiptid}>
          {t('journals:receipt_id')}
        </ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.orderid}
        secondaryAction={<ListItemText>{journal?.orderid}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-orderid"
      >
        <ListItemText>{t('journals:order_id')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.zreportid}
        secondaryAction={<ListItemText>{journal?.zreportid}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-zreportid"
      >
        <ListItemText>{t('journals:zreport_id')}</ListItemText>
      </ListItemSkeleton>
    </>
  )
}
