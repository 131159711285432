import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  onClose(): void
  onSubmit(): void
}
export const UserTagDialog = ({ open, onClose, onSubmit }: Props) => {
  const { register, getValues } = useFormContext()
  const userTagId = getValues('id') as string | null
  const { t } = useTranslation(['shared', 'users'])
  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>
        {userTagId
          ? t('users:user_tags.edit_user_tag', { count: 1 })
          : t('users:user_tags.add_user_tag')}
      </DialogTitle>

      <DialogContent>
        <TextField
          {...register('label')}
          placeholder={t('users:user_tags.add_tag_placeholder')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-user-tag"
        >
          {t('shared:action.cancel')}
        </Button>

        <Button
          color="primary"
          onClick={onSubmit}
          data-testid="dialog-add-product-group"
        >
          {t('shared:action.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
