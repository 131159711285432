import {
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridColumnMenuPinningItem,
  GridColumnMenuHideItem,
} from '@mui/x-data-grid-pro'
import { forwardRef } from 'react'

export const CustomColumnMenu = forwardRef<
  HTMLUListElement,
  GridColumnMenuProps
>(function GridColumnMenu(props, ref) {
  const { hideMenu, colDef } = props

  return (
    <GridColumnMenuContainer ref={ref} {...props}>
      <GridColumnMenuHideItem onClick={hideMenu} colDef={colDef} />

      <GridColumnMenuPinningItem ref={ref} onClick={hideMenu} colDef={colDef} />
    </GridColumnMenuContainer>
  )
})
