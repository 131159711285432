import { ShipmentV2Input } from '../../generated/graphql'
import { stripNullValues } from '../../utils/strip-null-values'
import {
  BaseShipmentFormContext,
  GenericShipmentAddressFields,
  GenericShipmentContactFields,
} from './shared'

type FormatSubject = 'sender' | 'receiver'

export const formatFormAddressDataToPayload = (
  subject: FormatSubject,
  data?: GenericShipmentAddressFields,
) => {
  return stripNullValues(
    {
      [subject + '_address']: data?.address || null,
      [subject + '_address2']: data?.address2 || null,
      [subject + '_city']: data?.city || null,
      [subject + '_countryid']: data?.countryid || null,
      [subject + '_name']: data?.name || null,
      [subject + '_state']: data?.state || null,
      [subject + '_warehouseid']: data?.warehouseid || null,
      [subject + '_zip']: data?.zip || null,
    },
    {
      deleteEmptyProperties: true,
    },
  )
}

export const formatFormContactDataToPayload = (
  subject: FormatSubject,
  data?: GenericShipmentContactFields,
) => {
  return stripNullValues(
    {
      [subject + '_referenceemail']: data?.referenceemail || null,
      [subject + '_referencename']: data?.referencename || null,
      [subject + '_referencemobile']: data?.referencemobile || null,
      [subject + '_instructions']: data?.instructions || null,
    },
    {
      deleteEmptyProperties: true,
    },
  )
}

/**
 *
 * @param data Shipment data in the format of form data
 * @returns The same data in the format of a payload ready to be sent in a request
 */
export const formatFormDataToShipment = (
  data: BaseShipmentFormContext & ShipmentV2Input,
): ShipmentV2Input => {
  const {
    datenew, // Extracting to not send in payload

    senderAddress,
    senderContact,
    receiverAddress,
    receiverContact,
    carrier,
    ...fields
  } = data

  const formattedPayload: ShipmentV2Input = {
    ...fields,
    ...carrier,
    ...formatFormContactDataToPayload('receiver', receiverContact),
    ...formatFormContactDataToPayload('sender', senderContact),
    ...formatFormAddressDataToPayload('receiver', receiverAddress),
    ...formatFormAddressDataToPayload('sender', senderAddress),
  }

  return formattedPayload
}
