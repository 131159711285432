import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
} from '@mui/material'
import { useMoney } from '../../../hooks/money'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { InfluencerCode } from '.'

type Props = {
  influencerCode?: InfluencerCode
  isLoading: boolean
}

export const Sales = (props: Props) => {
  const { influencerCode, isLoading } = props
  const { t } = useTranslation(['shared', 'influencer_codes'])

  const { formatDate } = useLocalizedDate()
  const { formatCurrency } = useMoney()

  const influencerDiscounts = influencerCode?.moneyinfluencerdiscount || 0
  const influencerTotal = influencerCode?.moneyinfluencertotal || 0
  const totalSales = influencerCode?.moneyordertotal || 0
  const additionalSales = Number(totalSales) - Number(influencerTotal)

  return (
    <>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="details">
          <SectionHeader sx={{ p: 0 }}>
            {t('influencer_codes:sales')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List>
            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                <ListItemText>
                  {influencerCode?.datelastused
                    ? formatDate(influencerCode?.datelastused)
                    : '-'}
                </ListItemText>
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-last-used"
            >
              <ListItemText>
                {t('influencer_codes:date_last_used')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                <ListItemText>
                  {formatCurrency(influencerDiscounts)}
                </ListItemText>
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-name"
            >
              <ListItemText>
                {t('influencer_codes:influencer_discounts')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                <ListItemText>{formatCurrency(influencerTotal)}</ListItemText>
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-influencer-total-sales"
            >
              <ListItemText>
                {t('influencer_codes:influencer_total_sales')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                <ListItemText>{formatCurrency(additionalSales)}</ListItemText>
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-additional_sales"
            >
              <ListItemText>
                {t('influencer_codes:additional_sales')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction={
                <ListItemText>{formatCurrency(totalSales)}</ListItemText>
              }
              childrenSkeleton
              divider
              data-testid="influencer-code-total-sales"
            >
              <ListItemText>{t('influencer_codes:total_sales')}</ListItemText>
            </ListItemSkeleton>
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
