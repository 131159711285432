import { useReactiveVar } from '@apollo/client'
import { PropsWithChildren, useEffect } from 'react'
import { globalNotificationState } from '../../cache'
import { router } from '../router/router'
import usePrevious from 'react-use/lib/usePrevious'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export const NotificationProvider = (props: PropsWithChildren) => {
  const errorState = useReactiveVar(globalNotificationState)
  const { pathname } = router.state.location
  const prevPathname = usePrevious(pathname)
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['shared'])

  useEffect(() => {
    if (errorState && pathname !== prevPathname) {
      globalNotificationState(undefined)
    }
  }, [errorState, pathname, prevPathname])

  useEffect(() => {
    if (!errorState) return

    enqueueSnackbar(errorState.message, { variant: errorState.variant })
  }, [enqueueSnackbar, errorState, t])

  return props.children
}
