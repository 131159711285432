import { useQuery } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeVarPersisted } from '../../../cache/make-var-persisted'
import { FilterProvider } from '../../../components/data-grid/context'
import { CustomOverlay } from '../../../components/data-grid/custom-overlay'
import { PageHeader } from '../../../components/page-header'
import { MeDocument } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { usePageTitle } from '../../../hooks/title'
import { StoreFilter } from './store-filter'
import { InStoreReplenishmentList } from './in-store-replenishment-list'
import { ActionButton } from './action-button'

export type QueryParamsState = {
  storeId?: number
  warehouseToId?: number
  warehouseFromId?: number
}

const prevStoreId = makeVarPersisted<number | undefined>(
  undefined,
  'apollo:inStoreReplenishment:storeId',
)

const prevwarehouseToId = makeVarPersisted<number | undefined>(
  undefined,
  'apollo:inStoreReplenishment:warehouseToId',
)

const prevwarehouseFromId = makeVarPersisted<number | undefined>(
  undefined,
  'apollo:inStoreReplenishment:warehouseFromId',
)

export const InStoreReplenishmentPage = () => {
  const { t } = useTranslation(['stock'])

  usePageTitle(t('stock:in_store_replenishment.title'))

  const { data: meData } = useQuery(MeDocument)

  const hasWarehouses = !!meData?.me.warehouseIds.length

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>({
    storeId: prevStoreId(),
    warehouseToId: prevwarehouseToId(),
    warehouseFromId: prevwarehouseFromId(),
  })

  const storeId =
    queryParams.storeId === undefined ? undefined : Number(queryParams.storeId)

  const warehouseToId =
    queryParams.warehouseToId === undefined
      ? undefined
      : Number(queryParams.warehouseToId)

  const warehouseFromId =
    queryParams.warehouseFromId === undefined
      ? undefined
      : Number(queryParams.warehouseFromId)

  useEffect(() => {
    prevStoreId(storeId)
    prevwarehouseToId(warehouseToId)
    prevwarehouseFromId(warehouseFromId)
  }, [storeId, warehouseToId, warehouseFromId])

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader
            title={t('stock:in_store_replenishment.title')}
            isFlexible
            rightColumn={
              <>
                <StoreFilter
                  storeId={storeId}
                  warehouseToId={warehouseToId}
                  warehouseFromId={warehouseFromId}
                  setQueryParams={setQueryParams}
                />

                <ActionButton
                  warehouseToId={warehouseToId}
                  warehouseFromId={warehouseFromId}
                />
              </>
            }
          />
          <Container maxWidth={false}>
            {!hasWarehouses ? (
              <CustomOverlay
                icon={<WarehouseIcon />}
                title={t('stock:empty_warehouses.title')}
                description={t('stock:empty_warehouses.description')}
                sx={{ position: 'relative' }}
              />
            ) : (
              <InStoreReplenishmentList />
            )}
          </Container>
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
