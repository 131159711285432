import { LoadingButton } from '@mui/lab'
import { Box, IconButton, List, ListItem, ListItemText } from '@mui/material'
import { EditIcon, FormFieldset, PlusIcon } from '@sitoo/mui-components'
import { useState } from 'react'
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ServiceOrderItem } from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'
import { SetServiceDialog } from '../set-service-dialog'
import { BaseServiceOrderFormContext } from '../shared'

export const ServicesField = () => {
  const { control, getValues } = useFormContext<BaseServiceOrderFormContext>()
  const { t } = useTranslation(['service_orders'])

  const { formatCurrency } = useMoney()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'product.services',
  })
  const [isServiceDialogOpen, setServiceDialogOpen] = useState(false)
  const [selectedService, setSelectedService] =
    useState<ServiceOrderItem | null>()
  const [selectedIndex, setSelectedIndex] = useState<number>(fields.length)

  const resetState = () => {
    setSelectedService(null)
    setSelectedIndex(fields.length)
  }

  const handleCloseDialog = () => {
    resetState()
    setServiceDialogOpen(false)
  }

  const onEditInfo = (
    service: FieldArrayWithId<ServiceOrderItem>,
    index: number,
  ) => {
    const { id, ...rest } = service
    setSelectedService(rest as ServiceOrderItem)
    setSelectedIndex(index)
    setServiceDialogOpen(true)
  }

  const currency = getValues('currency')

  return (
    <>
      <SetServiceDialog
        service={selectedService}
        open={isServiceDialogOpen}
        onClose={handleCloseDialog}
        onSave={(service: ServiceOrderItem) => update(selectedIndex, service)}
        onAdd={(service: ServiceOrderItem) => append(service)}
        onDelete={() => remove(selectedIndex)}
      />

      <FormFieldset
        label={t('services')}
        sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
      >
        <List>
          {fields.map((field, index) => (
            <ListItem
              divider
              key={`${field.id}`}
              data-testid={`service-order-service-row-${index}`}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                py: 1,
                px: 2,
              }}
            >
              <ListItemText
                primary={field?.name}
                secondaryTypographyProps={{
                  component: 'div',
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                }}
                secondary={
                  <>
                    {t('view_panel.sku', { sku: field?.sku })}
                    <br />
                    {t('view_panel.customer_share', {
                      cost_share: field?.cost_share,
                    })}
                    {field?.note && (
                      <>
                        <br />
                        {t('view_panel.service_note', {
                          note: field?.note,
                        })}
                      </>
                    )}
                  </>
                }
              />
              <ListItemText
                sx={{ flex: 'none' }}
                primary={formatCurrency(Number(field.unit_price), {
                  currency,
                })}
                secondary={t('view_panel.quantity', {
                  qty: field.quantity,
                })}
              />
              <Box>
                <IconButton onClick={() => onEditInfo(field, index)}>
                  <EditIcon color="primary" />
                </IconButton>
              </Box>
            </ListItem>
          ))}
        </List>
        <ListItem sx={{ p: 0, minHeight: 'auto' }}>
          <LoadingButton
            fullWidth
            color="tertiary"
            onClick={() => {
              resetState()
              setServiceDialogOpen(true)
            }}
            data-testid="service-order-add-service-button"
          >
            <PlusIcon sx={{ mr: 1 }} />
            {t('service_order_form.add_service')}
          </LoadingButton>
        </ListItem>
      </FormFieldset>
    </>
  )
}
