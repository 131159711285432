import { useQuery } from '@apollo/client'
import {
  Box,
  Divider,
  FormHelperText,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useRef } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { WarehousesInput } from '../../../inputs/warehouses-input'
import {
  GetWarehousesDocument,
  UserAvailableRolesDocument,
  UserPermissionsByIdQuery,
  UserRole,
} from '../../../generated/graphql'
import { useScrollToError } from '../../../hooks/scroll-to-error'
import { BaseUserContext } from '../shared'
import { SwitchInput } from '../../../inputs/switch-input'

type Props = {
  permissions: UserPermissionsByIdQuery['userPermissionsById'] | undefined
  loading: boolean
}

export const RolesField = ({ permissions, loading }: Props) => {
  const { t } = useTranslation(['users'])
  const { control } = useFormContext<BaseUserContext>()
  const { data: warehousesData, loading: fetchWarehousesLoading } = useQuery(
    GetWarehousesDocument,
  )

  const { data } = useQuery(UserAvailableRolesDocument)

  const availableRoles = data?.userAvailableRoles || []
  const RoleNone = 'None'

  const fallbackRoles = [
    RoleNone,
    UserRole.Admin,
    UserRole.Staff,
    UserRole.StoreManager,
    UserRole.StoreStaff,
  ]

  const roles = permissions?.changeRole ? availableRoles : fallbackRoles
  const viewAccessFields = permissions?.changeAccess !== null

  const roleRef = useRef<HTMLInputElement>(null)
  const currentRole = useWatch({ control, name: 'role' })

  useScrollToError({ ref: roleRef, name: 'role', control })

  if (permissions?.changeRole === null && permissions.changeAccess === null) {
    return null
  }

  return (
    <FormFieldset
      label={t('user_form.roles_fieldset')}
      sx={{ '.MuiBox-root': { pb: 0 } }}
    >
      <Controller
        control={control}
        name="role"
        data-testid="user-role-parent"
        render={({ field, fieldState: { error } }) => (
          <Box>
            <InputLabel>{t('role')}</InputLabel>
            <Select
              value={field.value || ''}
              data-testid="user-role-field"
              onChange={(event) => field.onChange(event.target.value)}
              displayEmpty
              disabled={!permissions?.changeRole}
              ref={roleRef}
              sx={{ mb: 2 }}
            >
              {roles.map((role) => (
                <MenuItem
                  data-testid="role-option"
                  value={role === RoleNone ? '' : role}
                  key={role}
                >
                  {t(`roles.${role}`)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error={!!error?.message}>
              {error?.message}
            </FormHelperText>
          </Box>
        )}
      />

      {!loading && viewAccessFields && (
        <>
          {currentRole !== UserRole.Admin && (
            <WarehousesInput
              warehouses={warehousesData?.allWarehouses || []}
              isLoading={fetchWarehousesLoading}
              name="allWarehouses"
              control={control}
              multiple
              label={t('user_form.warehouses_label')}
              helperText={t('user_form.warehouses_helper')}
              disabled={!permissions?.changeAccess}
              sx={{ '.MuiFormControl-root': { mb: 2 } }}
            />
          )}

          <Divider sx={{ mx: -2 }} />

          <ListItem
            data-testid="user-posallstores-field-label"
            component="div"
            sx={{ p: 0 }}
            secondaryAction={
              <SwitchInput
                name="posallstores"
                control={control}
                disabled={!permissions?.changeAccess}
                data-testid="user-posallstores-field"
              />
            }
          >
            <ListItemText>{t('user_form.access_label')}</ListItemText>
          </ListItem>

          <Divider sx={{ mx: -2 }} />

          <ListItem
            data-testid="user-posrefundcard-field-label"
            component="div"
            sx={{ p: 0 }}
            secondaryAction={
              <SwitchInput
                name="posrefundcard"
                control={control}
                disabled={!permissions?.changeAccess}
                data-testid="user-posrefundcard-field"
              />
            }
          >
            <ListItemText>{t('user_form.card_refunds_label')}</ListItemText>
          </ListItem>

          {currentRole == UserRole.Staff && (
            <>
              <Divider sx={{ mx: -2 }} />

              <ListItem
                component="div"
                sx={{ p: 0 }}
                secondaryAction={
                  <SwitchInput
                    name="staff_pos_activation"
                    control={control}
                    disabled={!permissions?.changeAccess}
                    data-testid="user-staff-pos-activation"
                  />
                }
              >
                <ListItemText>
                  {t('user_form.pos_activation_label')}
                </ListItemText>
              </ListItem>
            </>
          )}
        </>
      )}
    </FormFieldset>
  )
}
