import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { JournalDivider } from './journal-divider'
import { JournalReportItem } from '../types'
import { FormatCurrencyOptions, useMoney } from '../../../hooks/money'
import { PrintContainer } from './print-container'
import { Fragment } from 'react'

type Props = {
  journal: JournalReportItem
  registerNumber: number | undefined | null
}

export const JournalReport = (props: Props) => {
  const { t } = useTranslation(['z_reports'])
  const { journal } = props
  const registerNumber = props.registerNumber || 0
  const currency = journal.data.currencycode
  const { formatDate } = useLocalizedDate()
  const { formatCurrency } = useMoney()
  const getDate = (date: string) => formatDate(new Date(date))
  const isZReport = typeof journal.data.zreportid === 'number'
  const reportTitle = isZReport
    ? `${t('z_reports:logtype_reportz')} #${journal.data.zreportid}`
    : t('z_reports:logtype_reportx')

  const currencyOptions: FormatCurrencyOptions = {
    currency,
    forceDecimals: true,
  }

  return (
    <PrintContainer>
      {/* Header */}
      <Box sx={{ textAlign: 'center' }}>
        <Box>{journal.data.company}</Box>
        <Box>{journal.data.address}</Box>
        <Box>{journal.data.address2}</Box>
        <Box>{journal.data.address3}</Box>
        <Box>{journal.data.companyid}</Box>

        <JournalDivider variant="blank" />

        <Typography variant="heading">{reportTitle}</Typography>
        <Box>
          {t('z_reports:register_display', {
            nnn: `${registerNumber} (${journal.registerkey})`,
          })}
        </Box>
        <Box>{journal.datecreated ? getDate(journal.datecreated) : ''}</Box>
      </Box>
      <JournalDivider variant="blank" />

      {/* Total Sales */}
      <JournalDivider variant="dashed" />
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_salestotal')}</Box>
        <Box>
          {formatCurrency(journal.data.moneysalestotal, currencyOptions)}
        </Box>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_salestotal_net')}</Box>
        <Box>
          {formatCurrency(journal.data.moneysalestotalnet, currencyOptions)}
        </Box>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_salestotal_count')}</Box>
        <Box>
          {journal.data.numsales}/{journal.data.numsalesitems}
        </Box>
      </Stack>

      {/* VAT Group Sales */}
      {journal.data.vatgroupssales.length > 0 && (
        <JournalDivider variant="blank" />
      )}

      {journal.data.vatgroupssales.map((sale, index) => (
        <Stack direction="row" justifyContent="space-between" key={index}>
          <Box>
            {t('z_reports:logtype_reportitem_net', {
              sss: `${sale.vatvalue}%`,
            })}
          </Box>
          <Box>{formatCurrency(sale.moneytotalnet, currencyOptions)}</Box>
        </Stack>
      ))}

      {journal.data.vatgroupssales.map((sale, index) => (
        <Stack direction="row" justifyContent="space-between" key={index}>
          <Box>
            {t('z_reports:logtype_reportitem_vat', {
              sss: `${sale.vatvalue}%`,
            })}
          </Box>
          <Box>{formatCurrency(sale.moneytotalvat, currencyOptions)}</Box>
        </Stack>
      ))}

      {/* Product Group Sales */}
      {journal.data.productgroupssales?.map((sale, index) => (
        <Box key={index}>
          <JournalDivider variant="blank" />
          <Stack direction="row" justifyContent="space-between">
            <Box>
              {sale.name} {sale.vatvalue}%
            </Box>
            <Box>{formatCurrency(sale.moneytotal, currencyOptions)}</Box>
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Box>
              {sale.numtotal}x (
              {formatCurrency(sale.moneytotalnet, currencyOptions)})
            </Box>
          </Stack>
        </Box>
      ))}

      <JournalDivider variant="dashed" />

      {/* Not delivered */}

      {journal.data.numsalesnotdelivereditems > 0 && (
        <>
          {Number(journal.data.moneysalesnotdeliveredtotal) > 0 && (
            <Stack direction="row" justifyContent="space-between">
              <Box>
                {t('z_reports:logtype_reportitem_salesnotdeliveredtotal')}
              </Box>
              <Box>
                {formatCurrency(
                  journal.data.moneysalesnotdeliveredtotal,
                  currencyOptions,
                )}
              </Box>
            </Stack>
          )}

          {Number(journal.data.moneysalesnotdeliveredtotalnet) > 0 && (
            <Stack direction="row" justifyContent="space-between">
              <Box>
                {t('z_reports:logtype_reportitem_salesnotdeliveredtotal_net')}
              </Box>
              <Box>
                {formatCurrency(
                  journal.data.moneysalesnotdeliveredtotalnet,
                  currencyOptions,
                )}
              </Box>
            </Stack>
          )}

          {journal.data.numsalesnotdelivered > 0 &&
            journal.data.numsalesnotdelivereditems > 0 && (
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  {t(
                    'z_reports:logtype_reportitem_salesnotdeliveredtotal_count',
                  )}
                </Box>
                <Box>
                  {journal.data.numsalesnotdelivered}/
                  {journal.data.numsalesnotdelivereditems}
                </Box>
              </Stack>
            )}

          {/* VAT Group Not Delivered Sales*/}
          {journal.data.vatgroupssalesnotdelivered.length > 0 && (
            <JournalDivider variant="dashed" />
          )}

          {journal.data.vatgroupssalesnotdelivered.map((sale, index) => (
            <Stack direction="row" justifyContent="space-between" key={index}>
              <Box>
                {t('z_reports:logtype_reportitem_salesnotdelivered_net', {
                  sss: `${sale.vatvalue}%`,
                })}
              </Box>
              <Box>{formatCurrency(sale.moneytotalnet, currencyOptions)}</Box>
            </Stack>
          ))}

          {journal.data.vatgroupssalesnotdelivered.map((sale, index) => (
            <Stack direction="row" justifyContent="space-between" key={index}>
              <Box>
                {t('z_reports:logtype_reportitem_salesnotdelivered_vat', {
                  sss: `${sale.vatvalue}%`,
                })}
              </Box>
              <Box>{formatCurrency(sale.moneytotalvat, currencyOptions)}</Box>
            </Stack>
          ))}

          {/* Product Group Not Delivered Sales */}
          {journal.data.productgroupssalesnotdelivered.length > 0 && (
            <JournalDivider variant="dashed" />
          )}

          {journal.data.productgroupssalesnotdelivered.map((sale, index) => (
            <Box key={index}>
              <JournalDivider variant="blank" hidden={index === 0} />
              <Stack direction="row" justifyContent="space-between">
                <Box>{sale.name}</Box>
                <Box>
                  {sale.numtotal}x (
                  {formatCurrency(sale.moneytotalnet, currencyOptions)})
                </Box>
              </Stack>
            </Box>
          ))}

          <JournalDivider variant="dashed" />
        </>
      )}

      {/* Refunds Total*/}

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_refundtotals')}</Box>
        <Box>
          {formatCurrency(journal.data.moneyrefundtotal, {
            ...currencyOptions,
            isNegative: true,
          })}
        </Box>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_refundtotals_net')}</Box>
        <Box>
          {formatCurrency(journal.data.moneyrefundtotalnet, {
            ...currencyOptions,
            isNegative: true,
          })}
        </Box>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_refundtotals_count')}</Box>
        <Box>
          {journal.data.numrefund}/{journal.data.numrefunditems}
        </Box>
      </Stack>

      {journal.data.vatgroupsrefund.length > 0 && (
        <JournalDivider variant="blank" />
      )}

      {journal.data.vatgroupsrefund.map((refund, index) => (
        <Stack direction="row" justifyContent="space-between" key={index}>
          <Box>
            {t('z_reports:logtype_reportitem_refundnet', {
              sss: `${refund.vatvalue}%`,
            })}
          </Box>
          <Box>
            {formatCurrency(refund.moneytotalnet, {
              ...currencyOptions,
              isNegative: true,
            })}
          </Box>
        </Stack>
      ))}

      {journal.data.vatgroupsrefund.map((refund, index) => (
        <Stack direction="row" justifyContent="space-between" key={index}>
          <Box>
            {t('z_reports:logtype_reportitem_refundvat', {
              sss: `${refund.vatvalue}%`,
            })}
          </Box>
          <Box>
            {formatCurrency(refund.moneytotalvat, {
              ...currencyOptions,
              isNegative: true,
            })}
          </Box>
        </Stack>
      ))}

      {/* Product Group Refunds */}
      {journal.data.productgroupsrefund.map((refund, index) => (
        <Box key={index}>
          <JournalDivider variant="blank" />
          <Stack direction="row" justifyContent="space-between">
            <Box>
              {refund.name} {refund.vatvalue}%
            </Box>
            <Box>
              {formatCurrency(refund.moneytotal, {
                ...currencyOptions,
                isNegative: true,
              })}
            </Box>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              {refund.numtotal * -1}x (
              {formatCurrency(refund.moneytotalnet, {
                ...currencyOptions,
                isNegative: true,
              })}
              )
            </Box>
          </Stack>
        </Box>
      ))}

      {/* Sales Taxes */}
      {(journal.data.salestaxessales.length > 0 ||
        journal.data.salestaxesrefund.length > 0 ||
        Number(journal.data.moneysalestaxinadvance) !== 0) && (
        <>
          <JournalDivider variant="dashed" />
          <Stack direction="row" justifyContent="space-between">
            <Box>{t('z_reports:logtype_reportitem_subtotal')}</Box>
            <Box>
              {formatCurrency(
                journal.data.moneysummarysubtotal,
                currencyOptions,
              )}
            </Box>
          </Stack>

          <JournalDivider variant="blank" />

          {journal.data.salestaxessales
            .filter((sale) => Number(sale.moneytax) > 0)
            .map((sale, index) => {
              const salesTaxDisplay = [sale.code, sale.externalid]
                .filter(Boolean)
                .join(', ')

              return (
                <Box key={index}>
                  <Stack direction="row" justifyContent="space-between">
                    <Box>
                      {sale.name} ({Number(sale.decimaltaxvalue)}%)
                    </Box>
                    <Box>
                      {formatCurrency(sale.moneytax || 0, currencyOptions)}
                    </Box>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Box>
                      {[
                        salesTaxDisplay,
                        formatCurrency(sale.moneytotal || 0, currencyOptions),
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </Box>
                  </Stack>
                </Box>
              )
            })}

          {journal.data.salestaxesrefund
            .filter((sale) => Number(sale.moneytax) > 0)
            .map((sale, index) => {
              const salesTaxDisplay = [sale.code, sale.externalid]
                .filter(Boolean)
                .join(', ')

              return (
                <Box key={index} sx={{ pl: 2 }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Box>
                      {sale.name} ({Number(sale.decimaltaxvalue)}%)
                    </Box>
                    <Box>
                      {formatCurrency(sale.moneytax || 0, {
                        ...currencyOptions,
                        isNegative: true,
                      })}
                    </Box>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Box>
                      {[
                        salesTaxDisplay,
                        formatCurrency(sale.moneytotal || 0, {
                          ...currencyOptions,
                          isNegative: true,
                        }),
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </Box>
                  </Stack>
                </Box>
              )
            })}

          <Stack direction="row" justifyContent="space-between">
            <Box>{t('z_reports:receipt_salestax_in_advance')}</Box>
            <Box>
              {formatCurrency(
                journal.data.moneysalestaxinadvance,
                currencyOptions,
              )}
            </Box>
          </Stack>
        </>
      )}

      {/* Money Roundoff */}
      <JournalDivider variant="dashed" />

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_roundoff')}</Box>
        <Box>
          {formatCurrency(journal.data.moneysummaryroundoff, currencyOptions)}
        </Box>
      </Stack>

      {/* Money Total */}
      <JournalDivider variant="dashed" />

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_totals')}</Box>
        <Box>
          {formatCurrency(journal.data.moneysummarytotal, currencyOptions)}
        </Box>
      </Stack>

      {/* Payment Sales */}
      {journal.data.paymentssales.length > 0 && (
        <JournalDivider variant="dashed" />
      )}

      {journal.data.paymentssales.map((sale, index) => (
        <Box key={index}>
          <JournalDivider variant="blank" hidden={index === 0} />
          <Stack direction="row" justifyContent="space-between">
            <Box>
              {t('z_reports:logtype_reportitem_payment')} - {sale.name}
            </Box>
            <Box>
              {formatCurrency(sale.moneycaptured || 0, currencyOptions)}
            </Box>
          </Stack>

          {Number(sale.moneyinadvance) > 0 && (
            <Box sx={{ pl: 2 }}>
              {t('z_reports:logtype_reportitem_payment_advance', {
                money: formatCurrency(
                  sale.moneyinadvance || 0,
                  currencyOptions,
                ),
              })}
            </Box>
          )}

          {Number(sale.moneyreserved) > 0 && (
            <Box sx={{ pl: 2 }}>
              {t('z_reports:logtype_reportitem_payment_reserved', {
                money: formatCurrency(sale.moneyreserved || 0, currencyOptions),
              })}
            </Box>
          )}

          {sale.subpayments.map((payment, index) => {
            const name = payment.name
              ? payment.name
              : `${t(
                  'z_reports:logtype_reportitem_paymentsub_other',
                )} (${formatCurrency(
                  payment.moneycaptured || 0,
                  currencyOptions,
                )})`

            const paymentTotal = Number(payment.moneysubtotal)
            const paymentReserved = Number(payment.moneyreserved)
            let total = paymentTotal

            if (paymentTotal > 0 && paymentReserved > 0) {
              total = paymentTotal - paymentReserved
            }

            return (
              <Box key={index} sx={{ pl: 2 }}>
                <Box>
                  {name} ({formatCurrency(total, currencyOptions)})
                </Box>

                {Number(payment.moneyinadvance) > 0 && (
                  <Box sx={{ pl: 2 }}>
                    {t('z_reports:logtype_reportitem_payment_advance', {
                      money: formatCurrency(
                        payment.moneyinadvance || 0,
                        currencyOptions,
                      ),
                    })}
                  </Box>
                )}

                {Number(payment.moneyreserved) > 0 && (
                  <Box sx={{ pl: 2 }}>
                    {t('z_reports:logtype_reportitem_payment_reserved', {
                      money: formatCurrency(
                        payment.moneyreserved || 0,
                        currencyOptions,
                      ),
                    })}
                  </Box>
                )}
              </Box>
            )
          })}
        </Box>
      ))}

      {/* Payment Refunds */}
      {journal.data.paymentsrefund.length > 0 && (
        <JournalDivider variant="dashed" />
      )}

      {journal.data.paymentsrefund.map((payment, index) => (
        <Box key={index}>
          <Stack direction="row" justifyContent="space-between">
            <Box>
              {t('z_reports:logtype_reportitem_refund')} - {payment.name}
            </Box>
            <Box>
              {formatCurrency(payment.moneytotal || 0, {
                ...currencyOptions,
                isNegative: true,
              })}
            </Box>
          </Stack>

          {payment?.subpayments.map((subPayment, index) => {
            const name = subPayment.name
              ? subPayment.name
              : `${t(
                  'z_reports:logtype_reportitem_refundsub_other',
                )} (${formatCurrency(subPayment.moneycaptured || 0, {
                  ...currencyOptions,
                  isNegative: true,
                })})`

            return (
              <Box key={index} sx={{ pl: 2 }}>
                {name} (
                {formatCurrency(subPayment.moneysubtotal, currencyOptions)})
              </Box>
            )
          })}
        </Box>
      ))}

      {/* Report Cash  */}
      <JournalDivider variant="dashed" />

      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_cashstart')}</Box>
        <Box>{formatCurrency(journal.data.moneycash_in, currencyOptions)}</Box>
      </Stack>

      {/* Cash sales refunds*/}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_cashsalesrefunds')}</Box>
        <Box>
          {formatCurrency(journal.data.moneycash_salesrefunds, currencyOptions)}
        </Box>
      </Stack>

      {/* Cash Petty */}
      {Number(journal.data.moneycash_petty) !== 0 && (
        <Stack direction="row" justifyContent="space-between">
          <Box>
            {Number(journal.data.moneycash_petty) < 0
              ? t('z_reports:logtype_reportitem_cashpetty')
              : t('z_reports:logtype_reportitem_cashpetty_in')}
          </Box>
          <Box>
            {formatCurrency(journal.data.moneycash_petty, currencyOptions)}
          </Box>
        </Stack>
      )}

      {/* Cash Bank */}
      {Number(journal.data.moneycash_bank) !== 0 && (
        <Stack direction="row" justifyContent="space-between">
          <Box>
            {Number(journal.data.moneycash_bank) < 0
              ? t('z_reports:logtype_reportitem_cashbank')
              : t('z_reports:logtype_reportitem_cashbank_in')}
          </Box>
          <Box>
            {formatCurrency(journal.data.moneycash_bank, currencyOptions)}
          </Box>
        </Stack>
      )}

      {/* Cash Expected */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_cashexpected')}</Box>
        <Box>
          {formatCurrency(journal.data.moneycash_expected, currencyOptions)}
        </Box>
      </Stack>

      {/* Cash Counted */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_cashcounted')}</Box>
        <Box>
          {formatCurrency(journal.data.moneycash_counted, currencyOptions)}
        </Box>
      </Stack>

      {/* Cash Diff */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_cashdiff')}</Box>
        <Box>
          {formatCurrency(journal.data.moneycash_diff, currencyOptions)}
        </Box>
      </Stack>

      {(journal.data.cashdiff_reasoncode ||
        journal.data.cashdiff_reasoncomment) && (
        <Box sx={{ pl: 2 }}>
          {journal.data.cashdiff_reasoncode}
          {Boolean(journal.data.cashdiff_reasoncomment) &&
            ` (${journal.data.cashdiff_reasoncomment})`}
        </Box>
      )}

      {/* Cash Bank Final */}
      {
        <Stack direction="row" justifyContent="space-between">
          <Box>
            {Number(journal.data.moneycash_bankfinal) <= 0
              ? t('z_reports:logtype_reportitem_cashbankfinal')
              : t('z_reports:logtype_reportitem_cashbankfinal_in')}
          </Box>
          <Box>
            {formatCurrency(journal.data.moneycash_bankfinal, currencyOptions)}
          </Box>
        </Stack>
      }

      {/* Cash Total */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_cashtotal')}</Box>
        <Box>{formatCurrency(journal.data.moneycash_out, currencyOptions)}</Box>
      </Stack>

      {/* Discount */}
      <JournalDivider variant="dashed" />
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_discount')}</Box>
        <Box>{formatCurrency(journal.data.moneydiscount, currencyOptions)}</Box>
      </Stack>

      {/* Product Sales */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_productsales')}</Box>
        <Box>{journal.data.numsalestypeproduct}</Box>
      </Stack>

      {/* Service Sales */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_servicesales')}</Box>
        <Box>{journal.data.numsalestypeservice}</Box>
      </Stack>

      {/* Gitft Card Sales */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_giftcardsales')}</Box>
        <Box>{journal.data.numsalestypegiftcard}</Box>
      </Stack>

      {/* Receipts Count */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_receipt')}</Box>
        <Box>{journal.data.numreceipts}</Box>
      </Stack>

      {/* Drawer Open Count */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_opendrawer')}</Box>
        <Box>{journal.data.numopendrawer}</Box>
      </Stack>

      {/* Practice */}
      {journal.data.numpractice > 0 && (
        <Box>
          <Box>{t('z_reports:logtype_reportitem_practice_title')}</Box>

          <Stack direction="row" justifyContent="space-between" sx={{ pl: 2 }}>
            <Box>{t('z_reports:logtype_reportitem_practice_num')}</Box>
            <Box>{journal.data.numpractice}</Box>
          </Stack>

          <Stack direction="row" justifyContent="space-between" sx={{ pl: 2 }}>
            <Box>{t('z_reports:logtype_reportitem_practice_amount')}</Box>
            <Box>
              {formatCurrency(journal.data.moneypractice, currencyOptions)}
            </Box>
          </Stack>
        </Box>
      )}

      {/* Currency Conversions */}
      {Array.isArray(journal.data.currencyconversions) &&
        journal.data.currencyconversions.length > 0 && (
          <>
            <JournalDivider variant="dashed" />
            <Box>
              {t('z_reports:logtype_reportitem_currencyconversions_title')}
            </Box>

            {journal.data.currencyconversions.map(
              (currencyConversion, index) => {
                const foreignCurrencyOptions: FormatCurrencyOptions = {
                  forceDecimals: true,
                  hideCurrency: true,
                }

                const currencyCode = currencyConversion.currency_code

                const isNotEqual =
                  Number(currencyConversion.money_expected) !==
                  Number(currencyConversion.money_counted)

                const moneyCounted = formatCurrency(
                  currencyConversion.money_counted,
                  { currency: currencyCode, ...foreignCurrencyOptions },
                )

                const moneyCountedBase = formatCurrency(
                  currencyConversion.money_counted_base,
                  { ...currencyOptions },
                )

                const moneyExpected = formatCurrency(
                  currencyConversion.money_expected,
                  { currency: currencyCode, ...foreignCurrencyOptions },
                )

                const moneyExpectedField = formatCurrency(
                  currencyConversion.money_expected_base,
                  { ...currencyOptions },
                )

                return (
                  <Fragment key={index}>
                    <Stack direction="row" justifyContent="space-between">
                      <Box>
                        {currencyCode} {moneyCounted}
                      </Box>
                      <Box>{moneyCountedBase}</Box>
                    </Stack>
                    {isNotEqual && (
                      <Box sx={{ pl: 2 }}>
                        {t(
                          'z_reports:logtype_reportitem_currencyconversion_expected',
                        )}{' '}
                        {currencyCode} {moneyExpected} ({moneyExpectedField})
                      </Box>
                    )}
                  </Fragment>
                )
              },
            )}
          </>
        )}

      {/* Grand Total Sales */}
      <JournalDivider variant="dashed" />
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_grandtotalsales')}</Box>
        <Box>
          {formatCurrency(journal.data.moneygrandtotalsales, currencyOptions)}
        </Box>
      </Stack>

      {/* Grand Total Refund */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_grandtotalrefund')}</Box>
        <Box>
          {formatCurrency(journal.data.moneygrandtotalrefund, {
            ...currencyOptions,
            isNegative: true,
          })}
        </Box>
      </Stack>

      {/* Grand Total Net */}
      <Stack direction="row" justifyContent="space-between">
        <Box>{t('z_reports:logtype_reportitem_grandtotalnet')}</Box>
        <Box>
          {formatCurrency(journal.data.moneygrandtotalnet, currencyOptions)}
        </Box>
      </Stack>

      {/* Staff */}
      <JournalDivider variant="dashed" />
      <Box>{journal.data.staff}</Box>

      {/* Comment */}
      <Box>{journal.data.comment}</Box>
      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
