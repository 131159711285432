import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { usePageTitle } from '../../../hooks/title'
import {
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { EditIcon } from '@sitoo/mui-components'
import { useState } from 'react'
import { RewardsDialog } from './rewards-dialog'

export const SettingsCustomAPIPage = () => {
  const [showEditRewardsDialog, setShowEditRewardsDialog] = useState(false)
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.custom_api'))

  return (
    <>
      <PageHeader title={t('shared:menu.custom_api')} />
      <Container maxWidth={false}>
        <Paper elevation={0}>
          <List>
            <ListItem
              divider
              secondaryAction={
                <IconButton onClick={() => setShowEditRewardsDialog(true)}>
                  <EditIcon color="primary" />
                </IconButton>
              }
            >
              <ListItemText>
                {t('settings:custom_api.rewards.title')}
              </ListItemText>
            </ListItem>
          </List>
        </Paper>
      </Container>
      <RewardsDialog
        open={showEditRewardsDialog}
        onClose={() => setShowEditRewardsDialog(false)}
      />
    </>
  )
}
