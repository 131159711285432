import { Box } from '@mui/material'
import { ToolbarPropsOverrides } from '@mui/x-data-grid-pro'
import { forwardRef } from 'react'
import { BulkGridHeaderActions } from './bulk-grid-header-actions'

type CustomGridHeaderProps = ToolbarPropsOverrides

export const CustomGridHeader = forwardRef<
  HTMLDivElement,
  CustomGridHeaderProps
>(function CustomGridHeader(props, ref) {
  return (
    <Box ref={ref} sx={{ position: 'relative' }}>
      <BulkGridHeaderActions>{props.bulkAction}</BulkGridHeaderActions>
    </Box>
  )
})
