import {
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Switch,
} from '@mui/material'
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  gridColumnDefinitionsSelector,
  GridColumnResizeParams,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import { ReturnIcon, SectionHeader } from '@sitoo/mui-components'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridImageRenderer } from '../data-grid-image'
import { RESET_COLUMN_WIDTH_EVENT } from '../data-grid/utils'

type Props = {
  setMenuOpen: (value: boolean) => void
}

type Column = { field: string }

export const DataGridColumnOptions = ({ setMenuOpen }: Props) => {
  const { t } = useTranslation(['shared'])

  const apiRef = useGridApiContext()

  const allColumns = useGridSelector(apiRef, gridColumnDefinitionsSelector)

  const columns = useMemo(
    () =>
      allColumns
        .filter((column) => column.headerName)
        .filter((column) => column.field !== GRID_CHECKBOX_SELECTION_FIELD)
        .filter(
          (column) => column.renderCell?.name !== DataGridImageRenderer.name,
        ),
    [allColumns],
  )

  const columnVisibility = useGridSelector(
    apiRef,
    gridColumnVisibilityModelSelector,
  )

  const resetGridState = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const params = {
        colDef: { field: RESET_COLUMN_WIDTH_EVENT },
      } as GridColumnResizeParams

      apiRef.current.publishEvent('columnResize', params, event.nativeEvent)
      setMenuOpen(false)
    },
    [apiRef, setMenuOpen],
  )

  const toggleColumnVisibility = useCallback(
    (column: Column) => {
      const visibility = columnVisibility[column.field]
      const isVisible = visibility === undefined ? false : visibility === false

      apiRef.current.setColumnVisibility(column.field, isVisible)
    },
    [apiRef, columnVisibility],
  )

  return (
    <>
      <SectionHeader variant="transparent">
        {t('shared:label.columns')}
      </SectionHeader>

      <Divider />

      <ListItemButton
        role="menuitem"
        onClick={resetGridState}
        data-testid="reset-column-width-button"
      >
        <ListItemText>{t('shared:label.reset_grid_state')}</ListItemText>
        <ListItemIcon>
          <ReturnIcon />
        </ListItemIcon>
      </ListItemButton>

      <Divider />

      {columns.map((column) => (
        <MenuItem
          role="menuitem"
          data-testid={`${column.field}-button`}
          key={column.field}
          disabled={column.hideable === false}
          onClick={() => toggleColumnVisibility(column)}
        >
          <ListItemText>{column.headerName}</ListItemText>

          <Switch
            data-testid={`${column.field}-switch`}
            checked={columnVisibility[column.field] ?? true}
            disabled={column.hideable === false}
          />
        </MenuItem>
      ))}
    </>
  )
}
