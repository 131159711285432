import { Box, Container, useTheme } from '@mui/material'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { SitooGlobalStyles } from '../../styles/global'
import { SitooLogo } from '@sitoo/mui-components'

const Logo = () => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        top: (theme) => theme.spacing(5),
        left: (theme) => theme.spacing(5),
        width: '200px',
        position: 'absolute',
        color: 'white',

        [theme.breakpoints.down('md')]: {
          display: 'grid',
          justifyItems: 'center',
          mb: 3.5,
          position: 'initial',
          width: '100%',
        },
      }}
    >
      <SitooLogo style={{ maxHeight: '33px' }} />
    </Box>
  )
}

export const LoginLayout = () => {
  return (
    <Suspense>
      <SitooGlobalStyles />

      <Box
        sx={{
          minHeight: '100vh',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          backgroundImage: 'url(/login-background.jpeg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          position: 'relative',
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 480,

              '.MuiFormControl-root .MuiInput-root input': {
                padding: '13px 12px',
              },
              '.MuiButton-sizeLarge': {
                height: '50px',
              },
              '.MuiFormLabel-root': {
                fontWeight: 'normal',
              },
            }}
          >
            <Logo />
            <Outlet />
          </Box>
        </Container>
      </Box>
    </Suspense>
  )
}
