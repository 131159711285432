import { Chip, Tab, Tabs } from '@mui/material'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import { useTracking } from '../../../hooks/tracking'
import { Details } from './details'
import { Report } from './report'
import { JournalItem, JournalReportItem } from '../types'
import { useTabs } from '../../../hooks/tab'

type Props = {
  open: boolean
  onClose: () => void
  journal?: JournalItem
}

enum TabLabels {
  Details = 1,
  Report,
}

export const JournalViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const [_activeTab, setActiveTab] = useTabs(
    TabLabels,
    TabLabels.Details,
    'viewPanelTab',
  )

  const journal = props.journal

  const activeTab =
    journal?.data.__typename === 'JournalReport'
      ? _activeTab
      : TabLabels.Details

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        title={t(`journals:entry_type_enum.${journal?.type || ''}`)}
        tags={
          journal?.data && 'staff' in journal.data ? (
            <Chip size="small" label={journal?.data?.staff} color="gray" />
          ) : undefined
        }
        section={t('journals:page_title')}
        onClose={props.onClose}
        hideActionsSkeleton
        onBack={() => {
          trackButtonClick({ name: 'journal-panel-go-back' })
        }}
      />

      <Tabs
        value={activeTab}
        onChange={(_e, value: TabLabels) => {
          trackButtonClick({
            name: 'journal-view-panel-tab',
            value,
          })
          setActiveTab(value)
        }}
        variant="fullWidth"
        sx={{
          '.MuiButtonBase-root': {
            background: (theme) => theme.palette.background.paper,
          },
        }}
      >
        <Tab
          label={t('journals:details_label')}
          value={TabLabels.Details}
          data-testid="tab-details"
        />
        {journal?.data.__typename === 'JournalReport' && (
          <Tab
            label={t('journals:report_label')}
            value={TabLabels.Report}
            data-testid="tab-report"
          />
        )}
      </Tabs>

      {activeTab === TabLabels.Details && <Details journal={journal} />}

      <Report
        journal={journal as JournalReportItem}
        // Prevent unmaunting of <Providers />
        hidden={
          journal?.data.__typename !== 'JournalReport' ||
          activeTab !== TabLabels.Report
        }
      />
    </ViewPanel>
  )
}
