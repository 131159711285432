import { Divider, TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SearchProductField } from '../../../components/search-product-field'
import { ProductFieldsFragment } from '../../../generated/graphql'
import { BaseServiceOrderFormContext } from '../shared'

export const ProductField = () => {
  const { register, formState, setValue } =
    useFormContext<BaseServiceOrderFormContext>()
  const { t } = useTranslation(['service_orders'])

  const handleOnChange = (product: ProductFieldsFragment) => {
    if (product.title) setValue('product.name', product.title)
    if (product.sku) setValue('product.sku', product.sku)
  }

  return (
    <FormFieldset label={t('product')}>
      <SearchProductField
        sx={{ mb: 2 }}
        onChange={handleOnChange}
        data-testid="search-product-input"
      />
      <Divider sx={{ mb: 2 }} />

      <TextField
        error={!!formState?.errors.product?.name}
        fullWidth
        helperText={formState?.errors.product?.name?.message}
        label={t('service_order_form.product_name')}
        required
        {...register('product.name', {
          required: t('shared:validation.field_required', {
            field: t('service_order_form.product_name'),
          }),
        })}
        data-testid="edit-service-order-product-name-field"
        sx={{ mb: 2 }}
      />
      <TextField
        error={!!formState?.errors.product?.sku}
        fullWidth
        helperText={formState?.errors.product?.sku?.message}
        label={t('sku')}
        required
        {...register('product.sku', {
          required: t('shared:validation.field_required', {
            field: t('sku'),
          }),
        })}
        data-testid="edit-service-order-product-sku-field"
        sx={{ mb: 2 }}
      />
      <TextField
        error={!!formState?.errors.product?.serial}
        fullWidth
        helperText={formState?.errors.product?.serial?.message}
        label={t('service_order_form.serial')}
        data-testid="edit-service-order-product-serial-field"
        {...register('product.serial')}
        sx={{ mb: 2 }}
      />
    </FormFieldset>
  )
}
