import { List, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { ListItemAccordion } from '../../../../components/list-item-accordion'
import { ListItemCodeBlock } from '../../../../components/list-item-code-block'
import { stripTypename } from '@apollo/client/utilities'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalReceipt' }
  >
}

export const ReceiptDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props
  const receiptNumber = journal?.receiptnumber
  const storeName = journal?.additionaldata?.['store-name'] as string
  const dataString = JSON.stringify(stripTypename(journal || {}), null, 2)

  return (
    <>
      <ListItemSkeleton
        hide={!receiptNumber}
        secondaryAction={<ListItemText>{receiptNumber}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-receiptnumber"
      >
        <ListItemText>{t('journals:receipt_number')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!storeName}
        secondaryAction={<ListItemText>{storeName}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-store"
      >
        <ListItemText>{t('journals:store_name')}</ListItemText>
      </ListItemSkeleton>

      <ListItemAccordion
        aria-controls="journal-report-data"
        summary={
          <ListItemSkeleton>
            <ListItemText>{t('journals:data')}</ListItemText>
          </ListItemSkeleton>
        }
      >
        <List>
          <ListItemCodeBlock code={dataString} />
        </List>
      </ListItemAccordion>
    </>
  )
}
