import { GridCellEditStopReasons, GridRowId } from '@mui/x-data-grid-pro'
import { GridEventListener } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { MutableRefObject, useCallback } from 'react'

export const useFocusNextEditable = (apiRef: MutableRefObject<GridApiPro>) => {
  const focusNextEditable: GridEventListener<'cellEditStop'> = useCallback(
    ({ reason }) => {
      if (reason !== GridCellEditStopReasons.enterKeyDown) {
        return
      }

      // Fire an event when edit of the previous cell is complete
      // and next cell is in focus
      const unsubscribe = apiRef.current.subscribeEvent(
        'cellFocusIn',
        ({ id, field }) => {
          let cellIndex = apiRef.current.getRowIndexRelativeToVisibleRows(id)
          let nextRowId: GridRowId

          do {
            nextRowId = apiRef.current.getRowIdFromRowIndex(cellIndex)

            if (!nextRowId) {
              break
            }

            const { isEditable, cellMode } = apiRef.current.getCellParams(
              nextRowId,
              field,
            )

            if (isEditable && cellMode === 'view') {
              apiRef.current.startCellEditMode({ id: nextRowId, field })
              break
            }

            cellIndex += 1
          } while (nextRowId)

          unsubscribe()
        },
      )
    },
    [apiRef],
  )

  return focusNextEditable
}
