import { useCallback } from 'react'
import { useStateParams } from '../state-params'

export const useTabs = <
  E extends Record<number, string | number>,
  V extends E[keyof E],
>(
  tabsEnum: E,
  defaultTab: V,
  tabQueryName = 'tab',
): [V, (value: V) => void] => {
  const [queryParams, setQueryParams] = useStateParams({
    [tabQueryName]: defaultTab,
  })

  const activeTab = tabsEnum[Number(queryParams[tabQueryName])]
    ? (Number(queryParams[tabQueryName]) as V)
    : defaultTab

  const setActiveTab = useCallback(
    (value: V) => setQueryParams({ [tabQueryName]: value }),
    [setQueryParams, tabQueryName],
  )

  return [activeTab, setActiveTab]
}
