import { useQuery } from '@apollo/client'
import { Box, Button } from '@mui/material'
import { SalesTaxIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ViewPanel, ViewPanelHeader } from '../../../../components/view-panel'
import { SalesTaxGroupDocument } from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'

import { RootRoute } from '../../..'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { Information } from './information'
import { SalesTaxes } from './sales-taxes'

type Props = {
  open: boolean
  onClose: () => void
  salesTaxGroupId: number | undefined
}

export const SalesTaxGroupViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'settings'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()

  const generatePath = useAbsolutePath()

  const { data, loading: fetchLoading } = useQuery(SalesTaxGroupDocument, {
    variables: { salesTaxGroupId: props.salesTaxGroupId as number },
    skip: !props.salesTaxGroupId,
  })

  const salesTaxGroup = data?.salesTaxGroup
  const isLoading = fetchLoading

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={isLoading}
        title={salesTaxGroup?.name || ''}
        section={t('settings:sales_tax_group.page_title')}
        onClose={props.onClose}
        actions={
          props.salesTaxGroupId && (
            <Button
              component={Link}
              to={generatePath(RootRoute.SettingsSalesTaxGroup, {
                id: props.salesTaxGroupId,
              })}
              color="secondary"
              size="small"
              data-testid="edit-sales-tax-group-button"
              onClick={() => {
                trackButtonClick({
                  name: 'sales-tax-group-panel-edit',
                  id: props.salesTaxGroupId,
                })
              }}
            >
              {t('shared:action.edit')}
            </Button>
          )
        }
        onBack={() => {
          trackButtonClick({ name: 'sales-tax-group-panel-go-back' })
        }}
        sectionIcon={<SalesTaxIcon />}
      />
      <Box sx={{ background: (theme) => theme.palette.background.paper }}>
        <Information salesTaxGroup={salesTaxGroup} />
        <SalesTaxes salesTaxes={salesTaxGroup?.salestaxes} />
      </Box>
    </ViewPanel>
  )
}
