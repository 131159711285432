import { CampaignType } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import {
  translateCampaignTypeExample,
  translateCampaignTypeTitle,
} from '../i18n'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type PackageCampaignSelectionProps = {
  onSelectCampaignType: (campaignType: CampaignType) => void
  ['data-testid']?: string
}

export const PackageCampaignSelection = (
  props: PackageCampaignSelectionProps,
) => {
  const { trackButtonClickEvent } = useTracking()

  return (
    <div data-testid={props['data-testid']}>
      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-package-price' },
          () => props.onSelectCampaignType(CampaignType.PackageBuyForPriceM),
        )}
        primary={translateCampaignTypeTitle(CampaignType.PackageBuyForPriceM)}
        secondary={translateCampaignTypeExample(
          CampaignType.PackageBuyForPriceM,
        )}
        hasExample
        data-testid="package-price-campaign-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-package-percentage-discount',
          },
          () => props.onSelectCampaignType(CampaignType.PackageDiscountX),
        )}
        primary={translateCampaignTypeTitle(CampaignType.PackageDiscountX)}
        secondary={translateCampaignTypeExample(CampaignType.PackageDiscountX)}
        hasExample
        data-testid="package-percentage-discount-campaign-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-package-price-discount',
          },
          () => props.onSelectCampaignType(CampaignType.PackageDiscountM),
        )}
        primary={translateCampaignTypeTitle(CampaignType.PackageDiscountM)}
        secondary={translateCampaignTypeExample(CampaignType.PackageDiscountM)}
        hasExample
        data-testid="package-price-discount-campaign-listItem"
      />
    </div>
  )
}
