import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormFieldset } from '@sitoo/mui-components'
import { memo } from 'react'
import { getCampaignType } from '../../../../utils/campaigns'
import { CampaignFormContext } from '../..'
import { ListItem, ListItemIcon, ListItemText } from '@mui/material'

export const CampaignTypeField = memo(function TypeField() {
  const { register, getValues } = useFormContext<CampaignFormContext>()
  const { t } = useTranslation(['campaigns', 'shared'])
  const type = getValues('campaign.vouchertype')
  const campaignType = getCampaignType(type)

  if (!campaignType) return null

  return (
    <FormFieldset
      label={t('campaigns:campaign_form.details_fieldset')}
      sx={{
        '.MuiFormFieldset-Paper': { py: 0.5, px: 0 },
      }}
    >
      <ListItem>
        <ListItemIcon>{campaignType.icon}</ListItemIcon>
        <ListItemText
          primary={t(campaignType.title)}
          secondary={t(campaignType.caption)}
        />
      </ListItem>

      <input type="hidden" {...register('campaign.vouchertype')} />
    </FormFieldset>
  )
})
