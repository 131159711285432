import { useMutation, useQuery } from '@apollo/client'
import { Divider } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { SettingsDetailDialog } from '../../../components/settings-detail-dialog'
import { TextFieldInput } from '../../../inputs/text-field-input'
import {
  AddCustomAttributeDocument,
  CustomAttribute,
  CustomAttributeTypeEnum,
  DeleteCustomAttributeDocument,
  GetCustomAttributeDocument,
  GetCustomAttributesDocument,
  UpdateCustomAttributeDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { ValuesInput } from '../../../inputs/values-input'
import { SwitchInput } from '../../../inputs/switch-input'
import { SelectInput } from '../../../inputs/select-input'

export const CustomAttributeDialog = () => {
  const { t } = useTranslation(['shared', 'settings'])

  const { id } = useParams()
  const attributeId = id
  const isNewAttribute = !attributeId

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { writeSettingsCustomAttributes } = useAuthorization().modules

  const { data, loading: fetchLoading } = useQuery(GetCustomAttributeDocument, {
    variables: { id: String(attributeId) },
    fetchPolicy: 'cache-and-network',
    skip: isNewAttribute,
  })

  const { reset, control, getValues, handleSubmit, formState } =
    useForm<CustomAttribute>({
      defaultValues: {
        id: '',
        title: '',
        type: CustomAttributeTypeEnum.String,
        searchable: false,
        enums: [],
      },
    })

  const type = useWatch({ control, name: 'type' })

  const attributeTypes = useMemo(
    () =>
      Object.values(CustomAttributeTypeEnum).map((value) => ({
        value,
        name: t(
          `settings:custom_attributes.type_options.${value.toLowerCase()}`,
        ),
      })),
    [t],
  )

  const [addAttribute, { loading: addLoading }] = useMutation(
    AddCustomAttributeDocument,
    { refetchQueries: [GetCustomAttributesDocument] },
  )

  const [updateAttribute, { loading: updateLoading }] = useMutation(
    UpdateCustomAttributeDocument,
    { refetchQueries: [GetCustomAttributesDocument] },
  )

  const [deleteAttribute, { loading: deleteLoading }] = useMutation(
    DeleteCustomAttributeDocument,
    { refetchQueries: [GetCustomAttributesDocument] },
  )

  const isLoading = fetchLoading || addLoading || updateLoading || deleteLoading

  const onSubmit = async (data: CustomAttribute) => {
    const { id, type, title, searchable } = data

    const enums = data.enums?.filter(Boolean)

    const customAttribute =
      data.type === CustomAttributeTypeEnum.String
        ? { id, type, title, searchable, enums }
        : { id, type, title }

    if (isNewAttribute) {
      await addAttribute({ variables: { customAttribute } })
    } else {
      await updateAttribute({ variables: { customAttribute } })
    }
  }

  const deleteItem = async () => {
    if (attributeId) {
      await deleteAttribute({ variables: { id: attributeId } })
    }
  }

  useEffect(() => {
    if (!attributeId) reset()

    if (data?.customAttribute) {
      reset(data.customAttribute)
    }
  }, [data, reset, attributeId])

  const onClose = () => {
    navigate(generatePath(RootRoute.SettingsCustomAttributes))
  }

  return (
    <SettingsDetailDialog
      type={isNewAttribute ? 'add' : 'edit'}
      dialogName="custom-attribute"
      typeLabel={t('settings:custom_attributes.item_type')}
      getItemName={() => getValues('title')}
      onSave={onSubmit}
      onDelete={deleteItem}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formState={formState}
    >
      <TextFieldInput
        name="id"
        control={control}
        label={t('settings:custom_attributes.id')}
        disabled={!isNewAttribute || !writeSettingsCustomAttributes}
        required
        rules={{
          validate: (value) => {
            const pattern = /^[a-zA-Z][a-zA-Z0-9_-]{0,31}$/

            if (typeof value === 'string') {
              if (!pattern.test(value)) {
                return t('shared:error.field_does_not_match', { pattern })
              }
            }
          },
        }}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'custom-attribute-id' },
        }}
      />

      <TextFieldInput
        name="title"
        control={control}
        label={t('settings:custom_attributes.title')}
        required
        rules={{
          validate: (value) => {
            if (typeof value === 'string' && value.trim().length === 0)
              return t('shared:validation.field_required', {
                field: t('settings:custom_attributes.title'),
              })
          },
        }}
        disabled={!writeSettingsCustomAttributes}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'custom-attribute-title' },
        }}
      />

      <SelectInput
        name="type"
        control={control}
        options={attributeTypes}
        label={t(`settings:custom_attributes.type`)}
        dataTestid="custom-attribute-type"
        disabled={!writeSettingsCustomAttributes || !isNewAttribute}
      />

      {type === CustomAttributeTypeEnum.String && (
        <>
          <SwitchInput
            name="searchable"
            control={control}
            data-testid="searchable"
            label={t('settings:custom_attributes.searchable')}
            slotProps={{ typography: { fontWeight: 'medium' } }}
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              m: (theme) => theme.spacing(2, 0),
            }}
            disabled={!writeSettingsCustomAttributes || !isNewAttribute}
          />

          <Divider sx={{ mx: -3, mb: 2 }} />

          <ValuesInput
            name="enums"
            control={control}
            label={t('settings:custom_attributes.allowed_values')}
            rules={{
              validate: (value) => {
                if (Array.isArray(value)) {
                  if (new Set(value).size !== value.length) {
                    return t('shared:error.field_is_not_unique', {
                      property: t('settings:custom_attributes.allowed_values'),
                    })
                  }
                }
                return true
              },
            }}
            sx={{ mb: 2 }}
          />
        </>
      )}
    </SettingsDetailDialog>
  )
}
