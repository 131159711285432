import { Dropzone, FormFieldset } from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ImageViewerDialog } from '../../../../components/image-viewer-dialog'
import { GetProductQuery } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'

type Props = {
  isLoading?: boolean
}

export const IMAGE_ACCEPT = {
  'image/jpeg': ['.jpg', '.jpeg', '.jpe'],
  'image/png': ['.png'],
  'image/gif': ['.gif'],
}

export const ImagesField = ({ isLoading }: Props) => {
  const { getValues, setValue, watch } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const isSingleProduct =
    watch('product.isSingleProduct') ||
    watch('product.isSingleProduct') === undefined

  const setFiles = (files: UploadedFile[]) => {
    setValue(
      'product.productImages',
      files.map((f) => ({ fileUrl: f.fileUrl!, id: f.id!, file: f.file })),
    )
  }

  const preloadFiles = getValues('product.productImages')?.map((image) => ({
    fileUrl: image.fileUrl,
    id: image.id,
  }))

  const onPreviewClick = (urls: string[], activeIndex: number) => {
    setImageUrls(urls)
    setActiveIndex(activeIndex)
    setOpen(true)
  }

  if (!isSingleProduct) {
    return null
  }

  return (
    <>
      <ImageViewerDialog
        open={open}
        onClose={() => setOpen(false)}
        imageUrls={imageUrls}
        activeIndex={activeIndex}
      />
      <FormFieldset label={t('products:product_form.images_fieldset')}>
        <Dropzone
          label={t('products:product_form.add_photo_label')}
          preloadFiles={preloadFiles}
          onPreviewClick={onPreviewClick}
          setFiles={setFiles}
          isLoading={isLoading}
          accept={IMAGE_ACCEPT}
          hideFileName
          disabled={!writeProducts}
        />
      </FormFieldset>
    </>
  )
}
