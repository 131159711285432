import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../../components/filter-view-panel'
import { ProductCategoryIdFilter } from '../../../../filters/product-category-id-filter'
import { FranchiseSiteFilter } from '../../../products/product-list/franchise-site-filter'
import { ProductStateFilter } from '../../../products/product-list/product-state-filter'

type StockLevelsFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const StockLevelsFilterViewPanel = (
  props: StockLevelsFilterViewPanelProps,
) => {
  const { t } = useTranslation(['stock'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('stock:stock_levels.title')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <ProductCategoryIdFilter />
            </ListItem>
            <ListItem>
              <ProductStateFilter />
            </ListItem>
            <FranchiseSiteFilter />
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
