import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../inputs/autocomplete-input'
import { FilterContext } from '../components/data-grid/context'
import { AllProductGroupsDocument } from '../generated/graphql'
import { useFormFilter } from '../hooks/form-filter'

export type ProductGroupIdFilter = {
  productGroupIds?: number[]
}

export const ProductGroupFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'productGroupIds'

  const { data, loading: isLoading } = useQuery(AllProductGroupsDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const options = useMemo(
    () =>
      [...(data?.allProductGroups || [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    [data?.allProductGroups],
  )

  const { queryParams, formContext, resetForm } =
    useFormFilter<ProductGroupIdFilter>({
      formProps: {
        defaultValues: { productGroupIds: [] },
      },
      parseOptions: {
        types: { productGroupIds: (value) => value.split(',').map(Number) },
      },
    })

  const { productGroupIds } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (isLoading) return

    if (productGroupIds?.length) {
      setFilter(filterKey, {
        label: t('filter:product_group.label'),
        labelValues: productGroupIds.map(
          (id) => options.find((x) => x.id === id)?.name || String(id),
        ),
        value: productGroupIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [isLoading, options, productGroupIds, removeFilter, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="productGroupIds"
      control={formContext.control}
      options={options.map(({ id }) => id)}
      disabled={isLoading}
      label={t('filter:product_group.label')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'product-group-input' },
        },
      }}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) =>
          options.find((x) => x.id === option)?.name || String(option),
      }}
    />
  )
}
