import { SxProps, Theme } from '@mui/material'

export const SwiperOverrides: SxProps<Theme> = {
  '.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet':
    {
      margin: (theme) => theme.spacing(1),
    },

  '.swiper-pagination-bullet': {
    '&::after': {
      display: 'block',
      content: '""',
      padding: (theme) => theme.spacing(1.5),
      marginLeft: (theme) => theme.spacing(-1),
      marginTop: (theme) => theme.spacing(-1),
    },
  },
}
