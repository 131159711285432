import { useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { SettingsList } from '../../../components/settings-list'
import {
  GetPurchasePriceListsDocument,
  GetPurchasePriceListsQuery,
  GetPurchasePriceListsQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { OrderIcon } from '@sitoo/mui-components'
import { DateTimeRenderer } from '../../../components/data-grid/utils/date-time-renderer'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { PurchasePriceListsContextMenu } from './context-menu'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<
  GetPurchasePriceListsQuery['purchasePriceLists']['items']
>

const PAGE_SIZE = 100

export const SettingsPurchasePriceListsPage = () => {
  const name = 'purchase-price-lists'
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.purchase_price_lists'))
  const apiRef = useGridApiRef()
  const { trackButtonClick } = useTracking()

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { formatDate } = useLocalizedDate()

  const {
    data,
    fetchMore,
    loading: fetchLoading,
  } = useQuery(GetPurchasePriceListsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { start: 0, num: PAGE_SIZE },
  })

  const onEdit = (id: string) => {
    trackButtonClick({ name: `${name}-edit` })
    navigate(generatePath(RootRoute.SettingsPurchasePriceList, { id }))
  }

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    navigate(generatePath(RootRoute.SettingsPurchasePriceListNew))
  }

  const isLoading = fetchLoading

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'id',
        minWidth: 140,
        headerName: t('settings:purchase_price_lists.id'),
      },
      {
        field: 'name',
        minWidth: 240,
        headerName: t('settings:purchase_price_lists.name'),
      },
      {
        field: 'currency',
        minWidth: 100,
        headerName: t('settings:purchase_price_lists.currency'),
      },
      {
        field: 'created_at',
        minWidth: 180,
        headerName: t('settings:purchase_price_lists.created_at'),
        renderCell: (params) => (
          <DateTimeRenderer {...params} formatDate={formatDate} />
        ),
      },
      {
        field: 'modified_at',
        minWidth: 180,
        headerName: t('settings:purchase_price_lists.modified_at'),
        renderCell: (params) => (
          <DateTimeRenderer {...params} formatDate={formatDate} />
        ),
      },
      {
        field: 'owner_eshop_id',
        minWidth: 80,
        headerName: t('settings:purchase_price_lists.owner_site'),
      },
    ],
    [formatDate, t],
  )

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.purchasePriceLists.next) {
      return fetchMore({
        variables: {
          start: (data?.purchasePriceLists?.start || 0) + pageSize,
          next: data?.purchasePriceLists.next,
        } as GetPurchasePriceListsQueryVariables,
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <SettingsList
      name="purchase-price-lists"
      apiRef={apiRef}
      rows={data?.purchasePriceLists.items || []}
      rowCount={
        data?.purchasePriceLists.items?.length
          ? data?.purchasePriceLists.items.length + 1
          : 0
      }
      showMore={!!data?.purchasePriceLists.next}
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      fetchMore={fetchMoreItems}
      hideTotalCount
      columns={columns}
      loading={isLoading}
      onRowClick={({ id }) => onEdit(String(id))}
      noRowsOverlay={{
        icon: <OrderIcon />,
        title: t('settings:purchase_price_lists.empty_title'),
        description: t('settings:purchase_price_lists.empty_description'),
        action: (
          <Button
            onClick={() => navigate(generatePath(RootRoute.SettingsImportData))}
          >
            {t('settings:purchase_price_lists.import')}
          </Button>
        ),
      }}
      slots={{ PageHeader, pagination: CursorPagination }}
      slotProps={{
        pageHeader: {
          title: t('shared:menu.purchase_price_lists'),
          isFlexible: true,
          rightColumn: (
            <>
              <PurchasePriceListsContextMenu />

              <Button data-testid="add-price-list" onClick={onAdd}>
                {t('settings:purchase_price_lists.add_price_list')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
