import { useQuery } from '@apollo/client'
import { ListItem, MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { AllFranchiseSitesDocument } from '../../../generated/graphql'
import { useMe } from '../../../hooks/me'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  siteId?: string
}

export const FranchiseSiteFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])

  const { me } = useMe()
  const { data: sitesData, loading: isLoading } = useQuery(
    AllFranchiseSitesDocument,
  )

  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'siteId'
  const sites = useMemo(
    () => sitesData?.allFranchiseSites || [],
    [sitesData?.allFranchiseSites],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const siteId = useMemo(() => {
    if (sites.some((x) => String(x.eshopid) === queryParams[filterKey])) {
      return queryParams[filterKey]
    }

    return String(me?.siteId)
  }, [me?.siteId, queryParams, sites])

  useEffect(() => {
    registerFilter({
      key: filterKey,
      isReady: !isLoading,
    })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (siteId === String(me?.siteId)) {
      removeFilter(filterKey)
    } else {
      const site = sites.find((x) => String(x.eshopid) === siteId)
      setFilter(filterKey, {
        label: t('filter:franchise_site_filter.label'),
        labelValues: [site?.eshopname || siteId || ''],
        value: siteId,
      })
    }
  }, [siteId, removeFilter, setFilter, me?.siteId, sites, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  if (sites.length < 2) {
    return null
  }

  return (
    <ListItem>
      <TextField
        onChange={(event) => {
          setQueryParams({
            [filterKey]: event.target.value || undefined,
          })
        }}
        value={sites.length ? siteId : String(me?.siteId)}
        select
        label={t('shared:label.site_id')}
      >
        {sites.map((s, i) => (
          <MenuItem
            key={i}
            value={String(s.eshopid)}
            divider
            onClick={trackButtonClickEvent({
              name: 'franchise-site-filter-select-site',
              id: s.eshopid,
            })}
          >
            <Typography variant="body02">
              {`${s.eshopname} (${s.eshopid})`}
            </Typography>
          </MenuItem>
        ))}
      </TextField>
    </ListItem>
  )
}
