import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../components/data-grid/context'
import { GetCategoriesDocument } from '../generated/graphql'
import { MAX_NUM_REQUEST } from '../utils/constants'
import { formatCategoryName } from '../utils/format/category'
import { AutocompleteInput } from '../inputs/autocomplete-input'
import { useFormFilter } from '../hooks/form-filter'

export type CategoryIdFilter = {
  categoryIds?: number[]
}

export const ProductCategoryIdFilter = () => {
  const { t } = useTranslation(['products', 'shared'])

  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'categoryIds'

  const { data: categoriesData, loading: isLoading } = useQuery(
    GetCategoriesDocument,
    { variables: { num: MAX_NUM_REQUEST } },
  )

  const categories = useMemo(
    () => categoriesData?.categories.items || [],
    [categoriesData?.categories.items],
  )

  const options = useMemo(
    () => categories.map(({ id, title }) => ({ label: title, id: Number(id) })),
    [categories],
  )

  const { queryParams, formContext, resetForm } =
    useFormFilter<CategoryIdFilter>({
      formProps: {
        defaultValues: { categoryIds: [] },
      },
      parseOptions: {
        types: { categoryIds: (value) => value.split(',').map(Number) },
      },
    })

  const { categoryIds } = queryParams

  useEffect(() => {
    if (categoryIds?.length) {
      setFilter(filterKey, {
        label: t('products:category_filter_tag_label'),
        labelValues: categoryIds.map(
          (id) =>
            formatCategoryName(Number(id), categories, true) || String(id),
        ),
        value: categoryIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [categories, categoryIds, removeFilter, setFilter, t])

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="categoryIds"
      control={formContext.control}
      options={options.map(({ id }) => id)}
      disabled={isLoading}
      label={t('products:product_category')}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) => {
          return formatCategoryName(option, categories, true) || String(option)
        },
      }}
    />
  )
}
