import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { PriorityField } from '../priority'

export const BasicDetailsField = () => {
  const { register, formState } = useFormContext<CampaignFormContext>()
  const { t } = useTranslation(['campaigns', 'shared'])

  return (
    <FormFieldset>
      <TextField
        error={!!formState?.errors.campaign?.vouchername}
        fullWidth
        helperText={
          formState?.errors.campaign?.vouchername?.message ||
          t('campaigns:campaign_form.vouchername_description')
        }
        label={t('campaigns:campaign_form.vouchername_label')}
        {...register('campaign.vouchername', {
          required: t('shared:validation.field_required', {
            field: t('campaigns:campaign_form.vouchername_label'),
          }),
        })}
        sx={{ mb: 2 }}
        data-testid="campaign-name-field"
      />

      <TextField
        error={!!formState?.errors.campaign?.vouchercode}
        fullWidth
        helperText={t('campaigns:campaign_form.vouchercode_description')}
        label={t('campaigns:campaign_form.vouchercode_label')}
        {...register('campaign.vouchercode')}
        sx={{ mb: 2 }}
        data-testid="campaign-group-field"
      />
      <PriorityField />
    </FormFieldset>
  )
}
