/* eslint-disable @typescript-eslint/no-misused-promises */
import { LoadingButton } from '@mui/lab'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ShipmentStateV2 } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { useServerValidation } from '../../../hooks/server-validation'
import { ShipmentPackage } from '../fields/packages'
import { ShipmentV2Package } from '../shared'

type Props = {
  open: boolean
  package?: ShipmentV2Package
  shipmentState?: ShipmentStateV2
  isNewShipment: boolean
  onClose(): void
  onUpdate?(data: ShipmentV2Package): void | Promise<void>
  onUpdateSuccess?(): void
  onUpdateError?(errorMessage: string): void

  onDelete(packageId: ShipmentV2Package): void | Promise<void>
  onDeleteSuccess?(): void
  onDeleteError?(errorMessage: string): void

  dataTestid?: string
}

type Form = ShipmentPackage

export const SetPackageInfoDialog = (props: Props) => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  const dialogName = 'set-package-info-v2'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()

  const prevOpen = usePrevious(props.open)

  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const isLoading = loadingDelete || loadingUpdate

  const formId = 'set-package-info'

  const { formState, handleSubmit, register, reset, setError } = useForm<Form>({
    mode: 'onSubmit',
  })

  const setFormError = useServerValidation<Form>('package', setError, {
    resolveFieldFromProperty: (property) => {
      return property.replace('data.package', '')
    },
  })

  useEffect(() => {
    if (props.open === prevOpen) return

    if (props.open) {
      reset(props.package, { keepDefaultValues: false })
      trackDialogOpen({ name: dialogName })
    } else {
      reset(undefined)
      trackDialogClose({ name: dialogName })
    }
  }, [
    props.open,
    props.package,
    prevOpen,
    trackDialogOpen,
    trackDialogClose,
    reset,
  ])

  const onSubmit = async (formData: Form) => {
    try {
      setLoadingUpdate(true)
      await props.onUpdate?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onUpdateSuccess?.()
    } catch (error) {
      setFormError(error)
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments_v2:shipment_message.failure_update'),
      )
      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onUpdateError?.(errorMessage)
    } finally {
      setLoadingUpdate(false)
    }
  }

  const onDelete = async () => {
    try {
      setLoadingDelete(true)
      await props.onDelete(props.package as ShipmentPackage)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onDeleteSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments_v2:shipment_message.failure_update'),
      )
      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onDeleteError?.(errorMessage)
    } finally {
      setLoadingDelete(false)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <DialogTitle type="extended" sx={{ pb: 2 }}>
        {props.package?.shipmentpackageid
          ? t('shipments_v2:packages.edit_package')
          : t('shipments_v2:packages.add_package')}
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
          <TextField
            error={!!formState?.errors?.shipmentpackageid}
            helperText={formState?.errors?.shipmentpackageid?.message}
            fullWidth
            required
            label={t('shipments_v2:shipment_form.package_id')}
            {...register('shipmentpackageid', {
              setValueAs: (v) => Number(v),
              required: t('shared:validation.field_required', {
                field: t('shipments_v2:shipment_form.package_id'),
              }),
              validate: (v) =>
                Number.isNaN(v) ? t('shipments:error.number_is_invalid') : true,
            })}
            sx={{ mb: 2 }}
          />

          <TextField
            error={!!formState?.errors?.externalid}
            fullWidth
            label={t('shipments_v2:shipment_form.externalid')}
            {...register('externalid')}
            sx={{ mb: 2 }}
          />

          <TextField
            error={!!formState?.errors?.barcode}
            fullWidth
            label={t('shipments_v2:shipment_form.barcode')}
            {...register('barcode')}
            sx={{ mb: 2 }}
          />

          <TextField
            error={!!formState?.errors?.comment}
            fullWidth
            label={t('shipments_v2:shipment_form.comment')}
            {...register('comment')}
            sx={{ mb: 2 }}
          />

          {props.shipmentState === ShipmentStateV2.New && (
            <LoadingButton
              color="error"
              fullWidth
              sx={{ mt: 2 }}
              onClick={onDelete}
              disabled={isLoading}
              loading={loadingDelete}
            >
              {t('shipments_v2:packages.delete_package')}
            </LoadingButton>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            { name: `${dialogName}-dialog-cancel` },
            props.onClose,
          )}
          color="secondary"
          size="small"
          type="button"
          disabled={isLoading}
        >
          {t('shared:action.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          onClick={trackButtonClickEvent({
            name: `${dialogName}-dialog-save`,
          })}
          disabled={isLoading}
          data-testid="dialog-submit"
          loading={loadingUpdate}
          form={formId}
        >
          {props.isNewShipment
            ? t('shared:action.ok')
            : t('shared:action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
