import { Chip, Stack, SxProps } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { UserRole } from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { Row } from '.'

type RenderProps = {
  allWarehouses?: Row['allWarehouses']
  role?: Row['role']
}

export const WarehouseRenderer = (
  params: GridRenderCellParams<RenderProps>,
) => (
  <WarehouseChips
    warehouses={params.row.allWarehouses}
    role={params.row.role}
    sx={{ flexWrap: 'nowrap' }}
  />
)

type Props = {
  warehouses?: Row['allWarehouses'] | null
  sx?: SxProps
  role?: Row['role']
}

export const WarehouseChips = (props: Props) => {
  const { warehouses, sx, role } = props
  const { t } = useTranslation(['users'])
  const isAdmin = role === UserRole.Admin

  // Don't show warehouse chips if the user doesn't have a role
  if (!role) return

  return (
    <Stack
      direction="row"
      gap={0.5}
      py={0.5}
      alignItems="center"
      height="100%"
      sx={{ flexWrap: 'wrap', ...sx }}
    >
      {isAdmin ? (
        <Chip
          data-testid="warehouse-chip"
          color="black"
          size="small"
          label={t('users:all')}
        />
      ) : (
        warehouses?.map((warehouse) => (
          <Chip
            data-testid="warehouse-chip"
            key={warehouse.id}
            size="small"
            label={warehouse.name}
          />
        ))
      )}
    </Stack>
  )
}
