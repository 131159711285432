import { RouteLeavingGuard } from '../route-leaving-guard'
import { containsDirtyFields } from '../../utils/contains-dirty-fields'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction } from 'react'
import { FieldValues, FormState } from 'react-hook-form'

type Props = {
  formState: FormState<FieldValues>
  setOriginalDialogOpen: Dispatch<SetStateAction<boolean>>
}

export const DialogRouteLeavingGuard = ({
  formState,
  setOriginalDialogOpen,
}: Props) => {
  const { t } = useTranslation('shared')
  const { dirtyFields, isSubmitSuccessful, isSubmitting } = formState

  const hasDirtyFields = containsDirtyFields(dirtyFields)
  const when = hasDirtyFields && !isSubmitSuccessful && !isSubmitting

  return (
    <RouteLeavingGuard
      when={when}
      title={t('unsaved_changes_in_dialog.title')}
      text={t('unsaved_changes_in_dialog.description')}
      cancelLabel={t('unsaved_changes_in_dialog.stay')}
      confirmLabel={t('unsaved_changes_in_dialog.leave')}
      onStateChange={(isBlocked) => setOriginalDialogOpen(!isBlocked)}
    />
  )
}
