import { List, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { GetJournalEntriesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { ListItemAccordion } from '../../../../components/list-item-accordion'
import { ListItemCodeBlock } from '../../../../components/list-item-code-block'
import { stripTypename } from '@apollo/client/utilities'

type Props = {
  journal?: Extract<
    ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>['data'],
    { __typename?: 'JournalReport' }
  >
}

export const ReportDetails = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal } = props

  return (
    <>
      <ListItemSkeleton
        hide={!journal?.zreportid}
        secondaryAction={<ListItemText>{journal?.zreportid}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-zreportid"
      >
        <ListItemText>{t('journals:zreport_id')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.manufacturerid}
        childrenSkeleton
        divider
        data-testid="journal-manufacturerid"
      >
        <ListItemText secondary={journal?.manufacturerid}>
          {t('journals:manufacturer_id')}
        </ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.eshopid}
        secondaryAction={<ListItemText>{journal?.eshopid}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-eshopid"
      >
        <ListItemText>{t('journals:eshop_id')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.store_id}
        secondaryAction={<ListItemText>{journal?.store_id}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-store-id"
      >
        <ListItemText>{t('journals:store_id')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.store_name}
        secondaryAction={<ListItemText>{journal?.store_name}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-store-name"
      >
        <ListItemText>{t('journals:store_name')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.companyid}
        secondaryAction={<ListItemText>{journal?.companyid}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-company-id"
      >
        <ListItemText>{t('journals:company_id')}</ListItemText>
      </ListItemSkeleton>

      <ListItemSkeleton
        hide={!journal?.company}
        secondaryAction={<ListItemText>{journal?.company}</ListItemText>}
        childrenSkeleton
        divider
        data-testid="journal-company-name"
      >
        <ListItemText>{t('journals:company_name')}</ListItemText>
      </ListItemSkeleton>

      <ListItemAccordion
        aria-controls="journal-report-data"
        summary={
          <ListItemSkeleton>
            <ListItemText>{t('journals:data')}</ListItemText>
          </ListItemSkeleton>
        }
      >
        <List>
          <ListItemCodeBlock
            code={JSON.stringify(stripTypename(journal || {}), null, 2)}
          />
        </List>
      </ListItemAccordion>
    </>
  )
}
