import { useQuery } from '@apollo/client'
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material'
import { Link, SectionHeader } from '@sitoo/mui-components'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ProductViewPanelState } from '..'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetProductQuery,
  AllCustomAttributesDocument,
  GetCategoriesDocument,
} from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'
import { DEFAULT_PRICELIST_ID, MAX_NUM_REQUEST } from '../../../utils/constants'
import { formatCategoryName } from '../../../utils/format/category'
import { forceHttps } from '../../../utils/format/url'

type InformationTabProps = {
  product?: GetProductQuery['product']
  isLoading?: boolean
  selectedProduct: ProductViewPanelState
}

export const InformationTab = ({
  product,
  isLoading,
  selectedProduct,
}: InformationTabProps) => {
  const { t } = useTranslation(['products'])

  const { data: allCustomAttributesData } = useQuery(
    AllCustomAttributesDocument,
    {
      variables: {
        customAttributeSiteId: selectedProduct.siteId,
      },
    },
  )
  const allCustomAttributes = allCustomAttributesData?.allCustomAttributes || []

  const { data: allCategories } = useQuery(GetCategoriesDocument, {
    variables: { num: MAX_NUM_REQUEST, categorySiteId: selectedProduct.siteId },
  })

  const { formatCurrency } = useMoney()
  const productCustomAttributes: { title: string; value: string | number }[] =
    []

  if (product?.customattributes) {
    const customAttributes = product?.customattributes as Record<
      string,
      string | number
    >

    for (const key in customAttributes) {
      const customAttribute = allCustomAttributes.find((c) => c.id === key)
      productCustomAttributes.push({
        title: customAttribute?.title || key,
        value: customAttributes[key] || '-',
      })
    }
  }

  return (
    <List>
      <ListItemSkeleton
        secondaryAction={
          <ListItemText
            data-testid="product-price-value"
            primary={formatCurrency(
              product?.pricelistitems?.find(
                (x) => x.pricelistid === DEFAULT_PRICELIST_ID,
              )?.moneyprice ||
                product?.moneyprice ||
                '',
            )}
          />
        }
        isLoading={isLoading}
        divider
      >
        <ListItemText
          data-testid="product-price-label"
          primary={t('products:price')}
        />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.sku}
        isLoading={isLoading}
        divider
      >
        <Tooltip title={product?.sku} placement="bottom-start">
          <ListItemText
            data-testid="product-sku"
            sx={{ whiteSpace: 'nowrap' }}
            primary={t('products:sku')}
            secondary={product?.sku || '-'}
          />
        </Tooltip>
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.barcode}
        secondaryAction={<ListItemText />}
        isLoading={isLoading}
        divider
      >
        <ListItemText
          data-testid="product-barcode"
          primary={t('products:barcode')}
          secondary={product?.barcode || '-'}
        />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.productgroup}
        isLoading={isLoading}
        divider
      >
        <ListItemText
          data-testid="product-group-field"
          primary={t('products:product_group')}
          secondary={
            product?.productgroup
              ? `${product.productgroup.name || ''} ${
                  product.productgroup.value || 0
                }%`
              : '-'
          }
        />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.manufacturer?.name}
        secondaryAction={
          <ListItemText
            data-testid="brand-value"
            primary={product?.manufacturer?.name || '-'}
          />
        }
        isLoading={isLoading}
        divider
      >
        <ListItemText data-testid="brand-label" primary={t('products:brand')} />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.skumanufacturer}
        secondaryAction={
          <ListItemText
            data-testid="brand-sku-value"
            primary={product?.skumanufacturer || '-'}
          />
        }
        isLoading={isLoading}
        divider
      >
        <ListItemText
          data-testid="brand-sku-label"
          primary={t('products:manufacturer_sku')}
        />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.manufacturerurl}
        secondaryAction={
          <ListItemText
            primary={
              product?.manufacturerurl ? (
                <Link
                  data-testid="brand-url-value"
                  href={forceHttps(product.manufacturerurl)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {product?.manufacturerurl}
                </Link>
              ) : (
                '-'
              )
            }
          />
        }
        isLoading={isLoading}
        divider
      >
        <ListItemText
          data-testid="brand-url-label"
          primary={t('products:manufacturer_url')}
        />
      </ListItemSkeleton>
      <ListItemSkeleton
        isLoading={isLoading}
        hide={
          !isLoading &&
          !formatCategoryName(
            product?.defaultcategoryid,
            allCategories?.categories.items,
          )
        }
        divider
      >
        <Stack direction="row" width="100%" spacing={5}>
          <ListItemText
            data-testid="main-category-field"
            sx={{ whiteSpace: 'nowrap' }}
            primary={t('products:main_category')}
          />

          <ListItemText
            sx={{ textAlign: 'right' }}
            primary={
              formatCategoryName(
                product?.defaultcategoryid,
                allCategories?.categories.items,
              ) || '-'
            }
          />
        </Stack>
      </ListItemSkeleton>
      <ListItemSkeleton
        isLoading={isLoading}
        hide={
          !isLoading &&
          !product?.categories
            ?.map((category) =>
              formatCategoryName(category, allCategories?.categories.items),
            )
            .join('')
        }
      >
        <Stack direction="row" width="100%" spacing={5}>
          <ListItemText
            data-testid="additional-categories-field"
            sx={{ whiteSpace: 'nowrap' }}
            primary={t('products:additional_categories')}
          />

          <ListItemText
            sx={{ textAlign: 'right' }}
            primary={
              product?.categories
                ?.map((category) =>
                  formatCategoryName(category, allCategories?.categories.items),
                )
                .join(', ') || '-'
            }
          />
        </Stack>
      </ListItemSkeleton>
      <ListItem
        disablePadding
        sx={{
          minHeight: 'auto',
          display:
            !isLoading &&
            productCustomAttributes.length === 0 &&
            !product?.custom1 &&
            !product?.custom2 &&
            !product?.custom3 &&
            !product?.custom4 &&
            !product?.custom5
              ? 'none'
              : 'flex',
        }}
      >
        <SectionHeader>
          {t('products:view_panel.additional_information')}
        </SectionHeader>
      </ListItem>
      {productCustomAttributes.map((c, i) => (
        <Fragment key={i}>
          <ListItemSkeleton
            data-testid="custom-attribute"
            secondaryAction={<ListItemText primary={c.value} />}
            isLoading={isLoading}
          >
            <ListItemText primary={c.title} />
          </ListItemSkeleton>
          <Divider />
        </Fragment>
      ))}
      <ListItemSkeleton
        hide={!isLoading && !product?.custom1}
        secondaryAction={<ListItemText primary={product?.custom1 || '-'} />}
        isLoading={isLoading}
        divider
      >
        <ListItemText primary={t('products:custom', { number: 1 })} />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.custom2}
        secondaryAction={<ListItemText primary={product?.custom2 || '-'} />}
        isLoading={isLoading}
        divider
      >
        <ListItemText primary={t('products:custom', { number: 2 })} />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.custom3}
        secondaryAction={<ListItemText primary={product?.custom3 || '-'} />}
        isLoading={isLoading}
        divider
      >
        <ListItemText primary={t('products:custom', { number: 3 })} />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.custom4}
        secondaryAction={<ListItemText primary={product?.custom4 || '-'} />}
        isLoading={isLoading}
        divider
      >
        <ListItemText primary={t('products:custom', { number: 4 })} />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={!isLoading && !product?.custom5}
        secondaryAction={<ListItemText primary={product?.custom5 || '-'} />}
        isLoading={isLoading}
      >
        <ListItemText primary={t('products:custom', { number: 5 })} />
      </ListItemSkeleton>
    </List>
  )
}
