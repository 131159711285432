import { Box, Divider } from '@mui/material'

type FilterViewPanelFooterProps = {
  children?: React.ReactNode
}

export const FilterViewPanelFooter = (props: FilterViewPanelFooterProps) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
      }}
    >
      <Divider />
      <Box
        sx={{
          p: 2,
          bgcolor: (theme) => theme.palette.background.paper,
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}
