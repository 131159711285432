import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { DEFAULT_PRODUCT_STATE } from '../../../utils/constants'
import { SelectInput } from '../../../inputs/select-input'
import { useForm } from 'react-hook-form'

export type ProductState = 'active' | 'all'

type QueryState = {
  state?: ProductState
}

type Form = {
  state?: ProductState
}

export const ProductStateFilter = () => {
  const { t } = useTranslation(['products', 'shared'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'state'

  const [queryParams, setQueryParams] = useStateParams<QueryState>()

  const state = ['active', 'all'].includes(queryParams[filterKey] as string)
    ? queryParams[filterKey]
    : DEFAULT_PRODUCT_STATE

  const options = useMemo(
    () => [
      { name: t('shared:label.active'), value: 'active' },
      { name: t('shared:label.all'), value: 'all' },
    ],
    [t],
  )

  const { control, setValue, watch } = useForm<Form>({
    values: { state },
    defaultValues: { state: DEFAULT_PRODUCT_STATE },
  })

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (state === 'active') {
      setFilter(filterKey, {
        isDefault: true,
        label: t('products:state_filter_tag_label', {
          state: t('shared:label.active'),
        }),
        value: '',
      })
    }
    if (state === 'all') {
      setFilter(filterKey, {
        label: t('products:state_filter_tag_label', {
          state: t('shared:label.all'),
        }),
        value: state,
      })
    }
  }, [state, removeFilter, setFilter, t])

  useEffect(() => {
    const subscribe = watch(({ state }) => {
      setQueryParams({ [filterKey]: state })
    })

    return () => subscribe.unsubscribe()
  }, [options, removeFilter, setFilter, setQueryParams, t, watch])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setValue('state', DEFAULT_PRODUCT_STATE)
      }
    })

    return () => unsubscribe()
  }, [setValue, subscribeOnResetFilter])

  return (
    <SelectInput
      name="state"
      control={control}
      options={options}
      label={t('products:state')}
    />
  )
}
