import { ExtendableMetadata, TrackAction } from './types'

type DialogMetadata = ExtendableMetadata & {
  // dialogName: string
}

type TrackDialog = TrackAction<{ (metadata: DialogMetadata): void }>

export const trackDialogOpen: TrackDialog =
  (track, baseMetadata) => (metadata) => {
    void track(`DialogOpen`, {
      ...baseMetadata,
      ...metadata,

      category: 'Dialog',
      label: metadata.name,
    })
  }

export const trackDialogClose: TrackDialog =
  (track, baseMetadata) => (metadata) => {
    void track(`DialogClose`, {
      ...baseMetadata,
      ...metadata,

      category: 'Dialog',
      label: metadata.name,
    })
  }
