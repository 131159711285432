import { SxProps, Autocomplete, TextField } from '@mui/material'
import { Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'
import {
  FieldError,
  FieldValues,
  UseControllerProps,
  get,
  useController,
} from 'react-hook-form'

type Props = {
  disabled?: boolean
  sx?: SxProps
  innerRef?: Ref<HTMLElement>
}

export const TimeZonesInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, disabled, control, sx, innerRef } = props
  const { t } = useTranslation(['shared'])
  const { trackInputBlur, trackInputFocus } = useTracking()

  const fieldLabel = t('shared:time_zones.override_time_zone')

  const { field, formState } = useController({ name, control })

  const options = useMemo(
    () => [
      {
        label: t('shared:time_zones.use_system_time_zone'),
        value: null,
      },
      ...Intl.supportedValuesOf('timeZone').map((timeZone) => ({
        label: timeZone,
        value: timeZone,
      })),
    ],
    [t],
  )

  const formError = get(formState.errors, name) as FieldError

  const value = useMemo(() => {
    return options.find((option) => option.value === field.value) || options[0]
  }, [field.value, options])

  return (
    <Autocomplete
      ref={innerRef}
      autoHighlight
      fullWidth
      options={options}
      disabled={disabled}
      value={value}
      onChange={(_event, option) => field.onChange(option?.value || null)}
      getOptionLabel={(option) => option.label || ''}
      sx={sx}
      data-testid="time-zones-field"
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={fieldLabel}
            helperText={
              formError?.message || t('shared:time_zones.time_zone_description')
            }
            type="text"
            onFocus={trackInputFocus({ name: 'time-zones-field-focus' })}
            onBlur={trackInputBlur({ name: 'time-zones-field-blur' })}
            error={!!formError}
            inputProps={{
              ...params.inputProps,
              ['data-testid']: 'time-zones-input',
            }}
          />
        )
      }}
    />
  )
}
