import { ListItem, ListItemText, MenuItem, TextField } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { OrderLookupFilterOrderTransactionTypeEnum } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { ExchangeFilter } from './exchange-filter'
import { ReturnLookupMethodFilter } from './return-lookup-method-filter'
import { PaymentValidatedFilter } from './payment-validated-filter'

type QueryParamsState = {
  transactionType?: string
  exchange?: string
  returnMethod?: string | string[]
  paymentValidated?: string
}

export const TransactionTypeFilter = () => {
  const { t } = useTranslation(['orders', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'transactionType'
  const transactionTypes = useMemo(
    () =>
      Object.values(OrderLookupFilterOrderTransactionTypeEnum)
        .map((x) => ({
          value: x,
          label: t(`orders:order_lookup_filter_order_transaction_type.${x}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedTransactionType = transactionTypes.find(
    ({ value }) => value === queryParams.transactionType,
  )
    ? queryParams.transactionType
    : ''

  const isReturn =
    selectedTransactionType ===
      OrderLookupFilterOrderTransactionTypeEnum.Returns ||
    selectedTransactionType ===
      OrderLookupFilterOrderTransactionTypeEnum.ReturnsValidated ||
    selectedTransactionType ===
      OrderLookupFilterOrderTransactionTypeEnum.ReturnsUnvalidated

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    if (!isReturn) {
      setQueryParams(
        {
          exchange: undefined,
          returnMethod: undefined,
          paymentValidated: undefined,
        },
        hasApply !== true,
      )
    }
  }, [hasApply, isReturn, setQueryParams])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const transactionType = transactionTypes?.find(
      ({ value }) => value === selectedTransactionType,
    )

    if (transactionType) {
      setFilter(filterKey, {
        label: t('orders:transaction_type_filter_tag', {
          transactionType: transactionType.label,
        }),
        value: transactionType.value,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, selectedTransactionType, transactionTypes, t])

  return (
    <>
      <ListItem
        sx={{
          boxShadow: (theme) =>
            isReturn
              ? `8px -4px 0px -4px ${theme.palette.blueBase} inset`
              : undefined,
        }}
      >
        <TextField
          data-testid="transaction-type-field"
          onChange={(event) =>
            setQueryParams(
              { [filterKey]: event.target.value || undefined },
              hasApply !== true,
            )
          }
          value={selectedTransactionType || ''}
          SelectProps={{
            displayEmpty: true,
          }}
          select // tell TextField to render select
          label={t('orders:transaction_type')}
        >
          <MenuItem
            data-testid="transaction-type-selector"
            value={''}
            divider
            onClick={trackButtonClickEvent({
              name: 'order-search-filter-select-transaction-type',
              transactionType: 'all',
            })}
          >
            <ListItemText>{t('shared:label.all')}</ListItemText>
          </MenuItem>
          {transactionTypes?.map((transactionType) => (
            <MenuItem
              data-testid="transaction-type-option"
              key={transactionType.value}
              value={transactionType.value || ''}
              divider
              onClick={trackButtonClickEvent({
                name: 'order-search-filter-select-transaction-type',
                transactionType: transactionType.value,
              })}
            >
              <ListItemText>{transactionType.label}</ListItemText>
            </MenuItem>
          ))}
        </TextField>
      </ListItem>

      <ListItem
        sx={{
          display: isReturn ? undefined : 'none',
          boxShadow: (theme) =>
            `8px -4px 0px -4px ${theme.palette.divider} inset`,
        }}
      >
        <ExchangeFilter />
      </ListItem>

      <ListItem
        sx={{
          display: isReturn ? undefined : 'none',
          boxShadow: (theme) =>
            `8px -4px 0px -4px ${theme.palette.divider} inset`,
        }}
      >
        <ReturnLookupMethodFilter />
      </ListItem>

      <ListItem
        sx={{
          display: isReturn ? undefined : 'none',
          boxShadow: (theme) =>
            `8px -4px 0px -4px ${theme.palette.divider} inset`,
        }}
      >
        <PaymentValidatedFilter />
      </ListItem>
    </>
  )
}
