import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../../hooks/tracking'

type Props = {
  open: boolean
  dataTestid?: string
  onClose?(): void
  onSuccess?(): void
  onError?(): void
}

export const ActivationCodeConfirmationDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'cash_registers'])
  const dialogName = 'activation-code-confirmation'
  const isLoading = false
  const { trackDialogOpen, trackDialogClose, trackButtonClick } = useTracking()
  const prevOpen = usePrevious(props.open)

  const onConfirm = () => {
    trackButtonClick({ name: `${dialogName}-confirm` })
    props.onSuccess?.()
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={onConfirm}
      title={t('cash_registers:activation_code.confirmation_title')}
      text={t('cash_registers:activation_code.confirmation_description')}
      mainActionLabel={t('shared:action.request')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
