import { useQuery } from '@apollo/client'
import { Radio, RadioGroup } from '@mui/material'
import { FormFieldset, RadioGroupLabel } from '@sitoo/mui-components'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { AllStoreTagsDocument } from '../../../../generated/graphql'
import { StoreTagsInput } from '../../../../inputs/store-tags-input'

enum Selection {
  AllStores,
  CustomSelection,
}

export const StoreTagsField = () => {
  const { t } = useTranslation('campaigns')
  const { loading } = useQuery(AllStoreTagsDocument)
  const { getValues, setValue, unregister, control } =
    useFormContext<CampaignFormContext>()

  const [selection, setSelection] = useState<Selection>(
    getValues('campaign.tags')?.length
      ? Selection.CustomSelection
      : Selection.AllStores,
  )

  const [persistedData, setPersistedData] = useState(
    {} as Record<Selection, { tags: string[] }>,
  )

  const onSelectionChange = useCallback(
    (_event: React.SyntheticEvent<HTMLInputElement>, value: string) => {
      setPersistedData((state) => ({
        ...state,
        [selection]: { tags: getValues('campaign.tags') },
      }))

      const selectionValue = Number(value) as Selection

      if (Selection.AllStores === selectionValue) {
        setSelection(Selection.AllStores)
        unregister(['campaign.tags'])
      } else if (Selection.CustomSelection === selectionValue) {
        setSelection(Selection.CustomSelection)
        setValue(
          'campaign.tags',
          persistedData[Selection.CustomSelection]?.tags,
        )
      }
    },
    [getValues, persistedData, selection, setValue, unregister],
  )

  return (
    <FormFieldset
      isLoading={loading}
      sx={{
        '.MuiFormFieldset-Paper': {
          p: 0,
        },
      }}
    >
      <RadioGroup value={selection} onChange={onSelectionChange}>
        <RadioGroupLabel
          divider
          label={t('campaign_form.tags_selection_all_label')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio
              value={Selection.AllStores}
              data-testid="campaign-store-selection-all-field"
            />
          }
        />

        <RadioGroupLabel
          label={t('campaign_form.tags_selection_custom_label')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio
              value={Selection.CustomSelection}
              data-testid="campaign-store-selection-custom-field"
            />
          }
        >
          <StoreTagsInput
            name="campaign.tags"
            freeSolo
            control={control}
            sx={{ mb: 2 }}
            helperText={t('campaign_form.tags_selection_custom_description')}
          />
        </RadioGroupLabel>
      </RadioGroup>
    </FormFieldset>
  )
}
