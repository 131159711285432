import { Box, Dialog, Stack } from '@mui/material'
import { Pagination, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon } from '@sitoo/mui-components'
import { SwiperOverrides } from '../swiper'
import { useTracking } from '../../hooks/tracking'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'

type ViewPanelImageProps = {
  open: boolean
  onClose(): void
  imageUrls: string[]
  activeIndex: number
}

export const ImageViewerDialog = (props: ViewPanelImageProps) => {
  const { open, activeIndex } = props
  const dialogName = 'image-viewer'
  const prevOpen = usePrevious(props.open)
  const { trackDialogOpen, trackDialogClose } = useTracking()

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          background: 'transparent',
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },
      }}
    >
      <Box
        sx={{
          '.swiper-wrapper': {
            alignItems: 'center',
            paddingTop: '10vh',
            paddingBottom: '10vh',
            height: '80vh',
          },
          '--swiper-theme-color': (theme) => theme.palette.blue40,
          '--swiper-pagination-bullet-width': (theme) => theme.spacing(1.25),
          '--swiper-pagination-bullet-height': (theme) => theme.spacing(1.25),
          '--swiper-pagination-bullet-inactive-opacity': 1,
          '--swiper-pagination-bullet-inactive-color': (theme) =>
            theme.palette.white,
          '.swiper-button-next, .swiper-button-prev': {
            fontSize: (theme) => theme.spacing(3),
            color: (theme) => theme.palette.white,
          },
          ...SwiperOverrides,
        }}
      >
        <Swiper
          modules={[Navigation, Pagination]}
          navigation={{
            nextEl: `.${dialogName}-swiper-button-next`,
            prevEl: `.${dialogName}-swiper-button-prev`,
          }}
          slidesPerView={1}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 5,
            el: `.${dialogName}-swiper-pagination`,
          }}
          onSwiper={(swiper) => swiper.slideTo(activeIndex, 0)}
        >
          <Stack
            spacing={2}
            direction="row"
            sx={{
              position: 'absolute',
              zIndex: 1,
              cursor: 'pointer',
              fontSize: (theme) => theme.spacing(4),
              color: (theme) => theme.palette.white,
              top: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
            }}
          >
            <CloseIcon onClick={onClose} fontSize="medium" />
          </Stack>
          <ArrowRightIcon
            className={`swiper-button-next ${dialogName}-swiper-button-next`}
            fontSize="medium"
          />
          <ArrowLeftIcon
            className={`swiper-button-prev ${dialogName}-swiper-button-prev`}
            fontSize="medium"
          />
          {props.imageUrls.map((url, index) => (
            <SwiperSlide key={`${url}-${index}`}>
              <Box
                component="img"
                src={url}
                sx={{
                  display: 'block',
                  margin: 'auto',
                  height: '100%',
                  width: '100%',
                  maxWidth: '80%',
                  objectFit: 'contain',
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Box
          sx={{
            height: '10vh',
            display: 'grid',
            justifyContent: 'center',
            alignItems: 'center',
            gridAutoFlow: 'column',

            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1,
          }}
        >
          <div
            className={`${dialogName}-swiper-pagination swiper-pagination`}
          />
        </Box>
      </Box>
    </Dialog>
  )
}
