import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ProductViewPanelState } from '..'
import { DataGridImage } from '../../../components/data-grid-image'
import { SectionHeader } from '@sitoo/mui-components'
import { GetRelatedProductsDocument } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { Fragment } from 'react'
import { useQuery } from '@apollo/client'

type RelatedTabProps = {
  selectedProduct: ProductViewPanelState

  onDetailProduct: (
    productId: number,
    siteId?: number,
    previous?: ProductViewPanelState,
  ) => void
}

export const RelatedTab = ({
  selectedProduct,
  onDetailProduct,
}: RelatedTabProps) => {
  const { t } = useTranslation(['products'])
  const { trackButtonClickEvent } = useTracking()

  const { data: productData, loading } = useQuery(GetRelatedProductsDocument, {
    variables: {
      id: selectedProduct.productId,
      productSiteId: selectedProduct.siteId,
    },
    skip: selectedProduct.productId === 0,
  })

  const product = productData?.product

  return (
    <List
      sx={{
        '.MuiListItemAvatar-root': {
          marginRight: 0,
        },
      }}
    >
      {loading &&
        [1, 2, 3].map((_, index) => (
          <Fragment key={index}>
            <ListItemSkeleton
              sx={{
                background: (theme) => theme.palette.background.default,
                padding: (theme) => theme.spacing(1.5, 2),
                minHeight: 0,
              }}
              isLoading={loading}
            >
              <SectionHeader />
            </ListItemSkeleton>
            <ListItemSkeleton isLoading={loading} secondaryAction="" />
          </Fragment>
        ))}

      {!loading && !!product?.accessoriesProducts?.length && (
        <>
          <ListItemSkeleton
            sx={{
              background: (theme) => theme.palette.background.default,
              p: 0,
              minHeight: 0,
            }}
          >
            <SectionHeader>{t('products:accessories')}</SectionHeader>
          </ListItemSkeleton>
          {product.accessoriesProducts.map((p, i) => (
            <Fragment key={p.id}>
              <ListItemButton
                data-testid="accessories-product-button"
                onClick={trackButtonClickEvent(
                  {
                    name: 'product-panel-related-tab-detail-product-accessories',

                    productId: p.id,
                  },
                  () =>
                    onDetailProduct(
                      p.id,
                      selectedProduct.siteId,
                      selectedProduct,
                    ),
                )}
              >
                <ListItemAvatar
                  sx={{
                    marginRight: (t) => t.spacing(2),
                  }}
                >
                  <DataGridImage
                    src={p.productImages?.[0] ? p.productImages[0].fileUrl : ''}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={p.title || '-'}
                  primaryTypographyProps={{
                    sx: {
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    },
                  }}
                  secondary={
                    p.variant?.[0]
                      ? `${p.variant[0].name || ''} (${
                          p.variant[0].value || ''
                        })`
                      : ''
                  }
                />
              </ListItemButton>
              {i + 1 < product.accessoriesProducts!.length && <Divider />}
            </Fragment>
          ))}
        </>
      )}
      {!loading && !!product?.relatedProducts?.length && (
        <>
          <ListItemSkeleton
            sx={{
              background: (theme) => theme.palette.background.default,
              p: 0,
              minHeight: 0,
            }}
          >
            <SectionHeader>{t('products:related')}</SectionHeader>
          </ListItemSkeleton>
          {product.relatedProducts.map((p, i) => (
            <Fragment key={p.id}>
              <ListItemButton
                data-testid="related-product-button"
                onClick={trackButtonClickEvent(
                  {
                    name: 'product-panel-related-tab-detail-product-related',

                    productId: p.id,
                  },
                  () =>
                    onDetailProduct(
                      p.id,
                      selectedProduct.siteId,
                      selectedProduct,
                    ),
                )}
              >
                <ListItemAvatar
                  sx={{
                    marginRight: (t) => t.spacing(2),
                  }}
                >
                  <DataGridImage
                    src={p.productImages?.[0] ? p.productImages[0].fileUrl : ''}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={p.title || '-'}
                  primaryTypographyProps={{
                    sx: {
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    },
                  }}
                  secondary={
                    p.variant?.[0]
                      ? `${p.variant[0].name || ''} (${
                          p.variant[0].value || ''
                        })`
                      : ''
                  }
                />
              </ListItemButton>
              {i + 1 < product.relatedProducts!.length && <Divider />}
            </Fragment>
          ))}
        </>
      )}
      {!loading && !!product?.similarProducts?.length && (
        <>
          <ListItem disablePadding sx={{ minHeight: 'auto' }}>
            <SectionHeader>{t('products:similar')}</SectionHeader>
          </ListItem>
          {product.similarProducts.map((p, i) => (
            <Fragment key={p.id}>
              <ListItemButton
                data-testid="similar-product-button"
                onClick={trackButtonClickEvent(
                  {
                    name: 'product-panel-related-tab-detail-product-similar',

                    productId: p.id,
                  },
                  () =>
                    onDetailProduct(
                      p.id,
                      selectedProduct.siteId,
                      selectedProduct,
                    ),
                )}
              >
                <ListItemAvatar
                  sx={{
                    marginRight: (t) => t.spacing(2),
                  }}
                >
                  <DataGridImage
                    src={p.productImages?.[0] ? p.productImages[0].fileUrl : ''}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={p.title || '-'}
                  primaryTypographyProps={{
                    sx: {
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    },
                  }}
                  secondary={
                    p.variant?.[0]
                      ? `${p.variant[0].name || ''} (${
                          p.variant[0].value || ''
                        })`
                      : ''
                  }
                />
              </ListItemButton>
              {i + 1 < product.similarProducts!.length && <Divider />}
            </Fragment>
          ))}
        </>
      )}
    </List>
  )
}
