import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  GetUsersDocument,
  MeDocument,
  MfaCodeDocument,
  SetMfaDocument,
} from '../../generated/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { DuplicateIcon } from '@sitoo/mui-components'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useSnackbar } from 'notistack'
import { useTracking } from '../../hooks/tracking'

type Form = {
  code: string
}

type Props = {
  accountId: number
  email: string
  backTitle: string
  onBack(): void
  onSuccess(): void
  mfaId?: string
}

export const MfaConfigure = (props: Props) => {
  const { t } = useTranslation(['login', 'shared'])
  const { email, accountId } = props
  const formId = 'mfa-configure'

  const [isSecretShown, setSecretShown] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { trackInputBlur, trackInputFocus } = useTracking()

  const { register, formState, setError, handleSubmit } = useForm<Form>()

  const { data, loading: fetchLoading } = useQuery(MfaCodeDocument, {
    variables: { email, accountId, mfaId: props.mfaId },
    skip: !email,
  })

  const [setMfaMutation, { loading: setLoading }] = useMutation(
    SetMfaDocument,
    { refetchQueries: [MeDocument, GetUsersDocument] },
  )

  const isLoading = fetchLoading || setLoading

  const secret = data?.mfaCode.secret || ''
  const mfaId = props.mfaId || data?.mfaCode.mfaId || ''
  const image = data?.mfaCode.image || ''

  const copyToClipboard = () => {
    void navigator.clipboard
      .writeText(secret)
      .then(() => enqueueSnackbar(t('shared:copied_to_clipboard')))
  }

  const onSubmit = (formData: Form) => {
    const { code } = formData

    void setMfaMutation({
      variables: { accountId, code, mfaId },
    }).then((response) => {
      const success = !!response.data?.setMfa

      if (success) {
        props.onSuccess()
      } else {
        setError('code', { message: t('login:error.invalid_otp_code') })
      }
    })
  }

  return (
    <Paper elevation={0} sx={{ p: 3, maxWidth: '388px' }}>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <Typography variant="displayMedium" sx={{ mb: 3 }}>
          {t('login:mfa_setup_title')}
        </Typography>

        <Typography>{t('login:mfa_setup_description')}</Typography>

        <Box
          dangerouslySetInnerHTML={{ __html: image }}
          sx={{
            mt: 2,
            mb: 1,
            minHeight: '176px',
            svg: {
              display: 'inline-block',
              border: (theme) => `1px solid ${theme.palette.divider}`,
            },
          }}
        />

        <Button
          color="tertiary"
          size="small"
          onClick={() => setSecretShown(!isSecretShown)}
          sx={{ mb: 2 }}
        >
          {isSecretShown ? t('login:hide_secret') : t('login:show_secret')}
        </Button>

        {isSecretShown && (
          <TextField
            type="text"
            multiline
            sx={{ mb: 3 }}
            value={secret}
            InputProps={{
              disabled: true,
              endAdornment: (
                <IconButton onClick={copyToClipboard}>
                  <DuplicateIcon />
                </IconButton>
              ),
            }}
          />
        )}

        <TextField
          sx={{ mb: 3 }}
          {...register('code', {
            required: t('shared:validation.field_required', {
              field: t('login:mfa_description'),
            }),
            validate: (value) =>
              value !== null && Number.isNaN(Number(value))
                ? t('shared:validation.field_invalid', {
                    field: t('login:mfa_description'),
                  })
                : true,
            onBlur: trackInputBlur({ name: 'login-mfa' }),
          })}
          error={!!formState.errors.code}
          helperText={formState.errors.code?.message}
          label={t('login:mfa_description')}
          autoComplete="one-time-code"
          inputProps={{ 'data-testid': 'mfa-input' }}
          onFocus={trackInputFocus({ name: 'login-mfa' })}
        />

        <Stack direction="row" justifyContent="end" gap={1}>
          <Button color="secondary" size="small" onClick={props.onBack}>
            {props.backTitle}
          </Button>

          <LoadingButton
            color="primary"
            size="small"
            type="submit"
            loading={isLoading}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(onSubmit)}
            form={formId}
          >
            {t('shared:action.next')}
          </LoadingButton>
        </Stack>
      </form>
    </Paper>
  )
}
