import { makeVar } from '@apollo/client'
import { DEFAULT_ORDER_ID } from '../../utils/constants'

export const orderViewPanelVar = makeVar({
  isOpen: false,
  orderId: DEFAULT_ORDER_ID,
})

export const orderFilterViewPanelVar = makeVar({
  isOpen: false,
})
