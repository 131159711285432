import { Button, ButtonProps } from '@mui/material'
import { MoreHorizontalIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'

type Props = ButtonProps & {
  dataTestid?: string
  active?: boolean
}

export const MoreButton = (props: Props) => {
  const { t } = useTranslation(['shared'])

  return (
    <Button
      color="secondary"
      className={props.active ? 'Mui-Active' : undefined}
      data-testid={props.dataTestid}
      endIcon={<MoreHorizontalIcon />}
      {...props}
    >
      {t('shared:action.more')}
    </Button>
  )
}
