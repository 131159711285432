import { FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'

const PRIORITIES = [
  {
    value: 1,
    label: 'campaigns:campaign_priority.highest_value',
  },
  {
    value: 2,
    label: 'campaigns:campaign_priority.high_value',
  },
  {
    value: 3,
    label: 'campaigns:campaign_priority.normal_value',
  },
  {
    value: 4,
    label: 'campaigns:campaign_priority.low_value',
  },
  {
    value: 5,
    label: 'campaigns:campaign_priority.lowest_value',
  },
] as const

export const PriorityField = () => {
  const { control } = useFormContext<CampaignFormContext>()
  const { t } = useTranslation(['campaigns', 'shared'])

  return (
    <Controller
      control={control}
      name="campaign.priority"
      render={({ field }) => (
        <>
          <InputLabel>{t('campaigns:campaign_form.priority_label')}</InputLabel>
          <Select
            value={field.value || PRIORITIES[2].value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
            inputProps={{ 'data-testid': 'campaign-priority-field' }}
          >
            {PRIORITIES.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {t(label, { priority: value })}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {t('campaigns:campaign_form.priority_description')}
          </FormHelperText>
        </>
      )}
    />
  )
}
