import {
  Button,
  Collapse,
  Divider,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { ChevronSmallDownIcon, ChevronSmallUpIcon } from '@sitoo/mui-components'
import {
  forwardRef,
  Fragment,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ProductViewPanelState } from '../..'
import { GetProductQuery } from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'

type SingleVariantSelectorProps = {
  selectedProduct: ProductViewPanelState
  variantGroup: { name: string; options: string[] }

  variants: { id: number; attributes: string[] }[]

  product?: GetProductQuery['product']
  onDetailProduct: (
    productId: number,
    siteId?: number,
    previous?: ProductViewPanelState,
  ) => void
}

type VariantSelectorButton = {
  children?: React.ReactNode
  isSelected?: boolean
  secondary?: React.ReactNode

  sx?: SxProps<Theme>
  onClick?: MouseEventHandler
}

export const VariantSelectorButton = forwardRef<
  HTMLButtonElement,
  VariantSelectorButton
>(function VariantSelectorButton(props, ref) {
  return (
    <Button
      data-testid="variant-selector"
      ref={ref}
      sx={{
        borderBottom: '2px solid',
        borderColor: (theme) =>
          props.isSelected ? theme.palette.blueBase : 'transparent',
        backgroundColor: (theme) =>
          props.isSelected
            ? theme.palette.blue20
            : theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column',
        padding: (theme) => theme.spacing(0.5),
        minHeight: (theme) => theme.spacing(6.25),
        ...props.sx,

        ':hover': { backgroundColor: (theme) => theme.palette.blue20 },
      }}
      variant="text"
      onClick={props.onClick}
    >
      {props.children}
      {props.secondary !== undefined && (
        <Typography variant="caption">{props.secondary}</Typography>
      )}
    </Button>
  )
})

export const SingleVariantSelector = (props: SingleVariantSelectorProps) => {
  const { t } = useTranslation('products')
  const buttonGridRef = useRef<HTMLDivElement>(null)
  const [isShowingAll, setIsShowingAll] = useState(false)
  const [calculateItemsPerRow, setCalculateItemsPerRow] = useState(3)
  const [buttonHeight, setButtonHeight] = useState(50)
  const { trackButtonClickEvent } = useTracking()

  const showHideVariants = () => {
    setIsShowingAll((p) => !p)
  }

  useEffect(() => {
    const totalSize = buttonGridRef.current?.clientWidth
    if (!totalSize) return

    let biggestWidth = 0
    let height = 0
    for (const [, child] of Array.from(
      buttonGridRef.current.childNodes.entries(),
    )) {
      if ((child as HTMLElement).clientWidth > biggestWidth) {
        biggestWidth = (child as HTMLElement).clientWidth
        height = (child as HTMLElement).offsetHeight
      }
    }

    setButtonHeight(height)
    const totalItemsPerRow = Math.floor(totalSize / biggestWidth)
    setCalculateItemsPerRow(totalItemsPerRow)
  }, [buttonGridRef])

  const collapsedRows = 3
  const itemsPerRow = Math.min(
    calculateItemsPerRow,
    props.variantGroup.options.length,
  )
  //  The minimum collapsed size should be either the "collapsedRows * buttonHeight" or in case that there's less than the amount of "collapsedRows"
  // it should be exact size of rows
  const collapsedSize = Math.min(
    collapsedRows * buttonHeight,
    Math.ceil(props.variantGroup.options.length / itemsPerRow) * buttonHeight,
  )
  const collapsedVariantsLimit = itemsPerRow * collapsedRows
  const variants = isShowingAll
    ? props.variantGroup.options
    : props.variantGroup.options.slice(0, collapsedVariantsLimit)

  return (
    <>
      <Stack
        ref={buttonGridRef}
        sx={{
          visibility: 'hidden',
          position: 'absolute',
          pointerEvents: 'none',
          // width: '100%',
          flexWrap: 'wrap',
        }}
        direction="row"
      >
        {props.variantGroup.options.map((v, i) => {
          return <VariantSelectorButton key={i}>{v}</VariantSelectorButton>
        })}
      </Stack>

      <Collapse in={isShowingAll} collapsedSize={collapsedSize}>
        <Stack
          direction="row"
          sx={{
            flexWrap: 'wrap',
          }}
        >
          {variants.map((v, i) => {
            const variantWithValue = props.variants?.find((variant) =>
              variant.attributes.includes(v),
            )

            if (!variantWithValue) return null

            return (
              <Fragment key={i}>
                {i + 1 > itemsPerRow && (i + 1) % itemsPerRow === 1 && (
                  <Divider sx={{ width: '100%' }} />
                )}
                <VariantSelectorButton
                  isSelected={props.product?.variant?.some(
                    (x) => x.name === props.variantGroup.name && x.value === v,
                  )}
                  //   secondary={i}
                  sx={{
                    width: `${100 / itemsPerRow}%`,
                  }}
                  onClick={trackButtonClickEvent(
                    {
                      name: 'product-panel-single-variant-selector-select',

                      variantId: variantWithValue.id,
                      variantAttributes: variantWithValue.attributes.join(', '),
                    },
                    () =>
                      props.onDetailProduct(
                        variantWithValue.id,
                        props.selectedProduct.siteId,
                      ),
                  )}
                >
                  {v}
                </VariantSelectorButton>
              </Fragment>
            )
          })}
        </Stack>
      </Collapse>

      {collapsedVariantsLimit < props.variantGroup.options.length && (
        <>
          <Divider />
          <Stack
            sx={{
              height: (theme) => theme.spacing(6),
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="text"
              color="primary"
              sx={{
                width: '100%',
                height: '100%',
              }}
              endIcon={
                isShowingAll ? (
                  <ChevronSmallUpIcon fontSize="medium" />
                ) : (
                  <ChevronSmallDownIcon fontSize="medium" />
                )
              }
              onClick={trackButtonClickEvent(
                { name: 'product-panel-single-variant-selector-show-more' },
                showHideVariants,
              )}
            >
              {isShowingAll ? t('view_panel.hide') : t('view_panel.show_all')}
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
