import { ListItemButton, Typography } from '@mui/material'
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArchiveShipmentDialog } from '../../archive-shipment-dialog'

export const ArchiveShipment = () => {
  const { t } = useTranslation(['shipments_v2'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()

  const selectedIds = Array.from(
    apiRef.current.getSelectedRows().keys(),
  ) as number[]

  const firstItem = selectedIds[0]
    ? (apiRef.current.getSelectedRows().get(selectedIds[0]) as GridRowModel<{
        archived: boolean
      }>)
    : undefined

  const setToArchived = !firstItem?.archived

  const onClose = () => {
    setIsDialogOpen(false)
  }

  const onSuccess = (archivedIds: number[]) => {
    if (archivedIds.length) {
      enqueueSnackbar(
        t('success_archive_shipment_snackbar', {
          count: archivedIds.length,
        }),
      )
    }

    onClose()
  }

  const onError = (archivedIds: number[]) => {
    if (archivedIds.length) {
      enqueueSnackbar(
        t('failure_archive_shipment_snackbar', {
          count: archivedIds.length,
        }),
        { variant: 'error' },
      )
    }
    onClose()
  }

  return (
    <>
      <ArchiveShipmentDialog
        open={isDialogOpen}
        setToArchived={setToArchived}
        onClose={onClose}
        selectedIds={selectedIds}
        onSuccess={onSuccess}
        onError={onError}
      />

      <ListItemButton onClick={() => setIsDialogOpen(true)}>
        <Typography variant="body02">
          {setToArchived
            ? t('view_panel.archive_shipment', { count: selectedIds.length })
            : t('view_panel.unarchive_shipment', { count: selectedIds.length })}
        </Typography>
      </ListItemButton>
    </>
  )
}
