export const stripEmptyProperties = <T extends Record<string, unknown>>(
  object: T,
): T => {
  for (const key in object) {
    const value = object[key]

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      stripEmptyProperties(value as Record<string, unknown>)
      continue
    }
  }
  return object
}
