import { nanoid } from 'nanoid'
import { useSearchParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { useAbsolutePath } from '../../../hooks/absolute-path'

export const usePrint = () => {
  const [searchParams] = useSearchParams()
  const generatePath = useAbsolutePath()
  const warehouseToId = searchParams.get('warehouseToId')
  const warehouseFromId = searchParams.get('warehouseFromId')

  const print = (skus?: string[]) => {
    const printId = nanoid()

    sessionStorage.setItem(
      printId,
      JSON.stringify({ warehouseToId, warehouseFromId, skus }),
    )

    window.open(
      generatePath(RootRoute.InStoreReplenishmentPrint, { id: printId }),
      '_blank',
    )
  }

  return print
}
