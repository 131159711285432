import { ChipProps, Chip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { PaymentState } from '../../../generated/graphql'

export const PaymentStateRenderer = (
  params: GridRenderCellParams<
    { paymentstateid?: PaymentState | null },
    PaymentStateProps
  >,
): React.ReactElement => (
  <PaymentStateTag stateId={params.row.paymentstateid || PaymentState.None} />
)

export type PaymentStateProps = {
  stateId?: PaymentState
}

export const PaymentStateTag = (props: PaymentStateProps) => {
  const { t } = useTranslation('orders')
  const { stateId } = props

  const getColor = (paymentStateId: PaymentState): ChipProps['color'] => {
    switch (paymentStateId) {
      case PaymentState.Pending:
      case PaymentState.Reserved:
        return 'orange'

      case PaymentState.Successful:
        return 'green'

      case PaymentState.Cancelled:
      case PaymentState.Failed:
        return 'red'

      default:
        return 'grayLight'
    }
  }

  if (!stateId) return null

  return (
    <Chip
      data-testid="payment-state-tag"
      size="small"
      color={getColor(stateId)}
      label={t(`payment_state.${stateId}`)}
    />
  )
}
