import {
  Box,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  ServiceOrderQuery,
  ServiceOrderState,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { BaseServiceOrderFormContext } from '../shared'

type Props = {
  serviceOrder?: ServiceOrderQuery['serviceOrder']
  isLoading: boolean
}

export const InfoField = ({ serviceOrder, isLoading }: Props) => {
  const { control } = useFormContext<BaseServiceOrderFormContext>()
  const { t } = useTranslation(['service_orders'])
  const { formatDate } = useLocalizedDate()
  const serviceOrderStates: ServiceOrderState[] = useMemo(() => {
    return [
      ServiceOrderState.New,
      ServiceOrderState.InProgress,
      ServiceOrderState.ServiceDone,
      ServiceOrderState.Closed,
      ServiceOrderState.Cancelled,
    ]
  }, [])

  return (
    <FormFieldset
      label={t('service_order_form.info_fieldset')}
      sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
    >
      <ListItemSkeleton
        hide={isLoading}
        isLoading={isLoading}
        secondaryAction={<ListItemText>{serviceOrder?.id || '-'}</ListItemText>}
        data-testid="edit-service-order-id-field"
        divider
      >
        <ListItemText primary={t('id')} />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={isLoading}
        isLoading={isLoading}
        secondaryAction={
          <ListItemText>{formatDate(serviceOrder?.created || '')}</ListItemText>
        }
        data-testid="edit-service-order-created-field"
        divider
      >
        <ListItemText primary={t('created')} />
      </ListItemSkeleton>
      <ListItemSkeleton
        hide={isLoading}
        isLoading={isLoading}
        secondaryAction={
          <ListItemText>{serviceOrder?.owner.email || '-'}</ListItemText>
        }
        data-testid="edit-service-order-owner-field"
        divider
      >
        <ListItemText
          primary={t('service_order_form.owner')}
          secondary={serviceOrder?.owner.employee_id}
        />
      </ListItemSkeleton>

      <Box sx={{ p: 2 }}>
        <Controller
          control={control}
          name="state"
          render={({ field, fieldState: { error } }) => (
            <>
              <InputLabel>
                {t('service_orders:set_state_dialog.state')}
              </InputLabel>
              <Select
                value={field.value || ''}
                data-testid="edit-service-order-state-field"
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              >
                {serviceOrderStates.map((state) => (
                  <MenuItem value={state} key={state}>
                    {t(`service_orders:states.${state}`)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!error?.message}>
                {error?.message}
              </FormHelperText>
            </>
          )}
        />
      </Box>
    </FormFieldset>
  )
}
