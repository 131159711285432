import { useApolloClient } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemProductImage } from '../../../components/list-item-product-image'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetOrderDocument,
  GetOrderQuery,
  OrderDeliveryItemState,
  OrderDeliveryState,
  OrderState,
} from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { useMoney } from '../../../hooks/money'
import { ArrayElement } from '../../../utils/types'
import { DeliveryAddDialog, DeliveryItem } from './delivery-add-dialog'
import { SalesTaxes } from './sales-taxes'
import { formatAdditionalData } from '../../../utils/format/additional-data'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { OrderItemAdditionalData } from '../util'
import { OrderMoneySummary } from '../order-money-summary'

type Props = {
  isLoading?: boolean
  order?: GetOrderQuery['order']
  readOnly?: boolean
}

export const Details = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const { enqueueSnackbar } = useSnackbar()
  const [showAddDeliveryDialog, setShowAddDeliveryDialog] = useState(false)
  const apolloClient = useApolloClient()
  const { formatCurrency: baseFormatCurrency } = useMoney()
  const {
    modules: { writeOrders },
  } = useAuthorization()

  const formatCurrency = (cash: string | number) =>
    baseFormatCurrency(cash, { forceDecimals: true })

  const order = props.order

  const showSalesTax = order?.orderitems.some(
    (orderItem) => orderItem.salestaxes?.length,
  )

  const canAddDelivery =
    order?.orderstateid === OrderState.Open &&
    order?.orderDeliveryState !== OrderDeliveryState.Delivered &&
    writeOrders &&
    !props.readOnly

  const orderItems = Array.from(
    (order?.orderitems || [])
      .reduce((acc, item) => {
        if (typeof item.orderitemid !== 'number') return acc

        const groupedOrders = acc.get(item.orderitemid) || []
        acc.set(item.orderitemid, [...groupedOrders, item])

        return acc
      }, new Map<number, GetOrderQuery['order']['orderitems']>())
      .entries(),
  )

  const getRemainingAmount = (
    item: ArrayElement<GetOrderQuery['order']['orderitems']>,
    order: GetOrderQuery['order'],
  ) => {
    return (
      item.quantity -
      order.orderDelivery
        .filter((x) => !x.datecancelled)
        .flatMap((x) => x.orderdeliveryitems)
        .filter((x) => x.orderitemid === item.orderitemid)
        .reduce((p, c) => p + c.quantity, 0)
    )
  }

  const getDeliveryItems = (
    order: GetOrderQuery['order'] | undefined,
  ): DeliveryItem[] => {
    if (!order) return []

    return order.orderitems.map(
      (x): DeliveryItem => ({
        ...x,
        remainingQuantity: getRemainingAmount(x, order),
      }),
    )
  }

  const getItemDeliveryChip = (
    item: ArrayElement<GetOrderQuery['order']['orderitems']>,
    order: GetOrderQuery['order'] | undefined,
  ) => {
    const deliveryAmount = order?.orderDelivery
      .filter((x) => x.state === OrderDeliveryItemState.Delivered)
      .flatMap((x) => x.orderdeliveryitems)
      .filter((x) => x.orderitemid === item.orderitemid)
      .reduce((p, c) => p + c.quantity, 0)

    return (
      <Chip
        label={
          deliveryAmount === item.quantity
            ? t('orders:view_panel.delivered')
            : t('orders:view_panel.x_of_y_delivered', {
                delivered: deliveryAmount,
                total: item.quantity,
              })
        }
        size="small"
        data-testid="delivery-chip"
        color={deliveryAmount === item.quantity ? 'green' : 'orange'}
      />
    )
  }

  const getManualDiscount = (additionalData: OrderItemAdditionalData) => {
    if (
      Number(additionalData?.['orderitem-moneyrowdiscount']) === 0 ||
      !additionalData?.['reasoncode-manualdiscount']
    )
      return null

    return {
      reasonCode: additionalData?.['reasoncode-manualdiscount'],
      discount: Number(additionalData?.['orderitem-moneyrowdiscount']),
    }
  }

  const onSuccessInformationEditDialog = () => {
    void apolloClient.refetchQueries({
      include: [GetOrderDocument],
    })
    setShowAddDeliveryDialog(false)
    enqueueSnackbar(t('orders:add_delivery_dialog.success_update'))
  }

  const onErrorInformationEditDialog = () => {
    enqueueSnackbar(t('orders:add_delivery_dialog.failure_update'), {
      variant: 'error',
    })
  }

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="details">
        <SectionHeader sx={{ p: 0 }}>{t('orders:details')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          {props.isLoading && (
            <ListItemSkeleton
              isLoading={props.isLoading}
              secondaryAction="-"
              childrenSkeleton
            />
          )}
          {orderItems.map(([, items], i) => {
            const mainItem = items.find((x) => x.quantity !== 0)

            if (!mainItem) return

            const discountItem = items.find((x) => x.quantity === 0)
            const additionalData =
              mainItem.additionaldata as OrderItemAdditionalData

            const manualDiscount = getManualDiscount(additionalData)
            const manualPriceChange =
              additionalData?.['reasoncode-manualprice'] || null
            const reasonForRefund =
              additionalData?.['reasoncode-return'] || null
            const productUnitPrice =
              (Number(mainItem.moneyitemtotal_net) +
                Number(mainItem.moneyitemtotal_vat)) /
              Math.abs(mainItem.quantity)
            const productDescription = [
              mainItem.productattributes,
              mainItem.sku,
              manualDiscount || Math.abs(mainItem.quantity) !== 1
                ? `${Math.abs(mainItem.quantity)} x ${
                    manualDiscount
                      ? `${formatCurrency(productUnitPrice)} (${formatCurrency(
                          productUnitPrice +
                            Number(manualDiscount.discount) /
                              Math.abs(mainItem.quantity),
                        )})`
                      : formatCurrency(productUnitPrice)
                  }`
                : '',
              manualDiscount
                ? `${t('orders:view_panel.reason_code_manual_discount', {
                    code: manualDiscount.reasonCode,
                  })}`
                : '',
              manualPriceChange
                ? t('orders:view_panel.reason_code_manual_price', {
                    code: manualPriceChange,
                  })
                : '',
              reasonForRefund
                ? t('orders:view_panel.reason_code_return', {
                    code: reasonForRefund,
                  })
                : '',
              (mainItem.additionaldata as OrderItemAdditionalData)?.[
                'product-group-name'
              ],
            ]
              .filter(Boolean)
              .join('\n')

            const formattedAdditionalData = formatAdditionalData(additionalData)

            return (
              <Fragment key={`accordion-${mainItem.orderitemid}`}>
                <ListItemAccordion
                  aria-controls={`item-${i}`}
                  sx={{ mb: 1 }}
                  summary={
                    <ListItem data-testid={`order-item-${i}`}>
                      <ListItemProductImage
                        url={mainItem?.product?.images?.[0]}
                      />
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                      >
                        <Box sx={{ width: '100%', display: 'flex' }}>
                          <ListItemText
                            data-testid="receipt-item-details"
                            primary={
                              mainItem?.product?.title ||
                              mainItem?.productname ||
                              mainItem?.sku
                            }
                            secondaryTypographyProps={{
                              component: 'div',
                              sx: {
                                whiteSpace: 'pre-line',
                              },
                            }}
                            secondary={
                              <>
                                {productDescription}
                                <br />
                                {getItemDeliveryChip(mainItem, order)}
                              </>
                            }
                          />
                          <ListItemText
                            data-testid="receipt-item-price"
                            sx={{ flex: 'none' }}
                            primary={formatCurrency(
                              Number(mainItem.moneyitemtotal_net) +
                                Number(mainItem.moneyitemtotal_vat),
                            )}
                            secondary={
                              manualDiscount
                                ? t('orders:view_panel.original_price', {
                                    price: formatCurrency(
                                      Number(mainItem.moneyitemtotal_net) +
                                        Number(mainItem.moneyitemtotal_vat) +
                                        manualDiscount.discount,
                                    ),
                                  })
                                : undefined
                            }
                          />
                        </Box>
                        {discountItem && (
                          <>
                            <Box
                              sx={{
                                width: (theme) =>
                                  `calc(100% + ${theme.spacing(2)})`,
                                mr: -2,
                                mt: 0.5,
                              }}
                            >
                              <Divider />
                            </Box>
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            >
                              <ListItemText
                                sx={{ flexGrow: 1 }}
                                primary={
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: (theme) => theme.palette.orange70,
                                    }}
                                  >
                                    {discountItem.vouchername?.toUpperCase() ||
                                      discountItem.vouchercode?.toUpperCase() ||
                                      ''}
                                  </Typography>
                                }
                              />
                              <ListItemText
                                sx={{ flexGrow: 0 }}
                                primary={
                                  <Typography
                                    variant="caption"
                                    data-testid="order-total"
                                  >
                                    {formatCurrency(
                                      Number(discountItem.moneyitemtotal_net) +
                                        Number(discountItem.moneyitemtotal_vat),
                                    )}
                                  </Typography>
                                }
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    </ListItem>
                  }
                >
                  <ListItem>
                    <ListItemText
                      data-testid="additional-data"
                      primary={t('orders:view_panel.additional_data_label')}
                      secondary={formattedAdditionalData.map(([key, value]) => (
                        <Box
                          key={key}
                          component="span"
                          sx={{ display: 'block' }}
                        >
                          {key}: {value}
                        </Box>
                      ))}
                    />
                  </ListItem>
                </ListItemAccordion>
                <Divider />
              </Fragment>
            )
          })}

          {showSalesTax && (
            <>
              <SalesTaxes order={order} />
              <Divider />
            </>
          )}

          <OrderMoneySummary
            order={order}
            showSalesTax={showSalesTax}
            isLoading={props.isLoading}
          />

          {canAddDelivery && (
            <ListItemSkeleton
              divider
              sx={{
                boxShadow: (theme) => `0 -1px 0 ${theme.palette.white}`,
              }}
              isLoading={props.isLoading}
              childrenSkeleton
            >
              <Button
                color="primary"
                size="small"
                onClick={() => setShowAddDeliveryDialog(true)}
                data-testid="add-order-delivery"
                fullWidth
              >
                {t('orders:view_panel.add_delivery')}
              </Button>
              <DeliveryAddDialog
                open={showAddDeliveryDialog}
                onClose={() => setShowAddDeliveryDialog(false)}
                items={getDeliveryItems(order)}
                orderId={order?.orderid}
                warehouseId={order?.warehouseid}
                onSuccess={onSuccessInformationEditDialog}
                onError={onErrorInformationEditDialog}
              />
            </ListItemSkeleton>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
