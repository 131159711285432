import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { JournalPrintout as JournalPrintoutType } from '../../../generated/graphql'
import { JournalItem } from '.'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { JournalDivider } from './journal-divider'
import { PrintContainer } from './print-container'

type Props = {
  journal: JournalItem
  data: JournalPrintoutType
}

export const JournalPrintout = (props: Props) => {
  const { t } = useTranslation(['shared', 'journals'])
  const { journal, data } = props
  const { formatDate } = useLocalizedDate()
  const getDate = (date: string) => formatDate(new Date(date))

  return (
    <PrintContainer>
      <Box>{journal.datecreated ? getDate(journal.datecreated) : ''}</Box>
      <Box>
        <strong>
          {t('journals:journal_entry_id', {
            type: t(`journals:journal_entry_type_enum.${journal.type}`),
            id: journal.id,
          })}
        </strong>
      </Box>

      {journal?.ispractice && (
        <Box>
          {t('journals:is_practice')}: {t('shared:label.yes')}
        </Box>
      )}

      {data?.type && (
        <Box>
          {t('journals:type')}: {t(`journals:printout_type_enum.${data.type}`)}
        </Box>
      )}

      {data?.receiptid && (
        <Box>
          {t('journals:receipt_id')}: {data.receiptid}
        </Box>
      )}

      {data?.orderid && (
        <Box>
          {t('journals:order_id')}: {data.orderid}
        </Box>
      )}

      {data?.zreportid && (
        <Box>
          {t('journals:zreport_id')}: {data.zreportid}
        </Box>
      )}

      <JournalDivider variant="double" />
    </PrintContainer>
  )
}
