import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'

type Props = {
  children: React.ReactNode
}

const myCache = createCache({
  key: 'backoffice',
  nonce: import.meta.env.__CSP_NONCE__,
})

export const EmotionCacheProvider = ({ children }: Props) => {
  return <CacheProvider value={myCache}>{children}</CacheProvider>
}
