import { useQuery } from '@apollo/client'
import { Autocomplete, TextField } from '@mui/material'
import { SyntheticEvent, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { GetCampaignsDocument } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import { addTagOnInputChange } from '../../../utils/autocomplete'

type QueryParamsState = {
  campaign?: string | string[]
}

export const CampaignFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)

  const { trackInputFocus, trackInputBlur } = useTracking()
  const filterKey = 'campaign'
  const { data, loading: isLoading } = useQuery(GetCampaignsDocument, {
    variables: {
      num: MAX_NUM_REQUEST,
    },
  })

  const campaigns = useMemo(
    () =>
      (data?.campaigns.items || [])
        .slice()
        .sort((a, b) => a.vouchername.localeCompare(b.vouchername)),
    [data?.campaigns.items],
  )

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const selectedCampaigns = useMemo(() => {
    return typeof queryParams[filterKey] === 'string'
      ? [queryParams[filterKey]]
      : queryParams[filterKey] || []
  }, [queryParams])

  useEffect(() => {
    registerFilter({
      key: filterKey,
      isReady: !isLoading,
    })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (selectedCampaigns.length) {
      setFilter(filterKey, {
        label: t('filter:campaign.label'),
        labelValues: selectedCampaigns,
        value: selectedCampaigns,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [campaigns, selectedCampaigns, removeFilter, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <Autocomplete
      autoHighlight
      multiple
      limitTags={3}
      fullWidth
      freeSolo
      options={campaigns.map((x) => x.vouchername)}
      disabled={isLoading}
      onChange={(_event, value) => {
        setQueryParams(
          {
            [filterKey]: value.slice(-10),
          },
          hasApply !== true,
        )
      }}
      onInputChange={addTagOnInputChange}
      value={selectedCampaigns}
      data-testid="campaign-field"
      renderInput={(params) => (
        <TextField
          label={t('filter:campaign.label')}
          type="text"
          {...params}
          onFocus={trackInputFocus({ name: `campaign-${filterKey}` })}
          onBlur={trackInputBlur({ name: `campaign-${filterKey}` })}
          inputProps={{
            ...params.inputProps,
            ['data-testid']: 'campaign-input',
          }}
        />
      )}
    />
  )
}
