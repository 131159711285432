import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { useTracking } from '../../hooks/tracking'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'
import { useTranslation } from 'react-i18next'

type ViewPanelImageProps = {
  open: boolean
  onClose(): void
  onSuccess(): void
  children?: React.ReactNode
  editable?: boolean
}

export const ImageUploadDialog = (props: ViewPanelImageProps) => {
  const { open, children, editable = true } = props
  const dialogName = 'image-upload'
  const { t } = useTranslation(['products', 'shared'])
  const prevOpen = usePrevious(props.open)
  const { trackDialogOpen, trackDialogClose, trackButtonClickEvent } =
    useTracking()

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  const onSuccess = () => {
    trackDialogClose({ name: dialogName })
    props.onSuccess?.()
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('products:product_images_title')}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={trackButtonClickEvent(
            { name: `${dialogName}-dialog-cancel` },
            onClose,
          )}
        >
          {editable ? t('shared:action.cancel') : t('shared:action.close')}
        </Button>
        {editable && (
          <Button
            color="primary"
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-save` },
              onSuccess,
            )}
          >
            {t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
