import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemProps,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material'
import { DeleteIcon, EditIcon, EyeIcon, PlusIcon } from '@sitoo/mui-components'
import { JSX } from 'react'

type Id = number | string

type Props<T extends { id: Id }> = {
  loading?: boolean
  items?: T[]
  itemRender: (item: T) => JSX.Element
  secondaryActionRenderer?: (item: T) => JSX.Element
  baseDataTestId: string
  addText: string

  listItemProps?: (item: T) => Partial<ListItemProps>

  emptyTitle?: string
  emptyDescription?: string

  onEdit?: (item: T, index: number) => void
  onDelete?: (item: T, index: number) => void
  onAdd?: () => void

  canAdd?: boolean
  editable?: ((item: T) => boolean) | boolean
  readOnly?: boolean
}

export const ProductSettingsList = <T extends { id: Id }>(props: Props<T>) => {
  const { loading, items, editable, readOnly, canAdd } = props

  const onAdd = readOnly ? undefined : props.onAdd

  const isEditable = (item: T): boolean => {
    if (typeof editable === 'function') {
      return editable(item)
    }

    if (typeof editable === 'boolean') {
      return editable
    }

    return true
  }

  return (
    <>
      {loading &&
        !items?.length &&
        [1, 2, 3, 4, 5].map((_, i) => (
          <ListItem key={i} divider={i + 1 < 5}>
            <ListItemText secondary={<Skeleton width="10%" />}>
              <Skeleton width="20%" />
            </ListItemText>
          </ListItem>
        ))}

      {!loading && !items?.length && props.emptyTitle && (
        <Box
          sx={{
            p: 3.75,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          data-testid="empty-settings-list"
        >
          <Typography variant="body02">{props.emptyTitle}</Typography>
          <Typography variant="caption">{props.emptyDescription}</Typography>
        </Box>
      )}

      {items?.map((x, i) => (
        <ListItem
          key={x.id}
          divider={i !== items?.length - 1}
          secondaryAction={
            <Box>
              {readOnly ? (
                <>
                  {props.onEdit && (
                    <IconButton
                      disabled={loading}
                      onClick={() => props.onEdit?.(x, i)}
                      data-testid={`edit-${props.baseDataTestId}-${x.id}`}
                    >
                      <EyeIcon />
                    </IconButton>
                  )}
                </>
              ) : (
                isEditable(x) && (
                  <>
                    {isEditable(x) && (
                      <>
                        {props.onEdit && (
                          <IconButton
                            disabled={loading}
                            onClick={() => props.onEdit?.(x, i)}
                            data-testid={`edit-${props.baseDataTestId}-${x.id}`}
                          >
                            <EditIcon />
                          </IconButton>
                        )}
                      </>
                    )}
                    {props.onDelete && (
                      <IconButton
                        disabled={loading}
                        onClick={() => props.onDelete?.(x, i)}
                        data-testid={`delete-${props.baseDataTestId}-${x.id}`}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                )
              )}
              {props.secondaryActionRenderer?.(x)}
            </Box>
          }
          data-testid={`${props.baseDataTestId}-${x.id}`}
          {...(props.listItemProps?.(x) || {})}
        >
          {props.itemRender(x)}
        </ListItem>
      ))}

      {onAdd && canAdd && (
        <Box>
          <Button
            color="tertiary"
            fullWidth
            onClick={onAdd}
            data-testid={`add-${props.baseDataTestId}`}
            disabled={loading}
          >
            <PlusIcon sx={{ mr: 1 }} fontSize="medium" />
            {props.addText}
          </Button>
        </Box>
      )}
    </>
  )
}
