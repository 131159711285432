import { PropsWithChildren, useId } from 'react'
import { SubmitErrorHandler, useFormContext } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessages } from '../../../utils/error-mapping'
import { ListItem, Button, Divider, Stack } from '@mui/material'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import { useTranslation } from 'react-i18next'

type Props<T> = PropsWithChildren & {
  onSubmit(form: T): Promise<void>
}

export const PosSettingsForm = <T extends Record<string, unknown>>(
  props: Props<T>,
) => {
  const { t } = useTranslation(['pos_settings'])
  const formId = useId()

  const { formState, handleSubmit, reset } = useFormContext<T>()

  const { isDirty, isSubmitSuccessful } = formState

  const { trackButtonClick, trackFormSuccess, trackFormError } = useTracking()

  const onSubmit = async (data: T) => {
    trackButtonClick({ name: 'pos-settings-general-save' })

    try {
      await props.onSubmit(data)

      reset(data, { keepDefaultValues: false })

      trackFormSuccess({ name: 'pos-settings-general' })

      enqueueSnackbar(t('pos_settings:success_edit'))
    } catch (error) {
      const errorMessage = getErrorMessages(error, {
        resolve: (errorCode) => `pos_settings:error.${errorCode}`,
        fallbackError: t('pos_settings:error.generic'),
      }).join(', ')

      trackFormError({ name: 'pos-settings-general', errorMessage })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<T> = (_errors) => {
    enqueueSnackbar(t('pos_settings:failure_edit'), { variant: 'error' })
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form id={formId} onSubmit={handleSubmit(onSubmit, onError)}>
      <RouteLeavingGuard when={isDirty && !isSubmitSuccessful} />

      {props.children}

      <Divider />

      <ListItem sx={{ justifyContent: 'flex-end' }}>
        <Stack direction="row" spacing={1} sx={{ my: 2 }}>
          <Button size="small" color="secondary" onClick={() => reset()}>
            {t('shared:action.cancel')}
          </Button>

          <Button
            form={formId}
            type="submit"
            disabled={!formState.isDirty}
            size="small"
          >
            {t('shared:action.save')}
          </Button>
        </Stack>
      </ListItem>
    </form>
  )
}
