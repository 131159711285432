import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemText,
} from '@mui/material'
import {
  ChevronSmallRightIcon,
  PrimitiveCircleSmallIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { GetOrderQuery } from '../../../generated/graphql'
import { OrderViewPanelState } from '../util'
import { ReactiveVar, useReactiveVar } from '@apollo/client'
import { useMoney } from '../../../hooks/money'
import { ListItemButtonSkeleton } from '../../../components/list-item-button-skeleton'

type Props = {
  isLoading?: boolean
  order?: GetOrderQuery['order']
  viewPanelVar: ReactiveVar<OrderViewPanelState>
}

export const RelatedOrders = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const viewPanelVar = useReactiveVar(props.viewPanelVar)
  const { formatCurrency: baseFormatCurrency } = useMoney()
  const formatCurrency = (cash?: string | number | null) =>
    baseFormatCurrency(cash || 0, { forceDecimals: true })

  const order = props.order

  const isDiscountItem = (orderItem: { quantity: number }) => {
    return orderItem.quantity === 0
  }

  const getItemsCounter = (orderItems: { quantity: number }[] | undefined) => {
    return orderItems?.filter((item) => !isDiscountItem(item)).length || 0
  }

  if (
    props.isLoading ||
    !(order?.originalOrder || order?.refundOrder || order?.exchangeOrder)
  ) {
    return null
  }

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="related-orders">
        <SectionHeader sx={{ p: 0 }}>
          {t('orders:related_orders')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemButtonSkeleton
            data-testid="original-order"
            hide={!props.isLoading && !order?.originalOrder}
            isLoading={props.isLoading}
            divider
            onClick={() =>
              props.viewPanelVar({
                orderId: order?.originalOrder?.orderid || -1,
                isOpen: true,
                previous: viewPanelVar,
              })
            }
          >
            <ListItemText
              data-testid="linked-order-details"
              primary={`#${order?.originalOrder?.orderid || ''}`}
              secondary={t('orders:original_order_items', {
                count: getItemsCounter(order?.originalOrder?.orderitems),
              })}
            />
            <Box
              data-testid="linked-order-price"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PrimitiveCircleSmallIcon
                color={
                  (Number(order?.originalOrder?.moneytotal_gross_all) || 0) >= 0
                    ? 'success'
                    : 'error'
                }
              />
              {formatCurrency(order?.originalOrder?.moneytotal_gross_all)}
              <ChevronSmallRightIcon sx={{ ml: 2 }} />
            </Box>
          </ListItemButtonSkeleton>
          <ListItemButtonSkeleton
            data-testid="refund-order"
            hide={!props.isLoading && !order?.refundOrder}
            isLoading={props.isLoading}
            divider
            onClick={() =>
              props.viewPanelVar({
                orderId: order?.refundOrder?.orderid || -1,
                isOpen: true,
                previous: viewPanelVar,
              })
            }
          >
            <ListItemText
              data-testid="linked-order-details"
              primary={`#${order?.refundOrder?.orderid || ''}`}
              secondary={t('orders:refund_order_items', {
                count: getItemsCounter(order?.refundOrder?.orderitems),
              })}
            />
            <Box
              data-testid="linked-order-price"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PrimitiveCircleSmallIcon
                color={
                  (Number(order?.refundOrder?.moneytotal_gross_all) || 0) >= 0
                    ? 'success'
                    : 'error'
                }
              />
              {formatCurrency(order?.refundOrder?.moneytotal_gross_all)}
              <ChevronSmallRightIcon sx={{ ml: 2 }} />
            </Box>
          </ListItemButtonSkeleton>
          <ListItemButtonSkeleton
            data-testid="exchange-order"
            hide={props.isLoading || !order?.exchangeOrder}
            isLoading={props.isLoading}
            onClick={() =>
              props.viewPanelVar({
                orderId: order?.exchangeOrder?.orderid || -1,
                isOpen: true,
                previous: viewPanelVar,
              })
            }
          >
            <ListItemText
              data-testid="linked-order-details"
              primary={`#${order?.exchangeOrder?.orderid || ''}`}
              secondary={t('orders:exchange_order_items', {
                count: getItemsCounter(order?.exchangeOrder?.orderitems),
              })}
            />
            <Box
              data-testid="linked-order-price"
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PrimitiveCircleSmallIcon
                color={
                  (Number(order?.exchangeOrder?.moneytotal_gross_all) || 0) >= 0
                    ? 'success'
                    : 'error'
                }
              />
              {formatCurrency(order?.exchangeOrder?.moneytotal_gross_all)}
              <ChevronSmallRightIcon sx={{ ml: 2 }} />
            </Box>
          </ListItemButtonSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
