import { SxProps, Autocomplete, TextField } from '@mui/material'
import { Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { FALLBACK_LANGUAGE } from '../../i18n'

type Props = {
  disabled?: boolean
  isRequired?: boolean
  sx?: SxProps
  innerRef?: Ref<HTMLElement>
  label?: string
  helperText?: string
}

type Resources = Record<string, string>

export const CountriesInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, disabled, control, rules, isRequired, sx, innerRef } = props
  const { t, i18n } = useTranslation(['shared', 'countries'])
  const { trackInputBlur, trackInputFocus } = useTracking()

  const fieldLabel = t('shared:label.country')

  const { field, formState, fieldState } = useController({
    name,
    control,
    rules: {
      ...rules,
      required: isRequired
        ? t('shared:validation.field_required', { field: fieldLabel })
        : undefined,
    },
  })

  const { isSubmitting } = formState

  const countries = useMemo(() => {
    const resources =
      (i18n.getResourceBundle(i18n.language, 'countries') as Resources) ||
      (i18n.getResourceBundle(FALLBACK_LANGUAGE, 'countries') as Resources)

    return Object.entries(resources).map(([id, label]) => ({ id, label }))
  }, [i18n])

  const value = useMemo(() => {
    const fieldValue = field.value as string | null

    return countries.find(({ id }) => id === fieldValue?.toUpperCase()) || null
  }, [countries, field.value])

  const formError = fieldState.error

  return (
    <Autocomplete
      ref={innerRef}
      autoHighlight
      fullWidth
      options={countries}
      disabled={disabled ?? isSubmitting}
      value={value}
      onChange={(_event, value) => field.onChange(value?.id || null)}
      sx={sx}
      data-testid="countries-field"
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label ?? fieldLabel}
            helperText={formError?.message ?? props.helperText}
            type="text"
            onFocus={trackInputFocus({ name: 'countries-field-focus' })}
            onBlur={trackInputBlur({ name: 'countries-field-blur' })}
            error={!!formError}
            required={isRequired}
            inputProps={{
              ...params.inputProps,
              ['data-testid']: 'countries-input',
            }}
          />
        )
      }}
    />
  )
}
