import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseSalesTaxContext } from '../shared'
import { DECIMAL_REGEX } from '../../../../utils/constants'

export const InformationField = () => {
  const { t } = useTranslation(['settings', 'shared'])
  const { register, formState } = useFormContext<BaseSalesTaxContext>()

  return (
    <FormFieldset label={t('sales_tax.information_fieldset')}>
      <TextField
        data-testid="tax-name-field"
        label={t('settings:sales_tax.name')}
        sx={{ mb: 2 }}
        error={!!formState?.errors.name}
        helperText={formState?.errors.name?.message}
        {...register('name', {
          required: t('shared:validation.field_required', {
            field: t('settings:sales_tax.name'),
          }),
        })}
      />

      <TextField
        data-testid="tax-value-field"
        label={`${t('settings:sales_tax.tax')} %`}
        sx={{ mb: 2 }}
        error={!!formState?.errors.decimalvaluedefault}
        helperText={
          formState?.errors.decimalvaluedefault?.message ||
          t('settings:sales_tax.tax_helper_text')
        }
        {...register('decimalvaluedefault', {
          pattern: {
            value: DECIMAL_REGEX,
            message: t('settings:sales_tax.validation.decimal_pattern'),
          },
          required: t('shared:validation.field_required', {
            field: t('settings:sales_tax.tax'),
          }),
        })}
      />

      <TextField
        data-testid="tax-code-field"
        label={t('settings:sales_tax.code')}
        sx={{ mb: 2 }}
        error={!!formState?.errors.code}
        helperText={
          formState?.errors.code?.message ||
          t('settings:sales_tax.code_helper_text')
        }
        {...register('code')}
      />
    </FormFieldset>
  )
}
