import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  GetShipmentsV2Document,
  UpdateArchiveStateShipmentsV2Document,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'

type Props = {
  open: boolean
  selectedIds: number[]
  setToArchived?: boolean
  onClose?(): void
  onSuccess?(archivedIds: number[]): void
  onError?(archivedIds: number[]): void
}

export const ArchiveShipmentDialog = (props: Props) => {
  const { t } = useTranslation('shipments_v2')
  const dialogName = 'archive-shipment'

  const [archiveShipment, { loading }] = useMutation(
    UpdateArchiveStateShipmentsV2Document,
    { refetchQueries: [GetShipmentsV2Document] },
  )

  const {
    trackButtonClick,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const onArchive = async () => {
    trackButtonClick({ name: `${dialogName}-archive` })

    try {
      const { data } = await archiveShipment({
        variables: {
          shipments: props.selectedIds.map((selectedId) => ({
            shipmentid: selectedId,
            archived: props.setToArchived ?? true,
          })),
        },
      })

      const archivedIds =
        data?.updateShipmentsV2.map((shipment) =>
          Number(shipment.shipmentid),
        ) || []

      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.(archivedIds)
    } catch {
      trackFormError({ name: `${dialogName}-dialog` })
      props.onError?.(props.selectedIds)
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={onArchive}
      title={
        props.setToArchived === false
          ? t('unarchive_dialog.title', { count: props.selectedIds.length })
          : t('archive_dialog.title', { count: props.selectedIds.length })
      }
      text={
        props.setToArchived === false
          ? t('unarchive_dialog.description', {
              count: props.selectedIds.length,
            })
          : t('archive_dialog.description', { count: props.selectedIds.length })
      }
      variant="destructive"
      open={props.open}
      onClose={onClose}
    />
  )
}
