import { Menu, MenuItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../../hooks/tracking'
import { usePrint } from '../use-print'
import { useMutation } from '@apollo/client'
import {
  ExportStoreReplenishmentDocument,
  FileType,
} from '../../../../generated/graphql'
import { ExportDialog } from '../../../../components/export-dialog'
import { useState } from 'react'
import { useSnackbar } from 'notistack'

type Props = {
  anchorEl: Element | null
  onClose?(): void
  selectedIds: string[] | undefined
  warehouseToId: number | undefined
  warehouseFromId: number | undefined
}

export const ContextMenu = (props: Props) => {
  const { t } = useTranslation(['shared', 'stock'])
  const { trackButtonClickEvent } = useTracking()
  const [isExportDialogOpen, setExportDialogOpen] = useState(false)
  const print = usePrint()
  const { enqueueSnackbar } = useSnackbar()

  const { warehouseToId, warehouseFromId } = props

  const [exportMutation, { loading: isLoading }] = useMutation(
    ExportStoreReplenishmentDocument,
  )

  const onExport = async (fileType: FileType) => {
    if (!warehouseToId || !warehouseFromId) {
      return
    }

    try {
      const { data } = await exportMutation({
        variables: {
          warehouseToId,
          warehouseFromId,
          skus: props.selectedIds,
          fileType,
        },
      })

      const fileLink = data?.exportAllReplenishmentItems

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('stock:in_store_replenishment.export_success'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('stock:in_store_replenishment.export_error'), {
        variant: 'error',
      })
    } finally {
      setExportDialogOpen(false)
    }
  }

  const isDisabled = !warehouseToId || !warehouseFromId

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.onClose}
        onClick={props.onClose}
        data-testid="more-options-button"
      >
        <MenuItem
          disabled={isDisabled}
          onClick={trackButtonClickEvent(
            { name: 'in-store-replenishment-list-bulk-actions-menu-print' },
            () => {
              print(props.selectedIds)
              props.onClose?.()
            },
          )}
        >
          <Typography variant="body02">
            {t('stock:in_store_replenishment.print_label', {
              count: props.selectedIds?.length || 0,
            })}
          </Typography>
        </MenuItem>

        <MenuItem
          disabled={isDisabled}
          onClick={trackButtonClickEvent(
            { name: 'in-store-replenishment-list-bulk-actions-menu-export' },
            () => {
              setExportDialogOpen(true)
              props.onClose?.()
            },
          )}
        >
          <Typography variant="body02">
            {t('stock:in_store_replenishment.export_label', {
              count: props.selectedIds?.length || 0,
            })}
          </Typography>
        </MenuItem>
      </Menu>

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        label={t('stock:in_store_replenishment.export_label', {
          count: props.selectedIds?.length || 0,
        })}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSuccess={onExport}
        isLoading={isLoading}
      />
    </>
  )
}
