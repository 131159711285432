import { Analytics } from 'analytics'
import { AnalyticsProvider as Provider } from 'use-analytics'
import amplitudePlugin from '@analytics/amplitude'
import consolePlugin from './console-plugin'
import { AmplitudeClient } from 'amplitude-js'

const isProduction = import.meta.env.PROD

const hasAmplitude = (amplitude: unknown): amplitude is AmplitudeClient => {
  return (
    typeof window['amplitude'] === 'object' && 'options' in window['amplitude']
  )
}

const analytics = Analytics({
  app: 'backoffice',
  plugins: isProduction
    ? [
        {
          ...amplitudePlugin({
            apiKey: import.meta.env['VITE_AMPLITUDE_KEY'],
            // See options at https://bit.ly/3dRdZnE
            options: {},
          }),
          reset: () => {
            if (hasAmplitude(window['amplitude'])) {
              window.amplitude.getInstance().setUserId(null)
            }
          },
        },
      ]
    : [consolePlugin()],
})

type Props = {
  children: React.ReactNode
}

export const AnalyticsProvider = (props: Props) => {
  return <Provider instance={analytics}>{props.children}</Provider>
}
