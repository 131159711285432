import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetOrderQuery } from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { useLocalizedDate } from '../../../hooks/localized-date'
import {
  InformationEditDialog,
  OrderInformation,
} from './information-edit-dialog'
import { formatAdditionalData } from '../../../utils/format/additional-data'
import { OrderAdditionalData } from '../util'

type Props = {
  isLoading?: boolean
  order?: GetOrderQuery['order']
  readOnly?: boolean
}

export const Information = (props: Props) => {
  const { formatDate } = useLocalizedDate()
  const { t } = useTranslation(['shared', 'orders'])
  const { enqueueSnackbar } = useSnackbar()
  const [showEditInformationDialog, setShowEditInformationDialog] =
    useState(false)
  const {
    modules: { writeOrders },
  } = useAuthorization()

  const order = props.order

  const orderState = order?.orderstateid
    ? t(`orders:order_state.${order?.orderstateid}`)
    : undefined

  const paymentState = order?.paymentstateid
    ? t(`orders:payment_state.${order?.paymentstateid}`)
    : undefined

  const additionalData: OrderAdditionalData | null | undefined =
    order?.additionaldata || {}

  const additionalDataList = formatAdditionalData(additionalData)

  const returnLookupMethod = additionalData['pos-refund-initiation-mode']

  const staffName =
    [order?.staff?.namefirst, order?.staff?.namelast]
      .filter(Boolean)
      .join(' ') || order?.staff?.externalid

  const staffHelperNames = order?.staffHelpers
    ?.map(
      (x) =>
        [x?.namefirst, x?.namelast].filter(Boolean).join(' ') || x.externalid,
    )
    .join(',')

  const warehouseName = order?.warehouse?.name

  const register = order?.cashRegister?.registerkey

  const getOrderInformation = (
    order?: GetOrderQuery['order'],
  ): OrderInformation | undefined =>
    order
      ? {
          orderdate: order.orderdate,
          orderstateid: order.orderstateid,
          paymentstateid: order.paymentstateid,
          warehouseid: order.warehouseid,
          commentinternal: order.commentinternal,
          datereserved: order.datereserved,
          comment: order.comment,
        }
      : undefined

  const onSuccessInformationEditDialog = () => {
    setShowEditInformationDialog(false)
    enqueueSnackbar(t('orders:edit_information_dialog.success_update'))
  }

  const onErrorInformationEditDialog = () => {
    enqueueSnackbar(t('orders:edit_information_dialog.failure_update'), {
      variant: 'error',
    })
  }

  return (
    <>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="information">
          <SectionHeader sx={{ p: 0 }}>{t('orders:information')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List>
            <ListItemSkeleton
              secondaryAction={<ListItemText>{order?.orderid}</ListItemText>}
              isLoading={props.isLoading}
              data-testid="order-information-order-id"
              divider
            >
              <ListItemText primary={t('orders:view_panel.order_id_label')} />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!order?.creditorderid}
              secondaryAction={
                <ListItemText>{order?.creditorderid}</ListItemText>
              }
              isLoading={props.isLoading}
              data-testid="order-information-credit-order-id"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.credit_order_id_label')}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              secondaryAction={
                <ListItemText>
                  {order?.orderdate
                    ? formatDate(new Date(order.orderdate))
                    : '-'}
                </ListItemText>
              }
              isLoading={props.isLoading}
              data-testid="order-information-date"
              divider
            >
              <ListItemText primary={t('orders:view_panel.order_date_label')} />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !orderState}
              secondaryAction={<ListItemText>{orderState || '-'}</ListItemText>}
              isLoading={props.isLoading}
              data-testid="order-information-state"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.order_state_label')}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !paymentState}
              secondaryAction={
                <ListItemText>{paymentState || '-'}</ListItemText>
              }
              isLoading={props.isLoading}
              data-testid="order-information-payment-state"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.payment_state_label')}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !warehouseName}
              secondaryAction={
                <ListItemText>{warehouseName || '-'}</ListItemText>
              }
              isLoading={props.isLoading}
              data-testid="order-information-warehouse"
              divider
            >
              <ListItemText primary={t('orders:view_panel.warehouse_label')} />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !order?.datereserved}
              secondaryAction={
                <ListItemText>
                  {order?.datereserved
                    ? formatDate(new Date(order.datereserved))
                    : '-'}
                </ListItemText>
              }
              isLoading={props.isLoading}
              data-testid="order-information-reserve-until"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.reserve_until_label')}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !returnLookupMethod}
              secondaryAction={
                <ListItemText>
                  {returnLookupMethod
                    ? t(
                        `orders:view_panel.return_lookup_method_enum.${
                          returnLookupMethod || ''
                        }`,
                      )
                    : '-'}
                </ListItemText>
              }
              isLoading={props.isLoading}
              data-testid="order-information-return-lookup-method"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.return_lookup_method')}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !register}
              secondaryAction={<ListItemText>{register || '-'}</ListItemText>}
              isLoading={props.isLoading}
              data-testid="order-information-register"
              divider
            >
              <ListItemText primary={t('orders:view_panel.register_label')} />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={
                !props.isLoading &&
                order?.payments.map((p) => p.refid).join('') === ''
              }
              isLoading={props.isLoading}
              data-testid="order-information-payment-reference"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.payment_reference_label')}
                secondary={order?.payments.map((p) => p.refid).join(',') || '-'}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !staffName}
              secondaryAction={<ListItemText>{staffName || '-'}</ListItemText>}
              isLoading={props.isLoading}
              data-testid="order-information-pos-staff"
              divider
            >
              <ListItemText primary={t('orders:view_panel.pos_staff_label')} />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !staffHelperNames}
              isLoading={props.isLoading}
              data-testid="order-information-pos-staff-helpers"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.additional_staff_member')}
                secondary={staffHelperNames}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && additionalDataList.length === 0}
              secondaryAction={additionalDataList.length ? null : '-'}
              isLoading={props.isLoading}
              data-testid="order-information-additional-data"
              divider
            >
              <ListItemText
                primary={t('orders:view_panel.additional_data_label')}
                secondary={additionalDataList.map(([key, value]) => (
                  <Box key={key} component="span" sx={{ display: 'block' }}>
                    {key}: {value}
                  </Box>
                ))}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !order?.commentinternal}
              secondaryAction={order?.commentinternal ? null : '-'}
              isLoading={props.isLoading}
              data-testid="order-information-internal-comment"
              divider
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
              <ListItemText
                primary={t('orders:view_panel.internal_comment_label')}
                secondary={order?.commentinternal}
              />
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!props.isLoading && !order?.comment}
              isLoading={props.isLoading}
              secondaryAction={order?.comment ? null : '-'}
              data-testid="order-information-comment"
              divider
              sx={{
                whiteSpace: 'pre-line',
              }}
            >
              <ListItemText
                primary={t('orders:view_panel.comment_label')}
                secondary={order?.comment}
              />
            </ListItemSkeleton>

            {writeOrders && !props.readOnly && (
              <ListItemSkeleton
                sx={{
                  boxShadow: (theme) => `0 -1px 0 ${theme.palette.white}`,
                }}
                isLoading={props.isLoading}
              >
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => setShowEditInformationDialog(true)}
                  data-testid="edit-order-information"
                  fullWidth
                >
                  {t('shared:action.edit')}
                </Button>
                <InformationEditDialog
                  open={showEditInformationDialog}
                  onClose={() => setShowEditInformationDialog(false)}
                  order={getOrderInformation(order)}
                  orderId={order?.orderid}
                  onSuccess={onSuccessInformationEditDialog}
                  onError={onErrorInformationEditDialog}
                />
              </ListItemSkeleton>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
