import { Autocomplete, TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseUserContext } from '../shared'
import { UserPermissionsByIdQuery } from '../../../generated/graphql'

type Props = {
  permissions: UserPermissionsByIdQuery['userPermissionsById'] | undefined
}

export const DetailsField = (props: Props) => {
  const { permissions } = props
  const { t } = useTranslation(['users'])
  const { register, control, formState } = useFormContext<BaseUserContext>()

  const { field } = useController({
    name: 'voucherpasswords',
    control,
  })

  return (
    <FormFieldset label={t('user_form.details_fieldset')}>
      <TextField
        label={t('customer_number')}
        sx={{ mb: 2 }}
        error={!!formState?.errors.customernumber}
        helperText={formState?.errors.customernumber?.message}
        {...register('customernumber')}
        disabled={!permissions?.editUser}
      />

      <Autocomplete
        freeSolo
        multiple
        autoSelect
        options={[]}
        value={field.value || []}
        onChange={(_event, value) => field.onChange(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('user_form.campaign_passwords')}
            helperText={t('user_form.campaign_passwords_helper')}
            disabled={!permissions?.editUser}
          />
        )}
      />
    </FormFieldset>
  )
}
