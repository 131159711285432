import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { useMoney } from '../../../../hooks/money'

export const BasicDetailsField = () => {
  const { register, formState, watch } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const { formatStringCurrencyToNumber } = useMoney()
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const isSingleProduct =
    watch('product.isSingleProduct') ||
    watch('product.isSingleProduct') === undefined

  const isNewProduct = !watch('product.id')

  return (
    <FormFieldset label={t('products:product_form.details_fieldset')}>
      <TextField
        error={!!formState?.errors.product?.title}
        data-testid="title-field"
        fullWidth
        helperText={
          formState?.errors.product?.title?.message ||
          t('products:product_form.title_description')
        }
        label={t('products:product_form.title_label')}
        {...register('product.title', {
          required: t('shared:validation.field_required', {
            field: t('products:product_form.title_label'),
          }),
        })}
        disabled={!writeProducts || !isSingleProduct}
        sx={{ mb: isSingleProduct ? 2 : 0 }}
      />

      {isSingleProduct && (
        <TextField
          data-testid="sku-field"
          error={!!formState?.errors.product?.sku}
          fullWidth
          helperText={
            formState?.errors.product?.sku?.message ||
            t('products:product_form.sku_description')
          }
          label={t('products:product_form.sku_label')}
          {...register('product.sku', {
            required: t('shared:validation.field_required', {
              field: t('products:product_form.sku_label'),
            }),
          })}
          disabled={!writeProducts}
          sx={{ mb: 2 }}
        />
      )}

      {isSingleProduct && (
        <TextField
          data-testid="barcode-field"
          error={!!formState?.errors.product?.barcode}
          fullWidth
          label={t('products:product_form.barcode')}
          {...register('product.barcode')}
          disabled={!writeProducts}
          sx={{ mb: 2 }}
        />
      )}

      {isSingleProduct && (
        <TextField
          data-testid="price-field"
          error={!!formState?.errors.product?.moneyprice}
          fullWidth
          helperText={
            formState?.errors.product?.moneyprice?.message ||
            t('products:product_form.price_description')
          }
          label={t('products:price')}
          {...register('product.moneyprice', {
            required: t('shared:validation.field_required', {
              field: t('products:price'),
            }),
            validate: (v) =>
              isNewProduct && v && Number.isNaN(formatStringCurrencyToNumber(v))
                ? t('shared:validation.field_invalid', {
                    field: t('products:price'),
                  })
                : true,
          })}
          disabled={!isNewProduct}
          sx={{ mb: 2 }}
        />
      )}

      {isSingleProduct && (
        <TextField
          data-testid="srp-field"
          error={!!formState?.errors.product?.moneypriceorg}
          fullWidth
          helperText={formState?.errors.product?.moneypriceorg?.message}
          label={t('products:srp')}
          {...register('product.moneypriceorg', {
            validate: (v) =>
              isNewProduct && v && Number.isNaN(formatStringCurrencyToNumber(v))
                ? t('shared:validation.field_invalid', {
                    field: t('products:srp'),
                  })
                : true,
          })}
          disabled={!writeProducts}
          sx={{ mb: 2 }}
        />
      )}
    </FormFieldset>
  )
}
