import { Avatar, Box } from '@mui/material'
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'
import { QuestionIcon } from '@sitoo/mui-components'

export const DataGridImageRenderer = (
  params: GridRenderCellParams<GridValidRowModel, string>,
) => <DataGridImage src={params.formattedValue} />

type DataGridImageProps = {
  src?: string
}

export const DataGridImage = (props: DataGridImageProps) => {
  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Avatar
        variant="square"
        src={props.src}
        sx={{
          width: 30,
          height: 30,
          margin: 'auto',
          borderRadius: '2px',
          img: {
            objectFit: 'contain',
          },
          background: 'white',
        }}
      >
        <Box
          sx={{
            background: (t) => t.palette.gray20,
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <QuestionIcon sx={{ color: (t) => t.palette.gray50 }} />
        </Box>
      </Avatar>
    </Box>
  )
}
