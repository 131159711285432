import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { SalesTaxProductGroupRule } from '../../../../generated/graphql'
import { SalesTaxInvalidRuleBanner } from '../sales-tax-invalid-rule-banner'
import { useSalesTaxFormatter } from '../useSalesTaxFormatter'

type Props = {
  salesTaxRules?: SalesTaxProductGroupRule[] | null
}

export const Rules = ({ salesTaxRules }: Props) => {
  const { t } = useTranslation(['settings'])
  const { formatNameAndTax, formatRuleTypeAndAmounts } = useSalesTaxFormatter()

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="rules">
        <SectionHeader sx={{ p: 0 }}>
          {t('settings:sales_tax.rules')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List sx={{ backgroundColor: 'background.default' }}>
          {salesTaxRules?.map((rule, index) => {
            const isRuleInvalid = !rule.groupName
            const isLastRule = index + 1 === salesTaxRules.length
            return (
              <Box key={`${rule.vatid}-${index}`} component="span">
                <ListItemSkeleton
                  data-testid="sales_tax_rule"
                  divider={!!rule.groupName}
                  sx={{
                    backgroundColor: 'background.paper',
                    color: isRuleInvalid ? 'redBase' : '',
                    '& .MuiListItemText-secondary': {
                      color: isRuleInvalid ? 'redBase' : '',
                    },
                  }}
                >
                  <ListItemText
                    primary={formatNameAndTax(
                      rule?.groupName,
                      rule.decimalvalue,
                    )}
                    secondary={
                      <>
                        {formatRuleTypeAndAmounts(rule)}
                        {rule.externalid && (
                          <>
                            <br />
                            {`${t('settings:sales_tax.external_id')}: ${rule.externalid}`}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItemSkeleton>

                {isRuleInvalid && (
                  <SalesTaxInvalidRuleBanner sx={{ mb: isLastRule ? 0 : 1 }} />
                )}
              </Box>
            )
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
