import { Box, Radio, RadioGroup, Stack } from '@mui/material'
import {
  FormFieldset,
  FormLabelField,
  RadioGroupLabel,
  SectionHeader,
} from '@sitoo/mui-components'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { Attributes, AttributesMap, CustomAttributeValue } from './attributes'

enum Selection {
  All,
  Exclude,
}

export const CartAppliesToField = () => {
  const { t } = useTranslation(['campaigns'])
  const { watch, setValue, getValues } = useFormContext<CampaignFormContext>()

  const productAttributes = watch('campaign.productattributes')
  const excludeAttributes = (productAttributes?.exclude as AttributesMap) || {}

  const [selection, setSelection] = useState<Selection>(
    Object.keys(excludeAttributes).length ? Selection.Exclude : Selection.All,
  )

  const [persistedData, setPersistedData] = useState(
    {} as Record<Selection, { include: AttributesMap; exclude: AttributesMap }>,
  )

  const onSelectionChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setPersistedData((state) => ({
        ...state,
        [selection]: { ...getValues('campaign.productattributes') },
      }))

      const selectionValue = Number(value) as Selection

      if (Selection.All === selectionValue) {
        setSelection(Selection.All)

        setValue('campaign.productattributes', {
          include: {},
          exclude: {},
        })
      } else if (Selection.Exclude === selectionValue) {
        setSelection(Selection.Exclude)

        setValue('campaign.productattributes', {
          include: {},
          exclude: persistedData[Selection.Exclude]?.exclude,
        })
      }
    },
    [getValues, persistedData, selection, setValue],
  )

  const onChangeExcludeAttribute = (
    attributeId: string,
    values: NonNullable<CustomAttributeValue>[],
  ) => {
    setValue('campaign.productattributes.exclude', {
      ...excludeAttributes,
      [attributeId]: values,
    })
  }

  const onRemoveExcludeAttribute = (attributeId: string) => {
    delete excludeAttributes[attributeId]
    setValue('campaign.productattributes.exclude', excludeAttributes)
  }

  return (
    <FormFieldset
      label={t('campaigns:campaign_form.applies_to')}
      sx={{
        '.MuiFormFieldset-Paper': {
          p: 0,
        },
      }}
    >
      <RadioGroup value={selection} onChange={onSelectionChange}>
        <RadioGroupLabel
          divider
          label={t('campaigns:campaign_form.all_products')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio
              value={Selection.All}
              data-testid="applies-to-all-products"
            />
          }
        />

        <RadioGroupLabel
          divider
          label={t('campaigns:campaign_form.exclude_attributes_label')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio
              value={Selection.Exclude}
              data-testid="applies-to-all-products-except"
            />
          }
        >
          <Stack sx={{ pb: 2 }} spacing={2}>
            <Box>
              <SectionHeader sx={{ px: 0 }} variant="transparent">
                {t('campaigns:campaign_form.exclude_attributes')}
              </SectionHeader>

              <Attributes
                attributes={excludeAttributes}
                onChangeAttribute={onChangeExcludeAttribute}
                onRemoveAttribute={onRemoveExcludeAttribute}
                data-testid="attributes-exclude-section"
              />
            </Box>
          </Stack>
        </RadioGroupLabel>
      </RadioGroup>
    </FormFieldset>
  )
}
