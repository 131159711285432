import { Paper } from '@mui/material'
import { JournalReport } from '../../journal-print/journal-report'
import { JournalReportItem } from '../../types'
import { I18nextProvider } from '../../../../providers/i18n'
import { GetCashRegisterDocument } from '../../../../generated/graphql'
import { useQuery } from '@apollo/client'
import { ReceiptThemeProvider } from '../../../../providers/theme/receipt'
import { FALLBACK_LANGUAGE } from '../../../../i18n'

type Props = {
  journal: JournalReportItem
  hidden: boolean
}

export const Report = (props: Props) => {
  const { journal, hidden } = props

  const { data: registerData } = useQuery(GetCashRegisterDocument, {
    variables: { id: journal?.registerid },
    skip: !journal?.registerid,
  })

  const language =
    registerData?.cashRegister.receiptlanguagetag || FALLBACK_LANGUAGE

  const registerNumber = registerData?.cashRegister.registernumber

  return (
    <ReceiptThemeProvider>
      <I18nextProvider language={language}>
        <Paper elevation={0}>
          {!hidden && (
            <JournalReport journal={journal} registerNumber={registerNumber} />
          )}
        </Paper>
      </I18nextProvider>
    </ReceiptThemeProvider>
  )
}
