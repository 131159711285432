import { Container, List, ListItem, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { FormProvider, useForm } from 'react-hook-form'
import {
  PosOrderField,
  PosRequiredFieldsSettingsDocument,
  Settings,
  UpdateRequiredFieldsSettingsDocument,
} from '../../../generated/graphql'
import { PosSettingsForm } from './pos-settings-form'
import { useMutation, useQuery } from '@apollo/client'
import { usePageTitle } from '../../../hooks/title'
import { AutocompleteInput } from '../../../inputs/autocomplete-input'

type Form = Omit<Settings, 'eshopid'>

export const PosSettingsRequiredFieldsPage = () => {
  const { t } = useTranslation(['shared', 'pos_settings'])
  usePageTitle(t('shared:menu.pos_required_fields'))

  const defaultValues: Form = {
    required_fields_booking: [],
    required_fields_delivery: [],
    required_fields_pickup: [],
  }

  const formContext = useForm<Form>({ defaultValues })

  const { control, reset } = formContext

  useQuery(PosRequiredFieldsSettingsDocument, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => reset(data.settings, { keepDefaultValues: false }),
  })

  const [updateSettings] = useMutation(UpdateRequiredFieldsSettingsDocument)

  const onSubmit = async (data: Form) => {
    await updateSettings({ variables: { settings: data } })
  }

  return (
    <>
      <PageHeader title={t('shared:menu.pos_required_fields')} />

      <Container>
        <Paper elevation={0} sx={{ pt: 1 }}>
          <FormProvider {...formContext}>
            <PosSettingsForm onSubmit={onSubmit}>
              <List>
                {(['booking', 'pickup', 'delivery'] as const).map((type) => (
                  <ListItem divider key={type}>
                    <AutocompleteInput
                      multiple
                      name={`required_fields_${type}`}
                      control={control}
                      options={Object.keys(PosOrderField)}
                      label={t(`pos_settings:required_fields_${type}_label`)}
                      sx={{ mb: 1 }}
                      textFieldProps={{
                        helperText: t(
                          `pos_settings:required_fields_${type}_helper`,
                        ),
                      }}
                      autocompleteProps={{
                        getOptionLabel: (option) =>
                          t(`pos_settings:pos_required_field.${option}`),
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </PosSettingsForm>
          </FormProvider>
        </Paper>
      </Container>
    </>
  )
}
