import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  isReturn: boolean
}

export const ReturnTag = (props: Props) => {
  const { t } = useTranslation(['orders'])
  const { isReturn } = props

  if (!isReturn) return null

  return (
    <Chip
      data-testid="return-tag"
      size="small"
      color="red"
      label={t('orders:view_panel.return')}
    />
  )
}
