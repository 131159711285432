/// <reference types="vite-plugin-pwa/client" />

const isProduction = import.meta.env.PROD
import { registerSW } from 'virtual:pwa-register'

export const initServiceWorker = () => {
  if (isProduction && 'serviceWorker' in navigator) {
    registerSW({
      onOfflineReady() {},
      onRegisterError(error) {
        // eslint-disable-next-line no-console
        console.log('Service Worker Error', error)
      },
    })
  }
}
